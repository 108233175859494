// import jsonData from '../../ui/components/BackendJson.json'

export interface NestedIndustry {
  name: string
  subIndustries: [{ name: string; id: number }]
}

export interface IndustryGroup {
  id: number
  name: string
  industries:
    | [
        {
          name: string
          subIndustries: [
            {
              name: string
              id: number
            }
          ]
        }
      ]
    | any[] // empty array
}

interface Item {
  id: number
  industry_group: string
  industry: string
  sub_industry: string
}
const useNestedIndustries = (jsonData: Item[]) => {
  const industryData = jsonData.reduce((pre, curr) => {
    // Find IndustryGroup which name is curr.industry_group
    const currentGroupIndex = pre.find((industryGroup) => {
      return curr.industry_group == industryGroup.name
    })
    if (currentGroupIndex) {
      const currentIndustryIndex = currentGroupIndex.industries?.find((ind) => curr.industry == ind.name)
      if (currentIndustryIndex) {
        currentIndustryIndex.subIndustries.push({ name: curr.sub_industry, id: curr.id })
      } else {
        currentGroupIndex.industries?.push({ name: curr.industry, subIndustries: [{ name: curr.sub_industry, id: curr.id }] })
      }
    } else if (!currentGroupIndex && curr.industry) {
      pre.push({
        id: curr.id,
        name: curr.industry_group,
        industries: [
          {
            name: curr.industry,
            subIndustries: [{ name: curr.sub_industry, id: curr.id }],
          },
        ],
      })
    } else {
      pre.push({
        id: curr.id,
        name: curr.industry_group,
        industries: [],
      })
    }
    return pre
  }, [] as IndustryGroup[])

  return industryData
}

export default useNestedIndustries
