import { AgGridEvent, ColumnState } from '@ag-grid-community/core'
import { SavedFilterData } from './Backend'

class KeyedStorage {
  constructor(protected storage: Storage, protected gridKey: string) {}

  has(key: string): boolean {
    const item = localStorage.getItem(this.key_(key))
    return item !== undefined && item !== null
  }

  load<T>(key: string): T | undefined {
    const item = localStorage.getItem(this.key_(key))
    if (item !== undefined && item !== null && item !== '{}' && item !== 'undefined') {
      return JSON.parse(item)
    }
    return undefined
  }

  key_(keyFragment: string): string {
    return this.gridKey + '/' + keyFragment
  }

  save(key: string, value: unknown): void {
    localStorage.setItem(this.key_(key), JSON.stringify(value))
  }

  loadOrElse<T>(key: string, ifValue: (value: T) => void, ifNoValue?: () => void): void {
    const value = this.load<T>(key)
    value ? ifValue(value) : ifNoValue?.()
  }
}

export class LocalStorage extends KeyedStorage {
  constructor(gridKey: string) {
    super(localStorage, gridKey)
  }

  saveCompanyOpenedRightSidebar(isOpened: boolean): void {
    this.save('openedRightSidebar', isOpened)
  }

  loadCompanyOpenedRightSidebar(): boolean | undefined {
    return this.load('openedRightSidebar')
  }

  saveGridColumnState(event: AgGridEvent): void {
    const columnState = event.columnApi.getColumnState()
    columnState.forEach((row) => {
      row.sort = null
      row.sortIndex = null
    })
    this.save('columnState', columnState)
  }

  saveGridOpenedToolPanel(event: AgGridEvent): void {
    this.save('openedToolPanel', event.api.getOpenedToolPanel())
  }

  loadGridColumnState(event: AgGridEvent, ifNoValue?: () => void): void {
    this.loadOrElse<ColumnState[]>(
      'columnState',
      (savedState) => {
        const latestState = event.columnApi.getColumnState()
        const mergedState = this.mergeGridColumnStates(savedState, latestState)
        event.columnApi.setColumnState(mergedState)
      },
      ifNoValue
    )
  }

  loadGridOpenedToolPanel(event: AgGridEvent): void {
    this.loadOrElse<string>(
      'openedToolPanel',
      (openedToolPanel) => {
        event.api.openToolPanel(openedToolPanel)
      },
      () => {
        event.api.openToolPanel('filters')
      }
    )
  }

  private mergeGridColumnStates(savedState: ColumnState[], latestState: ColumnState[]): ColumnState[] {
    return latestState.map((latestStateCol) => {
      const savedIndex = savedState.findIndex((col) => col.colId === latestStateCol.colId)
      return savedIndex === -1 ? latestStateCol : savedState[savedIndex]
    })
  }
}

export class SessionStorage extends KeyedStorage {
  constructor(gridKey: string) {
    super(sessionStorage, gridKey)
  }

  saveActiveSavedFilterId(id?: SavedFilterData['id']): void {
    this.save('activeSavedFilterId', id)
  }

  loadActiveSavedFilterId(): SavedFilterData['id'] | undefined {
    return this.load('activeSavedFilterId')
  }
}
