import { Button, Dialog, FormGroup, IActionProps, InputGroup, Intent, MenuItem, Radio, RadioGroup, TextArea } from '@blueprintjs/core'
import React, { ChangeEventHandler, FC, FormEvent, useEffect, useState } from 'react'
import * as Backend from '../../logic/Backend'
import styles from './index.module.scss'
import { ItemPredicate, ItemRenderer } from '@blueprintjs/select'
import { queryIndex } from '../../logic/Helpers'
import { showErrorToast } from '../../logic/Toaster'
import { highlightText } from '../../logic/ValueFormatters'
import { Select } from '../../stories/KurrantTheme/Select'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { useSelector } from 'react-redux'
// import { t } from 'i18next'

const newList: Backend.ListData = {
  id: '',
  name: '',
  notes: '',
  publicly_editable: true,
  publicly_visible: true,
  assignee_id: '',
  organizations: [],
}

const nullAssignee: Backend.UserData = {
  id: '',
  name: 'None',
  email: 'none@email.com',
}

export interface ListFormProps {
  isOpen: boolean
  onClick: (r: Backend.ListRequest | Backend.ListData) => Promise<void>
  onClickCancel: () => void
  list?: Backend.ListData
  fetchUsers?: typeof Backend.users
}

const ListForm: FC<WithNamespaces & ListFormProps & IncludeT> = ({ isOpen, onClick, onClickCancel, list, fetchUsers = Backend.users, t }) => {
  const [currentList, setCurrentList] = useState(list || newList)
  const [intent, setIntent] = useState<Intent>('none')
  const [helperText, setHelperText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [assigneesList, setAssigneesList] = useState<Backend.UserData[]>([])
  const [currentUser, setCurrentUser] = useState<Backend.UserData | undefined>(undefined)
  const selectedCompanies = useSelector((state: any) => state.selectedCompanyState)
  async function fetchUsers_() {
    try {
      const users = await fetchUsers()
      setAssigneesList([nullAssignee, ...users])
      setCurrentUser(users?.find((usr) => usr.is_current_user))
    } catch (error) {
      showErrorToast(t('Failed to get users info'))
    }
  }

  useEffect(() => {
    fetchUsers_()
  }, [])

  function verifyName(): boolean {
    if (currentList.name?.trim() === '') {
      setIntent('danger')
      setHelperText('Please enter a name')
      return false
    } else {
      setIntent('none')
      setHelperText('')
      return true
    }
  }

  const onChangeName: ChangeEventHandler<HTMLInputElement> = (event) => {
    setCurrentList({ ...currentList, name: event.target.value })
  }

  const onChangeNotes: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setCurrentList({ ...currentList, notes: event.target.value })
  }

  const onClick_: IActionProps['onClick'] = async () => {
    if (verifyName()) {
      setIsLoading(true)
      await onClick({
        ...currentList,
        organizations: selectedCompanies,
        name: currentList.name?.trim(),
        notes: currentList.notes?.trim(),
      })
      setIsLoading(false)
    }
  }

  function onAssigneeSelect(assignee: Backend.UserData) {
    setCurrentList({ ...currentList, assignee_id: assignee.id })
  }

  function isCurrentListAssignedToPublic() {
    return currentList.assignee_id !== null && currentList.assignee_id !== '' && currentList.assignee_id !== currentUser?.id
  }

  function onVisibilityChange(event: FormEvent<HTMLInputElement>) {
    const permission_ = (event.target as HTMLInputElement).value as Permission
    setCurrentList({
      ...currentList,
      publicly_visible: permission_ !== 'private',
      publicly_editable: permission_ === 'public',
      ...(isCurrentListAssignedToPublic() && permission_ === 'private' && { assignee_id: currentUser?.id }),
    })
  }

  function getCurrentListAssignee(): Backend.UserData {
    const assignee = assigneesList.find((assignee) => currentList.assignee_id === assignee.id)

    if (assignee) {
      return assignee
    }

    return nullAssignee
  }

  function getAssigneesList(): Backend.UserData[] {
    if (currentList.publicly_visible) {
      return assigneesList
    }

    return assigneesList.filter(function (assignee) {
      // differentiate the users available to assign depending on whether creating or updating list
      if (currentList.id === '') {
        return assignee.id === currentList.user_id || assignee.id == '' || assignee.is_current_user
      }

      return assignee.id === currentList.user_id || assignee.id == ''
    })
  }

  function permission(): Permission {
    if (currentList.publicly_editable) {
      return 'public'
    }
    if (currentList.publicly_visible) {
      return 'readonly'
    }
    return 'private'
  }

  return (
    <Dialog className={styles.newList} isOpen={isOpen} onClose={() => onClickCancel()}>
      <FormGroup className={styles.formGroup} label={t('List Name *')} labelFor="text-input" helperText={helperText} intent={intent}>
        <InputGroup value={currentList.name} onChange={onChangeName} intent={intent} />
      </FormGroup>
      <FormGroup className={styles.formGroup} label={t('List Notes')} labelFor="text-input">
        <TextArea
          style={{ minHeight: '127px' }}
          placeholder={t('Type your notes here…')}
          growVertically={true}
          onChange={onChangeNotes}
          value={currentList.notes}
        />
      </FormGroup>
      <RadioGroup
        className={styles.radioGroup}
        label={
          <>
            <div style={{ fontWeight: 600, marginBottom: '3px' }}>{t('Visibility & Editor Access')}</div>
            <span>{t('Define who can view or see this list & who can edit notes, modify visibility, add or remove companies, and assign this list.')}</span>
          </>
        }
        onChange={onVisibilityChange}
        selectedValue={permission()}
      >
        <Radio label={`${t('Everyone in my company can see and edit this list')}`} value="public" />
        <Radio label={`${t('Everyone in my company can see this list, but only I can edit this list')}`} value="readonly" />
        <Radio label={`${t('Only I can see and edit this list')}`} value="private" />
      </RadioGroup>
      <div className={styles.selectGroup}>
        <p>{t('Assign To')}</p>
        <Select
          className={styles.ListFormSelect}
          activeItem={getCurrentListAssignee()}
          items={getAssigneesList()}
          filterable={true}
          itemRenderer={assigneeRenderer}
          itemPredicate={assigneeFilters}
          onItemSelect={onAssigneeSelect}
          popoverProps={{ usePortal: false, captureDismiss: true }}
        >
          <Button text={getCurrentListAssignee().name} rightIcon="caret-down" alignText={'left'} fill={true} />
        </Select>
      </div>
      <div style={{ display: 'flex', gap: '10px', marginTop: '16px' }}>
        <Button intent={'primary'} minimal={true} disabled={isLoading} style={{ marginLeft: 'auto' }} text={t('Cancel')} onClick={() => onClickCancel()} />
        <Button
          intent={currentList.name === '' ? 'none' : 'primary'}
          loading={isLoading}
          disabled={currentList.name === ''}
          text={list === undefined ? t('Create New List') : t('Update List')}
          type={'submit'}
          onClick={onClick_}
        />
      </div>
    </Dialog>
  )
}

export const assigneeFilters: ItemPredicate<Backend.UserData> = (query, assignee) => {
  return queryIndex(assignee.name, query) >= 0
}

export const assigneeRenderer: ItemRenderer<Backend.UserData> = (assignee, { handleClick, modifiers, query }) => {
  if (!modifiers.matchesPredicate) {
    return null
  }
  return <MenuItem active={modifiers.active} key={assignee.id} onClick={handleClick} text={highlightText(assignee.name, query)} />
}

type Permission = 'public' | 'readonly' | 'private'

export default withNamespaces()(ListForm)
