import { useCallback, useEffect } from 'react'

export function useDebouncedEffect(delay: number, effect: Parameters<typeof useCallback>[0], deps: Parameters<typeof useCallback>[1]): void {
  const callback = useCallback(effect, deps)

  useEffect(() => {
    const handler = setTimeout(() => callback(), delay)
    return () => clearTimeout(handler)
  }, [callback, delay])
}
