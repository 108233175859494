import { IconName } from '@blueprintjs/icons'
import React, { FC, useEffect, useState } from 'react'
import { Button, Menu, MenuItem, Popover } from '@blueprintjs/core'
import { GridApi, ICellRendererParams, RowNode } from '@ag-grid-community/core'
import { nameof, switchSingleRating } from '../logic/Helpers'
import { CompanyData } from '../logic/Backend'
import { RatingLabel } from './RatingLabel'
import styles from './UserRatingPicker.module.scss'

export interface UserRatingPickerProps {
  company: CompanyData
  rating?: number
  api?: GridApi
  large?: boolean
}

export const UserRatingPicker: FC<UserRatingPickerProps> = ({ api, company, rating, large }) => {
  if (rating === undefined || rating === null) {
    rating = 4
  }
  const [rp, setRp] = useState(propertiesFromRating(rating)!)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  useEffect(() => {
    setRp(propertiesFromRating(rating, false)!)
  }, [rating])

  function respectCurrentColumnFilter(rating: string) {
    // server-side refresh
    // api?.refreshServerSideStore({})

    // client-side refresh
    const values = api?.getFilterModel()?.[nameof<CompanyData>('rating')]?.values
    if (values?.includes(rating) === false) {
      api!.getSelectedNodes().forEach((node) => {
        let prevRowNode: RowNode
        api!.forEachNode((rowNode) => {
          if (node.data.id === rowNode.data.id) {
            prevRowNode = rowNode
          } else if (prevRowNode) {
            prevRowNode.setData(rowNode.data)
            prevRowNode.setSelected(false)
            prevRowNode = rowNode
          }
        })
      })
    }
  }

  return (
    <Popover isOpen={isPopoverOpen} hasBackdrop={true} onInteraction={(open) => setIsPopoverOpen(open)}>
      <Button
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        small={true}
        minimal={true}
        alignText={'left'}
        className={styles[`color-${rp.backendValue}`]}
        style={{
          fontSize: '10px',
          borderRadius: '3px',
          fontWeight: '600',
          lineHeight: 'initial',
          minHeight: '20px',
          ...(large && { padding: '0', flexDirection: 'column', border: '1px solid ' + (rp.backendValue === 4 ? '#666666' : 'transparent') }),
        }}
        text={
          <RatingLabel
            rp={rp}
            text={rp.label}
            noPadding={true}
            leftIconSize={8}
            rightIconSize={11}
            rightIcon={isPopoverOpen ? 'chevron-up' : 'chevron-down'}
            large={large}
          />
        }
      />
      <Menu style={{ minWidth: '120px', display: 'flex', flexDirection: 'column', gap: '5px' }}>
        {ratingPropertiesArray.map((r, i) => (
          <MenuItem
            key={i}
            shouldDismissPopover={false}
            className={styles[`color-${r.backendValue}`]}
            style={{
              fontSize: '10px',
              fontWeight: '500',
              borderRadius: '3px',
            }}
            onClick={async () => {
              if (r.backendValue === rp?.backendValue) return
              setRp(propertiesFromRating(r.backendValue)!)
              await switchSingleRating(r.backendValue, rp?.backendValue, company.id, company?.name)
              respectCurrentColumnFilter(String(r.backendValue))
            }}
            text={
              <RatingLabel
                rp={r}
                text={r.label}
                rightIcon={r.backendValue === rp.backendValue ? 'tick' : undefined}
                leftIconSize={8}
                rightIconSize={11}
                large={large}
              />
            }
          />
        ))}
      </Menu>
    </Popover>
  )
}

export const UserRatingPickerCellRenderer: FC<ICellRendererParams> = (props) => {
  return <UserRatingPicker api={props.api} company={props.data} rating={props.value} />
}

export class RatingProperties {
  constructor(public backendValue: number, public mlRatingRange: number | undefined, public label: string, public icon?: IconName) {}
}

const ratingPropertiesArray = [
  new RatingProperties(0, 0, 'Love', 'heart'),
  new RatingProperties(1, 1, 'Like', 'clean'),
  new RatingProperties(2, 2, 'Later', 'time'),
  new RatingProperties(3, 3, 'Pass', 'ban-circle'),
  new RatingProperties(4, undefined, 'No Rating', undefined),
]

export function propertiesFromRating(rating?: string | number, mlRating = false): RatingProperties | undefined {
  if (rating === null || rating === undefined) {
    return undefined
  }
  if (typeof rating === 'string') {
    if (rating.includes('-')) {
      mlRating = true
      rating = rating.split('-')[0]
    }
    rating = Number(rating)
  }

  return ratingPropertiesArray.find((p) => rating === p.backendValue)
}
