import React, { useState } from 'react'
import styles from './style.module.scss'
import GridItem from './GridItem'
import { industries, internationalUnits, revenueParser, salesTargetParser } from '../../../../logic/ValueFormatters'
import { LikeButton } from './LikeButton/index'
import noLogo from '../../../../resources/images/unknown.svg'
import { CompanyData } from '../../../../logic/Backend'
import { MultiSelect } from '@blueprintjs/select'
import { useSelector } from 'react-redux'
import { Button, Popover } from '@blueprintjs/core'
import ListForm from '../../../components/ListForm'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { NoResults } from '../../../../stories/NoResults'
import useMultiSelect from '../../../../resources/hooks/useMultiSelect'
import { hrefForId } from '../../../../logic/Routes'
import * as Routes from '../../../../logic/Routes'
import EmployeeCount from './EmployeeCount'
import { CbLiOrPlaceholderImage } from '../CbLiOrPlaceholderImage'

interface Props extends CompanyData {
  companies?: (CompanyData & SimilarCompaniesType)[]
}

// need to pass entire company data, or at least list IDs for similar companies...

export type SimilarCompaniesType = {
  similarities?: string[]
  industries?: string[]
}

const SimilarCompanies = (props: Props & WithNamespaces & IncludeT) => {
  const { companies, t } = props
  const [listPopupState, setListPopupState] = useState<boolean>(false)
  const [companyForPopup, setCompanyForPopup] = useState<CompanyData>()
  const rowLabels = ['Industry', 'Geography', 'Total Funding', 'Revenue Stream', 'Sales Target', 'Employee Count & Growth']

  const lists = useSelector((state: any) => state.listState)

  const handleClick = (company: CompanyData) => {
    setCompanyForPopup(company)
    setListPopupState(!listPopupState)
  }
  const {
    onItemSelect,
    itemPredicateListData,
    selectedItems,
    onQueryChange,
    itemsEqualListData,
    tagRenderer,
    onRemove,
    clearButton,
    itemRenderer,
    newListOnClick,
    newListIsOpen,
    setNewListIsOpen,
  } = useMultiSelect({
    company: companyForPopup,
  })

  return (
    <div className={styles.outerLayer}>
      <div className={styles.gridContainer}>
        {' '}
        <div className={styles.firstColumn}>
          <span className={styles.firstRow}></span>
          {rowLabels.map((label, index) => (
            <span key={label} className={`${styles.rowName} ${index === 0 && styles.industry}`}>
              {label.toUpperCase()}
            </span>
          ))}
        </div>
        {companies?.map((company, index) => {
          return (
            <div className={styles.columnContainer} key={index}>
              <span className={`${styles.firstRow} ${styles.nameContainer}`}>
                <CbLiOrPlaceholderImage
                  logo_id={company?.logo_id}
                  li_logo_url={company?.li_logo_url}
                  style={{ maxHeight: '50px' }}
                  altText={'Logo'}
                  size={50}
                />
                <span className={styles.companyName}>
                  <a href={hrefForId(Routes.company, company.id)}>{company.name}</a>
                </span>{' '}
                <Popover
                  isOpen={listPopupState && companyForPopup == company}
                  position="bottom"
                  onInteraction={(open) => {
                    if (open) {
                      setListPopupState(false)
                    }
                    setListPopupState(open)
                  }}
                >
                  <button className={styles.saveToListButton} onClick={() => handleClick(company)}>
                    Save to List
                  </button>
                  <div className={styles.popoverContent}>
                    <Button
                      style={{ alignSelf: 'flex-start' }}
                      minimal={true}
                      icon={'add'}
                      text={t('Create New List')}
                      intent={'primary'}
                      onClick={() => setNewListIsOpen(true)}
                    />
                    <MultiSelect
                      fill={true}
                      className={styles.subContextMenu}
                      items={lists}
                      selectedItems={selectedItems}
                      itemRenderer={itemRenderer}
                      itemPredicate={itemPredicateListData}
                      onQueryChange={onQueryChange}
                      itemsEqual={itemsEqualListData}
                      tagRenderer={tagRenderer}
                      placeholder={'Click or type here to see existing lists.'}
                      noResults={<NoResults />}
                      onItemSelect={onItemSelect}
                      resetOnQuery={false}
                      popoverProps={{ minimal: true, boundary: 'viewport', popoverClassName: 'max-width', usePortal: false }}
                      tagInputProps={{
                        onRemove: onRemove,
                        rightElement: clearButton,
                      }}
                    />
                    {newListIsOpen && <ListForm isOpen={newListIsOpen} onClick={newListOnClick} onClickCancel={() => setNewListIsOpen(false)} />}
                  </div>
                </Popover>
                <span className={styles.sharesSimilarCompaniesWith}>
                  <span>
                    Shares similar <b>{industries(company.similarities)}</b>
                  </span>
                </span>
              </span>
              <GridItem className={styles.industryTag} industryTag data={company.industries} />
              <GridItem className={styles.gridItem} data={company.region} />
              <GridItem className={styles.gridItem} data={internationalUnits(company.total_funding_usd_bigint, 0)} />
              <GridItem className={styles.gridItem} data={revenueParser(company.revenue_stream_type)} />
              <GridItem className={styles.gridItem} data={salesTargetParser(company.sales_target_type)} />
              <GridItem
                className={styles.gridItem}
                data={
                  company.li_employee_count_integer && (
                    <EmployeeCount
                      companyId={company.id}
                      index={index}
                      employeeCount={company.li_employee_count_integer}
                      employeeGrowth={company.li_employee_count_mom_decimal}
                    />
                  )
                }
              />
              {/* <div className={styles.buttonContainer}>
                <LikeButton buttonType={'thumbs-up'} />
                <LikeButton buttonType={'thumbs-down'} />
              </div> */}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default withNamespaces()(SimilarCompanies)
