import React, { useEffect, useState } from 'react'
import '../styles/companyStatusDropDown.scss'
import { Icon, Button, Menu, MenuItem, Popover, Position } from '@blueprintjs/core'
import { GridApi } from '@ag-grid-community/core'
import { updatePipelineData } from '../../../../logic/Backend'
import { t } from 'i18next'

type StatusOption = {
  value: string
  index: number
  label: string
  backgroundColor: string
  color: string
  borderColor: string
}

type CompanyStatusDropDownProps = {
  organization_id: string
  pipeline_status?: number
  api?: GridApi
}

export const STATUS_OPTIONS: StatusOption[] = [
  { value: 'new', index: 0, label: t('New'), backgroundColor: '#D75AEC1F', color: '#D75AEC', borderColor: '#D75AEC' },
  { value: 'active', index: 1, label: t('Active'), backgroundColor: '#85B8571F', color: '#85B857', borderColor: '#85B857' },
  { value: 'diligence', index: 2, label: t('Diligence'), backgroundColor: '#0D75FF1F', color: '#0D75FF', borderColor: '#0D75FF' },
  { value: 'won', index: 3, label: t('Won'), backgroundColor: '#FF5EC41F', color: '#FF5EC4', borderColor: '#FF5EC4' },
  { value: 'missed', index: 4, label: t('Missed'), backgroundColor: '#A906361F', color: '#A90636', borderColor: '#A90636' },
  { value: 'passed', index: 5, label: t('Passed'), backgroundColor: '#3400591F', color: '#340059', borderColor: '#340059' },
  { value: 'no_status', index: 6, label: t('No Status'), backgroundColor: '#4848492E', color: '#484849', borderColor: '#484849' },
]

const CompanyStatusDropDown: React.FC<CompanyStatusDropDownProps> = ({ organization_id, pipeline_status, api }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const findInitialStatus = () => {
    const currentStatusOption = STATUS_OPTIONS.find((option) => option.index === pipeline_status)
    return currentStatusOption || STATUS_OPTIONS[6]
  }
  const [selectedStatus, setSelectedStatus] = useState<StatusOption>(findInitialStatus)

  useEffect(() => {
    const currentStatusOption = findInitialStatus()
    setSelectedStatus(currentStatusOption)
  }, [organization_id])

  const handleStatusChange = async (option) => {
    setSelectedStatus(option)

    const statusToUpdate = option.value === 'no_status' ? null : option.value

    try {
      updatePipelineData({ organization_ids: [organization_id], pipeline_status: statusToUpdate })
    } catch (error) {
      console.error('Error updating card status:', error)
    } finally {
      // force refresh server side for RightSidebar `getSelectedRows` function to receive updated data
      // updateData param needs to be a new object
      if (api) {
        const node = api.getSelectedNodes()?.[0]
        node && node.updateData({ ...node.data, pipeline_status: statusToUpdate })
      }
    }
    setIsPopoverOpen(false)
  }

  return (
    <Popover
      isOpen={isPopoverOpen}
      onInteraction={(state) => {
        setIsPopoverOpen(state)
      }}
      position={Position.BOTTOM}
      content={
        <Menu>
          {STATUS_OPTIONS.map((option) => (
            <MenuItem
              className="custom-menu-item"
              key={option.value}
              text={
                <div style={{ display: 'flex', position: 'relative', width: '100%', justifyContent: 'center' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '4px', height: '20px' }}>{option.label}</div>
                  {selectedStatus.value === option.value && <Icon icon="tick" style={{ position: 'absolute', right: 0, top: 2 }} />}
                </div>
              }
              onClick={() => handleStatusChange(option)}
              style={{
                backgroundColor: option.backgroundColor,
                color: option.color,
                borderColor: option.borderColor,
                borderWidth: '1px',
                borderStyle: 'solid',
                fontSize: '10px',
                margin: '3px',
                minWidth: '10rem',
                borderRadius: 5,
                display: 'flex',
                justifyContent: 'center',
              }}
            />
          ))}
        </Menu>
      }
    >
      <Button
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        minimal={true}
        rightIcon="caret-down"
        text={selectedStatus.label}
        style={{
          color: selectedStatus.color,
          backgroundColor: selectedStatus.backgroundColor,
          borderColor: selectedStatus.borderColor,
          borderRadius: '20px',
          fontSize: '10px',
          fontWeight: '500',
          height: '20px',
          width: '100px',
        }}
      />
    </Popover>
  )
}

export default CompanyStatusDropDown
