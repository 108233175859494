import { IServerSideGetRowsRequest } from '@ag-grid-community/core'
import * as Routes from './Routes'
import { showErrorToast } from './Toaster'
import ahoy, { EventProperties } from 'ahoy.js'
import { FilterModel } from '../ui/components/Filters'
import { routerHistory } from '../ui/app/App'
import { SimilarCompaniesType } from '../ui/pages/Company/SimilarCompanies'
import { QuickSearchCompaniesType } from '../ui/pages/Dashboard/SearchBar'

export interface Indexed {
  id: string
}

export interface CompanyData extends Indexed {
  address?: string
  industries?: string[]
  city?: string
  country_iso_3166_1_alpha_3?: string
  description?: string
  domain?: string
  emails?: string
  employee_count_int4range?: string
  exit_count_integer?: number
  facebook_url?: string
  founded_on_date?: string
  funding_round_count_integer?: number
  homepage?: string
  investors?: string
  latest_funding_amount_bigint?: number
  latest_funding_on_date?: string
  latest_funding_type?: string
  linkedin_url?: string
  list_ids?: ListData['id'][]
  name?: string
  net_income_bigint_usd?: number
  operating_income_bigint_usd?: number
  phones?: string
  postal_code?: string
  rating?: number
  revenue_bigint_usd?: number
  short_description?: string
  state?: string
  status_enum?: string
  total_assets_bigint_usd?: number
  total_funding_usd_bigint?: number
  twitter_url?: string
  logo_id?: string
  website_screenshot_id?: string
  ig_biography?: string
  ig_business_category_name?: string
  ig_external_url?: string
  ig_full_name?: string
  ig_followers_count_integer?: number
  ig_following_count_integer?: number
  ig_is_verified?: boolean
  ig_latest_9_posts_daterange?: string
  ig_latest_9_posts_max_likes?: number
  ig_latest_9_posts_max_likes_date?: string
  ig_latest_9_posts_max_comments?: number
  ig_latest_9_posts_max_comments_date?: string
  ig_posts_count_integer?: number
  ig_profile_pic_url_hd?: string
  ig_username?: string
  instagram_url?: string
  pinterest_url?: string
  tech_spent_bigint_usd?: number
  bw_revenue_usd_bigint?: number
  li_logo_url?: string
  li_name?: string
  li_type_enum?: number
  li_followers_count_integer?: number
  li_employee_count_integer?: number
  li_employee_list?: Array<LinkedinEmployeeData>
  people?: Array<EmployeeData>
  bw_revenue_mom_decimal?: string
  li_employee_count_mom_decimal?: string
  li_followers_count_mom_decimal?: string
  ig_followers_count_mom_decimal?: string
  ml_rating?: string
  bw_revenue_latest_6_months?: (number | [number, string])[]
  li_employee_count_latest_6_months?: (number | [number, string])[]
  li_followers_count_latest_6_months?: (number | [number, string])[]
  ig_followers_count_latest_6_months?: (number | [number, string])[]
  insights?: string
  sales_target_type?: 'unknown' | 'b2b' | 'b2c' | 'b2b_and_b2c'
  revenue_stream_type?: 'non_recurring' | 'recurring'
  industry?: string[]
  industry_groups?: string[]
  sub_industries?: string[]
  region?: string
  sub_region?: string
  is_new?: boolean
  pipeline_status?: number
  pipeline_cards?: any
  notes?: any
  name_jp?: string
  description_jp?: string
  short_description_jp?: string
  industry_tag?: string
  tags?: string[]
  growth?: number
  new_employee?: number
  recommended_score?: number
  created_at?: string
  visited_at?: string
  employee_growth?: string
  source?: string
  hot_score?: number
}

export interface LinkedinEmployeeData {
  name: string
  role?: string
  profile_url?: string
  profile_picture_url?: string
}

export interface EmployeeData {
  name: string
  role?: string
  description?: string
  email?: string
  linkedin_url?: string
  facebook_url?: string
  twitter_url?: string
  li_profile_pic_url?: string
  cb_profile_pic_url?: string
  jobs?: Array<JobData>
  degrees?: Array<DegreeData>
}

export interface JobData {
  title?: string
  started_on?: string
  ended_on?: string
  is_current?: boolean
  organization_name?: CompanyData['name']
  organization_logo_id?: CompanyData['logo_id']
  organization_li_logo_url?: CompanyData['li_logo_url']
}

export interface DegreeData {
  title?: string
  degree_type?: string
  started_on?: string
  completed_on?: string
  is_completed?: boolean
  organization_name?: CompanyData['name']
  organization_logo_id?: CompanyData['logo_id']
  organization_li_logo_url?: CompanyData['li_logo_url']
}

export interface ListData extends Indexed {
  name?: string
  notes?: string
  user_id?: UserData['id']
  assignee_id?: UserData['id']
  publicly_editable?: boolean
  publicly_visible?: boolean
  belongs_to_current_user?: boolean
  assigned_to_current_user?: boolean
  editable_by_current_user?: boolean
  created_at?: string
  updated_at?: string
  organizations?: CompanyData[]
}

export interface ListsPageData extends ListData {
  total_organizations?: number
  progress?: string
}

export interface ListPageData {
  list: ListsPageData
  organizations: ResponseForAgGrid<CompanyData>
}

export interface SavedFilterData extends Indexed {
  title?: string
  filter_model?: FilterModel
  active_alert?: boolean
}

export interface DeleteSavedFilterRequest {
  id: SavedFilterData['id']
}

export interface ResponseForAgGrid<T> {
  rows: T[]
  rowsCount: number
  exactCount: boolean
  lastPage: boolean
}

export interface UserData extends Indexed {
  email: string
  name: string
  created_at?: string
  customer?: string
  is_current_user?: boolean
  onboarding_progress?: 'first-time' | 'first-ip' | 'first-ip-done'
}

export interface LoginData {
  logged_in: boolean
}

export interface LoginRequest {
  email: UserData['email']
  password: string
}

export interface PasswordResetRequest {
  email: UserData['email']
  newPassword: string
  token: string
}

export interface SignupRequest extends LoginRequest {
  name: UserData['name']
  companyName: string
  firmType: number
  signupOrigin?: 'webapp'
}

export interface StripeUrlResponse {
  stripe_url: string
}

export interface StripePortalRequest {
  return_url: string
}

export interface ListRequest {
  id?: ListData['id']
  name?: ListData['name']
  notes?: ListData['notes']
  publicly_editable?: ListData['publicly_editable']
  publicly_visible?: ListData['publicly_visible']
}

export interface DeleteListsRequest {
  list_ids: ListData['id'][]
}

export interface UpdateRatingsRequest {
  rating: number | undefined
  organization_ids: CompanyData['id'][]
}

export interface SortModel {
  [key: string]: unknown
}

export interface QuotaInfo {
  quota: number
  consumed_quota: number
}

export interface AgGridState {
  filterModel?: FilterModel
  sortModel?: SortModel[]
}

export interface ExportCsvRequest extends AgGridState {
  columns: string[]
  header_names: string[]
  organization_ids?: CompanyData['id'][]
}

export interface BatchSaveToListsRequest extends AgGridState {
  list_id: ListData['id']
}

export interface BatchDeleteListsRequest extends AgGridState {
  list_ids: ListData['id'][]
}

export interface BatchAddRateRequest extends AgGridState {
  rating: number | undefined
}

export interface AddListingsRequest {
  list_id: ListData['id']
  organization_ids: CompanyData['id'][]
}

export interface DeleteListingsRequest {
  list_ids: ListData['id'][]
  organization_ids: CompanyData['id'][]
}

export interface AgGridRequest extends IServerSideGetRowsRequest {
  listId?: ListData['id']
}

export interface InvestmentProfileData extends Indexed, NewInvestmentProfileRequest {
  status?: 'training' | 'trained' | 'predicting' | 'predicted'
  is_active?: boolean
}

export interface NewInvestmentProfileRequest {
  name: string
  total_funding_usd_int8range_weight?: number
  employee_count_int4range_weight?: number
  founded_on_int4range_weight?: number
  industries_weight?: number
  countries_weight?: number
  funding_types_weight?: number
  total_funding_usd_int8range?: string
  employee_count_int4range?: string
  founded_on_int4range?: string
  industries?: number[]
  countries?: string[]
  funding_types?: string[]
}

export interface SetActiveInvestmentProfile {
  id: InvestmentProfileData['id']
}

export interface PipelineData {
  id: string
  status: Status
  customer_id: string
  organization_id: string
  created_at: string
  updated_at: string
  organization_logo: string
  organization_name: string
  user_name: string
  pipeline_status: number | null
}

export type Status = 'new' | 'active' | 'diligence' | 'won' | 'missed' | 'passed'
export interface UpdatePipelineStatus {
  organization_ids: string[]
  pipeline_status?: number
}

export type NewsType = {
  author?: string
  candidates?: string
  content: NewsContentType
  created_at?: string
  headline?: string
  id?: string
  published_date?: string
  source?: string
  title?: string
  url?: string
  status?: string
  updated_at?: string
}

export type NewsContentType = {
  article?: string
  categories?: string
  keywords?: string
  type?: string
}
export interface Note {
  id: string
  description: string
  userId: string
  created_at: string
  updated_at: string
  isPrivate: boolean
  organization_id: string
}

export type GetQuicksearchCompaniesType = {
  query: string
  from?: number
  limit?: number
}

export interface INewsItem {
  id: string
  title_en?: string
  title_jp?: string
  summary_en?: string
  summary_jp?: string
  published_date: string
  author_id: number
  company_name?: string
  company_id?: string
  company_logo_id?: string
  url?: string
}

export type SessionType = {
  title: string
  id: string
  created_at: string
}

export type ChatHistoryType = {
  answer: string
  question: string
  created_at: string
}
export type ActivityType = {
  id: string
  user_name: string
  title: string
  body: string
  title_jp?: string
  body_jp?: string
}

function request<RequestT>(verb: HttpVerb, body?: RequestT, abortSignal?: AbortSignal): RequestInit {
  const base: RequestInit = {
    method: verb,
    credentials: 'include',
    signal: abortSignal,
  }
  if (body === undefined) {
    return base
  }
  return {
    ...base,
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    body: JSON.stringify(body),
  }
}

async function callBackend<RequestT, ResponseT>(
  verb: HttpVerb,
  path: string,
  successStatus: number,
  body: RequestT | undefined,
  successFn?: (response: Response) => ResponseT,
  abortSignal?: AbortSignal
): Promise<ResponseT | undefined> {
  let response
  try {
    response = await fetch(process.env.REACT_APP_BACKEND_URL + path, request<RequestT>(verb, body, abortSignal))
  } catch (error: any) {
    if (error.name !== 'AbortError') {
      showErrorToast('Can’t reach the server. Are you online?')
    }
    throw error
  }
  if (response.status === successStatus) {
    return successFn?.(response)
  }
  if (response.status === 401) {
    routerHistory.push(Routes.login.href, { from: location.pathname })
    return
  }
  throw Error(`Network error. Response status: ${response.status} ${response.statusText}`)
}

function startFileDownload(path: string, params: ExportCsvRequest): void {
  const iframe = document.createElement('iframe')
  iframe.name = 'download_iframe'
  const form = document.createElement('form')
  form.setAttribute('method', 'POST')
  form.setAttribute('target', iframe.name)
  form.setAttribute('action', process.env.REACT_APP_BACKEND_URL + path)
  const hiddenField = document.createElement('input')
  hiddenField.setAttribute('type', 'hidden')
  hiddenField.setAttribute('name', 'body')
  hiddenField.setAttribute('value', JSON.stringify(params))
  form.appendChild(hiddenField)
  document.body.appendChild(form)
  form.submit()
}

export const companiesPage: BackendCall<AgGridRequest, ResponseForAgGrid<CompanyData>> = async (body, abortSignal) => {
  return await callBackend('post', 'companies-all', 200, body, (r) => r.json(), abortSignal)
}

export const companiesExactCount: BackendCall<AgGridRequest, ResponseForAgGrid<CompanyData>> = async (body, abortSignal) => {
  return await callBackend('post', 'companies-count', 200, body, (r) => r.json(), abortSignal)
}

export const companyPage: BackendCall<CompanyData['id'], CompanyData> = async (id, abortSignal) => {
  return await callBackend('get', `companies/${id}`, 200, undefined, (r) => r.json(), abortSignal)
}

export const listsPage: BackendCall<AgGridRequest, ResponseForAgGrid<ListsPageData>> = async (body, abortSignal) => {
  return await callBackend('post', 'lists-all', 200, body, (r) => r.json(), abortSignal)
}

export const listPage: BackendCall<ListsPageData['id'], ListsPageData> = async (id, abortSignal) => {
  return await callBackend('get', `lists/${id}`, 200, undefined, (r) => r.json(), abortSignal)
}

export const newList: BackendCall<ListRequest, ListData> = async (body, abortSignal) => {
  ahoy.track('newList', body as EventProperties)
  return await callBackend('post', 'lists', 201, body, (r) => r.json(), abortSignal)
}

export const editList: BackendCall<ListRequest, ListData> = async (body, abortSignal) => {
  ahoy.track('editList', body as EventProperties)
  return await callBackend('put', `lists/${body!.id}`, 200, body, (r) => r.json(), abortSignal)
}

export const deleteLists: BackendCall<DeleteListsRequest, undefined> = async (body, abortSignal) => {
  ahoy.track('deleteLists', body as unknown as EventProperties)
  return await callBackend('delete', 'lists', 200, body, undefined, abortSignal)
}

export const lists: BackendCall<undefined, ListData[]> = async (_, abortSignal) => {
  return await callBackend('get', 'lists-simple', 200, undefined, (r) => r.json(), abortSignal)
}

export const signup: BackendCall<SignupRequest, StripeUrlResponse> = async (body, abortSignal) => {
  return await callBackend('post', 'signup', 201, body, (r) => r.json(), abortSignal)
}

export const login: BackendCall<LoginRequest, UserData | StripeUrlResponse> = async (body, abortSignal) => {
  return await callBackend('post', 'login', 201, body, (r) => r.json(), abortSignal)
}

export const requestPasswordReset: BackendCall<{ email: UserData['email'] }, undefined> = async (body, abortSignal) => {
  return await callBackend('post', 'request_password_reset', 200, body, undefined, abortSignal)
}

export const resetPassword: BackendCall<PasswordResetRequest, undefined> = async (body, abortSignal) => {
  return await callBackend('post', 'reset_password', 200, body, undefined, abortSignal)
}

export const logout: BackendCall<undefined, undefined> = async (_, abortSignal) => {
  return await callBackend('delete', 'logout', 200, undefined, undefined, abortSignal)
}

export const currentUser: BackendCall<undefined, UserData> = async (body, abortSignal) => {
  return await callBackend('get', 'users/current', 200, undefined, (r) => r.json(), abortSignal)
}

export const users: BackendCall<undefined, UserData[]> = async (_, abortSignal) => {
  return await callBackend('get', 'users', 200, undefined, (r) => r.json(), abortSignal)
}

export const onboardingProgress: BackendCall<{ onboarding_progress: UserData['onboarding_progress'] }, undefined> = async (body, abortSignal) => {
  return await callBackend('post', 'onboarding_progress', 200, body, undefined, abortSignal)
}

export const updateRatings: BackendCall<UpdateRatingsRequest, undefined> = async (body, abortSignal) => {
  ahoy.track('updateRatings', body as unknown as EventProperties)
  return await callBackend('put', 'ratings', 200, body, undefined, abortSignal)
}

export const batchAddRate: BackendCall<BatchAddRateRequest, undefined> = async (body, abortSignal) => {
  ahoy.track('batchAddRate', body as unknown as EventProperties)
  return await callBackend('put', 'ratings/batch_update', 200, body, undefined, abortSignal)
}

export const addListings: BackendCall<AddListingsRequest, undefined> = async (body, abortSignal) => {
  ahoy.track('addListings', body as unknown as EventProperties)
  return await callBackend('post', 'listings', 200, body, undefined, abortSignal)
}

export const batchSaveToLists: BackendCall<BatchSaveToListsRequest, undefined> = async (body) => {
  ahoy.track('batchSaveToLists', body as unknown as EventProperties)
  return await callBackend('put', 'listings/batch_create', 200, body)
}

export const deleteListings: BackendCall<DeleteListingsRequest, undefined> = async (body, abortSignal) => {
  ahoy.track('deleteListings', body as unknown as EventProperties)
  return await callBackend('delete', 'listings', 200, body, undefined, abortSignal)
}

export const batchDeleteLists: BackendCall<BatchDeleteListsRequest, undefined> = async (body) => {
  ahoy.track('batchDeleteLists', body as unknown as EventProperties)
  return await callBackend('put', 'listings/batch_destroy', 200, body)
}

export const fetchExportQuota: BackendCall<undefined, QuotaInfo> = async (_, abortSignal) => {
  return await callBackend('get', 'companies/fetch_export_quota', 200, undefined, (r) => r.json(), abortSignal)
}

export function exportCsv(body: ExportCsvRequest): void {
  ahoy.track('organization_ids' in body ? 'exportCsv' : 'batchExportCsv', body as unknown as EventProperties)
  startFileDownload('companies/export.csv', body)
}

export const createSavedFilter: BackendCall<SavedFilterData, SavedFilterData> = async (body, abortSignal) => {
  ahoy.track('createSavedFilter', body as unknown as EventProperties)
  return await callBackend('post', 'saved_filters', 200, body, (r) => r.json(), abortSignal)
}

export const updateSavedFilter: BackendCall<SavedFilterData, SavedFilterData> = async (body, abortSignal) => {
  ahoy.track('updateSavedFilter', body as unknown as EventProperties)
  return await callBackend('put', 'saved_filters/update', 200, body, (r) => r.json(), abortSignal)
}

export const listSavedFilters: BackendCall<undefined, SavedFilterData[]> = async (_, abortSignal) => {
  return await callBackend('get', 'saved_filters', 200, undefined, (r) => r.json(), abortSignal)
}

export const deleteSavedFilter: BackendCall<DeleteSavedFilterRequest, undefined> = async (body, abortSignal) => {
  ahoy.track('deleteSavedFilter', body as unknown as EventProperties)
  return await callBackend('delete', 'saved_filters/destroy', 200, body, undefined, abortSignal)
}

export const investmentProfiles: BackendCall<undefined, InvestmentProfileData[]> = async (_, abortSignal) => {
  return await callBackend('get', 'investment_profiles', 200, undefined, (r) => r.json(), abortSignal)
}

export const newInvestmentProfile: BackendCall<NewInvestmentProfileRequest, undefined> = async (body, abortSignal) => {
  ahoy.track('newInvestmentProfile', body as unknown as EventProperties)
  return await callBackend('post', 'investment_profiles', 200, body, undefined, abortSignal)
}

export const setActiveInvestmentProfile: BackendCall<SetActiveInvestmentProfile, undefined> = async (body, abortSignal) => {
  ahoy.track('setActiveInvestmentProfile', body as unknown as EventProperties)
  return await callBackend('put', 'investment_profiles/set_active', 200, body, undefined, abortSignal)
}

export const deleteInvestmentProfile: BackendCall<InvestmentProfileData['id'], undefined> = async (id, abortSignal) => {
  ahoy.track('deleteInvestmentProfile', { id })
  return await callBackend('delete', `investment_profiles/${id}`, 200, undefined, undefined, abortSignal)
}

export const editInvestmentProfile: BackendCall<InvestmentProfileData, undefined> = async (body, abortSignal) => {
  ahoy.track('editInvestmentProfile', body as unknown as EventProperties)
  return await callBackend('put', `investment_profiles/${body!.id}`, 200, body, undefined, abortSignal)
}

export const stripePortal: BackendCall<StripePortalRequest, StripeUrlResponse> = async (body, abortSignal) => {
  return await callBackend('post', 'stripe_portal', 201, body, (r) => r.json(), abortSignal)
}

export const similarCompanies: BackendCall<CompanyData['id'], (SimilarCompaniesType & CompanyData)[]> = async (id, abortSignal) => {
  return await callBackend('get', `companies/${id}/similar_companies`, 200, undefined, (r) => r.json(), abortSignal)
}

export const nestedIndustries: BackendCall<CompanyData['id'], (SimilarCompaniesType & CompanyData)[]> = async (id, abortSignal) => {
  return await callBackend('get', `industries/index`, 200, undefined, (r) => r.json(), abortSignal)
}

export const getPipelineData: BackendCall<unknown, PipelineData[]> = async (_, abortSignal) => {
  return await callBackend('post', 'pipeline', 200, undefined, (r) => r.json(), abortSignal)
}

export const updatePipelineData: BackendCall<{ organization_ids: CompanyData['id'][]; pipeline_status: string }, undefined> = async (body, abortSignal) => {
  return await callBackend('put', 'pipeline_cards', 200, body, undefined, abortSignal)
}
export const companyNotes: BackendCall<CompanyData['id'], Note[]> = async (companyId, abortSignal) => {
  return await callBackend('get', `/notes?company_id=${companyId}`, 200, undefined, (r) => r.json(), abortSignal)
}

export const createNote: BackendCall<{ note: { description: string; private: boolean; organization_id: string } }, Note> = async (body, abortSignal) => {
  return await callBackend('post', 'notes', 201, body, (r) => r.json(), abortSignal)
}

export const updateNote: BackendCall<{ note: { description: string; private: boolean }; noteId: string }, Note> = async ({ note, noteId }, abortSignal) => {
  return await callBackend('put', `notes/${noteId}`, 200, { note }, (r) => r.json(), abortSignal)
}

export const getNews: BackendCall<string, any> = async (id) => {
  return await callBackend('get', `news_articles?organization_id=${id}`, 200, undefined, (r) => r.json())
}
export const getTeamActivity: BackendCall<unknown, { activities: ActivityType[]; from: number; to: number; total_count: number }> = async (_, abortSignal) => {
  // to do: change from hardcode from/to to dynamic
  return await callBackend('get', 'team_activity?from=0&limit=51', 200, undefined, (r) => r.json(), abortSignal)
}

export const getNewlyDiscoveredCompanies: BackendCall<
  { from: number; limit: number },
  { companies: CompanyData[]; from: number; to: number; total_count: number }
> = async ({ from, limit }, abortSignal) => {
  return await callBackend('get', `newly_discovered?from=${from}&limit=${limit}`, 200, undefined, (r) => r.json(), abortSignal)
}

export const getHotCompanies: BackendCall<
  { from: number; limit: number },
  { companies: CompanyData[]; from: number; to: number; total_count: number }
> = async ({ from, limit }, abortSignal) => {
  return await callBackend('get', `hot_companies?from=${from}&limit=${limit}`, 200, undefined, (r) => r.json(), abortSignal)
}

export const getRecommendedCompanies: BackendCall<
  { from: number; limit: number },
  { companies: CompanyData[]; from: number; to: number; total_count: number }
> = async ({ from, limit }, abortSignal) => {
  return await callBackend('get', `recommended?from=${from}&limit=${limit}`, 200, undefined, (r) => r.json(), abortSignal)
}

export const getLastVisitedCompanies: BackendCall<
  { from: number; limit: number },
  { companies: CompanyData[]; from: number; to: number; total_count: number }
> = async ({ from, limit }, abortSignal) => {
  return await callBackend('get', `last_visited?from=${from}&limit=${limit}`, 200, undefined, (r) => r.json(), abortSignal)
}

export const getQuickSearch: BackendCall<
  GetQuicksearchCompaniesType,
  { companies: QuickSearchCompaniesType[]; from: number; limit: number; total_count: number }
> = async ({ query, from, limit }, abortSignal) => {
  return await callBackend('get', `quick_search?query=${query}&from=${from}&limit=${limit}`, 200, undefined, (r) => r.json(), abortSignal)
}

export const getNewsFilters: BackendCall<undefined, { industry: string[]; theme: string[]; article_type: string[]; geography: string[] }> = async (
  _,
  abortSignal
) => {
  return await callBackend('get', 'news_articles/filters', 200, undefined, (r) => r.json(), abortSignal)
}

export const getLatestNews: BackendCall<
  { filters?: { geography?: string; theme?: string; industries?: string; article_type?: string } },
  { news: any[]; from: number; to: number; total_count: number }
> = async (body, abortSignal) => {
  const queryParams = new URLSearchParams()

  if (body?.filters) {
    // Construct the nested 'filters' object
    Object.entries(body.filters).forEach(([key, value]) => {
      if (value) {
        queryParams.append(`filters[${key}]`, value) // Use array-like syntax to build nested filters
      }
    })
  }

  return await callBackend('get', `latest_news?${queryParams.toString()}`, 200, undefined, (r) => r.json(), abortSignal)
}

export const getSearchSessions: BackendCall<undefined, { sessions: SessionType[] }> = async (_, abortSignal) => {
  return await callBackend('get', 'ai_search_sessions', 200, undefined, (r) => r.json(), abortSignal)
}

export const getSessionConversation: BackendCall<{ id: string }, { chats: ChatHistoryType[]; id: string; title?: string }> = async ({ id }, abortSignal) => {
  return await callBackend('get', `ai_search_sessions/${id}`, 200, undefined, (r) => r.json(), abortSignal)
}

export type BackendCall<RequestT, ResponseT> = (body?: RequestT, abortSignal?: AbortSignal) => Promise<ResponseT>

type HttpVerb = 'get' | 'post' | 'put' | 'delete'

//PIPEILNE REQUESTS

const statusPipelineMappings: { [key: string]: number } = {
  new: 0,
  active: 1,
  diligence: 2,
  won: 3,
  missed: 4,
  passed: 5,
}

export const mapStatusToEnum = (status: string): number => {
  return statusPipelineMappings[status] || 0
}
