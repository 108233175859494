import React, { useEffect, useRef, useState } from 'react'
import Autosuggest from 'react-autosuggest'
import * as theme from './theme.module.scss'
import { CbLiOrPlaceholderImage } from '../../Company/CbLiOrPlaceholderImage'
import { useHistory } from 'react-router-dom'
import { List } from 'react-content-loader'
import { QuickSearchCompaniesType } from '.'
import styles from './styles.module.scss'
import CustomSpinner from '../../../components/CustomSpinner'
import { Icon } from '@blueprintjs/core'
import Logo from '../../Dashboard/components/logo192.png'

interface Props {
  getQuickSearchSuggestions: (arg: string) => void
  searchResultCompanies?: QuickSearchCompaniesType[]
  loading: boolean
  setFrom: React.Dispatch<React.SetStateAction<number>>
  noReturnedCompanies: boolean
  sendMessage: (msg: string) => void
  onEnter: () => void
}

const getSuggestionValue = (suggestion) => suggestion.name

const AutoSuggest = ({ getQuickSearchSuggestions, searchResultCompanies, loading, setFrom, noReturnedCompanies, sendMessage, onEnter }: Props) => {
  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [open, setOpen] = useState(false)
  const history = useHistory()
  const isFetching = useRef(false) // Add a ref to track fetching state
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    setSuggestions(searchResultCompanies)
  }, [searchResultCompanies])

  const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    const isAtBottom = scrollHeight - Math.ceil(scrollTop) === clientHeight
    if (isAtBottom && !isFetching.current) {
      isFetching.current = true
      open && setFrom((prevState: number) => prevState + 10)

      // Re-enable the scroll event listener after a short delay, may need to come up with a more sophisticated measure of re-enabling the scroll
      setTimeout(() => {
        isFetching.current = false
      }, 1000)
    }
  }

  useEffect(() => {
    const container = containerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll as any)
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll as any)
      }
    }
  }, [])

  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return noReturnedCompanies ? (
      <div {...containerProps} className={open ? theme.suggestionContainer : theme.closedSuggestionDropdown}>
        <div className={theme.noCompaniesReturned}>
          No companies found. Press <b>Enter</b> to initiate an AI Response.
          {loading && (
            <div className={theme.loadingSpinner}>
              <CustomSpinner />
            </div>
          )}
        </div>
      </div>
    ) : (
      <div
        {...containerProps}
        className={loading ? theme.suggestionContainer : open ? theme.suggestionContainer : theme.closedSuggestionDropdown}
        onScroll={handleScroll}
      >
        {loading && !suggestions[0] ? (
          <div className={theme.renderSuggestion}>
            {' '}
            <List height={50} width={300} />
          </div>
        ) : (
          <div>
            {children}
            {loading && (
              <div className={theme.loadingSpinner}>
                <CustomSpinner />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  const renderSuggestion = (suggestion) => {
    value !== '' && setOpen(true)
    return (
      <div
        className={theme.renderSuggestion}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          history.push(`/companies/${suggestion.id}`)
        }}
      >
        <CbLiOrPlaceholderImage logo_id={suggestion.logo_id} altText={'Logo'} size={30} />
        <div className={theme.flexCol}>
          <div className={theme.flexRow}>
            <span className={theme.cutText}>{suggestion.name}</span> <span className={theme.companyDomain}>({suggestion.domain})</span>
          </div>
          {suggestion.industry_tag && <span className={theme.tags}>{suggestion.industry_tag}</span>}
        </div>
      </div>
    )
  }

  const onChange = (event, { newValue }) => {
    getQuickSearchSuggestions(newValue)
    setValue(newValue)
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(searchResultCompanies)
  }

  const onSuggestionsClearRequested = () => {
    setOpen(false)
    // setSuggestions([])
    // setAutosuggestItems([])
  }

  const inputProps = {
    placeholder: 'Startups, news, or trends',
    value,
    onChange: onChange,
    className: theme.input,
    onKeyDown: (event) => {
      if (event.keyCode === 13) {
        event.preventDefault()
        sendMessage(value)
        onEnter()
      }
    },
    onClick: () => noReturnedCompanies && setOpen(true),
  }
  const containerProps = {
    className: theme.autosuggestContainer,
  }

  return (
    <div className={styles.relativeContainer}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        focusInputOnSuggestionClick={false}
        inputProps={inputProps}
        containerProps={containerProps}
        theme={theme}
      />
      <div className={styles.autosuggestOverlayLeft}>
        <img className={`${styles.absoluteElement} ${styles.chatIcon}`} src={Logo} />
      </div>
      <div className={styles.autosuggestOverlayRight}>
        {' '}
        <button className={styles.absoluteButton} onClick={() => value && sendMessage(value)}>
          <Icon icon="arrow-right" />
        </button>
      </div>
    </div>
  )
}

export default AutoSuggest
