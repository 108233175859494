import React, { CSSProperties, FC, ReactNode } from 'react'
import { TruncatableText } from '../../../stories/TruncatableText'

export interface CompanyDataPointProps {
  label: string
  labelSize?: 'small' | 'large'
  alignment?: 'left' | 'center'
  style?: CSSProperties
  value?: string
  children?: ReactNode
}

export const CompanyDataPoint: FC<CompanyDataPointProps> = ({ label, labelSize, alignment, style, value, children }) => {
  return (
    <div
      style={{
        ...(labelSize === 'large' && { height: '57px' }),
        ...(alignment === 'center' && { textAlign: 'center' }),
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        gap: '4px',
        ...style,
      }}
    >
      <TruncatableText text={label} className={'caption'} />
      {children ? children : <TruncatableText text={value} style={{ color: 'black', fontSize: labelSize === 'large' ? '20px' : '14px' }} />}
    </div>
  )
}
