import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import styles from './style.module.scss'
import NewsItem from './NewsItem'
import { getLatestNews } from '../../../../logic/Backend'
import { getNewsFilters } from '../../../../logic/Backend'
import FilterContainer from './FilterContainer'
import { INewsItem } from '../../../../logic/Backend'
import Skeleton from 'react-loading-skeleton'

type NewsResponse = {
  from: number
  to: number
  total_count: number
  news: INewsItem[]
}

const News = () => {
  const [news, setNews] = useState<NewsResponse>({ from: 0, to: 0, total_count: 0, news: [] })
  const [filters, setFilters] = useState({})
  const [activeFilters, setActiveFilters] = useState({})
  const [loading, setLoading] = useState(false)
  const [shouldClearFilter, setShouldClearFilter] = useState(false)
  const localization = localStorage.getItem('language')

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getNewsFilters()
        setFilters(res)
        return res
      } catch (e) {
        console.log('Error fetching news filters: ', e)
      }
    }
    getData()
  }, [])

  useEffect(() => {
    const getNews = async () => {
      try {
        setLoading(true)
        const res = await getLatestNews({ filters: activeFilters })
        setNews(res)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    getNews()
  }, [activeFilters])

  const onFilterSelected = (key: string, value: string) => {
    setActiveFilters((prevState) => {
      const newVal = {}
      newVal[key] = value
      return { ...prevState, ...newVal }
    })
  }
  const handleClear = () => {
    setActiveFilters([])
    setShouldClearFilter(true)
  }

  const resetClearFilter = () => {
    setShouldClearFilter(false)
  }

  return (
    <div className={styles.newsContainer}>
      <h2 className={styles.heading}>Latest News</h2>
      <FilterContainer
        filterValues={filters}
        onFilterSelected={onFilterSelected}
        handleClear={handleClear}
        shouldClearFilter={shouldClearFilter}
        resetClearFilter={resetClearFilter}
      />
      <div className={styles.newsGrid}>
        {loading
          ? ((<Skeleton count={5} height={80} />) as ReactElement)
          : news?.news.map((news, i) => {
              return (
                <NewsItem
                  key={i}
                  companyName={news.company_name}
                  articleDate={news.published_date}
                  articleTitle={localization === 'jp' ? news.title_jp : news.title_en}
                  articleSummary={localization === 'jp' ? news.summary_jp : news.summary_en}
                  // tags={news.tags}
                  companyId={news.company_id}
                  logo={news.company_logo_id}
                  url={news.url}
                />
              )
            })}
      </div>
    </div>
  )
}

export default News
