import React, { FC, useEffect, useState } from 'react'
import { NumericInputProps } from '@blueprintjs/core'
import { NumberPicker, NumberPickerProps } from './NumberPicker'

export interface NumberRangePickerProps extends NumericInputProps {
  placeholders?: [string, string]
  onChange?: (state: NumberRangePickerState) => void
  formatting?: NumberPickerProps['formatting']
}

export interface NumberRangePickerState {
  start?: number
  end?: number
}

export const NumberRangePicker: FC<NumberRangePickerProps & NumberRangePickerState> = ({
  start,
  end,
  placeholders,
  onChange,
  formatting = 'locale',
  ...numericInputProps
}) => {
  const [startProp, setStartProp] = useState(start)
  const [start$, setStart$] = useState(start)
  const [endProp, setEndProp] = useState(end)
  const [end$, setEnd$] = useState(end)
  // sometimes we clamp value which resends the same value to the NumberPickers; we need to force them to rerender
  const [forceRenderStart, setForceRenderStart] = useState(false)
  const [forceRenderEnd, setForceRenderEnd] = useState(false)

  useEffect(() => {
    onChange_(start, end)
  }, [])

  if (start !== startProp) {
    setStartProp(start)
    setStart$(start)
  }

  if (end !== endProp) {
    setEndProp(end)
    setEnd$(end)
  }

  const onChangeStart: NumberPickerProps['onChange'] = (start_) => {
    if (end$ !== undefined && start_ !== undefined && start_ > end$) {
      start_ = end$
    }
    setStart$(start_)
    onChange_(start_, end$)
    setForceRenderStart(!forceRenderStart)
  }

  const onChangeEnd: NumberPickerProps['onChange'] = (end_) => {
    if (end_ !== undefined && start$ !== undefined && end_ < start$) {
      end_ = start$
    }
    setEnd$(end_)
    onChange_(start$, end_)
    setForceRenderEnd(!forceRenderEnd)
  }

  function onChange_(start_?: number, end_?: number) {
    onChange?.({ ...(start_ !== undefined && { start: start_ }), ...(end_ !== undefined && { end: end_ }) })
  }

  return (
    <div style={{ width: '100%', display: 'flex', gap: '10px', alignItems: 'baseline' }}>
      <NumberPicker
        {...numericInputProps}
        value={start$}
        placeholder={placeholders?.[0]}
        onChange={onChangeStart}
        forceRender={forceRenderStart}
        formatting={formatting}
      />
      <div>and</div>
      <NumberPicker
        {...numericInputProps}
        value={end$}
        placeholder={placeholders?.[1]}
        onChange={onChangeEnd}
        forceRender={forceRenderEnd}
        formatting={formatting}
      />
    </div>
  )
}
