import React from 'react'
import styles from './styles.module.scss'
import { Icon } from '@blueprintjs/core'
interface Props {
  text: string
  value: number
  onClick: (text: string) => void
}

const PresetSearches = (props: Props) => {
  const { text, value, onClick } = props
  return (
    <div className={styles.pill} onClick={() => onClick(text)}>
      {text}
      <button className={styles.absoluteButton}>
        <Icon icon="arrow-right" />
      </button>
    </div>
  )
}

export default PresetSearches
