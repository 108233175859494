import React, { FC, useEffect, useState } from 'react'
import { IMultiSelectProps, MultiSelect } from '@blueprintjs/select'
import { Button, Divider, IActionProps, ITagInputProps, MenuItem } from '@blueprintjs/core'
import * as Backend from '../../logic/Backend'
import { CompanyData, ListData, ListRequest } from '../../logic/Backend'
import styles from '../pages/Standard/index.module.scss'
import { IconName } from '@blueprintjs/icons'
import { showErrorToast, showSuccessToast } from '../../logic/Toaster'
import ListForm from './ListForm'
import { Explanation } from '../pages/Companies/Explanation'
import { packCompanyQueryParams } from '../../logic/AgGrid/AgGrid'
import { getSearchResultsMaxMessage, LIST_LIMIT } from '../pages/Companies/TopBarContents'
import { highlightText } from '../../logic/ValueFormatters'
import { ColumnApi, GridApi } from '@ag-grid-community/core'
import { itemPredicateListData, itemsEqualListData } from './CompanyLists'
import { NoResults } from '../../stories/NoResults'
import { withNamespaces, WithNamespaces } from 'react-i18next'

export interface CompanyListsAllResultsProps {
  api?: GridApi
  columnApi?: ColumnApi
  fetchLists?: typeof Backend.lists
  newList?: typeof Backend.newList
  addListings?: typeof Backend.batchSaveToLists
  deleteListings?: typeof Backend.batchDeleteLists
}

const CompanyListsAllResults: FC<WithNamespaces & CompanyListsAllResultsProps & IncludeT> = ({
  api,
  columnApi,
  newList = Backend.newList,
  fetchLists = Backend.lists,
  addListings = Backend.batchSaveToLists,
  deleteListings = Backend.batchDeleteLists,
  t,
}) => {
  const totalRowsCount = api?.paginationGetRowCount() || 0
  const [selectedListsMap, setSelectedListsMap] = useState(new Map())
  const [selectedRows] = useState<CompanyData[]>(api?.getSelectedRows() || [])
  const [selectedRowsCount] = useState(selectedRows.length)
  const [query, setQuery] = useState('')
  const [items, setItems] = useState<ListData[]>([])
  const [selectedItems, setSelectedItems] = useState<ListData[]>([])
  const [createdItems, setCreatedItems] = useState<ListData[]>([])
  const [newListIsOpen, setNewListIsOpen] = useState(false)

  useEffect(() => {
    fetchItems()
  }, [])

  async function fetchItems() {
    try {
      const items_ = await fetchLists()
      setItems(items_.filter((item) => item.editable_by_current_user))
      setSelectedItems(items_.filter((i) => selectedListsMap?.has(i.id)))
    } catch {
      showErrorToast(t('Failed to load lists'))
    }
  }

  async function newListOnClick(request: ListRequest) {
    try {
      await newList(request)
      await fetchItems()
      setNewListIsOpen(false)
      showSuccessToast(t('Created new list', { name: request.name }))
    } catch {
      showErrorToast(t('Failed to create list'))
    }
  }

  function deleteItemFromArray(items: ListData[], itemToDelete: ListData): ListData[] {
    return items.filter((item) => item.id !== itemToDelete.id)
  }

  function arrayContainsItem(items: ListData[], itemToFind: ListData): boolean {
    return items.some((item) => item.id === itemToFind.id)
  }

  function maybeDeleteCreatedItemFromArrays(
    items: ListData[],
    createdItems: ListData[],
    item: ListData
  ): { nextCreatedItems: ListData[]; nextItems: ListData[] } {
    const wasItemCreatedByUser = arrayContainsItem(createdItems, item)

    // Delete the item if the user manually created it.
    return {
      nextCreatedItems: wasItemCreatedByUser ? deleteItemFromArray(createdItems, item) : createdItems,
      nextItems: wasItemCreatedByUser ? deleteItemFromArray(items, item) : items,
    }
  }

  function addItemToArray(items: ListData[], itemToAdd: ListData): ListData[] {
    return [...items, itemToAdd]
  }

  function maybeAddCreatedSelectedItemToArrays(items: ListData[], createdItems: ListData[], item: ListData): { createdItems: ListData[]; items: ListData[] } {
    const isNewlyCreatedItem = !arrayContainsItem(items, item)
    return {
      createdItems: isNewlyCreatedItem ? addItemToArray(createdItems, item) : createdItems,
      items: isNewlyCreatedItem ? addItemToArray(items, item) : items,
    }
  }

  async function deselectSelectedItem(item: ListData) {
    const { nextCreatedItems, nextItems } = maybeDeleteCreatedItemFromArrays(items, createdItems, item)
    setCreatedItems(nextCreatedItems)
    setItems(nextItems)
    setSelectedItems(selectedItems.filter((i) => i !== item))
    selectedListsMap.delete(item.id)
    setSelectedListsMap(selectedListsMap)

    try {
      await deleteListings({ list_ids: [item.id], ...packCompanyQueryParams(columnApi) })
      showSuccessToast(t(`${getSearchResultsMaxMessage(totalRowsCount)}`, 'companies were removed from list', { item: item.name }))
    } catch {
      showErrorToast(t('Failed to remove companies from list'))
    }
    api?.forEachNode((row) => {
      row.setDataValue(
        'list_ids',
        (row.data as CompanyData).list_ids?.filter((id) => id !== item.id)
      )
    })
  }

  async function selectSelectedItem(selectedItem: ListData) {
    let nextCreatedItems = createdItems.slice()
    let nextSelectedItems = selectedItems.slice()
    let nextItems = items.slice()

    const results = maybeAddCreatedSelectedItemToArrays(nextItems, nextCreatedItems, selectedItem)
    nextItems = results.items
    nextCreatedItems = results.createdItems
    // Avoid re-creating an item that is already selected (the "Create
    // Item" option will be shown even if it matches an already selected
    // item).
    if (!arrayContainsItem(nextSelectedItems, selectedItem)) {
      selectedListsMap.set(selectedItem.id, undefined)
      setSelectedListsMap(selectedListsMap)
      nextSelectedItems = [...nextSelectedItems, selectedItem]
    }
    setCreatedItems(nextCreatedItems)
    setItems(nextItems)
    setSelectedItems(nextSelectedItems)
    try {
      await addListings({ list_id: selectedItem.id, ...packCompanyQueryParams(columnApi) })
      showSuccessToast(t(`${getSearchResultsMaxMessage(totalRowsCount)}`, 'companies were added to list', { selected: selectedItem.name }))
    } catch (error) {
      showErrorToast(t('Failed to add companies to list'))
    }
    api?.forEachNode((row) => {
      row.setDataValue('list_ids', [...Array.from(new Set(row.data.list_ids)), selectedItem.id])
    })
  }

  const onRemove: ITagInputProps['onRemove'] = async (value, index) => {
    await deselectSelectedItem(selectedItems[index])
  }

  const handleClear: IActionProps['onClick'] = async () => {
    try {
      await deleteListings({ list_ids: selectedItems.map((item) => item.id), ...packCompanyQueryParams(columnApi) })
      showSuccessToast(
        `${t('Removed')} ${getSearchResultsMaxMessage(totalRowsCount)} ${t('companies from lists:')} '${selectedItems.map((item) => item.name).join(', ')}'`
      )
    } catch (error) {
      showErrorToast(t('Failed to remove companies from lists'))
    }
    api?.forEachNode((row) => {
      row.setDataValue(
        'list_ids',
        (row.data as CompanyData).list_ids?.filter((id) => !selectedItems.map((item) => item.id).includes(id))
      )
    })

    emptySelection()
  }

  const emptySelection: () => void = () => {
    setSelectedItems([])
    setSelectedListsMap(new Map())
  }

  const onItemSelect: IMultiSelectProps<ListData>['onItemSelect'] = async (selectedItem) => {
    const add = !selectedItems.includes(selectedItem)
    const localUpdateFn = add ? selectSelectedItem : deselectSelectedItem
    await localUpdateFn(selectedItem)
  }

  const clearButton: ITagInputProps['rightElement'] = selectedItems.length > 0 ? <Button icon="cross" minimal={true} onClick={handleClear} /> : <></>

  function listRenderer_(list: ListData): string {
    const selectedRowsReferencingList = selectedListsMap?.get(list.id)?.length
    if (selectedRowsReferencingList && selectedRowsCount && selectedRowsCount > 1) {
      return `${list.name} (${selectedRowsReferencingList})`
    }
    return list.name ?? ''
  }

  function listRenderer(list: ListData): React.ReactNode {
    return highlightText(listRenderer_(list), query)
  }

  function iconMenuItem(n: number | undefined, max: number | undefined): IconName {
    if (n === max) {
      return 'tick'
    }
    if (n === undefined || max === undefined || n === 0) {
      return 'blank'
    }
    return 'minus'
  }

  const itemRenderer: IMultiSelectProps<ListData>['itemRenderer'] = (item, { handleClick, modifiers, index }) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    const selectedRowsReferencingList = selectedListsMap?.get(item.id)?.length
    return (
      <MenuItem
        active={modifiers.active}
        icon={iconMenuItem(selectedRowsReferencingList, selectedItems.length)}
        disabled={modifiers.disabled}
        key={index}
        onClick={handleClick}
        text={listRenderer(item)}
        shouldDismissPopover={false}
      />
    )
  }

  const tagRenderer: IMultiSelectProps<ListData>['tagRenderer'] = (selectedItem) => {
    return listRenderer(selectedItem)
  }

  const onQueryChange: IMultiSelectProps<ListData>['onQueryChange'] = (query) => {
    setQuery(query)
  }

  const explanations = (() => {
    const alwaysShown = `${t(
      'Some of the companies selected may already be in lists. Due to the large number of companies selected, we cannot show the lists for all the companies.'
    )}`
    if (totalRowsCount > LIST_LIMIT) {
      return [t('list_limit', { number: LIST_LIMIT.toLocaleString() }), alwaysShown]
    }
    return [alwaysShown]
  })()

  return (
    <>
      <Explanation texts={explanations} />
      <Divider />
      <MultiSelect
        fill={true}
        className={styles.subContextMenu}
        items={items}
        selectedItems={selectedItems}
        itemRenderer={itemRenderer}
        itemPredicate={itemPredicateListData}
        onQueryChange={onQueryChange}
        itemsEqual={itemsEqualListData}
        tagRenderer={tagRenderer}
        placeholder={t('Click or type here to see existing lists.')}
        noResults={<NoResults />}
        onItemSelect={onItemSelect}
        resetOnQuery={false}
        popoverProps={{ minimal: true, boundary: 'viewport', popoverClassName: 'max-width' }}
        tagInputProps={{
          onRemove: onRemove,
          rightElement: clearButton,
        }}
      />
      <Button
        style={{ alignSelf: 'flex-start' }}
        minimal={true}
        icon={'add'}
        text={t('Create New List')}
        intent={'primary'}
        onClick={() => setNewListIsOpen(true)}
      />
      {newListIsOpen && <ListForm isOpen={newListIsOpen} onClick={newListOnClick} onClickCancel={() => setNewListIsOpen(false)} />}
    </>
  )
}

export default withNamespaces()(CompanyListsAllResults)
