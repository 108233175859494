import React from 'react'
import styles from './styles.module.scss'
import { InitialsInDisk } from '../../../../stories/InitialsInDisk'

interface Props {
  title: string
  body: string
  userName: string
}

const ActivityListItem = ({ title, body, userName }: Props) => {
  return (
    <div className={styles.flexContainer}>
      <span className={styles.teamActivityAvatar}>
        {' '}
        <InitialsInDisk name={userName} />
      </span>
      <div className={styles.flexColContainer}>
        <div className={styles.boldInfo}>{title}</div>
        <div className={styles.plainText}>{body}</div>
      </div>
    </div>
  )
}

export default ActivityListItem
