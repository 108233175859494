import React from 'react'
import '../styles/pipelineDropDown.scss'
import CompanyStatusDropDown from '../components/CompanyStatusDropDown'

const PipelineStatusCellRenderer = ({ data, ...rest }) => {
  const organizationId = data.id
  const pipelineStatus = data.pipeline_status
  return (
    <div className="grid-dropdown">
      <CompanyStatusDropDown organization_id={organizationId} pipeline_status={pipelineStatus} api={rest.api} />
    </div>
  )
}

export default PipelineStatusCellRenderer
