import React, { FC, useState, useEffect } from 'react'
import ContainerComponent from '../components/ContainerComponent'
import styles from '../components/styles.module.scss'
import { getNewlyDiscoveredCompanies } from '../../../../logic/Backend'
import { DateTime } from 'luxon'
import CompanyItem from '../components/CompanyItem'
import SeeMoreButton from '../components/SeeMoreButton'
import { useFetchCompanies } from '../components/useFetchCompanies'
import Skeleton from 'react-loading-skeleton'

export const NewlyDiscoveredCompanies: FC = () => {
  const { companies, visibleCompanies, setFrom, total_count, loading } = useFetchCompanies(0, 10, getNewlyDiscoveredCompanies)
  const [initialLoading, setInitialLoading] = useState(true)
  const [paginationLoading, setPaginationLoading] = useState(false)

  useEffect(() => {
    if (!loading) {
      setInitialLoading(false)
      setPaginationLoading(false)
    }
  }, [loading])

  const queryParams = new URLSearchParams({ from: String(0), limit: String(10) }).toString()
  const seeMoreUrl = `/companies?${queryParams}`

  function formatRelativeDate(dateString: string): string {
    const date = DateTime.fromISO(dateString).startOf('day')
    const now = DateTime.now().startOf('day')
    const diff = now.diff(date, 'days').toObject()

    if (diff.days !== undefined) {
      if (diff.days < 1) return 'TODAY'
      if (diff.days < 2) return 'YESTERDAY'
      if (diff.days < 7) return `${Math.floor(diff.days)} DAYS AGO`
      if (diff.days <= 30) return `${Math.floor(diff.days)} DAYS AGO`
      return '1 MONTH +'
    }
  }

  const uniqueVisibleCompanies = Array.from(new Set(visibleCompanies.map((company) => company.id))).map((id) =>
    visibleCompanies.find((company) => company.id === id)
  )

  const sortedVisibleCompanies = uniqueVisibleCompanies.sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime() || b.recommended_score - a.recommended_score
  )

  const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    if (scrollHeight - scrollTop <= clientHeight + 1 && companies.length < total_count && !paginationLoading) {
      setPaginationLoading(true)
      setFrom((prevFrom) => prevFrom + 10)
    }
  }

  const renderedItems = (
    <div className={styles.scrollable} onScroll={handleScroll}>
      {initialLoading
        ? ((<Skeleton count={8} height={80} />) as React.ReactElement)
        : sortedVisibleCompanies.map((company) => (
            <CompanyItem
              key={company.id}
              company={company}
              additionalInfo={<div className={styles.newlyDiscoveredCompanies}>DISCOVERED {formatRelativeDate(company.created_at)}</div>}
            />
          ))}
      {paginationLoading && <Skeleton count={1} height={80} />}
    </div>
  )

  return (
    <>
      <ContainerComponent title="Discovered Companies" seeMoreButton={<SeeMoreButton url={seeMoreUrl} />} renderedItems={renderedItems} />
    </>
  )
}

export default NewlyDiscoveredCompanies
