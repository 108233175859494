import React, { FC, useState } from 'react'
import { Button, Classes, Dialog, InputGroup, Intent } from '@blueprintjs/core'
import * as Routes from '../../logic/Routes'
import { useQueryParams } from '../../logic/Routes'
import { PasswordButton } from '../components/PasswordButton'
import { useFormik } from 'formik'
import * as yup from 'yup'
import * as Backend from '../../logic/Backend'
import { showErrorToast } from '../../logic/Toaster'
import { submitForm, validation } from '../../logic/FormValidation'
import { PlainPageTemplate } from './PlainPageTemplate'
import { routerHistory } from '../app/App'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { t } from 'i18next'

const PasswordResetPage: FC<WithNamespaces> = () => {
  const { email, token } = useQueryParams(['email', 'token'])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const formik = useFormik({
    initialValues: {
      newPassword: '',
    },
    validationSchema: yup.object({
      newPassword: yup.string().label('Password').min(8).required(),
    }),
    onSubmit: async (values) => {
      try {
        await Backend.resetPassword({ ...values, email: email!, token: token! })
        setIsDialogOpen(true)
      } catch (error) {
        showErrorToast(t('Failed to reset password'))
      }
    },
  })

  function goToLoginPage() {
    routerHistory.push(Routes.login.href)
  }

  return (
    <PlainPageTemplate>
      <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px', width: '100%' }}>
        <InputGroup fill={true} placeholder={'Email'} value={email} disabled={true} />
        <PasswordButton placeholder={`${t('New password (8+ characters)')}`} {...validation(formik, 'newPassword')} onChange={formik.handleChange} />
        <Button
          onClick={() => submitForm(formik)}
          type={'button'}
          loading={formik.isValidating || formik.isSubmitting}
          intent={Intent.PRIMARY}
          text={t('Reset Password')}
          fill={true}
        />
      </form>
      <Dialog title={t('Password Reset Successful')} isOpen={isDialogOpen} onClose={() => goToLoginPage()}>
        <div style={{ margin: '20px 20px 0 20px' }}>
          <p>{t('Your password was reset. You can now log in using your new password')}</p>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => goToLoginPage()} intent={Intent.PRIMARY} text={t('Login')} />
          </div>
        </div>
      </Dialog>
    </PlainPageTemplate>
  )
}

export default withNamespaces()(PasswordResetPage)
