import React from 'react'
import { Icon } from '@blueprintjs/core'
import { percentage } from '../../../../../logic/ValueFormatters'
import style from './style.module.scss'
interface Props {
  companyId: string
  index: number
  employeeCount?: number
  employeeGrowth?: string
}

const EmployeeCount = (props: Props) => {
  const { companyId, index, employeeCount, employeeGrowth } = props
  const isPositive = employeeGrowth && parseFloat(employeeGrowth) > 0
  return (
    <div className={style.employeeGrowthContainer}>
      <div key={companyId} className={style.employeeCount}>
        {employeeCount}
      </div>
      {employeeGrowth && (
        <div key={index}>
          ({percentage(employeeGrowth?.toString())}
          {employeeGrowth && parseFloat(employeeGrowth) != 0 && <Icon color={isPositive ? 'green' : 'red'} icon={isPositive ? 'caret-up' : 'caret-down'} />})
        </div>
      )}
    </div>
  )
}

export default EmployeeCount
