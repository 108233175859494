import { RowNode } from '@ag-grid-community/core'
import { CompanyData, ListData, updateRatings } from './Backend'
import { showErrorToast, showSuccessToast } from './Toaster'
import { RATINGS_WITH_UNRATED } from '../resources/reference_data/ratings'
import { pluralize } from './ValueFormatters'

export function uniqueArray<T>(array: T[] | undefined): T[] | undefined {
  if (array === undefined) {
    return undefined
  }
  return Array.from(
    array
      .reduce((a, e) => {
        if (!a.get(e)) {
          a.set(e, true)
        }
        return a
      }, new Map<T, boolean>())
      .keys()
  )
}

export function nameof<T>(name: keyof T & string): string {
  return name
}

// remove diacritics, and convert to lower-case
// see https://stackoverflow.com/a/51874002/2249756
function normalizeString(text: string): string {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}

export function queryIndex(text: string, query: string): number {
  return normalizeString(text).indexOf(normalizeString(query))
}

export function canEditList(list?: ListData): boolean {
  return list?.belongs_to_current_user || list?.publicly_editable || false
}

export function canDeleteList(list?: ListData): boolean {
  return list?.belongs_to_current_user || false
}

export const switchRating = async (comingRating: number, selectedRows: CompanyData[], selectedNodes: RowNode[]): Promise<void> => {
  const shouldUnrate = selectedRows.every((row) => row.rating === comingRating)
  const newRating = shouldUnrate ? undefined : comingRating
  selectedNodes.forEach((row) => {
    row.setDataValue('rating', newRating)
  })
  const selectedCompanyIds = selectedRows.map((row) => row.id)
  const message =
    newRating === undefined
      ? `Unrated ${selectedRows.length} ${pluralize('compan', 'y', 'ies', selectedRows.length)}`
      : `Rated ${selectedRows.length} ${pluralize('compan', 'y', 'ies', selectedRows.length)} '${RATINGS_WITH_UNRATED[newRating]}'`
  await rateCompanies(newRating, selectedCompanyIds, message)
}

export const switchSingleRating = async (
  newRating: number,
  oldRating: number | undefined,
  companyId: CompanyData['id'],
  companyName?: CompanyData['name'],
  updateRating?: (new_rating_: number | undefined) => void
): Promise<void> => {
  const shouldUnrate = oldRating === newRating
  const newRating_ = shouldUnrate ? undefined : newRating
  updateRating?.(newRating_)
  const selectedCompanyIds = [companyId]
  const message = newRating_ === undefined ? `Unrated '${companyName}'` : `Rated '${companyName}' to '${RATINGS_WITH_UNRATED[newRating_]}'`
  await rateCompanies(newRating_, selectedCompanyIds, message)
}

const rateCompanies = async (rating: number | undefined, organization_ids: string[], message: string) => {
  try {
    await updateRatings({ rating: rating, organization_ids: organization_ids })
    showSuccessToast(message)
  } catch {
    showErrorToast('Failed to rate companies')
  }
}

export const isACompanyPageFromList = (search: string, pathname: string): boolean => {
  const urlParams = new URLSearchParams(search)
  return pathname.startsWith('/companies') && urlParams.has('focusedListId')
}
