import { createModel } from '@rematch/core'
import { RootModel } from '.'

const chatMessagesState = createModel<RootModel>()({
  state: [{ message: '', sender: '', sessionId: '' }],
  reducers: {
    updateChatHistory(state, payload) {
      return [...state, { message: payload.message, sender: payload.sender, sessionId: payload.sessionId }]
    },
    populateChatHistory(state, payload) {
      if (payload.chats.length === 0) return
      const newState = []
      payload.chats.forEach((dialogue) => {
        newState.push({ message: dialogue.question, sender: 'user' })
        newState.push({ message: dialogue.answer, sender: 'ai' })
      })
      return newState
    },
  },
})

export default chatMessagesState
