import React, { FC, useEffect, useState } from 'react'
import TopBarContents from './TopBarContents'
import Grid from './Grid'
import * as Backend from '../../../logic/Backend'
import { ListsPageData, UserData } from '../../../logic/Backend'
import { TopBar } from '../../components/TopBar'
import * as Routes from '../../../logic/Routes'
import { Cache, Set } from '../Standard'

export const Lists: FC<{ cache?: Cache; setCache: Set<Cache | undefined> }> = ({ cache, setCache }) => {
  const [lists, setLists] = useState([] as ListsPageData[])
  const [users, setUsers] = useState<UserData[]>([])

  async function loadFromBackend() {
    setUsers(await Backend.users())
  }

  useEffect(() => {
    loadFromBackend()
  }, [])

  return (
    <>
      <TopBar breadcrumbs={[{ ...Routes.lists, ...{ current: true } }]}>
        <TopBarContents selectedRows={lists} cache={cache} />
      </TopBar>
      <Grid selectedRows={lists} setSelectedRows={setLists} cache={cache} setCache={setCache} users={users} />
    </>
  )
}
