import React, { Dispatch, MutableRefObject, SetStateAction } from 'react'
import { GridApi } from '@ag-grid-community/core'
import ReactDOM from 'react-dom'
import Pagination from '../../stories/Pagination'
import { ResponseForAgGrid } from '../Backend'
import ahoy from 'ahoy.js'

export interface PaginationState {
  currentPage: number
  lastPage?: number
  rowsCount?: [number, boolean]
}

export function render(
  state: MutableRefObject<PaginationState>,
  labels: [string, string],
  statusBarElement: Element | null,
  api?: GridApi,
  isLoading?: boolean,
  isCalculating?: boolean
): void {
  if (api && statusBarElement) {
    ReactDOM.render(
      <Pagination
        page={state.current.currentPage}
        labels={labels}
        rowsCount={state.current.rowsCount?.[0]}
        exactCount={state.current.rowsCount?.[1]}
        lastPage={state.current.lastPage}
        isLoading={isLoading}
        isCalculating={isCalculating}
        onPageChanged={(shift) => {
          onPageChanged(shift, api, state)
          render(state, labels, statusBarElement, api, isLoading, isCalculating)
        }}
      />,
      statusBarElement
    )
  }
}

export function setToPage1(state: MutableRefObject<PaginationState>): void {
  state.current.currentPage = 1
  state.current.rowsCount = undefined
}

export function setRowsCountAndLastPage<T>(state: MutableRefObject<PaginationState>, response: ResponseForAgGrid<T>, api?: GridApi): void {
  if (api) {
    state.current.rowsCount = [response.rowsCount, response.exactCount]
    state.current.lastPage = response.lastPage ? api!.paginationGetCurrentPage() + 1 : undefined
  }
}

export function prepareStatusBar(statusBarElement: Element | null, setStatusBarElement: Dispatch<SetStateAction<Element | null>>): void {
  if (!statusBarElement) {
    setStatusBarElement(document.querySelector('.ag-status-bar-right'))
  }
  document.querySelector('.ag-status-bar')!.classList.remove('ag-hidden')
}

function onPageChanged(shift: number, api: GridApi, state: MutableRefObject<PaginationState>): void {
  const newPage = state.current.currentPage + shift
  const cachedPageCount = Object.keys(api.getCacheBlockState()).length
  if (newPage > cachedPageCount) {
    state.current.lastPage = newPage
  }
  state.current.currentPage = newPage
  shift === 1 ? api.paginationGoToNextPage() : api.paginationGoToPreviousPage()
  ahoy.track('changePage', { page: newPage })
}
