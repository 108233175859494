import React, { useEffect, useState } from 'react'
import ContainerComponent from '../components/ContainerComponent'
import styles from '../components/styles.module.scss'
import ActivityListItem from './ActivityListItem'
import { ActivityType, getTeamActivity } from '../../../../logic/Backend'

const TeamActivity = () => {
  const [data, setData] = useState<ActivityType[]>()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTeamActivity({ from: 0, to: 10 })
        setData(response.activities)
      } catch (e) {
        console.log('error getting Team Activity: ', e)
      }
    }
    fetchData()
  }, [])

  const renderedItems = (
    <div className={styles.scrollable}>
      {data?.map((activity) => (
        <ActivityListItem key={activity.id} title={activity.title} body={activity.body} userName={activity.user_name} />
      ))}
    </div>
  )
  return data && data.length <= 0 ? <></> : <ContainerComponent title="Team Activity" renderedItems={renderedItems} />
}

export default TeamActivity
