import React, { FC, ReactNode, useState } from 'react'
import styles from './InvestmentProfileCriteria.module.scss'
import { Button, FormGroup, Icon, Position } from '@blueprintjs/core'
import { Tooltip } from '../Tooltip'
import { t } from 'i18next'

export interface InvestmentProfileCriteriaProps {
  title?: string
  children: ReactNode
  onChangeWeight: (i: number) => void
  description?: string | null
  initialWeight?: number
}

export const InvestmentProfileCriteria: FC<InvestmentProfileCriteriaProps> = ({ children, title, description, onChangeWeight, initialWeight = 3 }) => {
  const [weight, setWeight] = useState(initialWeight)

  return (
    <FormGroup className={styles.root} style={title === undefined ? { backgroundColor: 'transparent', padding: '0' } : {}} label={title} labelFor="text-input">
      <p style={title === undefined ? { fontSize: '16px', fontWeight: '600' } : {}}>{description}</p>
      {children}
      <div className={styles.weightContainer}>
        <span>{t('Weight of importance')}</span>
        <Tooltip position={Position.TOP}>
          <Icon className={styles.icon} icon={'help'} iconSize={16} color={'#5a737d'} />
          <div>
            {t(
              'Please select a weight of importance for this criteria, with 1 being the least important and 5 being the most important. We will train our model to prioritize criteria with high weights of importance over those with lower weights of importance.'
            )}
          </div>
        </Tooltip>
        <div className={styles.weight}>
          {[1, 2, 3, 4, 5].map((i) => (
            <Button
              key={i}
              text={i.toString()}
              intent={i === weight ? 'primary' : 'none'}
              onClick={() => {
                setWeight(i)
                onChangeWeight(i)
              }}
            />
          ))}
        </div>
      </div>
    </FormGroup>
  )
}
