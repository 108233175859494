import React, { FC, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styles from '../app/App.module.scss'
import { Button, Icon } from '@blueprintjs/core'
import logo from '../../resources/images/logo.png'
import gptIcon from '../../resources/images/ai_chat_icon.svg'
import { NavLinkButton } from '../../stories/NavLinkButton'
import LocalizationButton from '../../stories/LocalizationButton'
import * as Routes from '../../logic/Routes'
import { isACompanyPageFromList } from '../../logic/Helpers'
import { InitialsInDisk } from '../../stories/InitialsInDisk'
import { Cache } from '../pages/Standard'
import CollapseButton from '../../stories/KurrantTheme/CollapseButton'
import i18n from '../../i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

const LeftSidebar: FC<{ cache: Cache | undefined } & WithNamespaces & IncludeT> = ({ cache, t }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
    localStorage.setItem('language', lng)
  }

  useEffect(() => {
    document.documentElement.style.setProperty('--left-sidebar-width', isCollapsed ? '50px' : '200px')
  }, [isCollapsed])
  const localization = localStorage.getItem('language')
  return (
    <nav className={isCollapsed ? styles.navCollapsed : styles.nav}>
      <ul className={styles.ul}>
        <li>
          <NavLinkButton
            label={Routes.chat.text}
            isCollapsed={isCollapsed}
            path={Routes.chat.href}
            icon={<img src={logo} alt="Logo" width={20} height={20} />}
            isActive={(_match, location) => {
              return !isACompanyPageFromList(location.search, location.pathname) && location.pathname.startsWith('/chat')
            }}
          />
        </li>
        <li>
          <NavLinkButton
            label={t(Routes.dashboard.text!)}
            isCollapsed={isCollapsed}
            path={Routes.dashboard.href}
            icon={'control'}
            isActive={(_match, location) => {
              return !isACompanyPageFromList(location.search, location.pathname) && location.pathname.startsWith('/dashboard')
            }}
          />
        </li>
        <li>
          <NavLinkButton
            label={t(Routes.companies.text!)}
            isCollapsed={isCollapsed}
            path={Routes.companies.href}
            icon={'office'}
            isActive={(_match, location) => {
              return !isACompanyPageFromList(location.search, location.pathname) && location.pathname.startsWith('/companies')
            }}
          />
        </li>
        <li>
          <NavLinkButton
            label={t(Routes.lists.text!)}
            isCollapsed={isCollapsed}
            path={Routes.lists.href}
            icon={'applications'}
            isActive={(_match, location) => {
              return location.pathname.startsWith('/lists') || isACompanyPageFromList(location.search, location.pathname)
            }}
          />
        </li>
        {/*
        TODO: To be implemented once help page is created.
        <li style={{ marginTop: 'auto' }}>
          <NavLinkButton
            label={'Help'}
            isCollapsed={isCollapsed}
            path={''}
            icon={'help'}
          />
        </li> */}
        <li>
          <NavLinkButton label={t('Pipeline')} isCollapsed={isCollapsed} path={'/pipeline'} icon="layers" />
        </li>
        <li style={{ marginTop: 'auto' }}>
          <LocalizationButton label={t(localization)} isCollapsed={isCollapsed} icon={localization === 'jp' ? <>あ</> : <>A</>} localization={localization} />
        </li>
        <li>
          <NavLinkButton
            label={t(Routes.preferences.text!)}
            isCollapsed={isCollapsed}
            path={Routes.preferences.href}
            icon={<InitialsInDisk name={cache?.currentUser?.name} />}
          />
        </li>
        <li>
          <CollapseButton onClick={() => setIsCollapsed(!isCollapsed)} isCollapsed={isCollapsed} />
        </li>
      </ul>
    </nav>
  )
}

export default withNamespaces()(LeftSidebar)
