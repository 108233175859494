import React, { FC } from 'react'
import { TruncatableText } from './TruncatableText'
import palette from '../_palette.module.scss'
import { CbLiOrPlaceholderImage } from '../ui/pages/Company/CbLiOrPlaceholderImage'
import { CompanyData } from '../logic/Backend'

export interface EmployeeHistoryPointProps {
  label1?: string
  label2?: string
  label3?: string
  isCurrent?: boolean
  logo_id?: CompanyData['logo_id']
  li_logo_url?: CompanyData['li_logo_url']
}

export const EmployeeHistoryPoint: FC<EmployeeHistoryPointProps> = ({ label1, label2, label3, isCurrent, logo_id, li_logo_url }) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: '16px',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          height: '17px',
          width: '17px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: palette.blue3,
          borderRadius: '50%',
          backgroundColor: isCurrent ? palette.blue3 : palette.white,
          flexShrink: '0',
          zIndex: '1', // go over the dashed line
        }}
      />
      <div
        style={{
          height: '56px',
          width: '56px',
          borderRadius: '50%',
          flexShrink: '0',
        }}
      >
        <CbLiOrPlaceholderImage logo_id={logo_id} li_logo_url={li_logo_url} size={56} style={{ borderRadius: '50%' }} altText={'Logo'} />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        {label1 && <TruncatableText text={label1} style={{ color: palette.black }} />}
        {label2 && <TruncatableText text={label2} style={{ color: palette.darkGray3 }} />}
        {label3 && <TruncatableText text={label3} className={'caption'} style={{ marginTop: '2px' }} />}
      </div>
    </div>
  )
}
