import React from 'react'
import styles from './styles.module.scss'
import Logo from '../Dashboard/components/logo192.png'
import { Icon } from '@blueprintjs/core'

interface Props {
  onSendMessage: (text: string) => void
  onValueChange: (text: string) => void
  value: string
  isNewConversation: boolean
}

const ChatInput = (props: Props) => {
  const { onSendMessage, onValueChange, value, isNewConversation } = props
  return (
    <div className={`${!isNewConversation ? styles.bottomInput : ''} ${styles.relativeContainer}`}>
      <img className={`${styles.absoluteElement} ${styles.chatIcon}`} src={Logo} />
      <input
        autoFocus
        className={isNewConversation ? styles.freshChatInput : styles.chatInput}
        placeholder="Send a message to Kurrant"
        value={value}
        onChange={(e) => onValueChange(e.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onSendMessage(value)
            onValueChange('')
          }
        }}
      />
      <button className={styles.absoluteButton} onClick={() => value && onSendMessage(value)}>
        <Icon icon="arrow-right" />
      </button>
    </div>
  )
}

export default ChatInput
