import React, { CSSProperties, FC, useEffect, useState } from 'react'
import styles from './index.module.scss'
import * as Backend from '../../../logic/Backend'
import {
  createSavedFilter,
  deleteSavedFilter,
  Indexed,
  ListData,
  listSavedFilters,
  ListsPageData,
  SavedFilterData,
  updateSavedFilter,
  UserData,
} from '../../../logic/Backend'
import { canDeleteList, canEditList } from '../../../logic/Helpers'
import * as Routes from '../../../logic/Routes'
import { hrefForId } from '../../../logic/Routes'
import {
  deleteListConfirmationText,
  localDate,
  notes,
  parseEditAccess,
  parseVisibility,
  percentage,
  userName,
  withoutHighlight,
} from '../../../logic/ValueFormatters'
import { TopBar } from '../../components/TopBar'
import Grid from '../Companies/Grid'
import { Cache, Set } from '../Standard'
import TopBarContents from '../Companies/TopBarContents'
import { deleteListsOnClick, editListOnClick } from '../Lists/TopBarContents'
import { GridOptions } from '@ag-grid-community/core'
import { Button, Intent, PopoverPosition, ProgressBar } from '@blueprintjs/core'
import { ConfirmationDialog } from '../../components/ConfirmationDialog'
import { DetailBlock } from '../../components/DetailBlockWidget/DetailBlock'
import { DetailBlockDefaultTargetWrapper } from '../../components/DetailBlockWidget/DetailBlockDefaultTargetWrapper'
import { SessionStorage } from '../../../logic/ClientSideStorage'
import SavedFiltersPanel from '../../../stories/Filtering/SavedFiltersPanel'
import { ServerSideDatasource } from '../../../logic/AgGrid/AgGrid'
import { showErrorToast } from '../../../logic/Toaster'
import { routerHistory } from '../../app/App'
import ListForm from '../../components/ListForm'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { t } from 'i18next'

const List: FC<WithNamespaces & { id: Indexed['id']; cache?: Cache; setCache: Set<Cache | undefined> }> = (props) => {
  const [list, setList] = useState<ListsPageData>()
  const [users, setUsers] = useState<UserData[]>([])
  const [scrollDown, setScrollDown] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [sessionStorage, setSessionStorage] = useState(new SessionStorage(`List/${props.id}`))
  const [savedFilters$, setSavedFilters$] = useState<SavedFilterData[]>()

  async function loadFromBackend() {
    try {
      setSavedFilters$(await listSavedFilters())
    } catch (error) {
      showErrorToast(t('Failed to load saved filters'))
    }
    if (list === undefined) {
      try {
        setList(await Backend.listPage(props.id))
      } catch (error) {
        showErrorToast(t('Failed to load list'))
      }
    }
    try {
      setUsers(await Backend.users())
    } catch (error) {
      showErrorToast(t('Failed to load users'))
    }
  }

  async function deleteList() {
    setShowDeleteConfirmation(!showDeleteConfirmation)
    await deleteListsOnClick([list!], props.cache?.api)
    routerHistory.push(Routes.lists.href)
  }

  const show = {
    height: '210px',
    transition: 'padding .07s, height .07s',
  }

  const hide = {
    height: '70px',
    padding: '20px 40px',
    transition: 'padding .07s, height .07s',
  }

  const handleScroll: GridOptions['onBodyScroll'] = (event) => {
    if (event.direction === 'horizontal') return

    if (event.top > 140) {
      setScrollDown(true)
    } else if (event.top == 0) {
      setScrollDown(false)
    }
  }

  useEffect(() => {
    loadFromBackend()
    return () => (ServerSideDatasource.listId = undefined)
  }, [])

  const listName = withoutHighlight(list?.name)

  const [editListIsOpen, setEditListIsOpen] = useState(false)

  const updateListCallback = (list: ListData) => {
    setEditListIsOpen(false)
    setList(list)
  }

  function getDeleteListConfirmationText() {
    if (list == null) {
      return ''
    }

    return deleteListConfirmationText(list)
  }

  ServerSideDatasource.listId = props.id

  return (
    <>
      <TopBar
        breadcrumbs={[
          Routes.lists,
          {
            href: hrefForId(Routes.list, props.id),
            text: listName,
            current: true,
          },
        ]}
        left={
          savedFilters$ && (
            <SavedFiltersPanel
              savedFilters={savedFilters$}
              activeSavedFilterId={sessionStorage.loadActiveSavedFilterId()}
              backendCreateSavedFilter={createSavedFilter}
              backendUpdateSavedFilter={updateSavedFilter}
              backendDeleteSavedFilter={deleteSavedFilter}
              gridApi={props.cache?.api}
              sessionStorage={sessionStorage}
            />
          )
        }
      >
        <TopBarContents cache={props.cache} />
      </TopBar>
      <div className={styles.page}>
        <div className={styles.top} style={scrollDown ? hide : show}>
          <div className={styles.top__listName}>
            <label>{listName}</label>
            {canEditList(list) && (
              <>
                <Button text={t('Edit List')} onClick={() => setEditListIsOpen(true)} />
                {editListIsOpen && (
                  <ListForm
                    list={list}
                    isOpen={editListIsOpen}
                    onClick={editListOnClick(props.id, props.cache?.api, updateListCallback)}
                    onClickCancel={() => setEditListIsOpen(false)}
                  />
                )}
              </>
            )}
            {canDeleteList(list) && <Button text={t('Delete List')} onClick={() => setShowDeleteConfirmation(!showDeleteConfirmation)} />}
            <ConfirmationDialog
              alertProps={{
                isOpen: showDeleteConfirmation,
                onClose: () => setShowDeleteConfirmation(false),
                cancelButtonText: `${t('Cancel')}`,
                confirmButtonText: `${t('Delete')}`,
                onConfirm: deleteList,
              }}
              text={getDeleteListConfirmationText()}
            />
          </div>
          <div className={styles.top__highlights}>
            <DetailBlock
              label={t('Notes')}
              value={notes(list?.notes)}
              style={
                {
                  backgroundColor: 'transparent',
                  maxWidth: '320px',
                  width: 'auto',
                  padding: 0,
                } as CSSProperties
              }
              popoverProps={{ position: 'bottom' }}
            />
            {list?.progress !== undefined && (
              <DetailBlock
                label={t('Progress')}
                style={
                  {
                    backgroundColor: 'transparent',
                    height: 'auto',
                    width: 'auto',
                    padding: 0,
                    minWidth: '200px',
                  } as CSSProperties
                }
                popoverProps={{
                  position: PopoverPosition.BOTTOM,
                  target: (
                    <>
                      <DetailBlockDefaultTargetWrapper>
                        {`${percentage(list?.progress)} (${Math.round(
                          Number(list?.progress) * Number(list?.total_organizations)
                        )} of ${list?.total_organizations?.toLocaleString()})`}
                      </DetailBlockDefaultTargetWrapper>
                      <DetailBlockDefaultTargetWrapper
                        wrapperProps={{
                          style: {
                            marginTop: '12px',
                            minWidth: '200px',
                          } as CSSProperties,
                        }}
                      >
                        <ProgressBar intent={Intent.PRIMARY} stripes={false} animate={false} value={Number(list?.progress)} />
                      </DetailBlockDefaultTargetWrapper>
                    </>
                  ),
                }}
              />
            )}
            <DetailBlock
              label={t('Created by')}
              style={
                {
                  backgroundColor: 'transparent',
                  width: 'auto',
                  padding: 0,
                } as CSSProperties
              }
              popoverProps={{
                position: PopoverPosition.BOTTOM,
                target: (
                  <DetailBlockDefaultTargetWrapper wrapperClassName={'lineClamp2'}>
                    {userName(users, list?.user_id)}
                    <br />
                    {localDate(list?.created_at)}
                  </DetailBlockDefaultTargetWrapper>
                ),
              }}
            />
            <DetailBlock
              label={t('Visibility')}
              value={parseVisibility(list?.publicly_visible)}
              style={
                {
                  backgroundColor: 'transparent',
                  width: 'auto',
                  padding: 0,
                } as CSSProperties
              }
              popoverProps={{
                position: PopoverPosition.BOTTOM,
              }}
            />
            <DetailBlock
              label={t('Edit Access')}
              value={parseEditAccess(list?.publicly_editable)}
              style={
                {
                  backgroundColor: 'transparent',
                  width: 'auto',
                  padding: 0,
                } as CSSProperties
              }
              popoverProps={{
                position: PopoverPosition.BOTTOM,
              }}
            />
            <DetailBlock
              label={t('Assigned To')}
              value={userName(users, list?.assignee_id)}
              style={
                {
                  backgroundColor: 'transparent',
                  width: 'auto',
                  padding: 0,
                } as CSSProperties
              }
              popoverProps={{
                position: PopoverPosition.BOTTOM,
              }}
            />
          </div>
        </div>
      </div>
      {savedFilters$ && (
        <Grid
          cache={props.cache}
          setCache={props.setCache}
          focusedListName={listName}
          agGridOptions={{
            onBodyScroll: handleScroll,
          }}
        />
      )}
    </>
  )
}

export default withNamespaces()(List)
