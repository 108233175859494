import { Button, InputGroup, InputGroupProps } from '@blueprintjs/core'
import React, { FC, useState } from 'react'
import { HTMLInputProps } from '@blueprintjs/core/src/common/props'
import { Tooltip } from '../../stories/Tooltip'
import { t } from 'i18next'

export const PasswordButton: FC<HTMLInputProps & InputGroupProps> = ({ onChange, className, id, intent, placeholder = `${t('Password')}` }) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <InputGroup
      placeholder={placeholder}
      rightElement={
        <Tooltip openOnTargetFocus={false}>
          <Button icon={showPassword ? 'eye-open' : 'eye-off'} minimal={true} onClick={() => setShowPassword(!showPassword)} />
          <div>{showPassword ? 'Hide' : 'Show'} Password</div>
        </Tooltip>
      }
      type={showPassword ? 'text' : 'password'}
      fill={true}
      id={id}
      intent={intent}
      className={className}
      onChange={onChange}
    />
  )
}
