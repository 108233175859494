import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import styles from '../Standard/index.module.scss'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { t } from 'i18next'

const NotFound: FC<WithNamespaces> = () => {
  return (
    <div className={styles.notFound}>
      <h2 className={styles.notFoundMessage}>
        {t('The URL you entered doesn`t seem to match any page')} (<code>{useLocation().pathname}</code>)
      </h2>
    </div>
  )
}

export default withNamespaces()(NotFound)
