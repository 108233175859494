import { ModuleRegistry } from '@ag-grid-community/core'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { LicenseManager } from '@ag-grid-enterprise/core'
import { Settings } from 'luxon'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import ahoy from 'ahoy.js'
import { FocusStyleManager } from '@blueprintjs/core'
import i18n from './i18n'

export function globalInit(): void {
  // ag-grid: license activation
  LicenseManager.setLicenseKey(
    `CompanyName=Ekohe,LicensedApplication=Kurrant AI,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,
LicensedProductionInstancesCount=1,AssetReference=AG-022835,ExpiryDate=7_January_2023_[v2]_MTY3MzA0OTYwMDAwMA==d1a0263278a90cf2573c204dce2ece92`
  )
  // ag-grid: only load necessary modules to optimize bundle size
  ModuleRegistry.registerModules([ColumnsToolPanelModule, ServerSideRowModelModule, MenuModule, StatusBarModule])

  // luxon
  Settings.defaultLocale = 'en'

  // ahoy
  ahoy.configure({ urlPrefix: '/api/camp' })
  ahoy.debug(false)

  // blueprintjs: don't show focus outline on mouse interactions
  // see https://blueprintjs.com/docs/#core/accessibility.focus-management
  FocusStyleManager.onlyShowFocusOnTabs()

  let language = navigator.language
  if (language == 'ja') {
    language = 'jp'
  }
  if (!localStorage.getItem('language')) {
    i18n.changeLanguage(language)
    localStorage.setItem('language', language)
  }
}
