import { Button, ActionProps, Icon } from '@blueprintjs/core'
import React, { FC } from 'react'
import styles from './CollapseButton.module.scss'
import { t } from 'i18next'
import { withNamespaces, WithNamespaces } from 'react-i18next'

const CollapseButton: FC<{ isCollapsed: boolean; onClick: ActionProps['onClick'] } & WithNamespaces> = ({ isCollapsed, onClick }) => {
  return (
    <div>
      <Button
        className={styles.collapseButtonStyle}
        intent="primary"
        minimal={true}
        icon={<Icon icon={isCollapsed ? 'double-chevron-right' : 'double-chevron-left'} iconSize={20} />}
        text={isCollapsed ? '' : t('Collapse')}
        alignText={'left'}
        onClick={onClick}
      />
    </div>
  )
}

export default withNamespaces()(CollapseButton)
