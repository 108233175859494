import React, { CSSProperties, FC } from 'react'
import { Button, ButtonProps, Text } from '@blueprintjs/core'

export interface ButtonShowingSelectionProps extends ButtonProps {
  text?: string
  style?: CSSProperties
}

export const ButtonShowingSelection: FC<ButtonShowingSelectionProps> = ({ text, ...buttonProps }) => {
  return (
    <Button rightIcon={'caret-down'} alignText={'left'} {...buttonProps}>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <Text ellipsize={true}>{text}</Text>
      </div>
    </Button>
  )
}
