import { ColDef, ColGroupDef, ColumnApi, FirstDataRenderedEvent, GridApi, ICellRendererParams, SortChangedEvent } from '@ag-grid-community/core'
import { internationalUnits, localDate, year } from '../ValueFormatters'
import { AgGridRequest, AgGridState, BackendCall, ListData, ResponseForAgGrid } from '../Backend'
import { LocalStorage } from '../ClientSideStorage'
import { showGenericErrorToast } from '../Toaster'
import { FilterModel } from '../../ui/components/Filters'
import { DebouncedInputGroupState } from '../../ui/components/DebounchedInputGroup'
import { MultiSelectState } from '../../stories/Filtering/Filters/MultiSelect'
import { NumberRangePickerState } from '../../stories/Filtering/Filters/NumberRangePicker'
import i18next from 'i18next'

export class ServerSideDatasource<T> {
  abortControllers = new Array<AbortController>()
  static listId?: ListData['id']
  static searchBar?: string
  static filterModel?: FilterModel

  constructor(
    api: GridApi | undefined,
    backendCallFn: BackendCall<AgGridRequest, ResponseForAgGrid<T>>,
    afterResponseCallback?: (response: ResponseForAgGrid<T>, exactCountResources?: any) => void
  ) {
    api?.setServerSideDatasource({
      getRows: async (params) => {
        try {
          const response = await backendCallFn(
            {
              ...params.request,
              listId: ServerSideDatasource.listId,
              filterModel: ServerSideDatasource.compactFilterModel(),
            },
            this.getNewAbortControllerSignal()
          )
          afterResponseCallback?.(response, { params, listId: ServerSideDatasource.listId, filterModel: ServerSideDatasource.compactFilterModel() })
          if (response.rowsCount === 0) {
            api?.showNoRowsOverlay()
          }
          params.success({ rowData: response.rows, rowCount: response.rowsCount })
        } catch (error) {
          params.fail()
          if (error.name !== 'AbortError') {
            showGenericErrorToast(error)
          }
        } finally {
          // for some reason the rowDataChanged event is not trigger after data is retrieved from the server
          // we force the event emission
          params.api.dispatchEvent({ type: 'rowDataChanged' })
        }
      },
    })
  }

  static updateFilterModelWithSearchBar(): void {
    if (ServerSideDatasource.filterModel === undefined) {
      ServerSideDatasource.filterModel = []
    }
    const f = ServerSideDatasource.filterModel.find((f) => f.column == 'search_bar' && f.searchLogic == 'containsAll')
    if (f) {
      ;(f.state as DebouncedInputGroupState).text = ServerSideDatasource.searchBar
    } else {
      ServerSideDatasource.filterModel.push({
        column: 'search_bar',
        filterType: 'text',
        searchLogic: 'containsAll',
        state: { text: ServerSideDatasource.searchBar },
      })
    }
  }

  static compactFilterModel(): FilterModel {
    ServerSideDatasource.updateFilterModelWithSearchBar()
    return (
      ServerSideDatasource.filterModel?.reduce((acc, e) => {
        if (e.filterType == 'text') {
          const value = (e.state as DebouncedInputGroupState)?.text
          if (value !== undefined && value !== '') {
            return [...acc, e]
          }
        }
        //search logic for the contains filterType, uses the 'containsAll' search logic
        // UI displays contains instead of containsALL,
        //added for the filters that only use 1 data point, as it makes more sense then the containsAny or containsAll
        if (e.filterType == 'contains') {
          const value = (e.state as MultiSelectState)?.selectedItems
          if (value !== undefined && value.length > 0) {
            e.filterType = 'set'
            if (e.searchLogic == 'contains') {
              e.searchLogic = 'containsAll'
            }
            return [...acc, e]
          }
        }
        if (e.filterType == 'set') {
          const value = (e.state as MultiSelectState)?.selectedNestedIndustryNames || (e.state as MultiSelectState)?.selectedItems
          if (value !== undefined && value.length > 0) {
            return [...acc, e]
          }
        }
        if (e.filterType == 'numberRange') {
          const start = (e.state as NumberRangePickerState)?.start
          const end = (e.state as NumberRangePickerState)?.end
          if (start !== undefined || end !== undefined) {
            return [...acc, e]
          }
        }
        if (e.filterType == 'socialMedia') {
          return [...acc, e]
        }
        return acc
      }, [] as FilterModel) ?? []
    )
  }

  getNewAbortControllerSignal(): AbortSignal {
    this.cancelPotentialPreviousRequest()
    const abortController = new AbortController()
    this.abortControllers.push(abortController)
    return abortController.signal
  }

  private cancelPotentialPreviousRequest(): void {
    this.abortControllers = this.abortControllers.filter((c) => {
      const aborted = c.signal.aborted
      if (!aborted) {
        c.abort()
      }
      return !aborted
    })
  }
}

export function autoSizeColumns_(event: FirstDataRenderedEvent, columns: string[]): void {
  event.api.removeEventListener('animationQueueEmpty', autoSizeColumns_)
  event.columnApi.autoSizeColumns(columns)
}

export function autoSizeColumns(event: FirstDataRenderedEvent, localStorage: LocalStorage, columns: string[]): void {
  if (localStorage.has('columnState')) {
    return
  }
  // auto-sizing should be done after browser rendering; we wait for the animation queue to be empty
  if (!event.api.isAnimationFrameQueueEmpty()) {
    event.api.addEventListener('animationQueueEmpty', autoSizeColumns_)
  } else {
    autoSizeColumns_(event, columns)
  }
}

function htmlCellRenderer(params: ICellRendererParams): string {
  let v = params.valueFormatted ?? params.value
  if (v && typeof v !== 'string') {
    v = v.toString()
  }
  return v ?? ''
}

export function columnsInitiallyHidden(col: ColDef, columns: string[]): void {
  if (col.field && columns.includes(col.field)) {
    col.initialHide = true
  }
}

export function columnsShowingYear(col: ColDef, columns: string[]): void {
  if (col.field && columns.includes(col.field)) {
    col.valueFormatter = (params) => year(params.value)
    col.tooltipValueGetter = (params) => year(params.value)
    col.sortable = true
  }
}

export function columnsShowingDate(col: ColDef, columns: string[]): void {
  if (col.field && columns.includes(col.field)) {
    const id = col.field
    col.valueGetter = (params) => localDate(params.data?.[id])
    col.tooltipValueGetter = (params) => localDate(params.value)
  }
}

export function standardColumns(col: ColDef, colGroup?: ColGroupDef): void {
  if (colGroup) {
    col.headerClass = colGroup.headerClass
  }
  col.headerTooltip = col.headerName
  col.wrapText = true
  if (!col.tooltipValueGetter) {
    col.tooltipValueGetter = (params) => params.valueFormatted || params.value
  }
  if (!col.cellRenderer) {
    col.cellRenderer = htmlCellRenderer
  }
  if (!col.tooltipComponent) {
    col.tooltipComponent = 'HtmlTooltip'
  }
}

export const moneyColDef: ColDef = {
  valueFormatter: (params) => internationalUnits(params.value, 1, '$'),
  sortable: true,
}

export function selectedRowsLabel(selectedRowsCount: number, entity: 'Company' | 'List'): string {
  return `${selectedRowsCount.toLocaleString()} ${
    selectedRowsCount > 1 || selectedRowsCount == 0
      ? i18next.t('selected_entities', { entity: entity === 'Company' ? 'Companies' : entity + 's' })
      : i18next.t('selected_entities', { entity: entity })
  }`
}

export function packCompanyQueryParams(columnApi?: ColumnApi): AgGridState {
  return {
    ...(ServerSideDatasource.listId && { listId: ServerSideDatasource.listId }),
    filterModel: ServerSideDatasource.compactFilterModel(),
    sortModel: columnApi
      ?.getColumnState()
      ?.filter((colState) => !!colState.sort)
      ?.map((colState) => ({ colId: colState.colId, sort: colState.sort })),
  }
}

export function getCurrentSortForAhoy(event: SortChangedEvent): Record<string, string> {
  const sort = event.api.getSortModel()?.[0]
  if (sort) {
    return { [event.api.getColumnDef(sort.colId!)!.headerName!]: sort.sort! }
  } else {
    return {}
  }
}
