// pipelineStatus.ts

export const PIPELINE_STATUSES = {
  0: 'New',
  1: 'Active',
  2: 'Diligence',
  3: 'Won',
  4: 'Missed',
  5: 'Passed',
  6: 'No Status',
}

export const statusPipelineMappings: { [key: string]: number } = {
  new: 0,
  active: 1,
  diligence: 2,
  won: 3,
  missed: 4,
  passed: 5,
  no_status: 6,
}

export const mapStatusToEnum = (status: string): number => {
  return statusPipelineMappings[status.toLowerCase()]
}

export const getAllPipelineStatuses = (): Array<string | null> => {
  return [...Object.keys(PIPELINE_STATUSES), null]
}

export const mapEnumToStatus = (enumValue: number): string => {
  return PIPELINE_STATUSES[enumValue] || 'No Status'
}

export const pipelineStatusesForMultiSelect = (): string[] => {
  return [...Object.values(PIPELINE_STATUSES).map((status) => status)]
}
