import React, { FC } from 'react'
import { Button } from '@blueprintjs/core'
import { withNamespaces, WithNamespaces } from 'react-i18next'

const LayoutResetUi: FC<WithNamespaces & IncludeT> = ({ t }) => {
  function restoreDefaultLayout() {
    Object.keys(localStorage).forEach((key) => {
      if (key.endsWith('columnState')) {
        localStorage.removeItem(key)
      }
    })
    location.reload()
  }

  return <Button text={t('Restore default layout')} onClick={restoreDefaultLayout} />
}

export default withNamespaces()(LayoutResetUi)
