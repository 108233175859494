import React, { FC, Fragment, useState } from 'react'
import { OmniFilter, OmniFilterState } from './OmniFilter'
import styles from './FiltersPanel.module.scss'
import { Button } from '@blueprintjs/core'
import equal from 'fast-deep-equal/es6'
import { FilterModel, FilterModelUtils } from '../../ui/components/Filters'
import { withNamespaces, WithNamespaces, Trans } from 'react-i18next'

export interface FiltersPanelProps {
  onChange?: (filterModel: FilterModel) => void
}

export interface FiltersPanelState {
  filterModel?: FilterModel
}

const FiltersPanel: FC<FiltersPanelProps & FiltersPanelState & WithNamespaces & IncludeT> = ({ filterModel, onChange, t }) => {
  const [filterModelProp, setFilterModelProp] = useState(filterModel)
  const [filters$, setFilters$] = useState([...FilterModelUtils.deserialize(filterModel), {}])

  if (!equal(filterModel, filterModelProp)) {
    setFilterModelProp(filterModel)
    setFilters$([...FilterModelUtils.deserialize(filterModel), {}])
  }

  function onChangeOmniFilter(state: OmniFilterState, rowIndex: number): void {
    filters$[rowIndex] = state
    if (rowIndex === filters$.length - 1) {
      filters$.push({})
    }
    const newFilters = [...filters$]
    setFilters$(newFilters)
    onChange?.(FilterModelUtils.serialize(newFilters))
  }

  function onDelete(rowIndex: number): void {
    const newFilters = filters$.filter((_, i2) => i2 !== rowIndex)
    setFilters$(newFilters)
    onChange?.(FilterModelUtils.serialize(newFilters))
  }

  return (
    <div className={styles.panel}>
      <h6 className="bp3-heading">{t('Filter')}</h6>
      <h6 className="bp3-heading">{t('Search Logic')}</h6>
      <h6 className="bp3-heading">{t('Input')}</h6>
      <h6 className="bp3-heading" style={{ whiteSpace: 'pre-line' }}>
        {t('companies_without_data')}
      </h6>
      <h6 className="bp3-heading">{t('Remove')}</h6>
      {filters$.map((f, i) => (
        <Fragment key={f?.filter?.id + '-' + i}>
          <OmniFilter {...f} onChange={(state) => onChangeOmniFilter(state, i)} />
          <div>{i !== filters$.length - 1 && <Button icon={'trash'} minimal={true} small={true} onClick={() => onDelete(i)} />}</div>
        </Fragment>
      ))}
    </div>
  )
}

export default withNamespaces()(FiltersPanel)
