import React, { FC } from 'react'
import { Breadcrumb, Breadcrumbs, IBreadcrumbProps, IBreadcrumbsProps } from '@blueprintjs/core'
import { NavLink } from 'react-router-dom'
import { withNamespaces, WithNamespaces } from 'react-i18next'

const breadcrumbRenderer: IBreadcrumbsProps['breadcrumbRenderer'] = (props) => {
  return props.current ? (
    <BreadcrumbWithoutHref {...props} />
  ) : (
    <NavLink to={props.href!}>
      <BreadcrumbWithoutHref {...props} />
    </NavLink>
  )
}

const BreadcrumbWithoutHref: FC<IBreadcrumbProps> = (props) => {
  return <Breadcrumb {...props} href={undefined} />
}

const BreadcrumbsNavigation: FC<IBreadcrumbsProps & WithNamespaces & IncludeT> = (props) => {
  const { items, t } = props
  items[0].text = t(items[0].text)
  return <Breadcrumbs breadcrumbRenderer={breadcrumbRenderer} items={items} overflowListProps={{ ...props.overflowListProps, observeParents: true }} />
}

export default withNamespaces()(BreadcrumbsNavigation)
