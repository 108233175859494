import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { InterpretedHtml } from '../../logic/ValueFormatters'

export const HtmlTooltip = forwardRef(({ value }: { value: string | undefined }, ref) => {
  const [data] = useState(value?.replace(/&lt;/g, '<')?.replace(/&gt;/g, '>') ?? '')

  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses() {
        return ['ag-tooltip']
      },
    }
  })

  if (data === undefined) {
    return <></>
  }

  return <InterpretedHtml html={data} />
})
