import React, { useState } from 'react'
import styles from './styles.module.scss'
import { Icon } from '@blueprintjs/core'
import { DateTime } from 'luxon'

type ChatHistoryType = {
  created_at: string
  id: string
  title: string
}
interface Props {
  chats?: ChatHistoryType[]
  handleItemClick: (id: string) => void
}

const ChatHistory = (props: Props) => {
  const { chats, handleItemClick } = props
  const [showChatHistory, setShowChatHistory] = useState(true)
  const timePartitions = {
    today: [],
    thisWeek: [],
    thisMonth: [],
    older: [],
  }

  if (chats) {
    for (const chat of chats) {
      if (partitionDates(chat.created_at) === 'today') {
        timePartitions.today.push(chat)
      } else if (partitionDates(chat.created_at) === 'week') {
        timePartitions.thisWeek.push(chat)
      } else if (partitionDates(chat.created_at) === 'month') {
        timePartitions.thisMonth.push(chat)
      } else {
        timePartitions.older.push(chat)
      }
    }
  }

  return (
    <div className={`${styles.chatHistoryContainer}`}>
      {showChatHistory ? <h2 className={styles.header}>Your Searches</h2> : <h2>Expand</h2>}
      <div className={`${showChatHistory ? styles.expanded : styles.shrunk} ${styles.chatHistoryList}`}>
        <div className={styles.toggleViewButton}>
          <button onClick={() => setShowChatHistory(!showChatHistory)}>
            {showChatHistory ? <Icon icon="double-chevron-right" /> : <Icon icon="double-chevron-left" />}
          </button>
        </div>

        {showChatHistory && (
          <div className={styles.chatSessionList}>
            {Object.keys(timePartitions).map((key) => {
              if (timePartitions[key].length === 0) return null
              return (
                <div key={key}>
                  <div className={styles.timePartitionHeader}>{formatKeyText(key)}</div>
                  {timePartitions[key].map((chat, index) => (
                    <div key={index} className={styles.chatHistoryItem} onClick={() => handleItemClick(chat.id)}>
                      <p>Chat #{index}</p>
                    </div>
                  ))}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

function partitionDates(dateString: string): string {
  const date = DateTime.fromISO(dateString).startOf('day')
  const now = DateTime.now().startOf('day')
  const diff = now.diff(date, 'days').toObject()

  if (diff.days !== undefined) {
    if (diff.days < 1) return 'today'
    if (diff.days < 7) return `week`
    if (diff.days <= 30) return `month`
    return 'older'
  }
}

function formatKeyText(heading: string) {
  if (heading === 'today') return 'Today'
  if (heading === 'thisWeek') return 'Pevious 7 Days'
  if (heading === 'thisMonth') return 'Previous 30 Days'
  if (heading === 'older') return 'Older'
}

export default ChatHistory
