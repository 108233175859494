import React, { useEffect, useState } from 'react'
import { Button } from '@blueprintjs/core'
import { currentUser, updatePipelineData } from '../../../../logic/Backend'
import '../styles/addToPipelinePopup.scss'
import { Explanation } from '../../Companies/Explanation'
import { pluralize } from '../../../../logic/ValueFormatters'
import { GridApi } from '@ag-grid-community/core'

export interface AddToPipelinePopupProps {
  count: number
  api?: GridApi
}

export const AddToPipelinePopup: React.FC<AddToPipelinePopupProps> = ({ api }) => {
  const [selectedRows, setSeletedRows] = useState(api?.getSelectedRows() || [])
  const numberOfSelectedRows = selectedRows.length
  const [currentUsername, setCurrentUsername] = useState('')
  const [alreadyInPipeline, setAlreadyInPipeline] = useState(selectedRows.filter((company) => company.pipeline_status !== 6))
  const [eligibleCompanies, setEligibleCompanies] = useState(selectedRows.filter((company) => company.pipeline_status === 6))
  const [secondText, setSecondText] = useState('')
  useEffect(() => {
    const fetchUserData = async () => {
      const userData = await currentUser()
      setCurrentUsername(userData.name)
    }
    fetchUserData()
  }, [])
  useEffect(() => {
    setAlreadyInPipeline(selectedRows.filter((company) => company.pipeline_status !== 6))
    setEligibleCompanies(selectedRows.filter((company) => company.pipeline_status === null || company.pipeline_status === 6))
  }, [selectedRows, currentUsername])

  useEffect(() => {
    if (alreadyInPipeline.length === 0 && eligibleCompanies.length > 0) {
      setSecondText(`Add ${eligibleCompanies.length} ${pluralize('compan', 'y', 'ies', eligibleCompanies.length)} to your pipeline?`)
    } else if (alreadyInPipeline.length > 0 && eligibleCompanies.length === 0) {
      setSecondText(
        `All of the selected companies already exist in the pipeline and cannot be added. If you want to change the pipeline status, you can select a different status under the Pipeline column.`
      )
    } else if (alreadyInPipeline.length > 0 && eligibleCompanies.length > 0) {
      setSecondText(
        `${alreadyInPipeline.length} ${pluralize(
          'compan',
          'y',
          'ies',
          alreadyInPipeline.length
        )} within the selection already exist in the pipeline.  Add the other ${eligibleCompanies.length} ${pluralize(
          'compan',
          'y',
          'ies',
          eligibleCompanies.length
        )} to the pipeline?`
      )
    }
  }, [selectedRows])

  const addToPipeline = async () => {
    const orgIds = eligibleCompanies.map((company) => company.id)
    try {
      await updatePipelineData({ organization_ids: orgIds, pipeline_status: 'new' })
    } catch (error) {
      console.log(error)
    } finally {
      window.location.reload() //is this the required effect, or should this stay consistent with updating single items?
    }
  }
  const firstText =
    numberOfSelectedRows === 0
      ? `Set the pipeline status of the first 50,000 companies?`
      : `${numberOfSelectedRows} ${pluralize('compan', 'y', 'ies', numberOfSelectedRows)} ${pluralize('', 'has', 'have', numberOfSelectedRows)} been selected.`
  return (
    <>
      <Explanation texts={[firstText, secondText]}></Explanation>
      <Button intent="primary" onClick={addToPipeline} disabled={eligibleCompanies.length === 0} text="Add to Pipeline" />
    </>
  )
}
