import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Button, Dialog, FormGroup, InputGroup, Intent } from '@blueprintjs/core'
import waves from '../../resources/images/form-deco-waves.jpg'
import palette from '../../_palette.module.scss'
import { InvestmentProfileCriteria } from '../InvestmentProfile/InvestmentProfileCriteria'
import { OmniFilter } from '../Filtering/OmniFilter'
import { allFiltersMap } from '../../ui/components/Filters'
import { MultiSelectState } from '../Filtering/Filters/MultiSelect'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { newInvestmentProfile, NewInvestmentProfileRequest } from '../../logic/Backend'
import { showErrorToast } from '../../logic/Toaster'
import { NumberRangePickerState } from '../Filtering/Filters/NumberRangePicker'
import { t } from 'i18next'

export interface IpOnboardingDialogProps {
  backendNewInvestmentProfile: typeof newInvestmentProfile
}

export const IpOnboardingDialog: FC<IpOnboardingDialogProps> = ({ backendNewInvestmentProfile }) => {
  const [page, setPage] = useState(1)
  const [dialogIsOpen, setDialogIsOpen] = useState(true)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      industries: undefined,
      industries_weight: 3,
      countries: undefined,
      countries_weight: 3,
      funding_types: undefined,
      funding_types_weight: 3,
      total_funding_usd_int8range: undefined,
      total_funding_usd_int8range_weight: 3,
      employee_count_int4range: undefined,
      employee_count_int4range_weight: 3,
      founded_on_int4range: undefined,
      founded_on_int4range_weight: 3,
    },
    validationSchema: yup.object({
      name: yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      const ip: NewInvestmentProfileRequest = {
        ...values,
        total_funding_usd_int8range: forBackend(values.total_funding_usd_int8range),
        employee_count_int4range: forBackend(values.employee_count_int4range),
        founded_on_int4range: forBackend(values.founded_on_int4range),
      }
      try {
        await backendNewInvestmentProfile(ip)
        setPage(page + 1)
      } catch (error) {
        if (error.message.match(/status: (\d+)/)?.[1] === '417') {
          formik.setFieldError('name', 'This name is already taken')
        } else {
          showErrorToast('Failed to create investment profile')
        }
      }
    },
  })

  useEffect(() => {
    const errors = Object.keys(formik.errors)
    if (errors.length > 0) {
      document.getElementById(errors[0])?.focus()
    }
  }, [formik])

  function intent(key: keyof typeof formik.values): Intent {
    return formik.touched[key] && Boolean(formik.errors[key]) ? 'danger' : 'none'
  }

  function helperText(key: keyof typeof formik.values): string | undefined {
    return formik.touched[key] ? (formik.errors[key] as string) : undefined
  }

  function forBackend(state?: NumberRangePickerState): string | undefined {
    if (state === undefined) {
      return undefined
    }
    return [state.start, state.end].map((x) => x?.toString() ?? 'Any').join('...')
  }
  // {t('Profile Name')}
  function Header(): ReactElement {
    const pageTitles = [
      t('Welcome to Kurrant.ai!'),
      t('Let’s set up your profile!'),
      t('Half way there!'),
      t('Almost ready!'),
      t('Final Step!'),
      t('First Investment Profile Created'),
    ]

    return (
      <h3
        style={{
          margin: '0',
          padding: '20px 0',
          fontSize: '24px',
          width: 'fit-content',
          backgroundImage: 'linear-gradient(90deg, #4D52DE, #9855E3)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        {pageTitles[page - 1]}
      </h3>
    )
  }

  function Footer(): ReactElement {
    function ProgressIndicator(): ReactElement {
      return (
        <div style={{ display: 'flex', gap: '14px', alignItems: 'center' }}>
          <div className={'caption'} style={{ fontWeight: '400', color: palette.darkGray5, fontVariantNumeric: 'tabular-nums' }}>
            STEP {page - 1}/4
          </div>
          <div style={{ display: 'flex', gap: '8px' }}>
            {Array.from({ length: 4 }, (_, i) => (
              <span
                key={i}
                style={{
                  width: '16px',
                  height: '2px',
                  borderRadius: '2px',
                  display: 'inline-block',
                  background: i === page - 2 ? palette.blue3 : palette.darkGray5,
                }}
              ></span>
            ))}
          </div>
        </div>
      )
    }

    function PageNavigation(): ReactElement {
      const isSubmitPage = page >= 5
      return (
        <div style={{ marginLeft: 'auto', display: 'flex', gap: '8px' }}>
          <Button text={t('Previous Step')} minimal={true} onClick={() => setPage(page - 1)} disabled={formik.isValidating || formik.isSubmitting} />
          <Button
            text={isSubmitPage ? t('Finish Setup') : t('Next Step')}
            intent={'primary'}
            onClick={() => (isSubmitPage ? formik.submitForm() : setPage(page + 1))}
            loading={formik.isValidating || formik.isSubmitting}
          />
        </div>
      )
    }

    return (
      <div style={{ marginTop: 'auto', display: 'flex' }}>
        {ProgressIndicator()}
        {PageNavigation()}
      </div>
    )
  }

  function Page1(): ReactElement {
    return (
      <>
        <div style={{ fontSize: '16px' }}>
          {t('Let’s get you set up. We will start with creating an Investment Profile to jumpstart Kurrant.ai’s personalized company recommendations.')}
        </div>
        <div style={{ marginTop: 'auto' }}>
          <Button text={t('Create Investment Profile')} intent={'primary'} onClick={() => setPage(page + 1)} />
        </div>
      </>
    )
  }

  function Page2(): ReactElement {
    function Page2Header(): ReactElement {
      return (
        <div style={{ fontSize: '16px' }}>
          {t(
            'When you create an Investment Profile, Kurrant’s AI learns about your investment preferences and will use this information to provide the following custom company ratings: love, like, later, pass.'
          )}
        </div>
      )
    }

    function Page2IpCriterias(): ReactElement {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '32px', margin: '32px 0' }}>
          <InvestmentProfileCriteria
            initialWeight={formik.values.industries_weight}
            onChangeWeight={(w) => formik.setFieldValue('industries_weight', w)}
            description={t('Select the industries and/or sub-industries in which you’re interested.')}
          >
            <OmniFilter
              filter={allFiltersMap['industries']}
              searchLogic={'containsAny'}
              standalone={true}
              noSwitch={true}
              fixed={true}
              filterState={{ selectedItems: formik.values.industries }}
              onChange={(state) => {
                return formik.setFieldValue('industries', (state.filterState as MultiSelectState).selectedNestedIndustryNames)
              }}
            />
          </InvestmentProfileCriteria>
          <InvestmentProfileCriteria
            initialWeight={formik.values.countries_weight}
            onChangeWeight={(w) => formik.setFieldValue('countries_weight', w)}
            description={t('Select the countries in which you want your target companies to be located.')}
          >
            <OmniFilter
              filter={allFiltersMap['country_iso_3166_1_alpha_3']}
              searchLogic={'containsAny'}
              standalone={true}
              noSwitch={true}
              fixed={true}
              filterState={{ selectedItems: formik.values.countries }}
              onChange={(state) => formik.setFieldValue('countries', (state.filterState as MultiSelectState).selectedItems)}
            />
          </InvestmentProfileCriteria>
        </div>
      )
    }

    return (
      <>
        {Page2Header()}
        {Page2IpCriterias()}
        {Footer()}
      </>
    )
  }

  function Page3(): ReactElement {
    function Page3Header(): ReactElement {
      return (
        <div style={{ fontSize: '16px' }}>
          {t(
            'As you source and apply your own ratings to the companies in our database, Kurrant.ai will learn from your feedback and provide even more accurate recommendations over time.'
          )}
        </div>
      )
    }

    function Page3IpCriterias(): ReactElement {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '32px', margin: '32px 0' }}>
          <InvestmentProfileCriteria
            initialWeight={formik.values.employee_count_int4range_weight}
            onChangeWeight={(w) => formik.setFieldValue('employee_count_int4range_weight', w)}
            description={t('Select the company size of your target companies.')}
          >
            <OmniFilter
              filter={allFiltersMap['employee_count_int4range']}
              searchLogic={'between'}
              standalone={true}
              noSwitch={true}
              fixed={true}
              filterState={formik.values.employee_count_int4range}
              onChange={(state) => formik.setFieldValue('employee_count_int4range', state.filterState as NumberRangePickerState)}
            />
          </InvestmentProfileCriteria>
          <InvestmentProfileCriteria
            initialWeight={formik.values.founded_on_int4range_weight}
            onChangeWeight={(w) => formik.setFieldValue('founded_on_int4range_weight', w)}
            description={t('Select the year founded range for your target companies.')}
          >
            <OmniFilter
              filter={allFiltersMap['founded_on_date']}
              searchLogic={'between'}
              standalone={true}
              noSwitch={true}
              fixed={true}
              filterState={formik.values.founded_on_int4range}
              onChange={(state) => formik.setFieldValue('founded_on_int4range', state.filterState as NumberRangePickerState)}
            />
          </InvestmentProfileCriteria>
        </div>
      )
    }

    return (
      <>
        {Page3Header()}
        {Page3IpCriterias()}
        {Footer()}
      </>
    )
  }

  function Page4(): ReactElement {
    function Page4IpCriterias(): ReactElement {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '32px', margin: '0 0 32px 0' }}>
          <InvestmentProfileCriteria
            initialWeight={formik.values.funding_types_weight}
            onChangeWeight={(w) => formik.setFieldValue('funding_types_weight', w)}
            description={t('Select the preferred funding stage(s) of your target companies.')}
          >
            <OmniFilter
              filter={allFiltersMap['latest_funding_type']}
              searchLogic={'containsAny'}
              standalone={true}
              noSwitch={true}
              fixed={true}
              filterState={{ selectedItems: formik.values.funding_types }}
              onChange={(state) => formik.setFieldValue('funding_types', (state.filterState as MultiSelectState).selectedItems)}
            />
          </InvestmentProfileCriteria>
          <InvestmentProfileCriteria
            initialWeight={formik.values.total_funding_usd_int8range_weight}
            onChangeWeight={(w) => formik.setFieldValue('total_funding_usd_int8range_weight', w)}
            description={t('Select the prior total funding amount of your target companies.')}
          >
            <OmniFilter
              filter={allFiltersMap['total_funding_usd_bigint']}
              searchLogic={'between'}
              standalone={true}
              noSwitch={true}
              fixed={true}
              filterState={formik.values.total_funding_usd_int8range}
              onChange={(state) => formik.setFieldValue('total_funding_usd_int8range', state.filterState as NumberRangePickerState)}
            />
          </InvestmentProfileCriteria>
        </div>
      )
    }

    return (
      <>
        {Page4IpCriterias()}
        {Footer()}
      </>
    )
  }

  function Page5(): ReactElement {
    function Page5Header(): ReactElement {
      return (
        <div style={{ fontSize: '16px', marginBottom: '32px' }}>
          {t('Kurrant.ai Ratings will be refreshed on a monthly basis. Remember, the more you rate, the smarter your AI becomes!')}
        </div>
      )
    }

    function Page5IpCriterias(): ReactElement {
      return (
        <FormGroup
          intent={intent('name')}
          label={t('Name your Investment Profile')}
          labelFor="name"
          helperText={helperText('name')}
          style={{ fontSize: '16px', fontWeight: '600' }}
        >
          <InputGroup
            intent={intent('name')}
            placeholder={t('Enter your profile name…') || ''}
            id="name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </FormGroup>
      )
    }

    return (
      <>
        {Page5Header()}
        {Page5IpCriterias()}
        {Footer()}
      </>
    )
  }

  function Page6(): ReactElement {
    return (
      <>
        <div style={{ fontSize: '16px' }}>
          {t('Investment profile successfully created!')}
          <br />
          <br />
          {t('You can continue using Kurrant.ai as ratings are being generated. Kurrant.ai ratings will appear automatically once they’re ready.')}
        </div>
        <div style={{ marginTop: 'auto' }}>
          <Button text={t('Start Using Kurrant.ai')} intent={'primary'} onClick={async () => location.reload()} />
        </div>
      </>
    )
  }

  return (
    <Dialog
      isOpen={dialogIsOpen}
      style={{ minHeight: '565px', width: '1088px', padding: '40px', borderRadius: '3px', background: `url(${waves}) white right center/contain no-repeat` }}
    >
      <div style={{ width: '616px', height: '100%', display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
        {Header()}
        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
          {page === 1 && Page1()}
          {page === 2 && Page2()}
          {page === 3 && Page3()}
          {page === 4 && Page4()}
          {page === 5 && Page5()}
          {page === 6 && Page6()}
        </div>
      </div>
    </Dialog>
  )
}
