import { ColDef, ICellRendererParams, ITooltipParams, ValueFormatterParams } from '@ag-grid-community/core'
import { CompanyData, ListData, ListsPageData, UserData } from './Backend'
import React, { FC, ReactElement, ReactNode } from 'react'
import { DateTime } from 'luxon'
import { queryIndex } from './Helpers'
import { COMPANY_TYPES } from '../resources/reference_data/company_types'
import { OPERATING_STATUS_MAP } from '../resources/reference_data/operating_statuses'
import { COUNTRIES_MAP } from '../resources/reference_data/countries'
import { STATES_MAP } from '../resources/reference_data/states'
import { FUNDING_TYPES } from '../resources/reference_data/funding_types'
import { RATINGS_WITH_UNRATED } from '../resources/reference_data/ratings'
import { NumberRangePickerState } from '../stories/Filtering/Filters/NumberRangePicker'
import linkedin_icon from '../resources/images/linkedin.svg'
import twitter_icon from '../resources/images/twitter.svg'
import facebook_icon from '../resources/images/facebook.svg'
import instagram_icon from '../resources/images/instagram.svg'
import pinterest_icon from '../resources/images/pinterest.svg'
import email_icon from '../resources/images/email.svg'
import userInitials from 'initials'
import { revenueStreamObject } from '../resources/reference_data/revenue_stream_types'
import { salesTargetObject } from '../resources/reference_data/sales_target_types'

export const range: ColDef['valueFormatter'] = (params) => {
  return rangeString(params.value)
}

export function rangeString(range?: string, cap = { capNumber: 10000, capLabel: '10K+' }, nullValue?: string): string {
  return nullGuard(
    range,
    (value) => {
      const split = value.split(/\.{2,3}/)
      split[1] !== undefined && (split[1] = (Number(split[1]) - 1).toString())
      if (cap && Number(split[1]) > cap.capNumber) {
        return cap.capLabel
      }
      if (value.includes('...')) {
        split[0] = internationalUnits(split[0])
        split[1] = internationalUnits(split[1])
      }
      return split.join('-')
    },
    nullValue
  )
}

export function relativeDate(date: string): string {
  return nullGuard(date, (value) => DateTime.fromFormat(value, 'yyyy-MM-dd').toRelative({ style: 'narrow' }))
}

export function year(date?: string, nullValue = ''): string {
  return nullGuard(date, (date) => date.slice(0, 4), nullValue)
}

export function localDate(date?: string): string {
  return nullGuard(date, (value) => new Date(value).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }))
}

export function userName(users?: UserData[], userId?: UserData['id']): string {
  if (userId) {
    return users?.find((user) => user.id === userId)?.name ?? 'Unknown user'
  }
  return '-'
}

export function withoutHighlight(text?: string): string | undefined {
  return text?.replace(/<\/?strong>/g, '')
}

export const unitTuples: [number, string][] = [
  [1_000_000_000_000, 'T'],
  [1_000_000_000, 'B'],
  [1_000_000, 'M'],
  [1_000, 'K'],
]

export function internationalUnits(amount: string | number | undefined, decimalCount = 0, prefix = '', nullValue = ''): string {
  if (amount === null || amount === undefined) {
    return nullValue
  }
  if (typeof amount === 'string') {
    amount = Number(amount)
  }
  for (const [scale, unit] of unitTuples) {
    if (amount >= scale) {
      return `${prefix}${(amount / scale).toFixed(decimalCount)}${unit}`
    }
  }
  return `${prefix}${amount}`
}

export function totalFundingRecap(company?: CompanyData): string {
  return (
    (company?.funding_round_count_integer && company?.funding_round_count_integer < 2
      ? company?.funding_round_count_integer + ' round for '
      : company?.funding_round_count_integer && company?.funding_round_count_integer > 1
      ? company?.funding_round_count_integer + ' rounds for '
      : '') +
    internationalUnits(company?.total_funding_usd_bigint, 1, '$', 'n/a') +
    (company?.funding_round_count_integer ? ' total' : '')
  )
}

export enum CountryFormat {
  Code,
  Name,
}

export function status(params: ValueFormatterParams): string {
  return nullGuard(params.value, (value) => OPERATING_STATUS_MAP[value])
}

export function liType(s: number | undefined, nullValue = ''): string | undefined {
  //li_type_enum returns a int value from db
  return nullGuard(s, (s) => COMPANY_TYPES[s], nullValue)
}
export const salesTargetParser = (val?: 'unknown' | 'b2b' | 'b2c' | 'b2b_and_b2c', nullValue = 'N/A') => {
  return nullGuard(val, () => val && salesTargetObject.returnValues[val], nullValue)
}
export const revenueParser = (val?: 'non_recurring' | 'recurring', nullValue = 'N/A') => {
  return nullGuard(val, () => val && revenueStreamObject.returnValues[val], nullValue)
}

export const subRegionParser = (val?: string, nullValue = 'N/A') => {
  // need to parse strings such as "Middle East" to "middle_east"
  return nullGuard(
    val,
    () =>
      val &&
      val
        .split(' ')
        .map((word) => word.toLowerCase())
        .join('_')
        .replace('_&_', '_'),
    nullValue
  )
}

export function fundingType(params: ValueFormatterParams): string {
  return nullGuard(params.value, (value) => FUNDING_TYPES[value])
}

export function flagAndCountryName(iso3166_1_alpha3: string | undefined, format: CountryFormat): string | undefined {
  if (iso3166_1_alpha3) {
    const country = COUNTRIES_MAP[iso3166_1_alpha3]
    if (country) {
      if (format === CountryFormat.Code) {
        return country[0] + ' ' + iso3166_1_alpha3
      }
      return country[0] + ' ' + country[1]
    }
    return iso3166_1_alpha3
  }
  return undefined
}

export function highlightText(text: string, query: string): ReactNode {
  if (query === '') {
    return <>{text}</>
  }
  const queryIndexStart = queryIndex(text, query)
  if (queryIndexStart === -1) {
    return <>{text}</>
  }
  const queryIndexEnd = queryIndexStart + query.length
  return (
    <>
      {queryIndexStart > 0 ? text.substring(0, queryIndexStart) : ''}
      <strong>{text.substring(queryIndexStart, queryIndexEnd)}</strong>
      {queryIndexEnd <= text.length ? text.substring(queryIndexEnd, text.length) : ''}
    </>
  )
}

export function stateName(state: string | undefined | null): string {
  if (state) {
    const usaState = STATES_MAP[state]
    if (usaState) {
      return `${usaState} (${state})`
    }
  }
  return ''
}

export function classForRating(ratingIndex: number | null, active: boolean): string {
  if (ratingIndex === null) {
    return `rating`
  }
  const cssClass = RATINGS_WITH_UNRATED[ratingIndex].toLowerCase().replace(' ', '_')
  if (active) {
    return `rating rating__active rating__active__${cssClass}`
  }
  return `rating rating__${cssClass}`
}

export function classForLineClamp(height: number): string {
  if (height > 80 && height < 171) {
    return 'lineClamp4'
  }
  if (height > 170) {
    return 'lineClamp7'
  }
  return 'lineClamp1'
}

export function rating(params: ValueFormatterParams): string {
  return nullGuard(params.value, (value) => {
    return `<span class="rating__label ${classForRating(value, false)}">${RATINGS_WITH_UNRATED[value]}</span>`
  })
}

export function mailtoLinks(value: string | undefined): string {
  return nullGuard(value, (value) => {
    return value === '' ? '' : value.split(', ').map((email: string) => `<a href="mailto:${email}">${email}</a>`)?.[0]
    // .join(' ')
  })
}

export function telLink(params: ITooltipParams): string {
  return nullGuard(params.value, (value) => {
    return value === '{}'
      ? ''
      : value
          .split(', ')
          .map((phone: string) => `<a href="tel:${phone}">${phone} ↗️</a>`)
          .join(' ')
  })
}

export function addressLink(params: ITooltipParams): string {
  return nullGuard(params.value, (value) => {
    return `<a href="https://maps.google.com/?q=${encodeURIComponent(value)}"
title="Open in Google Maps" target="_blank" rel="noopener noreferrer">${value} ↗️</a>`
  })
}

export function addressLinkHref(address?: string): string {
  return nullGuard(address, (value) => {
    return 'https://maps.google.com/?q=' + encodeURIComponent(value)
  })
}

export const industries: (industries?: CompanyData['industries']) => string = (industries) => {
  return nullGuard(industries, (value) => {
    return value.join(', ')
  })
}

export function percentage(percentage?: string): string {
  return nullGuard(percentage, (value) => {
    return `${(Number(value) * 100).toFixed()}%`
  })
}

export function notes(notes?: string): string {
  return notes === undefined || notes === null || notes === '' ? 'n/a' : notes
}

export const listCount: ColDef['valueFormatter'] = (params) => {
  return nullGuard(params.value, (value) => {
    return value.length === 0 ? '' : internationalUnits(value.length.toString(), 1)
  })
}

export const listTooltip = (params: ITooltipParams, lists: Record<ListData['id'], ListData['name']>): string => {
  return nullGuard(params.value, (value: ListData['id'][]) => {
    const items = value.reduce((acc, listId) => (lists[listId] ? acc + `<li>${lists[listId]}</li>` : acc), '')
    return `<ul>${items}</ul>`
  })
}

export function linkedinUrlNormalization(url?: string): string | undefined {
  if (url) {
    const match = url.match(/linkedin\.com\/(?:company(?:-beta)?\/)?([^\/\n]+)/i)?.[1]
    if (match) {
      return `https://www.linkedin.com/company/${match}/`
    }
    return url
  }
  return undefined
}

export function companyLink(homepage?: string, domain?: string): string | undefined {
  const regexp = /^http[s]?:\/\//
  if (homepage) {
    return regexp.test(homepage) ? homepage : '//' + homepage
  }
  if (domain) {
    return '//' + domain
  }
  return undefined
}

export function linkFromUrlOrDomain(urlOrDomain: string): string {
  if (urlOrDomain.startsWith('http')) {
    return urlOrDomain
  }
  return '//' + urlOrDomain
}

export function safeCbPicture(url?: string): string | undefined {
  return url?.includes('placeholder') ? undefined : url
}

export const InterpretedHtml: FC<{ html: string }> = ({ html }) => {
  return <span dangerouslySetInnerHTML={{ __html: html }} />
}

export type Sns = 'facebook' | 'linkedin' | 'twitter' | 'instagram' | 'pinterest' | 'email'

export const SnsLink: FC<ICellRendererParams & { sns: Sns }> = ({ value, sns }) => {
  if (value === undefined || value === null) {
    return <></>
  }
  return (
    <a href={value} title="Visit website" target="_blank" rel="noreferrer" style={{ textTransform: 'capitalize' }}>
      {sns === 'linkedin' ? 'LinkedIn' : sns}
    </a>
  )
}

export function snsImage(sns: Sns, size: number): ReactElement {
  const size_ = { width: size, height: size, style: { display: 'block' } }
  if (sns === 'facebook') {
    return <img src={facebook_icon} alt="Facebook" {...size_} />
  }
  if (sns === 'linkedin') {
    return <img src={linkedin_icon} alt="LinkedIn" {...size_} />
  }
  if (sns === 'pinterest') {
    return <img src={pinterest_icon} alt="Pinterest" {...size_} />
  }
  if (sns === 'twitter') {
    return <img src={twitter_icon} alt="Twitter" {...size_} />
  }
  if (sns === 'instagram') {
    return <img src={instagram_icon} alt="Instagram" {...size_} />
  }
  if (sns === 'email') {
    return <img src={email_icon} alt="Email" {...size_} />
  }
  return <></>
}

function nullGuard<T>(value: T | undefined | null, fn: (v: T) => string | undefined | null, nullValue = ''): string {
  return value === undefined || value === null ? nullValue : fn(value) ?? nullValue
}

export const parseEditAccess = function (value?: boolean | undefined): string {
  if (value) {
    return 'Public'
  }

  return 'Private'
}

export const parseVisibility = function (value?: boolean | undefined): string {
  if (value) {
    return 'Public'
  }

  return 'Private'
}

export function deleteListConfirmationText(list: ListData | ListsPageData): string {
  let message = `Are you sure you want to delete ${list.name}?`
  if (list.publicly_visible) {
    message += ' It will be removed for all organization members.'
  }

  return message
}

export function labelFromVariable(option?: string): string | undefined {
  return option
    ?.split(/(?=[A-Z])/)
    ?.map((x) => x[0].toUpperCase() + x.substring(1))
    ?.join(' ')
}

export function rangeFromBackend(range?: string): NumberRangePickerState | undefined {
  if (range === undefined || range === null) {
    return undefined
  }
  const [start, end] = range.split('...').map((x) => (x.endsWith('Infinity') ? undefined : Number(x)))
  return { start, end: end !== undefined ? end - 1 : undefined }
}

export function pluralize(text: string, singularSuffix: string, pluralSuffix: string, count: number): string {
  return text + (count > 1 ? pluralSuffix : singularSuffix)
}

export function initial(name: string | undefined): string | undefined {
  if (name == undefined || name === '') {
    return undefined
  }
  const initials_ = userInitials(name)
  if (initials_.length === 2) {
    return initials_
  }
  return initials_[0] + initials_[initials_.length - 1]
}
