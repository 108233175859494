import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Dialogue from './Dialogue'
import styles from './styles.module.scss'
import { currentUser, getSearchSessions, getSessionConversation, SessionType, UserData } from '../../../logic/Backend'
import { showErrorToast } from '../../../logic/Toaster'
import ChatHistory from './ChatHistory'

export type ChatState = {
  chatMessagesState: ChatType[]
}

export type ChatType = {
  message: string
  sender: string
  sessionId?: string
}

type chatLoadingState = {
  chatLoadingState: boolean
}

const apiUrl = `${process.env.REACT_APP_SEARCH_BAR_API}?message=`

const Chat = () => {
  const dispatch = useDispatch()
  const latestMessageRef = useRef<null | HTMLDivElement>(null)
  const [user, setUser] = useState<UserData>()
  const [chatSessions, setChatSessions] = useState<any>()
  const chatSessionLog = useSelector((state: ChatState) => state.chatMessagesState)
  const loadingState = useSelector((state: chatLoadingState) => state.chatLoadingState)
  const [sessionId, setSessionId] = useState('')
  useEffect(() => {
    const getCurrentUser = async () => {
      setUser(await currentUser())
    }
    getCurrentUser()
    const getChatHistory = async () => {
      setChatSessions(await getSearchSessions())
    }
    getChatHistory()
  }, [])
  const scrollToBottom = () => {
    latestMessageRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const [value, setValue] = useState('')

  const onValueChange = (inputText: string) => {
    setValue(inputText)
  }

  const onSendMessage = (message: string) => {
    sendMessage(message)
  }
  const onSuggestedConversationClick = (message: string) => {
    sendMessage(message)
  }

  const onChatHistoryItemClick = async (id: string) => {
    try {
      const res = await getSessionConversation({ id })
      dispatch({ type: 'chatMessagesState/populateChatHistory', payload: { chats: res.chats } })
      setSessionId(res.id)
    } catch (e) {
      console.log(e)
    }
  }

  const sendMessage = async (message: string) => {
    dispatch({ type: 'chatMessagesState/updateChatHistory', payload: { message: message, sender: 'user' } })
    dispatch({ type: 'chatLoadingState/updateChatLoadingState', payload: true })
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message, user_id: user.id, session_id: sessionId }),
      })
      const res = await response.json()
      dispatch({ type: 'chatMessagesState/updateChatHistory', payload: { message: res.answer, sender: 'ai', sessionId: res.session_id } })
      setSessionId(res.session_id)
      dispatch({ type: 'chatLoadingState/updateChatLoadingState', payload: false })
    } catch (error) {
      showErrorToast('Error sending message')
      dispatch({ type: 'chatLoadingState/updateChatLoadingState', payload: false })
      return
    }
    return
  }
  useEffect(() => {
    scrollToBottom()
  }, [chatSessionLog.length])
  return (
    <div className={styles.chatPageContainer}>
      <Dialogue
        chatHistory={chatSessionLog}
        onValueChange={onValueChange}
        onSendMessage={onSendMessage}
        value={value}
        loading={loadingState}
        onSuggestedConversationClick={onSuggestedConversationClick}
      />
      <ChatHistory chats={chatSessions?.sessions} handleItemClick={onChatHistoryItemClick} />
    </div>
  )
}
export default Chat
