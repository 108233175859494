import React, { Dispatch, SetStateAction } from 'react'
import { GridApi } from '@ag-grid-community/core'
import ReactDOM from 'react-dom'
import RightSidebar from '../../stories/RightSidebar'
import { Provider } from 'react-redux'
import { store } from '../../store'

export function render(sidebarElement: Element | null, api?: GridApi): void {
  if (api && sidebarElement) {
    ReactDOM.render(
      <Provider key={0} store={store}>
        <RightSidebar api={api} />
      </Provider>,
      sidebarElement
    )
    ;(document.querySelector('.ag-side-buttons')! as HTMLElement).style.display = 'inherit'
  }
}

export function prepareSidebar(sideBarElement: Element | null, setSideBarElement: Dispatch<SetStateAction<Element | null>>): void {
  if (!sideBarElement) {
    setSideBarElement(document.querySelector('.ag-side-buttons')!)
    document.querySelector('.ag-side-bar')!.classList.add('bp3-elevation-2')
  }
}
