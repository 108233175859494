import { createModel } from '@rematch/core'
import { RootModel } from '.'
import { IndustryGroup } from '../resources/hooks/useNestedIndustries'

const initialState: any[] = []
const industryState = createModel<RootModel>()({
  state: initialState,
  reducers: {
    updateIndustryState(state, payload) {
      return { ...state, parsedIndustryData: payload }
    },
    updateUnparsedIndustryState(state, payload) {
      return { ...state, unparsedIndustryData: payload }
    },
    // handle state changes with pure functions
  },

  // effects: (dispatch) => ({
  //   // handle state changes with impure functions.
  //   // use async/await for async actions
  //   async incrementAsync(payload: number, state) {
  //     console.log("This is current root state", state);
  //     await new Promise((resolve) => setTimeout(resolve, 1000));
  //     dispatch.count.increment(payload);
  //   },
  // }),
})

export default industryState
