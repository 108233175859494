import React, { FC, useEffect, useState } from 'react'
import { Button, Icon } from '@blueprintjs/core'
import { CompanyData } from '../../../logic/Backend'
import { GridApi } from '@ag-grid-community/core'
import CompanyLists from '../../components/CompanyLists'
import { LocalStorage } from '../../../logic/ClientSideStorage'
import { withNamespaces, WithNamespaces } from 'react-i18next'

export interface SidePanelProps {
  api?: GridApi
  company?: CompanyData
}

const RightSidebar: FC<SidePanelProps & WithNamespaces & IncludeT> = ({ api, company, t }) => {
  const [localStorage, setLocalStorage] = useState(new LocalStorage('Company/RightSidebar'))
  const [isCollapsed, setIsCollapsed] = useState(localStorage.loadCompanyOpenedRightSidebar())
  const [companyProp, setCompanyProp] = useState(company)
  const [company$, setCompany$] = useState(company)
  const [listCount, setListCount] = useState(company?.list_ids?.length ?? 0)

  if (companyProp !== company) {
    setCompanyProp(company)
    setCompany$(company)
    setListCount(company?.list_ids?.length ?? 0)
  }

  useEffect(() => {
    document.documentElement.style.setProperty('--right-sidebar-width', isCollapsed ? '50px' : '355px')
  }, [isCollapsed])

  function toggle() {
    const newState = !isCollapsed
    setIsCollapsed(newState)
    localStorage.saveCompanyOpenedRightSidebar(newState)
  }

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        padding: '10px 0',
        borderLeft: '1px solid var(--ag-border-color, #babfc7)',
        width: 'var(--right-sidebar-width)',
      }}
    >
      <Button
        intent={'primary'}
        minimal={true}
        text={!isCollapsed && 'Collapse'}
        rightIcon={<Icon icon={isCollapsed ? 'double-chevron-left' : 'double-chevron-right'} iconSize={20} />}
        onClick={() => toggle()}
        style={{ margin: isCollapsed ? '0' : '0 10px 0 auto', fontWeight: '500' }}
      />
      <div style={{ padding: '0 24px', display: isCollapsed ? 'none' : 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
        <div style={{ fontSize: '16px', color: '#333333' }}>{t('company_in_list', { listCount: listCount, plural: listCount !== 1 ? 's' : '' })}</div>
        <CompanyLists api={api} company={company$} updateCount={(c) => setListCount(c)} />
      </div>
    </div>
  )
}

export default withNamespaces()(RightSidebar)
