import React, { FC } from 'react'
import { Alert, IAlertProps, Intent } from '@blueprintjs/core'

export const ConfirmationDialog: FC<{
  text: string
  alertProps?: IAlertProps
}> = (props) => {
  return (
    <Alert intent={Intent.PRIMARY} {...props.alertProps}>
      <h3>{props.text}</h3>
    </Alert>
  )
}
