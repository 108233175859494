import React, { FC } from 'react'
import { Intent, ProgressBar } from '@blueprintjs/core'
import { percentage as percentage_ } from '../../logic/ValueFormatters'
import { ICellRendererParams } from '@ag-grid-community/core'
import styles from './index.module.scss'

export const PercentageBar: FC<ICellRendererParams> = (props) => {
  return (
    <>
      {props.value && (
        <div className={styles.percentageBar}>
          <ProgressBar value={Number(props.value)} intent={Intent.PRIMARY} animate={false} stripes={false} />
          <span>{percentage_(props.value)}</span>
        </div>
      )}
    </>
  )
}
