import React, { FC, useEffect, useRef, useState } from 'react'
import { ColDef, FirstDataRenderedEvent, GridApi, GridOptions, GridReadyEvent } from '@ag-grid-community/core'
import { autoSizeColumns, columnsShowingDate, ServerSideDatasource, standardColumns } from '../../../logic/AgGrid/AgGrid'
import { PercentageBar } from '../../components/PercentageBar'
import { HtmlTooltip } from '../../components/HtmlTooltip'
import { internationalUnits, parseEditAccess, parseVisibility, percentage, userName } from '../../../logic/ValueFormatters'
import * as Backend from '../../../logic/Backend'
import { CompanyData, ListsPageData, UserData } from '../../../logic/Backend'
import { Cache, Set } from '../Standard'
import * as Routes from '../../../logic/Routes'
import { hrefForId } from '../../../logic/Routes'
import { AgGridReact } from '@ag-grid-community/react'
import * as AgGridPagination from '../../../logic/AgGrid/Pagination'
import { PaginationState, prepareStatusBar } from '../../../logic/AgGrid/Pagination'
import { LocalStorage } from '../../../logic/ClientSideStorage'
import { routerHistory } from '../../app/App'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { t } from 'i18next'

const Grid: FC<
  WithNamespaces & {
    selectedRows: ListsPageData[]
    setSelectedRows: (l: ListsPageData[]) => void
    cache?: Cache
    setCache: Set<Cache | undefined>
    users: UserData[]
  }
> = (props) => {
  const usersRef = useRef<UserData[]>([])

  useEffect(() => {
    return () => {
      props.setCache({ ...props.cache, ...({ api: undefined, columnApi: undefined } as Cache) })
    }
  }, [])

  useEffect(() => {
    props.users.forEach((user) => {
      usersRef.current.push(user)
    })
    props.cache?.api?.refreshCells({ columns: ['assignee_id', 'user_id'], force: true })
  }, [props.users])

  const columns = (): ColDef[] => {
    return (
      [
        { field: 'name', headerName: t('Name'), sortable: true },
        { field: 'notes', headerName: t('Notes') },
        {
          field: 'total_organizations',
          headerName: t('Companies'),
          valueFormatter: (params) => internationalUnits(params.value?.toString(), 1),
          sortable: true,
        },
        {
          field: 'progress',
          headerName: t('Progress'),
          cellRenderer: 'PercentageBar',
          valueFormatter: (params) => percentage(params.value),
          sortable: true,
        },
        {
          field: 'user_id',
          headerName: t('Author'),
          valueFormatter: (params) => userName(usersRef.current, params.value),
          sortable: true,
        },
        {
          field: 'assignee_id',
          headerName: t('Assignee'),
          valueFormatter: (params) => userName(usersRef.current, params.value),
        },
        {
          field: 'publicly_visible',
          headerName: t('Visibility'),
          valueFormatter: (params) => parseVisibility(params.value),
        },
        {
          field: 'publicly_editable',
          headerName: t('Edit Access'),
          valueFormatter: (params) => parseEditAccess(params.value),
        },
        { field: 'created_at', headerName: t('Created on'), sortable: true },
        { field: 'updated_at', headerName: t('Updated on'), sortable: true },
      ] as ColDef[]
    ).map((col) => {
      columnsShowingDate(col, ['created_at', 'updated_at'])
      standardColumns(col)
      return col
    })
  }

  const [serverSideDatasource, setServerSideDatasource] = useState<ServerSideDatasource<CompanyData>>()
  const [gridSetupIsReady, setGridSetupIsReady] = useState(false)
  const [statusBarElement, setStatusBarElement] = useState<Element | null>(null)
  const paginationState = useRef<PaginationState>({ currentPage: 1 })
  const [localStorage, setLocalStorage] = useState(new LocalStorage('Lists/Grid'))

  function onFirstDataRendered(event: FirstDataRenderedEvent): void {
    autoSizeColumns(event, localStorage, ['total_organizations', 'progress', 'author', 'created_at', 'updated_at'])
  }

  const onSelectionChanged: GridOptions['onSelectionChanged'] = (event) => {
    props.setSelectedRows(event.api.getSelectedRows())
  }

  const onRowDoubleClicked: GridOptions['onRowDoubleClicked'] = (event) => {
    const list = event.data as ListsPageData
    if (list) {
      routerHistory.push(hrefForId(Routes.list, list.id))
    }
  }

  function updateDataSource_(api?: GridApi) {
    setServerSideDatasource(
      new ServerSideDatasource(api, Backend.listsPage, (response) => {
        AgGridPagination.setRowsCountAndLastPage(paginationState, response, api)
        AgGridPagination.render(paginationState, ['list', 'lists'], statusBarElement, props.cache?.api)
      })
    )
  }

  async function onGridReady(event: GridReadyEvent) {
    props.setCache?.({ ...props.cache, ...({ api: event.api, columnApi: event.columnApi } as Cache) })
    localStorage.loadGridColumnState(event)
    event.columnApi.applyColumnState({ state: [{ colId: 'created_at', sort: 'desc' }] })
    prepareStatusBar(statusBarElement, setStatusBarElement)
    setGridSetupIsReady(true)
  }

  const onSortChanged: GridOptions['onSortChanged'] = (event) => {
    localStorage.saveGridColumnState(event)
    AgGridPagination.setToPage1(paginationState)
    AgGridPagination.render(paginationState, [t('list'), t('lists')], statusBarElement, props.cache?.api)
  }

  const onFilterChanged: GridOptions['onFilterChanged'] = () => {
    AgGridPagination.setToPage1(paginationState)
    AgGridPagination.render(paginationState, [t('list'), t('lists')], statusBarElement, props.cache?.api)
  }

  useEffect(() => {
    if (gridSetupIsReady && props.cache?.api) {
      updateDataSource_(props.cache.api)
    }
  }, [props.cache, gridSetupIsReady])

  return (
    <div className="ag-theme-alpine" style={{ flex: '1 1 auto', height: '100%' }}>
      <AgGridReact
        rowModelType={'serverSide'}
        serverSideStoreType={'partial'}
        rowSelection={'multiple'}
        suppressMultiSort={true}
        pagination={true}
        suppressColumnVirtualisation={true}
        sideBar={false}
        defaultColDef={{
          resizable: true,
          filter: true,
          menuTabs: ['generalMenuTab'],
          sortingOrder: ['desc', 'asc', null],
        }}
        rowHeight={70}
        autoSizePadding={10}
        columnDefs={columns()}
        tooltipShowDelay={0}
        tooltipMouseTrack={true}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        onSelectionChanged={onSelectionChanged}
        onRowDoubleClicked={onRowDoubleClicked}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        onColumnVisible={(e) => localStorage.saveGridColumnState(e)}
        onColumnPinned={(e) => localStorage.saveGridColumnState(e)}
        onColumnResized={(e) => e.finished && localStorage.saveGridColumnState(e)}
        onColumnMoved={(e) => localStorage.saveGridColumnState(e)}
        onColumnGroupOpened={(e) => localStorage.saveGridColumnState(e)}
        suppressContextMenu={true}
        suppressPaginationPanel={true}
        frameworkComponents={{
          HtmlTooltip: HtmlTooltip,
          PercentageBar: PercentageBar,
        }}
      />
    </div>
  )
}

export default withNamespaces()(Grid)
