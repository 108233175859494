import React, { FC } from 'react'
import { Cache } from '../Standard'
import InvestmentProfilePicker from '../../../stories/InvestmentProfile/InvestmentProfilePicker'
import { ServerSideDatasource } from '../../../logic/AgGrid/AgGrid'
import { DebounchedInputGroup } from '../../components/DebounchedInputGroup'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { t } from 'i18next'
export const LIST_LIMIT = 50000

export function getSearchResultsMaxMessage(totalRowsCount: number): string {
  if (totalRowsCount < LIST_LIMIT) {
    return totalRowsCount.toLocaleString()
  }
  return `${t('First')} ${LIST_LIMIT.toLocaleString()}`
}

interface TopBarContentsProps {
  cache?: Cache
}

const TopBarContents: FC<TopBarContentsProps & WithNamespaces & IncludeT> = ({ cache, t }) => {
  function onChangeSearchBar(text?: string): void {
    ServerSideDatasource.searchBar = text
    cache?.api?.onFilterChanged()
  }

  return (
    <>
      <DebounchedInputGroup placeholder={t('Search company name…')} round={true} onChange_={(s) => onChangeSearchBar(s.text)} />
      <InvestmentProfilePicker initialProfiles={cache?.investmentProfiles} cache={cache} />
    </>
  )
}

export default withNamespaces()(TopBarContents)
