// card.tsx
import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Link } from 'react-router-dom'
import '../styles/card.scss'
import logo from '../../Pipeline/components/logo192.png'

type CardProps = {
  id: string
  index: number
  organization_name: string
  user_name: string
  organization_id: string
  organization_logo: string | null
}

const getLogoUrl = (logo_id) => {
  if (!logo_id) return null
  return `https://images.crunchbase.com/image/upload/${logo_id}`
}

const Card: React.FC<CardProps> = ({ id, index, organization_name, user_name, organization_id, organization_logo }) => {
  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    window.location.href = `/companies/${organization_id}`
  }
  const logoUrl = getLogoUrl(organization_logo)

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="card">
          <div className="card-header">
            <img src={logoUrl || logo} alt={`${organization_name} logo`} className="card-logo" />
            <Link to={`/companies/${organization_id}`} onClick={handleLinkClick} className="organization-link">
              {organization_name}
            </Link>
          </div>
          <div className="card-footer">
            <p>{user_name}</p>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default Card
