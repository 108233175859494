import React, { FC, useState } from 'react'
import { Button } from '@blueprintjs/core'
import * as Backend from '../../../logic/Backend'
import { ListData, ListRequest, ListsPageData } from '../../../logic/Backend'
import { deleteListConfirmationText } from '../../../logic/ValueFormatters'
import { selectedRowsLabel, ServerSideDatasource } from '../../../logic/AgGrid/AgGrid'
import { canDeleteList, canEditList } from '../../../logic/Helpers'
import ListForm from '../../components/ListForm'
import { Cache } from '../Standard'
import { showErrorToast, showSuccessToast } from '../../../logic/Toaster'
import { ConfirmationDialog } from '../../components/ConfirmationDialog'
import { DebounchedInputGroup } from '../../components/DebounchedInputGroup'
import { GridApi } from '@ag-grid-community/core'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { t } from 'i18next'

const TopBarContents: FC<WithNamespaces & { selectedRows: ListsPageData[]; cache?: Cache }> = (props) => {
  const [newListIsOpen, setNewListIsOpen] = useState(false)
  const [editListIsOpen, setEditListIsOpen] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  async function deleteList() {
    setShowDeleteConfirmation(!showDeleteConfirmation)
    await deleteListsOnClick(props.selectedRows, props.cache?.api)
  }

  async function newListOnClick(request: ListRequest) {
    try {
      await Backend.newList(request)
      // this forces a refresh of the grid with server-side data
      props.cache?.api?.onFilterChanged()
      setNewListIsOpen(false)
      showSuccessToast(t('Created new list', { name: request.name }))
    } catch {
      showErrorToast(t('Failed to create list'))
    }
  }

  function getSelectedList(): ListsPageData {
    return props.selectedRows[0]
  }

  function getDeleteListConfirmationText() {
    if (props.selectedRows.length === 0) {
      return ''
    }

    if (props.selectedRows.length === 1) {
      return deleteListConfirmationText(getSelectedList())
    }

    return t('Are you sure you want to delete these lists? If a list is public, it will be removed for all organization members.')
  }

  function onChangeSearchBar(text?: string): void {
    ServerSideDatasource.searchBar = text
    props.cache?.api?.onFilterChanged()
  }

  return (
    <>
      {props.selectedRows.length > 0 && (
        <>
          <div>{selectedRowsLabel(props.selectedRows.length, t('List'))}</div>
          {props.selectedRows.length === 1 && canEditList(getSelectedList()) && (
            <>
              <Button text={t('Edit List')} onClick={() => setEditListIsOpen(true)} />
              {editListIsOpen && (
                <ListForm
                  list={getSelectedList()}
                  onClick={editListOnClick(getSelectedList().id, props.cache?.api, () => setEditListIsOpen(false))}
                  onClickCancel={() => setEditListIsOpen(false)}
                  isOpen={editListIsOpen}
                />
              )}
            </>
          )}
          {canDeleteList(getSelectedList()) && <Button text={t('Delete List')} onClick={() => setShowDeleteConfirmation(!showDeleteConfirmation)} />}
          <ConfirmationDialog
            alertProps={{
              isOpen: showDeleteConfirmation,
              onClose: () => setShowDeleteConfirmation(false),
              cancelButtonText: `${t('Cancel')}`,
              confirmButtonText: `${t('Delete')}`,
              onConfirm: deleteList,
            }}
            text={getDeleteListConfirmationText()}
          />
          <span className="bp3-navbar-divider" />
        </>
      )}
      <Button text={t('Create New List')} onClick={() => setNewListIsOpen(true)} />
      {newListIsOpen && <ListForm onClick={newListOnClick} onClickCancel={() => setNewListIsOpen(false)} isOpen={newListIsOpen} />}
      <DebounchedInputGroup placeholder={`${t('Search name/notes…')}`} round={true} onChange_={(s) => onChangeSearchBar(s.text)} />
    </>
  )
}

export function editListOnClick(id: ListData['id'], api?: GridApi, extraSteps?: (list: ListData) => void): (request: ListRequest) => Promise<void> {
  return async (request) => {
    try {
      const list = await Backend.editList({ id: id, ...request })
      // workaround: without this, the row stays selected, and the edit menu is showing the previous values
      api?.getSelectedNodes()?.forEach((node) => node.setSelected(false))
      // this forces a refresh of the grid with server-side data
      api?.onFilterChanged()
      extraSteps?.(list)
      showSuccessToast(t('Updated list', { name: request.name }))
    } catch {
      showErrorToast(t('Failed to update list'))
    }
  }
}

export async function deleteListsOnClick(selectedRows: ListsPageData[], api?: GridApi): Promise<void> {
  try {
    await Backend.deleteLists({ list_ids: selectedRows.map((x) => x.id) })
    // this forces a refresh of the grid with server-side data
    api?.onFilterChanged()
    const message = selectedRows.length === 1 ? `list '${selectedRows[0].name}'` : `${selectedRows.length} lists`
    showSuccessToast(t('Deleted', { message: message }))
  } catch {
    showErrorToast(t('Failed delete list'))
  }
}

export default withNamespaces()(TopBarContents)
