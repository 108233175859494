export const COMPANY_TYPES = [
  'Privately Held',
  'Public Company',
  'Partnership',
  'Sole Proprietorship',
  'Nonprofit',
  'Self-Employed',
  'Educational Institution',
  'Government Agency',
]

export const COMPANY_TYPES_ORDER = [0, 1, 2, 3, 4, 5, 6, 7]
