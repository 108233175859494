import React, { FC } from 'react'
import styles from './styles.module.scss'
import defaultLogo from '../components/logo192.png'
import { CompanyData } from '../../../../logic/Backend'
import { Link } from 'react-router-dom'

interface CompanyItemProps {
  company: CompanyData
}

const getLogoUrl = (logo_id: string | undefined) => {
  if (!logo_id) return defaultLogo
  return `https://images.crunchbase.com/image/upload/${logo_id}`
}

const BrowsingHistoryItem: FC<CompanyItemProps> = ({ company }) => {
  const truncatedName = company.name.length > 10 ? company.name.substring(0, 10) + '...' : company.name
  return (
    <div className={styles.companies__item_BH}>
      <div className={styles.logoWrapper_BH}>
        <img src={getLogoUrl(company.logo_id)} alt={`${company.name} logo`} className={styles.companies__logo} />
      </div>
      <div className={styles.companies__info_BH}>
        <div className={styles.companies__header_BH}>
          <div className={styles.companies__name}>
            <Link to={`/companies/${company.id}`} className={styles.companyLink}>
              {truncatedName}
            </Link>
          </div>
        </div>
        <div className={styles.companies__tags_BH}>
          {company.industry_tag && <span className={styles.companies__tag_BH + ' ' + styles['companies__tag_BH--industry']}>{company.industry_tag}</span>}
        </div>
      </div>
    </div>
  )
}

export default BrowsingHistoryItem
