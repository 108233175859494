import React, { FC, useEffect, useState } from 'react'
import SearchBar from './SearchBar'
import styles from './style.module.scss'
import News from './News'
import { RecommendedCompanies } from './RecommendedCompanies'
import NewlyDiscoveredCompanies from './NewCompanies'
import HotCompanies from './HotCompanies'
import TeamActivity from './TeamActivity'
import BrowsingHistory from './BrowsingHistory'
import { currentUser, UserData } from '../../../logic/Backend'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { showErrorToast } from '../../../logic/Toaster'

export const DashboardPage: FC = () => {
  const [user, setUser] = useState<UserData>()
  useEffect(() => {
    const getCurrentUser = async () => {
      setUser(await currentUser())
    }
    getCurrentUser()
  }, [])
  const dispatch = useDispatch()
  const apiUrl = `${process.env.REACT_APP_SEARCH_BAR_API}?message=`
  const history = useHistory()

  const sendMessage = async (message: string) => {
    history.push(`/chat`)
    dispatch({ type: 'chatMessagesState/updateChatHistory', payload: { message: message, sender: 'user' } })
    dispatch({ type: 'chatLoadingState/updateChatLoadingState', payload: true })
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message, user_id: user.id }),
      })
      const res = await response.json()
      dispatch({ type: 'chatMessagesState/updateChatHistory', payload: { message: res.answer, sender: 'ai', sessionId: res.session_id } })
      dispatch({ type: 'chatLoadingState/updateChatLoadingState', payload: false })
    } catch (e) {
      showErrorToast('Error sending message')
      dispatch({ type: 'chatLoadingState/updateChatLoadingState', payload: false })
    }

    return
  }
  const onSuggestedConversationClick = (text: string) => {
    sendMessage(text)
  }

  return (
    <div className={styles.dashboardContainer}>
      <>
        <BrowsingHistory />
        <SearchBar sendMessage={sendMessage} onSuggestedConversationClick={onSuggestedConversationClick} />
        <div className={styles.newsAndActivityContainer}>
          <News />
          <TeamActivity />
        </div>
        <div className={styles.componentGrid}>
          <NewlyDiscoveredCompanies />
          <HotCompanies />
          <RecommendedCompanies />
        </div>
      </>
    </div>
  )
}
