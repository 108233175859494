/* eslint-disable prettier/prettier */
import React, { FC } from 'react'
import { Button, Spinner } from '@blueprintjs/core'
import { internationalUnits } from '../logic/ValueFormatters'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { t } from 'i18next'
import CustomSpinner from '../ui/components/CustomSpinner'
import styles from './PaginationTag.module.scss'

export interface PaginationProps {
  page: number
  labels?: [string, string]
  rowsCount?: number
  exactCount?: boolean
  lastPage?: number
  onPageChanged?: (shift: number) => void
  isLoading?: boolean
  isCalculating?: boolean
}

const Pagination: FC<WithNamespaces & PaginationProps> = ({
  page,
  labels = [t('Company'), t('Companies')],
  rowsCount,
  exactCount,
  lastPage,
  onPageChanged,
}) => {
  if (rowsCount === undefined) {
    return <Button small={true} style={{ visibility: 'hidden', margin: '5px 0' }} />
  }
  return (
    <div style={{ display: 'flex', gap: '50px', alignItems: 'center', padding: '5px 0' }}>
      <div className="spinner_count" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
        {!exactCount && (
          <div className="custom-spinner" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', gap: '6px' }}>
            <CustomSpinner /> <div className={styles.tag}>{t('Est')}</div> &nbsp;
          </div>
        )}
        {exactCount ? rowsCount.toLocaleString() : internationalUnits(rowsCount, 1)} {labels[rowsCount > 1 ? 1 : 0]}
      </div>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <div style={{ minWidth: '6ch' }}>
          {t('Pagination Page')} &nbsp;
          {page}
        </div>
        <Button small={true} icon={'chevron-left'} disabled={page === 1} onClick={() => onPageChanged?.(-1)} />
        <Button small={true} icon={'chevron-right'} disabled={page === lastPage} onClick={() => onPageChanged?.(1)} />
      </div>
    </div>
  )
}

export default withNamespaces()(Pagination)
