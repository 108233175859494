import React from 'react'
import { revenueParser, salesTargetParser, subRegionParser } from '../../../../logic/ValueFormatters'
import { CompanyDetailsCard } from '../../../../stories/CompanyDetailsCard'
import { CompanyDataPoint } from '../CompanyDataPoint'
import icons from './icons'
import styles from './index.module.scss'
import { withNamespaces, WithNamespaces } from 'react-i18next'

interface Props {
  insightsObject: InsightsDataType
}

export type InsightsDataType = {
  sales_target?: 'unknown' | 'b2b' | 'b2c' | 'b2b_and_b2c'
  revenue_stream?: 'non_recurring' | 'recurring'
  industries?: string[]
  region?: string
  sub_region?: string
}

const Insights = (props: Props & WithNamespaces & IncludeT) => {
  const { insightsObject, t } = props
  return (
    <div className={styles.insightBody}>
      <div className={styles.insightCard}>
        <CompanyDetailsCard style={{ alignItems: 'center' }}>
          <CompanyDataPoint label={t('Sales Target')} value={salesTargetParser(insightsObject.sales_target)} />
          {<img src={icons[insightsObject.sales_target || 'noData']} />}
        </CompanyDetailsCard>
      </div>

      <div className={styles.insightCard}>
        <CompanyDetailsCard style={{ alignItems: 'center' }}>
          <CompanyDataPoint label={t('Revenue Stream')} value={revenueParser(insightsObject.revenue_stream)} />
          <img src={icons[insightsObject.revenue_stream || 'noData']} />
        </CompanyDetailsCard>
      </div>

      <div className={styles.insightCard}>
        <CompanyDetailsCard style={{ alignItems: 'center' }}>
          <CompanyDataPoint label={t('Geography Mapping')} value={insightsObject.sub_region} />
          {/* sub region contains values such as "South & Central Asia", so it needs to be parsed to properly index the icons object  */}
          <img src={subRegionParser(insightsObject.sub_region) === 'N/A' ? icons['noData'] : icons[subRegionParser(insightsObject.sub_region)]} />
        </CompanyDetailsCard>
      </div>

      <div className={styles.insightCard}>
        <CompanyDetailsCard style={{ height: 'fit-content' }}>
          <span className={styles.industryCardTitle}>{t('Industry Groups / Industry')}</span>
          <div style={{ display: 'flex' }}>
            <ul>
              {insightsObject.industries && (
                <li>
                  {insightsObject.industries.map((industry, index) => (
                    <React.Fragment key={`${industry}-${index}`}>
                      {industry} {insightsObject.industries && index !== insightsObject.industries.length - 1 && <>&gt; </>}
                    </React.Fragment>
                  ))}
                </li>
              )}
            </ul>
          </div>
        </CompanyDetailsCard>
      </div>
    </div>
  )
}
export default withNamespaces()(Insights)
