import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './ui/app/App'
import { globalInit } from './global-init'
import './i18n'
import { store } from './store'
import { Provider } from 'react-redux'
globalInit()

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById('root')
)
