import React from 'react'
import styles from './styles.module.scss'
import AutoSuggest from './AutoSuggest'
import PresetSearches from './PresetSearches'
import { QuickSearchCompaniesType } from '.'
import SearchButton from './SearchButton'
const QuickSearchInput = ({
  heading,
  input,
  className,
  onSuggestedConversationClick,
}: {
  heading: React.ReactElement<HTMLParagraphElement>
  input: any
  className?: string
  onSuggestedConversationClick: (text: string) => void
}) => {
  return (
    <div className={`${className && styles[className]} ${styles.searchComponentContainer}`}>
      {heading}
      <div>{input}</div>
      <div className={styles.presetsContainer}>
        {presetSearch.map((preset) => {
          return <PresetSearches key={preset.value} text={preset.text} value={preset.value} onClick={onSuggestedConversationClick} />
        })}
      </div>
      <SearchButton />
    </div>
  )
}

export default QuickSearchInput

const presetSearch = [
  { text: 'What are the top startups in AI for 2024?', value: 1 },
  { text: 'How is blockchain technology evolving?', value: 2 },
  { text: 'What are the latest trends in renewable energy?', value: 3 },
  { text: 'Which companies are leading in quantum computing?', value: 4 },
  { text: 'Trends about drone technologies in Japan', value: 5 },
]
