import React, { useEffect, useState } from 'react'
import { ControlGroup } from '@blueprintjs/core'
import Filter from './Filter'
import styles from './style.module.scss'

const filterGroups = {
  industry: 'Industries',
  theme: 'Themes',
  article_type: 'Type',
  geography: 'Geography',
}

interface Props {
  filterValues: { industry: string[]; theme: string[]; article_type: string[]; geography: string[] }
  onFilterSelected: (key: string, value: string) => void
  handleClear: () => void
  shouldClearFilter: boolean
  resetClearFilter: () => void
}

const FilterContainer = ({ filterValues, onFilterSelected, handleClear, shouldClearFilter, resetClearFilter }: Props) => {
  return (
    <div className={styles.filterContainer}>
      <span>Filter by:</span>
      <ControlGroup>
        {Object.keys(filterGroups).map(
          (
            filter, // e.g. filter === 'article_type'
            i
          ) => {
            const optionsFromValues = filterValues[filter]?.map((value) => ({ value, label: value }))
            return (
              <Filter
                category={filter}
                label={filterGroups[filter]}
                values={optionsFromValues || []}
                key={i}
                onFilterSelected={onFilterSelected}
                shouldClearFilter={shouldClearFilter}
                resetClearFilter={resetClearFilter}
              />
            )
          }
        )}
      </ControlGroup>
      <span className={`${styles.alignRight} ${styles.clickableLink}`} onClick={handleClear}>
        Clear Filters
      </span>
    </div>
  )
}

export default FilterContainer
