import React, { CSSProperties, FC } from 'react'
import styles from './DetailBlockWidget.module.css'
import { IPopoverProps, Popover, PopoverInteractionKind, PopoverPosition } from '@blueprintjs/core'
import { DetailBlockDefaultContentPopover } from './DetailBlockDefaultContentPopover'
import { DetailBlockDefaultTargetWrapper } from './DetailBlockDefaultTargetWrapper'

export const DetailBlock: FC<{
  label: string
  value?: string
  style: CSSProperties
  height?: number | null
  popoverProps?: IPopoverProps
}> = (props) => {
  function hasPopover() {
    if (props.popoverProps?.content) {
      return true
    }

    return props.value && props.value.length && props.value.length >= 34
  }

  props.style.height = props.height || 80 + 'px'

  function popoverTargetProps() {
    if (props.popoverProps?.target) {
      return props.popoverProps?.target
    } else {
      return <DetailBlockDefaultTargetWrapper height={props.height}>{props.value}</DetailBlockDefaultTargetWrapper>
    }
  }

  function popoverContentProps() {
    if (props.popoverProps?.content) {
      return props.popoverProps?.content
    } else {
      return <DetailBlockDefaultContentPopover>{props.value}</DetailBlockDefaultContentPopover>
    }
  }

  return (
    <div className={styles.DetailBlockWidget} style={props.style}>
      <div className={styles.DetailBlockLabel}>{props.label}</div>
      <Popover
        disabled={!hasPopover()}
        minimal={true}
        enforceFocus={false}
        interactionKind={PopoverInteractionKind.HOVER}
        position={PopoverPosition.TOP}
        content={popoverContentProps()}
        target={popoverTargetProps()}
        {...props.popoverProps}
      />
    </div>
  )
}
