import { IOptionProps } from '@blueprintjs/core'
import { t } from 'i18next'

export const FIRM_TYPES: IOptionProps[] = [
  { label: `${t('Firm Type')}`, value: '', disabled: true },
  { label: `${t('Private Equity')}`, value: 0 },
  { label: `${t('Venture Capital')}`, value: 1 },
  { label: `${t('Search Fund')}`, value: 2 },
  { label: `${t('Corporate Venture Capital')}`, value: 3 },
  { label: `${t('Investment Bank')}`, value: 4 },
  { label: `${t('Other')}`, value: 5 },
]
