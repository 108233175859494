import React, { HTMLAttributes } from 'react'
import { Intent } from '@blueprintjs/core'
import { showErrorToast } from './Toaster'
import { FormikProps } from 'formik/dist/types'
import { t } from 'i18next'

export function validation<T>(
  formik: FormikProps<T>,
  key: keyof typeof formik.values
): {
  id: HTMLAttributes<unknown>['id']
  intent: Intent
} {
  return {
    id: key as string,
    intent: formik.touched[key] && Boolean(formik.errors[key]) ? 'danger' : 'none',
  }
}

export async function submitForm<T>(formik: FormikProps<T>): Promise<void> {
  const errors = await formik.validateForm(formik.values)
  if (Object.keys(errors).length > 0) {
    showErrorToast(
      <ul style={{ margin: 0 }}>
        {Object.values(errors).map((e, i) => (
          <li key={i}>{t(e as string)}</li>
        ))}
      </ul>
    )
  }
  await formik.submitForm()
}
