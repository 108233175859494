import { ICellRendererParams } from '@ag-grid-community/core'
import React, { FC, ReactNode } from 'react'
import { propertiesFromRating, RatingProperties } from './UserRatingPicker'
import { Icon } from '@blueprintjs/core'
import { IconName } from '@blueprintjs/icons'
import styles from './UserRatingPicker.module.scss'

export interface RatingLabelProps {
  rp: RatingProperties
  text?: ReactNode
  leftIconSize?: number
  rightIconSize?: number
  rightIcon?: IconName
  noPadding?: boolean
  large?: boolean
}

export const RatingLabel: FC<RatingLabelProps> = ({ rp, text, leftIconSize = 12, rightIconSize = 14, rightIcon, noPadding, large }) => {
  const isButton = rightIcon?.startsWith('chevron')
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '3px',
        gap: '4px',
        ...(large && { width: '160px' }),
        ...(!noPadding && !large && { padding: '1px 7px' }),
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          ...(large && {
            margin: isButton ? '0 auto' : '0 0 0 57px',
            padding: isButton ? '5px 0' : '0',
          }),
        }}
      >
        <Icon icon={rp?.icon} color={styles[`color${rp?.backendValue}`]} style={{ marginRight: '5px' }} iconSize={leftIconSize} />
        <span style={{ lineHeight: '1.6' }}>{text}</span>
        {isButton && <Icon icon={rightIcon} color={styles[`color${rp?.backendValue}`]} style={{ marginLeft: '5px' }} iconSize={rightIconSize} />}
      </div>
      {!isButton && (
        <Icon icon={rightIcon} color={styles[`color${rp?.backendValue}`]} style={{ marginLeft: 'auto', paddingLeft: '5px' }} iconSize={rightIconSize} />
      )}
    </div>
  )
}

export const MlRatingLabelCellRenderer: FC<ICellRendererParams> = (props) => {
  if (props.value === null || props.value === undefined) {
    return <></>
  }
  const rp = propertiesFromRating(props.value, true)!
  return <RatingLabel rp={rp} text={rp?.label} />
}
