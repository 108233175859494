import React, { FC, useEffect, useState } from 'react'
import { Button, ButtonGroup, Divider, Menu, Popover } from '@blueprintjs/core'
import styles from '../pages/Standard/index.module.scss'
import { selectedRowsLabel } from '../../logic/AgGrid/AgGrid'
import { getSearchResultsMaxMessage } from '../pages/Companies/TopBarContents'
import { GridApi } from '@ag-grid-community/core'
import { RightSidebarButton } from '../../stories/RightSidebar'
import { IconName } from '@blueprintjs/icons'
import { t } from 'i18next'
import { useSelector } from 'react-redux'

interface BatchActionDropdownProps {
  icon: IconName
  title: string
  tabsChildren: JSX.Element[]
  api?: GridApi
  noTabs?: boolean
}

export const BatchActionDropdown: FC<BatchActionDropdownProps> = ({ icon, title, tabsChildren, api, noTabs }) => {
  const [selectedRowsCount, setSelectedRowsCount] = useState<number>(0)
  const [totalResultsCount, setTotalResultsCount] = useState<number>(0)
  const [tabId, setTabId] = useState<number>(0)
  const [tabs, setTabs] = useState<Tab[]>([])
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const companyCount = useSelector((state: any) => state.totalCompanyCountState)

  useEffect(() => {
    const events = ['rowDataChanged', 'selectionChanged']
    events.forEach((event) => api?.addEventListener(event, refreshContent))
    return () => {
      events.forEach((event) => api?.removeEventListener(event, refreshContent))
    }
  }, [api])

  useEffect(() => {
    setTotalResultsCount(companyCount)
  }, [companyCount])

  useEffect(() => {
    !noTabs &&
      setTabs([
        { title: selectedRowsLabel(selectedRowsCount, 'Company'), disabled: selectedRowsCount === 0 },
        {
          title: `${getSearchResultsMaxMessage(totalResultsCount)} ${totalResultsCount > 1 ? t('companies') : t('company')}`,
          disabled: totalResultsCount === 0,
        },
      ])
    setTabId(noTabs ? 0 : selectedRowsCount > 0 ? 0 : 1)
  }, [selectedRowsCount, totalResultsCount, localStorage.getItem('language')])

  function refreshContent() {
    let totalRows = companyCount || api?.paginationGetRowCount() || 0
    // when waiting for the server, ag-grid shows "loading" as an actual row, making the count 1
    // we consider this scenario to have 0 rows
    if (totalRows === 1 && api?.getDisplayedRowAtIndex(0)?.data === undefined) {
      totalRows = 0
    }
    setSelectedRowsCount(api?.getSelectedRows()?.length || 0)
    setTotalResultsCount(totalRows)
  }
  return (
    <Popover
      disabled={totalResultsCount === 0}
      fill={true}
      position={'left-top'}
      onOpening={() => setIsPopoverOpen(true)}
      onClosing={() => setIsPopoverOpen(false)}
    >
      <RightSidebarButton icon={icon} label={title} active={isPopoverOpen} disabled={totalResultsCount === 0} />
      <Menu className={styles.tabMenu}>
        <ButtonGroup fill={true}>
          {tabs.map((tab, i) => (
            <Button key={i} className={styles.toggleButton} disabled={tab.disabled} active={tabId === i} text={tab.title} onClick={() => setTabId(i)} />
          ))}
        </ButtonGroup>
        {!noTabs && <Divider />}
        {tabsChildren[tabId]}
      </Menu>
    </Popover>
  )
}

export interface Tab {
  title: string
  disabled: boolean
}
