import React from 'react'
import styles from './style.module.scss'
import { CbLiOrPlaceholderImage } from '../../Company/CbLiOrPlaceholderImage'

interface Props {
  companyName: string
  articleDate: string
  articleTitle: string
  articleSummary: string
  tags?: string[]
  companyId: string
  logo?: string
  url?: string
}

const NewsItem = ({ companyName, articleDate, articleTitle, articleSummary, companyId, logo, url }: Props) => {
  return (
    <div className={styles.newsItemContainer} onClick={() => window.open(url, '_blank')}>
      <p className={styles.newsPublishedDate}>{articleDate}</p>
      <div className={styles.newsItemHeading}>
        <p className={styles.articleTitle}>{articleTitle}</p>
        <p className={styles.newsSummary}>{articleSummary}</p>
        <a onClick={(e) => e.stopPropagation()} href={`/companies/${companyId}`} className={styles.clickableLink}>
          <CbLiOrPlaceholderImage logo_id={logo} altText={'Logo'} size={30} />
          <span className={styles.companyName}>{companyName}</span>
        </a>
        {/* {tags?.map((tag) => (
          <span key={tag} className={styles.tag}>
            {tag}
          </span>
        ))}{' '} */}
      </div>
    </div>
  )
}

export default NewsItem
