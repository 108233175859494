import React, { FC, useEffect, useState } from 'react'
import { IMultiSelectProps, MultiSelect } from '@blueprintjs/select'
import { Button, IActionProps, ITagInputProps, MenuItem } from '@blueprintjs/core'
import * as Backend from '../../logic/Backend'
import { CompanyData, ListData, ListRequest } from '../../logic/Backend'
import styles from '../pages/Standard/index.module.scss'
import { IconName } from '@blueprintjs/icons'
import { showErrorToast, showSuccessToast } from '../../logic/Toaster'
import { queryIndex } from '../../logic/Helpers'
import ListForm from './ListForm'
import { highlightText, pluralize } from '../../logic/ValueFormatters'
import { GridApi, RowNode } from '@ag-grid-community/core'
import { NoResults } from '../../stories/NoResults'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

export interface CompanyListsProps {
  company?: CompanyData
  api?: GridApi
  updateCount?: (count: number) => void
  fetchLists?: typeof Backend.lists
  newList?: typeof Backend.newList
  addListings?: typeof Backend.addListings | typeof Backend.batchSaveToLists
  deleteListings?: typeof Backend.deleteListings | typeof Backend.batchDeleteLists
  dispatch?: (params: any) => void
}

const CompanyLists: FC<CompanyListsProps & WithNamespaces & IncludeT> = ({
  company,
  api,
  updateCount,
  newList = Backend.newList,
  fetchLists = Backend.lists,
  addListings = Backend.addListings,
  deleteListings = Backend.deleteListings,
  dispatch,
  t,
}) => {
  const [selectedNodes] = useState<RowNode[]>(api?.getSelectedNodes() || [])
  const [selectedRows, setSelectedRows] = useState<CompanyData[]>(api?.getSelectedRows() || [])
  const [selectedListsMap, setSelectedListsMap] = useState(getSelectedListMap(selectedRows))
  const [query, setQuery] = useState('')
  const [items, setItems] = useState<ListData[]>([])
  const [selectedItems, setSelectedItems] = useState<ListData[]>([])
  const [createdItems, setCreatedItems] = useState<ListData[]>([])
  const [newListIsOpen, setNewListIsOpen] = useState(false)
  const reduxDispatch = useDispatch()

  useEffect(() => {
    if (company) {
      const rows = [company]
      setSelectedRows(rows)
      setSelectedListsMap(getSelectedListMap(rows))
    }
  }, [company])
  useEffect(() => {
    if (items) {
      setSelectedItems(items.filter((i) => selectedListsMap?.has(i.id)))
    }
  }, [items, selectedListsMap])

  useEffect(() => {
    updateCount?.(selectedItems.length)
  }, [selectedItems])

  useEffect(() => {
    fetchItems()
  }, [])

  dispatch ? dispatch({ type: 'listState/updateListState', payload: items }) : reduxDispatch({ type: 'listState/updateListState', payload: items })

  async function fetchItems() {
    try {
      const items_ = await fetchLists()
      setItems(items_.filter((item) => item.editable_by_current_user))
    } catch {
      showErrorToast(t('Failed to load lists'))
    }
  }

  async function newListOnClick(request: ListRequest) {
    try {
      await newList(request)
      await fetchItems()
      setNewListIsOpen(false)
      showSuccessToast(`${t('Created New list')} '${request.name}'`)
    } catch {
      showErrorToast(t('Failed to create list'))
    }
  }

  function deleteItemFromArray(items: ListData[], itemToDelete: ListData): ListData[] {
    return items.filter((item) => item.id !== itemToDelete.id)
  }

  function arrayContainsItem(items: ListData[], itemToFind: ListData): boolean {
    return items.some((item) => item.id === itemToFind.id)
  }

  function maybeDeleteCreatedItemFromArrays(
    items: ListData[],
    createdItems: ListData[],
    item: ListData
  ): { nextCreatedItems: ListData[]; nextItems: ListData[] } {
    const wasItemCreatedByUser = arrayContainsItem(createdItems, item)

    // Delete the item if the user manually created it.
    return {
      nextCreatedItems: wasItemCreatedByUser ? deleteItemFromArray(createdItems, item) : createdItems,
      nextItems: wasItemCreatedByUser ? deleteItemFromArray(items, item) : items,
    }
  }

  function addItemToArray(items: ListData[], itemToAdd: ListData): ListData[] {
    return [...items, itemToAdd]
  }

  function maybeAddCreatedSelectedItemToArrays(items: ListData[], createdItems: ListData[], item: ListData): { createdItems: ListData[]; items: ListData[] } {
    const isNewlyCreatedItem = !arrayContainsItem(items, item)
    return {
      createdItems: isNewlyCreatedItem ? addItemToArray(createdItems, item) : createdItems,
      items: isNewlyCreatedItem ? addItemToArray(items, item) : items,
    }
  }

  async function deselectSelectedItem(item: ListData) {
    const { nextCreatedItems, nextItems } = maybeDeleteCreatedItemFromArrays(items, createdItems, item)
    setCreatedItems(nextCreatedItems)
    setItems(nextItems)
    setSelectedItems(selectedItems.filter((i) => i !== item))
    selectedListsMap.delete(item.id)
    setSelectedListsMap(selectedListsMap)

    try {
      await deleteListings({ list_ids: [item.id], organization_ids: selectedRows.map((row) => row.id) })
      showSuccessToast(`Removed ${selectedRows.length} ${pluralize('compan', 'y', 'ies', selectedRows.length)} from list '${item.name}'`)
    } catch (error) {
      showErrorToast(`Failed to remove ${pluralize('compan', 'y', 'ies', selectedRows.length)} from list`)
    }
    selectedNodes.forEach((row) => {
      row.setDataValue(
        'list_ids',
        (row.data as CompanyData).list_ids?.filter((id) => id !== item.id)
      )
    })
  }

  async function selectSelectedItem(selectedItem: ListData) {
    let nextCreatedItems = createdItems.slice()
    let nextSelectedItems = selectedItems.slice()
    let nextItems = items.slice()

    const results = maybeAddCreatedSelectedItemToArrays(nextItems, nextCreatedItems, selectedItem)
    nextItems = results.items
    nextCreatedItems = results.createdItems
    // Avoid re-creating an item that is already selected (the "Create
    // Item" option will be shown even if it matches an already selected
    // item).
    if (!arrayContainsItem(nextSelectedItems, selectedItem)) {
      selectedListsMap.set(
        selectedItem.id,
        selectedRows.map((row) => row.id)
      )
      setSelectedListsMap(selectedListsMap)
      nextSelectedItems = [...nextSelectedItems, selectedItem]
    }

    setCreatedItems(nextCreatedItems)
    setItems(nextItems)
    setSelectedItems(nextSelectedItems)
    try {
      await addListings({ list_id: selectedItem.id, organization_ids: selectedRows.map((row) => row.id) })
      showSuccessToast(`Added ${selectedRows.length} ${pluralize('compan', 'y', 'ies', selectedRows.length)} to list '${selectedItem.name}'`)
    } catch {
      showErrorToast(`Failed to add ${pluralize('compan', 'y', 'ies', selectedRows.length)} to list`)
    }
    selectedNodes.forEach((row) => {
      row.setDataValue('list_ids', [...Array.from(new Set(row.data.list_ids)), selectedItem.id])
    })
  }

  const onRemove: ITagInputProps['onRemove'] = async (value, index) => {
    await deselectSelectedItem(selectedItems[index])
  }

  const handleClear: IActionProps['onClick'] = async () => {
    try {
      await deleteListings({ list_ids: selectedItems.map((item) => item.id), organization_ids: selectedRows.map((row) => row.id) })
      const toadEnd = selectedItems.length > 1 ? `${selectedItems.length} lists` : `list '${selectedItems[0].name}'`
      showSuccessToast(t('removed_lists', { number: selectedRows.length, companies: pluralize('compan', 'y', 'ies', selectedRows.length), list: toadEnd }))
    } catch {
      showErrorToast(t('failed_remove_lists', { company: pluralize('compan', 'y', 'ies', selectedRows.length) }))
    }
    selectedNodes.forEach((row) => {
      row.setDataValue('list_ids', ((row.data as CompanyData).list_ids = []))
    })

    emptySelection()
  }

  const emptySelection: () => void = () => {
    setSelectedItems([])
    setSelectedListsMap(new Map())
  }

  const onItemSelect: IMultiSelectProps<ListData>['onItemSelect'] = async (selectedItem) => {
    const add = !selectedItems.includes(selectedItem)
    const localUpdateFn = add ? selectSelectedItem : deselectSelectedItem
    await localUpdateFn(selectedItem)
  }

  const clearButton: ITagInputProps['rightElement'] = selectedItems.length > 0 ? <Button icon="cross" minimal={true} onClick={handleClear} /> : <></>

  function listRenderer_(list: ListData): string {
    const selectedRowsReferencingList = selectedListsMap?.get(list.id)?.length
    if (selectedRowsReferencingList !== undefined && selectedRows.length !== undefined && selectedRows.length > 1) {
      return `${list.name} (${selectedRowsReferencingList})`
    }
    return list.name ?? ''
  }

  function listRenderer(list: ListData): React.ReactNode {
    return highlightText(listRenderer_(list), query)
  }

  function iconMenuItem(n: number | undefined, max: number | undefined): IconName {
    if (n === max) {
      return 'tick'
    }
    if (n === undefined || max === undefined || n === 0) {
      return 'blank'
    }
    return 'minus'
  }

  const itemRenderer: IMultiSelectProps<ListData>['itemRenderer'] = (item, { handleClick, modifiers, index }) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    const selectedRowsReferencingList = selectedListsMap?.get(item.id)?.length
    return (
      <MenuItem
        active={modifiers.active}
        icon={iconMenuItem(selectedRowsReferencingList, selectedRows.length)}
        disabled={modifiers.disabled}
        key={index}
        onClick={handleClick}
        text={listRenderer(item)}
        shouldDismissPopover={false}
      />
    )
  }

  const tagRenderer: IMultiSelectProps<ListData>['tagRenderer'] = (selectedItem) => {
    return listRenderer(selectedItem)
  }

  const onQueryChange: IMultiSelectProps<ListData>['onQueryChange'] = (query) => {
    setQuery(query)
  }

  return (
    <>
      <MultiSelect
        fill={true}
        className={styles.subContextMenu}
        items={items}
        selectedItems={selectedItems}
        itemRenderer={itemRenderer}
        itemPredicate={itemPredicateListData}
        onQueryChange={onQueryChange}
        itemsEqual={itemsEqualListData}
        tagRenderer={tagRenderer}
        placeholder={t('Click or type here to see existing lists.')}
        noResults={<NoResults />}
        onItemSelect={onItemSelect}
        resetOnQuery={false}
        popoverProps={{ minimal: true, boundary: 'viewport', popoverClassName: 'max-width' }}
        tagInputProps={{
          onRemove: onRemove,
          rightElement: clearButton,
        }}
      />
      <Button
        style={{ alignSelf: 'flex-start' }}
        minimal={true}
        icon={'add'}
        text={t('Create New List')}
        intent={'primary'}
        onClick={() => setNewListIsOpen(true)}
      />
      {newListIsOpen && <ListForm isOpen={newListIsOpen} onClick={newListOnClick} onClickCancel={() => setNewListIsOpen(false)} />}
    </>
  )
}

export const itemPredicateListData: IMultiSelectProps<ListData>['itemPredicate'] = (query, item) => {
  return item.name !== undefined && query !== undefined && queryIndex(item.name, query) !== -1
}

export const itemsEqualListData: IMultiSelectProps<ListData>['itemsEqual'] = (selectedItemA, selectedItemB) => {
  return selectedItemA.id === selectedItemB.id
}

function getSelectedListMap(selectedRows: CompanyData[]): Map<ListData['id'], CompanyData['id'][]> {
  const map = new Map<ListData['id'], CompanyData['id'][]>()
  return selectedRows.reduce((map, row) => {
    return (
      row.list_ids?.reduce((map, id) => {
        map.set(id, [...(map.get(id) || []), row.id])
        return map
      }, map) || map
    )
  }, map)
}

export default withNamespaces()(CompanyLists)
