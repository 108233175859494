import React, { useEffect } from 'react'
import { SelectedIndustryGroups } from '../../MultiSelect'
import { NestedIndustry } from '../../../../../ui/components/MockIndustry'
import { useSelector, useDispatch } from 'react-redux'

export const useDeselectIndustryGroup = () => {
  const dispatch = useDispatch()
  const selectedIndustries = useSelector((state: any) => state.selectedNestedIndustryState)
  let subIndToRemove: SelectedIndustryGroups[] | undefined
  const industriesToRemove: NestedIndustry[] | undefined = []
  const industryFilter = (existingItem: SelectedIndustryGroups, clickedIndustry: SelectedIndustryGroups) => {
    if (existingItem.name === clickedIndustry.name) {
      if (existingItem.subIndustries?.[0].id == clickedIndustry.subIndustries?.[0].id) {
        subIndToRemove = clickedIndustry.subIndustries
        return false
      }
    }
    return true
  }
  // prep for obj for BE:

  const handleIndustryGroupClick = (
    selectedIndustryObjects: SelectedIndustryGroups[],
    newItems: SelectedIndustryGroups[],
    items: SelectedIndustryGroups[],
    itemsToDisplay: SelectedIndustryGroups[]
  ) => {
    if (selectedIndustryObjects.find((obj: SelectedIndustryGroups) => obj?.id && obj.id == items[0].id)) {
      // Remove it from the list
      newItems = newItems.filter((item: SelectedIndustryGroups) => item && item.id !== items[0].id)
      // Then, remove all selected industries...

      newItems = newItems.filter((obj: SelectedIndustryGroups) => {
        // Industries don't have IDs
        if (!obj.id) {
          items[0].industries?.filter((ind) => ind.name === obj.name).map((ind) => industriesToRemove?.push(ind))
          if (!items[0].industries?.map((ind) => ind.name)?.includes(obj.name)) {
            return true
          } else if (items[0].industries?.map((ind) => ind.name)?.includes(obj.name)) {
            // compare their subinds
            if (items[0].industries?.find((industry) => industry.subIndustries[0].name === obj.subIndustries![0].name)) {
              return false
            }
          }
          return true
        } else return true
      })
      // then remove all subindustries

      newItems = newItems.filter((obj: SelectedIndustryGroups) => {
        const subIndustriesToRemove = industriesToRemove?.flatMap((ind) => ind.subIndustries)
        if (obj.id) {
          // remove subind from ITD
          itemsToDisplay = itemsToDisplay.filter((item) => {
            const potentialSubIndsLen = subIndustriesToRemove?.length || 0
            for (let i = 0; i < potentialSubIndsLen; i++) {
              if (item.id === subIndustriesToRemove[i].id) {
                return false
              }
              continue
            }
            return true
          })
          return !subIndustriesToRemove?.map((si) => si.id).includes(obj.id)
        } else {
          return true
        }
      })
      itemsToDisplay = itemsToDisplay.filter((item) => item.id !== items[0].id)
      // remove inds from ITD
      const displayedIndustriesToRemove =
        items[0]?.industries?.filter((industry) =>
          itemsToDisplay.find((item) => item.name === industry.name && item.subIndustries![0].name === industry.subIndustries[0].name)
        ) || []

      itemsToDisplay = itemsToDisplay.filter((item) => {
        for (let i = 0; i < displayedIndustriesToRemove?.length; i++) {
          if (item.name !== displayedIndustriesToRemove[i].name) {
            continue
          } else if (item.subIndustries![0].name === displayedIndustriesToRemove[i].subIndustries[0].name) {
            return false
          }
        }
        return true
      })
    } else if (!items[1]) {
      newItems = [...newItems, ...items]
      itemsToDisplay = [...itemsToDisplay, items[0]]
    }
    dispatch({ type: 'selectedNestedIndustryState/updateAllItems', payload: { newItems, itemsToDisplay } })

    return { newItems, itemsToDisplay }
  }

  const handleIndustryClick = (
    selectedIndustryObjects: SelectedIndustryGroups[],
    newItems: SelectedIndustryGroups[],
    items: SelectedIndustryGroups[],
    itemsToDisplay: SelectedIndustryGroups[],
    selectedIGexists?: SelectedIndustryGroups
  ) => {
    const currentIndustry = selectedIndustryObjects.find((obj: SelectedIndustryGroups) => !obj.id && obj.name == items[1].name)
    if (currentIndustry && !industryFilter(currentIndustry, items[1])) {
      newItems = newItems.filter(
        // if the existing item within the selectedObjs name is the same as the new industry name, then check whether their first subind is the same
        // because some inds share the same name as ind groups, so need another verification (because industries don't have IDs)
        (existingItem: any) => industryFilter(existingItem, items[1])
      )
      const subIndIds = subIndToRemove?.map((si) => si.id)
      newItems = newItems.filter((obj) => {
        if (!obj.id) {
          return true
        }
        return obj.id && !subIndIds?.includes(obj.id)
      })
      itemsToDisplay = itemsToDisplay.filter((item) => industryFilter(item, items[1]))
      let shouldDisplayIG = false // for deselecting Industries, but only showing IG when no inds are selected
      const industryGroupChildren = items[0]?.industries?.map((ind) => ind.name)

      //check if items[0] already exists in ITD
      const displayedIDs = itemsToDisplay.map((item) => item.id && item.id)
      // if no items[0] children exist in itemsToDisplay
      shouldDisplayIG = !newItems.some((item) => industryGroupChildren?.includes(item.name) && !item.id) && !displayedIDs.includes(items[0].id)
      shouldDisplayIG && itemsToDisplay.push(items[0])

      // find subindustries in itemsToDisplay
      itemsToDisplay = itemsToDisplay.filter((item) => {
        for (let i = 0; i < items[1].subIndustries!.length; i++) {
          if (!item.id) {
            return true
          } else if (item.id !== items[1].subIndustries![i].id) {
            continue
          } else {
            return false
          }
        }
        return true
      })
    }
    // if industry doesn't exist, and ig doesn't exist, add them both
    else if (!selectedIGexists) {
      newItems = [...newItems, ...items]
      itemsToDisplay = [...itemsToDisplay, items[1]]
    }
    // if industry doesn't exist, and ig does too, add only the industry
    else if (selectedIGexists) {
      newItems = [...newItems, ...items]
      // To Do: if Inds have all been de-selected, then selected again, remove IG from filters
      itemsToDisplay = itemsToDisplay.filter((item) => item.id !== items[0].id)
      itemsToDisplay = [...itemsToDisplay, items[1]]
    }
    dispatch({ type: 'selectedNestedIndustryState/updateAllItems', payload: { newItems, itemsToDisplay } })
    return { newItems, itemsToDisplay }
  }
  const handleSubIndustryClick = (
    selectedIndustryObjects: SelectedIndustryGroups[],
    newItems: SelectedIndustryGroups[],
    items: SelectedIndustryGroups[],
    itemsToDisplay: SelectedIndustryGroups[]
  ) => {
    if (selectedIndustryObjects.find((obj: SelectedIndustryGroups) => obj?.id && obj.id === items[2].id)) {
      newItems = newItems.filter((item: SelectedIndustryGroups) => item.id !== items[2].id)
      itemsToDisplay = itemsToDisplay.filter((item: any) => {
        return item.id !== items[2].id
      })
      let shouldDisplayInd = false
      const industryChildrenNames = items[1]?.subIndustries?.map((si) => si.name)
      shouldDisplayInd = !itemsToDisplay.some((item) => industryChildrenNames?.includes(item.name))
      shouldDisplayInd && itemsToDisplay.push(items[1])
    }
    // check if items 0 exist already
    else if (selectedIndustryObjects.find((obj: SelectedIndustryGroups) => obj?.id && obj.id === items[0].id)) {
      // add items 1 and items 2 if items 1 does not exist
      if (!selectedIndustryObjects.find((obj: SelectedIndustryGroups) => obj.subIndustries?.[0] == items[1]?.subIndustries?.[0])) {
        newItems.push(...items)
        itemsToDisplay.push(items[2])
      }
      // only add items 2 if items 1 and items 0 already exist
      else {
        newItems.push(...items)
        //remove IG and ind from ITD
        itemsToDisplay = itemsToDisplay.filter((item) => {
          if (item.id) {
            return item.id !== items[0].id
          } else {
            return industryFilter(item, items[1])
          }
        })
        itemsToDisplay.push(items[2])
      }
    }
    // add all items, if items 0 and items 1 don't exist
    else {
      newItems = [...newItems, ...items]
      itemsToDisplay.push(items[2])
    }
    dispatch({ type: 'selectedNestedIndustryState/updateAllItems', payload: { newItems, itemsToDisplay } })
    return { newItems, itemsToDisplay }
  }

  const clearEntireSelection = (newItems: SelectedIndustryGroups[], itemsToDisplay: SelectedIndustryGroups[]) => {
    newItems = []
    itemsToDisplay = []
    return { newItems, itemsToDisplay }
  }
  const clearSingleTag = (newItems: SelectedIndustryGroups[], itemsToDisplay: SelectedIndustryGroups[]) => {
    return { newItems, itemsToDisplay }
  }

  const prepObjectForBackend = () => {
    const objectForBackend = selectedIndustries.itemsToDisplay.map((item: SelectedIndustryGroups) => {
      {
        const obj = {
          industry_group: '',
          industry: '',
          sub_industry: '',
        }
        //getting to 'subind' obj first (subind only has 'name', 'id')
        if (item.industries) {
          obj.industry_group = item.name
        } else if (item.subIndustries) {
          obj.industry = item.name
          let index = 0
          selectedIndustries.allItems.forEach((allItem: SelectedIndustryGroups, i: number) => {
            if (item.name === allItem.name) {
              index = i
            }
          })
          obj.industry_group = selectedIndustries.allItems[index - 1].name
        } else {
          const filteredAllItems = selectedIndustries.allItems.filter((itm: any) => !!itm)
          // selecting sub industry
          let index = 0
          filteredAllItems.forEach((allItem: SelectedIndustryGroups, i: number) => {
            if (item.name === allItem?.name) {
              index = i
            }
          })
          const idx = selectedIndustries.allItems.findIndex((allItemsItem: any) => allItemsItem?.name === item.name)
          if (selectedIndustries.allItems[idx]?.name === item.name) {
            obj.industry_group = selectedIndustries.allItems[idx - 2]?.name
            obj.industry = selectedIndustries.allItems[idx - 1]?.name
            obj.sub_industry = item?.name
          }
        }
        return obj
      }
    })
    dispatch({ type: 'selectedNestedIndustryState/updateObjectForBackend', payload: { objectForBackend } })
  }
  return { handleIndustryGroupClick, handleIndustryClick, handleSubIndustryClick, clearEntireSelection, prepObjectForBackend }
}
