import B2B_icon from '../../../../resources/images/B2B.svg'
import B2C_icon from '../../../../resources/images/B2C.svg'
import B2B_B2C_icon from '../../../../resources/images/B2B_B2C.svg'
import unknown_icon from '../../../../resources/images/unknown.svg'
import recurring_revenue_icon from '../../../../resources/images/recurring_revenue.svg'
import non_recurring_revenue_icon from '../../../../resources/images/non_recurring_revenue.svg'
import antarctica_icon from '../../../../resources/images/antarctica.svg'
import australia_oceania_icon from '../../../../resources/images/australia_oceania.svg'
import carribean_icon from '../../../../resources/images/carribean.svg'
import central_africa_icon from '../../../../resources/images/central_africa.svg'
import central_america_icon from '../../../../resources/images/central_america.svg'
import central_europe_icon from '../../../../resources/images/central_europe.svg'
import central_south_asia_icon from '../../../../resources/images/central_south_asia.svg'
import eastern_europe_icon from '../../../../resources/images/eastern_europe.svg'
import middle_east_icon from '../../../../resources/images/middle_east.svg'
import north_america_icon from '../../../../resources/images/north_america.svg'
import northeastern_asia_icon from '../../../../resources/images/northeastern_asia.svg'
import northern_africa_icon from '../../../../resources/images/northern_africa.svg'
import northern_europe_icon from '../../../../resources/images/northern_europe.svg'
import south_america_icon from '../../../../resources/images/south_america.svg'
import southeastern_asia_icon from '../../../../resources/images/southeastern_asia.svg'
import southern_africa_icon from '../../../../resources/images/southern_africa.svg'
import southern_europe_icon from '../../../../resources/images/southern_europe.svg'
import western_africa_icon from '../../../../resources/images/western_africa.svg'
import western_europe_icon from '../../../../resources/images/western_europe.svg'
import NoData from '../../../../resources/images/no-data.png'

const icons: any = {
  b2b: B2B_icon,
  b2c: B2C_icon,
  b2b_and_b2c: B2B_B2C_icon,
  unknown: unknown_icon,
  recurring: recurring_revenue_icon,
  non_recurring: non_recurring_revenue_icon,
  antarctica: antarctica_icon,
  australia_and_oceania: australia_oceania_icon,
  caribbean: carribean_icon,
  central_africa: central_africa_icon,
  central_america: central_america_icon,
  central_europe: central_europe_icon,
  central_south_asia: central_south_asia_icon,
  eastern_europe: eastern_europe_icon,
  middle_east: middle_east_icon,
  north_america: north_america_icon,
  northeastern_asia: northeastern_asia_icon,
  northern_africa: northern_africa_icon,
  northern_europe: northern_europe_icon,
  south_america: south_america_icon,
  southeastern_asia: southeastern_asia_icon,
  southern_africa: southern_africa_icon,
  southern_europe: southern_europe_icon,
  western_africa: western_africa_icon,
  western_europe: western_europe_icon,
  noData: NoData,
}
export default icons
