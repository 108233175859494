import { Button, IToastProps, Position, Toaster } from '@blueprintjs/core'
import React, { ReactNode } from 'react'
import { InterpretedHtml } from './ValueFormatters'

const AppToaster = Toaster.create({
  className: 'recipe-toaster',
  position: Position.TOP,
  maxToasts: 5,
})

export function showSuccessToast(message: string | ReactNode, undoFunction?: () => void): void {
  showToast(message, { intent: 'success', icon: 'tick-circle' }, undoFunction)
}

export function showErrorToast(message: string | ReactNode, undoFunction?: () => void): void {
  showToast(message, { intent: 'danger', icon: 'error' }, undoFunction)
}

export function showGenericErrorToast(error: Error): void {
  console.error(error)
  showErrorToast('Sorry, something went wrong. Please notify the Kurrant.ai team, and try again later.')
}

function showToast(message: string | ReactNode, toastProps: Omit<IToastProps, 'message'>, undoFunction?: () => void): void {
  let toasterMessage: string | ReactNode
  if (typeof message === 'string') {
    toasterMessage = <InterpretedHtml html={message} />
  } else {
    toasterMessage = message
  }

  if (undoFunction) {
    toasterMessage = (
      <>
        {toasterMessage}
        <a
          href="####"
          onClick={(event) => {
            event.preventDefault()
            undoFunction()
          }}
        >
          Undo
        </a>
      </>
    )
  }

  AppToaster.show({ message: toasterMessage, ...toastProps })
}
