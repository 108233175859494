import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import Select from 'react-select'

interface Props {
  label: string
  values?: { value: string; label: string }[]
  category: string
  onFilterSelected: (key: string, value: string) => void
  shouldClearFilter: boolean
  resetClearFilter: () => void
}

const Filter = (props: Props) => {
  const { label, values, onFilterSelected, category, shouldClearFilter, resetClearFilter } = props
  const [value, setValue] = useState(null)

  useEffect(() => {
    setValue(null)
    resetClearFilter()
  }, [shouldClearFilter])
  return (
    <div className={styles.filter}>
      <Select
        value={value}
        isClearable
        options={values}
        placeholder={label}
        onChange={(item) => {
          onFilterSelected(category, item ? item.value : '')
          setValue(item)
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
        styles={customStyles}
      />
    </div>
  )
}
const customStyles = {
  control: (base, state) => ({
    ...base,
    background: '#ededed',
    borderRadius: '8px',
    border: 'none',
    color: 'black',
    minWidth: 'fit-content',

    // match with the menu
    // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    // borderColor: state.isFocused ? "yellow" : "green",
    // Removes weird border around container
    // boxShadow: state.isFocused ? null : null,
    // "&:hover": {
    //   // Overwrittes the different states of border
    //   borderColor: state.isFocused ? "red" : "blue"
    // }
  }),
  menu: (base) => {
    return {
      ...base,
      minWidth: 'fit-content',
    }
  },
  // menuList: (base) => ({
  //   ...base,
  //   // kill the white space on first and last option
  //   padding: 0,
  // }),
  placeholder: (base) => ({
    ...base,
    color: 'black',
  }),
}

export default Filter
