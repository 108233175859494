import { createModel } from '@rematch/core'
import { ListData } from '../logic/Backend'
import { RootModel } from '.'

const initialState: ListData[] = []
const listState = createModel<RootModel>()({
  state: initialState, // initial state
  reducers: {
    updateListState(state, payload) {
      return payload
    },
    // handle state changes with pure functions
  },
  // effects: (dispatch) => ({
  //   // handle state changes with impure functions.
  //   // use async/await for async actions
  //   async incrementAsync(payload: number, state) {
  //     console.log("This is current root state", state);
  //     await new Promise((resolve) => setTimeout(resolve, 1000));
  //     dispatch.count.increment(payload);
  //   },
  // }),
})

export default listState
