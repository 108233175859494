export const salesTargetObject = {
  queryValues: [0, 1, 2, 3], // what to send the backend to query the db
  displayNames: ['Unknown', 'B2B', 'B2C', 'B2B + B2C'], // what to display on the filter
  returnValues: {
    // what's returned from the backend, mapped to what to display on the frontend
    unknown: 'Unknown',
    b2b: 'B2B',
    b2c: 'B2C',
    b2b_and_b2c: 'B2B + B2C',
  },
}
