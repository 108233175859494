import { Divider, MenuItem } from '@blueprintjs/core'
import { classForRating } from '../../logic/ValueFormatters'
import React, { FC, useEffect, useRef, useState } from 'react'
import * as Backend from '../../logic/Backend'
import { ListData } from '../../logic/Backend'
import { showErrorToast, showSuccessToast } from '../../logic/Toaster'
import { Explanation } from '../pages/Companies/Explanation'
import { packCompanyQueryParams } from '../../logic/AgGrid/AgGrid'
import { getSearchResultsMaxMessage, LIST_LIMIT } from '../pages/Companies/TopBarContents'
import { RATINGS, RATINGS_WITH_UNRATED } from '../../resources/reference_data/ratings'
import { ColumnApi, GridApi } from '@ag-grid-community/core'
import { withNamespaces, WithNamespaces } from 'react-i18next'

export interface RatingMenuAllResultsProps {
  api?: GridApi
  columnApi?: ColumnApi
  listId?: ListData['id']
}

const RatingMenuAllResults: FC<RatingMenuAllResultsProps & WithNamespaces & IncludeT> = ({ api, columnApi, listId, t }) => {
  const totalRowsCount = api?.paginationGetRowCount() || 0
  const countMax = getSearchResultsMaxMessage(totalRowsCount)
  // const [refresh, forceRefresh] = useState(false)
  const [rating, setRating] = useState<number | undefined>()

  const firstUpdate = useRef(true)

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    ;(async () => {
      try {
        api?.forEachNode((row) => {
          if (row.rowIndex! < LIST_LIMIT) {
            row.setDataValue('rating', rating)
          }
        })
        await Backend.batchAddRate({
          rating: rating,
          ...packCompanyQueryParams(columnApi),
          ...(listId && { listId }),
        })
        if (rating === undefined) {
          showSuccessToast(`Unrated ${countMax} companies`)
        } else {
          showSuccessToast(`Rated ${countMax} companies '${RATINGS_WITH_UNRATED[rating]}'`)
        }
        // forceRefresh(!refresh)
      } catch {
        showErrorToast('Failed to rate companies')
      }
    })()
  }, [rating])

  const explanations = (() => {
    const alwaysShown = t(
      'Some of the companies selected may already have a rating. Due to the large number of companies selected, we cannot show the ratings for all the companies.'
    )
    if (totalRowsCount > LIST_LIMIT) {
      return [t('rate_companies', { number: LIST_LIMIT.toLocaleString() }), alwaysShown]
    }
    return [alwaysShown]
  })()

  return (
    <>
      <Explanation texts={explanations} />
      <Divider />
      {RATINGS.map((label, i) => {
        const active = rating === i
        return (
          <MenuItem
            icon={active ? 'tick' : 'blank'}
            key={i}
            shouldDismissPopover={false}
            text={label}
            onClick={() => setRating(rating === i ? undefined : i)}
            className={classForRating(i, active)}
            active={active}
          />
        )
      })}
    </>
  )
}

export default withNamespaces()(RatingMenuAllResults)
