import React, { FC } from 'react'
import { Icon } from '@blueprintjs/core'
import styles from '../../app/App.module.scss'
export const Explanation: FC<{ texts: string[]; hasIcon?: boolean }> = ({ texts = [''], hasIcon = true }) => {
  return (
    <div className={styles.explanation}>
      <Icon className={styles.explanationIcon} style={hasIcon ? {} : { color: 'transparent' }} icon={'info-sign'} iconSize={18} />
      <div>
        {texts.map((text, index) => {
          return <p key={index}>{text}</p>
        })}
      </div>
    </div>
  )
}
