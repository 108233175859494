//RightSidebar.tsx

import React, { CSSProperties, FC, useState } from 'react'
import { BatchActionDropdown } from '../ui/components/BatchActionDropdown'
import CompanyListsAllResults from '../ui/components/CompanyListsAllResults'
import Export from '../ui/components/Export'
import RatingMenuAllResults from '../ui/components/RatingMenuAllResults'
import { RatingMenu } from '../ui/components/RatingMenu'
import CompanyLists from '../ui/components/CompanyLists'
import { GridApi } from '@ag-grid-community/core'
import { Button, Classes, Icon } from '@blueprintjs/core'
import { IconName } from '@blueprintjs/icons'
import styles from './RightSidebar.module.scss'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { t } from 'i18next'
import { useDispatch } from 'react-redux'
import { AddToPipelinePopup } from '../ui/pages/Pipeline/RightSidebarPipeline/AddToPipelinePopup'
export interface RightSidebarProps {
  api?: GridApi
}

const RightSidebar: FC<WithNamespaces & RightSidebarProps> = ({ api }) => {
  const [toolPanelShowing, setToolPanelShowing] = useState(api?.isToolPanelShowing() ?? false)
  const [selectedCount, setSelectedCount] = useState(0)

  function onClickColumnView() {
    if (api) {
      toolPanelShowing ? api.closeToolPanel() : api.openToolPanel('columns')
      setToolPanelShowing(!toolPanelShowing)
    }
  }

  return (
    <div className={(Classes.ELEVATION_2, styles.rightBar)}>
      <BatchActionDropdown
        icon={'thumbs-up'}
        title={t('Apply Rating')}
        tabsChildren={[<RatingMenu key={0} api={api} />, <RatingMenuAllResults key={1} api={api} />]}
        api={api}
      />
      <BatchActionDropdown
        icon={'add-to-folder'}
        title={t('Save to List')}
        tabsChildren={[<CompanyLists api={api} dispatch={useDispatch} key={0} />, <CompanyListsAllResults key={1} api={api} />]}
        api={api}
      />
      <BatchActionDropdown
        icon={'share'}
        title={t('Export')}
        tabsChildren={[0, 1].map((tabId) => (
          <Export key={tabId} api={api} tabId={tabId} />
        ))}
        api={api}
      />
      <BatchActionDropdown
        icon={'layers'}
        title={t('Add to Pipeline')}
        tabsChildren={[0].map((tabId) => (
          <AddToPipelinePopup count={selectedCount} api={api} key={tabId} />
        ))}
        api={api}
        noTabs
      />
      <RightSidebarButton
        icon={'column-layout'}
        label={t('Column View')}
        active={toolPanelShowing}
        style={{ marginTop: 'auto' }}
        onClick={() => onClickColumnView()}
      />
    </div>
  )
}

export interface RightSidebarButtonProps {
  icon: IconName
  label: string
  disabled?: boolean
  active?: boolean
  style?: CSSProperties
  onClick?: () => void
}

export const RightSidebarButton: FC<RightSidebarButtonProps> = ({ icon, label, disabled, active, style, onClick }) => {
  return (
    <Button
      className={styles.buttonStyle}
      intent="primary"
      onClick={() => onClick?.()}
      text={
        <div>
          <Icon icon={icon} size={20} />
          <span className={Classes.TEXT_SMALL}>{label}</span>
        </div>
      }
      active={active}
      minimal={true}
      disabled={disabled}
      style={{ ...style, padding: '5px', textAlign: 'center' }}
    />
  )
}

export default withNamespaces()(RightSidebar)
