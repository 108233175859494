import React from 'react'
import styles from './style.module.scss'

interface Props {
  title: string
}

const IndustryTag = (props: Props) => {
  const { title } = props
  return (
    <div className={styles.tagContainer}>
      <span className={styles.tag}>{title}</span>
    </div>
  )
}

export default IndustryTag
