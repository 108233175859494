import jsonData from './BackendJson.json'
// Not using this file any more, using useNestedIndustry
export interface IndustryGroup {
  id: number
  name: string
  industries:
    | [
        {
          name: string
          subIndustries: [
            {
              name: string
              id: number
            }
          ]
        }
      ]
    | any[] // empty array
}

export interface NestedIndustry {
  name: string
  subIndustries: [{ name: string; id: number }]
}

interface Item {
  id: number
  industry_group: string
  industry: string
  sub_industry: string
}

export const industryData = (jsonData as Item[]).reduce((pre, curr) => {
  // Find IndustryGroup which name is curr.industry_group
  const currentGroupIndex = pre.find((entry) => curr.industry_group == entry.name)
  if (currentGroupIndex) {
    const currentIndustryIndex = currentGroupIndex.industries?.find((entry) => curr.industry == entry.name)
    if (currentIndustryIndex) {
      currentIndustryIndex.subIndustries.push({ name: curr.sub_industry, id: curr.id })
    } else {
      currentGroupIndex.industries?.push({ name: curr.industry, subIndustries: [{ name: curr.sub_industry, id: curr.id }] })
    }
  } else if (!currentGroupIndex && curr.industry) {
    pre.push({
      id: curr.id,
      name: curr.industry_group,
      industries: [
        {
          name: curr.industry,
          subIndustries: [{ name: curr.sub_industry, id: curr.id }],
        },
      ],
    })
  } else {
    pre.push({
      id: curr.id,
      name: curr.industry_group,
      industries: [],
    })
  }
  return pre
}, [] as IndustryGroup[])

export const industryGroups = industryData.map((industryGroup) => industryGroup.name)
