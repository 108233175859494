import React, { FC } from 'react'
import { InterpretedHtml } from '../../../logic/ValueFormatters'

export const CompanyName: FC<{ name?: string; link?: string }> = ({ name, link }) => {
  if (!name) {
    return <></>
  }
  if (!link) {
    return <InterpretedHtml html={name} />
  }
  return (
    <div style={{ display: 'flex', gap: '10px', fontSize: '18px' }}>
      <InterpretedHtml html={name} />
      <a href={link} title="Visit website" target="_blank" rel="noopener noreferrer">
        ↗️
      </a>
    </div>
  )
}
