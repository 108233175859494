import { Button, Divider, Spinner, SpinnerSize } from '@blueprintjs/core'
import React, { FC, useEffect, useState } from 'react'
import * as Backend from '../../logic/Backend'
import { CompanyData, ExportCsvRequest, QuotaInfo } from '../../logic/Backend'
import { showErrorToast, showSuccessToast } from '../../logic/Toaster'
import { Explanation } from '../pages/Companies/Explanation'
import { packCompanyQueryParams, ServerSideDatasource } from '../../logic/AgGrid/AgGrid'
import { ColDef, ColGroupDef, ColumnApi, GridApi } from '@ag-grid-community/core'
import { pluralize } from '../../logic/ValueFormatters'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { t } from 'i18next'
import { useSelector } from 'react-redux'

const Export: FC<
  WithNamespaces & {
    tabId: number
    api?: GridApi
    columnApi?: ColumnApi
  }
> = ({ tabId, api, columnApi }) => {
  const [quota, setQuota] = useState<QuotaInfo['quota']>()
  const [consumedQuota, setConsumedQuota] = useState<QuotaInfo['consumed_quota']>()
  const isSelectionTab = tabId === 0
  const selectedRowsCount = api?.getSelectedRows().length || 0
  const totalRowsCount = api?.paginationGetRowCount() || 0 //from AG Grid API (not 100% functional - count is off)
  const companyCount = useSelector((state: any) => state.totalCompanyCountState)
  const wannaExportCount = isSelectionTab ? selectedRowsCount : companyCount
  const selectedRows: CompanyData[] = api?.getSelectedRows() || []
  useEffect(() => {
    refreshQuotaInfo()
  }, [])

  async function refreshQuotaInfo() {
    try {
      const quotaInfo = await Backend.fetchExportQuota()
      setQuota(quotaInfo.quota)
      setConsumedQuota(quotaInfo.consumed_quota)
    } catch {}
  }

  function getVisibleColumns(): ColDef[] {
    return (
      api!
        .getColumnDefs()
        ?.filter((col) => !col.headerTooltip && col.headerName)
        ?.flatMap((col) => (col as ColGroupDef).children.map((child) => child as ColDef))
        ?.filter((col) => !col.hide) ?? []
    )
  }

  function requestBody(): ExportCsvRequest {
    const visibleColumns = getVisibleColumns()
    return {
      columns: visibleColumns.map((c) => c.field!),
      header_names: visibleColumns.map((c) => c.headerName!),
      ...(isSelectionTab ? { organization_ids: selectedRows.map((row) => row.id) } : packCompanyQueryParams(columnApi)),
      ...(ServerSideDatasource.listId && { listId: ServerSideDatasource.listId }),
    }
  }

  const handleExport = async () => {
    try {
      Backend.exportCsv(requestBody())
      showSuccessToast(t('Successfully exported CSV file'))
    } catch (err) {
      showErrorToast(t('Failed to export CSV file'))
      console.log(err)
    }
    await refreshQuotaInfo()
  }

  if (quota === undefined || consumedQuota === undefined) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', placeContent: 'center' }}>
        <Spinner size={SpinnerSize.SMALL} />
        <span style={{ marginLeft: '10px' }}>{t('Loading quota info')}…</span>
      </div>
    )
  }

  const restExportableCount = quota - consumedQuota - (isSelectionTab ? selectedRowsCount : companyCount)
  const exportable = restExportableCount >= 0
  const thisThese = pluralize('', 'this', 'these', wannaExportCount)
  const companyIes = pluralize('compan', 'y', 'ies', wannaExportCount)

  return (
    <>
      <Explanation
        texts={[
          t('export_status', { export_limit: quota.toLocaleString(), export_number: consumedQuota.toLocaleString() }),
          exportable
            ? thisThese === 'this'
              ? t('export_now_singular', {
                  export_number: wannaExportCount.toLocaleString(),
                  export_remaining: restExportableCount.toLocaleString(),
                  this_these: thisThese,
                  companies: companyIes,
                })
              : t('export_now_multiple', {
                  export_number: wannaExportCount.toLocaleString(),
                  export_remaining: restExportableCount.toLocaleString(),
                  this_these: thisThese,
                  companies: companyIes,
                })
            : t('over_quota', { export_number: wannaExportCount.toLocaleString(), this_these: thisThese, companies: companyIes }),
        ]}
      />
      {!exportable && <Explanation hasIcon={false} texts={[t('Please reduce the number of companies to export or try again next month.')]} />}
      <Divider />
      <Button disabled={false} onClick={handleExport}>
        {isSelectionTab ? t('Export') : t('Export All Results')}
      </Button>
    </>
  )
}

export default withNamespaces()(Export)
