import React from 'react'
import { useHistory } from 'react-router-dom'
import styles from './styles.module.scss'

interface SeeMoreButtonProps {
  url: string
  disabled?: boolean
}

const SeeMoreButton: React.FC<SeeMoreButtonProps> = ({ url }) => {
  const history = useHistory()

  const handleClick = () => {
    history.push(url)
  }

  return (
    <button className={styles['see-more']} onClick={handleClick}>
      See More
    </button>
  )
}

export default SeeMoreButton
