import React, { FC } from 'react'
import styles from './IsnewTag.module.scss'

const IsNewTag: FC = () => {
  return (
    <div className={styles.newTagBox}>
      <span className={styles.newTag}>• NEW</span>
    </div>
  )
}

export default IsNewTag
