import React from 'react'
import styles from './style.module.scss'
import { Icon } from '@blueprintjs/core'
import KurrantGPT from '../../../../resources/images/KurrantGPT_small_icon.svg'
interface Props {
  toggleVisibility: React.Dispatch<React.SetStateAction<boolean>>
  visibility: boolean
}

const ToggleView = (props: Props) => {
  const { toggleVisibility, visibility } = props
  const handleClick = () => {
    toggleVisibility(!visibility)
  }
  return (
    <div className={styles.openButton} onClick={handleClick}>
      {visibility ? <Icon icon="cross" size={30} color="white" /> : <img src={KurrantGPT} height={80} onClick={handleClick} />}
    </div>
  )
}

export default ToggleView
