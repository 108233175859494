import React, { FC, useEffect, useState } from 'react'
import { Button, FormGroup, IActionProps, Icon } from '@blueprintjs/core'
import styles from './index.module.css'
import { TopBar } from '../../components/TopBar'
import * as Routes from '../../../logic/Routes'
import * as Backend from '../../../logic/Backend'
import { UserData } from '../../../logic/Backend'
import { showErrorToast } from '../../../logic/Toaster'
import { routerHistory } from '../../app/App'
import { localDate } from '../../../logic/ValueFormatters'
import { Tooltip } from '../../../stories/Tooltip'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { t } from 'i18next'

const Account: FC<WithNamespaces> = () => {
  const [currentUser, setCurrentUser] = useState<UserData>()

  useEffect(() => {
    getCurrentUser()
  }, [])

  async function getCurrentUser() {
    try {
      setCurrentUser(await Backend.currentUser())
    } catch {
      showErrorToast(t('Failed to get current user info'))
    }
  }

  async function logout() {
    try {
      await Backend.logout()
      routerHistory.push(Routes.login.href)
    } catch (error) {
      showErrorToast(t('Failed to logout'))
    }
  }

  async function billingPortal() {
    try {
      const response = await Backend.stripePortal({ return_url: location.href })
      location.href = response.stripe_url
    } catch (error) {
      showErrorToast(t('Failed to access the Billing Portal'))
    }
  }

  return (
    <>
      <TopBar breadcrumbs={[{ ...Routes.preferences, ...{ current: true } }]} />
      <div className={styles.page}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <ProfileInfo label={t('Full Name')} info={currentUser?.name} />
          <ProfileInfo label={t('Email')} info={currentUser?.email} />
          {currentUser?.customer != currentUser?.email && <ProfileInfo label={t('Company Name')} info={currentUser?.customer} />}
        </div>
        <div style={{ display: 'flex', gap: '20px', margin: '20px 0 50px 0' }}>
          <Button intent={'primary'} text={t('Billing Portal')} onClick={() => billingPortal()} />
          <Button intent={'danger'} text={t('Logout')} onClick={() => logout()} />
        </div>
        <h4 style={{ margin: '0' }}>{t('Read Legal Documents')}</h4>
        {currentUser?.created_at && (
          <p className={styles.acceptedOn}>
            {t('You accepted these Terms on ')}
            {`${localDate(currentUser?.created_at)}`}
          </p>
        )}
        <a className={'bp3-button'} href={'https://kurrant.ai/terms-of-use'} target="_blank" rel="noopener noreferrer">
          {t('Terms of Use')}
        </a>
        <a className={'bp3-button'} href={'https://kurrant.ai/privacy-policy'} target="_blank" rel="noopener noreferrer" style={{ marginTop: '10px' }}>
          {t('Privacy Policy')}
        </a>
        <h4 style={{ margin: '40px 0 0 0' }}>{t('Clear browser data')}</h4>
        <ClearButton
          label={t('Clear session data')}
          tooltip={t('Clears data which persist during the browsing session (e.g. active Saved Filter)')}
          onClick={() => sessionStorage.clear()}
        />
        <ClearButton
          label={t('Clear local data')}
          tooltip={t('Clears data which persist during and between browsing sessions (e.g. Company grid view columns position, size, etc)')}
          onClick={() => localStorage.clear()}
        />
      </div>
    </>
  )
}

const ProfileInfo: FC<{ label: string; info?: string }> = ({ label, info }) => {
  return (
    <FormGroup label={<strong>{label}</strong>}>
      <div>{info ?? ' '}</div>
    </FormGroup>
  )
}

const ClearButton: FC<{ label: string; tooltip: string; onClick: IActionProps['onClick'] }> = ({ label, tooltip, onClick }) => {
  return (
    <div className={styles.clearButton}>
      <Button text={label} onClick={onClick} />
      <Tooltip>
        <Icon icon={'help'} color={'#5a737d'} />
        <div>{tooltip}</div>
      </Tooltip>
    </div>
  )
}

export default withNamespaces()(Account)
