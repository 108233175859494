import React, { ReactElement, useEffect, useState } from 'react'
import { Button } from '@blueprintjs/core'
import styles from './style.module.scss'
import i18n from '../../i18n'
// import { useHistory } from 'react-router-dom'

interface Props {
  label: string
  icon: ReactElement
  isCollapsed: boolean
  localization: string | null
}

const LocalizationButton = (props: Props) => {
  const { label = '', icon, isCollapsed, localization } = props
  const [dropdownStatus, setDropdownStatus] = useState(false)
  // const history = useHistory()
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
    localStorage.setItem('language', lng)
    // history.go(0)  consider reloading entire page when language is changed
  }
  useEffect(() => {
    setDropdownStatus(false)
  }, [localization])
  return (
    <div
      className={dropdownStatus ? styles.buttonContainerSelected : styles.buttonContainer}
      onClick={() => setDropdownStatus(true)}
      tabIndex={0}
      onBlur={() => setDropdownStatus(false)}
    >
      <div className={localization ? (localization === 'en' ? styles.iconContainerEN : styles.iconContainerJP) : styles.iconContainerEN}>
        {icon}
        {!isCollapsed && (
          <div className={styles.label}>
            {label} <Button className={dropdownStatus ? styles.caretSelected : styles.caretDown} small icon={dropdownStatus ? 'caret-up' : 'caret-down'} />
          </div>
        )}
      </div>
      {dropdownStatus && (
        <div className={styles.dropdown}>
          <div className={localization === 'en' ? styles.selectedLanguageOption : ''} onClick={() => changeLanguage('en')}>
            English
          </div>
          <div className={localization === 'jp' ? styles.selectedLanguageOption : ''} onClick={() => changeLanguage('jp')}>
            日本語
          </div>
        </div>
      )}
    </div>
  )
}

export default LocalizationButton
