import React, { FC } from 'react'
import { Img } from 'react-image'
import placeholderAvatar from '../resources/images/placeholder_avatar.svg'
import { CompanyDataPoint } from '../ui/pages/Company/CompanyDataPoint'
import { LinkedinEmployeeData } from '../logic/Backend'
import styles from './LinkedinEmployee.module.scss'
import { CbLiOrPlaceholderImage } from '../ui/pages/Company/CbLiOrPlaceholderImage'

export const LinkedinEmployee: FC<LinkedinEmployeeData> = ({ profile_url, profile_picture_url, role, name }) => {
  return (
    <a className={styles.root} href={profile_url} target="_blank" rel="noopener noreferrer">
      <CbLiOrPlaceholderImage li_profile_pic_url={profile_picture_url} altText={'Profile picture'} size={50} style={{ borderRadius: '50%' }} />
      <CompanyDataPoint label={role ?? '-'} value={name} />
    </a>
  )
}
