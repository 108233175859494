import React, { FC, ReactNode } from 'react'
import styles from './styles.module.scss'

interface ContainerComponentProps {
  title: string
  renderedItems: ReactNode
}

const BrowsingHistoryContainer: FC<ContainerComponentProps> = ({ title, renderedItems }) => {
  return (
    <div className={styles.companies_BH}>
      <div className={styles.companies__header_BH}>
        <p>{title}</p>
      </div>
      <div className={`${styles.companies__list_BH} ${styles.scrollable}`}>{renderedItems}</div>
    </div>
  )
}

export default BrowsingHistoryContainer
