import React, { FC, useEffect, useState } from 'react'
import { InputGroup, InputGroupProps, InputGroupProps2 } from '@blueprintjs/core'
import { useDebouncedEffect } from '../../logic/useDebouncedEffect'

export interface DebouncedInputGroupProps extends InputGroupProps {
  onChange_?: (state: DebouncedInputGroupState) => void
}

export interface DebouncedInputGroupState {
  text?: string
}

export const DebounchedInputGroup: FC<DebouncedInputGroupProps & DebouncedInputGroupState> = ({ text, onChange_, ...inputGroupProps }) => {
  const [textProp, setTextProp] = useState(text)
  const [text$, setText$] = useState(text)

  useEffect(() => {
    onChange_?.({ text })
  }, [])

  if (text !== textProp) {
    setTextProp(text)
    setText$(text)
  }

  useDebouncedEffect(500, () => onChange_?.({ text: text$ }), [text$])

  const onChange__: InputGroupProps2['onChange'] = (event) => {
    const v = event.target.value
    setText$(v.length === 0 ? undefined : v)
  }

  return <InputGroup value={text$ ?? ''} leftIcon={'search'} onChange={onChange__} {...inputGroupProps} />
}
