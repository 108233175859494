import React, { FC, useState } from 'react'
import { Menu, MenuItem, Popover } from '@blueprintjs/core'
import { labelFromVariable } from '../../logic/ValueFormatters'
import { OmniFilterButton } from '../OmniFilterButton'
import { allFilters, Filter } from '../../ui/components/Filters'
import { withNamespaces, WithNamespaces } from 'react-i18next'

export interface FilterDropdownProps {
  filter?: Filter
  onChange?: (filter: Filter) => void
  fixed?: boolean
}

const FilterDropdown: FC<FilterDropdownProps & WithNamespaces & IncludeT> = ({ filter, onChange, fixed, t }) => {
  const [filterProp, setFilterProp] = useState(filter)
  const [filter$, setFilter$] = useState(filter)
  const [isOpen, setIsOpen] = useState(false)

  if (filter != filterProp) {
    setFilterProp(filter)
    setFilter$(filter)
    setIsOpen(false)
  }

  function onChange_(filter: Filter): void {
    setIsOpen(false)
    setFilter$(filter)
    onChange?.(filter)
  }

  const button = <OmniFilterButton text={filter$?.label ?? t('Add Filter')} fixed={fixed} {...(filter$?.label === undefined && { icon: 'add' })} />
  if (fixed) {
    return button
  }
  return (
    <>
      <Popover
        popoverClassName={'w-190'}
        position={'bottom'}
        minimal={true}
        fill={true}
        boundary={'viewport'}
        isOpen={isOpen}
        onInteraction={(open) => setIsOpen(open)}
      >
        {button}
        <Menu>
          {Object.entries(allFilters).map(([group, filters], i) => (
            <MenuItem text={t(labelFromVariable(group))} key={i} shouldDismissPopover={false}>
              {filters.map((filter, j) => (
                <MenuItem text={t(filter.label)} key={j} onClick={() => onChange_(filter)} shouldDismissPopover={false} />
              ))}
            </MenuItem>
          ))}
        </Menu>
      </Popover>
    </>
  )
}

export default withNamespaces()(FilterDropdown)
