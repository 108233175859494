import React, { useState, useEffect } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './index.scss'
import FormattedDate from './FormattedDate'
import {
  companyPage,
  CompanyData,
  companyNotes as fetchCompanyNotes,
  Note as BackendNote,
  createNote,
  updateNote,
  currentUser,
  UserData,
} from '../../../../logic/Backend'

interface CompanyNotesProps {
  companyId: string
}

const CompanyNotes: React.FC<CompanyNotesProps> = ({ companyId }) => {
  const [notes, setNotes] = useState<BackendNote[]>([])
  const [newNote, setNewNote] = useState<string>('')
  const [showNoteInput, setShowNoteInput] = useState<boolean>(false)
  const [editingNoteId, setEditingNoteId] = useState<string | null>(null)
  const [editingContent, setEditingContent] = useState<string>('')
  const [currentUserData, setCurrentUserData] = useState<UserData | null>(null)

  useEffect(() => {
    const fetchCompanyDataAndNotes = async () => {
      try {
        let backendNotes: BackendNote[] = await fetchCompanyNotes(companyId)

        // Filter notes for the specific company
        backendNotes = backendNotes.filter((note) => note.organization_id === companyId)

        backendNotes = backendNotes.sort((a, b) => new Date(b.updated_at || b.created_at).getTime() - new Date(a.updated_at || a.created_at).getTime())

        setNotes(backendNotes)
      } catch (error) {
        console.error('Failed to fetch company data or notes', error)
      }
    }

    const fetchCurrentUser = async () => {
      try {
        const user = await currentUser()
        setCurrentUserData(user)
      } catch (error) {
        console.error('Failed to fetch current user', error)
      }
    }

    fetchCompanyDataAndNotes()
    fetchCurrentUser()
  }, [companyId])

  const addNote = async () => {
    if (newNote.trim() === '' || !currentUserData) return

    const newNoteData = {
      note: {
        description: newNote,
        private: false,
        organization_id: companyId,
      },
    }

    try {
      const createdNote = await createNote(newNoteData)
      setNotes([createdNote, ...notes])
      setNewNote('')
      setShowNoteInput(false)
    } catch (error) {
      console.error('Failed to create note', error)
    }
  }

  const saveNote = async (id: string) => {
    const noteToUpdate = notes.find((note) => note.id === id)
    if (!noteToUpdate) return

    const updatedNoteData = {
      note: {
        description: editingContent,
        private: noteToUpdate.isPrivate,
      },
      noteId: id,
    }

    try {
      const updatedNote = await updateNote(updatedNoteData)
      setNotes(notes.map((note) => (note.id === id ? updatedNote : note)))
      setEditingNoteId(null)
      setEditingContent('')
    } catch (error) {
      console.error('Failed to update note', error)
    }
  }

  const modules = {
    toolbar: [[{ font: [] }], [{ size: [] }], ['bold', 'italic', 'underline', 'strike', 'blockquote'], [{ list: 'ordered' }, { list: 'bullet' }]],
  }

  return (
    <div className="company-notes">
      <div className="header">
        <button onClick={() => setShowNoteInput(true)} className="add-note-button">
          + Add new note
        </button>
      </div>
      {showNoteInput && (
        <div className="note-input">
          <ReactQuill value={newNote} onChange={setNewNote} placeholder="Write your note here..." modules={modules} />
          <button onClick={addNote} className="post-button">
            Post Note
          </button>
        </div>
      )}
      <hr />
      <div className="notes-list">
        {notes.map((note) => (
          <div key={note.id} className="note">
            <button
              onClick={() => {
                setEditingNoteId(note.id)
                setEditingContent(note.description)
              }}
              className="edit-button"
            >
              ✎
            </button>
            <p className="note-meta">
              {currentUserData && currentUserData.name} • {note.updated_at !== note.created_at ? 'updated on ' : ''}
              <FormattedDate date={new Date(note.updated_at || note.created_at)} />
            </p>
            {editingNoteId === note.id ? (
              <>
                <ReactQuill value={editingContent} onChange={setEditingContent} modules={modules} />
                <button onClick={() => saveNote(note.id)} className="save-button">
                  Save
                </button>
              </>
            ) : (
              <div className="note-content" dangerouslySetInnerHTML={{ __html: note.description }} />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CompanyNotes
