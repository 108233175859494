import React, { FC, useState, useEffect, ReactElement } from 'react'
import Skeleton from 'react-loading-skeleton'
import styles from './styles.module.scss'
import { useFetchCompanies } from '../components/useFetchCompanies'
import BrowsingHistoryItem from './BrowsingHistoryItem'
import BrowsingHistoryContainer from './BrowsingHitsoryContainer'
import { getLastVisitedCompanies } from '../../../../logic/Backend'

export const BrowsingHistory: FC = () => {
  const { companies, visibleCompanies, setFrom, total_count, loading } = useFetchCompanies(0, 10, getLastVisitedCompanies)
  const [initialLoading, setInitialLoading] = useState(true)

  useEffect(() => {
    if (!loading) {
      setInitialLoading(false)
    }
  }, [loading])

  const sortedVisibleCompanies = visibleCompanies.sort((a, b) => new Date(b.visited_at).getTime() - new Date(a.visited_at).getTime()).slice(0, 10)

  const renderedItems = (
    <div className={styles.scrollable_BH}>
      {initialLoading
        ? ((<Skeleton count={8} height={80} width={284} style={{ marginRight: '4px' }} />) as ReactElement)
        : sortedVisibleCompanies.map((company) => <BrowsingHistoryItem key={company.id} company={company} />)}
    </div>
  )

  return <>{sortedVisibleCompanies.length > 0 || loading ? <BrowsingHistoryContainer title="" renderedItems={renderedItems} /> : null}</>
}

export default BrowsingHistory
