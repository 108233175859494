import { useState, useEffect, Dispatch, SetStateAction } from 'react'

interface Company {
  id: string
  name?: string
  name_jp?: string | null
  industry_tag?: string
  tags?: string[]
  short_description?: string
  short_description_jp?: string | null
  growth?: number
  employee_growth?: string
  recommended_score?: number
  created_at?: string
  logo_id?: string
  founded_on_date?: string
  visited_at?: string
  domain?: string
  hot_score?: number
}

export const useFetchCompanies = (
  initialFrom: number,
  initialLimit: number,
  fetchFunction: (params: { from: number; limit: number }) => Promise<{ companies: Company[]; total_count: number }>
): {
  companies: Company[]
  visibleCompanies: Company[]
  setFrom: Dispatch<SetStateAction<number>>
  total_count: number
  loading: boolean
} => {
  const [companies, setCompanies] = useState<Company[]>([])
  const [visibleCompanies, setVisibleCompanies] = useState<Company[]>([])
  const [from, setFrom] = useState(initialFrom)
  const [limit] = useState(initialLimit)
  const [total_count, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await fetchFunction({ from, limit })
        const { companies: newCompanies, total_count } = response
        setCompanies((prevCompanies) => [...prevCompanies, ...newCompanies])
        setVisibleCompanies((prevCompanies) => [...prevCompanies, ...newCompanies])
        setTotalCount(total_count)
      } catch (error) {
        console.error('Error fetching companies:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [from, fetchFunction, limit])

  return {
    companies,
    visibleCompanies,
    setFrom,
    total_count,
    loading,
  }
}
