import React, { FC } from 'react'
import styles from './DetailBlockWidget.module.css'
import { classForLineClamp } from '../../../logic/ValueFormatters'
import cx from 'classnames'

export const DetailBlockDefaultTargetWrapper: FC<{
  height?: number | null
  wrapperClassName?: string | null
  wrapperProps?: React.HTMLAttributes<HTMLElement> | null
}> = (props) => {
  return (
    <div className={cx(styles.DetailBlockValue, classForLineClamp(props.height || 80), props.wrapperClassName)} {...props.wrapperProps}>
      {props.children}
    </div>
  )
}
