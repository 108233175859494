import { createModel } from '@rematch/core'
import { RootModel } from '.'

const chatLoadingState = createModel<RootModel>()({
  state: false,
  reducers: {
    updateChatLoadingState(state, payload) {
      return payload
    },
  },
})

export default chatLoadingState
