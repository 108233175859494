import React, { FC, useEffect, useState } from 'react'
import { SearchLogicDropdown, searchLogicMap, SearchLogicValue } from './SearchLogicDropdown'
import { Switch } from '@blueprintjs/core'
import equal from 'fast-deep-equal/es6'
import { Filter, FilterState } from '../../ui/components/Filters'
import FilterDropdown from './FilterDropdown'
import { inListsPickerProps } from './Filters/MultiSelect'

export interface OmniFilterProps {
  standalone?: boolean
  noSwitch?: boolean
  onChange?: (state: OmniFilterState) => void
  fixed?: boolean
}

export interface OmniFilterState {
  filter?: Filter
  searchLogic?: SearchLogicValue
  filterState?: FilterState
  showNoData?: boolean
}

export const OmniFilter: FC<OmniFilterProps & OmniFilterState> = ({ standalone, noSwitch, filter, searchLogic, filterState, showNoData, onChange, fixed }) => {
  const [filterProp, setfilterProp] = useState(filter)
  const [filter$, setFilter$] = useState(filter)
  const [searchLogicProp, setSearchLogicProp] = useState(searchLogic)
  const [searchLogic$, setSearchLogic$] = useState(searchLogic)
  const [showNoDataProp, setShowNoDataProp] = useState(showNoData)
  const [showNoData$, setShowNoData$] = useState(showNoData)
  const [filterStateProp, setFilterStateProp] = useState(filterState)
  const [filterState$, setFilterState$] = useState(filterState)
  const [filterStateAsync, setFilterStateAsync] = useState({})

  if (filter?.id !== filterProp?.id) {
    setfilterProp(filter)
    setFilter$(filter)
  }

  if (searchLogic !== searchLogicProp) {
    setSearchLogicProp(searchLogic)
    setSearchLogic$(searchLogic)
  }

  if (!equal(filterState, filterStateProp)) {
    setFilterStateProp(filterState)
    setFilterState$(filterState)
  }

  if (showNoData !== showNoDataProp) {
    setShowNoDataProp(showNoData)
    setShowNoData$(showNoData)
  }

  function filterOnChange(filter: Filter): void {
    setFilter$(filter)
    setSearchLogic$(undefined)
    setFilterState$(undefined)
  }

  function searchLogicOnClick(value: SearchLogicValue): void {
    setSearchLogic$(value)
    onChange?.({
      filter: filter$,
      searchLogic: value,
      filterState: filterState$,
      ...(showNoData$ !== undefined && { showNoData: showNoData$ }),
    })
  }

  function fcOnChange(filterState_: FilterState): void {
    setFilterState$(filterState_)
    onChange?.({
      filter: filter$,
      searchLogic: searchLogic$,
      filterState: filterState_,
      ...(showNoData$ !== undefined && { showNoData: showNoData$ }),
    })
  }

  function onChangeSwitch() {
    const showNoData_ = !showNoData$
    setShowNoData$(showNoData_)
    onChange?.({
      filter: filter$,
      searchLogic: searchLogic$,
      filterState: filterState$,
      ...(showNoData_ !== undefined && { showNoData: showNoData_ }),
    })
  }

  function showSwitch(filter?: Filter): boolean {
    return (
      !noSwitch &&
      filter !== undefined &&
      ['numberRange', 'set'].includes(filter.type) &&
      !['rating', 'ml_rating', 'list_ids', 'industries'].includes(filter.id)
    )
  }

  if (filter$?.type) {
    const options = searchLogicMap[filter$.type] as unknown as SearchLogicValue[]
    if (options.length === 1 && searchLogic$ === undefined) {
      searchLogicOnClick(options[0])
    }
  }

  async function loadFilterStateAsync() {
    if (filter$?.id === 'list_ids') {
      setFilterStateAsync(await inListsPickerProps())
    }
  }

  useEffect(() => {
    loadFilterStateAsync()
  }, [filter$?.id])

  const component = (
    <>
      <div>
        <FilterDropdown filter={filter$} onChange={filterOnChange} fixed={fixed} />
      </div>
      <div>{filter$ && <SearchLogicDropdown value={searchLogic$} type={filter$.type} onChange={searchLogicOnClick} fixed={fixed} />}</div>
      <div {...(standalone && { style: { flexGrow: '1' } })}>
        {searchLogic$ &&
          (filter$?.id !== 'list_ids' || Object.keys(filterStateAsync).length > 0) &&
          filter$?.fc?.({ ...filterState$, ...filterStateAsync }, (fs) => fcOnChange(fs), searchLogic$)}
      </div>
      {!standalone && (
        <div>
          {searchLogic$ && showSwitch(filter) && (
            <Switch label={undefined} checked={showNoData$} innerLabel={'Hide'} innerLabelChecked={'Show'} onChange={onChangeSwitch} />
          )}
        </div>
      )}
    </>
  )

  if (standalone) {
    return <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-start' }}>{component}</div>
  }

  return component
}
