import { createModel } from '@rematch/core'
import { RootModel } from '.'

const initialState = 0
const totalCompanyCountState = createModel<RootModel>()({
  state: initialState,
  reducers: {
    updateCompanyCountState(state, payload) {
      return payload
    },
  },
})

export default totalCompanyCountState
