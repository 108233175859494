//index.tsx
import React, { useState, useEffect } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import Column from '../Pipeline/components/column'
import '../Pipeline/styles/index.scss'
import { Icon } from '@blueprintjs/core'
import { getPipelineData, updatePipelineData } from '../../../logic/Backend'
import { t } from 'i18next'

export type CardData = {
  id: string
  status: string
  customer_id: string
  organization_id: string
  created_at: string
  updated_at: string
  organization_logo: string | null
  organization_name: string
  user_name: string
}

type Status = 'new' | 'active' | 'diligence' | 'won' | 'missed' | 'passed'

const Board: React.FC = () => {
  const columnOrder: Status[] = ['new', 'active', 'diligence', 'won', 'missed', 'passed']
  const [columns, setColumns] = useState<Record<string, CardData[]>>({})
  const [collapsedColumns, setCollapsedColumns] = useState<Record<string, boolean>>({})
  const [data, setData] = useState<any>()
  const [error, setError] = useState<unknown | undefined>()

  useEffect(() => {
    try {
      getPipelineData().then((data) => setData(data))
    } catch (error) {
      setError(error)
    }
  }, [])

  useEffect(() => {
    if (data && !error) {
      const newColumns: Record<string, CardData[]> = columnOrder.reduce((acc, cur) => {
        acc[cur] = []
        return acc
      }, {})

      data?.rows?.forEach((card) => {
        const status = card.status
        newColumns[status]?.push(card)
      })

      setColumns(newColumns)
    }
  }, [data])

  const isValidStatus = (status: string): status is Status => {
    return ['new', 'active', 'diligence', 'won', 'missed', 'passed'].includes(status.toLowerCase())
  }

  const toggleColumn = (columnId: string) => {
    setCollapsedColumns((prev) => ({
      ...prev,
      [columnId]: !prev[columnId],
    }))
  }
  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result

    if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
      return
    }

    const sourceStatus = source.droppableId
    const destinationStatus = destination.droppableId

    if (!isValidStatus(sourceStatus) || !isValidStatus(destinationStatus)) {
      console.error('Invalid status:', sourceStatus, 'or', destinationStatus)
      return
    }

    const cardBeingDragged = columns[sourceStatus][source.index]

    const currentColumns = JSON.parse(JSON.stringify(columns))

    // Optimistic updatingg
    const newColumns = { ...columns }
    const startColumnCards = Array.from(newColumns[sourceStatus])
    startColumnCards.splice(source.index, 1)
    newColumns[sourceStatus] = startColumnCards

    const finishColumnCards = Array.from(newColumns[destinationStatus] || [])
    finishColumnCards.splice(destination.index, 0, cardBeingDragged)
    newColumns[destinationStatus] = finishColumnCards

    setColumns(newColumns)

    try {
      updatePipelineData({ organization_ids: [cardBeingDragged.organization_id], pipeline_status: destinationStatus })
    } catch (error) {
      console.error('Failed to update card status:', error)
      setColumns(currentColumns)
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="pipeline-title">
        <Icon icon="layers" iconSize={20} />
        <h1>{t('Team Pipeline')}</h1>
      </div>
      <div className="board">
        {Object.entries(columns).map(([columnId, cards]) => (
          <Column
            key={columnId}
            columnId={columnId}
            title={t(columnId.charAt(0).toUpperCase() + columnId.slice(1))}
            cards={cards}
            isCollapsed={collapsedColumns[columnId] || false}
            toggleColumn={() => toggleColumn(columnId)}
          />
        ))}
      </div>
    </DragDropContext>
  )
}

export default Board
