import React, { FC, CSSProperties } from 'react'
import { hrefForId } from '../../logic/Routes'
import * as Routes from '../../logic/Routes'
import styles from '../../ui/app/App.module.scss'
import { CbLiOrPlaceholderImage } from '../pages/Company/CbLiOrPlaceholderImage'
import { companyLink } from '../../logic/ValueFormatters'
import { ICellRendererParams } from '@ag-grid-community/core'
import logo from '../../resources/images/logo.png'
import IsNewTag from './IsNewTag'
import { useSelector } from 'react-redux'

const NameWithHomepageLink: FC<
  { name?: string; homepage?: string; domain?: string; id: string; logo_id?: string; li_logo_url?: string; is_new?: boolean } | ICellRendererParams
> = (props) => {
  const name = 'value' in props ? props.value : props.name
  const homepage = 'data' in props ? props.data.homepage : props.homepage
  const domain = 'data' in props ? props.data.domain : props.domain
  const id = 'data' in props ? props.data.id : props.id
  const logo_id = 'data' in props ? props.data.logo_id : props.logo_id
  const li_logo_url = 'data' in props ? props.data.li_logo_id : props.li_logo_url
  const isNew = 'data' in props ? props.data.is_new : props.is_new
  const link = companyLink(homepage, domain)
  if (!name) {
    return <></>
  }
  const columnSizeState = useSelector((state: any) => state.companyNameColumnSizeState)

  return (
    <div className={styles.nameAndLogo}>
      {isNew && <IsNewTag />}
      {link ? (
        <a href={link} title="Visit website" target="_blank" rel="noopener noreferrer">
          <span role="img">
            <CbLiOrPlaceholderImage logo_id={logo_id} li_logo_url={logo_id ? li_logo_url : logo} altText={'Logo'} />
          </span>
        </a>
      ) : (
        <span role="img">
          <CbLiOrPlaceholderImage logo_id={logo_id} li_logo_url={logo_id ? li_logo_url : logo} altText={'Logo'} />
        </span>
      )}
      <span
        className={styles.blueLink}
        style={{ maxWidth: Object.keys(columnSizeState).length === 0 ? undefined : columnSizeState.columns[0]?.actualWidth - 60 }}
      >
        <a href={hrefForId(Routes.company, id)} dangerouslySetInnerHTML={{ __html: name }}></a>
      </span>
    </div>
  )
}

export default NameWithHomepageLink
