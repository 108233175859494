import React, { FC, useState } from 'react'
import { Button, Checkbox, Classes, Dialog, IOptionProps, InputGroup, Intent, MenuItem } from '@blueprintjs/core'
import * as Backend from '../../logic/Backend'
import { SignupRequest } from '../../logic/Backend'
import { showErrorToast } from '../../logic/Toaster'
import * as Routes from '../../logic/Routes'
import { PasswordButton } from '../components/PasswordButton'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { FIRM_TYPES } from '../../resources/reference_data/firm_types'
import { submitForm, validation } from '../../logic/FormValidation'
import { PlainPageTemplate } from './PlainPageTemplate'
import { routerHistory } from '../app/App'
import { Select } from '../../stories/KurrantTheme/Select'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { t } from 'i18next'

const SignupPage: FC<WithNamespaces> = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [dialogMessage, setDialogMessage] = useState('')
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      companyName: '',
      firmType: '' as string | number,
      password: '',
      terms: false,
    },
    validationSchema: yup.object({
      name: yup.string().label('Full Name').required(),
      email: yup.string().label('Email').email().required(),
      companyName: yup.string().label('Company Name').required(),
      firmType: yup.number().label('Firm Type').required(),
      password: yup.string().label('Password').min(8).required(),
      terms: yup.boolean().oneOf([true], 'Terms of Use must be accepted'),
    }),
    onSubmit: async (values) => {
      try {
        const response = await Backend.signup({ ...values, signupOrigin: 'webapp', language: localStorage.getItem('language') } as SignupRequest)
        location.href = response.stripe_url
      } catch (error) {
        if (error.message.match(/status: (\d+)/)?.[1] === '417') {
          formik.setFieldError('name', `${t('This email is already taken')}`)
        } else {
          showErrorToast(t('Failed to sign up'))
        }
      }
    },
  })

  const responsiveFirmTypes: IOptionProps[] = [
    { label: `${t('Firm Type')}`, value: '', disabled: true },
    { label: `${t('Private Equity')}`, value: 0 },
    { label: `${t('Venture Capital')}`, value: 1 },
    { label: `${t('Search Fund')}`, value: 2 },
    { label: `${t('Corporate Venture Capital')}`, value: 3 },
    { label: `${t('Investment Bank')}`, value: 4 },
    { label: `${t('Other')}`, value: 5 },
  ]

  const [firmType, setFirmType] = useState(responsiveFirmTypes.find((t) => t.value === formik.values.firmType)!)

  function goToLoginPage() {
    routerHistory.push(Routes.login.href)
  }

  return (
    <PlainPageTemplate>
      <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px', width: '100%' }}>
        <InputGroup {...validation(formik, 'name')} fill={true} placeholder={`${t('Full Name')}`} onChange={formik.handleChange} />
        <InputGroup {...validation(formik, 'email')} fill={true} placeholder={`${t('Email')}`} onChange={formik.handleChange} />
        <InputGroup {...validation(formik, 'companyName')} fill={true} placeholder={`${t('Company Name')}`} onChange={formik.handleChange} />
        <Select
          items={FIRM_TYPES.filter((t) => t.value !== '')}
          itemRenderer={(item, { modifiers, handleClick }) => {
            return <MenuItem active={modifiers.active} key={item.value} onClick={handleClick} text={t(item.label || '')} />
          }}
          onItemSelect={(e) => {
            setFirmType(e)
            formik.values.firmType = e.value as string
          }}
          filterable={false}
          fill={true}
          popoverProps={{ minimal: true, fill: true }}
        >
          <Button {...validation(formik, 'firmType')} text={firmType.label} rightIcon="caret-down" alignText={'left'} fill={true} />
        </Select>
        <PasswordButton placeholder={`${t('Password (8+ characters)')}`} {...validation(formik, 'password')} onChange={formik.handleChange} />
        <Checkbox style={{ color: '#999999' }} {...validation(formik, 'terms')} onChange={formik.handleChange}>
          <>
            {t('By signing up, I agree to Kurrant.ai’s ')}
            <a href={'https://kurrant.ai/terms-of-use'} target="_blank" rel="noreferrer">
              {t('Terms of Use')}
            </a>
            {t(' and ')}
            <a href={'https://kurrant.ai/privacy-policy'} target="_blank" rel="noreferrer">
              {t('Privacy Policy')}
            </a>
          </>
        </Checkbox>
        <Button
          onClick={() => submitForm(formik)}
          type={'button'}
          loading={formik.isValidating || formik.isSubmitting}
          intent={Intent.PRIMARY}
          text={t('Create Account')}
          fill={true}
        />
        <p style={{ marginTop: '20px', color: '#999999' }}>
          {t('Already have an account?')} <a href={Routes.login.href}>{t('Login')}</a>
        </p>
      </form>
      <Dialog title={t('Sign Up Successful')} isOpen={isDialogOpen} onClose={() => goToLoginPage()}>
        <div style={{ margin: '20px 20px 0 20px' }}>
          <p>{dialogMessage}</p>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => goToLoginPage()} intent={Intent.PRIMARY} text={t('Login')} />
          </div>
        </div>
      </Dialog>
    </PlainPageTemplate>
  )
}

export default withNamespaces()(SignupPage)
