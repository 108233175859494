import { MenuItem } from '@blueprintjs/core'
import { classForRating } from '../../logic/ValueFormatters'
import React, { FC, useState } from 'react'
import { CompanyData } from '../../logic/Backend'
import { switchRating } from '../../logic/Helpers'
import { RATINGS } from '../../resources/reference_data/ratings'
import { GridApi } from '@ag-grid-community/core'

export function getSelectedRowsRating(selectedRows: CompanyData[]): Map<number, number> {
  const ratingCountMap = RATINGS.reduce((a, e, i) => a.set(i, 0), new Map<number, number>())
  return (
    selectedRows.reduce((a, e) => {
      if (e.rating !== undefined && e.rating !== null) {
        const rating = Number(e.rating)
        const currentCount = a.get(rating)
        a.set(rating, currentCount === undefined ? 1 : currentCount + 1)
      }
      return a
    }, ratingCountMap) || ratingCountMap
  )
}

export interface RatingMenuProps {
  api?: GridApi
}

export const RatingMenu: FC<RatingMenuProps> = ({ api }) => {
  const [refresh, forceRefresh] = useState(false)
  const selectedNodes = api?.getSelectedNodes() || []
  const selectedRows: CompanyData[] = api?.getSelectedRows() || []
  const selectedRowsCount = selectedRows.length
  const ratingCountMap = getSelectedRowsRating(selectedRows)

  function format(ratingCount: number | undefined): string {
    return ratingCount !== undefined && ratingCount > 0 && selectedRowsCount !== undefined && selectedRowsCount > 1 ? ` ${ratingCount}` : ''
  }

  async function onClick(event: React.MouseEvent<HTMLElement>, rating_: number) {
    await switchRating(rating_, selectedRows, selectedNodes)
    forceRefresh(!refresh)
  }

  return (
    <>
      {RATINGS.map((rating, i) => {
        const ratingCount = ratingCountMap.get(i)
        return (
          <MenuItem
            icon={ratingCount! > 0 ? 'tick' : 'blank'}
            key={i}
            shouldDismissPopover={false}
            text={rating}
            onClick={(event: React.MouseEvent<HTMLElement>) => onClick(event, i)}
            className={classForRating(i, ratingCount! > 0)}
            active={ratingCount! > 0}
            labelElement={format(ratingCount)}
          />
        )
      })}
    </>
  )
}
