import React, { FC } from 'react'
import styles from '../components/styles.module.scss'
import defaultLogo from '../components/logo192.png'
import { CompanyData } from '../../../../logic/Backend'
import { Link } from 'react-router-dom'

interface CompanyItemProps {
  company: CompanyData
  additionalInfo?: React.ReactNode
}

const getLogoUrl = (logo_id: string | undefined) => {
  if (!logo_id) return defaultLogo
  return `https://images.crunchbase.com/image/upload/${logo_id}`
}

const CompanyItem: FC<CompanyItemProps> = ({ company, additionalInfo }) => {
  const truncatedName = company.name.length > 15 ? company.name.substring(0, 15) + '...' : company.name

  return (
    <div className={styles.companies__item}>
      <div className={styles.logoWrapper}>
        <img src={getLogoUrl(company.logo_id)} alt={`${company.name} logo`} className={styles.companies__logo} />
      </div>
      <div className={styles.companies__info}>
        <div className={styles.companies__header}>
          <div className={styles.companies__name}>
            <Link to={`/companies/${company.id}`} className={styles.companyLink}>
              {truncatedName}
            </Link>
          </div>
          {additionalInfo}
        </div>
        {(company.tags && company.tags.length > 0) ||
          (company.industry_tag && (
            <div className={styles.companies__tags}>
              {company.industry_tag && <span className={styles.companies__tag + ' ' + styles['companies__tag--industry']}>{company.industry_tag}</span>}
              {company.tags?.map((tag, index) => (
                <span key={index} className={styles.companies__tag}>
                  {tag}
                </span>
              ))}
              {company.founded_on_date && <span className={styles.founded_on_date_tag}>Founded in {new Date(company.founded_on_date).getFullYear()}</span>}
            </div>
          ))}
        <p className={styles.companies__description}>{company.short_description}</p>
      </div>
    </div>
  )
}

export default CompanyItem
