import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react'
import { ButtonProps } from '@blueprintjs/core'
import { Tooltip } from './Tooltip'

export interface TruncatableTextProps extends ButtonProps {
  text?: string
  maxLines?: number
  style?: CSSProperties
  className?: string
}

export const TruncatableText: FC<TruncatableTextProps> = ({ text, maxLines = 1, style, className }) => {
  const textRef = useRef<HTMLDivElement>(null)
  const [isContentOverflowing, setIsContentOverflowing] = useState(isContentOverflowing_())
  const resizeObserver = useRef(new ResizeObserver(() => setIsContentOverflowing(isContentOverflowing_())))

  useEffect(() => {
    if (textRef.current) {
      resizeObserver.current.observe(textRef.current)
    }
    return () => {
      if (textRef.current) {
        resizeObserver.current.unobserve(textRef.current)
      } else {
        resizeObserver.current.disconnect()
      }
    }
  }, [textRef])

  function isContentOverflowing_(): boolean {
    return textRef.current != null && (textRef.current.scrollHeight > textRef.current.clientHeight || textRef.current.scrollWidth > textRef.current.clientWidth)
  }

  if (text == null) {
    return <></>
  }

  return (
    <Tooltip disabled={!isContentOverflowing} targetTagName={'div'} wrapperTagName={'div'}>
      <div
        style={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: maxLines,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...style,
        }}
        className={className}
        ref={textRef}
      >
        {text}
      </div>
      <div>{text}</div>
    </Tooltip>
  )
}
