import React, { FC } from 'react'
import { Button, ButtonProps, Tag } from '@blueprintjs/core'

export interface OmniFilterButtonProps extends ButtonProps {
  tagText?: string
  text?: string
  fixed?: boolean
}

export const OmniFilterButton: FC<OmniFilterButtonProps> = ({ tagText, text, fixed, ...buttonProps }) => {
  if (fixed) {
    return (
      <Tag minimal={true} large={true}>
        {text}
      </Tag>
    )
  }
  if (text) {
    return <Button fill={true} alignText={'left'} text={text} {...buttonProps} />
  }
  return (
    <Button fill={true} alignText={'left'} {...buttonProps}>
      <Tag minimal={true}>{tagText}</Tag>
    </Button>
  )
}
