import React, { FC, useEffect, useState } from 'react'
import { NumericInput, NumericInputProps } from '@blueprintjs/core'
import { internationalUnits, unitTuples } from '../../../logic/ValueFormatters'

export interface NumberPickerProps extends NumericInputProps {
  forceRender?: boolean
  value?: number
  onChange?: (value?: number) => void
  formatting?: 'suffixes' | 'locale' | 'raw'
}

export const NumberPicker: FC<NumberPickerProps> = ({ value, min, max, leftIcon, placeholder, fill, forceRender, onChange, formatting }) => {
  const [valueAsNumber$, setValueAsNumber$] = useState(value)
  const [valueAsString$, setValueAsString$] = useState(format(value))
  const [min_, setMin_] = useState(min ? (leftIcon === 'percentage' ? min * 100 : min) : undefined)
  const [max_, setMax_] = useState(max ? (leftIcon === 'percentage' ? max * 100 : max) : undefined)

  useEffect(() => {
    setValueAsNumber$(value)
    setValueAsString$(format(value))
  }, [forceRender])

  function clampToMinMax(n?: number, min?: number, max?: number): number | undefined {
    if (n === undefined) {
      return undefined
    }
    let r = n
    if (min !== undefined) {
      r = Math.max(r, min)
    }
    if (max !== undefined) {
      r = Math.min(r, max)
    }
    return r
  }

  function onBlur_(event: React.FocusEvent<HTMLInputElement>): void {
    let v = clampToMinMax(stringToNumber(event.target.value), min_, max_)
    v = v === undefined ? undefined : leftIcon === 'percentage' ? v / 100 : v
    setValueAsNumber$(v)
    setValueAsString$(format(v))
    onChange?.(v)
  }

  function format(v?: number): string {
    if (v === undefined) {
      return ''
    }
    if (leftIcon === 'percentage') {
      v *= 100
    }
    if (formatting == 'suffixes' && v >= 1_000) {
      let s = internationalUnits(v, 1, '', '')
      if (s.charAt(s.length - 2) === '0') {
        s = s.slice(0, s.length - 3) + s.slice(s.length - 1)
      }
      return s
    }
    if (formatting == 'locale') {
      return v.toLocaleString()
    }
    return v.toString()
  }

  return (
    <NumericInput
      allowNumericCharactersOnly={false}
      className={'bp3-numeric-input-full-width'}
      value={valueAsString$}
      placeholder={placeholder ?? 'Any'}
      fill={fill}
      onValueChange={(valueAsNumber, valueAsString) => setValueAsString$(valueAsString)}
      onBlur={onBlur_}
      min={min}
      max={max}
      buttonPosition={'none'}
      leftIcon={leftIcon}
      // rightElement={leftIcon ? <Tag>{leftIcon === 'dollar' ? '$' : '%'}</Tag> : undefined}
    />
  )
}

export function stringToNumber(s: string): number | undefined {
  s = s.trim().replace(/,/g, '')
  const regex = /^(-?\d+\.\d+)([KMBT])$|^(-?\d+)([KMBT])?$/i
  const matches = regex.exec(s)
  if (matches) {
    const [_, n1, n1Suffix, n2, n2Suffix] = matches
    const n = stringToNum(n1 ?? n2)
    const suffix = n1Suffix ?? n2Suffix
    if (suffix === undefined || n === undefined) {
      return n
    }
    for (const [scale, unit] of unitTuples) {
      if (unit === suffix.toUpperCase()) {
        return Math.round(n * scale)
      }
    }
  }
  return undefined
}

function stringToNum(s: string): number | undefined {
  const n = Number(s)
  return isNaN(n) ? undefined : n
}
