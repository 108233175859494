// column.tsx
import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import '../styles/column.scss'
import Card from '../components/card'
import { CardData } from '../index'
import { Icon } from '@blueprintjs/core'

const columnColors = {
  new: '#D75AEC',
  active: '#85B857',
  diligence: '#0D75FF',
  won: '#FF5EC4',
  missed: '#A90636',
  passed: '#340059',
}

type ColumnProps = {
  title: string
  cards: CardData[]
  columnId: string
  isCollapsed: boolean
  toggleColumn: (columnId: string) => void
}

function capitalizeTitle(title: string) {
  return title
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const Column: React.FC<ColumnProps> = ({ title, cards, columnId, isCollapsed, toggleColumn }) => {
  const handleToggleCollapse = () => {
    toggleColumn(columnId)
  }

  const displayTitle = capitalizeTitle(title)
  const color = columnColors[title.toLowerCase()] || '#000'

  return (
    <div className={`column ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="column-header" onClick={handleToggleCollapse}>
        <span className="collapse-toggle">{<Icon icon={!isCollapsed ? 'chevron-down' : 'chevron-right'} iconSize={20} />}</span>
        <span className="status-dot" style={{ backgroundColor: color }}></span>
        <h2 style={{ color }}>{displayTitle}</h2>
        <div className="counter">
          <span className="card-count">{cards.length}</span>
        </div>
      </div>
      {!isCollapsed && (
        <Droppable droppableId={columnId}>
          {(provided) => (
            <div className="column-cards" {...provided.droppableProps} ref={provided.innerRef}>
              {cards.map((card, index) => (
                <Card
                  key={card.id}
                  id={card.id}
                  index={index}
                  organization_name={card.organization_name}
                  user_name={card.user_name}
                  organization_logo={card.organization_logo}
                  organization_id={card.organization_id}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </div>
  )
}

export default Column
