import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import ToggleView from './ToggleView'
import DialogueArea from './DialogueArea'
import QueryInput from './QueryInput'

export type DialogueType = {
  message: string
  source: string
}

type GPTResponseType = {
  reply: string
}
const apiUrl = process.env.REACT_APP_KURRANT_GPT_API

const initialMessage = 'Welcome to KurrantGPT.  How can I help you today?'
const Chatbox = () => {
  const [visible, setVisible] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dialogueHistory, setDialogueHistory] = useState<DialogueType[]>([{ message: initialMessage, source: 'gpt' }])
  const [apiResponse, setApiResponse] = useState<GPTResponseType>()
  useEffect(() => {
    const lastMessageIndex = dialogueHistory.length - 1
    const fetchData = async (query: string) => {
      setIsLoading(true)
      const response = await fetch(apiUrl + encodeURI(query))
      setApiResponse(await response.json())
    }
    // get the last message's prompt if from user
    if (dialogueHistory[lastMessageIndex].source === 'user') {
      fetchData(dialogueHistory[lastMessageIndex].message).then(() => setIsLoading(false))
    }
  }, [dialogueHistory.length])

  useEffect(() => {
    apiResponse?.reply && setDialogueHistory([...dialogueHistory, { message: apiResponse?.reply, source: 'gpt' }])
  }, [apiResponse?.reply])
  return (
    <div className={styles.chatBoxContainer}>
      <div className={`${visible ? styles.chatWindowContainer : styles.notVisible}`}>
        <DialogueArea chatHistory={dialogueHistory} isLoading={isLoading} />
        <QueryInput chatHistory={dialogueHistory} appendQueryToChat={setDialogueHistory} />
      </div>
      <ToggleView visibility={visible} toggleVisibility={setVisible} />
    </div>
  )
}

export default Chatbox
