import React, { useEffect, useRef } from 'react'
import styles from '../style.module.scss'
import { DialogueType } from '..'
import KurrantGPT from '../../../../resources/images/KurrantGPT_small_icon.svg'
interface Props {
  chatHistory: DialogueType[]
  isLoading: boolean
}

const DialogueArea = (props: Props) => {
  const { chatHistory, isLoading } = props
  const latestMessageRef = useRef<null | HTMLDivElement>(null)
  const scrollToBottom = () => {
    latestMessageRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  useEffect(() => {
    scrollToBottom()
  }, [chatHistory.length, isLoading])
  return (
    <div className={`${styles.maxWidth} ${styles.dialogueArea}`}>
      {chatHistory.map((chatBlock, i) => {
        return chatBlock.source === 'gpt' ? (
          <div key={i} className={styles.dialogueBlock}>
            <img className={styles.gptIcon} src={KurrantGPT} />
            <span className={styles.gptDialogueText}>{chatBlock.message}</span>
          </div>
        ) : (
          <div key={i} className={styles.userDialogueBlock}>
            <span className={styles.userDialogueText}>{chatBlock.message}</span>
          </div>
        )
      })}
      {isLoading && (
        <div className={styles.dialogueBlock}>
          <img className={styles.gptIcon} src={KurrantGPT} />
          <span className={styles.searchingText}>Searching...</span>
        </div>
      )}
      <div ref={latestMessageRef} />
    </div>
  )
}

export default DialogueArea
