import React, { FC } from 'react'
import { initial } from '../logic/ValueFormatters'
import palette from '../_palette.module.scss'

export const InitialsInDisk: FC<{ name: string | undefined }> = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        height: '22px',
        width: '22px',
        borderRadius: '50%',
        background: `linear-gradient(to right, #4D52DE, ${palette.blue3})`,
        color: 'white',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className="initials-display">{initial(props.name)}</div>
    </div>
  )
}
