import React from 'react'
import { Button } from '@blueprintjs/core'
import { Tooltip } from '../../stories/Tooltip'
import { SavedFilterData } from '../../logic/Backend'
interface AlertToggleButtonProps {
  savedFilter: SavedFilterData
  onClick: (filterId: string, shouldToggleAlert: boolean) => void
}

const AlertToggleButton: React.FC<AlertToggleButtonProps> = ({ savedFilter, onClick }) => {
  const alertActive = savedFilter.active_alert === true

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onClick(savedFilter.id, true)
  }

  const tooltipContent = alertActive ? 'Turn off email alert' : 'Opt in for email alerts'

  return (
    <div className="alert-button">
      <Tooltip content={tooltipContent} position="top" popoverClassName="alert">
        <Button icon={alertActive ? 'notifications-updated' : 'notifications'} onClick={handleClick} />
      </Tooltip>
    </div>
  )
}

export default AlertToggleButton
