export const subRegions = [
  'Caribbean',
  'Central America',
  'North America',
  'South America',
  'Antarctica',
  'Australia and Oceania',
  'Central & South Asia',
  'Northeastern Asia',
  'Southeastern Asia',
  'Central Europe',
  'Eastern Europe',
  'Northern Europe',
  'Southern Europe',
  'Western Europe',
  'Central Africa',
  'Middle East',
  'Northern Africa',
  'Southern Africa',
  'Western Africa',
]

export const regions = ['Americas', 'Antarctica', 'Asia Pacific', 'Europe', 'Middle East/Africa']
