import React, { FC, ReactNode } from 'react'
import { Divider, IBreadcrumbProps, Navbar, NavbarGroup } from '@blueprintjs/core'
import styles from './index.module.scss'
import BreadcrumbsNavigation from './BreadcrumbsNavigation'

export interface TopBarProps {
  breadcrumbs?: IBreadcrumbProps[]
  left?: ReactNode
  children?: ReactNode
}

export const TopBar: FC<TopBarProps> = ({ breadcrumbs, left, children }) => {
  return (
    <Navbar className={styles.topBar}>
      <NavbarGroup>
        {breadcrumbs && <BreadcrumbsNavigation items={breadcrumbs} overflowListProps={{ style: { marginRight: '10px' } }} />}
        {left}
      </NavbarGroup>
      <NavbarGroup className={styles.functionButtons}>{children}</NavbarGroup>
    </Navbar>
  )
}
