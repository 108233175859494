import React from 'react'
import style from './style.module.scss'
import IndustryTag from '../IndustryTag'

interface Props {
  data?: any
  className?: string
  industryTag?: boolean
}

const GridItem = (props: Props) => {
  const { data, className, industryTag = false } = props
  return data ? (
    <div className={`${className}`}>{industryTag ? data.map((industry: string) => <IndustryTag key={industry} title={industry} />) : data}</div>
  ) : (
    <div className={`${className}`}></div>
  )
}

export default GridItem
