import React, { FC, useEffect, useState } from 'react'
import Grid from './Grid'
import {
  createSavedFilter,
  deleteSavedFilter,
  listSavedFilters,
  nestedIndustries,
  newInvestmentProfile,
  SavedFilterData,
  updateSavedFilter,
} from '../../../logic/Backend'
import { TopBar } from '../../components/TopBar'
import TopBarContents from './TopBarContents'
import { Cache, Set } from '../Standard'
import SavedFiltersPanel from '../../../stories/Filtering/SavedFiltersPanel'
import { SessionStorage } from '../../../logic/ClientSideStorage'
import { showErrorToast } from '../../../logic/Toaster'
import { IpOnboardingDialog } from '../../../stories/Onboarding/IpOnboardingDialog'
import { useDispatch } from 'react-redux'
import useNestedIndustries from '../../../resources/hooks/useNestedIndustries'
import Chatbox from '../../components/Chatbox'

export const Companies: FC<{ cache?: Cache; setCache: Set<Cache | undefined> }> = (props) => {
  const [sessionStorage, setSessionStorage] = useState(new SessionStorage('Companies/Grid'))
  const [savedFilters$, setSavedFilters$] = useState<SavedFilterData[]>()
  const [rawNestedIndustries, setRawNestedIndustries] = useState<any>()
  const dispatch = useDispatch()

  async function loadSavedFilters() {
    try {
      setSavedFilters$(await listSavedFilters())
    } catch (error) {
      showErrorToast('Failed to get saved filters')
    }
  }
  async function loadNestedIndustries() {
    setRawNestedIndustries(await nestedIndustries())
  }
  useEffect(() => {
    loadSavedFilters()
    loadNestedIndustries()
  }, [])

  const reducedNestedIndustries = rawNestedIndustries ? useNestedIndustries(rawNestedIndustries) : []
  useEffect(() => {
    if (reducedNestedIndustries.length > 0) {
      dispatch({ type: 'industryState/updateIndustryState', payload: reducedNestedIndustries })
      dispatch({ type: 'industryState/updateUnparsedIndustryState', payload: rawNestedIndustries })
    }
  }, [rawNestedIndustries])
  return (
    <>
      <TopBar
        left={
          savedFilters$ && (
            <SavedFiltersPanel
              savedFilters={savedFilters$}
              activeSavedFilterId={sessionStorage.loadActiveSavedFilterId()}
              backendCreateSavedFilter={createSavedFilter}
              backendUpdateSavedFilter={updateSavedFilter}
              backendDeleteSavedFilter={deleteSavedFilter}
              gridApi={props.cache?.api}
              sessionStorage={sessionStorage}
            />
          )
        }
      >
        <TopBarContents cache={props.cache} />
      </TopBar>
      <Chatbox />
      {savedFilters$ && <Grid cache={props.cache} setCache={props.setCache} />}
      {props.cache?.currentUser?.onboarding_progress === 'first-time' && <IpOnboardingDialog backendNewInvestmentProfile={newInvestmentProfile} />}
    </>
  )
}
