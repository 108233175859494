import styles from '../app/App.module.scss'
import logo from '../../resources/images/logo.png'
import logoText from '../../resources/images/logo_text.png'
import React, { FC, ReactNode } from 'react'

export interface PlainPageTemplateProps {
  children: ReactNode
}

export const PlainPageTemplate: FC<PlainPageTemplateProps> = ({ children }) => {
  return (
    <div className={styles.login}>
      <div>
        <img style={{ marginBottom: '20px' }} src={logo} alt="Logo" width={60} height={60} />
        <img style={{ marginBottom: '50px' }} src={logoText} alt="Logo" width={154} height={21} />
        {children}
      </div>
    </div>
  )
}
