import { Button, Classes, Dialog, Menu, MenuDivider, MenuItem, Popover, Spinner } from '@blueprintjs/core'
import InvestmentProfileForm from './InvestmentProfileForm'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import * as Backend from '../../logic/Backend'
import { InvestmentProfileData } from '../../logic/Backend'
import { showErrorToast } from '../../logic/Toaster'
import { Cache } from '../../ui/pages/Standard'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { t } from 'i18next'
import { useDispatch } from 'react-redux'

export interface InvestmentProfilePickerProps {
  initialProfiles?: InvestmentProfileData[]
  cache?: Cache
}

const InvestmentProfilePicker: FC<WithNamespaces & InvestmentProfilePickerProps> = ({ initialProfiles, cache }) => {
  const [profiles, setProfiles] = useState(initialProfiles)
  const isFirstIp = cache?.currentUser?.onboarding_progress == 'first-ip'
  const [isOnboardingPopoverOpen, setIsOnboardingPopoverOpen] = useState(isFirstIp)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
  const [toEdit, setToEdit] = useState<InvestmentProfileData>()
  const dispatch = useDispatch()

  useEffect(() => {
    setProfiles(initialProfiles)
  }, [initialProfiles])

  useEffect(() => {
    setIsOnboardingPopoverOpen(isFirstIp)
  }, [isFirstIp])

  if (profiles === undefined) {
    return <Button loading={true} text={t('Loading investment profiles')} />
  }

  function investmentProfileFormSubmit(success: boolean) {
    if (success) {
      setIsDialogOpen(false)
      setIsConfirmationDialogOpen(true)
    } else {
      showErrorToast(t('Failed to create investment profile'))
    }
  }

  function createInvestmentProfile() {
    setToEdit(undefined)
    setIsDialogOpen(!isDialogOpen)
  }

  function editInvestmentProfile(profile: InvestmentProfileData, event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    setToEdit(profile)
    setIsDialogOpen(!isDialogOpen)
  }

  async function setActive(profile: InvestmentProfileData) {
    try {
      await Backend.setActiveInvestmentProfile({ id: profile.id })
      location.reload()
    } catch {
      showErrorToast(t('Failed to set active investment profile'))
    }
  }

  async function deleteInvestmentProfile(profile: InvestmentProfileData, event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    try {
      await Backend.deleteInvestmentProfile(profile.id)
      location.reload()
    } catch {
      showErrorToast(t('Failed to delete investment profile'))
    }
  }

  function IpDialog(): ReactElement {
    return (
      <>
        <Dialog
          style={{ width: '710px' }}
          icon={'projects'}
          title={'Create New Profile'}
          isOpen={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false)
            dispatch({ type: `selectedNestedIndustryState/clearIndustries` })
          }}
          className={'ip-wizard'}
        >
          <InvestmentProfileForm onSubmit={investmentProfileFormSubmit} onCancel={() => setIsDialogOpen(false)} toEdit={toEdit} />
        </Dialog>
        <Dialog style={{ width: '400px' }} isOpen={isConfirmationDialogOpen} onClose={() => location.reload()}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px 20px 0 20px' }}>
            {t('Investment profile successfully', { action: toEdit ? t('edited') : t('created') })}!{' '}
            {t('You can continue using Kurrant AI as ratings are being generated. Kurrant AI ratings will appear automatically once they’re ready.')}
            <Button type={'button'} style={{ marginLeft: 'auto' }} fill={false} text={t('Okay')} intent={'primary'} onClick={() => location.reload()} />
          </div>
        </Dialog>
      </>
    )
  }

  if (profiles.length === 0) {
    return (
      <>
        <Button onClick={() => createInvestmentProfile()} text={t('Create New Profile')} icon={'add'} intent={'primary'} />
        {IpDialog()}
      </>
    )
  }

  const activeProfile = profiles.find((p) => p.is_active)

  const picker = (
    <>
      <Popover isOpen={isPopoverOpen} hasBackdrop={true} onInteraction={(open) => setIsPopoverOpen(open)}>
        <Button
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          rightIcon={'caret-down'}
          intent={'primary'}
          text={
            activeProfile ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SmallSpinner investmentProfile={activeProfile} main={true} />
                {activeProfile.name}
              </div>
            ) : (
              t('Select Profile')
            )
          }
        />
        <Menu>
          {profiles.map((p, i) => (
            <MenuItem
              key={i}
              popoverProps={{}}
              onClick={() => setActive(p)}
              text={
                <div style={{ display: 'flex', gap: '5px', alignItems: 'center', fontWeight: p.is_active ? 'bold' : 'normal' }}>
                  {p.name}
                  <SmallSpinner investmentProfile={p} />
                  <Button
                    disabled={p.status !== 'predicted'}
                    small={true}
                    icon={'edit'}
                    style={{ marginLeft: 'auto' }}
                    onClick={(e: React.MouseEvent<HTMLElement>) => editInvestmentProfile(p, e)}
                  />
                  <Button small={true} icon={'trash'} onClick={(e: React.MouseEvent<HTMLElement>) => deleteInvestmentProfile(p, e)} />
                </div>
              }
            />
          ))}
          <MenuDivider />
          <MenuItem shouldDismissPopover={false} icon={'add'} text={t('Create New Profile')} onClick={() => createInvestmentProfile()} />
        </Menu>
      </Popover>
      {IpDialog()}
    </>
  )

  if (isOnboardingPopoverOpen) {
    return (
      <Popover
        isOpen={isOnboardingPopoverOpen}
        hasBackdrop={false}
        onInteraction={async (open) => {
          setIsOnboardingPopoverOpen(open)
          if (!open) {
            await Backend.onboardingProgress({ onboarding_progress: 'first-ip-done' })
          }
        }}
        target={picker}
        popoverClassName={'bp3-popover-primary'}
        content={<OnboardingPopover />}
      />
    )
  }

  return picker
}

const OnboardingPopover: FC = () => {
  return (
    <div style={{ width: '307px', display: 'flex', flexDirection: 'column', padding: '32px 16px !important', gap: '8px' }}>
      <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{t('First Profile Created')}</div>
      <div>
        {t(
          'You can edit your investment profile here. You can also create as many investment profiles as you like, but be sure to select at least one criteria when creating an investment profile.'
        )}
      </div>
      <Button text={t('Okay')} style={{ marginLeft: 'auto' }} className={Classes.POPOVER_DISMISS} />
    </div>
  )
}

const SmallSpinner: FC<{ investmentProfile: InvestmentProfileData; main?: boolean }> = ({ investmentProfile, main }) => {
  return investmentProfile.status !== 'predicted' ? (
    <div style={{ marginRight: '5px', marginLeft: main ? '0' : '5px', display: 'flex' }}>
      <Spinner size={15} intent={main ? 'primary' : 'none'} />
    </div>
  ) : (
    <></>
  )
}

export default withNamespaces()(InvestmentProfilePicker)
