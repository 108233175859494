import React, { FC, useEffect, useRef } from 'react'
import { Chart } from 'react-chartjs-2'
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJs,
  ChartDataset,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import { internationalUnits } from '../logic/ValueFormatters'
import styles from './Latest6MonthsChart.module.scss'
import palette from '../_palette.module.scss'

import noDataImage from '../resources/images/no-data.png'
import { classesList } from '@ag-grid-community/react/lib/reactUi/utils'
import { CompanyDataPoint } from '../ui/pages/Company/CompanyDataPoint'

ChartJs.register(CategoryScale, LinearScale, BarElement, BarController, Title, Tooltip, Legend, PointElement, LineElement, LineController)

export interface Latest6MonthsChartProps {
  label: string
  latestValue?: number
  momValue?: string
  values?: (number | [number, string] | null)[]
  unit?: string
}

export const Latest6MonthsChart: FC<Latest6MonthsChartProps> = ({ label, latestValue, momValue, values, unit = '' }) => {
  const valuesWithoutInterpolatedArrays: (number | null)[] | undefined = values?.map((v) => (Array.isArray(v) ? v[0] : v))
  const growths = valuesWithoutInterpolatedArrays?.map((v, i) => {
    if (i === 0 || v == null) {
      return null
    }
    const previousMonth = valuesWithoutInterpolatedArrays[i - 1]
    if (previousMonth == null) {
      return null
    }
    return changePercentage(v, previousMonth)
  })
  const momValuePercentage = momValue != undefined ? Number((Number(momValue) * 100).toFixed(1)) : undefined

  return (
    <div style={{ width: '100%' }}>
      <CompanyDataPoint label={label} style={{ paddingBottom: '16px' }}>
        <div className={styles.latestStats}>
          <div>{internationalUnits(latestValue, 1, unit, 'n/a')}</div>
          {momValuePercentage != undefined && (
            <div className={classesList(styles.latestGrowth, momValuePercentage > 0 ? styles.positive : styles.negative)}>
              {percentageWithSign(momValuePercentage)}
            </div>
          )}
        </div>
      </CompanyDataPoint>
      {valuesWithoutInterpolatedArrays && valuesWithoutInterpolatedArrays.some((v) => v != null) ? (
        <ComboChart legend={label} values={values!} valuesWithoutInterpolatedArrays={valuesWithoutInterpolatedArrays} growths={growths!} unit={unit} />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
          <img src={noDataImage} alt="No data" width={100} height={87} />
          <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>Graph is unavailable at this time.</span>
        </div>
      )}
    </div>
  )
}

const ComboChart: FC<{
  legend: string
  values: (number | [number, string] | null)[]
  valuesWithoutInterpolatedArrays: (number | null)[]
  growths: (number | null)[]
  unit?: string
}> = ({ legend, values, valuesWithoutInterpolatedArrays, growths, unit }) => {
  function latest6MonthsLabels() {
    const labels = []
    const date = new Date()
    date.setDate(1)
    date.setMonth(date.getMonth() - 5)
    for (let i = 0; i < 6; i++) {
      labels.push(date.toLocaleDateString(navigator.language, { month: '2-digit', year: '2-digit' }))
      date.setMonth(date.getMonth() + 1)
    }
    return labels
  }

  const chartRef = useRef<ChartJs>(null)

  useEffect(() => {
    const chart = chartRef.current
    if (chart) {
      const backgroundColor = chart.ctx.createLinearGradient(0, chart.chartArea.bottom, 0, chart.chartArea.top)
      backgroundColor.addColorStop(0, palette.blue3)
      backgroundColor.addColorStop(1, '#4D52DE')
      const backgroundColorInterpolated = chart.ctx.createLinearGradient(0, chart.chartArea.bottom, 0, chart.chartArea.top)
      backgroundColorInterpolated.addColorStop(0, `${palette.blue3}B5`)
      backgroundColorInterpolated.addColorStop(1, '#4D52DEB5')
      chart.data.datasets.find((d) => d.type === 'bar')!.backgroundColor = values.map((v) => (Array.isArray(v) ? backgroundColorInterpolated : backgroundColor))
      const datasetLine = chart.data.datasets.find((d) => d.type === 'line')!
      ;(datasetLine as ChartDataset<'line'>).pointBackgroundColor = values.map((v) => (Array.isArray(v) ? '#ffffffB5' : '#ffffff'))
      ;(datasetLine as ChartDataset<'line'>).pointBorderColor = values.map((v) => (Array.isArray(v) ? '#0694CCB5' : '#0694CC'))
      datasetLine.borderColor = values.map((v) => (Array.isArray(v) ? '#0694CCB5' : '#0694CC'))
      chart.update()
    }
  }, [chartRef])

  return (
    <div style={{ position: 'relative', padding: '0 20px' }}>
      <Chart
        height={170}
        type={'bar'}
        ref={chartRef}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom',
              align: 'start',
              reverse: true,
              labels: {
                pointStyle: 'rectRounded',
                usePointStyle: true,
              },
            },
            tooltip: {
              itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
              callbacks: {
                label: (tooltipItem) => {
                  if (tooltipItem.dataset.label === 'Monthly Growth') {
                    return tooltipItem.dataset.label + ': ' + percentageWithSign(tooltipItem.parsed.y)
                  }
                  return tooltipItem.dataset.label + ': ' + internationalUnits(tooltipItem.parsed.y, 1, unit, 'n/a')
                },
              },
            },
          },
          interaction: {
            intersect: false,
            mode: 'index',
          },
          scales: {
            x: {
              grid: {
                display: false,
                drawBorder: false,
              },
            },
            yLeft: {
              position: 'left',
              display: 'auto',
              grid: {
                drawBorder: false,
                color: '#e5eaf2',
              },
              ticks: {
                callback: (value) => internationalUnits(value, 1, unit, 'n/a'),
              },
            },
            yRight: {
              position: 'right',
              display: 'auto',
              grid: {
                display: false,
                drawBorder: false,
                color: '#e5eaf2',
              },
              ticks: {
                callback: (value) => percentageWithSign(value),
              },
            },
          },
        }}
        data={{
          labels: latest6MonthsLabels(),
          datasets: [
            {
              label: 'Monthly Growth',
              type: 'line',
              data: growths,
              yAxisID: 'yRight',
              pointRadius: 4,
              pointBorderWidth: 1,
              borderWidth: 1.5,
              tension: 0.4,
              // spanGaps: true,
            },
            {
              label: legend,
              type: 'bar',
              data: valuesWithoutInterpolatedArrays,
              yAxisID: 'yLeft',
              borderRadius: 3,
              maxBarThickness: 24,
            },
          ],
        }}
      />
    </div>
  )
}

function changePercentage(newValue: number, oldValue: number) {
  return Math.round((newValue / oldValue - 1) * 100)
}

function percentageWithSign(percentage: number | string): string {
  if (typeof percentage === 'string') {
    percentage = Number(percentage)
  }
  return `${percentage > 0 ? '+' : ''}${percentage} % `
}
