import { createModel } from '@rematch/core'
import { RootModel } from '.'

const initialState = {}
const commpanyNameColumnSizeState = createModel<RootModel>()({
  state: initialState,
  reducers: {
    updateColumnSizeState(state, payload) {
      return payload
    },
  },
})

export default commpanyNameColumnSizeState
