export const STATES_MAP: Record<string, string> = {
  AA: 'Armed Forces Americas',
  AB: 'Alberta',
  AE: 'Armed Forces Africa/Canada/Europe/Middle East',
  AK: 'Alaska ',
  AL: 'Alabama',
  AP: 'Armed Forces Pacific ',
  AR: 'Arkansas ',
  AS: 'American Samoa ',
  AZ: 'Arizona',
  BC: 'British Columbia',
  CA: 'California ',
  CO: 'Colorado ',
  CT: 'Connecticut',
  DC: 'District of Columbia ',
  DE: 'Delaware ',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam ',
  HI: 'Hawaii ',
  IA: 'Iowa ',
  ID: 'Idaho',
  IL: 'Illinois ',
  IN: 'Indiana',
  KS: 'Kansas ',
  KY: 'Kentucky ',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MB: 'Manitoba',
  MD: 'Maryland ',
  ME: 'Maine',
  MH: 'Marshall Islands ',
  MI: 'Michigan ',
  MN: 'Minnesota',
  MO: 'Missouri ',
  MP: 'Northern Mariana Island',
  MS: 'Mississippi',
  MT: 'Montana',
  NB: 'New Brunswick',
  NC: 'North Carolina ',
  ND: 'North Dakota ',
  NE: 'Nebraska ',
  NH: 'New Hampshire',
  NJ: 'New Jersey ',
  NL: 'Newfoundland and Labrador',
  NM: 'New Mexico ',
  NS: 'Nova Scotia',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
  NV: 'Nevada ',
  NY: 'New York ',
  OH: 'Ohio ',
  OK: 'Oklahoma ',
  ON: 'Ontario',
  OR: 'Oregon ',
  PA: 'Pennsylvania ',
  PE: 'Prince Edward Island',
  PR: 'Puerto Rico',
  QC: 'Quebec',
  RI: 'Rhode Island ',
  SC: 'South Carolina ',
  SD: 'South Dakota ',
  SK: 'Saskatchewan',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah ',
  VA: 'Virginia ',
  VI: 'Virgin Islands ',
  VT: 'Vermont',
  WA: 'Washington ',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming',
  YT: 'Yukon',
}

export const STATES = Object.keys(STATES_MAP)
