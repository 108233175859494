export const CITIES = [
  "'s-graveland",
  "'s-gravendeel",
  "'s-gravenhage",
  "'s-gravenmoer",
  "'s-gravenpolder",
  "'s-gravenzande",
  "'s-heerenberg",
  "'s-hertogenbosch",
  '1 Decembrie',
  '10th Of Ramadan City',
  '6 October City',
  'A Coruña',
  'Aabenraa',
  'Aabybro',
  'Aabyhöj',
  'Aach',
  'Aachen',
  'Aadorf',
  'Aagtekerke',
  'Aalborg',
  'Aalen',
  'Aalesund',
  'Aalsmeer',
  'Aalst',
  'Aalten',
  'Aalter',
  'Aarau',
  'Aarberg',
  'Aarburg',
  'Aardenburg',
  'Aarhus',
  'Aarle',
  'Aars',
  'Aarschot',
  'Aarslev',
  'Aartselaar',
  'Aarup',
  'Aarwangen',
  'Aas',
  'Aathal',
  'Aba',
  'Abadia',
  'Abadiano',
  'Abadín',
  'Abaetetuba',
  'Abakaliki',
  'Abakan',
  'Abano Terme',
  'Abanto',
  'Abashiri',
  'Abba Hillel',
  'Abbenrode',
  'Abbeville',
  'Abborrträsk',
  'Abbots Langley',
  'Abbotsbury',
  'Abbotsford',
  'Abbott',
  'Abbottabad',
  'Abbottstown',
  'Abcoude',
  'Abdul Hakim',
  'Abee',
  'Abegondo',
  'Abenberg',
  'Abensberg',
  'Abeokuta',
  'Aberaman',
  'Aberbargoed',
  'Abercynon',
  'Aberdare',
  'Aberdeen',
  'Aberdeen Proving Ground',
  'Aberdour',
  'Aberfeldy',
  'Aberford',
  'Abergavenny',
  'Abergele',
  'Aberlour',
  'Abernathy',
  'Abertillery',
  'Aberystwyth',
  'Abha',
  'Abhanpur',
  'Abia',
  'Abidjan',
  'Abiko',
  'Abilene',
  'Abingdon',
  'Abington',
  'Abisko',
  'Abita Springs',
  'Abohar',
  'Abomey-calavi',
  'Abondant',
  'Aboshi',
  'Aboyne',
  'Abraka',
  'Abram',
  'Abrantes',
  'Abraveses',
  'Abrera',
  'Abrigada',
  'Absam',
  'Absecon',
  'Abstatt',
  'Abtwil',
  'Abu Dabi',
  'Abu Dhabi',
  'Abu Ghosh',
  'Abu Hamur',
  'Abu Hayl',
  'Abu `alanda',
  'Abuja',
  'Abule Egba',
  'Abuta',
  'Abyei',
  'Abzac',
  'Acacia Ridge',
  'Acampo',
  'Acapulco',
  'Acapulco De Juárez',
  'Acaraú',
  'Acarigua',
  'Acaster Malbis',
  'Accho',
  'Accident',
  'Accokeek',
  'Accord',
  'Accra',
  'Accrington',
  'Acebo',
  'Acerra',
  'Acharacle',
  'Acharnae',
  'Acharne',
  'Achberg',
  'Achern',
  'Achères',
  'Achim',
  'Achimota',
  'Achrafieh',
  'Achsheim',
  'Achstetten',
  'Achtmaal',
  'Aci Castello',
  "Aci Sant'antonio",
  'Acigné',
  'Acireale',
  'Acklam',
  'Acle',
  'Aclou',
  'Acme',
  'Acorn',
  'Acoz',
  'Acquafredda',
  'Acquarossa',
  'Acquaviva',
  'Acqui Terme',
  'Acra',
  'Acre',
  'Acri',
  'Acton',
  'Acworth',
  'Açores',
  'Ad Dammam',
  'Ad Dawha',
  'Ad Dawhah',
  'Ad Diwaniyah',
  'Ad Doha',
  'Ad Duqqi',
  'Ada',
  'Adairsville',
  'Adama',
  'Adamov',
  'Adams',
  'Adams Center',
  'Adamstown',
  'Adamsville',
  'Adana',
  'Adapazari',
  'Adazi',
  'Adderbury',
  'Adderet',
  'Addingham',
  'Addis Ababa',
  'Addison',
  'Addlestone',
  'Adegem',
  'Adeje',
  'Adel',
  'Adelaide',
  'Adelanto',
  'Adelberg',
  'Adelboden',
  'Adelebsen',
  'Adelheidsdorf',
  'Adelphia',
  'Adelsdorf',
  'Adelsheim',
  'Adelshofen',
  'Aden',
  'Adenau',
  'Adendorf',
  'Adé',
  'Adirondack',
  'Adlershof',
  'Adligenswil',
  'Adlington',
  'Adliswil',
  'Adlkofen',
  'Adma',
  'Admannshagen-bargeshagen',
  'Admington',
  'Admiralty',
  'Ado-ekiti',
  'Adra',
  'Adrano',
  'Adria',
  'Adrian',
  'Adro',
  'Aduna',
  'Advance',
  'Adyar',
  'Adygei',
  'Adygeysk',
  'Adzaneta De Albaida',
  'Aegaleo',
  'Aerdenhout',
  'Aerzen',
  'Aesch',
  'Aeschi',
  'Afa',
  'Affalterbach',
  'Affeltrangen',
  'Affi',
  'Affing',
  'Affoltern',
  'Affoltern Am Albis',
  'Affula',
  'Afikim',
  'Afton',
  'Afumati',
  'Afyonkarahisar',
  'Agadir',
  'Agano',
  'Agartala',
  'Agathenburg',
  'Agawam',
  'Agárd',
  'Agbani',
  'Agbara',
  'Agbole',
  'Agdal',
  'Agde',
  'Agen',
  'Ageo',
  'Agerskov',
  'Aghaglinny North',
  'Agidingbi',
  'Agios Athanasios',
  'Agía Paraskeví',
  'Agía Varvára',
  'Aglangia',
  'Aglasterhausen',
  'Agneaux',
  'Agnesberg',
  'Agnetz',
  'Agno',
  'Agoncillo',
  'Agostinho',
  'Agoura Hills',
  'Agra',
  'Agramunt',
  'Agrate Brianza',
  'Agrigento',
  'Agris',
  'Agrínio',
  'Agropoli',
  'Agua Prieta',
  'Aguada De Cima',
  'Aguadulce',
  'Agualva',
  'Aguamilpa',
  'Aguas Lindas',
  'Aguascalientes',
  'Aguda',
  'Agudat Israel',
  'Aguilar De Campóo',
  'Agullent',
  'Agung',
  'Agüés',
  'Agüimes',
  'Aham',
  'Ahaus',
  'Ahihud',
  'Ahipara',
  'Ahja',
  'Ahlen',
  'Ahmadabad',
  'Ahmadi',
  'Ahmedabad',
  'Ahmednagar',
  'Ahome',
  'Ahorn',
  'Ahoskie',
  'Ahrensboek',
  'Ahrensbök',
  'Ahrensburg',
  'Ahrensfelde',
  'Ahrenshöft',
  'Ahrenviöl',
  'Ahrweiler',
  'Ahuahuastepec',
  'Ahun',
  'Ahuriri',
  'Ahuy',
  'Ahvaz',
  'Ahwahnee',
  'Aich',
  'Aichach',
  'Aichhalden',
  'Aichstetten',
  'Aidenbach',
  'Aidlingen',
  'Aiea',
  'Aiffres',
  'Aigáleo',
  'Aigle',
  'Aigrefeuille',
  'Aigrefeuille-sur-maine',
  'Aiguafreda',
  'Aiguaviva',
  'Aigueperse',
  'Aigues-mortes',
  'Aigues-vives',
  'Aiken',
  'Aimargues',
  'Ainazi',
  'Aincourt',
  'Ainring',
  'Ainsdale',
  'Ainsworth',
  'Aintree',
  'Aioi',
  'Airaines',
  'Airdrie',
  'Aire-sur-la-lys',
  'Aires',
  'Airlie Beach',
  'Airway Heights',
  'Aisch',
  'Aiseau',
  'Aisey-sur-seine',
  'Aitkenvale',
  'Aitkin',
  'Aitrach',
  'Aix',
  'Aix-en-othe',
  'Aix-en-provence',
  'Aix-les-bains',
  'Aizawl',
  'Aizkraukle',
  'Aizoáin',
  'Aizpute',
  'Aizu',
  'Aizu-wakamatsu',
  'Ajaccio',
  'Ajalvir',
  'Ajax',
  'Ajdovscina',
  'Ajka',
  'Ajmer',
  'Ajo',
  'Ajurias',
  'Akaa',
  'Akabane',
  'Akademgorodok',
  'Akasaka',
  'Akashi',
  'Akashicho',
  'Akatsuka',
  'Akbou',
  'Akeno',
  'Akersloot',
  'Akharnaí',
  'Akhisar',
  'Akiruno',
  'Akishima',
  'Akita',
  'Akita-shi',
  'Akker',
  'Akland',
  'Akola',
  'Akot',
  'Akouda',
  'Akowonjo',
  'Akranes',
  'Akron',
  'Aksaray',
  'Aksoy',
  'Aksu',
  'Aktau',
  'Akumal',
  'Akure',
  'Akureyri',
  'Akyurt',
  'Al Ahmadi',
  'Al Ain',
  'Al Ajman',
  'Al Barahah',
  'Al Basrah',
  'Al Hadath',
  "Al Hamra'",
  'Al Haram',
  'Al Jadida',
  'Al Jizah',
  'Al Khan',
  'Al Khari',
  'Al Khobar',
  'Al Khubar',
  'Al Kuwait',
  'Al Kuwayt',
  'Al Ma`sarah Al Mahattah',
  'Al Madinah',
  'Al Malaz',
  'Al Manama',
  'Al Manamah',
  'Al Mubarraz',
  'Al Qahirah',
  'Al Rawdah',
  'Al Rayyan',
  'Al Satwa',
  'Al Sharjah',
  'Al-jubail',
  'Al-salt',
  'Ala',
  'Alaba',
  'Alabang',
  'Alabaster',
  'Alachua',
  'Alacuás',
  'Alagbado',
  'Alagoas',
  'Alajärvi',
  'Alajuela',
  'Alameda',
  'Alamo',
  'Alamogordo',
  'Alamosa',
  'Alandur',
  'Alapaha',
  'Alappuzha',
  'Alarcón',
  'Alasehir',
  'Alaska Peninsula Nwr',
  'Alata',
  'Alavus',
  'Alayor',
  'Alás',
  'Alba',
  'Alba Iulia',
  'Albacete',
  'Albaida',
  'Albal',
  'Albalate De Cinca',
  'Albalate Del Arzobispo',
  'Alban',
  'Albania',
  'Albano Laziale',
  "Albano Sant'alessandro",
  'Albany',
  'Albany Creek',
  'Albas',
  'Albatarrech',
  'Albbruck',
  'Albelda De Iregua',
  'Albemarle',
  'Albenga',
  'Albens',
  'Albergaria-a-velha',
  'Albergen',
  'Albergo',
  'Alberndorf In Der Riedmark',
  'Alberobello',
  'Albershausen',
  'Alberswil',
  'Albert',
  'Albert City',
  'Albert Lea',
  'Albert Park',
  'Albert Town',
  'Alberta',
  'Albertirsa',
  'Alberton',
  'Albertslund',
  'Albertson',
  'Albertville',
  'Albi',
  'Albia',
  "Albiano D'ivrea'",
  'Albignasego',
  'Albine',
  'Albinea',
  'Albino',
  'Albion',
  'Albion Park',
  'Albizzate',
  'Alblasserdam',
  'Albolote',
  'Albon',
  'Alboraya',
  'Albox',
  'Albrechtice',
  'Albrighton',
  'Albrightsville',
  'Albrook',
  'Albstadt',
  'Albudeite',
  'Albufeira',
  'Albuixech',
  'Albuquerque',
  'Alburquerque',
  'Alburtis',
  'Albury',
  'Albury Municipality',
  'Albuzzano',
  'Alby-sur-chéran',
  'Alcabideche',
  'Alcalá De Guadaira',
  'Alcalá De Henares',
  'Alcalá La Real',
  'Alcamo',
  'Alcantarilla',
  'Alcañiz',
  'Alcarras',
  'Alcaudete',
  'Alcácer',
  'Alcázar De San Juan',
  'Alcester',
  'Alcira',
  'Alcoa',
  'Alcobaça',
  'Alcobendas',
  'Alcocebre',
  'Alcochete',
  'Alcora',
  'Alcorcón',
  'Alcorta',
  'Alcoy',
  'Alcudia',
  'Alcudia De Crespins',
  'Alda',
  'Aldaca',
  'Aldama',
  'Aldaya',
  'Aldbourne',
  'Aldeamayor De San Martín',
  'Aldeburgh',
  'Aldeia De Juso',
  'Aldeia De Paio Pires',
  'Aldekerk',
  'Alden',
  'Aldenhoven',
  'Alderbury',
  'Aldergrove',
  'Alderley',
  'Alderley Edge',
  'Alderley Park',
  'Aldermaston',
  'Aldershot',
  'Alderson',
  'Aldie',
  'Aldinga',
  'Aldingen',
  'Aldington',
  'Aldridge',
  'Aledo',
  'Alegre',
  'Alegrete',
  'Alegría',
  'Aleksandrow Lodzki',
  'Aleksandrów Kujawski',
  'Aleksinac',
  'Alella',
  'Alençon',
  'Aleppo',
  'Alessandria',
  'Alessano',
  'Alexander',
  'Alexander City',
  'Alexandra',
  'Alexandria',
  'Alexandria Bay',
  'Alexandroúpolis',
  'Alexandru Cel Bun',
  'Alexandru Vlahuta',
  'Alexándreia',
  'Alès',
  'Aléria',
  'Alfafar',
  'Alfajarín',
  'Alfara Del Patriarca',
  'Alfaro',
  'Alfdorf',
  'Alfeld',
  'Alfena',
  'Alfenas',
  'Alfonso',
  'Alford',
  'Alfortville',
  'Alfragide',
  'Alfred',
  'Alfred Station',
  'Alfredton',
  'Alfreton',
  'Alfta',
  'Algeciras',
  'Algemesí',
  'Alger',
  'Algeri',
  'Algerri',
  'Algete',
  'Algés',
  'Alghero',
  'Algier',
  'Algiers',
  'Alginet',
  'Algoma',
  'Algona',
  'Algonac',
  'Algonquin',
  'Algorta',
  'Alguazas',
  'Algyo',
  'Alhabia',
  'Alhama',
  'Alhama De Murcia',
  'Alhambra',
  'Alhaurín De La Torre',
  'Alhaurín El Grande',
  'Alheim',
  'Alhendín',
  'Alhos Vedros',
  'Aliaga',
  'Alicante',
  'Alice',
  'Alice Springs',
  'Aliceville',
  'Alicia',
  'Aliganj',
  'Aligarh',
  'Alimosho',
  'Alingsås',
  'Alió',
  'Alipore',
  'Alipur',
  'Aliquippa',
  'Aliso Viejo',
  'Alixan',
  'Alizay',
  'Aljaraque',
  'Aljezur',
  'Alken',
  'Alkmaar',
  'Allahabad',
  'Allaire',
  'Allaman',
  'Allan',
  'Allariz',
  'Allas-champagne',
  'Allassac',
  'Allauch',
  'Alle',
  'Allegan',
  'Allegany',
  'Alleghany',
  'Allen',
  'Allen Park',
  'Allendale',
  'Allendorf',
  'Allendorf-eder',
  'Allenhurst',
  'Allenjoie',
  'Allensbach',
  'Allenton',
  'Allentown',
  'Allenwood',
  'Alleppey',
  'Allersberg',
  'Allershausen',
  'Allerton',
  'Allerød',
  'Allesley',
  'Alleur',
  'Alleyton',
  'Allhartsberg',
  'Alliance',
  'Allier',
  'Alliku',
  'Alling',
  'Allingåbro',
  'Allinge',
  'Allison',
  'Allison Park',
  'Alliston',
  'Allmendingen',
  'Alloa',
  'Allonne',
  'Allonzier-la-caille',
  'Alloway',
  'Allschwil',
  'Allston',
  'Allyn',
  'Alma',
  'Almada',
  'Almadén',
  'Almancil',
  'Almansa',
  'Almanzora',
  'Almaraz',
  'Almargem Do Bispo',
  'Almaty',
  'Almaza',
  'Almazán',
  'Almazora',
  'Almeirim',
  'Almelo',
  'Almeloo',
  'Almenara',
  'Almendralejo',
  'Almensilla',
  'Almere',
  'Almere-haven',
  'Almería',
  'Almese',
  'Almetyevsk',
  'Almkerk',
  'Almoharín',
  'Almoines',
  'Almondsbury',
  'Almont',
  'Almora',
  'Almoradí',
  'Almusafes',
  'Alnarp',
  'Alne',
  'Alness',
  'Alnö',
  'Alnwick',
  'Alonsótegui',
  'Alor Gajah',
  'Alor Setar',
  'Alora',
  'Alpe Grasso',
  'Alpedrete',
  'Alpen',
  'Alpena',
  'Alpha',
  'Alpharetta',
  'Alphen',
  'Alphen Aan De Rijn',
  'Alphen Aan Den Rijn',
  'Alphington',
  'Alpicat',
  'Alpine',
  'Alpirsbach',
  'Alpnach',
  'Alpnachstad',
  'Alresford',
  'Alsager',
  'Alsancak',
  'Alsasua',
  'Alsbach',
  'Alsbach-hähnlein',
  'Alsdorf',
  'Alsfeld',
  'Alsip',
  'Alsónémedi',
  'Alsópáhok',
  'Alston',
  'Alstonville',
  'Alsvåg',
  'Alt Mölln',
  'Alt Sankt Johann',
  'Alt Zeschdorf',
  'Alta',
  'Altach',
  'Altadena',
  'Altamira',
  'Altamont',
  'Altamonte Springs',
  'Altamura',
  'Altare',
  'Altavilla',
  'Altavilla Irpina',
  'Altavilla Vicentina',
  'Altbach',
  'Altdorf',
  'Altea La Vieja',
  'Altedo',
  'Alteglofsheim',
  'Altena',
  'Altenbeken',
  'Altenberg',
  'Altenberg Bei Linz',
  'Altenberge',
  'Altenburg',
  'Altendorf',
  'Altenhaßlau',
  'Altenholz',
  'Altenkunstadt',
  'Altenmarkt',
  'Altenmarkt Im Pongau',
  'Altenmedingen',
  'Altenmünster',
  'Altenrhein',
  'Altenstadt',
  'Altenstadt An Der Waldnaab',
  'Altensteig',
  'Alterswil',
  'Altes Lager',
  'Alteveer',
  'Altforst',
  'Altha',
  'Altham',
  'Altheim',
  'Althengstett',
  'Althofen',
  'Althorpe',
  'Altingen',
  'Altinova',
  'Altintop',
  'Altishofen',
  'Altkirch',
  'Altleiningen',
  'Altlengbach',
  'Altlußheim',
  'Altmannstein',
  'Altmar',
  'Altnau',
  'Alto',
  'Alto Paraíso De Goiás',
  'Altomünster',
  'Alton',
  'Alton Barnes',
  'Alton Bay',
  'Altona',
  'Altoona',
  'Altopascio',
  'Altötting',
  'Altrincham',
  'Altshausen',
  'Altstatten',
  'Altstätten',
  'Altura',
  'Altus',
  'Altusried',
  'Alum Bank',
  'Alum Creek',
  'Aluva',
  'Alva',
  'Alvarado',
  'Alvaro Obregón',
  'Alvdal',
  'Alvechurch',
  'Alvelos',
  'Alverca',
  'Alveringem',
  'Alveslohe',
  'Alvesta',
  'Alvin',
  'Alvinston',
  'Alviso',
  'Alvor',
  'Alvorada',
  'Alwar',
  'Alwar City',
  'Alwarpet',
  'Alwaye',
  'Alytus',
  'Alzano Lombardo',
  'Alzenau',
  'Alzey',
  'Alzingen',
  'Ama',
  'Amado',
  'Amador City',
  'Amadora',
  'Amagasaki',
  'Amailloux',
  'Amantea',
  'Amarante',
  'Amaravati',
  'Amares',
  'Amargosa Valley',
  'Amarillo',
  'Amaro',
  'Amaroúsion',
  'Amasya',
  'Amata',
  'Amatitlán',
  'Amay',
  'Amazon',
  'Ambacourt',
  'Ambad',
  'Ambala',
  'Ambarnath',
  'Ambato',
  'Ambattur',
  'Ambazac',
  'Ambejogai',
  'Amberg',
  'Amberley',
  'Ambert',
  'Ambès',
  'Ambérieu',
  'Ambérieu-en-bugey',
  'Ambikapur',
  'Ambler',
  'Amboise',
  'Ambon',
  'Ambonnay',
  'Amboy',
  'Ambridge',
  'Amchit',
  'Amden',
  'Ameca',
  'Ameglia',
  'Amelia',
  'Amenia',
  'Amer',
  'Amerang',
  'America',
  'American Canyon',
  'American Falls',
  'American Fork',
  'Americana',
  'Americus',
  'Amerongen',
  'Amersfoort',
  'Amersham',
  'Amery',
  'Ames',
  'Amesbury',
  'Ametlla',
  'América',
  'Amés',
  'Amherst',
  'Amherst Junction',
  'Amherstburg',
  'Amiens',
  'Amilly',
  'Amirim',
  'Amissville',
  'Amite',
  'Amityville',
  'Amlwch',
  'Amman',
  'Ammanford',
  'Ammerbach',
  'Ammern',
  'Ammerndorf',
  'Ammerzoden',
  'Ammikam',
  'Amminadav',
  'Ammon',
  'Amnéville',
  'Amora',
  'Amorbach',
  'Amorebieta',
  'Amorim',
  'Amory',
  'Amos',
  'Amos Quarries',
  'Amotherby',
  'Ampang',
  'Ampfing',
  'Amphoe Muang Chiang Mai',
  'Amposta',
  'Ampthill',
  'Ampuero',
  'Ampuis',
  'Amravati',
  'Amreli',
  'Amriswil',
  'Amritsar',
  'Amroha',
  'Amsteg',
  'Amstelveen',
  'Amsterdam',
  'Amsterdamsche Veld I',
  'Amstetten',
  'Amtzell',
  'Amurrio',
  'Amuwo',
  'An Najaf',
  'Anacapri',
  'Anaconda',
  'Anacortes',
  'Anagni',
  'Anaheim',
  'Anahola',
  'Anahuac',
  'Analomink',
  'Anambra',
  'Anamosa',
  'Anan',
  'Anand',
  'Anandpur Sahib',
  'Ananindeua',
  'Anantapur',
  'Anastácio',
  'Anápolis',
  'Ancaster',
  'Ancenis',
  'Anchal',
  'Anchor Point',
  'Anchorage',
  'Ancol',
  'Ancol Barat',
  'Ancona',
  'Ancourt',
  'Ancramdale',
  'Andale',
  'Andalo',
  'Andalucía',
  'Andalusia',
  'Andechs',
  'Andelfingen',
  'Andelst',
  'Andenes',
  'Andenne',
  'Anderlecht',
  'Anderlues',
  'Andermatt',
  'Andernach',
  'Andernos-les-bains',
  'Anderslöv',
  'Anderson',
  'Anderstorp',
  'Anderton',
  'Andes',
  'Andheri',
  'Andijk',
  'Andilly',
  'Andoain',
  'Andong',
  'Andorra',
  'Andorra La Vella',
  'Andover',
  'Andrespol',
  'Andretta',
  'Andrews',
  'Andrésy',
  'Andrézieux',
  'Andria',
  'Andrychow',
  'Andújar',
  'Andwil',
  'Ane',
  'Anekal',
  'Anet',
  'Angamali',
  'Angarsk',
  'Angaston',
  'Angel Fire',
  'Angeles',
  'Angeles City',
  'Angelniemi',
  'Angels Camp',
  'Anger',
  'Angera',
  'Angerberg',
  'Angered',
  'Angermünde',
  'Angers',
  'Anggrek',
  'Angier',
  'Anglade',
  'Anglesea',
  'Anglesola',
  'Anglet',
  'Angleton',
  'Angleur',
  'Anglès',
  'Anglés',
  'Angmering',
  'Angola',
  'Angoulême',
  'Angoulins-sur-mer',
  'Angra',
  'Angra Do Heroísmo',
  'Angri',
  'Anguillara Sabazia',
  'Angwin',
  'Anhausen',
  'Anhée',
  'Anhua',
  'Aniane',
  'Anières',
  'Anif',
  'Anitrella',
  'Anji',
  'Anjo',
  'Anjou',
  'Anju',
  'Anjuna',
  'Ankara',
  'Ankawa',
  'Ankeny',
  'Ankeveen',
  'Ankleshwar',
  'Ankola',
  'Ankum',
  'Ann Arbor',
  'Anna',
  'Anna Maria',
  'Anna Nagar West',
  'Annaba',
  'Annaberg-buchholz',
  'Annaka',
  'Annan',
  'Annandale',
  'Annandale-on-hudson',
  'Annaparochie',
  'Annapolis',
  'Annapolis Junction',
  'Annapolis Royal',
  'Annawan',
  'Annecy',
  'Annecy-le-vieux',
  'Annelöv',
  'Annemasse',
  'Annesley',
  'Annezin',
  'Annfield',
  'Annfield Plain',
  'Annicco',
  'Anniesland',
  'Anniston',
  'Annona',
  'Annonay',
  'Anntelias',
  'Annville',
  'Anoka',
  'Anoux',
  'Anqing',
  'Anqiu',
  'Ans',
  'Ansan',
  'Ansbach',
  'Anse',
  'Ansen',
  'Anseong',
  'Ansfelden',
  'Anshan',
  'Anshun',
  'Anson',
  'Ansonia',
  'Anstey',
  'Anstruther',
  'Antakya',
  'Antalya',
  'Antananarivo',
  'Antanhol',
  'Antapani',
  'Antas',
  'Antdorf',
  'Antelope',
  'Antequera',
  'Anteuil',
  'Anthem',
  'Anthony',
  'Anthy',
  'Antibes',
  'Antigo',
  'Antigonish',
  'Antigua',
  'Antigua Guatemala',
  'Antiguo Cuscatlán',
  'Anting',
  'Antioch',
  'Antioquia',
  'Antipolo',
  'Antofagasta',
  'Antoing',
  'Antony',
  'Antón',
  'Antônio Carlos',
  'Antrim',
  'Antwerp',
  'Antwerpen',
  'Antwort',
  'Anyang',
  'Anyós',
  'Anza',
  'Anzegem',
  'Anzin',
  'Anzin-saint-aubin',
  'Anzing',
  'Anzola',
  "Anzola Dell'emilia",
  'Anzuola',
  'Añover De Tajo',
  'Aoicho',
  'Aomori',
  'Aomori-shi',
  'Aosta',
  'Aotea',
  'Aoto',
  'Apache Junction',
  'Apahida',
  'Apalachicola',
  'Apalachin',
  'Apapa',
  'Aparecida',
  'Aparecida De São Manuel',
  'Apartadó',
  'Apaxco',
  'Apeldoorn',
  'Apen',
  'Apex',
  'Apizaco',
  'Apo',
  'Apodaca',
  'Apolda',
  'Apollo',
  'Apollo Beach',
  'Apopka',
  'Apostag',
  'Appel',
  'Appels',
  'Appelscha',
  'Appen',
  'Appenweier',
  'Appenzell',
  'Apperley Bridge',
  'Appiano',
  'Appingedam',
  'Apple Valley',
  'Appleby Magna',
  'Applecross',
  'Appledore',
  'Applegate',
  'Apples',
  'Appleton',
  'Appley Bridge',
  'Appling',
  'Appoigny',
  'Appomattox',
  'Apprieu',
  'Apricena',
  'Aprilia',
  'Aproz',
  'Apsley',
  'Apt',
  'Aptos',
  'Apucarana',
  'Aquebogue',
  'Aquidabã',
  'Aquiraz',
  'Ar Riyadh',
  'Ara',
  'Arab',
  'Aracaju',
  'Aracena',
  'Araçatuba',
  'Araçoiaba',
  'Araçoiaba Da Serra',
  'Arad',
  'Aradas',
  'Aradippou',
  'Arafo',
  'Araguaçu',
  'Araguaína',
  'Araguari',
  'Arakawa',
  'Aramil',
  'Aranda De Duero',
  'Aranguren',
  'Aranjuez',
  'Aransas Pass',
  'Arapahoe',
  'Arapiraca',
  'Araraquara',
  'Araras',
  'Arau',
  'Araucária',
  'Aravaca',
  'Araxá',
  'Arazede',
  'Arazuri',
  'Arán',
  'Arbedo',
  'Arbel',
  'Arbent',
  'Arbil',
  'Arbing',
  'Arboga',
  'Arbon',
  'Arbonne',
  'Arborea',
  'Arbroath',
  'Arbucias',
  'Arbuckle',
  'Arbus',
  'Arbuthnott',
  'Arcachon',
  'Arcade',
  'Arcadia',
  'Arcangues',
  'Arcanum',
  'Arcata',
  'Arcen',
  'Archamps',
  'Archbold',
  'Archelange',
  'Archena',
  'Archer',
  'Archie',
  'Arcidosso',
  'Arco',
  'Arcola',
  'Arcole',
  'Arconate',
  'Arcore',
  'Arcos De Valdevez',
  'Arcozelo',
  'Arcueil',
  'Arcugnano',
  'Ardea',
  'Ardee',
  'Arden',
  'Ardmore',
  'Ardon',
  'Ardooie',
  'Ardrossan',
  'Ardsley',
  'Are',
  'Areeiro',
  'Aregno',
  'Arenas De San Pedro',
  'Arendal',
  'Arendonk',
  'Arengosse',
  'Arenthon',
  'Arenys De Mar',
  'Arenys De Munt',
  'Arenzano',
  'Arenzville',
  'Arequipa',
  'Arese',
  'Arezzo',
  'Arès',
  'Argamé',
  'Arganda',
  'Arganil',
  'Argelato',
  'Argelès-sur-mer',
  'Argent-sur-sauldre',
  'Argenta',
  'Argentan',
  'Argentera',
  'Argenteuil',
  'Argenthal',
  'Argentina',
  'Argenton-sur-creuse',
  'Argonne',
  'Argostóli',
  'Arguedas',
  'Arguineguín',
  'Argyle',
  'Argyroupolis',
  'Argyroúpolis',
  'Ariake',
  'Ariana',
  'Ariano Irpino',
  'Arica',
  'Ariccia',
  'Aricestii-rahtivani',
  'Arichat',
  'Ariel',
  'Arielli',
  'Arifwala',
  'Arinaga',
  'Ariquemes',
  'Arisdorf',
  'Arita',
  'Ariyalur',
  'Arizona',
  'Arizona City',
  'Arjeplog',
  'Arkadelphia',
  'Arkansas City',
  'Arkdale',
  'Arkel',
  'Arkhangelsk',
  'Arklow',
  'Arlanc',
  'Arles',
  'Arlesey',
  'Arlesheim',
  'Arlington',
  'Arlington Heights',
  'Arlon',
  'Arlöv',
  'Arluno',
  'Arma Di Taggia',
  'Armada',
  'Armadale',
  'Armagh',
  'Armenia',
  'Armentières',
  'Armes',
  'Armidale',
  'Armilla',
  'Armonk',
  'Armorel',
  'Armstrong',
  'Armthorpe',
  'Armuchee',
  'Armunia',
  'Arnage',
  'Arnas',
  'Arnaudville',
  'Arnavik',
  'Arnavutköy',
  'Arnäsvall',
  'Arneberg',
  'Arnedillo',
  'Arnedo',
  'Arnesano',
  'Arnex',
  'Arnhem',
  'Arni',
  'Arnold',
  'Arnoldstein',
  'Arnouville',
  'Arnót',
  'Arnprior',
  'Arnsberg',
  'Arnsdorf',
  'Arnside',
  'Arnstadt',
  'Arnstorf',
  'Arogno',
  'Arolsen',
  'Aromas',
  'Arona',
  'Arouca',
  'Arpajon',
  'Arpajon-sur-cère',
  'Arpino',
  'Arquata Scrivia',
  'Arquennes',
  'Arques',
  'Arradon',
  'Arraiolos',
  'Arras',
  'Arre',
  'Arrecife',
  'Arrentela',
  'Arriate',
  'Arrigorriaga',
  'Arrington',
  'Arrochar',
  'Arroio Do Meio',
  'Arrone',
  'Arroyo Grande',
  'Arroyo Seco',
  'Arroyomolinos',
  'Arruda Dos Vinhos',
  'Arrúbal',
  'Ars-sur-moselle',
  'Art',
  'Artarmon',
  'Artegna',
  'Arteijo',
  'Artemivsk',
  'Artesa De Segre',
  'Artesia',
  'Artés',
  'Arthingworth',
  'Arthur',
  'Artica',
  'Artigues-près-bordeaux',
  'Artix',
  'Arucas',
  'Arujá',
  'Arumbakkam',
  'Arunachal',
  'Arundel',
  'Arusha',
  'Arvada',
  'Arverne',
  'Arvidsjaur',
  'Arvieu',
  'Arvika',
  'Arvin',
  'Arzachena',
  'Arzano',
  'Arzberg',
  'Arzignano',
  'Arzon',
  'As',
  'As Sabahani',
  'As Sulaymaniyah',
  'Asaba',
  'Asahi',
  'Asahicho',
  'Asahikawa',
  'Asahimachi',
  'Asaka',
  'Asan',
  'Asansol',
  'Asbach',
  'Asbestos',
  'Asbury',
  'Asbury Park',
  'Ascea',
  'Aschach An Der Donau',
  'Aschaffenburg',
  'Aschau',
  'Aschau Am Inn',
  'Aschau Im Chiemgau',
  'Aschbach Markt',
  'Ascheberg',
  'Aschendorf',
  'Aschersleben',
  'Aschheim',
  'Ascoli',
  'Ascoli Piceno',
  'Ascope',
  'Ascot',
  'Ascot Under Wychwood',
  'Ascot Vale',
  'Ascq',
  'Asdal',
  'Aseret',
  'Ash',
  'Ash Flat',
  'Ash Sharqiyah',
  'Ash Vale',
  'Ashanti',
  'Ashbourne',
  'Ashburn',
  'Ashburnham',
  'Ashburton',
  'Ashby',
  'Ashby De La Zouch',
  'Ashdod',
  'Ashdot Yaakov Meuhad',
  'Ashdown',
  'Asheboro',
  'Asheville',
  'Ashfield',
  'Ashford',
  'Ashfordby',
  'Ashgabat',
  'Ashgrove',
  'Ashikaga',
  'Ashington',
  'Ashiya',
  'Ashkelon',
  'Ashland',
  'Ashland City',
  'Ashley',
  'Ashmore',
  'Ashmun',
  'Ashok Nagar',
  'Ashqelon',
  'Ashquelon',
  'Ashtabula',
  'Ashtead',
  'Ashton',
  'Ashton In Makerfield',
  'Ashton Keynes',
  'Ashton-under-lyne',
  'Ashtown',
  'Ashville',
  'Ashwell',
  'Asia',
  'Asiago',
  'Ask',
  'Asker',
  'Askersund',
  'Askim',
  'Askola',
  'Aslacton',
  'Asmundtorp',
  'Asnières',
  'Asnières-sur-vègre',
  'Asnæs',
  'Asolo',
  'Asotin',
  'Aspach-le-bas',
  'Aspach-le-haut',
  'Aspe',
  'Aspen',
  'Aspendale',
  'Asperg',
  'Asprópirgos',
  'Asquith',
  'Assagao',
  'Assamstadt',
  'Asse',
  'Assebroek',
  'Assen',
  'Assendelft',
  'Assenede',
  'Assens',
  'Assentoft',
  'Assesse',
  'Assis',
  'Assisi',
  'Assiut',
  'Assonet',
  'Assuit',
  'Assumption',
  'Astana',
  'Astatula',
  'Asteasu',
  'Asten',
  'Asti',
  'Astigarraga',
  'Astley',
  'Astolfo Dutra',
  'Aston',
  'Aston Clinton',
  'Astorga',
  'Astoria',
  'Astra',
  'Astrabudúa',
  'Astrakhan',
  'Asturias',
  'Astwick',
  'Asunción',
  'Asúa',
  'Aswan',
  'Asyut',
  'Aszofo',
  'Atarfe',
  'Atascadero',
  'Atchison',
  'Atco',
  'Ate',
  'Ateli',
  'Atessa',
  'Atglen',
  'Ath',
  'Athabasca',
  'Athen',
  'Athens',
  'Atherstone',
  'Atherton',
  'Athénes',
  'Athi River',
  'Athis-mons',
  'Athína',
  'Athlone',
  'Athol',
  'Athurugiriya',
  'Athy',
  'Atibaia',
  'Atikokan',
  'Atitalaquia',
  'Atizapán De Zaragoza',
  'Atkins',
  'Atkinson',
  'Atlanta',
  'Atlantic',
  'Atlantic Beach',
  'Atlantic City',
  'Atlantic Highlands',
  'Atlantic Mine',
  'Atlantis',
  'Atlántico',
  'Atlixco',
  'Atmore',
  'Atoka',
  'Atotonilco El Alto',
  'Atouguia Da Baleia',
  'Atrau',
  'Atsubetsu',
  'Atsugi',
  'Attadale',
  'Attalens',
  'Attendorn',
  'Attenkirchen',
  'Attersee',
  'Attica',
  'Attikí',
  'Attimis',
  'Attiswil',
  'Attleboro',
  'Attleboro Falls',
  'Attleborough',
  'Attnang-puchheim',
  'Attock',
  'Attre',
  'Atwater',
  'Atwood',
  'Atyrau',
  'Atzgersdorf',
  'Au',
  "Au Dela De L'eau",
  'Au Gres',
  'Aubagne',
  'Aubais',
  'Aubange',
  'Aubel',
  'Aubenas',
  'Aubergenville',
  'Aubervilles',
  'Aubervilliers',
  'Aubeterre-sur-dronne',
  'Aubière',
  'Aubigny',
  'Aubigny-en-artois',
  'Aubigny-lès-sombernon',
  'Aubigny-sur-nère',
  'Aubin',
  'Aubonne',
  'Aubord',
  'Aubrey',
  'Auburn',
  'Auburn Hills',
  'Auburn University',
  'Auburndale',
  'Aucamville',
  'Auch',
  'Auckland',
  'Auderghem',
  'Audubon',
  'Aue',
  'Auerbach',
  'Auerbach In Der Oberpfalz',
  'Aufhausen',
  'Augny',
  'Augsburg',
  'Augst',
  'Augusta',
  'Augustdorf',
  'Augustów',
  'Augustusburg',
  'Auhagen',
  'Aukra',
  'Aulendorf',
  'Aulestia',
  'Aull',
  'Aulnay-sous-bois',
  'Aulnay-sur-iton',
  'Aulnay-sur-mauldre',
  'Aulum',
  'Auma',
  'Aumont',
  'Aumsville',
  'Aumühle',
  'Aundh',
  'Auning',
  'Aura',
  'Aurangabad',
  'Auray',
  'Aure',
  'Aurel',
  'Aureville',
  'Aurich',
  'Aurillac',
  'Auroa',
  'Aurolzmünster',
  'Auronzo Di Cadore',
  'Aurora',
  'Aursfjordgård',
  'Aurskog',
  'Aussonne',
  'Austell',
  'Austin',
  'Austinburg',
  'Austinmer',
  'Australia',
  'Australia Plains',
  'Australian Capital Territory',
  'Austrey',
  'Austria',
  'Austvatn',
  'Autechaux',
  'Auterive',
  'Authume',
  'Autol',
  'Autun',
  'Auvergne',
  'Auvernier',
  'Auvers-sur-oise',
  'Auw',
  'Aux-aussat',
  'Auxerre',
  'Auxonne',
  'Auxvasse',
  'Auzeville-tolosane',
  'Ava',
  'Avallon',
  'Avalon',
  'Avanashi',
  'Avarua',
  'Avcilar',
  'Aveiras De Cima',
  'Aveiro',
  'Avelãs De Caminho',
  'Aveleda',
  'Aveley',
  'Avelgem',
  'Avelin',
  'Avellaneda',
  'Avellino',
  'Avenal',
  'Avenches',
  'Avenel',
  'Aventura',
  'Avenue',
  'Avermes',
  'Aversa',
  'Averton',
  'Avery Island',
  'Avesnes-le-comte',
  'Avesta',
  'Avezzano',
  'Avidos',
  'Aviemore',
  'Avigliana',
  'Avignon',
  'Avihayil',
  'Avila Beach',
  'Avilés',
  'Avilla',
  'Avintes',
  'Avissawella',
  'Avoca',
  'Avoca Beach',
  'Avoine',
  'Avon',
  'Avon Dassett',
  'Avon Lake',
  'Avon Park',
  'Avon-les-roches',
  'Avondale',
  'Avondale Estates',
  'Avonmouth',
  'Avoudrey',
  'Avranches',
  'Avrig',
  'Avrillé',
  'Avry-sur-matran',
  'Awahuri',
  'Awans',
  'Awara',
  'Awendaw',
  'Awka',
  'Awoyaya',
  'Axat',
  'Axbridge',
  'Axel',
  'Axioúpoli',
  'Axminster',
  'Axmouth',
  'Axpe',
  'Axtell',
  'Ay',
  'Ayala',
  'Ayamonte',
  'Ayanavaram',
  'Ayase',
  'Ayat-sur-sioule',
  'Aycliffe',
  'Ayden',
  'Aydie',
  'Aydin',
  'Aye',
  'Ayelo De Malferit',
  'Ayer',
  'Ayer Keroh',
  "Ayer's Cliff",
  'Ayguesvives',
  'Ayia Napa',
  'Aying',
  'Ayía Paraskeví',
  'Aylesbury',
  'Aylesford',
  'Aylestone',
  'Aylmer',
  'Aylsham',
  'Ayora',
  'Ayr',
  'Aytré',
  'Ayuthaya',
  'Ayutthaya',
  'Aywaille',
  'Az Zagazig',
  "Az Zahra'",
  'Az Zinan',
  'Azabu',
  'Azadegan',
  'Azadpur',
  'Azagra',
  'Azaiba',
  'Azambuja',
  'Azay-le-rideau',
  'Azcapotzalco',
  'Azcoitia',
  'Azerailles',
  'Azle',
  'Azor',
  'Azov',
  'Azpeitia',
  'Aztec',
  'Azumabashi',
  'Azumino',
  'Azuqueca De Henares',
  'Azur',
  'Azurara',
  'Azusa',
  'Azusawa',
  'Azzano',
  'Azzano Decimo',
  'Azzate',
  'Aßlar',
  'Aßling',
  'Aßmannshardt',
  'Ágioi Anárgyroi',
  'Ágios Dimítrios',
  'Ágios Ioánnis',
  'Ágios Stéfanos',
  'Água Branca',
  'Águas Claras',
  'Águas Da Prata',
  'Águeda',
  'Águilas',
  'Áibar',
  'Álava',
  'Álvares Florence',
  'Álvaro Obregón',
  'Ámfissa',
  'Ángel R. Cabada',
  'Áno Liósia',
  'Ászár',
  'Ávila',
  'Áyios Stéfanos',
  'Äänekoski',
  'Ähtäri',
  'Äkäsjoensuu',
  'Älandsbro',
  'Älmhult',
  'Älta',
  'Älvängen',
  'Älvdalen',
  'Älvsjö',
  'Ängelholm',
  'Ängelholms Havsbad',
  'Äspenäs',
  'Äspered',
  'Åbo',
  'Åby',
  'Åbyhøj',
  'Ågotnes',
  'Åhus',
  'Åkarp',
  'Åker',
  'Åkers Styckebruk',
  'Åkersberga',
  'Åled',
  'Ålem',
  'Ålesund',
  'Ålgård',
  'Ålsgårde',
  'Åmål',
  'Åndalsnes',
  'Ånge',
  'Årdal',
  'Åre',
  'Århus',
  'Årjäng',
  'Årland',
  'Årnes',
  'Årslev',
  'Årsta',
  'Årsunda',
  'Åryd',
  'Ås',
  'Åsa',
  'Åsbro',
  'Åseda',
  'Åsgårdstrand',
  'Åstol',
  'Åstorp',
  'Åtvidaberg',
  'Baabda',
  'Baaigem',
  'Baao',
  'Baar',
  'Baarle-nassau',
  'Baarlo',
  'Baarn',
  'Baatstö',
  'Bab Ezzouar',
  'Babayevskaya',
  'Babenhausen',
  'Babice Nowe',
  'Babil',
  'Babite',
  'Babolsar',
  'Babraham',
  'Babson Park',
  'Babu',
  'Babylon',
  'Bac Lieu',
  'Bacau',
  'Bacchus Marsh',
  'Bach',
  'Bach An Der Donau',
  'Bacharach',
  'Bachenbulach',
  'Bachenbülach',
  'Bachhagel',
  'Bachte-maria-leerne',
  'Bachy',
  'Backa',
  'Backnang',
  'Backus',
  'Baclaran',
  'Bacoli',
  'Bacolod',
  'Bacolod City',
  'Bacoor',
  'Bacton',
  'Bacup',
  'Bad Abbach',
  'Bad Aibling',
  'Bad Alexandersbad',
  'Bad Aussee',
  'Bad Axe',
  'Bad Bellingen',
  'Bad Bentheim',
  'Bad Bergzabern',
  'Bad Berka',
  'Bad Berleburg',
  'Bad Berneck',
  'Bad Bertrich',
  'Bad Bevensen',
  'Bad Blankenburg',
  'Bad Bocklet',
  'Bad Boll',
  'Bad Bramstedt',
  'Bad Breisig',
  'Bad Brückenau',
  'Bad Camberg',
  'Bad Driburg',
  'Bad Düben',
  'Bad Dürkheim',
  'Bad Dürrheim',
  'Bad Ems',
  'Bad Endbach',
  'Bad Essen',
  'Bad Feilnbach',
  'Bad Füssing',
  'Bad Gandersheim',
  'Bad Goisern',
  'Bad Gögging',
  'Bad Grund',
  'Bad Harzburg',
  'Bad Herrenalb',
  'Bad Hersfeld',
  'Bad Homburg',
  'Bad Homburg Vor Der Höhe',
  'Bad Honnef Am Rhein',
  'Bad Iburg',
  'Bad Ischl',
  'Bad Karlshafen',
  'Bad Kissingen',
  'Bad König',
  'Bad Königshofen',
  'Bad Kreuznach',
  'Bad Krozingen',
  'Bad Laasphe',
  'Bad Laer',
  'Bad Langensalza',
  'Bad Lauchstädt',
  'Bad Lausick',
  'Bad Lauterberg',
  'Bad Liebenstein',
  'Bad Liebenwerda',
  'Bad Liebenzell',
  'Bad Lippspringe',
  'Bad Marienberg',
  'Bad Mergentheim',
  'Bad Münstereifel',
  'Bad Nauheim',
  'Bad Nenndorf',
  'Bad Neuenahr',
  'Bad Neuenahr-ahrweiler',
  'Bad Neustadt',
  'Bad Neustadt An Der Saale',
  'Bad Oberdorf',
  'Bad Oeynhausen',
  'Bad Oldesloe',
  'Bad Orb',
  'Bad Pyrmont',
  'Bad Ragaz',
  'Bad Rappenau',
  'Bad Reichenhall',
  'Bad Rothenfelde',
  'Bad Saarow',
  'Bad Sachsa',
  'Bad Salzdetfurth',
  'Bad Salzuflen',
  'Bad Salzungen',
  'Bad Sassendorf',
  'Bad Säckingen',
  'Bad Schussenried',
  'Bad Schwalbach',
  'Bad Schwartau',
  'Bad Segeberg',
  'Bad Soden',
  'Bad Soden Am Taunus',
  'Bad Soden-salmünster',
  'Bad Sooden-allendorf',
  'Bad Steben',
  'Bad Toelz',
  'Bad Tölz',
  'Bad Vilbel',
  'Bad Voslau',
  'Bad Waldsee',
  'Bad Wiessee',
  'Bad Wildbad',
  'Bad Wildungen',
  'Bad Windsheim',
  'Bad Wörishofen',
  'Bad Zwischenahn',
  'Badajoz',
  'Badalona',
  'Badarpur',
  'Badda',
  'Baddeck',
  'Baddeckenstedt',
  'Badem',
  'Baden',
  'Baden-baden',
  'Badesse',
  'Badhoevedorp',
  'Badia',
  'Badia Polesine',
  'Badlapur',
  'Badli',
  'Badminton',
  'Bado Sarai',
  'Badr',
  'Badulla',
  'Badung',
  'Bady Bassitt',
  'Baena',
  'Baerl',
  'Baesweiler',
  'Baexem',
  'Baeza',
  'Bafoussam',
  'Bagalkot',
  'Bagamoyo',
  'Bagcilar',
  'Bagdad',
  'Bageshwar',
  'Bagé',
  'Baghdad',
  'Bagheria',
  'Baghpat',
  'Bagillt',
  'Bagnasco',
  'Bagnes',
  'Bagneux',
  'Bagnères-de-bigorre',
  'Bagno A Ripoli',
  "Bagnoles-de-l'orne",
  'Bagnolet',
  'Bagnoli Irpino',
  'Bagnolo Cremasco',
  'Bagnolo Mella',
  'Bagnolo San Vito',
  'Bagnols-sur-cèze',
  'Bagshot',
  'Bagsværd',
  'Baguim Do Monte',
  'Baguio',
  'Baguio City',
  'Bagumbayan',
  'Bahadurgarh',
  'Bahama',
  'Bahang',
  'Baharestan',
  'Bahawalpur',
  'Bahawalpur Ghalwan',
  'Bahçeköy',
  'Bahçelievler',
  'Bahia',
  'Bahia Blanca',
  'Bahía Blanca',
  'Bahlingen',
  'Bahraich',
  'Baia Mare',
  'Baicheng',
  'Baidu',
  'Baie Du Tombeau',
  'Baie Verte',
  'Baie-comeau',
  "Baie-d'urfe",
  'Baie-saint-paul',
  'Baienfurt',
  'Baierbrunn',
  'Baiersbronn',
  'Baiersdorf',
  'Baijiantan',
  'Baildon',
  'Baile Na Habhann',
  'Bailey',
  'Bailén',
  'Baillargues',
  'Bailleul',
  'Baillonville',
  'Bailly',
  'Bailly-romainvilliers',
  'Bainbridge',
  'Bainbridge Island',
  'Baindt',
  'Bainville',
  'Baipail',
  'Bairnsdale',
  'Bairro',
  'Baise',
  'Baishan',
  'Baix',
  'Baiyin',
  'Baiyun',
  'Baja',
  'Baja California',
  'Bakar',
  'Baker',
  'Baker City',
  'Bakersfield',
  'Bakersville',
  'Bakewell',
  'Bakhuizen',
  'Baki',
  'Bakirkoy',
  'Bakkeveen',
  'Bakonyszombathely',
  'Bakov Nad Jizerou',
  'Baku',
  'Baku-baladshary',
  'Bakum',
  'Bala Cynwyd',
  'Balaclava',
  'Balagtas',
  'Balaguer',
  'Balakovo',
  'Balanagar',
  'Balanga',
  'Balaschicha',
  'Balashikha',
  'Balasore',
  'Balassagyarmat',
  'Balaton',
  'Balatonakarattya',
  'Balatonalmádi',
  'Balatonboglár',
  'Balatonfenyves',
  'Balatonföldvár',
  'Balatonfüred',
  'Balatonoszod',
  'Balástya',
  'Balbigny',
  'Balboa',
  'Balbriggan',
  'Balcombe',
  'Balçova',
  'Balderton',
  'Baldham',
  'Baldock',
  'Baldoyle',
  'Baldwin',
  'Baldwin City',
  'Baldwin Park',
  'Baldwinsville',
  'Baldwyn',
  'Balen',
  'Balerna',
  'Balerno',
  'Balestrand',
  'Balgach',
  'Balgat',
  'Balge',
  'Balgheim',
  'Balgowlah',
  'Balgowlah Heights',
  'Balgownie',
  'Balham',
  'Bali',
  'Balice',
  'Balik Pulau',
  'Balikesir',
  'Balingen',
  'Balintawak',
  'Balk',
  'Balkbrug',
  'Ball Ground',
  'Ballabgarh',
  'Ballaghaderreen',
  'Ballainvilliers',
  'Ballan',
  'Ballarat',
  'Ballard',
  'Ballard Estate',
  'Ballari',
  'Balle',
  'Ballendorf',
  'Ballenstedt',
  'Ballerup',
  'Ballia',
  'Ballico',
  'Ballina',
  'Ballinagh',
  'Ballinamallard',
  'Ballinasloe',
  'Ballincollig',
  'Ballinger',
  'Ballinskelligs',
  'Ballinteer',
  'Ballintemple',
  'Ballinure',
  'Ballószög',
  'Ballsbridge',
  'Ballstad',
  'Ballstedt',
  'Ballston Lake',
  'Ballston Spa',
  'Ballwil',
  'Ballwin',
  'Bally',
  'Ballybofey',
  'Ballyboughal',
  'Ballybrit',
  'Ballyclare',
  'Ballyclery',
  'Ballycotton',
  'Ballycullen',
  'Ballycurrane Cross Roads',
  'Ballyfermot',
  'Ballygawley',
  'Ballygowan',
  'Ballyhaunis',
  'Ballymena',
  'Ballymoe',
  'Ballymoney',
  'Ballymore Eustace',
  'Ballymote',
  'Ballymount',
  'Ballymun',
  'Ballynahinch',
  'Ballynure',
  'Ballyshannon',
  'Balma',
  'Balmain',
  'Balmattum',
  'Balmazújváros',
  'Balmoral',
  'Balneário',
  'Balneário De Camboriú',
  'Balotesti',
  'Balozi',
  'Balsam Lake',
  'Balsamo',
  'Balsares',
  'Balsthal',
  'Baltic',
  'Baltimore',
  'Balve',
  'Balwyn',
  'Balwyn North',
  'Balzers',
  'Bamako',
  'Bamber Bridge',
  'Bamberg',
  'Bambolim',
  'Bamburgh',
  'Bamenda',
  'Bammental',
  'Bampton',
  'Ban Chalong',
  'Ban Khao Lak',
  'Ban Khlong Tan',
  'Ban Mai',
  'Ban Nong Kong Chak',
  'Ban Pathumwan',
  'Ban-saint-martin',
  'Banan',
  'Banawa',
  'Banbridge',
  'Banbury',
  'Banchory',
  'Bancroft',
  'Banda',
  'Banda Aceh',
  'Bandai-cho',
  'Bandar',
  'Bandar Baru Bangi',
  'Bandar Lampung',
  'Bandar Seri Begawan',
  'Bandarawela',
  'Bandera',
  'Bandholm',
  'Bandirma',
  'Bandol',
  'Bandon',
  'Bandra',
  'Bandung',
  'Bandung Barat',
  'Bane',
  'Baneh',
  'Baneuil',
  'Banff',
  'Banfield',
  'Bang Bon',
  'Bang Chak',
  'Bang Kapi',
  'Bang Khen',
  'Bang Khun Thian',
  'Bang Lamung',
  'Bang Na',
  'Bang Pa-in',
  'Bang Phlat',
  'Bang Rak',
  'Bang Sue',
  'Banga',
  'Bangalore',
  'Bangalore City',
  'Bangbaedong',
  'Bangi',
  'Bangka',
  'Bangkok',
  'Bangkok Metropolis',
  'Bangkok Noi',
  'Bangkok Yai',
  'Bangor',
  'Bangui',
  'Bangun',
  'Banha',
  'Banilad',
  'Banja Luka',
  'Banjarmasin',
  'Banjarnegara',
  'Banjul',
  'Bankeryd',
  'Bankok',
  'Banks',
  'Banksmeadow',
  'Bankstown',
  'Bankura',
  'Bankya',
  'Bannalec',
  'Banner Elk',
  'Bannewitz',
  'Banning',
  'Bannockburn',
  'Bannwil',
  'Banpodong',
  'Bansha',
  'Banska Stiavnica',
  'Banská Bystrica',
  'Bansko',
  'Banstead',
  'Banswada',
  'Banten',
  'Banting',
  'Bantry',
  'Bantul',
  'Banyuls-sur-mer',
  'Banyumas',
  'Banyuwangi',
  'Bañeres',
  'Bañolas',
  'Bañoles',
  'Baoan',
  'Baodi',
  'Baoding',
  'Baohe',
  'Baoji',
  'Baone',
  'Baoshan',
  'Baotou',
  'Baotou Shi',
  'Baoying',
  'Baqa Al Gharbiya',
  'Baqiao',
  'Bar',
  'Bar Harbor',
  'Bar Hill',
  'Bar Mills',
  'Bar-le-duc',
  'Bara',
  'Baraboo',
  'Baracaldo',
  'Barajas',
  'Barak',
  'Baramula',
  'Baranowo',
  'Baranzate',
  'Barañáin',
  'Barasat',
  'Barat',
  'Barau',
  'Baraut',
  'Barão De Geraldo',
  'Barbacena',
  'Barbara',
  'Barbará',
  'Barbaros',
  'Barbastro',
  'Barbate',
  'Barbentane',
  'Barberaz',
  'Barberino',
  'Barberino Di Mugello',
  'Barberton',
  'Barbezieux',
  'Barbing',
  'Barbosa',
  'Barboursville',
  'Barbourville',
  'Barcarena',
  'Barcelona',
  'Barceloneta',
  'Barcelonnette',
  'Barcelos',
  'Barchfeld',
  'Barchon',
  'Barco',
  'Barcs',
  'Bardejov',
  'Bardhaman',
  'Bardoli',
  'Bardolino',
  'Bardon Mill',
  'Bardonecchia',
  'Bardowick',
  'Bardstown',
  'Bardwell',
  'Bareggio',
  'Bareilly',
  'Barembach',
  'Barendrecht',
  'Barentin',
  'Barford',
  'Bargarh',
  'Bargas',
  'Barge',
  'Bargemon',
  'Bargersville',
  'Bargo',
  'Bargoed',
  'Bargteheide',
  'Barhamsville',
  'Bari',
  'Bariloche',
  'Barinas',
  'Barisal',
  'Bark',
  'Barkai',
  'Barkarby',
  'Barkåker',
  'Barkhamsted',
  'Barking',
  'Barkingside',
  'Barkway',
  'Barlborough',
  'Barleben',
  'Barletta',
  'Barley',
  'Barmer',
  'Barmstedt',
  'Barnala',
  'Barnard Castle',
  'Barnardsville',
  'Barnaul',
  'Barnegat',
  'Barnegat Light',
  'Barnesville',
  'Barnet',
  'Barnetby',
  'Barneveld',
  'Barney',
  'Barnham',
  'Barnoldswick',
  'Barnsdall',
  'Barnsley',
  'Barnstable',
  'Barnstaple',
  'Barnstorf',
  'Barntrup',
  'Barnwell',
  'Baroda',
  'Barongarook',
  'Baronissi',
  'Barosa',
  'Barpeta',
  'Barquisimeto',
  'Barr',
  'Barra Bonita',
  'Barra Da Tijuca',
  'Barra Do Garças',
  'Barra Do Piraí',
  'Barra Dos Coqueiros',
  'Barra Mansa',
  'Barrackpore',
  'Barrancabermeja',
  'Barranco',
  'Barranquilla',
  'Barraux',
  'Barrax',
  'Barre',
  'Barreiras',
  'Barreiro',
  'Barrero',
  'Barretos',
  'Barrhead',
  'Barri',
  'Barrie',
  'Barrington',
  'Barrio De Fernán Núñez',
  'Barrios Unidos',
  'Barro Branco',
  'Barro Colorado',
  'Barro Vermelho',
  'Barrocas',
  'Barron',
  'Barrow',
  'Barrow In Furness',
  'Barrow Upon Soar',
  'Barrowford',
  'Barrô',
  'Barry',
  'Barrys Bay',
  'Barrytown',
  'Barryville',
  'Barsbüttel',
  'Barsinghausen',
  'Barstow',
  'Bartenheim',
  'Barth',
  'Barthélemy',
  'Bartlesville',
  'Bartlett',
  'Barto',
  'Barton',
  'Barton On Sea',
  'Barton Upon Humber',
  'Bartonsville',
  'Bartow',
  'Baru',
  'Barueri',
  'Barum',
  'Baruth',
  'Barwite',
  'Barwon Heads',
  'Barzano',
  'Barzkowice',
  'Bas-et-lezat',
  'Basalt',
  'Basauri',
  'Bascharage',
  'Basconcobe',
  'Basehor',
  'Basel',
  'Baselga Di Pine',
  'Basford',
  'Bashaw',
  'Bashkortostan',
  'Basildon',
  'Basilicanova',
  'Basilio',
  'Basin',
  'Basing',
  'Basingstoke',
  'Basking Ridge',
  'Basle',
  'Baslow',
  'Basoda',
  'Basovizza',
  'Basra',
  'Bassano Del Grappa',
  'Basse-goulaine',
  'Basse-yutz',
  'Bassecourt',
  'Bassendean',
  'Bassersdorf',
  'Basseterre',
  'Bassett',
  'Bassingham',
  'Bassum',
  'Basti',
  'Bastia',
  'Bastogne',
  'Bastrop',
  'Basye',
  'Bat Hadar',
  'Bat Hefer',
  'Bat Shelomo',
  'Bat Yam',
  "Bat'umi",
  'Bata',
  'Bataan',
  'Batala',
  'Batalha',
  'Batam',
  'Batangas',
  'Batangas City',
  'Batatais',
  'Batavia',
  'Batemans Bay',
  'Batesland',
  'Batesville',
  'Bath',
  'Batheaston',
  'Bathgate',
  'Bathurst',
  'Batikent',
  'Batley',
  'Batnfjordsøra',
  'Baton Rouge',
  'Batroûn',
  'Battaglia',
  'Battaramulla',
  'Battenberg',
  'Battenfeld',
  'Battersea',
  'Battice',
  'Battipaglia',
  'Battle',
  'Battle Creek',
  'Battle Ground',
  'Batu',
  'Batu Berendam',
  'Batu Caves',
  'Batu Kawan',
  'Batu Pahat',
  'Batu Uban',
  'Bauan',
  'Bauchi',
  'Baudette',
  'Baughurst',
  'Baugy',
  'Baule',
  'Baulers',
  'Baulkham Hills',
  'Baulkham Hills Shire',
  'Baulmes',
  'Bauma',
  'Baumbach',
  'Baumberg',
  'Baume-les-dames',
  'Baumgarten',
  'Baunatal',
  'Bauru',
  'Bauska',
  'Baustetten',
  'Bautzen',
  'Bavaria',
  'Bavel',
  'Baveno',
  'Bavet',
  'Bavikhove',
  'Bawana',
  'Bawtry',
  'Baxter',
  'Baxter Springs',
  'Bay',
  'Bay City',
  'Bay Minette',
  'Bay Saint Louis',
  'Bay Shore',
  'Bay Springs',
  'Bay View',
  'Bay Village',
  'Bayamo',
  'Bayan',
  'Bayan Lepas',
  'Baydon',
  'Bayerbach',
  'Bayerisch Eisenstein',
  'Bayeux',
  'Bayfield',
  'Bayonne',
  'Bayou La Batre',
  'Bayport',
  'Bayrakli',
  'Bayrampasa',
  'Bayreuth',
  'Bayside',
  'Bayswater',
  'Baytown',
  'Bayville',
  'Baza',
  'Bazancourt',
  'Bazas',
  'Bazel',
  'Bazemont',
  'Bazenheid',
  'Bazet',
  'Bazra',
  'Bazzano',
  'Bábolna',
  'Bácum',
  'Bánovce Nad Bebravou',
  'Bárcena De Pie De Concha',
  'Bäch',
  'Bäckaryd',
  'Bälinge',
  'Bärenstein',
  'Bäretswil',
  'Bärnau',
  'Bärnbach',
  'Bärschwil',
  'Bätterkinden',
  'Bålsta',
  'Båstad',
  'Båtstø',
  "Be'er Sheva",
  'Beach City',
  'Beach Haven',
  'Beach Lake',
  'Beachburg',
  'Beachlands',
  'Beachwood',
  'Beacon',
  'Beacon Falls',
  'Beaconsfield',
  'Bealeton',
  'Beaminster',
  'Beamsville',
  'Bean Station',
  'Bear',
  'Bear Lake',
  'Beardstown',
  'Bearsden',
  'Bearsted',
  'Beasain',
  'Beasley',
  'Beatrice',
  'Beattock',
  'Beau Bassin',
  'Beaucaire',
  'Beauchamp',
  'Beaucouzé',
  'Beaudesert',
  'Beaufays',
  'Beaufort',
  'Beauharnois',
  'Beaulieu-sur-dordogne',
  'Beaulieu-sur-mer',
  'Beauly',
  'Beaumaris',
  'Beaumesnil',
  'Beaumont',
  'Beaumont-de-pertuis',
  'Beaumont-du-périgord',
  'Beaumont-en-cambrésis',
  'Beaumont-lès-valence',
  'Beaune',
  'Beauport',
  'Beaupré',
  'Beaupréau',
  'Beauraing',
  'Beaurains',
  'Beauregard',
  'Beauséjour',
  'Beausoleil',
  'Beauteville',
  'Beauvais',
  'Beauvechain',
  'Beauzelle',
  'Beaver',
  'Beaver Bank',
  'Beaver Creek',
  'Beaver Dam',
  'Beaver Falls',
  'Beavercreek',
  'Beaverdam',
  'Beaverlodge',
  'Beaverton',
  'Bebedouro',
  'Bebek',
  'Bebington',
  'Bebra',
  'Beccar',
  'Beccles',
  'Becej',
  'Bechhofen',
  'Bechovice',
  'Bechtelsville',
  'Beckbury',
  'Beckenham',
  'Beckenried',
  'Becker',
  'Becket',
  'Beckington',
  'Beckley',
  'Beckton',
  'Beckum',
  'Becsehely',
  'Becvary',
  'Bedale',
  'Bedburg',
  'Beddingestrand',
  'Bedford',
  'Bedford Hills',
  'Bedford Park',
  'Bedfordview',
  'Bedlington',
  'Bedminster',
  'Bedok',
  'Bedum',
  'Bedwas',
  'Bedworth',
  'Beebe',
  'Beech Grove',
  'Beech Island',
  'Beecher',
  'Beechmont',
  'Beechville',
  'Beechy',
  'Beek',
  'Beekbergen',
  'Beelen',
  'Beelitz',
  'Beemte',
  'Beenleigh',
  'Beer Tuvya',
  'Beerfelden',
  'Beernem',
  'Beerot Yitzhak',
  'Beerotayim Alef',
  'Beers',
  'Beerse',
  'Beersel',
  'Beersheba',
  'Beervelde',
  'Beerwah',
  'Beerze',
  'Beerzel',
  'Beesd',
  'Beeskow',
  'Beeston',
  'Beetsterzwaag',
  'Beeville',
  'Befu',
  'Begampet',
  'Beggen',
  'Begnins',
  'Begonte',
  'Begues',
  'Begunje',
  'Begunje Na Gorenjskem',
  'Begusarai',
  'Behamberg',
  'Behren-lès-forbach',
  'Behror',
  'Beibei',
  'Beierfeld',
  'Beihai',
  'Beijiao',
  'Beijin',
  'Beijing',
  'Beijing Shi',
  'Beilen',
  'Beilin',
  'Beilngries',
  'Beilstein',
  'Beilu',
  'Beilun',
  'Beinasco',
  'Beinette',
  'Beinwil',
  'Beira',
  'Beirout',
  'Beirut',
  'Beit Alfa',
  "Beit El'azari",
  'Beit Hakerem',
  'Beit Halevi',
  'Beit Hanan',
  'Beit Hananya',
  'Beit Lehem Haglilit',
  'Beit Nehemya',
  'Beit Neqofa',
  'Beit Shean',
  'Beit Yanai',
  'Beit Zayit',
  'Beit-shemesh',
  'Beixingqiao',
  'Beja',
  'Bejaïa',
  'Bekasi',
  'Bekasi Kota',
  'Bekecs',
  'Bekesbourne',
  'Bekkjarvik',
  'Bekmünde',
  'Bel',
  'Bel Air',
  'Bela Pod Bezdezem',
  'Bela Vista',
  'Bela Vista De Goiás',
  'Beladice',
  'Belair',
  'Belapur',
  'Belas',
  'Belbroughton',
  'Belcamp',
  'Belchatow',
  'Belchertown',
  'Belcodène',
  'Belconnen',
  'Belcourt',
  'Belding',
  'Belebey',
  'Belem Do Pará',
  'Belen',
  'Belews Creek',
  'Belém',
  'Belém Do São Francisco',
  'Belfair',
  'Belfast',
  'Belfaux',
  'Belfeld',
  'Belfield',
  'Belford',
  'Belfort',
  'Belgami',
  'Belgaum',
  'Belgern',
  'Belgershain',
  'Belgian Gardens',
  'Belgiek',
  'Belgioioso',
  'Belgium',
  'Belgorod',
  'Belgrad',
  'Belgrade',
  'Belgrado',
  'Belgrano',
  'Belgrave',
  'Belhaven',
  'Belin',
  'Belize City',
  'Belk',
  'Bell',
  'Bell Block',
  'Bell City',
  'Bell Gardens',
  'Bell Ville',
  'Bella Vista',
  'Bellaire',
  'Bellary',
  'Bellbrae',
  'Bellbrook',
  'Belle Chasse',
  'Belle Fourche',
  'Belle Glade',
  'Belle Isle',
  'Belle Mead',
  'Belle Plaine',
  'Belle River',
  'Belle Vernon',
  'Belleair Beach',
  'Belledune',
  'Bellefontaine',
  'Bellefonte',
  'Bellegarde',
  'Bellegem',
  'Bellenberg',
  'Bellerive-sur-allier',
  'Bellerose',
  'Bellevaux',
  'Belleview',
  'Belleville',
  'Belleville-sur-meuse',
  'Belleville-sur-saône',
  'Belleville-sur-vie',
  'Bellevue',
  'Bellevue Hill',
  'Belley',
  'Bellême',
  'Bellflower',
  'Bellheim',
  'Bellignat',
  'Bellingen',
  'Bellingham',
  'Bellinzona',
  'Bellmawr',
  'Bellmore',
  'Bellmund',
  'Bello',
  'Bello Horizonte',
  'Bellona',
  'Bellows Falls',
  'Bellport',
  'Bellpuig',
  'Bells',
  'Bellshill',
  'Belluno',
  'Bellville',
  'Bellvue',
  'Bellwood',
  'Belm',
  'Belmar',
  'Belmond',
  'Belmont',
  'Belmont-sur-lausanne',
  'Belmonte',
  'Belmopan',
  'Belmore',
  'Belmullet',
  'Belo Horizonte',
  'Beloeil',
  'Beloit',
  'Belous',
  'Belp',
  'Belper',
  'Belpre',
  'Belrose',
  'Belterra',
  'Beltinge',
  'Belton',
  'Beltsville',
  'Belusice',
  'Belval',
  'Belvaux',
  'Belvedere',
  'Belvedere Tiburon',
  'Belvédère',
  'Belvidere',
  'Belvue',
  'Belzig',
  'Bembibre',
  'Bemidji',
  'Bemmel',
  'Ben Arous',
  'Ben Lomond',
  'Ben Shemen',
  'Ben Wheeler',
  'Benageber',
  'Benahavís',
  'Benai Beraq',
  'Benalmádena',
  'Benatky Nad Jizerou',
  'Benavente',
  'Benavides',
  'Bend',
  'Bendern',
  'Bendestorf',
  'Bendigo',
  'Bendorf',
  'Bendungan Hilir',
  'Bene Ayish',
  'Bene Beraq',
  'Bene Vagienna',
  'Beneden-leeuwen',
  'Benedita',
  'Benei Dror',
  'Benei Zion',
  'Benejúzar',
  'Benesov',
  'Benesov U Semil',
  'Benetúser',
  'Benevento',
  'Bené Darom',
  'Benfeld',
  'Benfica',
  'Benfleet',
  'Benga',
  'Bengalooru',
  'Bengaluru',
  'Bengbu',
  'Bengel',
  'Benghazi',
  'Bengtsfors',
  'Benguerir',
  'Benguet',
  'Bengy-sur-craon',
  'Benha',
  'Benhausen',
  'Beni Brahim',
  'Beni Khalled',
  'Benicarló',
  'Benicasim',
  'Benicia',
  'Benidorm',
  'Benifayó',
  'Benimaclet',
  'Benimamet',
  'Benin City',
  'Benin-city',
  'Beniparrell',
  'Benito Juarez',
  'Benito Juárez',
  'Benken',
  'Benllech',
  'Benloch',
  'Bennäs',
  'Bennebroek',
  'Benneckenstein',
  'Bennekom',
  'Bennett',
  "Bennett's Bridge",
  'Bennettsville',
  'Benningbroek',
  'Bennington',
  'Bennwihr',
  'Benoni',
  'Bensalem',
  'Benschop',
  'Bensenville',
  'Bensheim',
  'Benson',
  'Benthuizen',
  'Bentivoglio',
  'Bentleigh',
  'Bentleigh East',
  'Bentley',
  'Bentleyville',
  'Bento Gonçalves',
  'Benton',
  'Benton City',
  'Benton Harbor',
  'Bentong',
  'Bentonville',
  'Bentota',
  'Bentwisch',
  'Benxi',
  'Benzonia',
  'Beppu',
  'Beranang',
  'Beranga',
  'Berango',
  'Berantevilla',
  'Beratzhausen',
  'Berazategui',
  'Berceni',
  'Berchem',
  'Berchem-sainte-agathe',
  'Berchtesgaden',
  'Berck',
  'Berdyansk',
  'Berdychiv',
  'Berea',
  'Berekuso',
  'Beresfield',
  'Beresford',
  'Berg',
  'Berg Am See',
  'Berga',
  'Bergamo',
  'Berge',
  'Bergeforsen',
  'Bergeijk',
  'Bergen',
  'Bergen Auf Rügen',
  'Bergen Op Zoom',
  'Bergen-enkheim',
  'Bergenfield',
  'Bergentheim',
  'Bergerac',
  'Berges',
  'Bergères-lès-vertus',
  'Berghaupten',
  'Berghausen',
  'Bergheim',
  'Berghem',
  'Bergholz',
  'Bergisch Gladbach',
  'Bergkamen',
  'Bergkirchen',
  'Bergle',
  'Bergneustadt',
  'Bergondo',
  'Bergrheinfeld',
  'Bergs',
  'Bergschenhoek',
  'Bergsjö',
  'Bergstraße',
  'Bergues',
  'Bergum',
  'Bergvik',
  'Bergweiler',
  'Berhampore',
  'Berhampur',
  'Beriáin',
  'Berikon',
  'Beringe',
  'Beringen',
  'Berisso',
  'Berkel',
  'Berkeley',
  'Berkeley Heights',
  'Berkeley Springs',
  'Berkeley Vale',
  'Berkenthin',
  'Berkhamsted',
  'Berkley',
  'Berkshire',
  'Berkswell',
  'Berl',
  'Berlaar',
  'Berlare',
  'Berlicum',
  'Berlin',
  'Berlin Heights',
  'Berlin-adlershof',
  'Berlin-baumschulenweg',
  'Berlin-biesdorf',
  'Berlin-friedrichsfelde',
  'Berlin-friedrichshain',
  'Berlin-hohenschönhausen',
  'Berloz',
  'Bermatingen',
  'Bermeo',
  'Bermondsey',
  'Bermúdez',
  'Bern',
  'Bernal',
  'Bernalda',
  'Bernalillo',
  'Bernard',
  'Bernardston',
  'Bernardsville',
  'Bernau',
  'Bernau Am Chiemsee',
  'Bernay',
  'Bernbeuren',
  'Bernburg',
  'Berndorf Bei Salzburg',
  'Berndroth',
  'Berne',
  'Berneck',
  'Bernex',
  'Bernie',
  'Bernier',
  'Bernin',
  'Bernkastel-kues',
  'Bernolákovo',
  'Bernried',
  'Bernsbach',
  'Bernsdorf',
  'Bernsen',
  'Bernstadt',
  'Bernville',
  'Bernwiller',
  'Berod',
  'Beromunster',
  'Beroun',
  "Berre-l'etang",
  'Berrechid',
  'Berri',
  'Berriac',
  'Berrien Springs',
  'Berrima',
  'Berrioplano',
  'Berriozar',
  'Berry',
  'Berry Mills',
  'Berryville',
  'Berschis',
  'Berschweiler',
  'Bersenbrück',
  'Bersée',
  'Bertem',
  'Berthierville',
  'Berthold',
  'Berthoud',
  'Bertinoro',
  'Bertram',
  'Bertrange',
  'Bertrix',
  'Berwick',
  'Berwick-upon-tweed',
  'Berwyn',
  'Besalú',
  'Besana In Brianza',
  'Besançon',
  'Besano',
  'Bescanó',
  'Bescot',
  'Besenello',
  'Besenova',
  'Besigheim',
  'Besikduzu',
  'Besiktas',
  'Bessbrook',
  'Besse-sur-issole',
  'Bessemer',
  'Bessemer City',
  'Best',
  'Bestensee',
  'Bestovje',
  'Bestwig',
  'Bet Dagan',
  "Bet Gamli'el",
  'Bet Haemek',
  'Bet Halevi',
  'Bet Hashmonay',
  'Bet Herut',
  'Bet Shemesh',
  'Bet Yehoshua',
  'Bet Yizhaq',
  'Bet Zayit',
  'Bet Zera',
  'Betanzos',
  'Bethalto',
  'Bethany',
  'Bethany Beach',
  'Bethel',
  'Bethel Park',
  'Bethel Springs',
  'Bethesda',
  'Bethlehem',
  'Bethnal Green',
  'Bethpage',
  'Betim',
  'Betley',
  'Betschdorf',
  'Bettange',
  'Bettembourg',
  'Bettendorf',
  'Bettiah',
  'Bettlach',
  'Betton',
  'Bettringen',
  'Bettsville',
  'Bettystown',
  'Betul',
  'Betzdorf',
  'Betzigau',
  'Beuningen',
  'Beuren',
  'Beusichem',
  'Beuvry',
  'Bevagna',
  'Bevaix',
  'Bevendale',
  'Beveren',
  'Beverley',
  'Beverlo',
  'Beverly',
  'Beverly Hills',
  'Bevern',
  'Beverstedt',
  'Beverungen',
  'Beverwijk',
  'Bewdley',
  'Bex',
  'Bexbach',
  'Bexhill',
  'Bexley',
  'Bexleyheath',
  'Beychac-et-caillau',
  'Beykoz',
  'Beyne-heusay',
  'Beynes',
  'Beynost',
  'Beyoglu',
  'Beyrouth',
  'Beysehir',
  'Bezannes',
  'Bezaumont',
  'Bezi',
  'Bezons',
  'Bègles',
  'Bécancour',
  'Bécsi út',
  'Bédarieux',
  'Bédarrides',
  'Bégin',
  'Béja',
  'Béjar',
  'Békés',
  'Békéscsaba',
  'Bélesta-en-lauragais',
  'Bénin',
  'Béreldange',
  'Bérriz',
  'Bérulle',
  'Bétera',
  'Béthemont-la-forêt',
  'Bétheny',
  'Béthune',
  'Béville-le-comte',
  'Bézéril',
  'Béziers',
  'Bhadohi',
  'Bhadra',
  'Bhadravati',
  'Bhadreswar',
  'Bhagalpur',
  'Bhaktapur',
  'Bhamdoûn',
  'Bhandara',
  'Bhandup',
  'Bharat',
  'Bharatpur',
  'Bharuch',
  'Bhat',
  'Bhatinda',
  'Bhatkal',
  'Bhavnagar',
  'Bhawanipatna',
  'Bhayandar',
  'Bhilai',
  'Bhilwara',
  'Bhimber',
  'Bhiwandi',
  'Bhopal',
  'Bhor',
  'Bhubaneshwar',
  'Bhubaneswar',
  'Bhuj',
  'Bhusawal',
  'Biala',
  'Biala Podlaska',
  'Biale Blota',
  'Bialogard',
  'Bialystok',
  'Bianconese',
  'Biandrate',
  'Biandronno',
  'Biarritz',
  'Biars-sur-cère',
  'Biasca',
  'Biassono',
  'Biatorbágy',
  'Bibbiano',
  'Bibbiena',
  'Biberach',
  'Biberach An Der Riß',
  'Biberbach',
  'Biberen',
  'Biberist',
  'Bibra',
  'Biburg',
  'Bicester',
  'Bichelsee',
  'Bicheng',
  'Bichl',
  'Bicholim',
  'Bickenbach',
  'Bickendorf',
  'Bicske',
  'Bicton',
  'Bidar',
  'Bidart',
  'Biddeford',
  'Biddeford Pool',
  'Biddenden',
  'Biddinghuizen',
  'Bideford',
  'Bidford-on-avon',
  'Bidston',
  'Bieber',
  'Biebesheim',
  'Biedenkopf',
  'Biederitz',
  'Biel',
  'Bielany Wroclawskie',
  'Bielawa',
  'Bielefeld',
  'Biella',
  'Bielsk Podlaski',
  'Bielsko',
  'Bielsko-biala',
  'Bienne',
  'Bienne-lez-happart',
  'Bientina',
  'Bierges',
  'Bierne',
  'Bierun',
  'Biesenthal',
  'Biessenhofen',
  'Bietigheim',
  'Bietigheim-bissingen',
  'Biezenmortel',
  'Bière',
  'Bièvre',
  'Bièvres',
  'Biên Hòa',
  'Big Bear City',
  'Big Bear Lake',
  'Big Bend',
  'Big Lake',
  'Big Pine',
  'Big Pine Key',
  'Big Prairie',
  'Big Rapids',
  'Big Rock',
  'Big Sandy',
  'Big Sky',
  'Big Spring',
  'Big Stone Gap',
  'Biganos',
  'Bigastro',
  'Bigelow',
  'Bigfork',
  'Biggar',
  'Biggleswade',
  'Biglen',
  'Biglerville',
  'Bignan',
  'Biguaçu',
  'Biguglia',
  'Bihac',
  'Bihar',
  'Bihorel',
  'Bijainagar',
  'Bijapur',
  'Bijiang',
  'Bijilo',
  'Bikaner',
  'Bila Tserkva',
  'Bilaspur',
  'Bilbao',
  'Bilbrough',
  'Bildechingen',
  'Bilecik',
  'Bilhac',
  'Bilimora',
  'Bilina',
  'Bilinga',
  'Billancourt',
  'Billdal',
  'Billeberga',
  'Billerbeck',
  'Billerica',
  'Billericay',
  'Billère',
  'Billigheim',
  'Billingham',
  'Billings',
  'Billingshausen',
  'Billingshurst',
  'Billinudgel',
  'Billum',
  'Billund',
  'Billy-berclau',
  'Billy-montigny',
  'Bilovec',
  'Bilovice',
  'Bilovice Nad Svitavou',
  'Biloxi',
  'Bilozerka',
  'Bilston',
  'Bilten',
  'Bilthoven',
  'Bily Kostel Nad Nisou',
  'Bilzen',
  'Binau',
  'Binbrook',
  'Binche',
  'Bincombe',
  'Bindlach',
  'Binéfar',
  'Binfield',
  'Bingen',
  'Bingen Am Rhein',
  'Bingham',
  'Bingham Lake',
  'Binghamton',
  'Bingley',
  'Binjiang',
  'Binningen',
  'Binondo',
  'Binsted',
  'Binswangen',
  'Bintaro',
  'Binton',
  'Bintulu',
  'Binyamina',
  'Binzen',
  'Binzhou',
  'Biñan',
  'Bioggio',
  'Biograd Na Moru',
  'Biot',
  'Bioussac',
  'Bippen',
  'Biratnagar',
  'Birch',
  'Birchington',
  'Birchrunville',
  'Bird In Hand',
  'Bird Island',
  'Birdham',
  'Birdhill',
  'Birdlip',
  'Birdsboro',
  'Birdwell',
  'Biri',
  'Birigui',
  'Birjand',
  'Birkach',
  'Birkat As Sab`',
  'Birkby',
  'Birkdale',
  'Birkeland',
  'Birkenau',
  'Birkenfeld',
  'Birkenhead',
  'Birkenshaw',
  'Birkenwerder',
  'Birkerød',
  'Birkirkara',
  'Birlik',
  'Birmensdorf',
  'Birmenstorf',
  'Birmingham',
  'Birnamwood',
  'Birnbach',
  'Biron',
  'Birr',
  'Birrwil',
  'Birsfelden',
  'Birstall',
  'Birstein',
  'Birtley',
  'Birtouta',
  'Bisamberg',
  'Bisbee',
  'Biscarri',
  'Biscarrosse',
  'Bischheim',
  'Bischoffen',
  'Bischoffsheim',
  'Bischofsheim',
  'Bischofshofen',
  'Bischofswerda',
  'Bischofswiesen',
  'Bischwiller',
  'Biscoe',
  'Bishkek',
  'Bishoftu',
  'Bishop',
  'Bishop Auckland',
  'Bishop Wilton',
  'Bishopbriggs',
  'Bishops Castle',
  'Bishops Cleeve',
  'Bishops Frome',
  'Bishops Stortford',
  'Bishops Strotford',
  'Bishops Tachbrook',
  'Bishops Waltham',
  'Bishopthorpe',
  'Bishopville',
  'Bisingen',
  'Biskupice',
  'Biskupice Oloboczne',
  'Bismarck',
  'Bispingen',
  'Bissegem',
  'Bissegg',
  'Bissendorf',
  'Bissingen',
  'Bissingen An Der Teck',
  'Bistagno',
  'Bistrita',
  'Bitburg',
  'Bitche',
  'Bitola',
  'Bitonto',
  'Bitsaron',
  'Bitterfeld',
  'Bitung',
  'Biviers',
  'Bixby',
  'Biysk',
  'Bizau',
  'Bizerte',
  'Biziat',
  'Bjärred',
  'Bjelovar',
  'Bjerringbro',
  'Bjert',
  'Bjordal',
  'Björdal',
  'Björklinge',
  'Björköby',
  'Björnlunda',
  'Bjuråker',
  'Bjurholm',
  'Bjursås',
  'Bjuv',
  'Bjæverskov',
  'Bjørnevatn',
  'Blaby',
  'Blachownia',
  'Black Canyon City',
  'Black Creek',
  'Black Diamond',
  'Black Eagle',
  'Black Earth',
  'Black Hawk',
  'Black Mountain',
  'Black Notley',
  'Black River',
  'Black River Falls',
  'Black Rock',
  'Blackburn',
  'Blackduck',
  'Blackfalds',
  'Blackfoot',
  'Blackheath',
  'Blacklick',
  'Blacklion',
  'Blackmill',
  'Blackpool',
  'Blackrock',
  'Blacksburg',
  'Blackshear',
  'Blackstone',
  'Blacktown',
  'Blackville',
  'Blackwater',
  'Blackwell',
  'Blackwood',
  'Blacy',
  'Bladel',
  'Bladensburg',
  'Blaenavon',
  'Blagdon',
  'Blagnac',
  'Blagoevgrad',
  'Blaichach',
  'Blaine',
  'Blainville',
  "Blainville-sur-l'eau",
  'Blair',
  'Blair Atholl',
  'Blairgowrie',
  'Blairstown',
  'Blairsville',
  'Blakely',
  'Blakeslee',
  'Blan',
  'Blanca',
  'Blanchard',
  'Blanchardstown',
  'Blanchester',
  'Blanco',
  'Blandain',
  'Blandford',
  'Blandford Forum',
  'Blandon',
  'Blanes',
  'Blangy-sur-bresle',
  'Blankenberge',
  'Blankenburg',
  'Blankenese',
  'Blankenfelde',
  'Blankenloch',
  'Blanquefort',
  'Blansko',
  'Blantyre',
  'Blaricum',
  'Blarney',
  'Blaschette',
  'Blatna',
  'Blatnice',
  'Blaubeuren',
  'Blauen',
  'Blauvelt',
  'Blaydon',
  'Bleckede',
  'Bled',
  'Blegny',
  'Bleicherode',
  'Bleistein',
  'Bleiswijk',
  'Blendecques',
  'Blenheim',
  'Blentarp',
  'Bleskensgraaf',
  'Blessington',
  'Bletchingdon',
  'Bletchingley',
  'Bletchley',
  'Blénod-lès-pont-à-mousson',
  'Blérancourt',
  'Bléré',
  'Blidsberg',
  'Blidworth',
  'Bliedersdorf',
  'Blieskastel',
  'Bliestorf',
  'Blind River',
  'Blindern',
  'Blissfield',
  'Blisworth',
  'Blitar',
  'Blodelsheim',
  'Bloemendaal',
  'Bloemfontein',
  'Blois',
  'Blokker',
  'Blomberg',
  'Blomstermåla',
  'Blonay',
  'Blonie',
  'Bloomburg',
  'Bloomer',
  'Bloomfield',
  'Bloomfield Hills',
  'Blooming Grove',
  'Blooming Prairie',
  'Bloomingburg',
  'Bloomingdale',
  'Bloomington',
  'Bloomsburg',
  'Bloomsbury',
  'Bloomsdale',
  'Bloomville',
  'Blossburg',
  'Blountstown',
  'Blountville',
  'Blovice',
  'Blowatz',
  'Blowing Rock',
  'Bloxham',
  'Bloxwich',
  'Bludenz',
  'Blue Ball',
  'Blue Bell',
  'Blue Creek',
  'Blue Earth',
  'Blue Hill',
  'Blue Island',
  'Blue Jay',
  'Blue Lake',
  'Blue Mounds',
  'Blue Mountain',
  'Blue Mountains Municipality',
  'Blue Point',
  'Blue Ridge',
  'Blue River',
  'Blue Springs',
  'Bluebell',
  'Bluefield',
  'Bluefields',
  'Bluemont',
  'Bluff',
  'Bluff City',
  'Bluff Dale',
  'Bluffs',
  'Bluffton',
  'Bluford',
  'Blumenau',
  'Blunsdon',
  'Blyes',
  'Blyth',
  'Blythe',
  'Blytheville',
  'Blythewood',
  'Bney-brit',
  'Bo',
  "Bo'ness",
  'Boa Vista',
  'Boadilla',
  'Boadilla Del Monte',
  'Boalsburg',
  'Boardman',
  'Boaz',
  'Bobadela',
  'Bobbili',
  'Bobbington',
  'Bobcaygeon',
  'Bobenheim-roxheim',
  'Bobigny',
  'Bobingen',
  'Bobo Dioulasso',
  'Bobowa',
  'Bobruisk',
  'Boca Chica',
  'Boca Grande',
  'Boca Raton',
  'Bochnia',
  'Bocholt',
  'Bochov',
  'Bochum',
  'Bockenheim',
  'Bockhorn',
  'Bod',
  'Bodafors',
  'Boddam',
  'Bodedern',
  'Bodega Bay',
  'Bodegraven',
  'Bodelshausen',
  'Boden',
  'Bodenfelde',
  'Bodenheim',
  'Bodenkirchen',
  'Bodenteich',
  'Bodenwerder',
  'Bodio',
  'Bodiosa',
  'Bodman-ludwigshafen',
  'Bodmin',
  'Bodn',
  'Bodrum',
  'Bodø',
  'Boeblingen',
  'Boechout',
  'Boecillo',
  'Boeicop',
  'Boekel',
  'Boekhoute',
  'Boelenslaan',
  'Boerne',
  'Boersch',
  'Boeschèpe',
  'Boesingheliede',
  'Boé',
  'Bogalusa',
  'Bogangar',
  'Bogart',
  'Bogen',
  'Bogen I Ofoten',
  'Bogenhausen',
  'Bogliasco',
  'Bognor Regis',
  'Bogojina',
  'Bogor',
  'Bogor Barat',
  'Bogota',
  'Bogotá',
  'Bogö',
  'Bogra',
  'Boguchwala',
  'Bogumilowice',
  'Bohdalice',
  'Bohdalov',
  'Bohemia',
  'Bohicon',
  'Bohmte',
  'Bohol',
  'Bohumin',
  'Bohus',
  'Bohuslav',
  'Bohuslavice',
  'Bohusovice Nad Ohri',
  'Boijl',
  'Boiling Springs',
  'Boiro',
  'Bois',
  'Bois-colombes',
  "Bois-d'amont",
  "Bois-d'arcy",
  'Bois-des-filion',
  'Bois-grenier',
  'Bois-guillaume',
  'Bois-le-roi',
  'Boisbriand',
  'Boise',
  'Boisney',
  'Boisset-lès-montrond',
  'Boissevain',
  'Boissiere Village',
  'Boissière',
  'Boissy',
  "Boissy-l'aillerie",
  'Boissy-le-sec',
  'Boissy-saint-léger',
  'Boituva',
  'Boizenburg',
  'Bojanowo',
  'Bojnice',
  'Bojnicky',
  'Bojongsoang',
  'Bokaro',
  'Bokeelia',
  'Boksburg',
  'Bolano',
  'Bolatice',
  'Boldekow',
  'Boldon',
  'Bolgatanga',
  'Bolhrad',
  'Boliden',
  'Bolinas',
  'Bolingbrook',
  'Boliqueime',
  'Bolivar',
  'Bolivia',
  'Bolívar',
  'Bollate',
  'Bollebygd',
  'Bollendorf',
  'Bollène',
  'Bolligen',
  'Bollington',
  'Bollnäs',
  'Bollschweil',
  'Bollullos De La Mitación',
  'Bollullos Par Del Condado',
  'Bollwerk',
  'Bollwiller',
  'Bolney',
  'Bologna',
  'Bolognano',
  'Bolpur',
  'Bolsover',
  'Bolsward',
  'Bolszewo',
  'Bolton',
  'Bolton Upon Dearne',
  'Bolulla',
  'Bolungarvík',
  'Boluo',
  'Bolzano',
  'Bom Jardim',
  'Bom Jardim De Goiás',
  'Bom Jesus Da Lapa',
  'Bomaderry',
  'Bomal',
  'Bombarral',
  'Bombay',
  'Bombay Bandra',
  'Bombay Girgaon',
  'Bombay Santa Cruz',
  'Bombinhas',
  'Bomlitz',
  'Bommel',
  'Bomont',
  'Bompietro',
  'Bomporto',
  'Bon Air',
  'Bon Aqua',
  'Bon-encontre',
  'Bonaduz',
  'Bonanza',
  'Bonate Di Sopra',
  'Bonaventure',
  'Boncelles',
  'Boncourt',
  'Bondi',
  'Bondorf',
  'Bondoufle',
  'Bondues',
  'Bondville',
  'Bondy',
  'Bonefeld',
  'Bonfol',
  'Bongam',
  'Bongaon',
  'Bongaree',
  'Bongcheondong',
  'Bonham',
  'Bonheiden',
  'Bonifacio',
  'Bonifay',
  'Boniswil',
  'Bonita',
  'Bonita Springs',
  'Bonmatí',
  'Bonn',
  'Bonn-lengsdorf',
  'Bonndorf',
  'Bonnemain',
  'Bonner Springs',
  'Bonneuil',
  'Bonneuil-sur-marne',
  'Bonneval',
  'Bonneville',
  'Bonneville-sur-touques',
  'Bonnevoie',
  'Bonney Lake',
  'Bonnières-sur-seine',
  'Bonny',
  'Bonnyrigg',
  'Bonnyville',
  'Bonrepós Y Mirambell',
  'Bonsall',
  'Bonstetten',
  'Bonyhád',
  'Boo',
  'Booischot',
  'Book',
  'Bookham',
  'Boolaroo',
  'Boom',
  'Boondall',
  'Boone',
  'Boones Mill',
  'Booneville',
  'Boonsboro',
  'Boonton',
  'Boonville',
  'Boorowa',
  'Boortmeerbeek',
  'Boos',
  'Boostedt',
  'Booterstown',
  'Boothbay',
  'Boothbay Harbor',
  'Boothby Pagnell',
  'Bootle',
  'Bopfingen',
  'Boppard',
  'Bor',
  'Boran-sur-oise',
  'Borås',
  'Borchel',
  'Borculo',
  'Bordano',
  'Bordeaux',
  'Borden-carleton',
  'Bordentown',
  'Bordesholm',
  'Bordesley',
  'Bordils',
  'Bording',
  'Bordj Bou Arreridj',
  'Boreham',
  'Borehamwood',
  'Borensberg',
  'Boretto',
  'Borgarnes',
  'Borgaro Torinese',
  'Borgen',
  'Borgentreich',
  'Borger',
  'Borgerhout',
  'Borghamn',
  'Borghetto Di Vara',
  'Borgholm',
  'Borgholzhausen',
  'Borgloon',
  'Borgo',
  'Borgo A Mozzano',
  'Borgo San Dalmazzo',
  'Borgo San Lorenzo',
  'Borgo Ticino',
  'Borgo Val Di Taro',
  'Borgo Valsugana',
  'Borgomanero',
  'Borgonovo Val Tidone',
  'Borgonuovo',
  'Borgonyá',
  'Borgoricco',
  'Borgorose',
  'Borgosatollo',
  'Borgund',
  'Borgwedel',
  'Boring',
  'Borio',
  'Borispol',
  'Borivli',
  'Borjas Blancas',
  'Borken',
  'Borkenes',
  'Borki',
  'Borlänge',
  'Bormujos',
  'Born',
  'Borna',
  'Borne',
  'Bornem',
  'Bornheim',
  'Bornova',
  'Borod',
  'Boroko',
  'Boronia',
  'Borough Green',
  'Boroughbridge',
  'Borovlyane',
  'Borralha',
  'Borrby',
  'Borre',
  'Borriana',
  'Borriol',
  'Borroloola',
  'Borsano',
  'Borsbeek',
  'Borsdorf',
  'Borsodnádasd',
  'Borssele',
  'Borstel-hohenraden',
  'Borup',
  'Boryspil',
  'Borzano',
  'Bosa',
  'Bosanska Krupa',
  'Bosaso',
  'Bosch En Duin',
  'Bosco Marengo',
  'Boscobel',
  'Boscombe',
  'Bosham',
  'Bosingen',
  'Bosisio Parini',
  'Boskoop',
  'Boskovice',
  'Bosmat Tivon',
  'Bosques',
  'Bossier City',
  'Bosta',
  'Bostan',
  'Bostanj',
  'Bostic',
  'Boston',
  'Boston Spa',
  'Botafogo',
  'Botany',
  'Bothell',
  'Boticas',
  'Botkins',
  'Botley',
  'Botosani',
  'Bottendorf',
  'Bottens',
  'Bottenwil',
  'Bottesford',
  'Bottighofen',
  'Bottineau',
  'Bottisham',
  'Bottmingen',
  'Bottrop',
  'Botucatu',
  'Bouafles',
  'Bouaye',
  'Bouc-bel-air',
  'Bouchemaine',
  'Boucherville',
  'Boudevilliers',
  'Boudry',
  'Boué',
  'Bouffémont',
  'Boufféré',
  'Bouge',
  'Boughton',
  'Bougival',
  'Bouguenais',
  'Bouillargues',
  'Boulazac',
  'Boulder',
  'Boulder City',
  'Boulder Creek',
  'Bouldercombe',
  'Boulevard',
  'Bouliac',
  'Boulogne',
  'Boulogne-billancourt',
  'Boulogne-sur-mer',
  'Boult',
  'Bound Brook',
  'Bountiful',
  'Bourbon',
  'Bourbonnais',
  'Bourdeaux',
  'Bourg',
  'Bourg-argental',
  'Bourg-de-péage',
  'Bourg-de-thizy',
  'Bourg-en-bresse',
  'Bourg-la-reine',
  'Bourg-lès-valence',
  'Bourg-saint-maurice',
  'Bourges',
  'Bourgneuf-en-retz',
  'Bourgoin',
  'Bourgthéroulde',
  'Bourguignon',
  'Bourlers',
  'Bourn',
  'Bourne',
  'Bourne End',
  'Bournemouth',
  'Bournezeau',
  'Bournville',
  'Bourton',
  'Bourton On The Water',
  'Bous',
  'Bousbecque',
  'Bousies',
  'Bousse',
  'Boutenac',
  'Boutersem',
  'Bouthéon',
  'Bouvignies',
  'Bouville',
  'Bouzigues',
  'Bovenden',
  'Bovenkarspel',
  'Boves',
  'Bovey Tracey',
  'Bovezzo',
  'Bovingdon',
  'Bovisio Masciago',
  'Bow',
  'Bowdoin',
  'Bowdoinham',
  'Bowdon',
  'Bowen',
  'Bowers',
  'Bowers Gifford',
  'Bowerston',
  'Bowie',
  'Bowling Green',
  'Bowman',
  'Bowmansville',
  'Bowmanville',
  'Bowral',
  'Box Elder',
  'Box Hill',
  'Box Hill South',
  'Boxberg',
  'Boxborough',
  'Boxford',
  'Boxholm',
  'Boxing',
  'Boxley',
  'Boxmeer',
  'Boxtel',
  'Boxworth',
  'Boyacá',
  'Boyarka',
  'Boyce',
  'Boyceville',
  'Boyds',
  'Boyertown',
  'Boyes Hot Springs',
  'Boyle',
  'Boylston',
  'Boyne City',
  'Boyne Falls',
  'Boynton Beach',
  'Boz',
  'Bozeat',
  'Bozejov',
  'Bozel',
  'Bozeman',
  'Bozen',
  'Bozhou',
  'Bozouls',
  'Bozrah',
  'Bozum',
  'Bozzolo',
  'Bócsa',
  'Böblingen',
  'Böchingen',
  'Böhl-iggelheim',
  'Böhmenkirch',
  'Bönebüttel',
  'Bönen',
  'Bönnigheim',
  'Bönningstedt',
  'Börnsen',
  'Börwang',
  'Börßum',
  'Bösensell',
  'Bösingen',
  'Böthel',
  'Böttstein',
  'Bötzingen',
  'Braak',
  'Brabant',
  'Brabant-putte',
  'Brabrand',
  'Bracciano',
  'Bracebridge',
  'Braceville',
  'Brachbach',
  'Brackel',
  'Brackenheim',
  'Brackettville',
  'Brackley',
  'Bracknell',
  'Brackney',
  'Braço Do Norte',
  'Braddock',
  'Bradenton',
  'Bradenton Beach',
  'Bradford',
  'Bradford-on-avon',
  'Bradirn',
  'Bradley',
  'Bradmore',
  'Bradworthy',
  'Braemar',
  'Braeside',
  'Braga',
  'Bragadiru',
  'Bragança',
  'Bragança Paulista',
  'Braham',
  'Brahmapur',
  'Braidwood',
  'Braila',
  'Brainard',
  "Braine-l'alleud",
  'Braine-le-château',
  'Braine-le-comte',
  'Brainerd',
  'Brains',
  'Braintree',
  'Brak Et-tel',
  'Brakel',
  'Brakpan',
  'Bramhall',
  'Bramham',
  'Bramley',
  'Bramming',
  'Bramois',
  'Brampton',
  'Bramsche',
  'Branchport',
  'Branchville',
  'Brand',
  'Brand-erbisdorf',
  'Brande',
  'Brandenburg',
  'Brandesburton',
  'Brandon',
  'Brandys Nad Labem',
  'Brandys Nad Labem-stara Boleslav',
  'Brandywine',
  'Brandýs Nad Labem',
  'Branesti',
  'Branford',
  'Braniewo',
  'Branik',
  'Brannenburg',
  'Branson',
  'Branston',
  'Brantevik',
  'Brantford',
  'Braselton',
  'Brashear',
  'Brasil',
  'Brasilândia',
  'Brasiléia',
  'Brasilia',
  'Brasília',
  'Brasov',
  'Brasschaat',
  'Brasted',
  'Brasy',
  'Bratislava',
  'Brattleboro',
  'Brattvåg',
  'Braubach',
  'Braunau',
  'Braunau Am Inn',
  'Braunfels',
  'Braunichswalde',
  'Braunsbedra',
  'Braunschweig',
  'Braunshausen',
  'Braunston',
  'Braunstone',
  'Braunton',
  'Brawley',
  'Bray',
  'Bray-dunes',
  'Bray-en-val',
  'Bray-sur-seine',
  'Bray-sur-somme',
  'Braz',
  'Brazey-en-plaine',
  'Brazi',
  'Brazil',
  'Brazilia',
  'Brazoria',
  'Brazzaville',
  'Bräcke',
  'Bräkne-hoby',
  'Brämhult',
  'Brännland',
  'Bräunlingen',
  'Bre Sopra Lugano',
  'Brea',
  'Brea De Aragón',
  'Breakish',
  'Breamore',
  'Breaux Bridge',
  'Brebbia',
  'Brechin',
  'Brecht',
  'Breckenridge',
  'Breckerfeld',
  'Brecksville',
  'Breclav',
  'Brecon',
  'Breda',
  'Bredaryd',
  'Bredbury',
  'Breddorf',
  'Brede',
  'Bredebro',
  'Bredene',
  'Bree',
  'Breedenbroek',
  'Breedon On The Hill',
  'Breese',
  'Breezand',
  'Breezy Point',
  'Bregana',
  'Breganze',
  'Bregenz',
  'Breidenbach',
  'Breinigsville',
  'Breisach',
  'Breisach Am Rhein',
  'Breitenbach',
  'Breitenberg',
  'Breitenfurt',
  'Breitenworbis',
  'Breitscheid',
  'Breitungen',
  'Brekkestø',
  'Breklum',
  'Breme',
  'Bremen',
  'Bremen-vegesack',
  'Bremerhaven',
  'Bremerton',
  'Bremervörde',
  'Bremgarten',
  'Bremke',
  'Bremnes',
  'Bremond',
  'Brendola',
  'Brenham',
  'Brenna',
  'Brennberg',
  'Brennero',
  'Brennilis',
  'Breno',
  'Brensbach',
  'Brentford',
  'Brentwood',
  'Brentwood Bay',
  'Brenz',
  'Brenzikofen',
  'Brescello',
  'Brescia',
  'Breskens',
  'Bresles',
  'Bressanone',
  'Bressanvido',
  'Bresse-sur-grosne',
  'Bresso',
  'Bresson',
  'Bressuire',
  'Brest',
  'Bretagne',
  'Bretenières',
  'Breteuil',
  'Bretigny',
  'Bretnig',
  'Bretoncelles',
  'Bretten',
  'Bretteville',
  'Bretteville-sur-odon',
  'Bretton',
  'Bretx',
  'Bretzenheim',
  'Bretzfeld',
  'Bretzwil',
  'Breugel',
  'Breuil-le-sec',
  'Breukelen',
  'Breukeleveen',
  'Breuna',
  'Brevard',
  'Brevik',
  'Brevnov',
  'Brewer',
  'Brewerton',
  'Brewood',
  'Brewster',
  'Brewton',
  'Breyell',
  'Brezice',
  'Brezno',
  'Brezova',
  'Brezová Pod Bradlom',
  'Brezovica',
  'Brezovica Pri Ljubljani',
  'Brezovice',
  'Bréal-sous-montfort',
  'Brébières',
  'Bréhan-loudéac',
  'Brétigny-sur-orge',
  'Bréviandes',
  'Bréziers',
  'Brian Head',
  'Briarcliff Manor',
  'Bribie',
  'Bribir',
  'Brick',
  'Bridel',
  'Bridge',
  'Bridge City',
  'Bridge Of Weir',
  'Bridgehampton',
  'Bridgend',
  'Bridgenorth',
  'Bridgeport',
  'Bridgeton',
  'Bridgetown',
  'Bridgeview',
  'Bridgeville',
  'Bridgewater',
  'Bridgham',
  'Bridgman',
  'Bridgnorth',
  'Bridgton',
  'Bridgwater',
  'Bridlicna',
  'Bridlington',
  'Bridport',
  'Brie-comte-robert',
  'Briedel',
  'Brielle',
  'Briennon',
  'Brienz',
  'Brierfield',
  'Brierley Hill',
  'Brieselang',
  'Brieskow-finkenheerd',
  'Brié-et-angonnes',
  'Brig',
  'Brigantine',
  'Brigg',
  'Brigham City',
  'Brighouse',
  'Bright',
  'Brighton',
  'Brighton East',
  'Brighton-le-sands',
  'Brightwalton',
  'Brightwater',
  'Brightwaters',
  'Brignais',
  'Brignano',
  "Brignano Gera D'adda",
  'Brignoles',
  'Brihuega',
  'Brilliant',
  'Brillion',
  'Brillon',
  'Brilon',
  'Brimfield',
  'Brimhall',
  'Brimley',
  'Brimpton',
  'Brindas',
  'Brindisi',
  'Brinkley',
  'Brinklow',
  'Brinkworth',
  'Brinon-sur-sauldre',
  'Brion',
  'Brioude',
  'Brisbane',
  'Brislington',
  'Brissac',
  'Bristol',
  'Bristow',
  'Britford',
  'Brits',
  'Brittany',
  'Britz',
  'Brive',
  'Brive-la-gaillarde',
  'Brives-charensac',
  'Briviesca',
  'Brivio',
  'Brixham',
  'Brixton',
  'Brixworth',
  'Brize Norton',
  'Brno',
  'Brno-kralovo',
  'Bro',
  'Broadbeach',
  'Broadford',
  'Broadlands',
  'Broadmeadows',
  'Broadstairs',
  'Broadstone',
  'Broadview',
  'Broadview Heights',
  'Broadwater',
  'Broadway',
  'Broadwell',
  'Broager',
  'Broakulla',
  'Broby',
  'Broc',
  'Broceni',
  'Brockenhurst',
  'Brockhagen',
  'Brockhampton',
  'Brockport',
  'Brockton',
  'Brockum',
  'Brockville',
  'Brockworth',
  'Broddetorp',
  'Brodek U Prerova',
  'Brodenbach',
  'Broderstorf',
  'Brodhead',
  'Brodick',
  'Brodowski',
  'Brodósqui',
  'Broechem',
  'Broek Op Langedijk',
  'Brognard',
  'Brokaw',
  'Brokdorf',
  'Broken Arrow',
  'Broken Hill',
  'Brolo',
  'Bromborough',
  'Bromley',
  'Bromma',
  'Bromont',
  'Bromölla',
  'Bromsberrow',
  'Bromsgrove',
  'Bromyard',
  'Bron',
  'Broni',
  'Bronkhorst',
  'Bronneger',
  'Bronschhofen',
  'Bronson',
  'Bronte',
  'Bronx',
  'Bronxville',
  'Brook Park',
  'Brookeville',
  'Brookfield',
  'Brookhaven',
  'Brookings',
  'Brooklandville',
  'Brookline',
  'Brookline Station',
  'Brooklyn',
  'Brooklyn Park',
  'Brookneal',
  'Brooks',
  'Brookshire',
  'Brookston',
  'Brooksville',
  'Brooktondale',
  'Brookvale',
  'Brookville',
  'Brookwood',
  'Broomall',
  'Broome',
  'Broomfield',
  'Brossard',
  'Brotas De Macaúbas',
  'Brottby',
  'Brotterode',
  'Brouch',
  'Brough',
  'Broughshane',
  'Broughton',
  'Broughton Vale',
  'Broughty Ferry',
  'Broumov',
  'Broussard',
  'Brovary',
  'Brovst',
  'Brownfield',
  'Brownhills',
  'Browning',
  'Browns Bay',
  'Browns Mills',
  'Browns Plains',
  'Browns Summit',
  'Brownsboro',
  'Brownsburg',
  'Brownstown',
  'Brownsville',
  'Brownton',
  'Brownville',
  'Brownwood',
  'Broxbourne',
  'Broxburn',
  'Bruailles',
  'Bruay',
  "Bruay-sur-l'escaut",
  'Bruce',
  'Bruceton Mills',
  'Bruceville',
  'Bruch',
  'Bruchertseifen',
  'Bruchhausen-vilsen',
  'Bruchköbel',
  'Bruchmühlbach-miesau',
  'Bruchsal',
  'Bruck An Der Mur',
  'Bruck In Der Oberpfalz',
  'Bruckberg',
  'Bruckmühl',
  'Bruehl',
  'Bruère-allichamps',
  'Brugelette',
  'Bruges',
  'Brugg',
  'Brugge',
  'Brugherio',
  'Brugnera',
  'Bruguières',
  'Bruinisse',
  'Bruksvallarna',
  'Brumath',
  'Brummen',
  'Brumov',
  'Brumunddal',
  'Brundall',
  'Brundby',
  'Brunehaut',
  'Brunei',
  'Brunelles',
  'Brunete',
  'Brunico',
  'Bruning',
  'Brunn Am Gebirge',
  'Brunnadern',
  'Brunnen',
  'Brunnthal',
  'Bruno',
  'Brunoy',
  'Brunsbüttel',
  'Brunssum',
  'Brunstatt',
  'Brunswick',
  'Brunswick East',
  'Brunswick West',
  'Bruntal',
  'Bruntinge',
  'Brusasco',
  'Brush',
  'Brusperk',
  'Brusque',
  'Brussegem',
  'Brussel',
  'Brussels',
  'Bruton',
  'Bruttisellen',
  'Bruyelle',
  'Bruyères',
  'Bruyères-et-montbérault',
  'Bruyères-le-châtel',
  'Bruz',
  'Bruzaholm',
  'Brûlon',
  'Brücken',
  'Brügg',
  'Brüggen',
  'Brühl',
  'Brütten',
  'Brüttisellen',
  'Bry-sur-marne',
  'Bryan',
  'Bryans Road',
  'Bryansk',
  'Bryanston',
  'Bryant',
  'Bryant Pond',
  'Bryn Athyn',
  'Bryn Mawr',
  'Bryne',
  'Brynmawr',
  'Brynmenyn',
  'Bryson',
  'Bryson City',
  'Brzeg Dolny',
  'Brzesko',
  'Brzeznica',
  'Brzotín',
  'Brzozowa',
  'Brædstrup',
  'Brøndby Strand',
  'Brøndbyvester',
  'Brønderslev',
  'Brønnøysund',
  'Brønshøj',
  'Brørup',
  'Bua',
  'Bubendorf',
  'Bubenec',
  'Bubenheim',
  'Bubenreuth',
  'Bubikon',
  'Buc',
  'Buca',
  'Bucany',
  'Bucaramanga',
  'Bucarest',
  'Bucciano',
  'Bucelas',
  'Buch',
  'Bucha',
  'Buchanan',
  'Bucharest',
  'Buchbach',
  'Buchelay',
  'Buchen',
  'Buchenau',
  'Buchenbach',
  'Buchenberg',
  'Bucheon',
  'Buchholz',
  'Buchholz In Der Nordheide',
  'Buchloe',
  'Buchlovice',
  'Buchrain',
  'Buchs',
  'Buchschwabach',
  'Buck Hill Falls',
  'Buckenhof',
  'Buckeye',
  'Buckeystown',
  'Buckfastleigh',
  'Buckfield',
  'Buckhannon',
  'Buckhead',
  'Buckhurst Hill',
  'Buckie',
  'Buckingham',
  'Buckland',
  'Buckland Brewer',
  'Buckley',
  'Bucknell',
  'Buckner',
  'Bucks',
  'Bucksburn',
  'Bucksport',
  'Buctouche',
  'Bucuresci',
  'Bucurestii Noi',
  'Bucyrus',
  'Buda',
  'Budajeno',
  'Budakalász',
  'Budakeszi',
  'Budaörs',
  'Budapest',
  'Budca',
  'Budd Lake',
  'Bude',
  'Budejovice',
  'Budel',
  'Budenheim',
  'Buderim',
  'Budinscina',
  'Budleigh Salterton',
  'Budmerice',
  'Budrio',
  'Budyne Nad Ohri',
  'Buea',
  'Bueil',
  'Buellas',
  'Buellton',
  'Buelna',
  'Buena',
  'Buena Park',
  'Buena Vista',
  'Buenaventura',
  'Buenavista',
  'Buenavista Del Norte',
  'Buendía',
  'Bueng Kum',
  'Buenos Aires',
  'Bueu',
  'Buffalo',
  'Buffalo Grove',
  'Buffalo Lake',
  'Buford',
  'Buftea',
  'Bugarra',
  'Bugbrooke',
  'Buggenhout',
  'Buggiano',
  'Buggingen',
  'Buhari',
  'Buhl',
  'Buhl-lorraine',
  'Buhlen',
  'Buhler',
  'Buies Creek',
  'Builth Wells',
  'Buitenpost',
  'Buizingen',
  'Bujumbura',
  'Bukan',
  'Bukarest',
  'Bukavu',
  'Buke',
  'Bukit Merah',
  'Bukit Mertajam',
  'Bukit Pasir',
  'Bukit Rambai',
  'Bukit Tengah',
  'Bulacan',
  'Bulandshahr',
  'Bulawayo',
  'Bulford',
  'Bulgaria',
  'Bulgnéville',
  'Bulimba',
  'Bull Creek',
  'Bullay',
  'Bulle',
  'Bulleen',
  'Bullhead City',
  'Bullingen',
  'Bullion',
  'Bully',
  'Bulverde',
  'Bulwell',
  'Buna',
  'Bunbury',
  'Bunclody',
  'Buncrana',
  'Bundaberg',
  'Bundangdong',
  'Bundanoon',
  'Bunde',
  'Bundeena',
  'Bundella',
  'Bunderhee',
  'Bundi',
  'Bundoora',
  'Bungay',
  'Bungoma',
  'Buninyong',
  'Bunkeflostrand',
  'Bunker Hill',
  'Bunkie',
  'Bunnan',
  'Bunnell',
  'Bunnik',
  'Bunschoten',
  'Buntingford',
  'Buñol',
  'Buochs',
  'Buon Me Thuot',
  'Buonas',
  'Bupyong',
  'Buraidah',
  'Buraydah',
  'Burbach',
  'Burbage',
  'Burbank',
  'Burdwan',
  'Bure',
  'Bureå',
  'Burela',
  'Buren',
  'Bures',
  'Burford',
  'Burg',
  'Burg Auf Fehmarn',
  'Burg Bei Magdeburg',
  'Burgaltendorf',
  'Burgas',
  'Burgau',
  'Burgdorf',
  'Burgebrach',
  'Burgess',
  'Burgess Hill',
  'Burgfried',
  'Burgh',
  'Burgh Le Marsh',
  'Burghaslach',
  'Burghaun',
  'Burghausen',
  'Burghclere',
  'Burgheim',
  'Burghfield',
  'Burgkirchen',
  'Burgkirchen An Der Alz',
  'Burgkunstadt',
  'Burglengenfeld',
  'Burgoberbach',
  'Burgos',
  'Burgpreppach',
  'Burgrieden',
  'Burgschwalbach',
  'Burgsponheim',
  'Burgstetten',
  'Burgthann',
  'Burgwedel',
  'Buri',
  'Burjasot',
  'Burkau',
  'Burke',
  'Burkeville',
  'Burkhardtsdorf',
  'Burla',
  'Burlada',
  'Burladingen',
  'Burleigh',
  'Burleigh Heads',
  'Burleson',
  'Burley',
  'Burlingame',
  'Burlington',
  'Burnaby',
  'Burnaston',
  'Burnet',
  'Burnham',
  'Burnham On Crouch',
  'Burnham-on-sea',
  'Burnhaupt-le-haut',
  'Burnie',
  'Burnley',
  'Burnopfield',
  'Burns',
  'Burns Flat',
  'Burns Lake',
  'Burnside',
  'Burnsville',
  'Burnt Hills',
  'Burntisland',
  'Burntwood',
  'Burolo',
  'Burpengary',
  'Burriana',
  'Burringham',
  'Burry Port',
  'Bursa',
  'Burscheid',
  'Burscough',
  'Burshtyn',
  'Bursins',
  'Bursledon',
  'Burslem',
  'Burst',
  'Burt',
  'Burtenbach',
  'Burtnieki',
  'Burton',
  'Burton Joyce',
  'Burton Upon Trent',
  'Burton-on-trent',
  'Burtonsville',
  'Burtonwood',
  'Burträsk',
  'Burwell',
  'Burwood',
  'Burwood East',
  'Bury',
  'Bury Saint Edmunds',
  'Busalla',
  'Busan',
  'Busenbach',
  'Bushehr',
  'Bushey',
  'Busheyheath',
  'Bushkill',
  'Bushmills',
  'Bushnell',
  'Bushton',
  'Busia',
  'Busigny',
  'Busk',
  'Buskerud',
  'Busovice',
  'Busselton',
  'Busserach',
  'Bussey',
  'Bussigny',
  'Bussolengo',
  'Bussum',
  'Busswil',
  'Bussy-saint-georges',
  'Bussy-saint-martin',
  'Bustan Hagalil',
  'Bustehrad',
  'Busto Arsizio',
  'Bustos',
  'Butcher Burn',
  'Butetown',
  'Butleigh',
  'Butler',
  'Butner',
  'Buttapietra',
  'Butte',
  'Buttenheim',
  'Buttenwiesen',
  'Butterfield',
  'Buttershaw',
  'Butterworth',
  'Buttigliera Alta',
  'Buttisholz',
  'Buttonwillow',
  'Buttrio',
  'Buttstädt',
  'Buttwil',
  'Butwal',
  'Butzbach',
  'Buvrinnes',
  'Buxheim',
  'Buxin',
  'Buxtehude',
  'Buxton',
  'Buzançais',
  'Buzau',
  'Buzet',
  'Buzignargues',
  'Buzin',
  'Buzzards Bay',
  'Büchen',
  'Büchenau',
  'Büchenbach',
  'Büchenbeuren',
  'Bückeburg',
  'Büdelsdorf',
  'Büdingen',
  'Bühl',
  'Bühlau',
  'Bühler',
  'Bühlertann',
  'Bühlerzell',
  'Bülach',
  'Bünde',
  'Büren',
  'Büren An Der Aare',
  'Bürglen',
  'Bürmoos',
  'Büron',
  'Bürstadt',
  'Büsserach',
  'Büsum',
  'Bütschwil',
  'Büttelborn',
  'Büttstedt',
  'Bützberg',
  'Büyükçekmece',
  'Büyükdere',
  'Bybjerg',
  'Byculla',
  'Bydgoszcz',
  'Byesville',
  'Byfield',
  'Byfleet',
  'Bygdeå',
  'Bygdsiljum',
  'Bykhov',
  'Bylnice',
  'Bylong',
  'Byram',
  'Byrdstown',
  'Byron',
  'Byron Bay',
  'Byron Center',
  'Bystrany',
  'Bystrice',
  'Bystrice Nad Pernstejnem',
  'Bytca',
  'Bytom',
  'Bzenec',
  'Bzince Pod Javorinou',
  'Bærums Verk',
  'Bø',
  'Bø I Telemark',
  'Bø I Vesteralen',
  'Bømlo',
  'Bønes',
  'Børkop',
  'Ca Del Monte',
  'Ca Tron',
  'Caaporã',
  'Caaschwitz',
  'Caba',
  'Caballito',
  'Cabanas De Viriato',
  'Cabanillas',
  'Cabanillas Del Campo',
  'Cabano',
  'Cabaña De Río Pinto',
  'Cabarete',
  'Cabedelo',
  'Cabestany',
  'Cabezo De Torres',
  'Cabezón De La Sal',
  'Cabildo',
  'Cabin John',
  'Cabinteely',
  'Cable',
  'Cabo Frio',
  'Cabo Ruivo',
  'Cabo San Lucas',
  'Caboolture',
  'Cabot',
  'Cabourg',
  'Cabranes',
  "Cabrieres-d'aigues",
  'Cabrières',
  'Cabriès',
  'Cabrils',
  'Cabris',
  'Cacak',
  'Caccamo',
  'Cacém',
  'Cachan',
  'Cachoeira Do Sul',
  'Cachoeirinha',
  'Cachoeiro De Itapemirim',
  'Cachtice',
  'Cacia',
  'Caciulati',
  'Caçador',
  'Caçapava',
  'Cadca',
  'Caddington',
  'Cadelbosco Di Sopra',
  'Cadelbosco Di Sotto',
  'Cadempino',
  'Caden',
  'Cadenazzo',
  'Cadeo',
  'Caderousse',
  'Caderzone',
  'Cadillac',
  'Cadiz',
  'Cadolzburg',
  'Cadrete',
  'Cadro',
  'Caen',
  'Caerano Di San Marco',
  'Caernarfon',
  'Caerphilly',
  'Caesarea',
  'Caetano',
  'Caeté',
  'Caëstre',
  'Cafelândia',
  'Cagas',
  'Cagayan De Oro',
  'Cagayan De Oro City',
  'Cagliari',
  'Cagnes-sur-mer',
  'Cagny',
  'Caguas',
  'Cahors',
  'Caidian',
  'Caieiras',
  'Cainsville',
  'Cainta',
  'Cairate',
  'Cairndow',
  'Cairns',
  'Cairo',
  'Cairo Montenotte',
  'Cairon',
  'Caissargues',
  'Caistor',
  'Caivano',
  'Cajamar',
  'Cajamarca',
  'Cajarc',
  'Cajati',
  'Cajon',
  'Cakany',
  'Cakovec',
  'Cakovice',
  'Cakung',
  'Cal Nev Ari',
  'Calabar',
  'Calabasas',
  'Calabash',
  'Calabogie',
  'Calabozo',
  'Calaf',
  'Calafell',
  'Calahorra',
  'Calais',
  'Calalzo Di Cadore',
  'Calama',
  'Calamba',
  'Calangianus',
  'Calangute',
  'Calapan',
  'Calarasi',
  'Calasparra',
  'Calatabiano',
  'Calatayud',
  'Calau',
  'Calberlah',
  'Calci',
  'Calcinaia',
  'Calcinate',
  'Calcinato',
  'Calcio',
  'Calcutta',
  'Calcutta Bagh Bazar',
  'Caldas',
  'Caldas Da Rainha',
  'Caldas De Vizela',
  'Calderara Di Reno',
  'Caldes De Montbuy',
  'Caldicot',
  'Caldogno',
  'Caldwell',
  'Caldy',
  'Calebasses',
  'Caledon',
  'Caledonia',
  'Calella',
  'Calenzano',
  'Calera',
  'Calexico',
  'Calgary',
  'Calhoun',
  'Calhoun City',
  'Cali',
  'Calicut',
  'Caliente',
  'Califon',
  'California',
  'California City',
  'Californië',
  'Califórnia',
  'Calignac',
  'Calimesa',
  'Calistoga',
  'Callander',
  'Callanish',
  'Callao',
  'Callery',
  'Calles',
  'Callicoon',
  'Callicoon Center',
  'Callington',
  'Calmar',
  'Calne',
  'Calolziocorte',
  'Calonge',
  'Caloocan',
  'Caloundra',
  'Caltagirone',
  'Caltanissetta',
  'Caltignaga',
  'Calto',
  'Caluire',
  'Caluire-et-cuire',
  'Calumet',
  'Calumet City',
  "Calusco D'adda",
  'Caluso',
  'Calvária De Baixo',
  'Calverley',
  'Calvert',
  'Calverton',
  'Calvi',
  'Calviá',
  'Calvisson',
  'Calvörde',
  'Calw',
  'Calzada',
  'Calzada De Tera',
  'Cam Pha',
  'Cam Ranh',
  'Camacá',
  'Camaçari',
  'Camaiore',
  'Camanche',
  'Camano Island',
  'Camaragibe',
  'Camarate',
  'Camargo',
  'Camarillo',
  'Camarma De Esteruelas',
  'Camas',
  'Camas Valley',
  'Cambados',
  'Camberley',
  'Camberly',
  'Camberwell',
  'Camberwell South',
  'Cambes',
  'Cambé',
  'Cambiago',
  'Cambiano',
  'Cambon',
  'Camboriú',
  'Camborne',
  'Cambra',
  'Cambrai',
  'Cambre',
  'Cambria',
  'Cambridge',
  'Cambridge Bay',
  'Cambridge City',
  'Cambrils',
  'Cambs',
  'Cambuí',
  'Camburzano',
  'Cambus',
  'Cambuslang',
  'Camden',
  'Camden Town',
  'Camden Wyoming',
  'Camdenton',
  'Camelford',
  'Camerano',
  'Camerata Picena',
  'Cameri',
  'Camerino',
  'Cameron',
  'Cameron Highlands',
  'Camilla',
  'Camillus',
  'Caminha',
  'Camino',
  'Camisano',
  'Camisano Vicentino',
  'Cammeray',
  'Camobi',
  'Camorino',
  'Camp Hill',
  'Camp Lejeune',
  'Camp Mountain',
  'Camp Verde',
  'Campagna',
  'Campana',
  'Campanhã',
  'Campbell',
  'Campbell Hall',
  'Campbell Hill',
  'Campbell River',
  'Campbell Town',
  'Campbellfield',
  'Campbellford',
  'Campbellsport',
  'Campbellsville',
  'Campbellton',
  'Campbelltown',
  'Campbelltown South',
  'Campbeltown',
  'Campbon',
  'Campeche',
  'Campello',
  'Camperdown',
  'Campese',
  'Campestre',
  'Campestre Churubusco',
  'Campi Bisenzio',
  'Campi Salentina',
  'Campia Turzi',
  'Campina',
  'Campina Grande',
  'Campinas',
  'Campíns',
  'Campo',
  'Campo Belo',
  'Campo Belo Do Sul',
  'Campo Bom',
  'Campo Grande',
  'Campo Largo',
  'Campo Ligure',
  'Campo Mourão',
  'Campo Quijano',
  'Campo Real',
  'Campo San Martino',
  'Campo Tures',
  'Campo Verde',
  'Campobasso',
  'Campobello',
  'Campochiaro',
  'Campodarsego',
  'Campoformido',
  'Campogalliano',
  'Camponogara',
  'Campos',
  'Campos Dos Goitacazes',
  'Camposampiero',
  'Campton',
  'Camrose',
  'Canaan',
  'Canach',
  'Canacona',
  'Canada',
  'Canadá',
  'Canadian',
  'Canajoharie',
  'Canal Flats',
  'Canal Fulton',
  'Canal Winchester',
  'Canale',
  'Canandaigua',
  'Canas De Senhorim',
  'Canastota',
  'Canavieiras',
  'Canazei',
  'Canberra',
  'Canby',
  'Cancienes',
  'Cancún',
  'Candeias',
  'Candelaria',
  'Candelo',
  'Candiac',
  'Candler',
  'Candolim',
  'Caneças',
  'Canegrate',
  'Canela',
  'Canelas',
  'Canelli',
  'Canet',
  'Canet De Berenguer',
  'Canet De Mar',
  'Caneva',
  'Canéjan',
  'Canfield',
  'Cangas',
  'Canggu',
  'Cangnan',
  'Cangshan',
  'Cangzhou',
  'Canicatti',
  'Canicattì',
  'Canidelo',
  'Canillas De Aceituno',
  'Canim Lake',
  'Canisteo',
  'Cankiri',
  'Canley',
  'Canly',
  'Canmore',
  'Cannanore',
  'Cannara',
  'Cannelton',
  'Cannes',
  'Cannes-Écluse',
  "Canneto Sull'oglio",
  'Canning',
  'Cannington',
  'Cannock',
  'Cannon Falls',
  'Cannonvale',
  'Canoas',
  'Canoga Park',
  'Canohès',
  'Canon City',
  'Canonsburg',
  'Canosa Di Puglia',
  'Canossa',
  'Canovellas',
  'Cantabria',
  'Cantanhede',
  'Cantemir',
  'Cantenay-épinard',
  'Canterbury',
  'Canterbury Station',
  'Canton',
  'Canton Center',
  'Cantonment',
  'Cantoria',
  'Cantu',
  'Canutillo',
  'Canvey',
  'Canyon',
  'Canyon Country',
  'Canyon Lake',
  'Canyonville',
  'Cañón',
  'Caorle',
  'Caozhou',
  'Cap Santé',
  'Cap-rouge',
  'Capaci',
  'Capalaba',
  'Capalbio',
  'Capannori',
  'Caparica',
  'Caparroso',
  'Capay',
  'Capdenac',
  'Cape Canaveral',
  'Cape Charles',
  'Cape Coast',
  'Cape Coral',
  'Cape Elizabeth',
  'Cape Girardeau',
  'Cape May',
  'Cape May Court House',
  'Cape May Point',
  'Cape Neddick',
  'Cape Town',
  'Capel',
  'Capella',
  'Capellades',
  'Capelle',
  'Capelle Aan Den Ijssel',
  'Capellen',
  'Capena',
  'Capenhurst',
  'Capergnanica',
  'Capestang',
  'Capezzana',
  'Capistrano Beach',
  'Capital',
  'Capitol Heights',
  'Capitola',
  "Capo D'orlando",
  'Capodistria',
  'Capolago',
  'Capon Bridge',
  'Capoterra',
  'Cappelle-en-pévèle',
  'Capranica',
  'Caprarola',
  'Capri',
  'Capriago',
  'Caprie',
  'Caprino Veronese',
  'Captain Cook',
  'Captieux',
  'Capua',
  'Capurso',
  'Caputh',
  'Capvern',
  'Carabanchel Alto',
  'Carabaña',
  'Carabayllo',
  'Caracas',
  'Caraman',
  'Carangola',
  'Caransebes',
  'Carapicuíba',
  'Caraquet',
  'Carasco',
  'Carate Brianza',
  'Caratinga',
  'Caravaca',
  'Caravaggio',
  'Caràzinho',
  'Carbajosa De La Sagrada',
  'Carballino',
  'Carballo',
  'Carberry',
  'Carbon-blanc',
  'Carbondale',
  'Carbonera',
  'Carboneras',
  'Carbonia',
  'Carbonne',
  'Carbrook',
  'Carcarana',
  'Carcarès-sainte-croix',
  'Carcassonne',
  'Carcavelos',
  'Cardedeu',
  'Cardeñadijo',
  'Cardiff',
  'Cardiff By The Sea',
  'Cardigan',
  'Cardington',
  'Cardito',
  'Cardona',
  'Cardonald',
  'Cardosas',
  'Cardston',
  'Carefree',
  'Carei',
  'Carencro',
  'Carenno',
  'Carentoir',
  'Caresana',
  'Carey',
  'Carhaix-plouguer',
  'Cariacica',
  'Caribou',
  'Carignan',
  'Carignano',
  'Carina',
  'Caringbah',
  'Cariñena',
  'Carle Place',
  'Carlentini',
  'Carlepont',
  'Carlet',
  'Carleton',
  'Carleton Place',
  'Carlingford',
  'Carlinville',
  'Carlisle',
  'Carlos Casares',
  'Carlotta',
  'Carlow',
  'Carlsbad',
  'Carlstadt',
  'Carlton',
  'Carlton North',
  'Carlton South',
  'Carluke',
  'Carlyle',
  'Carmagnola',
  'Carmarthen',
  'Carmel',
  'Carmel By The Sea',
  'Carmel Valley',
  'Carmen',
  'Carmi',
  'Carmichael',
  'Carmiel',
  'Carmignano',
  'Carmignano Di Brenta',
  'Carmo',
  'Carmo Do Paranaíba',
  'Carmo Do Rio Claro',
  'Carmona',
  'Carnaby',
  'Carnate',
  'Carnation',
  'Carnaxide',
  'Carnegie',
  'Carnelian Bay',
  'Carnesville',
  'Carnforth',
  'Carnide',
  'Carnoustie',
  'Carnwath',
  'Caro',
  'Carol Stream',
  'Carolina',
  'Carolina Beach',
  'Caroline Springs',
  'Carora',
  'Carouge',
  'Carovigno',
  'Carp',
  'Carpenedolo',
  'Carpentersville',
  'Carpentras',
  'Carpi',
  'Carpignano',
  'Carpinteria',
  'Carpiquet',
  'Carquefou',
  'Carral',
  'Carrara',
  'Carrboro',
  'Carrefour',
  'Carregado',
  'Carregal Do Sal',
  'Carreño',
  'Carrick On Shannon',
  'Carrickfergus',
  'Carrickmacross',
  'Carrickmines',
  'Carrickmore',
  'Carrières-sous-poissy',
  'Carrières-sur-seine',
  'Carrigaline',
  'Carrigtohill',
  'Carrington',
  'Carrizo Springs',
  'Carroll',
  'Carrollton',
  'Carros',
  'Carrum Downs',
  'Carry-le-rouet',
  'Carryduff',
  'Carseland',
  'Carshalton',
  'Carsoli',
  'Carson',
  'Carson City',
  'Carstairs',
  'Cartagena',
  'Cartago',
  'Cartaxo',
  'Carter Lake',
  'Carteret',
  'Cartersville',
  'Carterton',
  'Carterville',
  'Cartes',
  'Carthage',
  'Cartier',
  'Cartmel',
  'Cartoceto',
  'Cartuja',
  'Cartwright',
  'Caruaru',
  'Carugate',
  'Caruthers',
  'Caruthersville',
  'Carvalhosa',
  'Carver',
  'Carville',
  'Carvin',
  'Carvoeiro',
  'Cary',
  'Caryville',
  'Casa Bamba',
  'Casa Grande',
  'Casablanca',
  'Casais Da Serra',
  'Casal Da Areia',
  'Casal Velino',
  'Casalbuttano',
  'Casalbuttano Ed Uniti',
  'Casale Monferrato',
  'Casale Sul Sile',
  'Casalecchio Di Reno',
  'Casalgrande',
  'Casalmaggiore',
  'Casalmaiocco',
  'Casalnuovo Di Napoli',
  'Casalromano',
  'Casandrino',
  'Casapulla',
  'Casarano',
  'Casarrubios Del Monte',
  'Casarsa Della Delizia',
  'Casatenovo',
  'Casazza',
  'Cascade',
  'Cascade Locks',
  'Cascadia',
  'Cascais',
  'Cascavel',
  'Cascina',
  'Casco',
  'Caseiros',
  'Caselette',
  'Caselle',
  'Caselle Torinese',
  'Caserío El Telefono',
  'Caserío La Valenciana',
  'Caserío Las Nubes',
  'Caserío San Pablo',
  'Caserío Santa Ana',
  'Caseros',
  'Caserras',
  'Caserta',
  'Caseville',
  'Caseyville',
  'Cashew City',
  'Cashmere',
  'Cashmore',
  'Casier',
  'Casino',
  "Casirate D'adda",
  'Caslano',
  'Caslav',
  'Casola Di Napoli',
  'Casoli',
  'Casorate Primo',
  'Casoria',
  'Caspe',
  'Casper',
  'Cass Lake',
  'Cassano',
  "Cassano D'adda",
  'Cassano Delle Murge',
  'Cassá De La Selva',
  'Casselberry',
  'Casselman',
  'Casselton',
  "Cassina De' Pecchi",
  'Cassington',
  'Cassino',
  'Cassis',
  'Cassolnovo',
  'Cassopolis',
  'Cassowary',
  'Cassville',
  'Castagnola',
  'Castaic',
  'Castalian Springs',
  'Castalla',
  'Castanet',
  'Castanet-tolosan',
  'Castanhal',
  'Castanheira Do Ribatejo',
  'Castano Primo',
  'Castañares De Rioja',
  'Casteau',
  'Casteggio',
  'Castegnato',
  'Castel Bolognese',
  'Castel Di Casio',
  'Castel Goffredo',
  'Castel Guelfo Di Bologna',
  'Castel Maggiore',
  'Castel Mella',
  'Castel Romano',
  'Castel San Giorgio',
  'Castel San Giovanni',
  'Castel San Pietro',
  'Castel San Pietro Terme',
  'Castelculier',
  'Castelfidardo',
  'Castelforte',
  'Castelfranco',
  'Castelfranco Di Sotto',
  'Castelfranco Emilia',
  'Castelfranco Veneto',
  'Castelginest',
  'Castelgomberto',
  "Castell'alfero",
  "Castell'umberto",
  'Castellalto',
  'Castellammare Di Stabia',
  'Castellamonte',
  'Castellana Grotte',
  'Castellanza',
  "Castellar De N'hug",
  'Castellarano',
  'Castellbisbal',
  'Castelldefels',
  'Castelleone',
  'Castelletto',
  'Castelli Calepio',
  'Castellina In Chianti',
  'Castello',
  'Castello Di Annone',
  'Castello Di Godego',
  'Castellolí',
  'Castelló',
  'Castellón',
  'Castellón De Ampurias',
  'Castellón De La Plana',
  'Castellucchio',
  "Castelnau-d'estretefonds",
  'Castelnau-le-lez',
  'Castelnaudary',
  "Castelnovo Ne' Monti",
  'Castelnuovo Del Zappa',
  'Castelnuovo Rangone',
  'Castelo',
  'Castelo Branco',
  'Castelo Do Neiva',
  'Castelplanio',
  'Castelrotto',
  'Castelsarrasin',
  'Castelvetrano',
  'Castelvetro Di Modena',
  'Castenaso',
  'Castenedolo',
  'Casteren',
  'Castiadas',
  'Castiglion Fiorentino',
  'Castiglione Del Lago',
  'Castiglione Delle Stiviere',
  'Castiglione Olona',
  'Castignano',
  'Castine',
  'Castle Ashby',
  'Castle Donington',
  'Castle Donnington',
  'Castle Douglas',
  'Castle Eden',
  'Castle Hayne',
  'Castle Hill',
  'Castle Rock',
  'Castlebar',
  'Castleblayney',
  'Castlecomer',
  'Castlecrag',
  'Castlederg',
  'Castleford',
  'Castlegar',
  'Castleknock',
  'Castlemaine',
  'Castleton',
  'Castleton On Hudson',
  'Castletown',
  'Castlewellan',
  'Castres',
  'Castres-gironde',
  'Castricum',
  'Castries',
  'Castrillo De La Vega',
  'Castro',
  'Castro Valley',
  'Castro-urdiales',
  'Castrocontrigo',
  'Castronno',
  'Castrop-rauxel',
  'Castropol',
  'Castrovillari',
  'Castroville',
  'Cataguases',
  'Catalina',
  'Cataluña',
  'Catamarca',
  'Catanduva',
  'Catania',
  'Catanzaro',
  'Catarroja',
  'Cataumet',
  'Catawba',
  'Catbalogan',
  'Catenay',
  'Caterham',
  'Catharpin',
  'Cathays',
  'Cathedral City',
  'Catherine Hill Bay',
  'Caticlan',
  'Catlett',
  'Catllar',
  'Cato',
  'Catonsville',
  'Catoosa',
  'Catskill',
  'Cattenières',
  'Catterick',
  'Cattolica',
  'Caturtunggal',
  'Cauayan',
  'Caucaia',
  'Caudan',
  'Caudebec-en-caux',
  'Caudebec-lès-elbeuf',
  'Cauldon',
  'Caulfield',
  'Caulfield Junction',
  'Caulfield North',
  'Caulfield South',
  'Caumont-sur-durance',
  'Causeway Bay',
  'Causse-de-la-selle',
  "Cava De' Tirreni",
  'Cavaillon',
  'Cavalese',
  'Cavallermaggiore',
  'Cavallino',
  'Cavalões',
  'Cavan',
  'Cavaria Con Premezzo',
  'Cavarzere',
  'Cavazzana',
  'Cave City',
  'Cave Creek',
  'Cave Hill',
  'Cave Spring',
  'Cavenago Di Brianza',
  'Cavendish',
  'Caversham',
  'Cavezzo',
  'Caviano',
  'Cavite',
  'Cavriago',
  'Cavuslu',
  'Cawang',
  'Caxias',
  'Caxias Do Sul',
  'Cayce',
  'Cayenne',
  'Cayon',
  'Cayucos',
  'Cayuga',
  'Cazadero',
  'Cazenovia',
  'Cazis',
  'Cazouls-lès-béziers',
  'Cazzago San Martino',
  'Cà Mau',
  'Cáceres',
  'Cárdenas',
  'Câmpina',
  'Ceará',
  'Cebin',
  'Cebu',
  'Cebu City',
  'Ceccano',
  'Cechtice',
  'Cecina',
  'Cedar',
  'Cedar Bluff',
  'Cedar City',
  'Cedar Creek',
  'Cedar Falls',
  'Cedar Grove',
  'Cedar Hill',
  'Cedar Key',
  'Cedar Knolls',
  'Cedar Lake',
  'Cedar Mountain',
  'Cedar Park',
  'Cedar Point',
  'Cedar Rapids',
  'Cedar Springs',
  'Cedar Valley',
  'Cedarburg',
  'Cedaredge',
  'Cedarhurst',
  'Cedartown',
  'Cedarville',
  'Cefalu',
  'Cegléd',
  'Celakovice',
  'Celaya',
  'Celbridge',
  'Celebration',
  'Celeirós',
  'Celerina',
  'Celina',
  'Celje',
  'Celle',
  'Celle-lévescault',
  'Celorico De Basto',
  'Celrá',
  'Cenes De La Vega',
  'Cengkareng',
  'Cenicero',
  'Cenon',
  'Centallo',
  'Centennial',
  'Center',
  'Center City',
  'Center Conway',
  'Center Harbor',
  'Center Line',
  'Center Moriches',
  'Center Tuftonboro',
  'Center Valley',
  'Centerbrook',
  'Centerburg',
  'Centereach',
  'Centerport',
  'Centerville',
  'Cento',
  'Central',
  'Central Bedeque',
  'Central City',
  'Central Coast',
  'Central Falls',
  'Central Islip',
  'Central Patricia',
  'Central Point',
  'Central Valley',
  'Central and Western',
  'Centralia',
  'Centre',
  'Centre Hall',
  'Centreville',
  'Centro',
  'Centro Novo',
  'Centurion',
  'Century City',
  'Cepagatti',
  'Cepões',
  'Ceprano',
  'Cerano',
  'Cercado',
  'Cerdañola',
  'Cerea',
  'Ceregnano',
  'Ceres',
  'Ceresara',
  'Ceresco',
  'Cerexhe-heuseux',
  'Cerfontaine',
  'Cergy',
  'Cergy-pontoise',
  'Ceriano Laghetto',
  'Cerignola',
  'Cerizay',
  'Cerknica',
  'Cermenate',
  'Cern',
  'Cernay',
  'Cernex',
  'Cerney',
  'Cernica',
  'Cernier',
  'Cernobbio',
  'Cernosice',
  'Cernusco',
  'Cernusco Sul Naviglio',
  'Cerqueira César',
  'Cerquilho',
  "Cerreto D'esi",
  'Cerreto Guidi',
  'Cerrillos',
  'Cerritos',
  'Cerro Al Volturno',
  'Cerro Azul',
  'Cerro Maggiore',
  'Certaldo',
  'Certines',
  'Certosa Di Pavia',
  'Cervelló',
  'Cervens',
  'Cerveny Kostelec',
  'Cervere',
  'Cervia',
  'Cesano Maderno',
  'Cesena',
  'Cesenatico',
  'Ceska',
  'Ceska Lipa',
  'Ceska Skalice',
  'Ceska Trebova',
  'Ceske Budejovice',
  'Ceske Mezirici',
  'Cesky Brod',
  'Cesky Krumlov',
  'Cesky Tesin',
  'Cesme',
  'Cesson',
  'Cesson-sévigné',
  'Cestas',
  'Cestlice',
  'Cesuras',
  'Ceuta',
  'Ceuti',
  'Ceva',
  'Ceylon',
  'Ceyreste',
  'Ceyzériat',
  'Cébazat',
  'Cépie',
  'Cérans-foulletourte',
  'Céret',
  'César',
  "Ch'arants'avan",
  'Chabanais',
  'Chabeuil',
  'Chacabuco',
  'Chacao',
  'Chacarita',
  'Chachoengsao',
  'Chacras De Coria',
  'Chadbourn',
  'Chaddesden',
  'Chadds Ford',
  'Chadianzi',
  'Chadron',
  'Chadwell',
  'Chadwick',
  'Chagford',
  'Chagrin Falls',
  'Chai Wan',
  'Chaibasa',
  'Chaillac-sur-vienne',
  'Chaingy',
  'Chaiwan',
  'Chakdaha',
  'Chakdara',
  'Chalakudi',
  'Chalandry-élaire',
  'Chalco De Díaz Covarrubias',
  'Chalezeule',
  'Chalfont',
  'Chalfont Saint Giles',
  'Chalfont Saint Peter',
  'Chalford',
  'Chalgrove',
  'Chalk River',
  'Chalkidóna',
  'Chalkída',
  'Challakere',
  'Challans',
  'Challes-les-eaux',
  'Challis',
  'Chalmette',
  'Chalon',
  'Chalon-sur-saône',
  'Chalonnes-sur-loire',
  'Cham',
  'Chamalières',
  'Chamalières-sur-loire',
  'Chamba',
  'Chambersburg',
  'Chambesy',
  'Chambéry',
  'Chambéry-le-vieux',
  'Chambésy',
  'Chamblee',
  'Chambly',
  'Chambon-sur-voueize',
  'Chambourcy',
  'Chambourg-sur-indre',
  'Chambray-lès-tours',
  'Chambry',
  'Chamoli',
  'Chamonix',
  'Chamonix-mont-blanc',
  'Champagne',
  "Champagne-au-mont-d'or",
  'Champagnole',
  'Champaign',
  'Champcevrais',
  'Champcueil',
  'Champdor',
  'Champfleury',
  'Champfromier',
  'Champigneulles',
  'Champigny',
  'Champigny-sur-marne',
  'Champion',
  'Champlain',
  'Champlan',
  'Champlin',
  'Champlitte',
  'Champniers',
  'Champs',
  'Champs-sur-marne',
  'Champtoceaux',
  'Champvent',
  'Chamusca',
  'Chanas',
  'Chancellor',
  'Chanchamayo',
  'Chancheng',
  'Chandigarh',
  'Chanditala',
  'Chandler',
  'Chandler Heights',
  'Chandlers Ford',
  'Chandrapur',
  'Chang Hwa',
  'Changan',
  'Changanacherry',
  'Changchun',
  'Changchun Shi',
  'Changde',
  'Changdu',
  'Change',
  'Change Islands',
  'Changé',
  'Changge',
  'Changhua',
  'Changhuahsien',
  'Changji',
  'Changjiang',
  'Changle',
  'Changning',
  'Changping',
  'Changqing',
  'Changsha',
  'Changshahsien',
  'Changshan',
  'Changshou',
  'Changshu',
  'Changshushi',
  'Changwon',
  'Changxing',
  'Changyang',
  'Changyi',
  'Changyuan',
  'Changzhi',
  'Changzhou',
  'Chanhassen',
  'Chaniá',
  'Chaniers',
  'Channahon',
  'Channelview',
  'Channing',
  'Chanos-curson',
  'Chantada',
  'Chanteloup',
  'Chanteloup-les-vignes',
  'Chantepie',
  'Chantilly',
  'Chantonnay',
  'Chanute',
  'Chanzeaux',
  'Chaohu',
  'Chaoyang',
  'Chaozhou',
  'Chapada Dos Guimarães',
  'Chapecó',
  'Chapeiry',
  'Chapel Allerton',
  'Chapel En Le Frith',
  'Chapel Hill',
  'Chapelhall',
  'Chapelizod',
  'Chapelle-lez-herlaimont',
  'Chapeltown',
  'Chapet',
  'Chapin',
  'Chaplinka',
  'Chapman',
  'Chapman Ranch',
  'Chaponnay',
  'Chaponost',
  'Chappaqua',
  'Chappell Hill',
  'Chappells',
  'Chappes',
  'Chapultepec',
  'Charalá',
  'Charbonnières',
  'Charbonnières-les-bains',
  'Charches',
  'Chard',
  'Chardon',
  'Chardonne',
  'Charenton',
  'Charenton-le-pont',
  'Charing',
  'Charing Cross',
  'Charkhi Dadri',
  'Charlbury',
  'Charleroi',
  'Charles',
  'Charles City',
  'Charles Town',
  'Charlesbourg',
  'Charleston',
  'Charlestown',
  'Charlesworth',
  'Charleval',
  'Charleville',
  'Charleville-Mézières',
  'Charleville-mézières',
  'Charlevoix',
  'Charlo',
  'Charlotte',
  'Charlotte Hall',
  'Charlottenberg',
  'Charlottenburg',
  'Charlottenlund',
  'Charlottenthal',
  'Charlottesville',
  'Charlottetown',
  'Charlton',
  'Charmes',
  'Charmey',
  'Charminster',
  'Charnay-lès-mâcon',
  'Charneca De Caparica',
  'Chars',
  'Chartham',
  'Chartres',
  'Chartres-de-bretagne',
  'Chase',
  'Chaska',
  'Chassagnas',
  'Chasse-sur-rhône',
  'Chassell',
  'Chasseneuil',
  'Chasseneuil-du-poitou',
  'Chassieu',
  'Chastel-sur-murat',
  'Chatan',
  "Chateauneuf-d'ille-et-vilaine",
  'Chatel-saint-denis',
  'Chatelaine',
  'Chatham',
  'Chatillon',
  'Chatom',
  'Chatou',
  'Chatswood',
  'Chatsworth',
  'Chattanooga',
  'Chattarpur',
  'Chatteris',
  'Chatuchak',
  'Chauché',
  'Chauchina',
  'Chaudefontaine',
  'Chauffailles',
  'Chauffours',
  'Chaumesnil',
  'Chaumont',
  'Chaumont-gistoux',
  'Chaumontel',
  'Chauray',
  'Chauvoncourt',
  'Chaux-du-dombief',
  'Chavagne',
  'Chavagnes-en-paillers',
  'Chaval',
  'Chavanay',
  'Chavannes',
  'Chavanod',
  'Chavanoz',
  'Chavenay',
  'Chaves',
  'Chavigny',
  'Chaville',
  'Chavornay',
  'Chawton',
  "Chazay-d'azergues",
  'Chazy',
  'Chánia',
  'Châlette-sur-loing',
  'Châlons-en-champagne',
  'Château Gombert',
  'Château-du-loir',
  'Château-gaillard',
  'Château-gontier',
  'Château-renault',
  'Château-thierry',
  'Châteaubourg',
  'Châteaubriant',
  'Châteaudun',
  'Châteaufort',
  'Châteaugay',
  'Châteaugiron',
  'Châteauguay',
  'Châteaulin',
  'Châteauneuf',
  'Châteauneuf-de-gadagne',
  'Châteauneuf-de-galaure',
  'Châteauneuf-du-rhône',
  'Châteauneuf-en-thymerais',
  'Châteauneuf-grasse',
  'Châteauneuf-lès-martigues',
  'Châteauneuf-sur-cher',
  'Châteaurenard',
  'Châteauroux',
  'Châteauthébaud',
  'Châteauvillain',
  'Châtel-saint-denis',
  'Châtel-saint-germain',
  'Châtelaillon-plage',
  'Châtelaine',
  'Châtelais',
  'Châtelard',
  'Châtelet',
  'Châtelineau',
  'Châtellerault',
  'Châtenay-en-france',
  'Châtenay-malabry',
  'Châtenois',
  'Châtenoy-le-royal',
  'Châtillon',
  'Châtillon-en-vendelais',
  'Châtillon-le-duc',
  'Châtillon-lès-sons',
  'Châtillon-saint-jean',
  'Châtillon-sur-thouet',
  'Châtres-la-forêt',
  'Chã Da Alegria',
  'Cheadle',
  'Cheadle Hulme',
  'Cheam',
  'Cheb',
  'Cheboksary',
  'Cheboygan',
  'Chedaogou',
  'Cheddar',
  'Cheddleton',
  'Cheektowaga',
  'Chef-du-pont',
  'Chehalis',
  'Cheju',
  'Chekhov',
  'Chelan',
  'Chelford',
  'Chellaston',
  'Chelles',
  'Chelm',
  'Chelmondiston',
  'Chelmsford',
  'Chelsea',
  'Chelsea Heights',
  'Cheltenham',
  'Chelun',
  'Chelyabinsk',
  'Chelyabinsk-40',
  'Chemainus',
  'Chemaudin',
  'Chemillé',
  'Chemnitz',
  'Chene-bougeries',
  'Chene-bourg',
  'Cheney',
  'Cheng',
  'Chengalpattu',
  'Chengde',
  'Chengdu',
  'Chengdu Shi',
  'Chenggong',
  'Chengguan',
  'Chenghai',
  'Chengmai',
  'Chengyang',
  'Chenjiang',
  'Chennai',
  'Chennevières-sur-marne',
  'Chenoa',
  'Chenôve',
  'Chenzhou',
  'Cheonan',
  'Cheongcheon',
  'Cheongju',
  'Cheonho',
  'Chepachet',
  'Chepstow',
  'Cheraga',
  'Cheras',
  'Cherasco',
  'Cheraw',
  'Cherbourg',
  'Cherepovets',
  'Cheriton',
  'Cherkassy',
  'Cherkasy',
  'Cherna Gora',
  'Chernihiv',
  'Chernivtsi',
  'Chernobyl',
  'Cherokee',
  'Cherry Hill',
  'Cherry Hills Village',
  'Cherry Point',
  'Cherry Valley',
  'Cherryvale',
  'Cherryville',
  'Chertsey',
  'Cherves',
  'Chesapeake',
  'Chesapeake Beach',
  'Cheseaux',
  'Chesham',
  'Cheshire',
  'Cheshunt',
  'Chesnay',
  'Chesnee',
  'Chessington',
  'Chessy',
  'Chest Springs',
  'Cheste',
  'Chester',
  'Chester Basin',
  'Chester Heights',
  'Chester Springs',
  'Chester-le-street',
  'Chesterfield',
  'Chesterford',
  'Chesterland',
  'Chestermere',
  'Chesters',
  'Chesterton',
  'Chestertown',
  'Chesterville',
  'Chestnut Hill',
  'Chestnut Ridge',
  'Cheswick',
  'Chetek',
  'Chetumal',
  'Cheung Chau',
  'Chevagnes',
  'Cheval-blanc',
  'Chevilly',
  'Chevilly-larue',
  'Chevreuse',
  'Chevrières',
  'Chevry-cossigny',
  'Chevy Chase',
  'Chew Magna',
  'Chewelah',
  'Chewton Mendip',
  'Chexbres',
  'Cheyenne',
  'Cheyney',
  'Chez-le-bart',
  'Chécy',
  'Chénas',
  'Chêne-bougeries',
  'Chêne-bourg',
  'Chênée',
  'Chhatarpur',
  'Chhattarpur',
  'Chhindwara',
  'Chi',
  'Chiai',
  'Chiajna',
  'Chiampo',
  'Chianciano Terme',
  'Chiang Mai',
  'Chiang Rai',
  'Chiapas',
  'Chiarano',
  'Chiaravalle',
  'Chiari',
  'Chiasso',
  'Chiavari',
  'Chiavenna',
  'Chiaying',
  'Chiba',
  'Chiba-shi',
  'Chibi',
  'Chicacao',
  'Chicago',
  'Chicago Heights',
  'Chicago Ridge',
  'Chichester',
  'Chichibu',
  'Chickasha',
  'Chiclana De La Frontera',
  'Chiclayo',
  'Chico',
  'Chicopee',
  'Chicora',
  'Chicoutimi',
  'Chicoutimi-nord',
  'Chidambaram',
  'Chiefland',
  'Chieri',
  'Chiesina Uzzanese',
  'Chieti',
  'Chièsaz',
  'Chièvres',
  'Chifeng',
  'Chifu',
  'Chigasaki',
  'Chignin',
  'Chigwell',
  'Chihuahua',
  'Chikuma',
  'Chikusa',
  'Chikusei',
  'Chilcompton',
  'Chilcoot',
  'Childers',
  'Childress',
  'Childrey',
  'Childwall',
  'Chile',
  'Chile Chico',
  'Chilecito',
  'Chilgrove',
  'Chilhowee',
  'Chilhowie',
  'Chililabombwe',
  'Chillan',
  'Chille',
  'Chillicothe',
  'Chilliwack',
  'Chilly',
  'Chilly-mazarin',
  'Chilmark',
  'Chilpancingo',
  'Chilpancingo De Los Bravos',
  'Chilton',
  'Chilwell',
  'Chilworth',
  'Chimaltenango',
  'Chimay',
  'Chimoio',
  'China',
  'China Grove',
  'China Spring',
  'Chincha Alta',
  'Chinchilla De Monte Aragón',
  'Chinchiwad',
  'Chinchón',
  'Chinchwad',
  'Chingford',
  'Chinle',
  'Chinley',
  'Chinnor',
  'Chino',
  'Chino Hills',
  'Chino Valley',
  'Chioggia',
  'Chipata',
  'Chipiona',
  'Chipley',
  'Chippendale',
  'Chippenham',
  'Chippewa Falls',
  'Chippewa Lake',
  'Chipping Campden',
  'Chipping Norton',
  'Chipping Ongar',
  'Chipping Warden',
  'Chirens',
  'Chirivella',
  'Chirnside',
  'Chironico',
  'Chisago City',
  'Chisinau',
  'Chislehurst',
  'Chisoda',
  'Chiswick',
  'Chita',
  'Chitose',
  'Chitradurga',
  'Chitré',
  'Chittagong',
  'Chittagong Port',
  'Chittaurgarh',
  'Chittenango',
  'Chittenden',
  'Chitterne',
  'Chittlehampton',
  'Chittoor',
  'Chiusdino',
  'Chiva',
  'Chivasso',
  'Chizhou',
  'Chía',
  'Chíos',
  'Chobham',
  'Chocen',
  'Choctaw',
  'Chodavaram',
  'Chodov',
  'Chofu',
  'Choisy-en-brie',
  'Choisy-le-roi',
  'Chojnice',
  'Cholapuram',
  'Cholderton',
  'Cholet',
  'Cholula',
  'Choluteca',
  'Chom Thong',
  'Chomérac',
  'Chomutov',
  'Chonburi',
  'Chongming',
  'Chongnoong Dong',
  'Chongqing',
  'Chongqing Shi',
  'Chorley',
  'Chorleywood',
  'Chorrillos',
  'Chortkiv',
  'Chorvátský Grob',
  'Chorzow',
  'Chorzów',
  'Chotebor',
  'Chotoviny',
  'Chotusice',
  'Chowchilla',
  'Chrastany',
  'Chrastava',
  'Chrenovec',
  'Christchurch',
  'Christiana',
  'Christiansburg',
  'Christiansfeld',
  'Christleton',
  'Chrlice',
  'Chropyne',
  'Chrudim',
  'Chryston',
  'Chrzanow',
  'Chuansha',
  'Chuburna',
  'Chudleigh',
  'Chugiak',
  'Chukou',
  'Chula Vista',
  'Chullora',
  'Chunan',
  'Chuncheon',
  'Chunchon',
  'Chung',
  'Chungam',
  'Chungbuk',
  'Chungcheng',
  'Chungho',
  'Chunghua',
  'Chungju',
  'Chungli',
  'Chungnam',
  'Chuo',
  'Chupei',
  'Chur',
  'Church Hill',
  'Church Point',
  'Church Rock',
  'Church Stretton',
  'Churchill',
  'Churchstow',
  'Churchton',
  'Churchtown',
  'Churchville',
  'Churra',
  'Churriana De La Vega',
  'Churubusco',
  'Chuxiong',
  'Chuzhou',
  'Chvalkovice',
  'Chvalovice',
  'Chvaly',
  'Chwaszczyno',
  'Chyby',
  'Chylice',
  'Chyne',
  'Chynorany',
  'Ciampino',
  'Cianjur',
  'Cianorte',
  'Cibarusah',
  'Cibitung',
  'Cibolo',
  'Cibubur',
  'Cicala',
  'Cicarovce',
  'Cicero',
  'Cidade Dos Meninos',
  'Cidade Nova',
  'Cidahu',
  'Ciempozuelos',
  'Cieszyn',
  'Cieza',
  'Cigales',
  'Cigli',
  'Cijuela',
  'Cikarang',
  'Cilacap',
  'Cilandak',
  'Cilavegna',
  'Ciledug',
  'Cilegon',
  'Cileungsi',
  'Cimadolmo',
  'Cimahi',
  'Cimanes Del Tejar',
  'Cimanggis',
  'Cincinnati',
  'Ciney',
  'Cinisello Balsamo',
  'Cinnaminson',
  'Cintré',
  'Cintruénigo',
  'Ciorogarla',
  'Ciputat',
  'Cirák',
  'Circle Pines',
  'Circleville',
  'Cirebon',
  'Cirencester',
  'Cisano Bergamasco',
  'Cisano Sul Neva',
  'Cisco',
  'Cisliano',
  'Cison Di Valmarino',
  'Cissna Park',
  'Cisterna Di Latina',
  'Cistérniga',
  'Citeureup',
  'Citra',
  'Citraindah',
  'Citronelle',
  'Citrus Heights',
  'Citta Nuova',
  "Citta Sant'angelo",
  'Cittadella',
  'Città Della Pieve',
  'Città Di Castello',
  'City Bell',
  'City Of Greater Wollongong',
  'City Of Industry',
  'City Of London',
  'City Of Nassau',
  'City Of Sydney',
  'Ciudad',
  'Ciudad Cochabamba',
  'Ciudad De Aguascalientes',
  'Ciudad De Dolores Hidalgo',
  'Ciudad De Guatemala',
  'Ciudad De León',
  'Ciudad De Malabo',
  'Ciudad De Panamá',
  'Ciudad Del Carmen',
  'Ciudad Evita',
  'Ciudad Granja',
  'Ciudad Guayana',
  'Ciudad Hidalgo',
  'Ciudad Juárez',
  'Ciudad Lineal',
  'Ciudad López Mateos',
  'Ciudad Madero',
  'Ciudad Obregón',
  'Ciudad Queretaro',
  'Ciudad Rama',
  'Ciudad Real',
  'Ciudad Santiago',
  'Ciudad Satélite',
  'Ciudad Temuco',
  'Ciudad Valles',
  'Ciudad Victoria',
  'Ciudad Vieja',
  'Ciudadela',
  'Civens',
  'Civezzano',
  'Cividale Del Friuli',
  'Cividate Al Piano',
  'Cividate Camuno',
  'Civitanova Marche',
  'Civitanova Marche Alta',
  'Civitavecchia',
  'Civitella Marittima',
  'Civrieux',
  "Civrieux-d'azergues",
  'Cixi',
  'Ciyu',
  'Cizay-la-madeleine',
  'Cizur Mayor',
  'Cizur Menor',
  'Clachan',
  'Clackamas',
  'Clackmannan',
  'Clacton-on-sea',
  'Clairac',
  'Clairmont',
  'Claix',
  'Clam Lake',
  'Clamart',
  'Clamecy',
  'Clanton',
  'Clapham',
  'Clapiers',
  'Clara',
  'Clara City',
  'Clare',
  'Claregalway',
  'Claremont',
  'Claremore',
  'Claremorris',
  'Clarence',
  'Clarence Center',
  'Clarendon',
  'Clarendon Hills',
  'Clarendon Park',
  'Clarenville',
  'Clarinda',
  'Clarion',
  'Clark',
  'Clarke City',
  'Clarkesville',
  'Clarks Summit',
  'Clarksburg',
  'Clarksdale',
  'Clarkson',
  'Clarkston',
  'Clarksville',
  'Clarkton',
  'Clarkville',
  'Clausthal-zellerfeld',
  'Claverack',
  'Clavier',
  'Clawson',
  'Claxton',
  'Clay Center',
  'Clay City',
  'Clay Cross',
  'Claybrooke',
  'Claybrooke Parva',
  'Claydon',
  'Claye-souilly',
  'Claygate',
  'Claymont',
  'Claypool',
  'Claysburg',
  'Clayton',
  'Clayton North',
  'Clayton South',
  'Cláudio',
  'Cle Elum',
  'Clear Lake',
  'Clear Lake Shores',
  'Clearfield',
  'Clearlake',
  'Clearlake Oaks',
  'Clearwater',
  'Clearwater Beach',
  'Cleator Moor',
  'Cleburne',
  'Cleckheaton',
  'Cleebronn',
  'Cleethorpes',
  'Cleeve Prior',
  'Clementon',
  'Clements',
  'Clemmons',
  'Clemson',
  'Clermont',
  'Clermont Herault',
  'Clermont-ferrand',
  "Clermont-l'herault",
  'Clerval',
  'Clervaux',
  'Cles',
  'Clevedon',
  'Cleveland',
  'Cleveleys',
  'Clever',
  'Cleves',
  'Clewiston',
  'Cléder',
  'Clémensat',
  'Cléon',
  'Clérieux',
  'Clichy',
  'Cliddesden',
  'Clifford',
  'Cliffside Park',
  'Cliffwood',
  'Clifton',
  'Clifton Forge',
  'Clifton Heights',
  'Clifton Hill',
  'Clifton Park',
  'Clifton Springs',
  'Clifton Upon Teme',
  'Climax',
  'Clinton',
  'Clinton Township',
  'Clintonville',
  'Clio',
  'Clipper Mills',
  'Clitheroe',
  'Clive',
  'Clogher',
  'Cloghran',
  'Clohars-carnoët',
  'Clonakilty',
  'Clonard',
  'Clondalkin',
  'Clonee',
  'Clonmel',
  'Clontarf',
  'Cloppenburg',
  'Cloquet',
  'Closter',
  'Cloud',
  'Clovelly',
  'Clovenfords',
  'Clover',
  'Cloverdale',
  'Clovis',
  'Cluj',
  'Cluj-napoca',
  'Clun',
  'Cluses',
  'Clusone',
  'Clute',
  'Clutton',
  'Clydach Vale',
  'Clyde',
  'Clydebank',
  'Clymer',
  'Clyst Honiton',
  'Clyst Saint Mary',
  'Coacalco',
  'Coachella',
  'Coahuila',
  'Coal City',
  'Coal Township',
  'Coalinga',
  'Coalville',
  'Coaña',
  'Coarsegold',
  'Coatbridge',
  'Coates',
  'Coatesville',
  'Coaticook',
  'Coatzacoalcos',
  'Cobán',
  'Cobb',
  'Cobeja',
  'Cobh',
  'Cobham',
  'Cobisa',
  'Cobleskill',
  'Cobourg',
  'Coburg',
  'Coccaglio',
  'Cocconato',
  'Cocentaina',
  'Cochabamba',
  'Cochin',
  'Cochise',
  'Cochiti Pueblo',
  'Cochran',
  'Cochrane',
  'Cockburn Town',
  'Cockerham',
  'Cockermouth',
  'Cockeysville',
  'Cockfosters',
  'Cocoa',
  'Cocoa Beach',
  'Cocody',
  'Coconut Creek',
  'Coden',
  'Codevilla',
  'Codlea',
  'Codognan',
  'Codogno',
  'Codolet',
  'Codrington',
  'Codroipo',
  'Codsall',
  'Cody',
  'Coesfeld',
  'Coeur D Alene',
  "Coeur D'alene",
  'Coevorden',
  'Coëx',
  'Coffeyville',
  'Coffin Bay',
  'Coffs Harbour',
  'Coggeshall',
  'Cognac',
  'Cognelée',
  'Cogny',
  'Cogolin',
  'Cogollo Del Cengio',
  'Cogon',
  'Cogorno',
  'Cohade',
  'Cohasset',
  'Cohoes',
  'Cohuna',
  'Coignières',
  'Coimbatore',
  'Coimbra',
  'Coimbrã',
  'Coimères',
  'Coings',
  'Cointrin',
  'Coín',
  'Cokato',
  'Cokercreek',
  'Col',
  'Colaba',
  'Colayrac-saint-cirq',
  'Colbert',
  'Colborne',
  'Colbun',
  'Colby',
  'Colchester',
  'Cold Lake',
  'Cold Spring',
  'Cold Spring Harbor',
  'Coldwater',
  'Coleambally',
  'Colebrook',
  'Coleford',
  'Coleman',
  'Coleraine',
  'Colere',
  'Colerne',
  'Coleshill',
  'Colfax',
  'Colfontaine',
  'Colijnsplaat',
  'Colima',
  'Colina',
  'Collado Mediano',
  'Collado-villalba',
  'Collaroy',
  'Collaroy Beach',
  'Collbató',
  'Colle',
  "Colle Di Val D'elsa",
  "Colle Val D'elsa",
  'Collecchio',
  'Collecorvino',
  'Colleferro',
  'College Park',
  'College Place',
  'College Point',
  'College Station',
  'Collegedale',
  'Collegeville',
  'Collegno',
  'Collendoorn',
  'Collettsville',
  'Colleyville',
  'Collégien',
  'Collierville',
  'Collingswood',
  'Collingwood',
  'Collins',
  'Collinstown',
  'Collinsville',
  'Collombey',
  "Collonges-au-mont-d'or",
  'Colman',
  'Colmar',
  'Colmberg',
  'Colmenar Viejo',
  'Colnbrook',
  'Colne',
  'Colney Heath',
  'Cologne',
  'Cologno',
  'Cologno Al Serio',
  'Cologno Monzese',
  'Coloma',
  'Colombelles',
  'Colombes',
  'Colombé',
  'Colombia',
  'Colombiano',
  'Colombier',
  'Colombo',
  'Colomiers',
  'Colonia',
  'Colonia Delta',
  'Colonia Escalón',
  'Colonia San José',
  'Colonial Beach',
  'Colonial Heights',
  'Colonnella',
  'Colora',
  'Colorado',
  'Colorado City',
  'Colorado Springs',
  'Colón',
  'Colômbia',
  'Colti',
  'Colton',
  'Colts Neck',
  'Columbia',
  'Columbia City',
  'Columbia Cross Roads',
  'Columbia Falls',
  'Columbia Station',
  'Columbiana',
  'Columbus',
  'Columbus Grove',
  'Colusa',
  'Colville',
  'Colwall',
  'Colwell',
  'Colwich',
  'Colwick',
  'Colwyn Bay',
  'Comacchio',
  'Comanche',
  'Comano',
  'Comber',
  'Comberton',
  'Combined Locks',
  'Comblain-au-pont',
  'Combloux',
  'Combres',
  'Combrit',
  'Combs',
  'Combs-la-ville',
  'Come By Chance',
  'Comerio',
  'Comércio',
  'Comfort',
  'Comilla',
  'Comines',
  'Comiso',
  'Comitancillo',
  'Comitán De Domínguez',
  'Commack',
  'Commelle-vernay',
  'Commerce',
  'Commerce City',
  'Commerce Township',
  'Commercial Point',
  'Commercy',
  'Como',
  'Comodoro Rivadavia',
  'Comox',
  'Compiègne',
  'Compostela',
  'Compton',
  'Compton Dundon',
  'Comstock Park',
  'Comun Nuovo',
  'Cona',
  'Conakry',
  'Concarneau',
  'Conceicão',
  'Conceição',
  'Concepcion',
  'Concepcion Del Uruguay',
  'Concepción De Buenos Aires',
  'Conception Junction',
  'Conchal',
  'Concord',
  'Concordia',
  'Concordia Sagittaria',
  'Concordia Sulla Secchia',
  'Concordville',
  'Concorezzo',
  'Concón',
  'Concórdia',
  'Conde',
  'Condé-northen',
  'Condé-sur-noireau',
  'Condren',
  'Conegliano',
  'Conel',
  'Confignon',
  'Conflans-sainte-honorine',
  'Congers',
  'Congerville',
  'Congéniès',
  'Conghua',
  'Congleton',
  'Congrier',
  'Conifer',
  'Conil De La Frontera',
  'Conjunto Seis De Novembro',
  'Conkal',
  'Conklin',
  'Conley',
  'Conliège',
  'Conlig',
  'Connahs Quay',
  'Connaught Place',
  'Connells Point',
  'Connellsville',
  'Connellys Springs',
  'Connersville',
  'Connewarre',
  'Conondale',
  'Conover',
  'Conrad',
  'Conroe',
  'Conselheiro Lafaiete',
  'Conselice',
  'Consell',
  'Conselve',
  'Consett',
  'Conshohocken',
  'Consolação',
  'Constance',
  'Constant Spring',
  'Constanta',
  'Constantine',
  'Constantí',
  'Constância',
  'Consuma',
  'Contagem',
  'Contamine-sur-arve',
  'Contarina',
  'Contern',
  'Conteville-lès-boulogne',
  'Conthey',
  'Contoocook',
  'Contrada',
  'Contrecoeur',
  'Convent',
  'Conversano',
  'Converse',
  'Convoy',
  'Conway',
  'Conwy',
  'Conyers',
  'Conyngham',
  'Cooch Behar',
  'Cooee',
  'Coogee',
  'Cook',
  'Cookeville',
  'Cookham',
  'Cooksbridge',
  'Cooksburg',
  'Cookshire',
  'Cookstown',
  'Cooksville',
  'Coolabah',
  'Coolangatta',
  'Coole',
  'Coolgreany',
  'Coolidge',
  'Coolock',
  'Coolum',
  'Coolum Beach',
  'Cooma',
  'Coomera',
  'Coon Rapids',
  'Coon Valley',
  'Coonoor',
  'Cooper',
  'Coopersburg',
  'Cooperstown',
  'Coopersville',
  'Cooranbong',
  'Coorparoo',
  'Coos Bay',
  'Cootamundra',
  'Copacabana',
  'Copaceni',
  'Copake',
  'Copdock',
  'Copenhagen',
  'Copford',
  'Copiague',
  'Copiapo',
  'Coplay',
  'Copley',
  'Copparo',
  'Coppell',
  'Copper City',
  'Copper Cliff',
  'Copper Mountain',
  'Copperas Cove',
  'Copperhill',
  'Copperopolis',
  'Coppet',
  'Coppull',
  'Copsa Mica',
  'Coquainvilliers',
  'Coquimbo',
  'Coquitlam',
  'Coral Gables',
  'Coral Springs',
  'Coralville',
  'Coram',
  'Coraopolis',
  'Corato',
  'Corbas',
  'Corbeanca',
  'Corbeil-essonnes',
  'Corbera',
  'Corbera De Llobregat',
  'Corbett',
  'Corbetta',
  'Corbin',
  'Corbridge',
  'Corby',
  'Corcelles',
  'Corciano',
  'Corcoran',
  'Cordele',
  'Cordemais',
  'Cordenons',
  'Cordignano',
  'Cordoba',
  'Cordova',
  'Cordovilla',
  'Coreggio',
  'Coreglia Antelminelli',
  'Corella',
  'Corenc',
  'Corfe Mullen',
  'Corfu',
  'Corgemont',
  'Coria',
  'Coria Del Río',
  'Coriano',
  'Corinne',
  'Corinth',
  'Corinthian',
  'Corio',
  'Cork',
  'Cormano',
  'Cormeilles-en-parisis',
  'Cormenon',
  'Corminboeuf',
  'Cormons',
  'Cormontreuil',
  'Cornaredo',
  'Cornaux',
  'Cornebarrieu',
  'Cornedo Vicentino',
  'Cornelia',
  'Cornelius',
  'Cornellá',
  'Cornellá De Llobregat',
  'Cornellá De Terri',
  'Corner Brook',
  'Corné',
  'Cornélio Procópio',
  'Cornillon-confoux',
  'Corning',
  'Cornish',
  'Cornu',
  'Cornuda',
  'Cornville',
  'Cornwall',
  'Cornwall Bridge',
  'Cornwell',
  'Cornwood',
  'Coromandel',
  'Corona',
  'Corona Del Mar',
  'Coronado',
  'Coronation',
  'Coronel Fabriciano',
  'Coroneo',
  'Corps',
  'Corpus Christi',
  'Corrales',
  'Correggio',
  'Corregidora',
  'Correzzola',
  'Corridonia',
  'Corrientes',
  'Corroios',
  'Corronsac',
  'Corroy-le-grand',
  'Corró De Vall',
  'Corry',
  'Corryong',
  'Corryton',
  'Corscia',
  'Corse',
  'Corsham',
  'Corsicana',
  'Corsico',
  'Corsley',
  'Cortaccia',
  'Cortaillod',
  'Cortaro',
  'Cortazar',
  'Corte Franca',
  'Corte Madera',
  'Cortegana',
  'Cortemaggiore',
  'Cortes',
  'Cortez',
  'Cortil-wodon',
  'Cortland',
  'Cortlandt Manor',
  'Cortona',
  'Coruche',
  'Corumbá',
  'Corunna',
  'Corvallis',
  'Corveissiat',
  'Corvera',
  'Corwen',
  'Corydon',
  'Cos Cob',
  'Coseley',
  'Cosenza',
  'Cosham',
  'Coshocton',
  'Coslada',
  'Cosmopolis',
  'Cosmópolis',
  'Cosne-sur-loire',
  'Cossall',
  'Cossato',
  'Cossonay-ville',
  'Costa',
  'Costa De Caparica',
  'Costa Di Mezzate',
  'Costa Mesa',
  'Costa Rica',
  'Costa Volpino',
  'Costabissara',
  'Costinesti',
  'Cota',
  'Cotati',
  'Cotham',
  'Cothen',
  'Cotia',
  'Cotonou',
  'Cotopaxi',
  'Cottage Grove',
  'Cottbus',
  'Cottenham',
  'Cotter',
  'Cottered',
  'Cottesloe',
  'Cottingham',
  'Cottleville',
  'Cotton Center',
  'Cottondale',
  'Cottonport',
  'Cottontown',
  'Cottonvale',
  'Cottonwood',
  'Cotuit',
  'Cotulla',
  'Coubon',
  'Coubron',
  'Coudekerque-branche',
  'Coudersport',
  'Couëron',
  'Couffouleux',
  'Couillet',
  'Coulaines',
  'Coulmer',
  'Coulounieix-chamiers',
  'Coulsdon',
  'Counce',
  'Council Bluffs',
  'Council Grove',
  'Countryside',
  'Coupar Angus',
  'Coupeville',
  'Coupland',
  'Cour-cheverny',
  'Courbevoie',
  'Courceboeufs',
  'Courcelles',
  'Courcelles-le-comte',
  'Courchevel',
  'Courcouronnes',
  'Courgenay',
  "Cournon-d'auvergne",
  'Cournonsec',
  'Cournonterral',
  'Courpière',
  'Courroux',
  'Cours',
  'Court-saint-étienne',
  'Courtauly',
  'Courtedoux',
  'Courtelary',
  'Courtenay',
  'Courthézon',
  'Courtice',
  'Coushatta',
  'Couternon',
  'Couthuin',
  'Couture-saint-germain',
  'Coutures',
  'Couva',
  'Couvet',
  'Couvin',
  'Couze-et-saint-front',
  'Couzeix',
  "Couzon-au-mont-d'or",
  'Covasna',
  'Cove',
  'Cove City',
  'Covelo',
  'Covent Garden',
  'Coventry',
  'Covesville',
  'Covilhã',
  'Covina',
  'Covington',
  'Cowbridge',
  'Cowden',
  'Cowdenbeath',
  'Cowell',
  'Cowes',
  'Coweta',
  'Cowfold',
  'Cowichan Bay',
  'Cowlesville',
  'Cowley',
  'Cowlinge',
  'Cowra',
  "Cox's Bazar",
  'Coxsackie',
  'Coyhaique',
  'Coyoacan',
  'Coyoacán',
  'Coyol',
  'Cozad',
  'Cozumel',
  'Cómpeta',
  'Córdoba',
  'Côte-saint-luc',
  'Cölbe',
  'Cracovia',
  'Cracow',
  'Cradley',
  'Crafts',
  'Craftsbury Common',
  'Craig',
  'Craigavon',
  'Craigellachie',
  'Craigieburn',
  'Crailsheim',
  'Craiova',
  'Crakehall',
  'Cramerton',
  'Cramlington',
  'Cran-gévrier',
  'Cranberry',
  'Cranberry Twp',
  'Cranbourne',
  'Cranbrook',
  'Cranbury',
  'Crane Hill',
  'Cranfield',
  'Cranford',
  'Cranleigh',
  'Crans',
  'Cranston',
  'Cranwell',
  'Craponne',
  'Crato',
  'Craughwell',
  'Craven Arms',
  'Cravinhos',
  'Crawford',
  'Crawfordsburn',
  'Crawfordsville',
  'Crawfordville',
  'Crawley',
  'Crayford',
  'Cream Ridge',
  'Creazzo',
  'Crediton',
  'Creede',
  'Creedmoor',
  'Creek',
  'Creekside',
  'Creil',
  'Creissan',
  'Creissels',
  'Crema',
  'Cremlingen',
  'Cremona',
  'Cremorne',
  'Creola',
  'Cresbard',
  'Crescent',
  'Crescent City',
  'Crescent Head',
  'Crespiá',
  'Crespina',
  'Crespo',
  'Cresserons',
  'Cressier',
  'Cresskill',
  'Cresson',
  'Cressona',
  'Crest',
  'Crest Hill',
  'Crested Butte',
  'Crestline',
  'Creston',
  'Crestone',
  'Crestview',
  'Crestwood',
  'Creswick',
  'Crete',
  'Creully',
  'Creusot',
  'Creuzier-le-neuf',
  'Creuzier-le-vieux',
  'Creußen',
  'Crevalcore',
  'Creve Coeur',
  'Crevillente',
  'Crewe',
  'Crewkerne',
  'Creysse',
  'Crécy',
  'Créhange',
  'Créney-près-troyes',
  'Crépieux-la-pape',
  'Crépy-en-valois',
  'Créteil',
  'Criciúma',
  'Crick',
  'Crickhowell',
  'Cricklade',
  'Cricklewood',
  'Crieff',
  'Crikvenica',
  'Crimmitschau',
  'Cringleford',
  'Cripple Creek',
  'Crisana',
  'Crisfield',
  'Crispiano',
  'Crissey',
  'Crissier',
  'Cristesti',
  'Cristian',
  'Cristina',
  'Crixás',
  'Crnomelj',
  'Crnuce',
  'Crocetta',
  'Crocetta Del Montello',
  'Crockett',
  'Croft',
  'Crofton',
  'Croissy',
  'Croissy-beaubourg',
  'Croissy-sur-seine',
  'Croix',
  'Croixrault',
  'Crolles',
  'Cromarty',
  'Cromer',
  'Cromhall',
  'Crompond',
  'Cromwell',
  'Crondall',
  'Cronulla',
  'Crook',
  'Crook Of Devon',
  'Crookham',
  'Crookston',
  'Cropwell',
  'Crosby',
  'Cross City',
  'Cross Hands',
  'Cross Plains',
  'Cross River',
  'Cross Roads',
  'Crossen',
  'Crossett',
  'Crossfield',
  'Crossgar',
  'Crosshill',
  'Crossmaglen',
  'Crossville',
  'Crosswicks',
  'Croswell',
  'Croton Falls',
  'Croton On Hudson',
  'Crotone',
  'Crottendorf',
  'Crouse',
  'Crow Agency',
  'Crowborough',
  'Crowland',
  'Crowley',
  'Crown Point',
  'Crownpoint',
  'Crownsville',
  'Crows Nest',
  'Crowthorne',
  'Croydon',
  'Crozet',
  'Crozier',
  'Crucero',
  'Crum Lynne',
  'Crumlin',
  'Cruquius',
  'Crusinallo',
  'Cruz Alta',
  'Cruz Quebrada',
  'Cruzeiro',
  'Cruzeiro Da Fortaleza',
  'Crymych',
  'Crystal',
  'Crystal City',
  'Crystal Lake',
  'Crystal River',
  'Crystal Springs',
  'Csenger',
  'Csobánka',
  'Csomád',
  'Csongrád',
  'Csömör',
  'Ctibor',
  'Cuajimalpa',
  'Cuardo',
  'Cuarte',
  'Cuarte De Huerva',
  'Cuauhtémoc',
  'Cuautitlán',
  'Cuautitlán Izcalli',
  'Cuautla',
  'Cuba',
  'Cubao',
  'Cubatão',
  'Cubelles',
  'Cuckfield',
  'Cucq',
  'Cudahy',
  'Cuddalore',
  'Cuddington',
  'Cuddy',
  'Cudham',
  'Cudworth',
  'Cuenca',
  'Cuencamé',
  'Cuernavaca',
  'Cuero',
  'Cuers',
  'Cuevas Del Almanzora',
  'Cuffley',
  'Cuggiono',
  'Cugnaux',
  'Cugy',
  'Cuiabá',
  'Cuijk',
  'Cuisery',
  'Cuisiat',
  'Cuitláhuac',
  'Cukarica',
  'Culbertson',
  'Culcheth',
  'Culemborg',
  'Culgaith',
  'Culiacán',
  'Cullera',
  'Culleredo',
  'Cullman',
  'Culloden',
  'Cullom',
  'Cullompton',
  'Cullowhee',
  'Cully',
  'Cullybackey',
  'Culoz',
  'Culpeper',
  'Culver',
  'Culver City',
  'Cumaná',
  'Cumberland',
  'Cumberland Center',
  'Cumberland Foreside',
  'Cumberland Furnace',
  'Cumbernauld',
  'Cumiana',
  'Cumming',
  'Cumnock',
  'Cuncolim',
  'Cundinamarca',
  'Cuneo',
  'Cunewalde',
  'Cunningham',
  'Cupar',
  'Cupertino',
  'Cupra Marittima',
  'Cuprija',
  'Curacao',
  'Curaco De Velez',
  'Curato Do Santa Catarina',
  'Curdridge',
  'Curepe',
  'Curepipe',
  'Curinga',
  'Curitiba',
  'Curityba',
  'Curl Curl',
  'Curno',
  'Curraheen',
  'Currais Novos',
  'Currency Creek',
  'Curridabat',
  'Currumbin',
  'Curtarolo',
  'Curtea De Arges',
  'Curtis',
  'Curtis Bay',
  'Curvelo',
  'Cusano Milanino',
  'Cusco',
  'Cushing',
  'Cusio',
  'Cussay',
  'Cusset',
  'Cusseta',
  'Custer',
  'Custines',
  'Cut Bank',
  'Cut Off',
  'Cutchogue',
  'Cuthbert',
  'Cutia',
  'Cutigliano',
  'Cutral-co',
  'Cutro',
  'Cuttack',
  'Cuxhaven',
  'Cuxwold',
  'Cuyahoga Falls',
  'Cuzco',
  'Cuzorn',
  'Cúcuta',
  'Cúllar-vega',
  'Cvikov',
  'Cwmbran',
  'Cwmcarvan',
  'Cyberport',
  'Cymer',
  'Cynthiana',
  'Cypress',
  'Cysoing',
  'Czechowice-dziedzice',
  'Czerniewice',
  'Czersk',
  'Czerwionka',
  'Czestochowa',
  'Czluchow',
  'Czosnow',
  'Çan',
  'Çanakkale',
  'Çankaya',
  'Çekmeköy',
  'Çengelköy',
  'Çerkezköy',
  'Çorlu',
  'Çorum',
  'Çubuklu',
  'Çukurova',
  'Da Lat',
  'Da Nang',
  'Dabas',
  'Dabo',
  'Dabrowa',
  'Dabrowa Gornicza',
  'Dachau',
  'Dachsen',
  'Dacice',
  'Dacono',
  'Dacula',
  'Dadahu',
  'Dade City',
  'Dadizele',
  'Dadong',
  'Dadukou',
  'Daebang',
  'Daechidong',
  'Daegu',
  'Daehwa',
  'Daejeon',
  'Daerimildong',
  'Dafeng',
  'Daganzo De Arriba',
  'Dagenham',
  'Dagmersellen',
  'Dagneux',
  'Dagobertshausen',
  'Dagsboro',
  'Dahab',
  'Dahanu',
  'Dahej',
  'Dahlem',
  'Dahlenburg',
  'Dahlenheim',
  'Dahlgren',
  'Dahlonega',
  'Dahlwitz-hoppegarten',
  'Dahme',
  'Dahod',
  'Daillens',
  'Daimiel',
  'Daimon',
  'Daingerfield',
  'Daira',
  'Daito',
  'Daix',
  'Dakabin',
  'Dakar',
  'Dakar Dioi',
  'Dakota',
  'Dakota City',
  'Dala-floda',
  'Dalang',
  'Dalarö',
  'Dalbeattie',
  'Dalby',
  'Dalcross',
  'Dale',
  'Dale City',
  'Dale I Sunnfjord',
  'Daleiden',
  'Dalen',
  'Daleville',
  'Dalfsen',
  'Dalhart',
  'Dalhem',
  'Dalhousie',
  'Dali',
  'Dalia',
  'Dalian',
  'Dalian Shi',
  'Dalkeith',
  'Dalkey',
  'Dallas',
  'Dallas Center',
  'Dallastown',
  'Dallgow',
  'Dalli Rajhara',
  'Dallington',
  'Dalmacio Vélez',
  'Dalmeny',
  'Dalmine',
  'Dalovice',
  'Dalsheim',
  'Dalston',
  'Dalstorp',
  'Dalton',
  'Dalton In Furness',
  'Dalvík',
  'Dalwood',
  'Daly City',
  'Damaia',
  'Daman',
  'Damansara',
  'Damansara New Village',
  'Damariscotta',
  'Damascus',
  'Damastown',
  'Damazan',
  'Dambulla',
  'Damery',
  'Damflos',
  'Damietta',
  'Damigny',
  'Damloup',
  'Dammam',
  'Damme',
  'Damp',
  'Dampierre-sur-boutonne',
  'Dampmart',
  'Dampremy',
  'Dan',
  'Dana Point',
  'Danao City',
  'Danboro',
  'Danbury',
  'Dandeli',
  'Dandenong',
  'Dandenong Road',
  'Dandenong West',
  'Danderyd',
  'Dandong',
  'Dandridge',
  'Dane',
  'Dangsan',
  'Dangyang',
  'Dania',
  'Danielson',
  'Danielsville',
  'Daniken',
  'Danis',
  'Danjoutin',
  'Danlí',
  'Danmark',
  'Dannemarie-sur-crête',
  'Dannewerk',
  'Danowita',
  'Dansville',
  'Danvers',
  'Danville',
  'Danyang',
  'Daojiao',
  'Daoulas',
  'Daphne',
  'Dapto',
  'Dapu',
  'Daqing',
  'Dar Es Salaam',
  'Dar Es Salam',
  'Daraga',
  'Darasti-ilfov',
  'Darbhanga',
  'Darby',
  'Dardagny',
  'Dardanelle',
  'Dardania',
  'Dardenne Prairie',
  'Dardilly',
  'Daresbury',
  'Dargun',
  'Darica',
  'Darien',
  'Darjeeling',
  'Darjiling',
  'Darkovice',
  'Darlaston',
  'Darling Point',
  'Darlinghurst',
  'Darlington',
  'Darmstadt',
  'Darnall',
  'Darnétal',
  'Darra',
  'Darrington',
  'Darstetten',
  'Dartford',
  'Dartmouth',
  'Darton',
  'Darwen',
  'Darwin',
  'Darya Ganj',
  'Dascalu',
  'Dasice',
  'Dasing',
  'Daska',
  'Dasma',
  'Dasmarinas Village',
  'Dasmariñas',
  'Dassel',
  'Dassow',
  'Datchet',
  'Datong',
  'Datteln',
  'Dattenberg',
  'Dauchingen',
  'Dauerthal',
  'Daugavpils',
  'Dauin',
  'Daun',
  'Dauphin',
  'Dauphin Island',
  'Davangere',
  'Davao',
  'Davao City',
  'Davenport',
  'Daventry',
  'David',
  'David City',
  'Davidson',
  'Davidsonville',
  'Davie',
  'Davik',
  'Davis',
  'Davis City',
  'Davis Junction',
  'Davisburg',
  'Davison',
  'Davos Dorf',
  'Davos Platz',
  'Davutpasa',
  'Dawley',
  'Dawlish',
  'Dawn',
  'Dawson',
  'Dawson Creek',
  'Dawsonville',
  'Dawukou',
  'Dax',
  'Daxing',
  'Dayboro',
  'Daye',
  'Daylesford',
  'Dayton',
  'Daytona Beach',
  'Dayville',
  'Dazhou',
  'Dánszentmiklós',
  'Dägeling',
  'Däniken',
  'Dättwil',
  'Dbayeh',
  'Ddmashen',
  'De Armanville',
  'De Bilt',
  'De Forest',
  'De Goorn',
  'De Haan',
  'De Klinge',
  'De Krim',
  'De Kwakel',
  'De Leon',
  'De Leon Springs',
  'De Lier',
  'De Meern',
  'De Panne',
  'De Pere',
  'De Pinte',
  'De Queen',
  'De Rijp',
  'De Smet',
  'De Soto',
  'De Steeg',
  'De Valls Bluff',
  'Deadwood',
  'Deagon',
  'Deakin',
  'Deal',
  'Deansboro',
  'Deanshanger',
  'Dearborn',
  'Dearborn Heights',
  'Deatsville',
  'Deba',
  'Debary',
  'Debe Wielkie',
  'Debert',
  'Debica',
  'Debno',
  'Debrecen',
  'Decatur',
  'Decazeville',
  'Deception Bay',
  'Decin',
  'Deckenpfronn',
  'Decorah',
  'Deddington',
  'Dedemsvaart',
  'Dedham',
  'Deep Gap',
  'Deep River',
  'Deepcar',
  'Deepcut',
  'Deepdene',
  'Deepwater',
  'Deer Creek',
  'Deer Park',
  'Deer River',
  'Deeragun',
  'Deerfield',
  'Deerfield Beach',
  'Deerhurst',
  'Deerlijk',
  'Deerwood',
  'Deesa',
  'Deesen',
  'Deeside',
  'Deewhy',
  'Defiance',
  'Deftinge',
  'Defuniak Springs',
  'Deganwy',
  'Degerfors',
  'Degernau',
  'Degersheim',
  'Deggendorf',
  'Deggingen',
  'Dehiwala',
  'Dehiwela',
  'Dehli',
  'Dehra Dun',
  'Dehrn',
  'Deidesheim',
  'Deil',
  'Deilinghofen',
  'Deiningen',
  'Deinze',
  'Deira',
  'Deisenhausen',
  'Deisenhofen',
  'Deitingen',
  'Deizisau',
  'Deißlingen',
  'Deje',
  'Dejvice',
  'Dekalb',
  'Del Mar',
  'Del Norte',
  'Del Rio',
  'Del Valle',
  'Del Viso',
  'Delabole',
  'Delafield',
  'Deland',
  'Delano',
  'Delaplane',
  'Delavan',
  'Delaware',
  'Delaware City',
  'Delaware Water Gap',
  'Delães',
  'Delbrück',
  'Delden',
  'Delemont',
  'Delémont',
  'Delfgauw',
  'Delft',
  'Delfzijl',
  'Delhi',
  'Delhi Paharganj',
  'Delhi Sabzimandi',
  'Delia',
  'Delicias',
  'Delisle',
  'Delitzsch',
  'Delligsen',
  'Delmar',
  'Delmenhorst',
  'Delmont',
  'Deloraine',
  'Delph',
  'Delphi',
  'Delphos',
  'Delray Beach',
  'Delsbo',
  'Delson',
  'Delta',
  'Delta Junction',
  'Deltona',
  'Demarest',
  'Dematagoda',
  'Demen',
  'Deming',
  'Demirciler',
  'Demitz-thumitz',
  'Demopolis',
  'Demorest',
  'Dempocho-kita',
  'Den Andel',
  'Den Bosch',
  'Den Burg',
  'Den Dolder',
  'Den Dungen',
  'Den Haag',
  'Den Ham',
  'Den Helder',
  'Den Hoorn',
  'Denain',
  'Denair',
  'Denbigh',
  'Denby',
  'Denby Dale',
  'Denderhoutem',
  'Denderleeuw',
  'Dendermonde',
  'Denekamp',
  'Denham',
  'Denham Springs',
  'Denia',
  'Denison',
  'Denizli',
  'Denkendorf',
  'Denklingen',
  'Denman Island',
  'Denmark',
  'Dennis',
  'Dennison',
  'Denpasar',
  'Densbüren',
  'Dentergem',
  'Denton',
  'Denver',
  'Denver City',
  'Denville',
  'Denzlingen',
  'Deoghar',
  'Deokjin',
  'Depew',
  'Depok',
  'Deposit',
  'Deptford',
  'Deqing',
  'Dera Bassi',
  'Dera Ghazi Khan',
  'Derby',
  'Dereham',
  'Derendingen',
  'Derental',
  'Dergachi',
  'Deridder',
  'Derio',
  'Dermott',
  'Dernbach',
  'Deroche',
  'Derrimut',
  'Derry',
  'Derrybeg',
  'Dersau',
  'Derval',
  'Derwood',
  'Des Allemands',
  'Des Arc',
  'Des Moines',
  'Des Plaines',
  'Desborough',
  'Desenzano',
  'Desenzano Del Garda',
  'Desert Hot Springs',
  'Desford',
  'Deshler',
  'Desio',
  'Desoto',
  'Dessau',
  'Dessel',
  'Desselgem',
  'Destelbergen',
  'Destin',
  'Detling',
  'Detmold',
  'Detroit',
  'Detroit Lakes',
  'Dettenhausen',
  'Dettingen',
  'Dettingen An Der Erms',
  'Dettingen Unter Teck',
  'Dettmannsdorf',
  'Detva',
  'Deuil-la-barre',
  'Deurle',
  'Deurne',
  'Deutsch',
  'Deutsch Goritz',
  'Deutsch-wagram',
  'Deutschlandberg',
  'Deutschlandsberg',
  'Deutschneudorf',
  'Deutz',
  'Deux-montagnes',
  'Deva',
  'Devanhalli',
  'Devens',
  'Deventer',
  'Devils Lake',
  'Devizes',
  'Devon',
  'Devonport',
  'Dewas',
  'Dewey',
  'Deweyville',
  'Dewitt',
  'Dewittville',
  'Dewsbury',
  'Dexheim',
  'Dexing',
  'Dexter',
  'Deyang',
  'Deyelsdorf',
  'Dezhou',
  'Dezmir',
  'Déchy',
  'Décines',
  'Décines-charpieu',
  'Désaignes',
  'Dévaványa',
  'Déville-lès-rouen',
  'Dhahran',
  'Dhaid',
  'Dhaka',
  'Dhamnod',
  'Dhampur',
  'Dhanbad',
  'Dhanera',
  'Dhankot',
  'Dhar',
  'Dharampur',
  'Dharamsala',
  'Dharapuram',
  'Dharmapuri',
  'Dharmsala',
  'Dharuhera',
  'Dharwad',
  'Dhemaji',
  'Dhenkanal',
  'Dhoraji',
  'Dhuy',
  'Diadema',
  'Diamantina',
  'Diamond Bar',
  'Diamond Creek',
  'Diamond Springs',
  'Diamondhead',
  "Diano D'alba",
  'Diavatá',
  'Dibrugarh',
  'Dickenschied',
  'Dickerson',
  'Dickinson',
  'Dickson',
  'Dickson City',
  'Didam',
  'Didcot',
  'Didsbury',
  'Die',
  'Dieblich',
  'Dieburg',
  'Diedorf',
  'Diegem',
  'Diego Garcia',
  'Diegten',
  'Diekholzen',
  'Diekirch',
  'Dielsdorf',
  'Diemen',
  'Diemeringen',
  'Diemtigen',
  'Dienethal',
  'Diepenau',
  'Diepenbeek',
  'Diepholz',
  'Diepoldsau',
  'Dieppe',
  'Dierdorf',
  'Dieren',
  'Dierhagen',
  'Diessenhofen',
  'Diest',
  'Dietach',
  'Dietenhofen',
  'Dietfurt',
  'Dietikon',
  'Dietlikon',
  'Dietmannsried',
  'Dietramszell',
  'Dietrich',
  'Dietwil',
  'Dietzenbach',
  'Dieuze',
  'Diez',
  'Dießen Am Ammersee',
  'Differdange',
  'Digana',
  'Digboi',
  'Digby',
  'Diggers Rest',
  'Diggins',
  'Dighton',
  'Dignac',
  'Digne',
  'Dijon',
  'Diksmuide',
  'Dilbeek',
  'Dili',
  'Dilijan',
  'Diliman',
  'Dillenburg',
  'Dilley',
  'Dilling',
  'Dillingen',
  'Dillingham',
  'Dillon',
  'Dillsburg',
  'Dilsen',
  'Dilwyn',
  'Dimapur',
  'Dimboola',
  'Dimitrovgrad',
  'Dimock',
  'Dimona',
  'Din Daeng',
  'Dinajpur',
  'Dinan',
  'Dinant',
  'Dinard',
  'Dinas Powys',
  'Dindigul',
  'Dingelstädt',
  'Dinghai',
  'Dingle',
  'Dingley',
  'Dingolfing',
  'Dingwall',
  'Dingxi',
  'Dingyuan',
  'Dinhard',
  'Dinkelsbühl',
  'Dinklage',
  'Dinnington',
  'Dinsheim',
  'Dinslaken',
  'Dinteloord',
  'Dinther',
  'Dinuba',
  'Dinxperlo',
  'Diors',
  'Diósd',
  'Dipalpur',
  'Dipperz',
  'Dippoldiswalde',
  'Diraz',
  'Dire Dawa',
  'Dirkshorn',
  'Dirksland',
  'Dirmstein',
  'Discovery Bay',
  'Disentis',
  'Dishforth',
  'Disley',
  'Dison',
  'Disputanta',
  'Diss',
  'Dissen',
  'District Heights',
  'Dittelbrunn',
  'Dittersdorf',
  'Ditton Priors',
  'Ditzingen',
  'Divinolândia',
  'Divinópolis',
  'Divion',
  'Divisoria',
  'Divonne-les-bains',
  'Dixon',
  'Diyarbakir',
  'Dizy',
  'Djerba',
  'Djurås',
  'Djurhamn',
  'Djurmo',
  'Djursholm',
  'Dki Jakarta',
  'Dlouhonovice',
  'Dmitrov',
  'Dnepropetrovsk',
  'Dniepropetrovsk',
  'Dniprodzerzhynsk',
  'Dnipropetrovsk',
  'Dobbertin',
  'Dobbs Ferry',
  'Dobel',
  'Doberan',
  'Dobl',
  'Dobong',
  'Dobra',
  'Dobrany',
  'Dobratice',
  'Dobre',
  'Dobrejovice',
  'Dobrenice',
  'Dobri',
  'Dobrich',
  'Dobris',
  'Dobron',
  'Dobrovice',
  'Dobroviz',
  'Dobruska',
  'Dobsice',
  'Dobson',
  'Docklands',
  'Doddington',
  'Dodewaard',
  'Dodge Center',
  'Dodge City',
  'Dodgeville',
  'Dodoma',
  'Dodworth',
  'Doe Run',
  'Doesburg',
  'Doetinchem',
  'Dogokdong',
  'Dogubeyazit',
  'Doha',
  'Dohna',
  'Doi Saket',
  'Dois Córregos',
  'Dois Irmãos',
  'Dois Vizinhos',
  'Dojc',
  'Dokka',
  'Dokkum',
  'Doksan',
  'Doksansadong',
  'Dol',
  'Dole',
  'Dolenjske Toplice',
  'Dolgeville',
  'Dolgoprudnyy',
  'Dolianova',
  'Dollard-des-ormeaux',
  'Dollnstein',
  'Dolná Mariková',
  'Dolné Saliby',
  'Dolni Benesov',
  'Dolni Brezany',
  'Dolni Chabry',
  'Dolni Dobrouc',
  'Dolni Nemci',
  'Dolni Pocernice',
  'Dolni Radechova',
  'Dolni Roven',
  'Dolni Rozinka',
  'Dolni Rychnov',
  'Dolni Zivotice',
  'Dolny Vadicov',
  'Dolný Kubín',
  'Dolomite',
  'Dolores',
  'Dolores Hidalgo',
  'Dolphin',
  'Dolsk',
  'Dolton',
  'Dolus',
  'Domagné',
  'Domat',
  'Domat Ems',
  'Domazlice',
  'Dombås',
  'Dombegyházi Út',
  'Domblans',
  'Dombóvár',
  'Dombühl',
  'Domdidier',
  'Domegliara',
  'Domène',
  'Domérat',
  'Dominguez',
  'Dominica',
  'Dominical',
  'Dominicana',
  'Domjulien',
  'Domjur',
  'Dommartemont',
  'Dommartin',
  'Dommitzsch',
  'Domnesti',
  'Domodossola',
  'Dompierre-les-ormes',
  'Dompierre-sur-mer',
  'Dompierre-sur-nièvre',
  'Dompierre-sur-yon',
  'Domsten',
  'Domusnovas',
  'Domzale',
  'Don Benito',
  'Don Bosco',
  'Don Justo',
  'Don Mills',
  'Don Torcuato',
  'Dona Paula',
  'Donabate',
  'Donaghadee',
  'Donaghcloney',
  'Donalds',
  'Donaldsonville',
  'Donau',
  'Donaueschingen',
  'Donauwörth',
  'Doncaster',
  'Doncaster East',
  'Donceel',
  'Doncols',
  'Donegal',
  'Donetsk',
  'Dongan',
  'Dongchen',
  'Dongcheng',
  'Donge',
  'Dongen',
  'Dongfang',
  'Dongfeng',
  'Donggang',
  'Dongguan',
  'Dongguang',
  'Donghu',
  'Dongio',
  'Dongling',
  'Dongming',
  'Dongshan',
  'Dongsheng',
  'Dongtou',
  'Dongwan',
  'Dongyang',
  'Dongying',
  'Dongyuan',
  'Donhead',
  'Donington',
  'Donja Lomnica',
  'Donja Zelina',
  'Donji Kraljevec',
  'Donji Miholjac',
  'Donna',
  'Donnalucata',
  'Donnelsville',
  'Donnemarie',
  'Donnersdorf',
  'Donnybrook',
  'Donostia-san Sebastián',
  'Donsö',
  'Donstiennes',
  'Donvale',
  'Donzdorf',
  'Donzenac',
  'Doña Josefa',
  'Doñinos De Salamanca',
  'Doorn',
  'Doornspijk',
  'Doorwerth',
  'Dopiewo',
  'Dor',
  'Dora',
  'Dorado',
  'Doral',
  'Dorchester',
  'Dordrecht',
  'Doreen',
  'Dores De Campos',
  'Dorfchemnitz',
  'Dorfen',
  'Dori',
  'Dorion-vaudreuil',
  'Dorking',
  'Dorlisheim',
  'Dormagen',
  'Dormettingen',
  'Dormitz',
  'Dornach',
  'Dornbirn',
  'Dornburg',
  'Dornhan',
  'Dornoch',
  'Dornstadt',
  'Dornstetten',
  'Dornum',
  'Dornwang',
  'Dorog',
  'Doronina',
  'Dorotea',
  'Dorr',
  'Dorridge',
  'Dorrigo',
  'Dorset',
  'Dorst',
  'Dorsten',
  'Dortmund',
  'Dorval',
  'Dos Hermanas',
  'Dosquebradas',
  'Dossenheim',
  'Dossobuono',
  'Dothan',
  'Dottignies',
  'Dottikon',
  'Dottingen',
  'Douai',
  'Douains',
  'Douala',
  'Douarnenez',
  'Doubravice Nad Svitavou',
  'Doubs',
  'Doucier',
  'Douglas',
  'Douglas Lake',
  'Douglassville',
  'Douglasville',
  'Dourados',
  'Dourdan',
  'Doussard',
  'Douvaine',
  'Douvrin',
  'Dove Creek',
  'Dover',
  'Dover Foxcroft',
  'Dover Heights',
  'Dover Plains',
  'Dovre',
  'Dowagiac',
  'Down Ampney',
  'Downers Grove',
  'Downey',
  'Downham Market',
  'Downingtown',
  'Downpatrick',
  'Downs',
  'Downton',
  'Doylestown',
  'Doynton',
  'Döbeln',
  'Dölbau',
  'Dömitz',
  'Dörfles',
  'Dörnigheim',
  'Dörpen',
  'Dörth',
  'Dörverden',
  'Dösjebro',
  'Döttingen',
  'Drabiv',
  'Dracena',
  'Drachten',
  'Dracut',
  'Dracy-le-fort',
  'Drage',
  'Dragomiresti-vale',
  'Dragoon',
  'Draguignan',
  'Dragør',
  'Drake',
  'Dramlje',
  'Drammen',
  'Drancy',
  'Dransfeld',
  'Drantum',
  'Draper',
  'Draperstown',
  'Draveil',
  'Drayton',
  'Drayton Valley',
  'Dráma',
  'Drebach',
  'Dreieich',
  'Dreilinden',
  'Dreilini',
  'Drempt',
  'Drensteinfurt',
  'Dresden',
  'Dresden-reick',
  'Dresher',
  'Dresser',
  'Dreumel',
  'Dreux',
  'Drewryville',
  'Drexel',
  'Drexel Hill',
  'Drégelypalánk',
  'Drémil-lafage',
  'Driebergen',
  'Driebruggen',
  'Drienov',
  'Drietoma',
  'Driffield',
  'Drifting',
  'Driftwood',
  'Driggs',
  'Drinagh',
  'Dripping Springs',
  'Driyorejo',
  'Drobeta-turnu Severin',
  'Drobollach Am Faakersee',
  'Drochtersen',
  'Drogenbos',
  'Drogheda',
  'Drohobycz',
  'Droichead Uí Bhriain',
  'Droitwich',
  'Drolshagen',
  'Dromore',
  'Dronfield',
  'Drongen',
  'Dronningmølle',
  'Dronten',
  'Drosiá',
  'Drouzkovice',
  'Droxford',
  'Druento',
  'Drumchapel',
  'Drumcondra',
  'Drumheller',
  'Drummondville',
  'Drummoyne',
  'Drumore',
  'Drumright',
  'Drums',
  'Drunen',
  'Drury',
  'Druskininkai',
  'Druten',
  'Druzec',
  'Dry Branch',
  'Dry Creek',
  'Dry Drayton',
  'Dryden',
  'Drymen',
  'Drytown',
  'Drøbak',
  'Dschang',
  'Du Bois',
  'Duanesburg',
  'Duarte',
  'Duba',
  'Dubai',
  'Dubayy',
  'Dubbo',
  'Dubendorf',
  'Dubi',
  'Dubino',
  'Dublin',
  'Dubliny',
  'Dubna',
  'Dubnany',
  'Dubnica Nad Váhom',
  'Dubnice',
  'Dubno',
  'Dubois',
  'Dubosevica',
  'Dubrovnik',
  'Dubrovnik Ii',
  'Dubuque',
  'Duca',
  'Ducey',
  'Duchesne',
  'Duchnice',
  'Duck Lake',
  'Duckwitz',
  'Ducós',
  'Dudelange',
  'Duderstadt',
  'Dudingen',
  'Dudley',
  'Dudullu',
  'Due West',
  'Duenweg',
  'Dueville',
  'Duffel',
  'Duffield',
  'Duffryn',
  'Dufftown',
  'Dugny',
  'Dugo Selo',
  'Duhok',
  'Duisburg',
  'Duisburg And Hamborn',
  'Duisdorf',
  'Duitama',
  'Duiven',
  'Duivendrecht',
  'Dukinfield',
  'Dukovany',
  "Dulaylat Al Hama'idah",
  'Dulles',
  'Duloe',
  'Duluth',
  'Dulwich',
  'Dumaguete City',
  'Dumai',
  'Dumas',
  'Dumbarton',
  'Dumbleton',
  'Dumbravita',
  'Dumfries',
  'Dumka',
  'Dumlupinar',
  'Dumont',
  'Dunaföldvár',
  'Dunaharaszti',
  'Dunaivtsi',
  'Dunajská Streda',
  'Dunakeszi',
  'Dunakiliti',
  'Dunaszentgyörgy',
  'Dunaújváros',
  'Dunavarsány',
  'Dunavecse',
  'Dunbar',
  'Dunblane',
  'Dunboyne',
  'Dunbridge',
  'Duncan',
  'Duncan Bay',
  'Duncannon',
  'Duncansville',
  'Duncanville',
  'Dundalk',
  'Dundas',
  'Dundee',
  'Dunderrow',
  'Dundonald',
  'Dundrum',
  'Dunedin',
  'Dunellen',
  'Dunfanaghy',
  'Dunfermline',
  'Dungannon',
  'Dungarpur',
  'Dungarvan',
  'Dunham',
  'Dunhill',
  'Dunhua',
  'Dunières',
  'Dunkerque',
  'Dunkeswell',
  'Dunkirk',
  'Dunlap',
  'Dunleer',
  'Dunmanway',
  'Dunmor',
  'Dunmore',
  'Dunmow',
  'Dunmurry',
  'Dunn',
  'Dunn Loring',
  'Dunnellon',
  'Dunningen',
  'Dunnington',
  'Dunnville',
  'Dunoon',
  'Dunsborough',
  'Dunseith',
  'Dunsfold',
  'Dunshaughlin',
  'Dunsmuir',
  'Dunstable',
  'Dunston',
  'Dunwich',
  'Dunwoody',
  'Duplje',
  'Dupo',
  'Dupont',
  'Duppigheim',
  'Duque De Caxias',
  'Duquesne',
  'Durach',
  'Dural',
  'Durand',
  'Durango',
  'Durant',
  'Durban',
  'Durchhausen',
  'Durg',
  'Durgapur',
  'Durham',
  'Duri',
  'Durlach',
  'Durlangen',
  'Durley',
  'Durmersheim',
  'Dursley',
  'Durtal',
  'Dury',
  'Dury-lès-amiens',
  'Duryea',
  'Dusejov',
  'Dushambe',
  'Dushanbe',
  'Dusnok',
  'Dussen',
  'Dustin',
  'Dutovlje',
  'Duttlenheim',
  'Duvall',
  'Duvendiek',
  'Duxbury',
  'Duxford',
  'Duz',
  'Dußlingen',
  'Dún Laoghaire',
  'Dübendorf',
  'Düdingen',
  'Dülmen',
  'Düren',
  'Dürmentingen',
  'Dürnau',
  'Dürnten',
  'Dürrheim',
  'Dürrröhrsdorf',
  'Dürrröhrsdorf-dittersbach',
  'Düsseldorf',
  'Düzce',
  'Dvärsätt',
  'Dvorníky',
  'Dvur Kralove',
  'Dvur Kralove Nad Labem',
  'Dwarka',
  'Dwight',
  'Dwingeloo',
  'Dworp',
  'Dybvad',
  'Dyce',
  'Dyer',
  'Dyersburg',
  'Dyersville',
  'Dymerka',
  'Dysart',
  'Dzaferli',
  'Dzerzhinsk',
  'Dzialdowo',
  'Dzierzoniow',
  'Eads',
  'Eagan',
  'Eagle',
  'Eagle Butte',
  'Eagle Farm',
  'Eagle Heights',
  'Eagle Mountain',
  'Eagle Pass',
  'Eagle Point',
  'Eagle River',
  'Eagle Rock',
  'Eaglemont',
  'Eagleville',
  'Ealing',
  'Earby',
  'Earith',
  'Earl Soham',
  'Earleville',
  'Earley',
  'Earlham',
  'Earls Barton',
  'Earls Colne',
  'Earls Court',
  'Earlston',
  'Earlswood',
  'Earlwood',
  'Earlysville',
  'Earth',
  'Earth City',
  'Easingwold',
  'Easley',
  'East Alton',
  'East Amherst',
  'East Arlington',
  'East Aurora',
  'East Bend',
  'East Bergholt',
  'East Berlin',
  'East Bloomfield',
  'East Boston',
  'East Braintree',
  'East Bridgewater',
  'East Bridgford',
  'East Brisbane',
  'East Brookfield',
  'East Brunswick',
  'East Burwood',
  'East Butler',
  'East Calais',
  'East Calder',
  'East Canaan',
  'East Charleston',
  'East Chicago',
  'East China',
  'East Cowes',
  'East Dennis',
  'East Dereham',
  'East Dorset',
  'East Dubuque',
  'East Dulwich',
  'East Earl',
  'East Ellijay',
  'East Elmhurst',
  'East Falmouth',
  'East Farleigh',
  'East Finchley',
  'East Flat Rock',
  'East Fremantle',
  'East Godavari',
  'East Granby',
  'East Grand Rapids',
  'East Greenbush',
  'East Greenville',
  'East Greenwich',
  'East Grinstead',
  'East Haddam',
  'East Haddon',
  'East Ham',
  'East Hampstead',
  'East Hampton',
  'East Hanney',
  'East Hanover',
  'East Hartford',
  'East Hartland',
  'East Haven',
  'East Helena',
  'East Horndon',
  'East Horsley',
  'East Huntspill',
  'East Irvine',
  'East Islip',
  'East Kew',
  'East Kilbride',
  'East Lansing',
  'East Liberty',
  'East Liverpool',
  'East London',
  'East Longmeadow',
  'East Lyme',
  'East Machias',
  'East Malling',
  'East Mckeesport',
  'East Meadow',
  'East Millsboro',
  'East Molesey',
  'East Moline',
  'East Montpelier',
  'East Northport',
  'East Norwich',
  'East Orange',
  'East Otis',
  'East Palo Alto',
  'East Peckham',
  'East Pembroke',
  'East Peoria',
  'East Perth',
  'East Petersburg',
  'East Pittsburgh',
  'East Point',
  'East Preston',
  'East Princeton',
  'East Providence',
  'East Quogue',
  'East Rochester',
  'East Rockaway',
  'East Rutherford',
  'East Saint Louis',
  'East Sandwich',
  'East Setauket',
  'East Stoke',
  'East Stroudsburg',
  'East Syracuse',
  'East Tamaki',
  'East Taunton',
  'East Tawas',
  'East Troy',
  'East Wakefield',
  'East Walpole',
  'East Wareham',
  'East Wellow',
  'East Wenatchee',
  'East Weymouth',
  'East Windsor',
  'East Windsor Hill',
  'East Wittering',
  'Eastanollee',
  'Eastbourne',
  'Eastchester',
  'Eastcote',
  'Eastern',
  'Eastern Creek',
  'Eastern Passage',
  'Eastford',
  'Eastham',
  'Easthampton',
  'Eastlake',
  'Eastland',
  'Eastleigh',
  'Eastman',
  'Easton',
  'Eastpointe',
  'Eastport',
  'Eastry',
  'Eastsound',
  'Eastville',
  'Eastwood',
  'Eaton',
  'Eaton Park',
  'Eaton Rapids',
  'Eaton Socon',
  'Eatonton',
  'Eatontown',
  'Eau Claire',
  'Eaubonne',
  'Eaunes',
  'Ebberup',
  'Ebbs',
  'Ebbw Vale',
  'Ebeltoft',
  'Eben-emael',
  'Ebene',
  'Ebenfurth',
  'Ebenhausen',
  'Ebensburg',
  'Ebensee',
  'Eberbach',
  'Eberdingen',
  'Ebermannsdorf',
  'Ebermannstadt',
  'Ebern',
  'Ebernhahn',
  'Ebersbach',
  'Ebersbach An Der Fils',
  'Ebersberg',
  'Ebersdorf',
  'Ebersheim',
  'Eberstallzell',
  'Eberswalde',
  'Ebes',
  'Ebetsu',
  'Ebène',
  'Ebikon',
  'Ebina',
  'Ebing',
  'Ebisu',
  'Ebnat-kappel',
  'Ebnath',
  'Eboli',
  'Ebreichsdorf',
  'Ebringen',
  'Ecatepec',
  'Ecatepec De Morelos',
  "Ecaussinnes-d'enghien",
  'Eccles',
  'Ecclesall',
  'Ecclesfield',
  'Eccleshall',
  'Eccleston',
  'Echallens',
  'Echarlens',
  'Echeverria',
  'Eching',
  'Echizen',
  'Echt',
  'Echterdingen',
  'Echternach',
  'Echtrop',
  'Echuca',
  'Echunga',
  'Echzell',
  'Eck En Wiel',
  'Eckbolsheim',
  'Eckenhagen',
  'Eckernförde',
  'Eckerö',
  'Eckington',
  'Ecru',
  'Ecublens',
  'Ed',
  'Edam',
  'Edapal',
  'Edappal',
  'Ede',
  'Edegem',
  'Edemissen',
  'Eden',
  'Eden Prairie',
  'Edenbridge',
  'Edendale',
  'Edenhall',
  'Edenkoben',
  'Edenton',
  'Ederveen',
  'Edewecht',
  'Edgar',
  'Edgartown',
  'Edgbaston',
  'Edge Hill',
  'Edgecliff',
  'Edgecomb',
  'Edgefield',
  'Edgemont',
  'Edgemoor',
  'Edgerton',
  'Edgewater',
  'Edgewood',
  'Edgware',
  'Edina',
  'Edinboro',
  'Edinburg',
  'Edinburgh',
  'Edingen-neckarhausen',
  'Edingley',
  'Edington',
  'Edirne',
  'Edison',
  'Edmeston',
  'Edmond',
  'Edmonds',
  'Edmonton',
  'Edogawa',
  'Edremit',
  'Edsbyn',
  'Edson',
  'Edt Bei Lambach',
  'Edwalton',
  'Edwards',
  'Edwardsburg',
  'Edwardsport',
  'Edwardstown',
  'Edwardsville',
  'Edwinstowe',
  'Eefde',
  'Eeklo',
  'Eelde',
  'Eemnes',
  'Eerbeek',
  'Eersel',
  'Effingen',
  'Effingham',
  'Effretikon',
  'Effurun',
  'Efringen-kirchen',
  'Egaa',
  'Eganville',
  'Egå',
  'Egeln',
  'Egelsbach',
  'Egem',
  'Egenhausen',
  'Eger',
  'Egerkingen',
  'Egerlovo',
  'Egersund',
  'Egerszalók',
  'Egestorf',
  'Egg',
  'Egg Harbor City',
  'Egg Harbor Township',
  'Eggebek',
  'Eggelsberg',
  'Eggenburg',
  'Eggenfelden',
  'Eggenstein',
  'Eggenstein-leopoldshafen',
  'Eggenwil',
  'Eggersdorf Bei Graz',
  'Eggersriet',
  'Eggingen',
  'Eggolsheim',
  'Eggstätt',
  'Egham',
  'Egilsstaðir',
  'Eging',
  'Egkomi',
  'Egling',
  'Eglinton',
  'Eglisau',
  'Egliswil',
  'Egmating',
  'Egmond Aan Den Hoef',
  'Egna',
  'Egnach',
  'Egsdorf',
  'Egtved',
  'Eguíllor',
  'Egypt',
  'Eheliyagoda',
  'Ehingen',
  'Ehlerange',
  'Ehndorf',
  'Ehningen',
  'Ehrenberg',
  'Ehrendingen',
  'Ehrenfriedersdorf',
  'Ehringshausen',
  'Eibau',
  'Eibelstadt',
  'Eibergen',
  'Eich',
  'Eichberg',
  'Eichede',
  'Eichenau',
  'Eichenzell',
  'Eichfeld',
  'Eichstätt',
  'Eichstetten',
  'Eide',
  'Eidsvoll',
  'Eigashima',
  'Eigenrieden',
  'Eigenstraat',
  'Eight Mile',
  'Eight Mile Plains',
  'Eighty Four',
  'Eijsden',
  'Eikangervåg',
  'Eiken',
  'Eilat',
  'Eilbek',
  'Eilenburg',
  'Eilon',
  'Eilot',
  'Eimeldingen',
  'Eimsbüttel',
  'Eina',
  'Einat',
  'Einbeck',
  'Eindhoven',
  'Einsiedeln',
  'Eischen',
  'Eiselfing',
  'Eisenach',
  'Eisenberg',
  'Eisenhüttenstadt',
  'Eisenstadt',
  'Eisental',
  'Eisfeld',
  'Eisleben',
  'Eislingen',
  'Eiterfeld',
  'Eitorf',
  'Eitzen',
  'Ejea De Los Caballeros',
  'Ejido',
  'Ekaterinburg',
  'Ekáli',
  'Eke',
  'Ekeby',
  'Ekeren',
  'Ekerö',
  'Ekinciler',
  'Ekkersrijt',
  'Ekolsund',
  'Ekpan',
  'Ekpoma',
  'Ekron',
  'Eksaarde',
  'Eksel',
  'Ekshärad',
  'Eksjö',
  'El Agustino',
  'El Aouina',
  'El Arañado',
  'El Astillero',
  'El Álamo',
  'El Bosque',
  'El Cairo',
  'El Cajon',
  'El Calafate',
  'El Campillo',
  'El Campo',
  'El Cangrejo',
  'El Carmen',
  'El Carmen De Bolívar',
  'El Casar',
  'El Centro',
  'El Cerrito',
  'El Dorado',
  'El Dorado Hills',
  'El Dorado Springs',
  'El Ejido',
  'El Escorial',
  'El Espinal',
  'El Espín',
  'El Eulma',
  'El Ferrol',
  'El Fuerte',
  'El Gizeh',
  'El Granada',
  'El Haram',
  'El Jadida',
  'El Llano',
  'El Médano',
  'El Mirage',
  'El Monte',
  'El Palomar',
  'El Paso',
  'El Picazo',
  'El Plantío',
  'El Prado',
  'El Prat De Llobregat',
  'El Pueblito',
  'El Puerto De Santa María',
  'El Puerto de Santa María',
  'El Puig',
  'El Reno',
  'El Run',
  'El Salto',
  'El Salvador',
  'El Segundo',
  'El Sobrante',
  'El Tarter',
  'El Toboso',
  'El Toro',
  'El Vendrell',
  'El Verde',
  'El Villar De Arnedo',
  'El Viso Del Alcor',
  'El-qurein',
  'Elat',
  'Elazig',
  'Elaziz',
  'Elba',
  'Elbarrena',
  'Elberfeld',
  'Elbert',
  'Elberton',
  'Elbeuf',
  'Elblag',
  'Elbow',
  'Elburg',
  'Elburn',
  'Elche',
  'Elche De La Sierra',
  'Elcho',
  'Elciego',
  'Elda',
  'Elderslie',
  'Eldon',
  'Eldora',
  'Eldorado',
  'Eldoret',
  'Eldred',
  'Eldridge',
  'Eldsberga',
  'Eleanor',
  'Eleftheroúpoli',
  'Elektrenai',
  'Elele',
  'Elephant Butte',
  'Eleusis',
  'Elexalde',
  'Elfershausen',
  'Elford',
  'Elgg',
  'Elgin',
  'Elgóibar',
  'Elgueta',
  'Eliot',
  'Elizabeth',
  'Elizabeth City',
  'Elizabethton',
  'Elizabethtown',
  'Elizabethville',
  'Elizalde',
  'Elk',
  'Elk City',
  'Elk Grove',
  'Elk Grove Village',
  'Elk Mound',
  'Elk Point',
  'Elk River',
  'Elkader',
  'Elkerhausen',
  'Elkhart',
  'Elkhart Lake',
  'Elkhorn',
  'Elkin',
  'Elkins',
  'Elkins Park',
  'Elko',
  'Elkridge',
  'Elkton',
  'Elkwood',
  'Elland',
  'Ellaville',
  'Ellecom',
  'Ellefeld',
  'Ellendale',
  'Ellensburg',
  'Ellenton',
  'Ellenville',
  'Ellenwood',
  'Ellerau',
  'Ellerbek',
  'Ellerhoop',
  'Ellerslie',
  'Ellesmere',
  'Ellesmere Port',
  'Ellettsville',
  'Ellhofen',
  'Ellicott City',
  'Ellicottville',
  'Ellijay',
  'Ellikon An Der Thur',
  'Ellington',
  'Ellinikón',
  'Elliot Lake',
  'Ellison Bay',
  'Elliston',
  'Ellisville',
  'Ellmau',
  'Ellon',
  'Ellsworth',
  'Ellwangen',
  'Ellwood City',
  'Ellzee',
  'Elm',
  'Elm Creek',
  'Elm Grove',
  'Elm Mott',
  'Elma',
  'Elmadag',
  'Elmas',
  'Elmbridge',
  'Elmendorf',
  'Elmenhorst',
  'Elmenthal',
  'Elmer',
  'Elmhurst',
  'Elmira',
  'Elmont',
  'Elmore',
  'Elmsdale',
  'Elmsford',
  'Elmshorn',
  'Elmswell',
  'Elmvale',
  'Elmwood',
  'Elmwood Park',
  'Elon',
  'Elora',
  'Elorrio',
  'Elosu',
  'Eloy',
  'Elphinstone',
  'Elroy',
  'Els Monjos',
  'Elsah',
  'Elsau',
  'Elsbethen',
  'Elsdorf',
  'Elsecar',
  'Elsene',
  'Elsenfeld',
  'Elsenham',
  'Elshout',
  'Elsie',
  'Elsinore',
  'Elsloo',
  'Elsoff',
  'Elst',
  'Elstead',
  'Elsternwick',
  'Elsterwerda',
  'Elstree',
  'Elsworth',
  'Elterlein',
  'Eltham',
  'Eltham North',
  'Eltmann',
  'Eltville',
  'Eluru',
  'Elvas',
  'Elverson',
  'Elverta',
  'Elverum',
  'Elvington',
  'Elwood',
  'Ely',
  'Elyakhin',
  'Elyashiv',
  'Elyria',
  'Elysburg',
  'Elysian',
  'Elz',
  'Elze',
  'Embakasi',
  'Emborough',
  'Embourg',
  'Embrach',
  'Embrun',
  'Embsen',
  'Embu',
  'Embu-Guaçu',
  'Emden',
  'Emeishan',
  'Emek',
  'Emerald',
  'Emerald Isle',
  'Emerson',
  'Emeryville',
  'Emigrant',
  'Emigsville',
  'Emiliano Zapata',
  'Eminence',
  'Eminönü',
  'Emirdag',
  'Emleben',
  'Emlenton',
  'Emlichheim',
  'Emmaboda',
  'Emmaus',
  'Emmeloord',
  'Emmelshausen',
  'Emmen',
  'Emmenbrucke',
  'Emmenbrücke',
  'Emmendingen',
  'Emmerich',
  'Emmerichenhain',
  'Emmering',
  'Emmern',
  'Emmett',
  'Emmetten',
  'Emmingen',
  'Emmingen-liptingen',
  'Emmitsburg',
  'Emory',
  'Empfingen',
  'Empire',
  'Empoli',
  'Emporia',
  'Emporium',
  'Emsbüren',
  'Emsdetten',
  'Emskirchen',
  'Emst',
  'Emstek',
  'Emsworth',
  'Emu Plains',
  'Emunim',
  'En Habesor',
  'Ena',
  'Enånger',
  'Encamp',
  'Encarnación',
  'Encinitas',
  'Encino',
  'Encs',
  'Enderby',
  'Enderlin',
  'Endicott',
  'Endingen',
  'Endwell',
  'Enebakkneset',
  'Enebakneset',
  'Enebyberg',
  'Energodar',
  'Enfield',
  'Enfield Lock',
  'Engadine',
  'Enge-sande',
  'Engelberg',
  'Engeln',
  'Engelsbrand',
  'Engelskirchen',
  'Engen',
  'Engenho De Dentro',
  'Enger',
  'Engerwitzdorf',
  'Enghien',
  'Enghien-les-bains',
  'Engis',
  'England',
  'Englefield Green',
  'Englerston',
  'Englewood',
  'Englewood Cliffs',
  'Englishtown',
  'Englos',
  'Engomi',
  'Engordany',
  'Enguera',
  'Enham-alamein',
  'Enid',
  'Eningen',
  'Eniwa',
  'Enka',
  'Enkenbach',
  'Enkenbach-alsenborn',
  'Enkhuizen',
  'Enköping',
  'Enmore',
  'Enna',
  'Ennepe',
  'Ennepetal',
  'Ennery',
  'Ennetburgen',
  'Ennetbürgen',
  'Ennevelin',
  'Ennigerloh',
  'Ennis',
  'Enniscorthy',
  'Enniskerry',
  'Enniskillen',
  'Ennsdorf',
  'Enoggera',
  'Enola',
  'Enon',
  'Enosburg Falls',
  'Enping',
  'Enschede',
  'Ensdorf',
  'Ensen',
  'Ensenada',
  'Enshi',
  'Ensingen',
  'Ensisheim',
  'Enskede',
  'Entebbe',
  'Entenmoos',
  'Enter',
  'Enterprise',
  'Entraching',
  'Entraigues-sur-sorgue',
  'Entringen',
  'Entzheim',
  'Enugu',
  'Enugu-ukwu',
  'Enumclaw',
  'Enval',
  'Envigado',
  'Enying',
  'Enzklösterle',
  'Eolia',
  'Epalinges',
  'Epautheyres',
  'Epe',
  'Epernay',
  'Epfendorf',
  'Ephraim',
  'Ephrata',
  'Epoo',
  'Eppelborn',
  'Eppelheim',
  'Eppenhuizen',
  'Eppenstein',
  'Epping',
  'Eppingen',
  'Eppishausen',
  'Eppstein',
  'Epse',
  'Epsom',
  'Epworth',
  'Equality',
  'Er Rachidia',
  'Erandio',
  'Erba',
  'Erbach',
  'Erbendorf',
  'Erbes-büdesheim',
  'Erbil',
  'Erbray',
  'Ercé-en-lamée',
  'Ercolano',
  'Ercuis',
  'Erding',
  'Erdington',
  'Erdotelek',
  'Ere',
  'Erechim',
  'Eregli',
  'Erembodegem',
  'Erenköy',
  'Erevan',
  'Erez',
  'Erfoud',
  'Erftstadt',
  'Erfurt',
  'Erfurt-bindersleben',
  'Ergersheim',
  'Ergolding',
  'Ergoldsbach',
  'Ericeira',
  'Erie',
  'Erin',
  'Erina',
  'Eriskirch',
  'Erith',
  'Erkelenz',
  'Erkenschwick',
  'Erkerode',
  'Erkheim',
  'Erkner',
  'Erkrath',
  'Erlabrunn',
  'Erlangen',
  'Erlanger',
  'Erlbach',
  'Erlen',
  'Erlenbach',
  'Erlenmoos',
  'Erlensee',
  'Erligheim',
  'Erlinsbach',
  'Ermatingen',
  'Ermelo',
  'Ermezinde',
  'Ermington',
  'Ermita',
  'Ermont',
  'Ermúa',
  'Ernakulam',
  'Erndtebrück',
  'Ernei',
  'Ernster',
  'Erode',
  'Erolzheim',
  'Erp',
  'Erpe',
  'Erpent',
  'Erpingham',
  'Erquelinnes',
  'Erquinghem-lys',
  'Erquy',
  'Ersange',
  'Erschwil',
  'Ersigen',
  'Erskine',
  'Erstein',
  'Erstfeld',
  'Ertingen',
  'Ervik',
  'Erving',
  'Erwinna',
  'Erwitte',
  'Erxleben',
  'Erzhausen',
  'Erzincan',
  'Erzingen',
  'Esbeek',
  'Esbjerg',
  'Esbo',
  'Escalante',
  'Escaldes',
  'Escalon',
  'Escalquens',
  'Escanaba',
  'Escapães',
  'Escazú',
  'Esch',
  'Esch-sur-alzette',
  'Eschbach',
  'Eschborn',
  'Escheburg',
  'Eschede',
  'Eschelbronn',
  'Eschen',
  'Eschenbach',
  'Eschenbach In Der Oberpfalz',
  'Eschenlohe',
  'Eschette',
  'Eschlikon',
  'Eschlkam',
  'Eschol',
  'Eschwege',
  'Eschweiler',
  'Escles',
  'Escobedo',
  'Escondido',
  'Escrick',
  'Escuinapa',
  'Escúzar',
  'Esenler',
  'Esens',
  'Esentepe',
  'Esenyurt',
  'Esh',
  'Esher',
  'Esine',
  'Eskedal',
  'Eskilstuna',
  'Eskisehir',
  'Eslöv',
  'Esmans',
  'Esmoriz',
  'Esneux',
  'Espalion',
  'Espana',
  'Espanola',
  'España',
  'Espargo',
  'Esparragal',
  'Esparraguera',
  'Espasa',
  'Espe',
  'Espel',
  'Espelkamp',
  'Esperance',
  'Espergærde',
  'Espiel',
  'Espina',
  'Espinal',
  'Espinardo',
  'Espinho',
  'Espírito Santo',
  'Esplugas De Llobregat',
  'Espoo',
  'Esporlas',
  'Esposende',
  'Espumoso',
  'Esquimalt',
  'Essaouira',
  'Esselbach',
  'Essen',
  'Essenbach',
  'Essendon',
  'Essenheim',
  'Essertines-sur-rolle',
  'Essex',
  'Essex Junction',
  'Essey-lès-nancy',
  'Essigny-le-grand',
  'Essington',
  'Esslingen',
  'Esson',
  'Essonnes',
  'Est',
  'Estacada',
  'Estado De México',
  'Estaimpuis',
  'Estaires',
  'Estancarbon',
  'Estarreja',
  'Estartit',
  'Estavannens',
  'Estavayer-le-lac',
  'Estância Velha',
  'Este',
  'Esteio',
  'Estelí',
  'Estella',
  'Estenfeld',
  'Estepa',
  'Estepona',
  'Esterhazy',
  'Esternay',
  'Estero',
  'Esterwegen',
  'Estes Park',
  'Estevan',
  'Estherville',
  'Estillac',
  'Estoril',
  'Estrées-saint-denis',
  'Esztergom',
  'Etawah',
  'Etchingham',
  'Eth',
  'Ether',
  'Ethul Kotte',
  'Etiler',
  'Etimesgut',
  'Etna',
  'Etobicoke',
  'Eton',
  'Etowah',
  'Ettelbruck',
  'Etten',
  'Ettenheim',
  'Etterbeek',
  'Etters',
  'Ettingen',
  'Ettiswil',
  'Ettlingen',
  'Ettlingenweier',
  'Etyek',
  'Etzelwang',
  'Eu',
  'Euclid',
  'Eudora',
  'Eufaula',
  'Eugendorf',
  'Eugene',
  'Euijeongbu',
  'Euless',
  'Eumundi',
  'Eunice',
  'Eupen',
  'Eura',
  'Eurajoki',
  'Eurasburg',
  'Eureka',
  'Eureka Springs',
  'Eurobin',
  'Eurville',
  'Euscheid',
  'Eusébio',
  'Euskirchen',
  'Eustis',
  'Euthal',
  'Eutin',
  'Eutingen',
  'Euxton',
  'Eußenheim',
  'Evanger',
  'Evans',
  'Evans City',
  'Evanston',
  'Evansville',
  'Eveleth',
  'Even Yehuda',
  'Evere',
  'Everett',
  'Evergem',
  'Everglades City',
  'Evergreen',
  'Evergreen Park',
  'Everleigh',
  'Everly',
  'Everöd',
  'Eversholt',
  'Evesham',
  'Evilard',
  'Evington',
  'Evje',
  'Evron',
  'Ewa Beach',
  'Ewell',
  'Ewenny',
  'Ewhurst',
  'Ewijk',
  'Ewing',
  'Ewood Bridge',
  'Excelsior',
  'Excelsior Springs',
  'Exeter',
  'Exhall',
  'Exmore',
  'Exmouth',
  'Export',
  'Exton',
  'Eybens',
  'Eye',
  'Eyendorf',
  'Eygelshoven',
  'Eyguières',
  'Eyholz',
  'Eynatten',
  'Eynsford',
  'Eynsham',
  'Eyrarbakki',
  'Eysines',
  'Eysins',
  'Eyüp',
  'Ezhou',
  'Ezinge',
  'Ezpeleta',
  'Eßlingen',
  'Écharcon',
  'Échirolles',
  'Écija',
  'Éclose',
  'École',
  'Écouen',
  'Écuillé',
  'Écully',
  'Édern',
  'Éghezée',
  'Égly',
  'Égreville',
  'Éguilles',
  'Éibar',
  'Élancourt',
  'Émerainville',
  'Épaignes',
  'Épernay',
  'Épervans',
  'Épila',
  'Épinal',
  'Épinay-sous-sénart',
  'Épinay-sur-orge',
  'Épinay-sur-seine',
  'Épouville',
  'Épône',
  'Épuisay',
  'Éragny',
  'Éragny-sur-epte',
  'Érd',
  'Étainhus',
  'Étalle',
  'Étampes',
  'Étang',
  'Étaples',
  'Étaules',
  'Étinehem',
  'Étival-clairefontaine',
  'Étoile-sur-rhône',
  'Étouy',
  'Étréchy',
  'Étrépagny',
  'Évecquemont',
  'Évora',
  'Évreux',
  'Évry',
  'Ézy-sur-eure',
  'Faaborg',
  'Faarvang',
  'Fabbrico',
  'Fabrègues',
  'Fabriano',
  'Fabryczna',
  'Factoryville',
  'Faenza',
  'Fagagna',
  'Fagernes',
  'Fagersta',
  'Fagerstrand',
  'Fagnano Olona',
  'Fahaheel',
  'Fahrenzhausen',
  'Fahrwangen',
  'Faido',
  'Failsworth',
  'Fair Grove',
  'Fair Haven',
  'Fair Lawn',
  'Fair Oaks',
  'Fairacres',
  'Fairbanks',
  'Fairborn',
  'Fairburn',
  'Fairbury',
  'Fairfax',
  'Fairfax Station',
  'Fairfield',
  'Fairfield Bay',
  'Fairford',
  'Fairhaven',
  'Fairhope',
  'Fairland',
  'Fairlawn',
  'Fairlee',
  'Fairless Hills',
  'Fairlie',
  'Fairmont',
  'Fairmount',
  'Fairplay',
  'Fairport',
  'Fairton',
  'Fairview',
  'Fairview Heights',
  'Fairwater',
  'Fairy Meadow',
  'Faisalabad',
  'Faison',
  'Faizabad',
  'Fajozes',
  'Fakenham',
  'Fakus',
  'Falaise',
  'Falciano',
  'Falcon Heights',
  'Falconara Marittima',
  'Falconer',
  'Falera',
  'Falerone',
  'Falkenberg',
  'Falkensee',
  'Falkenstein',
  'Falkirk',
  'Falkow',
  'Falköping',
  'Fall City',
  'Fall River',
  'Fallanden',
  'Fallbrook',
  'Fallon',
  'Falls',
  'Falls Church',
  'Falls City',
  'Falls Village',
  'Fallston',
  'Falmer',
  'Falmouth',
  'Falsterbo',
  'Falun',
  'Famagusta',
  'Famalicão',
  'Fameck',
  'Famões',
  'Fampoux',
  'Fancheng',
  'Fangcheng',
  'Fangshan',
  'Fann',
  'Fano',
  'Fanwood',
  'Faoug',
  'Far Hills',
  'Far Rockaway',
  "Fara Gera D'adda",
  'Farciennes',
  'Fareham',
  'Faremoutiers',
  'Fargo',
  'Faribault',
  'Faridabad',
  'Faridkot',
  'Faridpur',
  'Faringdon',
  'Farington',
  'Farley',
  'Farmers Branch',
  'Farmersville',
  'Farmingdale',
  'Farmington',
  'Farmington Hills',
  'Farmingville',
  'Farmland',
  'Farmsum',
  'Farmville',
  'Farnam',
  'Farnborough',
  'Farnham',
  'Farnham Common',
  'Farnley',
  'Farnsfield',
  'Faro',
  'Farol',
  'Farra Di Soligo',
  'Farragut',
  'Farre',
  'Farringdon',
  'Farrington Gurney',
  'Farroupilha',
  'Farrukhabad',
  'Farsley',
  'Farsta',
  'Farsund',
  'Farsø',
  'Farthinghoe',
  'Farum',
  'Farvagny-le-petit',
  'Farwell',
  'Fassifern Valley',
  'Fastiv',
  'Fate',
  'Fatehabad',
  'Fatehpur',
  'Fatick',
  'Fatih',
  'Fauglia',
  'Faulbach',
  'Faulquemont',
  'Faverges',
  'Faversham',
  'Fawkham',
  'Fawkner',
  'Fawley',
  'Fay-aux-loges',
  'Fay-sur-lignon',
  'Fayence',
  'Fayette',
  'Fayetteville',
  'Faygate',
  'Fayt-le-franc',
  'Fazana',
  'Fazilka',
  'Fársala',
  'Fátima',
  'Fällanden',
  'Färgelanda',
  'Färila',
  'Färjestaden',
  'Fågelsången',
  'Fårevejle',
  'Fårup',
  'Feasterville Trevose',
  'Featherstone',
  'Fechenheim',
  'Federal',
  'Federal Way',
  'Federalsburg',
  'Feeding Hills',
  'Feering',
  'Fegersheim',
  'Fehraltorf',
  'Feillens',
  'Feira',
  'Feira De Santana',
  'Felanitx',
  'Felben',
  'Felcsút',
  'Feldafing',
  'Feldberg',
  'Feldbrunnen',
  'Feldgeding',
  'Feldkirch',
  'Feldkirchen',
  'Feldkirchen In Karnten',
  'Feldkirchen-westerham',
  'Feldmeilen',
  'Felino',
  'Felixstowe',
  'Fellbach',
  'Fellsmere',
  'Felsopakony',
  'Felsozsolca',
  'Feltham',
  'Felton',
  'Feltre',
  'Fen Stanton',
  'Fendalton',
  'Fene',
  'Fenelon Falls',
  'Fengcheng',
  'Fengersfors',
  'Fenggang',
  'Fenghua',
  'Fengtai',
  'Fengting',
  'Fengxian',
  'Fengxiang',
  'Fengxin',
  'Fengyang',
  'Feni',
  'Fennimore',
  'Fenouillet',
  'Fensdorf',
  'Fenton',
  'Fenyang',
  'Ferdinand',
  'Ferentino',
  'Fergus',
  'Fergus Falls',
  'Fermo',
  'Fern Down',
  'Fernandina Beach',
  'Fernán-núñez',
  'Ferndale',
  'Ferney',
  'Ferney-voltaire',
  'Fernhurst',
  'Fernie',
  'Fernley',
  'Ferno',
  'Ferns',
  'Ferntree Gully',
  'Fernwood',
  'Ferny Creek',
  'Ferozepur',
  'Ferran',
  'Ferrand',
  'Ferrandina',
  'Ferrania',
  'Ferrara',
  'Ferraz De Vasconcelos',
  'Ferreira Do Alentejo',
  'Ferriday',
  'Ferrière',
  'Ferrières',
  'Ferrières-en-gâtinais',
  'Ferris',
  'Ferrisburg',
  'Ferrol',
  'Ferrum',
  'Ferryhill',
  'Fertoszentmiklos',
  'Fesches-le-châtel',
  'Festus',
  'Fethiye',
  'Feucherolles',
  'Feucht',
  'Feuchtwangen',
  'Feuchy',
  'Feuerthalen',
  'Feuquières',
  'Feuquières-en-vimeu',
  'Feurs',
  'Feusdorf',
  'Feusisberg',
  'Fexhe-le-haut-clocher',
  'Feytiat',
  'Feyzin',
  'Fez',
  'Fès',
  'Fécamp',
  'Fégréac',
  'Félines',
  'Fénols',
  'Férolles-attilly',
  'Fiano Romano',
  'Fiddletown',
  'Fidenza',
  'Fideris',
  'Fieberbrunn',
  'Fields Landing',
  'Fierbinti',
  'Fiesole',
  "Fiesso D'artico",
  'Fiez',
  'Fife',
  'Figarol',
  'Figeac',
  'Figeholm',
  'Figino',
  'Figueira',
  'Figueira Da Foz',
  'Figueiró Dos Vinhos',
  'Figueras',
  'Figueroles',
  'Fijnaart',
  'Filadelfia',
  'Filaga',
  'Filderstadt',
  'Filer',
  'Filey',
  'Filipstad',
  'Filleigh',
  'Fillinges',
  'Fillmore',
  'Fillongley',
  'Filothéi',
  'Filottrano',
  'Filsum',
  'Filton',
  'Finale Emilia',
  'Finale Ligure',
  'Finance',
  'Finans',
  'Finca Elisa',
  'Fincastle',
  'Finch',
  'Finchampstead',
  'Finchingfield',
  'Finchley',
  'Findel',
  'Findhorn',
  'Findikli',
  'Findlay',
  'Findon',
  'Finedon',
  'Finestrat',
  'Finglas',
  'Finkenwerder',
  'Finksburg',
  'Finland',
  'Finley',
  'Finnentrop',
  'Fino Mornasco',
  'Finsbury',
  'Finsing',
  'Finspång',
  'Finsterwalde',
  'Fiorano Modenese',
  'Firebaugh',
  'Firenze',
  'Firestone',
  'Firminy',
  'Firozpur',
  'Fischbach',
  'Fischbachau',
  'Fischen',
  'Fischenthal',
  'Fisciano',
  'Fish Creek',
  'Fishburn',
  'Fishers',
  'Fishers Island',
  'Fishersville',
  'Fisherville',
  'Fishguard',
  'Fishing Creek',
  'Fishkill',
  'Fishponds',
  'Fisk',
  'Fiskars',
  'Fiskdale',
  'Fiskerton',
  'Fismes',
  'Fitchburg',
  'Fitjar',
  'Fittstown',
  'Fitzgerald',
  'Fitzroy',
  'Fitzroy North',
  'Fiuggi',
  'Fiumana',
  'Fiume Veneto',
  'Fiumicino',
  'Five Points',
  'Fivemiletown',
  'Fjälkinge',
  'Fjällbacka',
  'Fjärås',
  'Fjärdhundra',
  'Fjell',
  'Fjerdingen',
  'Fjugesta',
  'Flaach',
  'Flachslanden',
  'Flacht',
  'Flagler Beach',
  'Flagstaff',
  'Flagtown',
  'Flamatt',
  'Flamengo',
  'Flanders',
  'Flandreau',
  'Flat Rock',
  'Flaten',
  'Flatlands',
  "Flatt's Village",
  'Flatwoods',
  'Flavigny-sur-moselle',
  'Flavin',
  'Flawil',
  'Flax Bourton',
  'Flechtdorf',
  'Flechtorf',
  'Fleet',
  'Fleetwood',
  'Flein',
  'Fleix',
  'Flekkefjord',
  'Fleming Island',
  'Flemingsburg',
  'Flemington',
  'Flemming',
  'Flen',
  'Fleninge',
  'Flensburg',
  'Flensburg-weiche',
  'Flero',
  'Flers',
  'Flers-en-escrebieux',
  'Flesland',
  'Fletcher',
  'Fleurance',
  'Fleurbaix',
  'Fleurier',
  "Fleurieux-sur-l'arbresle",
  'Fleurines',
  'Fleurus',
  'Fleury-les-aubrais',
  'Fléac',
  'Flémalle-grande',
  'Flémalle-haute',
  'Fléron',
  'Fléville-devant-nancy',
  'Flieden',
  'Fliess',
  'Flims',
  'Flims Waldhaus',
  'Flin Flon',
  'Flinders',
  'Flines-lès-raches',
  'Flins-sur-seine',
  'Flint',
  'Flint Hill',
  'Flintbek',
  'Flippin',
  'Flisaker',
  'Flitwick',
  'Flixecourt',
  'Floby',
  'Flockton',
  'Floda',
  'Floirac',
  'Flomaton',
  'Flookburgh',
  'Flora',
  'Flora Vista',
  'Floral Park',
  'Florange',
  'Floreffe',
  'Florence',
  'Florenceville',
  'Florencia',
  'Florensac',
  'Florenville',
  'Flores Da Cunha',
  'Floresti',
  'Floresville',
  'Floréal',
  'Florham Park',
  'Floriano',
  'Florianópolis',
  'Florida',
  'Floridablanca',
  'Floridia',
  'Florissant',
  'Florø',
  'Flossmoor',
  'Flourens',
  'Flourtown',
  'Flower Mound',
  'Flowery Branch',
  'Flowood',
  'Floyd',
  'Floyds Knobs',
  'Floß',
  'Flórina',
  'Flöha',
  'Flörsheim',
  'Flörsheim Am Main',
  'Flums',
  'Fluorn',
  'Flurlingen',
  'Flushing',
  'Flühli',
  'Flyinge',
  'Fløng',
  'Foam Lake',
  'Fochville',
  'Fockbek',
  'Focsani',
  'Foetz',
  'Fogelsville',
  'Foggia',
  'Fohnsdorf',
  'Fohren',
  'Fohren-linden',
  'Foiano Della Chiana',
  'Fokovci',
  'Folcroft',
  'Foley',
  'Folignano',
  'Foligno',
  'Folkestone',
  'Follansbee',
  'Folldal',
  'Follina',
  'Follonica',
  'Folly Beach',
  'Folschviller',
  'Folsom',
  'Fombell',
  'Fonbeauzard',
  'Foncine-le-haut',
  'Fond Du Lac',
  'Fond Du Sac',
  'Fonda',
  'Fondarella',
  'Fondettes',
  'Fondo',
  'Fontain',
  'Fontaine',
  'Fontaine-Étoupefour',
  'Fontaine-la-guyon',
  'Fontaine-le-comte',
  'Fontaine-le-port',
  'Fontaine-lès-dijon',
  'Fontaine-raoul',
  'Fontainebleau',
  'Fontainemelon',
  'Fontaines',
  'Fontaines-sur-saône',
  'Fontana',
  'Fontanafredda',
  'Fontanellato',
  'Fontanelle',
  "Fontaneto D'agogna",
  'Fontanil-cornillon',
  'Fontcuberta',
  'Fontenay',
  'Fontenay-aux-roses',
  'Fontenay-le-comte',
  'Fontenay-le-fleury',
  'Fontenay-sous-bois',
  'Fontenay-trésigny',
  'Fontenilles',
  'Fontevivo',
  'Fonthill',
  'Fonyód',
  'Fonzaso',
  'Foochow',
  'Foothill Ranch',
  'Footscray',
  'Forano',
  'Forbach',
  'Forcalquier',
  'Forchheim',
  'Ford',
  'Forde',
  'Fordham',
  'Fordingbridge',
  'Fords',
  'Fordyce',
  'Forel',
  'Forest',
  'Forest City',
  'Forest Falls',
  'Forest Grove',
  'Forest Hill',
  'Forest Hills',
  'Forest Lake',
  'Forest Lakes',
  'Forest Park',
  'Forest Row',
  'Forest-sur-marque',
  'Foreston',
  'Forestville',
  'Forfar',
  'Foristell',
  'Fork Union',
  'Forked River',
  'Forli',
  'Forlimpopoli',
  'Forlì',
  'Formby',
  'Formello',
  'Formentera Del Segura',
  'Formia',
  'Formigine',
  'Formosa',
  'Fornåsa',
  'Fornebu',
  'Fornells De La Selva',
  'Forney',
  "Forni Di Val D'astico",
  'Fornovo Di Taro',
  'Forres',
  'Forrest',
  'Forrest City',
  'Forsa',
  'Forserum',
  'Forshaga',
  'Forsheda',
  'Forssa',
  'Forst',
  'Forster',
  'Forstern',
  'Forstinning',
  'Forsyth',
  'Fort Atkinson',
  'Fort Belvoir',
  'Fort Benton',
  'Fort Bonifacio',
  'Fort Bragg',
  'Fort Cobb',
  'Fort Collins',
  'Fort Defiance',
  'Fort Dodge',
  'Fort Erie',
  'Fort Frances',
  'Fort George G Meade',
  'Fort Gratiot',
  'Fort Huachuca',
  'Fort Lauderdale',
  'Fort Leavenworth',
  'Fort Lee',
  'Fort Loramie',
  'Fort Lupton',
  'Fort Madison',
  'Fort Mcmurray',
  'Fort Meade',
  'Fort Mill',
  'Fort Mitchell',
  'Fort Monmouth',
  'Fort Morgan',
  'Fort Myers',
  'Fort Myers Beach',
  'Fort Nelson',
  'Fort Payne',
  'Fort Pierce',
  'Fort Pierre',
  'Fort Recovery',
  'Fort Ripley',
  'Fort Rucker',
  'Fort Saint John',
  'Fort Saskatchewan',
  'Fort Scott',
  'Fort Smith',
  'Fort Stockton',
  'Fort Thomas',
  'Fort Totten',
  'Fort Valley',
  'Fort Walton Beach',
  'Fort Washington',
  'Fort Wayne',
  'Fort White',
  'Fort William',
  'Fort Worth',
  'Fort Yates',
  'Fort Yukon',
  'Fort-de-France',
  'Fort-mardyck',
  'Fortaleza',
  'Forte Dei Marmi',
  'Fortine',
  'Fortín De Las Flores',
  'Forton',
  'Fortress Hill',
  'Fortuna',
  'Fortuneswell',
  'Fortville',
  'Fos-sur-mer',
  'Foshan',
  'Fosnavåg',
  'Fossalta',
  'Fossalta Di Portogruaro',
  'Fossano',
  'Fossato',
  'Fossato Di Vico',
  'Fosse',
  'Fossoy',
  'Fossò',
  'Fosston',
  'Foster City',
  'Fostoria',
  'Fotö',
  'Fotskäl',
  'Fouchana',
  'Foucherans',
  'Fouchy',
  'Fouesnant',
  'Fougères',
  'Fougères-sur-bièvre',
  'Fouilloy',
  'Fouka',
  'Foulridge',
  'Foulum',
  'Founex',
  'Fountain',
  'Fountain Hills',
  'Fountain Inn',
  'Fountain Run',
  'Fountain Valley',
  'Four',
  'Four Marks',
  'Four Oaks',
  'Fourchambault',
  'Fourmetot',
  'Fourmies',
  'Fourques',
  'Fourqueux',
  'Fourways',
  'Fowey',
  'Fowler',
  'Fowlerville',
  'Fownhope',
  'Fox Island',
  'Fox Lake',
  'Fox Valley',
  'Foxboro',
  'Foxhol',
  'Foxley',
  'Foxrock',
  'Foxworth',
  'Foz',
  'Foz Do Iguaçu',
  'Fót',
  'Föhren',
  'Föllinge',
  'Fördergersdorf',
  'Frachet',
  'Fraga',
  'Fragnes',
  'Fraire',
  'Fraiture',
  'Fram',
  'Frameries',
  'Framersheim',
  'Framingham',
  'Framlingham',
  'Frammersbach',
  'Franca',
  'Francavilla Al Mare',
  'Francavilla Fontana',
  'France',
  'Franceau',
  'Franceix',
  'Frances',
  'Francescas',
  'Francesco',
  'Francesville',
  'Francheleins',
  'Franchet',
  'Francheville',
  'Francin',
  'Francisco',
  'Francisco Beltrão',
  'Francistown',
  'Franconia',
  'Franconville',
  'França',
  'Franeker',
  'Franken',
  'Frankenberg',
  'Frankenfeld',
  'Frankenmarkt',
  'Frankenmuth',
  'Frankenthal',
  'Frankershausen',
  'Frankford',
  'Frankfort',
  'Frankfurt',
  'Frankfurt An Der Oder',
  'Franklin',
  'Franklin Furnace',
  'Franklin Lakes',
  'Franklin Park',
  'Franklin Springs',
  'Franklin Square',
  'Franklinton',
  'Franklinville',
  'Frankston',
  'Franksville',
  'Frankton',
  'Franktown',
  'Frant',
  'Frantiskov',
  'Frascati',
  'Fraser',
  'Fraserburgh',
  'Frasne',
  'Frasnes-lez-gosselies',
  'Frassino',
  'Frattamaggiore',
  'Fratticciola Selvatica',
  'Fraubrunnen',
  'Frauenfeld',
  'Frauenholz',
  'Frauenkappelen',
  'Fraunberg',
  'Fraureuth',
  'Frazeysburg',
  'Frazier Park',
  'Frändefors',
  'Fränkisch-crumbach',
  'Freamunde',
  'Frechen',
  'Frechenrieden',
  'Fredensborg',
  'Frederic',
  'Frederica',
  'Fredericia',
  'Frederick',
  'Fredericksburg',
  'Frederickton',
  'Fredericktown',
  'Frederico Westphalen',
  'Fredericton',
  'Frederiksberg',
  'Frederikshavn',
  'Frederikssund',
  'Frederiksværk',
  'Fredersdorf',
  'Fredonia',
  'Fredriksberg',
  'Fredrikstad',
  'Freeburg',
  'Freedom',
  'Freehold',
  'Freeland',
  'Freeman',
  'Freemans Reach',
  'Freeport',
  'Freetown',
  'Fregona',
  'Freiberg',
  'Freiberg Am Neckar',
  'Freiburg',
  'Freiburg Im Breisgau',
  'Freienbach',
  'Freilassing',
  'Freinsheim',
  'Freisbach',
  'Freising',
  'Freistadt',
  'Freistett',
  'Freital',
  'Freitas',
  'Freiwalde',
  'Frekhaug',
  'Fremantle',
  'Fremington',
  'Fremont',
  'French Camp',
  'French Lick',
  'French Village',
  'Frenchtown',
  'Freneuse',
  'Frenkendorf',
  'Frensdorf',
  'Frensham',
  'Frenstat',
  'Frenstat Pod Radhostem',
  'Fresh Meadows',
  'Freshwater',
  'Fresneaux-montchevreuil',
  'Fresnes',
  'Fresnes-sur-marne',
  'Fresnillo',
  'Fresno',
  'Fresno De Torote',
  'Fresno Del Camino',
  'Fresnoy-le-grand',
  'Fretin',
  'Freudenberg',
  'Freudenstadt',
  'Freyburg',
  'Freystadt',
  'Freyung',
  'Fréchet-aure',
  'Fréjus',
  'Frélinghien',
  'Frépillon',
  'Fribourg',
  'Friburgo',
  'Frick',
  'Frickenhausen',
  'Frickhofen',
  'Fricovce',
  'Friday Harbor',
  'Fridingen',
  'Fridingen An Der Donau',
  'Friedberg',
  'Friedburg',
  'Friedeburg',
  'Friedenau',
  'Friedens',
  'Friedersdorf',
  'Friedewald',
  'Friedland',
  'Friedrichroda',
  'Friedrichsdorf',
  'Friedrichshafen',
  'Friedrichshain',
  'Friedrichskoog',
  'Friedrichsruh',
  'Friedrichstadt',
  'Friedrichsthal',
  'Friedrichswalde',
  'Frielas',
  'Friendsville',
  'Friendswood',
  'Friesenhagen',
  'Friesenheim',
  'Friesland',
  'Friesoythe',
  'Frillesås',
  'Frimley',
  'Frintrop',
  'Friolzheim',
  'Frisco',
  'Fristad',
  'Friston',
  'Fritzens',
  'Fritzlar',
  'Frodsham',
  'Frogner',
  'Frohburg',
  'Frohnleiten',
  'Froideville',
  'Froland',
  'Frome',
  'Fronhausen',
  'Front Royal',
  'Frontenac',
  'Frontenay-rohan-rohan',
  'Frontera',
  'Frontera Comalapa',
  'Frontignan',
  'Frontonas',
  'Frosinone',
  'Frosolone',
  'Frostburg',
  'Frostproof',
  'Frouard',
  'Froyennes',
  'Frölunda',
  'Fröndenberg',
  'Frösön',
  'Frövi',
  'Frufällan',
  'Fruita',
  'Fruitland',
  'Fruitland Park',
  'Fruitport',
  'Frunze',
  'Frutal',
  'Frutigen',
  'Frydek-mistek',
  'Frydlant',
  'Frydlant Nad Ostravici',
  'Fryeburg',
  'Frystak',
  'Frýdek-místek',
  'Ft Mitchell',
  'Ft Myer',
  'Fucecchio',
  'Fucheng',
  'Fuchou',
  'Fuchsstadt',
  'Fuchu',
  'Fuchun',
  'Fuding',
  'Fuefuki',
  'Fuengirola',
  'Fuenlabrada',
  'Fuenlabrada De Los Montes',
  'Fuenmayor',
  'Fuensalida',
  'Fuente De Cantos',
  'Fuente El Saz',
  'Fuente-Álamo',
  'Fuentealbilla',
  'Fuentes',
  'Fuentes De Andalucía',
  'Fugen',
  'Fuhou',
  'Fujairah',
  'Fuji',
  'Fujiang',
  'Fujieda',
  'Fujimi',
  'Fujimino',
  'Fujinomiya',
  'Fujisawa',
  'Fukaya',
  'Fukuchiyama',
  'Fukue',
  'Fukui',
  'Fukui-shi',
  'Fukuoka',
  'Fukuoka-shi',
  'Fukuroi',
  'Fukushima',
  'Fukushima-shi',
  'Fukuyama',
  'Fulbourn',
  'Fulda',
  'Fuldabrück',
  'Fulenbach',
  'Fulford',
  'Fulham',
  'Fuling',
  'Fulks Run',
  'Full',
  'Fullerton',
  'Fullinsdorf',
  'Fulmer',
  'Fulnek',
  'Fulshear',
  'Fulton',
  'Fultonville',
  'Fulwood',
  'Fumel',
  'Funabashi',
  'Funäsdalen',
  'Funchal',
  'Fundão',
  'Funza',
  'Fuqing',
  'Fuquan',
  'Fuquay Varina',
  'Furabeshi',
  'Furano',
  'Fureidis',
  'Furiani',
  'Furlong',
  'Furneux Pelham',
  'Furth',
  'Furth Im Wald',
  'Furtwangen',
  'Furukawa',
  'Furulund',
  'Furuvik',
  'Fusagasuga',
  'Fuschl Am See',
  'Fuse',
  'Fusha',
  'Fushan',
  'Fushimi',
  'Fushun',
  'Fusignano',
  'Fustiñana',
  'Futenma',
  'Futian',
  'Fuxin',
  'Fuyang',
  'Fuyong',
  'Fuzhou',
  'Füllinsdorf',
  'Fürnitz',
  'Fürstenfeldbruck',
  'Fürstenwalde',
  'Fürth',
  'Fürthen',
  'Füssen',
  'Füzesabony',
  'Fyffe',
  'Fyfield',
  'Fyshwick',
  'Førde',
  'Gaaden',
  'Gaanderen',
  'Gaastmeer',
  'Gaberones',
  'Gabia Grande',
  'Gablingen',
  'Gaborone',
  'Gabrovo',
  'Gachnang',
  'Gackenbach',
  'Gadag',
  'Gadbjerg',
  'Gadebusch',
  'Gadish',
  'Gadki',
  'Gadot',
  'Gadsden',
  'Gadstrup',
  'Gaerwen',
  'Gaeta',
  'Gaël',
  'Gafanha Da Nazaré',
  'Gaffney',
  'Gaggenau',
  'Gaggiano',
  'Gaggio',
  'Gaggio Montano',
  'Gagnac-sur-cère',
  'Gagnef',
  'Gagny',
  'Gaia',
  'Gaiberg',
  'Gaildorf',
  'Gailey',
  'Gaillac',
  'Gaillac-toulza',
  'Gaillard',
  'Gaillon',
  'Gaimersheim',
  'Gainesville',
  'Gainsborough',
  'Gais',
  'Gaithersburg',
  'Gaißach',
  'Gajano',
  'Galanta',
  'Galapagar',
  'Galar',
  'Galashiels',
  'Galata',
  'Galati',
  'Galax',
  'Galátsi',
  'Galátsion',
  'Galdácano',
  'Galena',
  'Galena Park',
  'Galesburg',
  'Galgorm',
  'Galilea',
  'Galion',
  'Galiza',
  'Gallarate',
  'Gallardon',
  'Gallarta',
  'Gallatin',
  'Gallen',
  'Galleywood',
  'Galliano',
  'Galliate',
  'Galliera Veneta',
  'Gallipoli',
  'Gallipolis',
  'Galloway',
  'Gallows Hill',
  'Gallspach',
  'Galluis',
  'Gallup',
  'Gallur',
  'Galmaarden',
  'Galmiz',
  'Galong',
  'Gals',
  'Galt',
  'Galten',
  'Galva',
  'Galvana',
  'Galveston',
  'Galway',
  'Gamagori',
  'Gambais',
  'Gambang',
  'Gambara',
  'Gambellara',
  'Gambier',
  'Gambir',
  'Gambrills',
  'Gameren',
  'Gamleby',
  'Gampaha',
  'Gampel',
  'Gampelen',
  'Gams',
  'Gamsen',
  'Gan Hadarom',
  'Gan Shomron',
  'Gan Yavne',
  'Gan Yoshiya',
  'Ganado',
  'Gananoque',
  'Ganapavaram',
  'Gand',
  'Gandarela',
  'Gander',
  'Ganderbal',
  'Ganderkesee',
  'Gandhidham',
  'Gandhigram',
  'Gandhinagar',
  'Gandino',
  'Gandía',
  'Gandra',
  'Gandrange',
  'Ganganagar',
  'Ganges',
  'Gangkofen',
  'Gangmei',
  'Gangneung',
  'Gangtok',
  'Ganjingzi',
  'Ganløse',
  'Gannat',
  'Ganne Tiqwa',
  'Ganot',
  'Ganserndorf',
  'Gansevoort',
  'Ganshmuel',
  'Ganshoren',
  'Ganterschwil',
  'Gantofta',
  'Ganzhou',
  'Ganzlin',
  'Gaocheng',
  'Gaomi',
  'Gaotang',
  'Gaoxin',
  'Gaoyang',
  'Gaoyao',
  'Gaoyou',
  'Gaozhou',
  'Gap',
  'Garanhuns',
  'Garbagnate Milanese',
  'Garbenteich',
  'Garberville',
  'Garbsen',
  'Garches',
  'Garching',
  'Garching Bei München',
  'Garcia',
  'García',
  'Garça',
  'Garda',
  'Gardanne',
  'Gardelegen',
  'Garden City',
  'Garden City Park',
  'Garden Grove',
  'Garden Valley',
  'Gardena',
  'Gardendale',
  'Gardermoen',
  'Gardiner',
  'Gardner',
  'Gardners',
  'Gardnerville',
  'Gardone Val Trompia',
  'Gardonne',
  'Garennes',
  'Garesnica',
  'Garfield',
  'Garford',
  'Garforth',
  'Garganta De Los Montes',
  'Gargas',
  'Gargenville',
  'Garges-lès-gonesse',
  'Gargett',
  'Gargrave',
  'Garhwal',
  'Garibaldi',
  'Garigliano',
  'Garin',
  'Garki',
  'Garland',
  'Garliava',
  'Garmisch-partenkirchen',
  'Garner',
  'Garnerans',
  'Garnerville',
  'Garnet Valley',
  'Garnett',
  'Garopaba',
  'Garoua',
  'Garowe',
  'Garphyttan',
  'Garray',
  'Garrel',
  'Garretson',
  'Garrett',
  'Garrett Park',
  'Garrettsville',
  'Garrison',
  'Garrisonville',
  'Garristown',
  'Gars',
  'Garsington',
  'Garstang',
  'Garstedt',
  'Gartenfeld',
  'Garton',
  'Garvagh',
  'Garve',
  'Garville',
  'Garwood',
  'Gary',
  'Garza',
  'Garza García',
  'Garzón',
  'Garðabær',
  'Gas City',
  'Gasandong',
  'Gasperich',
  'Gaspé',
  'Gassaway',
  'Gasse',
  'Gastão Vidigal',
  'Gaston',
  'Gastonia',
  'Gat Rimon',
  'Gata De Gorgos',
  'Gataia',
  'Gate City',
  'Gatersleben',
  'Gates Mills',
  'Gateshead',
  'Gatesville',
  'Gatineau',
  'Gatlinburg',
  'Gatwick',
  'Gau-algesheim',
  'Gau-odernheim',
  'Gauchy',
  'Gauhati',
  'Gaukönigshofen',
  'Gaupen',
  'Gaurain-ramecroix',
  'Gauribidanur',
  'Gauteng',
  'Gautier',
  'Gauting',
  'Gavardo',
  'Gavá',
  'Gavere',
  'Gavirate',
  'Gawcott',
  'Gawler',
  'Gaya',
  'Gaydon',
  'Gaylord',
  'Gays Mills',
  'Gaza',
  'Gazeley',
  'Gaziantep',
  'Gazimagusa',
  'Gazipur',
  'Gazit',
  'Gazoldo Degli Ippoliti',
  'Gazzada',
  'Gárdony',
  'Gávea',
  'Gäddede',
  'Gällivare',
  'Gällstad',
  'Gärsnäs',
  'Gärtringen',
  'Gävle',
  'Gånghester',
  'Gbagi',
  'Gbely',
  'Gdansk',
  'Gdynia',
  'Gebenstorf',
  'Gebesee',
  'Gebze',
  'Gechingen',
  'Gedera',
  'Gediz',
  'Gedsted',
  'Geel',
  'Geelong',
  'Geelong West',
  'Geertruidenberg',
  'Gees',
  'Geest',
  'Geeste',
  'Geesteren',
  'Geesthacht',
  'Gefen',
  'Geham',
  'Gehrden',
  'Geilenkirchen',
  'Geilo',
  'Geisa',
  'Geiselgasteig',
  'Geiselhöring',
  'Geisenfeld',
  'Geisenhausen',
  'Geisenheim',
  'Geislingen',
  'Geismar',
  'Geispolsheim',
  'Geithain',
  'Gejiu',
  'Gela',
  'Gelang Patah',
  'Geldermalsen',
  'Geldern',
  'Geldrop',
  'Geleen',
  'Gelenau',
  'Gelfingen',
  'Gelibolu',
  'Gellainville',
  'Gelnhausen',
  'Gelnica',
  'Gelsdorf',
  'Gelsenkirchen',
  'Geltendorf',
  'Gelterkinden',
  'Gelting',
  'Geluwe',
  'Gelves',
  'Gembloux',
  'Gemeinlebarn',
  'Gemert',
  'Gemla',
  'Gemlik',
  'Gemmingen',
  'Gemmrigheim',
  'Gemonde',
  'Gempen',
  'Gemunde',
  'Gemünden',
  'Genappe',
  'Genas',
  'Genay',
  'Gencsapáti',
  'Genderkingen',
  'Gendt',
  'Genemuiden',
  'General Lagos',
  'General Santos City',
  'General Trias',
  'Genesee Depot',
  'Geneseo',
  'Geneva',
  'Geneve',
  'Genève',
  'Genf',
  'Genga',
  'Gengenbach',
  'Genk',
  'Genlis',
  'Gennep',
  'Gennevilliers',
  'Genoa',
  'Genoa City',
  'Genouilleux',
  'Genova',
  'Gensac-la-pallue',
  'Gensingen',
  'Gent',
  'Gentbrugge',
  'Genthin',
  'Gentilly',
  'Gentofte',
  'Genval',
  'Genvry',
  'Genzano Di Roma',
  'George',
  'George Town',
  'George West',
  'Georgenberg',
  'Georgensgmünd',
  'Georges',
  'Georges Mills',
  'Georgetown',
  'Georgia',
  'Georgievsk',
  'Georgsmarienhütte',
  'Gera',
  'Gera-zwötzen',
  'Geraardsbergen',
  'Gerais',
  'Gerald',
  'Geraldton',
  'Gerasdorf',
  'Gerasdorf Bei Wien',
  'Gerås',
  'Gerbrunn',
  'Gerdshagen',
  'Gerenzago',
  'Geretsried',
  'Gering',
  'Geringswalde',
  'Gerlafingen',
  'Gerlingen',
  'Germaine',
  'German',
  'Germansville',
  'Germantown',
  'Germany',
  'Germering',
  'Germersheim',
  'Gerning',
  'Gernsbach',
  'Gernsheim',
  'Gerolsbach',
  'Gerolstein',
  'Gerona',
  'Gerosa',
  'Geroskipou',
  'Gerpinnes',
  'Gerringong',
  'Gersau',
  'Gersfeld',
  'Gersheim',
  'Gersthofen',
  'Gerstungen',
  'Gertrudes',
  'Gerzat',
  'Gescher',
  'Geschwenda',
  'Geseke',
  'Gesher Haziv',
  'Gesnes-le-gandelin',
  'Gessate',
  'Gestratz',
  'Gesves',
  'Getafe',
  'Getaria',
  'Getinge',
  'Gettnau',
  'Gettorf',
  'Gettysburg',
  'Getúlio Vargas',
  'Getzersdorf',
  'Getzville',
  'Geuensee',
  'Gevataym',
  'Gevelsberg',
  'Gevrey-chambertin',
  'Geyserville',
  'Géfosse-fontenay',
  'Géfyra',
  'Gélida',
  'Gémenos',
  'Gémozac',
  'Génelard',
  'Génicourt',
  'Génissieux',
  'Gérardmer',
  'Gétigné',
  'Ghana',
  'Ghandinagar',
  'Ghansoli',
  'Gharaunda',
  'Ghatkopar',
  'Ghaziabad',
  'Ghazibad',
  'Ghazipur',
  'Ghazir',
  'Ghazni',
  'Ghent',
  'Gheorgheni',
  'Ghimbav',
  'Ghiroda',
  'Ghislenghien',
  'Ghizzano',
  'Ghlin',
  'Giannitsá',
  'Gianyar',
  'Giaveno',
  'Giavera Del Montello',
  'Gibbsboro',
  'Gibraltar',
  'Gibsland',
  'Gibson',
  'Gibson City',
  'Gibsonburg',
  'Gibsonia',
  'Gibsons',
  'Gibsonton',
  'Gibsonville',
  'Gibswil',
  'Giddarbaha',
  'Giddings',
  'Giebelstadt',
  'Giebing',
  'Gien',
  'Giengen',
  'Giesbeek',
  'Giesen',
  'Giessen',
  'Giessenburg',
  'Giessendam',
  'Gieten',
  'Giethoorn',
  'Gietrzwald',
  'Gießen',
  'Gießen-wieseck',
  'Gießhübl',
  'Gières',
  'Gif-sur-yvette',
  'Giffers',
  'Giffnock',
  'Giffoni Valle Piana',
  'Gifhorn',
  'Gifu',
  'Gifu-shi',
  'Gig Harbor',
  'Gigean',
  'Gignac-la-nerthe',
  'Giheung',
  'Gijón',
  'Gilau',
  'Gilbert',
  'Gilberts',
  'Gilbertsville',
  'Gilching',
  'Gildersome',
  'Gilford',
  'Gilgit',
  'Gilleleje',
  'Gillenbeuren',
  'Gillett',
  'Gillette',
  'Gilling East',
  'Gillingham',
  'Gilly',
  'Gilly-sur-isère',
  'Gilman',
  'Gilmanton Iron Works',
  'Gilmer',
  'Gilroy',
  'Gilsum',
  'Gilze',
  'Gimli',
  'Gimo',
  'Gimont',
  'Gindie',
  'Ginés',
  'Gingee',
  'Gingen An Der Fils',
  'Ginosar',
  'Ginowan',
  'Ginsheim',
  'Ginsheim-gustavsburg',
  'Ginza',
  'Gioia Del Colle',
  'Gioia Tauro',
  'Giovinazzo',
  'Gipf-oberfrick',
  'Girard',
  'Girardot',
  'Girasol',
  'Giresun',
  'Giridih',
  'Girne',
  'Girona',
  'Gironde',
  'Girraween',
  'Girton',
  'Girvan',
  'Gisborne',
  'Gisenyi',
  'Gislaved',
  'Gislev',
  'Gisors',
  'Gissey-sur-ouche',
  'Gistel',
  'Gistoux',
  'Gistrup',
  'Giswil',
  'Giubiasco',
  'Giugliano In Campania',
  'Giulianova',
  'Giurgiu',
  'Giussago',
  'Giussano',
  "Giv'atayim",
  'Giv`at Olga',
  'Givat Haim',
  'Givat Hayim',
  'Give',
  'Givenchy',
  'Givet',
  'Givors',
  'Givrand',
  'Gizah',
  'Gizzâya',
  'Gîza',
  'Gjerdrum',
  'Gjilan',
  'Gjøvik',
  'Glabais',
  'Glabbeek',
  'Gladau',
  'Gladbach',
  'Gladbeck',
  'Gladenbach',
  'Gladesville',
  'Gladstone',
  'Gladwin',
  'Gladwyne',
  'Glamorgan Vale',
  'Glamsbjerg',
  'Gland',
  'Glandorf',
  'Glanmire',
  'Glanon',
  'Glanshammar',
  'Glarus',
  'Glasgow',
  'Glashütte',
  'Glashütten',
  'Glaslough',
  'Glasnevin',
  'Glassboro',
  'Glassport',
  'Glastonbury',
  'Glatigny',
  'Glattbach',
  'Glattbrugg',
  'Glatten',
  'Glattfelden',
  'Glaubitz',
  'Glauburg',
  'Glauchau',
  'Glavinitsa',
  'Glazebrook',
  'Glebe',
  'Gleichamberg',
  'Gleichen',
  'Gleisdorf',
  'Gleisweiler',
  'Gleizé',
  'Glen Allen',
  'Glen Arbor',
  'Glen Burnie',
  'Glen Carbon',
  'Glen Cove',
  'Glen Dale',
  'Glen Echo',
  'Glen Ellen',
  'Glen Ellyn',
  'Glen Flora',
  'Glen Gardner',
  'Glen Haven',
  'Glen Head',
  'Glen Huntly',
  'Glen Innes',
  'Glen Iris',
  'Glen Mills',
  'Glen Oaks',
  'Glen Osmond',
  'Glen Ridge',
  'Glen Rock',
  'Glen Rose',
  'Glen Spey',
  'Glen Waverley',
  'Glenamaddy',
  'Glenarm',
  'Glenbrook',
  'Glencoe',
  'Glencullin Lower',
  'Glendale',
  'Glendale Heights',
  'Glendive',
  'Glendora',
  'Glenelg',
  'Glenfield',
  'Glenhaven',
  'Glenmont',
  'Glenmoore',
  'Glenn',
  'Glenn Dale',
  'Glenns Ferry',
  'Glennville',
  'Glenolden',
  'Glenorie',
  'Glenrothes',
  'Glenrowan',
  'Glenroy',
  'Glens Falls',
  'Glenshaw',
  'Glenshee',
  'Glenside',
  'Glenview',
  'Glenview Nas',
  'Glenville',
  'Glenwood',
  'Glenwood Springs',
  'Glesborg',
  'Glifádha',
  'Glil Yam',
  'Glimåkra',
  'Glina',
  'Glinde',
  'Glinton',
  'Glion',
  'Glis',
  'Glisy',
  'Gliwice',
  'Globel',
  'Glogow',
  'Glogow Malopolski',
  'Glomfjord',
  'Glonn',
  'Gloppen',
  'Glos',
  'Glos-sur-risle',
  'Gloskow',
  'Glossop',
  'Glostrup',
  'Gloucester',
  'Gloucester City',
  'Gloversville',
  'Glubczyce',
  'Glugor',
  'Glücksburg',
  'Glückstadt',
  'Glyfáda',
  'Gmund',
  'Gmund Am Tegernsee',
  'Gmunden',
  'Gmünd',
  'Gnarp',
  'Gnarrenburg',
  'Gnesta',
  'Gniewkowo',
  'Gniezno',
  'Gnosjö',
  'Goa',
  'Goa Velha',
  'Goalpara',
  'Goatstown',
  'Gobichettipalayam',
  'Gobindgarh',
  'Goch',
  'Gochsheim',
  'Godalming',
  'Godavari',
  "Godega Di Sant'urbano",
  'Godella',
  'Goderich',
  'Godersdorf',
  'Godfrey',
  'Godhra',
  'Godley',
  'Godlinze',
  'Godmanchester',
  'Godo',
  'Godollo',
  'Godoy Cruz',
  'Godstone',
  'Godvik',
  'Goedereede',
  'Goes',
  'Goeteborg',
  'Goetzingen',
  'Goffstown',
  'Goheung',
  'Gohory',
  'Goianésia',
  'Goianira',
  'Goiás',
  'Goiânia',
  'Goio-erê',
  'Goirle',
  'Goito',
  'Gojan',
  'Gojome',
  'Gokarna',
  'Gol',
  'Golaghat',
  'Golbasi',
  'Golbey',
  'Golborne',
  'Gold Canyon',
  'Gold Coast',
  'Gold River',
  'Goldach',
  'Goldap',
  'Goldau',
  'Goldbach',
  'Golden',
  'Golden Eagle',
  'Golden Gate',
  'Golden Grove',
  'Golden Meadow',
  'Golden Spring',
  'Golden Valley',
  'Goldendale',
  'Goldens Bridge',
  'Goldingen',
  'Goldiwil',
  'Goldkronach',
  'Goldsboro',
  'Goldston',
  'Goldswil',
  'Goldthwaite',
  'Goldworth',
  'Goleczewo',
  'Golejow',
  'Goleniów',
  'Goleta',
  'Golf',
  'Golfo Aranci',
  'Goliad',
  'Gollion',
  'Golm',
  'Golmayo',
  'Golmés',
  'Golub-dobrzyn',
  'Golzow',
  'Goma',
  'Gomadingen',
  'Gomaringen',
  'Gombe',
  'Gomel',
  'Gometz-le-châtel',
  'Gommern',
  'Gommiswald',
  'Gomshall',
  'Gonars',
  'Gonda',
  'Gondal',
  'Gondar',
  'Gondecourt',
  'Gondia',
  'Gondomar',
  'Gondreville',
  'Gonesse',
  "Gonfreville-l'orcher",
  'Gongju',
  'Gongyi',
  'Gongzhuling',
  'Gonnosfanadiga',
  'Gontenschwil',
  'Gonzaga',
  'Gonzales',
  'Goochland',
  'Good Hope',
  'Goodells',
  'Gooding',
  'Goodland',
  'Goodlettsville',
  'Goodman',
  'Goodmayes',
  'Goodrich',
  'Goodwell',
  'Goodyear',
  'Gooi',
  'Gooik',
  'Goole',
  'Goor',
  'Goose Bay',
  'Goose Creek',
  'Gora',
  'Gora Kalwaria',
  'Gorakhpur',
  'Gorcy',
  'Gordola',
  'Gordon',
  'Gordonsville',
  'Gordonville',
  'Gorebridge',
  'Goregaon',
  'Goren',
  'Gorey',
  'Gorgan',
  'Gorgo Al Monticano',
  'Gorgonzola',
  'Gorham',
  'Gorinchem',
  'Goring',
  'Goring By Sea',
  'Gorizia',
  'Gorky',
  'Gorle',
  'Gorleston',
  'Gormanston',
  'Gorna Malina',
  'Gorna Oriahovica',
  'Gornau',
  'Gornja Radgona',
  'Gornja Stubica',
  'Gornji Milanovac',
  'Gornji Stupnik',
  'Gorredijk',
  'Gorseinon',
  'Goryachiy Klyuch',
  'Gorzewo',
  'Gorzow Wielkopolski',
  'Gorzów Wielkopolski',
  'Gosford',
  'Gosford Shire',
  'Gosforth',
  'Gosheim',
  'Goshen',
  'Goslar',
  'Gosnay',
  'Gosnells',
  'Gospic',
  'Gosport',
  'Gossau',
  'Gosselies',
  'Gostyn',
  'Gotanda',
  'Gotebo',
  'Goteburg',
  'Gotha',
  'Gothenburg',
  'Gottfrieding',
  'Gottlieben',
  'Gottmadingen',
  'Gottolengo',
  'Gotzis',
  'Gouda',
  'Gouderak',
  'Goudhurst',
  'Gouesnou',
  'Goulburn',
  'Gouldsboro',
  'Goulier',
  'Goulles',
  'Gourdon',
  'Gourin',
  'Gournay-sur-marne',
  'Gourock',
  'Goussainville',
  'Gouveia',
  'Gouvernes',
  'Gouverneur',
  'Goux-les-usiers',
  'Gouy-saint-andré',
  'Governador Valadares',
  'Gowanda',
  'Goxhill',
  'Goxwiller',
  'Gozon',
  'Gozzano',
  'Gójar',
  'Gómez Palacio',
  'Góra Kalwaria',
  'Górliz',
  'Göd',
  'Gödenstorf',
  'Gönnersdorf',
  'Gönnheim',
  'Göppingen',
  'Görisried',
  'Görlitz',
  'Görwihl',
  'Göschwitz',
  'Gösgen',
  'Göslikon',
  'Göteborg',
  'Götene',
  'Göttingen',
  'Götzis',
  'Göztepe',
  'Graauw',
  'Grabels',
  'Graben',
  'Graben-neudorf',
  'Grabenstätt',
  'Grabill',
  'Grabs',
  'Gracanica',
  'Grace',
  'Grace Park',
  'Gracefield',
  'Graceville',
  'Gracia',
  'Graça',
  'Gradacac',
  'Gradara',
  'Gradignan',
  "Gradisca D'isonzo",
  'Grado',
  'Grafeld',
  'Grafenau',
  'Grafenberg',
  'Grafenrheinfeld',
  'Grafenwald',
  'Grafing',
  'Grafling',
  'Grafrath',
  'Grafschaft',
  'Graft',
  'Grafton',
  'Gragnano',
  'Gragnano Trebbiense',
  'Graham',
  'Grahamstown',
  'Grahamsville',
  'Grain Valley',
  'Grainville-sur-ry',
  'Grajaú',
  'Gramado',
  'Gramat',
  'Grambach',
  'Grambling',
  'Grambois',
  'Gramercy',
  'Gramont',
  'Grampian',
  'Gramsbergen',
  'Gramzow',
  'Granada',
  'Granada Hills',
  'Granadero Baigorria',
  'Granadilla De Abona',
  'Granarolo',
  'Granbury',
  'Granby',
  'Grancy',
  'Grand Baie',
  'Grand Blanc',
  'Grand Bourg',
  'Grand Cayman',
  'Grand Coteau',
  'Grand Coulee',
  'Grand Falls',
  'Grand Forks',
  'Grand Haven',
  'Grand Island',
  'Grand Junction',
  'Grand Lake',
  'Grand Ledge',
  'Grand Prairie',
  'Grand Rapids',
  'Grand Ronde',
  'Grand Terrace',
  'Grand View',
  'Grand-bassam',
  'Grand-couronne',
  'Grand-gallargues',
  'Grand-quevilly',
  'Grand-remous',
  'Grand-rosière',
  'Grand-saconnex',
  'Grand-savagnier',
  'Granda',
  'Grandate',
  'Grande',
  'Grande Prairie',
  'Grandes-ventes',
  'Grandris',
  'Grandview',
  'Grandville',
  'Grandy',
  'Grange',
  'Grangemouth',
  'Granger',
  'Granges',
  'Grangetown',
  'Grangeville',
  'Granichen',
  'Graniczna',
  'Granite Bay',
  'Granite City',
  'Granite Falls',
  'Granite Quarry',
  'Graniteville',
  'Grankulla',
  'Granollers',
  'Granö',
  'Grans',
  'Grant',
  'Grant Park',
  'Grantham',
  'Grantown On Spey',
  'Grants Pass',
  'Grantsboro',
  'Grantsburg',
  'Grantsville',
  'Grantville',
  'Granville',
  'Grao De Castellón',
  'Grapeland',
  'Grapevine',
  'Grasbrunn',
  'Grasleben',
  'Grasonville',
  'Grass Lake',
  'Grass Valley',
  'Grassau',
  'Grasse',
  'Grassina',
  'Grassington',
  'Grasweg',
  'Gratentour',
  'Gratis',
  'Gratkorn',
  'Graton',
  'Gratz',
  'Graulhet',
  'Gravataí',
  'Grave',
  'Gravelines',
  'Gravellona Toce',
  'Gravenhurst',
  'Gravesano',
  'Gravesend',
  'Gravette',
  'Gravigny',
  'Gravina In Puglia',
  'Grawn',
  'Gray',
  'Gray Court',
  'Gray Summit',
  'Grayling',
  'Graymont',
  'Grays',
  'Grays Thurrock',
  'Grayslake',
  'Grayson',
  'Graysville',
  'Graytown',
  'Grayville',
  'Graz',
  'Grâce-berleur',
  'Gräddö',
  'Gräfelfing',
  'Gräfenhainichen',
  'Gräfinau-angstedt',
  'Grängesberg',
  'Gränichen',
  'Gränna',
  'Grästorp',
  'Grävenwiesbach',
  'Gråbo',
  'Gråsten',
  'Great Addington',
  'Great Alne',
  'Great Amwell',
  'Great Ayton',
  'Great Baddow',
  'Great Barford',
  'Great Barrington',
  'Great Bend',
  'Great Bentley',
  'Great Bookham',
  'Great Brickhill',
  'Great Bridgeford',
  'Great Burstead',
  'Great Chesterford',
  'Great Clacton',
  'Great Cornard',
  'Great Dunmow',
  'Great Falls',
  'Great Glen',
  'Great Habton',
  'Great Hallingbury',
  'Great Harwood',
  'Great Haseley',
  'Great Kimble',
  'Great Lakes',
  'Great Malvern',
  'Great Meadows',
  'Great Missenden',
  'Great Neck',
  'Great Ouseburn',
  'Great River',
  'Great Tey',
  'Great Totham',
  'Great Wakering',
  'Great Waldingfield',
  'Great Western',
  'Great Yarmouth',
  'Greatham',
  'Greatworth',
  'Greåker',
  'Grebbestad',
  'Grebenau',
  'Greding',
  'Greeley',
  'Greely',
  'Green',
  'Green Bay',
  'Green City',
  'Green Cove Springs',
  'Green Lake',
  'Green Lane',
  'Green Ridge',
  'Green River',
  'Green Valley',
  'Green Village',
  'Greenacres',
  'Greenback',
  'Greenbank',
  'Greenbelt',
  'Greenbrae',
  'Greenbrier',
  'Greencastle',
  'Greendale',
  'Greene',
  'Greeneville',
  'Greenfield',
  'Greenfield Park',
  'Greenford',
  'Greenhaugh',
  'Greenhills',
  'Greenhithe',
  'Greenland',
  'Greenlaw',
  'Greenlawn',
  'Greenock',
  'Greenport',
  'Greensboro',
  'Greensborough',
  'Greensburg',
  'Greentown',
  'Greenvale',
  'Greenville',
  'Greenwich',
  'Greenwood',
  'Greenwood Village',
  'Greer',
  'Greetham',
  'Greetland',
  'Grefrath',
  'Gregory',
  'Greifenberg',
  'Greifensee',
  'Greifenstein',
  'Greifswald',
  'Greifswald-eldena',
  'Greimerath',
  'Greiz',
  'Grellingen',
  'Grembergen',
  'Gremsdorf',
  'Grenaa',
  'Grenada',
  'Grenade',
  'Grenchen',
  'Grenivík',
  'Grenloch',
  'Grenoble',
  'Grentheville',
  'Greny',
  'Grenzach',
  'Grenzach-wyhlen',
  'Gresham',
  'Gresik',
  'Greslove Myto',
  'Gressvik',
  'Gresten',
  'Gretna',
  'Grettstadt',
  'Gretz-armainvilliers',
  'Greußen',
  'Greve',
  'Greven',
  'Grevená',
  'Grevenbicht',
  'Grevenbroich',
  'Grevenmacher',
  'Grevenstein',
  'Grevie',
  'Grewelthorpe',
  'Greystones',
  'Grez-doiceau',
  'Grezzana',
  'Gréasque',
  'Grésy-sur-aix',
  'Grézieu-la-varenne',
  'Gridley',
  'Griesheim',
  'Grieskirchen',
  'Griessen',
  'Griesstätt',
  'Griffin',
  'Griffith',
  'Grignasco',
  'Grigny',
  'Grijó',
  'Grijpskerk',
  'Grillby',
  'Grillon',
  'Grimaud',
  'Grimbergen',
  'Grimes',
  'Grimesland',
  'Grimesthorpe',
  'Grimisuat',
  'Grimma',
  'Grimmen',
  'Grimsby',
  'Grimstad',
  'Grimstead',
  'Grindavík',
  'Grindelwald',
  'Grindsted',
  'Gringley On The Hill',
  'Grinnell',
  'Griñón',
  'Griswold',
  'Grobbendonk',
  'Grobina',
  'Grodno',
  'Grodziec',
  'Grodzisk',
  'Grodzisk Mazowiecki',
  'Grodzisk Wielkopolski',
  'Grodzisko',
  'Groede',
  'Groenekan',
  'Groenlo',
  'Groesbeek',
  'Grogol',
  'Grogolpetamburan',
  'Grombalia',
  'Gron',
  'Gronau',
  'Groningen',
  'Grono',
  'Gronsveld',
  'Groot-ammers',
  'Groot-bijgaarden',
  'Grootebroek',
  'Gros Islet',
  'Grossaffoltern',
  'Grosse Ile',
  'Grosse Pointe',
  'Grosse Pointe Farms',
  'Grosse Pointe Park',
  'Grosse Pointe Woods',
  'Grossefehn',
  'Grosselfingen',
  'Grosseto',
  'Grossgmain',
  'Grossoeuvre',
  'Grosswangen',
  'Grosuplje',
  'Groton',
  'Grottaferrata',
  'Grottaglie',
  'Grottaminarda',
  'Grottammare',
  'Grouw',
  'Grove',
  'Grove City',
  'Grovedale',
  'Groveland',
  'Groveport',
  'Grover',
  'Grover Beach',
  'Groves',
  'Grovesend',
  'Grovetown',
  'Grozny',
  'Groß',
  'Groß Glienicke',
  'Groß Grönau',
  'Groß Kreutz',
  'Groß Lindow',
  'Groß Nordende',
  'Groß Oesingen',
  'Groß Pankow',
  'Groß Rheide',
  'Groß Schenkenberg',
  'Groß Stove',
  'Groß-bieberau',
  'Groß-gerau',
  'Groß-umstadt',
  'Groß-zimmern',
  'Großaitingen',
  'Großalmerode',
  'Großbeeren',
  'Großbettlingen',
  'Großburgwedel',
  'Großenhain',
  'Großenkneten',
  'Großenseebach',
  'Großerlach',
  'Großhabersdorf',
  'Großhansdorf',
  'Großharthau',
  'Großheide',
  'Großheirath',
  'Großheubach',
  'Großhöhenrain',
  'Großkarlbach',
  'Großkarolinenfeld',
  'Großkrotzenburg',
  'Großmaischeid',
  'Großmehring',
  'Großostheim',
  'Großpostwitz',
  'Großrinderfeld',
  'Großröhrsdorf',
  'Großrückerswalde',
  'Großschirma',
  'Großwallstadt',
  'Grójec',
  'Gröbenzell',
  'Gröden',
  'Grödig',
  'Gröditz',
  'Grönenbach',
  'Gröningen',
  'Grönwohld',
  'Grub Am Forst',
  'Grubbenvorst',
  'Grudziadz',
  'Grugliasco',
  'Gruibingen',
  'Grumello Del Monte',
  'Grums',
  'Grundau',
  'Grundhof',
  'Grundy Center',
  'Grunewald',
  'Grunow',
  'Grunwald',
  'Gruszczyn',
  'Grut',
  'Gruyères',
  'Grünberg',
  'Grünhain',
  'Grüningen',
  'Grünow',
  'Grünsfeld',
  'Grünstadt',
  'Grünwald',
  'Gryon',
  'Græsted',
  'Grønland',
  'Gschwend',
  'Gstaad',
  'Guacara',
  'Guachipelín',
  'Guadalajara',
  'Guadalix De La Sierra',
  'Guadalupe',
  'Guadarrama',
  'Guadasuar',
  'Guagnano',
  'Guaíba',
  'Gualaceo',
  'Gualala',
  'Gualdo Cattaneo',
  'Gualdo Tadino',
  'Gualeguaychu',
  'Gualtieri',
  'Guam',
  'Guan',
  'Guanajuato',
  'Guancheng',
  'Guandong',
  'Guangan',
  'Guangdong',
  'Guanghan',
  'Guangming',
  'Guangshun',
  'Guangxi',
  'Guangyuan',
  'Guangzhou',
  'Guangzhou Shi',
  'Guanlan',
  'Guanxi',
  'Guanzhou',
  'Guaporé',
  'Guarapari',
  'Guarapuava',
  'Guararapes',
  'Guaratinguetá',
  'Guará',
  'Guarda',
  'Guardaya',
  'Guardo',
  'Guariba',
  'Guarnizo',
  'Guaro',
  'Guarujá',
  'Guarulhos',
  'Guastalla',
  'Guasticce',
  'Guatavita',
  'Guatay',
  'Guatemala',
  'Guatemala City',
  'Guaxupé',
  'Guayabo',
  'Guayaquil',
  'Guaymas',
  'Guaynabo',
  'Gubbio',
  'Guben',
  'Gudensberg',
  'Gudlavalleru',
  'Guduvancheri',
  'Guebwiller',
  'Gueliz',
  'Guelph',
  'Guer',
  'Guermantes',
  'Guerneville',
  'Guernica',
  'Guernsey',
  'Guerrero',
  'Guerville',
  'Guestling',
  'Guénin',
  'Guérande',
  'Guéret',
  'Guglielmo',
  'Guia',
  'Guibeville',
  'Guichainville',
  'Guichen',
  'Guicheng',
  'Guiclan',
  'Guidel',
  'Guidizzolo',
  'Guidonia',
  'Guignicourt',
  'Guijuelo',
  'Guilderland',
  'Guilderland Center',
  'Guildford',
  'Guilford',
  'Guilhabreu',
  'Guilherand',
  'Guilin',
  'Guillena',
  'Guillerval',
  'Guilliers',
  'Guilsborough',
  'Guimarães',
  'Guin',
  'Guindy',
  'Guinea',
  'Guinéa',
  'Guingamp',
  'Guipavas',
  'Guipry',
  'Guirim',
  'Guisborough',
  'Guiseley',
  'Guisona',
  'Guiyang',
  'Guizhou',
  'Guía De Gran Canaria',
  'Gujranwala',
  'Gujrat',
  'Gulbarga',
  'Gulberg',
  'Gulf Breeze',
  'Gulf Shores',
  'Gulfport',
  'Gull Lake',
  'Gullane',
  'Gullaug',
  'Gullegem',
  'Gulpen',
  'Gulshan-e-iqbal',
  'Gulu',
  'Gumidong',
  'Gumligen',
  'Gumma',
  'Gummersbach',
  'Gummidipundi',
  'Gumpoldskirchen',
  'Gumus',
  'Gumussuyu',
  'Gundelfingen',
  'Gundelsheim',
  'Gundersheim',
  'Gundershoffen',
  'Gundremmingen',
  'Gunesli',
  'Gunnebo',
  'Gunnislake',
  'Gunnison',
  'Gunpo',
  'Gunsan',
  'Gunter',
  'Guntersville',
  'Guntown',
  'Guntramsdorf',
  'Guntur',
  'Gunung',
  'Gunungputri',
  'Gunzenhausen',
  'Gunzgen',
  'Guodu',
  'Guoshui',
  'Gurb',
  'Gurdaspur',
  'Gurdon',
  'Gurgaon',
  'Gurley',
  'Gurnee',
  'Gurupi',
  'Gush Halav',
  'Gussago',
  'Gussing',
  'Gustafs',
  'Gustavia',
  'Gustavo A. Madero',
  'Gustavsberg',
  'Gustavsfors',
  'Gustavus',
  'Gutach',
  'Gutenberg',
  'Gutenstein',
  'Gutenstetten',
  'Guthrie',
  'Gutiérrez Zamora',
  'Guttenberg',
  'Guwahati',
  'Guyancourt',
  'Guyans',
  'Guyhirn',
  'Guymon',
  'Güglingen',
  'Güime',
  'Gülzow',
  'Gümligen',
  'Güngören',
  'Güntersleben',
  'Günzburg',
  'Gütersloh',
  'Güttingen',
  'Güzelbahçe',
  'Gvat',
  'Gwalior',
  'Gwangju',
  'Gwangmyeong',
  'Gwarinpa',
  'Gwatt',
  'Gweru',
  'Gwinn',
  'Gwynedd',
  'Gwynedd Valley',
  'Gwynn Oak',
  'Gyál',
  'Gyeongju',
  'Gyeongsan',
  'Gyeyanggu',
  'Gyhum',
  'Gympie',
  'Gyoda',
  'Gyomaendrod',
  'Gyomro',
  'Gyor',
  'Gyorujbarat',
  'Gyöngyös',
  'Gyöngyössolymos',
  'Gypsum',
  'Gyttorp',
  'Gyula',
  'Gyumri',
  'Gørløse',
  'Haabneeme',
  'Haacht',
  'Haag',
  'Haaksbergen',
  'Haaltert',
  'Haamstede',
  'Haan',
  'Haapajärvi',
  'Haapavesi',
  'Haar',
  'Haarby',
  'Haaren',
  'Haarlem',
  'Haarlemmermeer',
  'Habana',
  'Habartov',
  'Habas',
  'Habay',
  'Habenhausen',
  'Haberfield',
  'Habiganj',
  'Habo',
  'Habonim',
  'Habsheim',
  'Hachen',
  'Hachenburg',
  'Hachimantai',
  'Hachinohe',
  'Hachioji',
  'Hacienda Heights',
  'Hackberry',
  'Hackbridge',
  'Hackensack',
  'Hackett',
  'Hacketts Cove',
  'Hackettstown',
  'Hackney',
  'Hadamar',
  'Hadano',
  'Hadar Am',
  'Hadar `am',
  'Hadasim',
  'Haddenham',
  'Haddington',
  'Haddon Heights',
  'Haddonfield',
  'Hadera',
  'Haderah',
  'Haderslev',
  'Hadfield',
  'Hadimkoy',
  'Hadleigh',
  'Hadley',
  'Hadlow Down',
  'Hadol',
  'Hadong',
  'Hadsten',
  'Hadsund',
  'Haelen',
  'Haerbin',
  'Haeundae',
  'Hafnarfjörður',
  'Hafnir',
  'Hagavik',
  'Hagåtña',
  'Hagen',
  'Hagenbach',
  'Hagenberg',
  'Hagenberg Im Muhlkreis',
  'Hagenberg Im Mühlkreis',
  'Hagendorf',
  'Hagenow',
  'Hagerman',
  'Hagerstown',
  'Hagersville',
  'Hagetmau',
  'Hagfors',
  'Haggatt Hall',
  'Hagley',
  'Hagondange',
  'Hagor',
  'Hagoshrim',
  'Hague',
  'Haguenau',
  'Haguro',
  'Hahn',
  'Hahnbach',
  'Hahndorf',
  'Hahnstätten',
  'Hahotrim',
  'Hai Duong',
  'Hai Phong',
  'Haian',
  'Haibach',
  'Haicang',
  'Haicheng',
  'Haidian',
  'Haifa',
  'Haiger',
  'Haigerloch',
  'Haikou',
  'Haikou Shi',
  'Haiku',
  'Hailey',
  'Hailin',
  'Hailsham',
  'Haimen',
  'Haimhausen',
  'Haina',
  'Hainan',
  'Hainau',
  'Haines City',
  'Hainesport',
  'Hainichen',
  'Haining',
  'Haiterbach',
  'Haiti',
  'Haiyan',
  'Haizhu',
  'Hajdina',
  'Hajdúböszörmény',
  'Hajdúdorog',
  'Hajdúhadház',
  'Hakata',
  'Hakdong',
  'Hakodate',
  'Hakozaki',
  'Hakozakicho',
  'Hakusan',
  'Halásztelek',
  'Halberstadt',
  'Halblech',
  'Halden',
  'Haldensleben',
  'Haldenstein',
  'Haldia',
  'Haldwani',
  'Hale',
  'Haledon',
  'Haleiwa',
  'Halemba',
  'Halen',
  'Halensee',
  'Hales Corners',
  'Halesowen',
  'Halesworth',
  'Halethorpe',
  'Haleyville',
  'Half Moon Bay',
  'Halfing',
  'Halfmoon',
  'Halfweg',
  'Haliburton',
  'Halifax',
  'Halikko',
  'Halinów',
  'Halisahar',
  'Halkirk',
  'Hall',
  'Hallam',
  'Hallandale',
  'Hallatrow',
  'Hallbergmoos',
  'Halle',
  'Halle An Der Saale',
  'Halle-neustadt',
  'Hallein',
  'Hallenberg',
  'Hallennes-lez-haubourdin',
  'Hallersdorf',
  'Hallettsville',
  'Hallowell',
  'Hallsberg',
  'Hallstadt',
  'Hallstahammar',
  'Hallstavik',
  'Hallum',
  'Hallwang',
  'Halmstad',
  'Halol',
  'Halsbach',
  'Halsbrücke',
  'Halsey',
  'Halstead',
  'Halstenbek',
  'Halsteren',
  'Haltern',
  'Haltom City',
  'Halton',
  'Haltwhistle',
  'Halver',
  'Ham',
  'Ham Nord',
  'Ham-sur-heure',
  'Hamada',
  'Hamakita',
  'Hamala',
  'Hamamatsu',
  'Hamamatsucho',
  'Hamamatu',
  'Haman',
  'Hamar',
  'Hamarvik',
  'Hamasaka',
  'Hamb',
  'Hambach',
  'Hambantota',
  'Hamble',
  'Hambledon',
  'Hambrook',
  'Hambrücken',
  'Hamburg',
  'Hamburgsund',
  'Hambühren',
  'Hamcearca',
  'Hamden',
  'Hamel',
  'Hameln',
  'Hamersley',
  'Hamersville',
  'Hamerton',
  'Hamfelde',
  'Hamilton',
  'Hamilton Bay',
  'Hamilton City',
  'Hamina',
  'Hamirpur',
  'Hamlet',
  'Hamlin',
  'Hamm',
  'Hammam Sousse',
  'Hammamet',
  'Hamman-sousse',
  'Hammarland',
  'Hammarö',
  'Hamme',
  'Hammel',
  'Hammelburg',
  'Hammerdal',
  'Hammerfest',
  'Hammersmith',
  'Hamminkeln',
  'Hammond',
  'Hammondsport',
  'Hammonton',
  'Hamoir',
  'Hamont',
  'Hampden',
  'Hampden Sydney',
  'Hampshire',
  'Hampstead',
  'Hampton',
  'Hampton Bays',
  'Hampton Falls',
  'Hampton Hill',
  'Hampton In Arden',
  'Hamptonville',
  'Hamra',
  'Hamrun',
  'Hamtramck',
  'Hamura',
  'Hamwarde',
  'Hana',
  'Hanahan',
  'Hanalei',
  'Hanam',
  'Hanamaki',
  'Hanamigawa',
  'Hanamkonda',
  'Hanau',
  'Hanborough',
  'Hanbury',
  'Hanceville',
  'Hancheng',
  'Hanchuan',
  'Hancock',
  'Handa',
  'Handan',
  'Handcross',
  'Handel',
  'Handen',
  'Handewitt',
  'Handforth',
  'Handlová',
  'Handsworth',
  'Haneckenfähr',
  'Haneda',
  'Hanford',
  'Hang Dong',
  'Hanga Roa',
  'Hangenbieten',
  'Hangyang',
  'Hangzhou',
  'Haniel',
  'Haninge',
  'Hanita',
  'Hanjiang',
  'Hank',
  'Hankasalmi',
  'Hankerton',
  'Hanko',
  'Hanley',
  'Hanna',
  'Hannam',
  'Hannibal',
  'Hannington',
  'Hanno',
  'Hannon',
  'Hannoversch Münden',
  'Hannut',
  'Hanoi',
  'Hanover',
  'Hanover Park',
  'Hanoverton',
  'Hanö',
  'Hanson',
  'Hansonville',
  'Hanstholm',
  'Hansville',
  'Hantsport',
  'Hanumangarh',
  'Hanvec',
  'Hanwell',
  'Hanworth',
  'Hanyu',
  'Hanzhong',
  'Haogen',
  'Haparanda',
  'Hapert',
  'Happy Valley',
  'Hapton',
  'Hapur',
  'Harads',
  'Harajuku',
  'Harare',
  'Harbin',
  'Harbinger',
  'Harbiye',
  'Harbke',
  'Harbor Beach',
  'Harbor City',
  'Harbor Springs',
  'Harbor View',
  'Harborne',
  'Harborough',
  'Harbour Buffet',
  'Harbour Island',
  'Hard',
  'Harde',
  'Hardeeville',
  'Hardegsen',
  'Hardenberg',
  'Harderwijk',
  'Hardheim',
  'Hardinxveld',
  'Hardt',
  'Hardwar',
  'Hardwick',
  'Hardy',
  'Harefield',
  'Hareid',
  'Harelbeke',
  'Haren',
  'Harestad',
  'Harfleur',
  'Harfsen',
  'Hargeisa',
  'Harghita',
  'Haridwar',
  'Harigaya',
  'Harihar',
  'Haringey',
  'Haringhata',
  'Haripad',
  'Harjavalta',
  'Harju',
  'Harjumaa',
  'Harkema',
  'Harker',
  'Harker Heights',
  'Harkstead',
  'Harku',
  'Harlan',
  'Harlech',
  'Harlem',
  'Harleston',
  'Harlev',
  'Harley',
  'Harleysville',
  'Harlingen',
  'Harlington',
  'Harlow',
  'Harlowton',
  'Harmans',
  'Harmånger',
  'Harmelen',
  'Harmon',
  'Harmonsburg',
  'Harmony',
  'Harmstorf',
  'Harnes',
  'Harnham',
  'Haro',
  'Harold Wood',
  "Harold's Cross",
  'Harpenden',
  'Harper Woods',
  'Harpers Ferry',
  'Harperville',
  'Harpstedt',
  'Harpswell',
  'Harrah',
  'Harriet',
  'Harrietsham',
  'Harrietta',
  'Harriman',
  'Harrington',
  'Harrington Park',
  'Harris',
  'Harrisburg',
  'Harrislee',
  'Harrison',
  'Harrison City',
  'Harrison Township',
  'Harrisonburg',
  'Harrisonville',
  'Harriston',
  'Harrodsburg',
  'Harrogate',
  'Harrold',
  'Harrow',
  'Harrow On The Hill',
  'Harrow Weald',
  'Harscheid',
  'Harsewinkel',
  'Harstad',
  'Harston',
  'Harsum',
  'Hart',
  'Hartberg',
  'Hartenfels',
  'Hartenholm',
  'Hartenstein',
  'Hartfield',
  'Hartford',
  'Hartford City',
  'Hartha',
  'Harthausen',
  'Hartington',
  'Hartland',
  'Hartlebury',
  'Hartlepool',
  'Hartley',
  'Hartly',
  'Hartmannsdorf',
  'Harts',
  'Hartsburg',
  'Hartsdale',
  'Hartselle',
  'Hartsville',
  'Hartuv',
  'Hartville',
  'Hartwell',
  'Hartwick',
  'Harumi',
  'Harutsim',
  'Harvard',
  'Harvest',
  'Harvey',
  'Harveys Lake',
  'Harwell',
  'Harwich',
  'Harwich Port',
  'Harwick',
  'Harwinton',
  'Harwood',
  'Harwood Heights',
  'Hary',
  'Haryana',
  'Hasbergen',
  'Hasbrouck Heights',
  'Haselünne',
  'Hasharon',
  'Hashima',
  'Haskell',
  'Haskins',
  'Haslach',
  'Haslach Im Kinzigtal',
  'Hasle',
  'Hasle-ruegsau',
  'Hasle-rüegsau',
  'Haslemere',
  'Haslet',
  'Haslett',
  'Haslev',
  'Haslingden',
  'Haslingfield',
  'Hasloch',
  'Hasloh',
  'Haslum',
  'Haspe',
  'Hassan',
  'Hassan Abdal',
  'Hasselager',
  'Hasselt',
  'Hassi Messaoud',
  'Hassocks',
  'Hastings',
  'Hastings On Hudson',
  'Hatay',
  'Hatboro',
  'Hatch End',
  'Hatchobori',
  'Hatfield',
  'Hatfield Broad Oak',
  'Hatfield Heath',
  'Hatfield Peverel',
  'Hathersage',
  'Hatsukaichi',
  'Hattem',
  'Hatten',
  'Hattenhofen',
  'Hattersheim',
  'Hattert',
  'Hattiesburg',
  'Hattingen',
  'Hatton',
  'Hattula',
  'Hatvan',
  'Haubourdin',
  'Haubstadt',
  'Hauenstein',
  'Hauge',
  'Haugesund',
  'Haughton',
  'Haukipudas',
  'Hauppauge',
  'Hauptwil',
  'Hausach',
  'Hausbay',
  'Hausen',
  'Hausen Im Tal',
  'Hauserdörfl',
  'Hausham',
  'Hausmannstätten',
  'Haut-de-bosdarros',
  'Hautcharage',
  'Haute-goulaine',
  'Haute-rivoire',
  'Hauterive',
  'Hauteville-lompnes',
  'Hautzendorf',
  'Hauzenberg',
  'Havana',
  'Havant',
  'Havdrup',
  'Havelange',
  'Havelberg',
  'Havelock',
  'Havelock North',
  'Haverford',
  'Haverfordwest',
  'Haverhill',
  'Haveri',
  'Havertown',
  'Haviland',
  'Havirov',
  'Havixbeck',
  'Havlickuv Brod',
  'Havndal',
  'Havre',
  'Havre De Grace',
  'Havré',
  'Hawaii National Park',
  'Hawaiian Gardens',
  'Hawalli',
  'Hawaly',
  'Hawarden',
  'Hawera',
  'Hawes',
  'Hawick',
  'Hawker',
  'Hawkesbury',
  'Hawkhurst',
  'Hawkins',
  'Hawkinsville',
  'Hawks',
  'Hawley',
  'Haworth',
  'Hawthorn',
  'Hawthorne',
  'Haxby',
  'Hay',
  'Hayama',
  'Hayashima',
  'Haydarpasa',
  'Hayden',
  'Haydenville',
  'Haydock',
  'Hayes',
  'Hayesville',
  'Hayle',
  'Hayling',
  'Haymarket',
  'Hayneville',
  'Hays',
  'Hayward',
  'Haywards Heath',
  'Hazard',
  'Hazaribagh',
  'Hazebrouck',
  'Hazel Crest',
  'Hazel Grove',
  'Hazel Park',
  'Hazelbrook',
  'Hazelton',
  'Hazelwood',
  'Hazen',
  'Hazerswoude-dorp',
  'Hazerswoude-rijndijk',
  'Hazeva',
  'Hazlehurst',
  'Hazlet',
  'Hazleton',
  'Hazmie',
  'Hazor',
  'Haßfurt',
  'Haßloch',
  'Häädemeeste',
  'Hägendorf',
  'Hägernäs',
  'Hägersten',
  'Häggenschwil',
  'Hägglingen',
  'Häggvik',
  'Häljarp',
  'Hällefors',
  'Hälleforsnäs',
  'Hällekis',
  'Hämeenlinna',
  'Härnösand',
  'Härryda',
  'Hässelby',
  'Hässleholm',
  'Håbo',
  'Hågån',
  'Hårlev',
  'Head Of Jeddore',
  'Headcorn',
  'Headington',
  'Headley',
  'Headquarters',
  'Healdsburg',
  'Healesville',
  'Health',
  'Heanor',
  'Hearne',
  'Hearst',
  'Heath',
  'Heathcote',
  'Heather',
  'Heatherton',
  'Heathfield',
  'Heathmont',
  'Heathrow',
  'Heaton',
  'Heaton Chapel',
  'Hebburn',
  'Hebden Bridge',
  'Hebei',
  'Heber',
  'Heber City',
  'Heber Springs',
  'Hebi',
  'Hebron',
  'Heby',
  'Hechi',
  'Hechingen',
  'Hechtel',
  'Hechuan',
  'Heckington',
  'Hecklingen',
  'Heckmondwike',
  'Hector',
  'Hedåsen',
  'Heddesheim',
  'Hedehusene',
  'Hedel',
  'Hedemora',
  'Hedensted',
  'Hedera',
  'Hedesunda',
  'Hednesford',
  'Hedong',
  'Heedfeld',
  'Heeg',
  'Heek',
  'Heel',
  'Heelsum',
  'Heemskerk',
  'Heemstede',
  'Heenvliet',
  'Heerbrugg',
  'Heerde',
  'Heerenveen',
  'Heerhugowaard',
  'Heerjansdam',
  'Heerle',
  'Heerlen',
  'Heers',
  'Heesbeen',
  'Heesch',
  'Heese',
  'Heeslingen',
  'Heeswijk',
  'Heeze',
  'Hefei',
  'Heffen',
  'Heflin',
  'Hefsi-bah',
  'Hegang',
  'Hegelsom',
  'Heggedal',
  'Heggenes',
  'Heibloem',
  'Heide',
  'Heidelberg',
  'Heidelberg West',
  'Heiden',
  'Heidenau',
  'Heidenheim',
  'Heidenheim An Der Brenz',
  'Heidesheim',
  'Heidgraben',
  'Heifa',
  'Heihe',
  'Heijen',
  'Heikendorf',
  'Heilbad Heiligenstadt',
  'Heilberscheid',
  'Heilbronn',
  'Heiligenberg',
  'Heiligenhagen',
  'Heiligenhaus',
  'Heiligenkreuz',
  'Heiligenstadt',
  'Heiligenthal',
  'Heillecourt',
  'Heiloo',
  'Heilsbronn',
  'Heimbach',
  'Heimberg',
  'Heimdal',
  'Heimenkirch',
  'Heimertingen',
  'Heimerzheim',
  'Heimsbrunn',
  'Heimsheim',
  'Heinenoord',
  'Heinersreuth',
  'Heinkensand',
  'Heino',
  'Heinola',
  'Heinsberg',
  'Heiskell',
  'Heisson',
  'Heist',
  'Heist-op-den-berg',
  'Heitenried',
  'Heitersheim',
  'Heiwajima',
  'Hejian',
  'Hejls',
  'Hejnsvig',
  'Helbra',
  'Helchteren',
  'Helena',
  'Helendale',
  'Helenelund',
  'Helens Bay',
  'Helensburgh',
  'Helensvale',
  'Helenwood',
  'Heliopolis',
  'Helix',
  'Hellange',
  'Hellebæk',
  'Hellemmes',
  'Hellemmes-lille',
  'Hellendoorn',
  'Hellenthal',
  'Hellertown',
  'Hellerup',
  'Hellevik',
  'Hellevoetsluis',
  'Hellissandur',
  'Hellín',
  'Helm',
  'Helmbrechts',
  'Helmetta',
  'Helmond',
  'Helmsdale',
  'Helmshore',
  'Helmsley',
  'Helmstadt',
  'Helmstedt',
  'Helotes',
  'Helpsen',
  'Helpston',
  'Helsby',
  'Helsingborg',
  'Helsingby',
  'Helsinge',
  'Helsingfors',
  'Helsinginpitäjä',
  'Helsingør',
  'Helsinki',
  'Helston',
  'Heltonville',
  'Helvoirt',
  'Helwan',
  'Hem',
  'Hem-lenglet',
  'Hem-monacu',
  'Hemau',
  'Hemed',
  'Hemeius',
  'Hemel Hempstead',
  'Hemer',
  'Hemet',
  'Hemiksem',
  'Hemlock',
  'Hemmental',
  'Hemmingen',
  'Hemmingsmark',
  'Hemmingstedt',
  'Hemmoor',
  'Hemphill',
  'Hempstead',
  'Hemsbach',
  'Hemsbünde',
  'Hemsedal',
  'Henan',
  'Henares',
  'Henau',
  'Henån',
  'Hencovce',
  'Hendaye',
  'Henderson',
  'Hendersonville',
  'Hendrik-ido-ambacht',
  'Hendschiken',
  'Henefer',
  'Henfield',
  'Hengdian',
  'Hengdong',
  'Hengelo',
  'Hengersberg',
  'Henggang',
  'Henggart',
  'Hengoed',
  'Hengsheng',
  'Hengshui',
  'Hengyang',
  'Henley In Arden',
  'Henley On Thames',
  'Henlow',
  'Henndorf Am Wallersee',
  'Hennebont',
  'Hennef',
  'Hennigsdorf',
  'Henniker',
  'Henning',
  'Hennstedt',
  'Henrico',
  'Henrietta',
  'Henriville',
  'Hensies',
  'Hensley',
  'Henstedt-ulzburg',
  'Henwood',
  'Heol-y-cyw',
  'Hepburn Springs',
  'Heppen',
  'Heppenbach',
  'Heppendorf',
  'Heppenheim',
  'Heppignies',
  'Heppner',
  'Heraklion',
  'Herand',
  'Heras',
  'Herbert',
  'Herbertingen',
  'Herbeys',
  'Herblay',
  'Herbolzheim',
  'Herborn',
  'Herbrechtingen',
  'Herbstein',
  'Herchies',
  'Herculaneum',
  'Hercules',
  'Herdecke',
  'Herdern',
  'Herdon',
  'Herdorf',
  'Herdwangen-schönach',
  'Heredia',
  'Hereford',
  'Herencia',
  'Herend',
  'Herent',
  'Herentals',
  'Herenthout',
  'Herev Laet',
  'Herford',
  'Herforst',
  'Herfølge',
  'Hergiswil',
  'Heringen',
  'Heringsdorf',
  'Herington',
  'Heriot',
  'Herisau',
  'Herk-de-stad',
  'Herkenbosch',
  'Herkimer',
  'Herlev',
  'Herlufmagle',
  'Hermalle-sous-argenteau',
  'Hermance',
  'Hermann',
  'Hermanville-sur-mer',
  'Hermaringen',
  'Hermeskeil',
  'Hermetschwil',
  'Hermée',
  'Hermiston',
  'Hermiswil',
  'Hermitage',
  'Hermosa Beach',
  'Hermosillo',
  'Hermsdorf',
  'Hernandez',
  'Hernando',
  'Hernani',
  'Hernádkak',
  'Hernán-valle',
  'Herndon',
  'Herne',
  'Herne Bay',
  'Herning',
  'Heroica Guaymas',
  'Heroica Nogales',
  'Heroldsbach',
  'Heroldsberg',
  'Heron',
  'Herreid',
  'Herrenberg',
  'Herrera',
  'Herriard',
  'Herriman',
  'Herrin',
  'Herrliberg',
  'Herrljunga',
  'Herrnburg',
  'Herrsching',
  'Herrsching Am Ammersee',
  'Hersbruck',
  'Herschbach',
  'Herscheid',
  'Herseaux',
  'Herselt',
  'Hersham',
  'Hershey',
  'Herstal',
  'Herstmonceux',
  'Herte',
  'Herten',
  'Hertford',
  'Hertsberge',
  'Hertsliyah',
  'Herut',
  'Herve',
  'Herveld',
  'Hervey Bay',
  'Herxheim',
  'Herzberg',
  'Herzberg Am Harz',
  'Herzebrock',
  'Herzhorn',
  'Herzlia B',
  'Herzliya',
  'Herzliyya',
  'Herzogenaurach',
  'Herzogenbuchsee',
  'Herzogenburg',
  'Herzogenrath',
  'Herøya',
  'Herøysund',
  'Hesel',
  'Heshan',
  'Heslington',
  'Hesnes',
  'Hesperange',
  'Hesperia',
  'Hessay',
  'Hesse',
  'Hessel',
  'Hesselager',
  'Hessen',
  'Hessett',
  'Hessisch Lichtenau',
  'Hessisch Oldendorf',
  'Hessle',
  'Hesston',
  'Heswall',
  'Heteren',
  'Hethersett',
  'Hettlingen',
  'Hettstadt',
  'Hettstedt',
  'Heubach',
  'Heuchelheim',
  'Heuchelheim-klingen',
  'Heuerßen',
  'Heukelum',
  'Heule',
  'Heusden',
  'Heusenstamm',
  'Heusweiler',
  'Hever',
  'Heverlee',
  'Heves',
  'Hevonpää',
  'Hewitt',
  'Hewlett',
  'Hexel',
  'Hexham',
  'Heybridge',
  'Heyburn',
  'Heyda',
  'Heydon',
  'Heysham',
  'Heythuysen',
  'Heyuan',
  'Heywood',
  'Heze',
  'Heßdorf',
  'Heßheim',
  'Hénin-beaumont',
  'Héricourt',
  'Héron',
  'Hérouville-saint-clair',
  'Hésingue',
  'Hi Hat',
  'Hialeah',
  'Hialeah Gardens',
  'Hiawatha',
  'Hibat Zion',
  'Hibbing',
  'Hibernia',
  'Hickman',
  'Hickory',
  'Hickory Corners',
  'Hickory Hills',
  'Hicksville',
  'Hida',
  'Hidaka',
  'Hidalgo',
  'Hidalgo Del Parral',
  'Hidd',
  'Hidden Valley Lake',
  'Hiddenhausen',
  'Hiddenite',
  'Hierden',
  'Higashi',
  'Higashi-hiroshima',
  'Higashi-kurume',
  'Higashi-matsuyama',
  'Higashi-murayama',
  'Higashi-osaka',
  'Higashi-osaki',
  'Higashi-shinagawa',
  'Higashine',
  'Higashiyama',
  'Higashiyamato',
  'Higganum',
  'Higgins Lake',
  'Higginsville',
  'High Bridge',
  'High Easter',
  'High Ercall',
  'High Ongar',
  'High Peak',
  'High Point',
  'High Prairie',
  'High Ridge',
  'High River',
  'High Springs',
  'High Wycombe',
  'Higham',
  'Higham Ferrers',
  'Highbridge',
  'Highbury',
  'Higher Bebington',
  'Highett',
  'Highgate',
  'Highgate Springs',
  'Highland',
  'Highland City',
  'Highland Park',
  'Highland Springs',
  'Highlands',
  'Highlands Ranch',
  'Hightstown',
  'Highwood',
  'Highworth',
  'Higley',
  'Hikone',
  'Hilah',
  'Hilbersdorf',
  'Hilchenbach',
  'Hildburghausen',
  'Hildebran',
  'Hilden',
  'Hildenborough',
  'Hildesheim',
  'Hildisrieden',
  'Hilgertshausen',
  'Hilham',
  'Hill',
  'Hill City',
  'Hill Country Village',
  'Hillburn',
  'Hillcrest',
  'Hille',
  'Hillegom',
  'Hillerstorp',
  'Hillerød',
  'Hillesheim',
  'Hilliard',
  'Hillingdon',
  'Hillington',
  'Hillion',
  'Hillmarton',
  'Hillrose',
  'Hills',
  'Hillsboro',
  'Hillsborough',
  'Hillscheid',
  'Hillsdale',
  'Hillside',
  'Hilltown',
  'Hillview',
  'Hillwood',
  'Hilmar',
  'Hilo',
  'Hilperton',
  'Hilpoltstein',
  'Hilter',
  'Hilton',
  'Hilton Head',
  'Hilton Head Island',
  'Hiltpoltstein',
  'Hilvarenbeek',
  'Hilversum',
  'Hilzingen',
  'Himatnagar',
  'Himayatnagar',
  'Himeji',
  'Himezi',
  'Himmelreich',
  'Himmighofen',
  'Hinckley',
  'Hindås',
  'Hindelang',
  'Hindhead',
  'Hindley',
  'Hindman',
  'Hindmarsh',
  'Hindringham',
  'Hinesburg',
  'Hinesville',
  'Hingene',
  'Hingeon',
  'Hingham',
  'Hinnerup',
  'Hino',
  'Hinsdale',
  'Hinstock',
  'Hinterkappelen',
  'Hinterzarten',
  'Hinton',
  'Hinwil',
  'Hinxton',
  'Hioki',
  'Hipólito Yrigoyen',
  'Hipperholme',
  'Hippolytushoef',
  'Hirado',
  'Hirakata',
  'Hiraki',
  'Hiram',
  'Hiranuma',
  'Hirata',
  'Hiratsuka',
  'Hirohata',
  'Hiromi',
  'Hiroo',
  'Hirosaki',
  'Hiroshima',
  'Hiroshima-shi',
  'Hirschau',
  'Hirschberg',
  'Hirschfeld',
  'Hirschhorn',
  'Hirsingue',
  'Hirson',
  'Hirtenberg',
  'Hirtshals',
  'Hirwaun',
  'Hirzel',
  'Hisar',
  'Hispania',
  'Hissar',
  'Histon',
  'Hita',
  'Hitachi',
  'Hitachi-naka',
  'Hitchcock',
  'Hitchin',
  'Hitoyoshi',
  'Hittnau',
  'Hitzkirch',
  'Hixson',
  'Hjallerup',
  'Hjälteby',
  'Hjärnarp',
  'Hjärup',
  'Hjelmeland',
  'Hjerm',
  'Hjo',
  'Hjørring',
  'Hlinsko',
  'Hlohovec',
  'Hloubetin',
  'Hlubocepy',
  'Hlyboka',
  'Hnífsdalur',
  'Hnusta',
  'Ho Chi Minh City',
  'Ho Ho Kus',
  'Ho-ho-kus',
  'Hoagland',
  'Hobart',
  'Hobart Town',
  'Hobbs',
  'Hobe Sound',
  'Hoboken',
  'Hobro',
  'Hobscheid',
  'Hobsonville',
  'Hoce',
  'Hochdorf',
  'Hochdorf-assenheim',
  'Hochheim',
  'Hochheim Am Main',
  'Hochstadt',
  'Hochstadt Am Main',
  'Hochwald',
  'Hockenheim',
  'Hockessin',
  'Hockley',
  'Hod Hasharon',
  'Hoddesdon',
  'Hodges',
  'Hodkovicky',
  'Hodmezovasarhely',
  'Hodogaya',
  'Hodogayacho',
  'Hodolany',
  'Hodonin',
  'Hoegaarden',
  'Hoeilaart',
  'Hoek Van Holland',
  'Hoeleden',
  'Hoenheim',
  'Hoensbroek',
  'Hoerdt',
  'Hoersching',
  'Hoeselt',
  'Hoevelaken',
  'Hoeven',
  'Hof',
  'Hofen',
  'Hoffenheim',
  'Hoffman',
  'Hoffman Estates',
  'Hofgeismar',
  'Hofheim',
  'Hofheim Am Taunus',
  'Hofit',
  'Hofkirchen',
  'Hofors',
  'Hofstade',
  'Hofstetten',
  'Hofu',
  'Hofuf',
  'Hogansburg',
  'Hogansville',
  'Hogla',
  'Hognoul',
  'Hohberg',
  'Hohen Neuendorf',
  'Hohen-sülzen',
  'Hohenaltheim',
  'Hohenbrunn',
  'Hohenems',
  'Hohenfelden',
  'Hohenfels',
  'Hohengandern',
  'Hohenhameln',
  'Hohenkammer',
  'Hohenkirchen',
  'Hohenleipisch',
  'Hohenlockstedt',
  'Hohenpeißenberg',
  'Hohenpolding',
  'Hohenrain',
  'Hohenstein-ernstthal',
  'Hohenstrasse',
  'Hohentengen',
  'Hohenthann',
  'Hohenthurm',
  'Hohenwald',
  'Hohenwarth',
  'Hohenwestedt',
  'Hohhot',
  'Hohn',
  'Hoisdorf',
  'Hojai',
  'Hokitika',
  'Hokksund',
  'Hokuto',
  'Holargos',
  'Holasovice',
  'Holbeach',
  'Holbeck',
  'Holboca',
  'Holborn',
  'Holbrook',
  'Holbæk',
  'Holcomb',
  'Holden',
  'Holdenville',
  'Holderness',
  'Holdingford',
  'Holdrege',
  'Holeby',
  'Holesov',
  'Holesovice',
  'Holetown',
  'Holgate',
  'Holguín',
  'Holic',
  'Holiday',
  'Hollabrunn',
  'Holladay',
  'Holland',
  'Hollandscheveld',
  'Hollenbach',
  'Hollenstedt',
  'Hollerich',
  'Hollern',
  'Hollfeld',
  'Holliday',
  'Hollidaysburg',
  'Hollinwood',
  'Hollis',
  'Hollis Center',
  'Hollister',
  'Holliston',
  'Hollogne',
  'Hollola',
  'Holloman Air Force Base',
  'Hollsopple',
  'Holly',
  'Holly Hill',
  'Holly Pond',
  'Holly Ridge',
  'Holly Springs',
  'Hollywood',
  'Holm',
  'Holmdel',
  'Holme',
  'Holmefjord',
  'Holmen',
  'Holmes',
  'Holmes Beach',
  'Holmes Chapel',
  'Holmestrand',
  'Holmfirth',
  'Holmsbu',
  'Holmsund',
  'Holon',
  'Holroyd',
  'Holsbeek',
  'Holstebro',
  'Holsted',
  'Holstein',
  'Holsworthy',
  'Holt',
  'Holte',
  'Holten',
  'Holton',
  'Holtrup',
  'Holts Summit',
  'Holtsville',
  'Holtzheim',
  'Holualoa',
  'Holwell',
  'Holy Cross',
  'Holybourne',
  'Holyhead',
  'Holyoke',
  'Holysov',
  'Holytown',
  'Holywell',
  'Holywood',
  'Holzgerlingen',
  'Holzgünz',
  'Holzhausen',
  'Holzheim',
  'Holzkirchen',
  'Holzmaden',
  'Holzminden',
  'Holzwickede',
  'Homa Bay',
  'Homberg',
  'Hombourg',
  'Hombrechtikon',
  'Homburg',
  'Homebush',
  'Homedale',
  'Homel',
  'Homeland',
  'Homer',
  'Homer Glen',
  'Homestead',
  'Homewood',
  'Hominy',
  'Hommelvik',
  'Hommersåk',
  'Homole',
  'Homosassa',
  'Hon-machi',
  'Honaker',
  'Honaunau',
  'Honcho',
  'Hondo',
  'Honduras',
  'Honeoye',
  'Honeoye Falls',
  'Honesdale',
  'Honey Brook',
  'Honfleur',
  'Hong Gai',
  'Hong Kong',
  'Hongdong',
  'Hongeun',
  'Honggong',
  'Hongje',
  'Hongkou',
  'Hongo',
  'Hongqiao',
  'Hongze',
  'Honiara',
  'Honiley',
  'Honiton',
  'Honjo',
  'Honokaa',
  'Honolulu',
  'Honor',
  'Honselersdijk',
  'Hontoria',
  'Hood River',
  'Hooe',
  'Hoofddorp',
  'Hoog-soeren',
  'Hooge Zwaluwe',
  'Hoogerheide',
  'Hoogeveen',
  'Hoogezand',
  'Hooghly',
  'Hoogkarspel',
  'Hoogland',
  'Hooglanderveen',
  'Hooglede',
  'Hoogvliet',
  'Hoogwoud',
  'Hook',
  'Hook Norton',
  'Hooksett',
  'Hooper',
  'Hoopeston',
  'Hoorn',
  'Hoosick Falls',
  'Hoover',
  'Hopatcong',
  'Hope',
  'Hope Hull',
  'Hope Island',
  'Hope Valley',
  'Hopedale',
  'Hopeville',
  'Hopewell',
  'Hopewell Junction',
  'Hopfgarten Im Brixental',
  'Hopkins',
  'Hopkins Park',
  'Hopkinsville',
  'Hopkinton',
  'Hopland',
  'Hoppegarten',
  'Hoppers Crossing',
  'Hoppstädten-weiersbach',
  'Hopton On Sea',
  'Hopwood',
  'Hoquiam',
  'Horakov',
  'Horam',
  'Horb',
  'Horb Am Neckar',
  'Horbourg',
  'Horbury',
  'Horda',
  'Hordle',
  'Horeham',
  'Horgau',
  'Horgen',
  'Horgenzell',
  'Horhausen',
  'Horice',
  'Horicon',
  'Horikawa',
  'Horizonte',
  'Horjul',
  'Horley',
  'Horn',
  'Horn Lake',
  'Horn-bad Meinberg',
  'Hornbeak',
  'Hornberg',
  'Hornburg',
  'Hornby',
  'Hornbæk',
  'Horncastle',
  'Hornchurch',
  'Horndean',
  'Hornell',
  'Horner',
  'Horni Briza',
  'Horni Cerekev',
  'Horni Libchava',
  'Horni Mostenice',
  'Horni Pocernice',
  'Horni Sucha',
  'Horning',
  'Hornnes',
  'Hornsby',
  'Hornsey',
  'Hornslet',
  'Hornsyld',
  'Hornu',
  'Horovice',
  'Horsching',
  'Horse Cave',
  'Horseheads',
  'Horsell',
  'Horsens',
  'Horseshoe Bay',
  'Horsford',
  'Horsforth',
  'Horsham',
  'Horst',
  'Horsted Keynes',
  'Horsten',
  'Horstmar',
  'Horta',
  'Hortaleza',
  'Horten',
  'Horton',
  'Hortonville',
  'Horw',
  'Horwich',
  'Hoschton',
  'Hosen',
  'Hosena',
  'Hoshangabad',
  'Hoshiarpur',
  'Hosingen',
  'Hoskote',
  'Hoslemo',
  'Hospet',
  'Hospitalet De Llobregat',
  'Hossegor',
  'Hosston',
  'Hosteradky-resov',
  'Hostinne',
  'Hostivar',
  'Hostivice',
  'Hostomel',
  'Hostomice',
  'Hoston',
  'Hosur',
  'Hot Springs',
  'Hot Springs National Park',
  'Hot Springs Village',
  'Hotan',
  'Hotchkiss',
  'Houdan',
  'Houdémont',
  'Houffalize',
  'Hougang',
  'Houghton',
  'Houghton Conquest',
  'Houghton Lake',
  'Houghton Lake Heights',
  'Houghton Le Spring',
  'Houghton Regis',
  'Houilles',
  'Houjie',
  'Houlgate',
  'Houlton',
  'Houma',
  'Houmet Es Souk',
  'Hounslow',
  'Houplin-ancoisne',
  'Housatonic',
  'House Springs',
  'Houshayu',
  'Houssen',
  'Houston',
  'Houtain-le-val',
  'Houten',
  'Houthalen',
  'Hov',
  'Hova',
  'Hovås',
  'Hovdebygda',
  'Hove',
  'Hovedgård',
  'Hovelange',
  'Hovin I Gauldal',
  'Hovmantorp',
  'Hovorcovice',
  'Howald',
  'Howard',
  'Howard Beach',
  'Howard Lake',
  'Howden',
  'Howe',
  'Howell',
  'Howes Cave',
  'Howey In The Hills',
  'Howick',
  'Howlong',
  'Howrah',
  'Howth',
  'Howwood',
  'Hoxton',
  'Hoya',
  'Hoyerswerda',
  'Hoylake',
  'Hoyland',
  'Hoyo De Manzanares',
  'Hoyt Lakes',
  'Hòa Bình',
  'Höchberg',
  'Höchheim',
  'Höchst Im Odenwald',
  'Höchstadt',
  'Höchstadt An Der Aisch',
  'Höchstädt An Der Donau',
  'Höfingen',
  'Höfn',
  'Höganäs',
  'Högling',
  'Högsäter',
  'Högsby',
  'Höhe',
  'Höhenkirchen',
  'Höhenkirchen-siegertsbrunn',
  'Höhenrain',
  'Höhr-grenzhausen',
  'Hökerum',
  'Höljes',
  'Höllviken',
  'Hölö',
  'Hölstein',
  'Hönningen',
  'Hönö',
  'Höör',
  'Höpfingen',
  'Hörbranz',
  'Hörby',
  'Hörde',
  'Hörden',
  'Hörja',
  'Hörnum',
  'Hörstel',
  'Hösbach',
  'Höttingen',
  'Hövelhof',
  'Höver',
  'Höxter',
  'Hrabuvka',
  'Hradec',
  'Hradec Kralove',
  'Hradek Nad Nisou',
  'Hradistko',
  'Hranice',
  'Hrastnik',
  'Hrodna',
  'Hroznová Lhota',
  'Hrpelje',
  'Hrusevje',
  'Hrusovany Nad Jevisovkou',
  'Hrusovany U Brna',
  'Hrvatini',
  'Hrvatska Dubica',
  'Hrvatski Leskovac',
  'Hsian',
  'Hsiananning',
  'Hsichih',
  'Hsienchü',
  'Hsinchu',
  'Hsinchuang',
  'Hsinchuhsien',
  'Hsinpei',
  'Hsinshih',
  'Hsintien',
  'Hua',
  'Hua Hin',
  'Huabi',
  'Huadu',
  'Huai Khwang',
  'Huaian',
  'Huaibei',
  'Huaihua',
  'Huaiji',
  'Huainan',
  'Huairen',
  'Huairou',
  'Huaiyin',
  'Huajiang',
  'Huajuapan De León',
  'Huali',
  'Hualien',
  'Huamantla',
  'Huangdao',
  'Huanggang',
  'Huangjiang',
  'Huangpu',
  'Huangpuqu',
  'Huangshan',
  'Huangshi',
  'Huangyan',
  'Huangzhou',
  'Huaqiang',
  'Huaqiao',
  'Huarte',
  'Huashan',
  'Huatabampo',
  'Huaura',
  'Huawei',
  'Huaxi',
  'Huaxin',
  'Huayuan',
  'Huánuco',
  'Hubbard',
  'Hubbardston',
  'Hubei',
  'Hubli',
  'Hucknall',
  'Huddersfield',
  'Huddinge',
  'Hude',
  'Hudiksvall',
  'Hudson',
  'Hudson Falls',
  'Hudson Heights',
  'Hudsonville',
  'Hue',
  'Huejutla',
  'Huejutla De Reyes',
  'Huelma',
  'Huelva',
  'Huesca',
  'Huércal De Almería',
  'Huércal-overa',
  'Huétor Vega',
  'Hughes',
  'Hughesdale',
  'Hughesville',
  'Hughson',
  'Huglfing',
  'Hugo',
  'Huguenot',
  'Huhehaote',
  'Huian',
  'Huicheng',
  'Huijbergen',
  'Huilongguan',
  'Huimanguillo',
  'Huinan',
  'Huins',
  'Huis Ter Heide',
  'Huisheim',
  'Huissen',
  'Huissignies',
  'Huitong',
  'Huixquilucan',
  'Huizen',
  'Huizhou',
  'Huizingen',
  'Hukou',
  'Hukuyama',
  'Hulbert',
  'Hulda',
  'Hulin',
  'Hull',
  'Hulshout',
  'Hulst',
  'Hultrop',
  'Hultsfred',
  'Hulu Langat',
  'Huludao',
  'Humanes De Madrid',
  'Humarock',
  'Humber',
  'Humberg',
  'Humberston',
  'Humberstone',
  'Humble',
  'Humboldt',
  'Hume',
  'Humenné',
  'Humevale',
  'Humlebæk',
  'Hummelstown',
  'Humpolec',
  'Humppila',
  'Hun',
  'Hunabasi',
  'Hunan',
  'Hundested',
  'Hundsangen',
  'Hundsdorf',
  'Hundslund',
  'Hundvin',
  'Hunenberg',
  'Hungen',
  'Hungerford',
  'Hunibach',
  'Huningue',
  'Hunmanby',
  'Hunsel',
  'Hunstanton',
  'Hunt',
  'Hunt Valley',
  'Hunter',
  'Hunter River',
  'Hunters Creek Village',
  'Hunters Hill',
  'Huntersville',
  'Huntingburg',
  'Huntingdon',
  'Huntingdon Valley',
  'Huntington',
  'Huntington Beach',
  'Huntington Park',
  'Huntington Station',
  'Huntington Woods',
  'Huntingtown',
  'Huntley',
  'Huntly',
  'Huntsburg',
  'Huntspill',
  'Huntsville',
  'Huntwangen',
  'Hunya',
  'Hurden',
  'Hurghada',
  'Hurley',
  'Huron',
  'Hurricane',
  'Hurst',
  'Hurstbridge',
  'Hurstpierpoint',
  'Hurstville',
  'Hurtigheim',
  'Hurup',
  'Hurwenen',
  'Husinec',
  'Huskvarna',
  'Hustenovice',
  'Hustias',
  'Hustisford',
  'Hustopece',
  'Hustopece Nad Becvou',
  'Husum',
  'Husøy',
  'Hutchins',
  'Hutchinson',
  'Huthwaite',
  'Huttenheim',
  'Hutto',
  'Hutton',
  'Huttwil',
  'Huttwilen',
  'Huvudsta',
  'Huxley',
  'Huy',
  'Huyton',
  'Huzhou',
  'Hüblingen',
  'Hückelhoven',
  'Hückeswagen',
  'Hüfingen',
  'Hügelsheim',
  'Hülben',
  'Hüllhorst',
  'Hülzweiler',
  'Hünenberg',
  'Hünfeld',
  'Hünibach',
  'Hünningen',
  'Hünxe',
  'Hürtgenwald',
  'Hürth',
  'Hütschenhausen',
  'Hüttenberg',
  'Hüüru',
  'Hvalstad',
  'Hvalsø',
  'Hvar',
  'Hveragerdi',
  'Hveragerði',
  'Hvidovre',
  'Hvittingfoss',
  'Hvitträsk',
  'Hvozdna',
  'Hwacheon',
  'Hwagok',
  'Hwaseodong',
  'Hwasong',
  'Hyannis',
  'Hyannis Port',
  'Hyattsville',
  'Hyde',
  'Hyde Park',
  'Hyden',
  'Hyderabad',
  'Hyderabad-deccan',
  'Hyères',
  'Hygiene',
  'Hyland Park',
  'Hyltebruk',
  'Hyogo',
  'Hyomok',
  'Hyrum',
  'Hyrynsalmi',
  'Hyssna',
  'Hythe',
  'Hyuga',
  'Hyvinkää',
  'Hærland',
  'Højbjerg',
  'Højby',
  'Højslev',
  'Høl',
  'Hønefoss',
  'Høng',
  'Hørning',
  'Hørsholm',
  'Høvåg',
  'Høvik',
  'Ialyssós',
  'Iasi',
  'Ibach',
  'Ibadan',
  'Ibagué',
  'Ibaraki',
  'Ibarra',
  'Ibbenbüren',
  'Iberville',
  'Ibi',
  'Ibiaçá',
  'Ibiúna',
  'Ibiza',
  'Ibos',
  'Ibstock',
  'Ibusa',
  'Icaraí',
  'Ichalkaranji',
  'Ichapur',
  'Ichaso',
  'Ichenhausen',
  'Icheon',
  'Ichigaya',
  'Ichihara',
  'Ichikawa',
  'Ichikawadaimon',
  'Ichinomiya',
  'Ichinoseki',
  'Ickenham',
  'Icking',
  'Ickleton',
  'Içara',
  'Ida Grove',
  'Idabel',
  'Idaho City',
  'Idaho Falls',
  'Idaho Springs',
  'Idalou',
  'Idar-oberstein',
  'Ide',
  'Idimu',
  'Idkerberget',
  'Idle',
  'Idre',
  'Idrija',
  'Idstein',
  'Idukki',
  'Idyllwild',
  'Iecava',
  'Ieper',
  'Ierápetra',
  'Ieriki',
  'Ifako',
  'Iffeldorf',
  'Iffezheim',
  'Ifield',
  'Ifrane',
  'Ifs',
  'Iga',
  'Igando',
  'Iganmu',
  'Igea Marina',
  'Igel',
  'Igersheim',
  'Iggesund',
  'Iglesias',
  'Ignacio',
  'Ignalina',
  'Igny',
  'Igrejinha',
  'Iguala De La Independencia',
  'Igualada',
  'Iguerande',
  'Ihringen',
  'Iida',
  'Iisalmi',
  'Iittala',
  'Iizuka',
  'Ijaiye',
  'Ijamsville',
  'Ijebu-ode',
  'Ijlst',
  'Ijmuiden',
  'Ijsselmuiden',
  'Ijsselstein',
  'Ijuí',
  'Ijzendoorn',
  'Ikaalinen',
  'Ikast',
  'Ikeda',
  'Ikeja',
  'Ikitelli',
  'Ikoma',
  'Ikornnes',
  'Ikorodu',
  'Ikosi',
  'Ikotun',
  'Ikoyi',
  'Iksan',
  'Ikskile',
  'Il Chiaro',
  'Ilania',
  'Ilanz',
  'Ilchester',
  'Ile-ife',
  'Ilesa',
  'Ilford',
  'Ilfracombe',
  'Ilhabela',
  'Ilhéus',
  'Iliamna',
  'Iligan',
  'Iligan City',
  'Ilin',
  'Ilion',
  'Ilirska Bistrica',
  'Ilishan',
  'Ilkeston',
  'Ilkley',
  'Illerrieden',
  'Illertissen',
  'Illescas',
  'Illichivsk',
  'Illingen',
  'Illinois City',
  'Illiopolis',
  'Illkirch',
  'Illkirch-graffenstaden',
  'Illnau',
  'Illschwang',
  'Illzach',
  'Ilmajoki',
  'Ilmenau',
  'Ilminster',
  'Ilmmünster',
  'Iloilo',
  'Iloilo City',
  'Ilok',
  'Ilorin',
  'Ilpendam',
  'Ilsandong',
  'Ilsangu',
  'Ilsbo',
  'Ilsede',
  'Ilsenburg',
  'Ilsfeld',
  'Ilshofen',
  'Ilupeju',
  'Ilvesheim',
  'Im Tal',
  'Imabari',
  'Imabari-shi',
  'Imado',
  'Imari',
  'Imatra',
  'Imárcoain',
  'Imbé',
  'Imbituba',
  'Imielin',
  'Imizu',
  'Imlay City',
  'Immaculata',
  'Immendingen',
  'Immenhausen',
  'Immensee',
  'Immenstaad',
  'Immenstaad Am Bodensee',
  'Immenstadt',
  'Immingham',
  'Immokalee',
  'Imo',
  'Imola',
  'Imotski',
  'Imperatriz',
  'Imperia',
  'Imperial',
  'Imperial Beach',
  'Imphal',
  'Impruneta',
  'Imunidong',
  'Imus',
  'Ina',
  'Inabe',
  'Inage',
  'Inagh',
  'Inagi',
  'Inashiki',
  'Inazawa',
  'Inca',
  'Ince',
  'Inchangdong',
  'Incheon',
  'Inchicore',
  'Inchinnan',
  'Inchon',
  'Inchy-en-artois',
  'Incline Village',
  'Incourt',
  'Indaial',
  'Indaiatuba',
  'Independence',
  'Independencia',
  'Indi',
  'India',
  'Indialantic',
  'Indian Harbour Beach',
  'Indian Head',
  'Indian Orchard',
  'Indian Rocks Beach',
  'Indian Trail',
  'Indian Wells',
  'Indiana',
  'Indianapolis',
  'Indianola',
  'Indianópolis',
  'Indiantown',
  'Indio',
  'Indjija',
  'Indore',
  'Indre',
  'Indreabhán',
  'Induno Olona',
  'Indura',
  'Industry',
  'Inegol',
  'Infiesto',
  'Ingatestone',
  'Ingå',
  'Ingelbach',
  'Ingelfingen',
  'Ingelheim',
  'Ingelheim Am Rhein',
  'Ingelmunster',
  'Ingenio',
  'Ingersheim',
  'Ingersoll',
  'Ingham',
  'Ingleburn',
  'Ingleside',
  'Inglewood',
  'Ingmarsö',
  'Ingolstadt',
  'Ingomar',
  'Ingrandes',
  'Ingré',
  'Ingwiller',
  'Inhumas',
  'Initao',
  'Inkberrow',
  'Inkster',
  'Inman',
  'Innerleithen',
  'Innertkirchen',
  'Innes Park',
  'Inning',
  'Inning Am Ammersee',
  'Innisfail',
  'Innisfil',
  'Innsbruck',
  'Inola',
  'Inowroclaw',
  'Ins',
  'Insjön',
  'Institute',
  'Intercession City',
  'Intercourse',
  'Interlaken',
  'Interlochen',
  'International Falls',
  'Intervale',
  'Intra',
  'Inuyama',
  'Inver',
  'Inver Grove Heights',
  'Invercargill',
  'Inverell',
  'Invergordon',
  'Invergowrie',
  'Inverin',
  'Inverkeithing',
  'Inverkip',
  'Invermere',
  'Inverness',
  'Inveruno',
  'Inverurie',
  'Inwil',
  'Inwood',
  'Inyokern',
  'Inzago',
  'Inzai',
  'Inzell',
  'Ioánnina',
  'Iola',
  'Iona',
  'Ione',
  'Iowa',
  'Iowa City',
  'Iowa Falls',
  'Ipaja',
  'Ipartelep',
  'Ipatinga',
  'Iperó',
  'Iphofen',
  'Ipiranga',
  'Ipoh',
  'Ipolytölgyes',
  'Ippesheim',
  'Ipswich',
  'Iqaluit',
  'Iquique',
  'Ira',
  'Iracemápolis',
  'Iragna',
  'Iran',
  'Irapuato',
  'Irákleio',
  'Iráklio',
  'Irbid',
  'Irbil',
  'Irecê',
  'Ireland',
  'Irigny',
  'Iringa',
  'Irishtown',
  'Irkutsk',
  'Irlam',
  'Irmo',
  'Iron Acton',
  'Iron Mountain',
  'Iron River',
  'Irondale',
  'Ironton',
  'Ironwood',
  'Iroquois',
  'Irpen',
  'Irpin',
  'Irricana',
  'Irschenberg',
  'Irsingen',
  'Irthlingborough',
  'Iruma',
  'Iruña',
  'Irura',
  'Irurzun',
  'Irún',
  'Irvin',
  'Irvine',
  'Irving',
  'Irvington',
  'Irwin',
  'Irxleben',
  'Isa Town',
  'Isabela',
  'Isabella',
  'Isahaya',
  'Isanti',
  'Iscar',
  'Ischia',
  'Ischia Di Castro',
  'Isdalstø',
  'Ise',
  'Isehara',
  'Iselin',
  'Iseltwald',
  'Isen',
  'Isenbüttel',
  'Isenhagen',
  'Iseo',
  'Iserlohn',
  'Isernhagen-süd',
  'Isernia',
  'Isesaki',
  'Iseyin',
  'Isfahan',
  'Isfiyeh',
  'Ishida',
  'Ishikari',
  'Ishikawa',
  'Ishinomaki',
  'Ishpeming',
  'Ishøj',
  'Isigny-sur-mer',
  'Iskitler',
  'Iskola Út',
  'Islamabad',
  'Islamorada',
  'Island',
  'Island City',
  'Island Lake',
  'Island Park',
  'Island Pond',
  'Islandia',
  'Islands',
  'Isle',
  'Isle Of Palms',
  'Isle Of Wight',
  'Isleton',
  'Isleworth',
  'Islikon',
  'Islington',
  'Islip',
  'Islip Terrace',
  'Ismailia',
  'Ismaning',
  'Isneauville',
  'Isnes',
  'Isny',
  'Isny Im Allgäu',
  'Isogo',
  'Isokyrö',
  'Isola Della Scala',
  'Isola Delle Femmine',
  'Isola Vicentina',
  'Isolo',
  'Isparta',
  'Ispringen',
  'Isques',
  'Israelândia',
  'Issaquah',
  'Isselburg',
  'Issigeac',
  'Issoire',
  'Issoudun',
  'Issum',
  'Issy',
  'Issy-les-moulineaux',
  'Istambul',
  'Istanbul',
  'Istorp',
  'Istra',
  'Istrana',
  'Istres',
  'Istria',
  'Isumi',
  'Itabashi',
  'Itabashicho',
  'Itabira',
  'Itabuna',
  'Itacuruba',
  'Itaguaí',
  'Itagüí',
  'Itaim',
  'Itaipu',
  'Itaituba',
  'Itajaí',
  'Itajubá',
  'Itakura',
  'Itala',
  'Italia',
  'Italy',
  'Itambaracá',
  'Itami',
  'Itanagar',
  'Itano',
  'Itapecerica Da Serra',
  'Itapema',
  'Itapemirim',
  'Itaperuna',
  'Itapetininga',
  'Itapeva',
  'Itapevi',
  'Itapira',
  'Itapoã',
  'Itaquaquecetuba',
  'Itarsi',
  'Itasca',
  'Itaú De Minas',
  'Itaúna',
  'Itá',
  'Itäkeskus',
  'Itchenor',
  'Itegem',
  'Ithaca',
  'Itoigawa',
  'Itoman',
  'Itta Bena',
  'Itterbeek',
  'Ittersbach',
  'Ittervoort',
  'Itteville',
  'Ittingen',
  'Ittre',
  'Itu',
  'Ituiutaba',
  'Itupeva',
  'Ituzaingo',
  'Itzehoe',
  'Itzig',
  'Itzstedt',
  'Ivalo',
  'Ivancice',
  'Ivancna Gorica',
  'Ivanec',
  'Ivangorod',
  'Ivanhoe',
  'Ivanhoe East',
  'Ivanic-grad',
  'Ivanka Pri Dunaji',
  'Ivanka Pri Nitre',
  'Ivanofrankivsk',
  'Ivanofrankovsk',
  'Ivanovo',
  'Ivanovskaya',
  'Ivel',
  'Iver',
  'Ivory',
  'Ivoryton',
  'Ivoti',
  'Ivrea',
  'Ivry-la-bataille',
  'Ivry-le-temple',
  'Ivry-sur-seine',
  'Ivybridge',
  'Ivyland',
  'Iwade',
  'Iwaki',
  'Iwakuni',
  'Iwakura',
  'Iwamizawa',
  'Iwanuma',
  'Iwata',
  'Iwatsuki',
  'Iwo',
  'Iwonicz-zdrój',
  'Ixcateopan De Cuauhtémoc',
  'Ixelles',
  'Ixmiquilpan',
  'Ixonia',
  'Ixtlahuaca De Rayón',
  'Ixtlán De Juárez',
  'Ixworth',
  'Iyo',
  'Iza',
  'Izabelin',
  'Izarza',
  'Izbica Kujawska',
  'Izegem',
  'Izel',
  'Izernore',
  'Izhevsk',
  'Izlake',
  'Izmir',
  'Izmit',
  'Izola',
  'Iztapalapa',
  'Izumi',
  'Izumo',
  'Izunokuni',
  'Izurza',
  'Ílhavo',
  'Ísafjörður',
  'Île-perrot',
  'Île-tudy',
  'Ïdar',
  'Ja Ela',
  'Jabalpur',
  'Jabaquara',
  'Jabares De Los Oteros',
  'Jabbeke',
  'Jabi',
  'Jablonec Nad Nisou',
  'Jablonne Nad Orlici',
  'Jablunkov',
  'Jaboatão',
  'Jaboticabal',
  'Jaca',
  'Jacareacanga',
  'Jacareí',
  'Jacarepaguá',
  'Jacarezinho',
  'Jacksboro',
  'Jackson',
  'Jackson Center',
  'Jackson Heights',
  'Jackson St Forest',
  'Jacksonville',
  'Jacksonville Beach',
  'Jacobs Creek',
  'Jacou',
  'Jacó',
  'Jadcherla',
  'Jade',
  'Jaén',
  'Jaffa',
  'Jaffa-tel Aviv',
  'Jaffna',
  'Jaffrey',
  'Jagadhri',
  'Jagakarsa',
  'Jagatsinghpur',
  'Jagdalpur',
  'Jagodina',
  'Jaguarari',
  'Jaguariúna',
  'Jaipur',
  'Jaipur City',
  'Jaisalmer',
  'Jajpur',
  'Jakar',
  'Jakarta',
  'Jakarta Pusat',
  'Jakatra',
  'Jakobsberg',
  'Jakobstad',
  'Jakubovany',
  'Jal',
  'Jala',
  'Jalan',
  'Jalandhar',
  'Jalgaon',
  'Jalhay',
  'Jalisco',
  'Jalkovec',
  'Jallans',
  'Jalna',
  'Jalpaiguri',
  'Jamaica',
  'Jamaica Plain',
  'Jamalpur',
  'Jamberoo',
  'Jambes',
  'Jamesport',
  'Jamestown',
  'Jamesville',
  'Jamison',
  'Jammu',
  'Jammu City',
  'Jamnagar',
  'Jamne Nad Orlici',
  'Jamshedpur',
  'Jamshoro',
  'Jamui',
  'Jamul',
  'Janakkala',
  'Jandaia',
  'Jandira',
  'Jane Lew',
  'Janesville',
  'Jangan',
  'Janneyrias',
  'Janov',
  'Janowice Wielkie',
  'Janzé',
  'Japan',
  'Jar',
  'Jaraguá Do Sul',
  'Jard-sur-mer',
  'Jardim',
  'Jardim América',
  'Jardim Paulista',
  'Jardim São Paulo',
  'Jaren',
  'Jarinu',
  'Jarnac',
  'Jarocin',
  'Jaromer',
  'Jarrell',
  'Jarrettsville',
  'Jarrow',
  'Jarville-la-malgrange',
  'Jarzé',
  'Jashpurnagar',
  'Jasienica',
  'Jasin',
  'Jasionka',
  'Jaska',
  'Jaslo',
  'Jasol',
  'Jasov',
  'Jasper',
  'Jassans-riottier',
  'Jastrebarsko',
  'Jastrzebie Zdroj',
  'Jataí',
  'Jatiasih',
  'Jatiuwung',
  'Jatni',
  'Jauchelette',
  'Jaunay-clan',
  'Jaunpur',
  'Jaures',
  'Jaux',
  'Jaú',
  'Java',
  'Javené',
  'Jawa',
  'Jawor',
  'Jaworze',
  'Jaworzno',
  'Jay',
  'Jaya',
  'Jayanagar',
  'Jaysingpur',
  'Jazan',
  'Jákfalva',
  'Jánossomorja',
  'Jánovce',
  'Jászberény',
  'Jászfényszaru',
  'Játiva',
  'Jávea',
  'Jääli',
  'Jämsä',
  'Jämtland',
  'Järane',
  'Järbo',
  'Järna',
  'Järpås',
  'Järpen',
  'Järvenpää',
  'Järvsö',
  'Jdeïdé',
  'Jeanerette',
  'Jeannette',
  'Jebsheim',
  'Jecheon',
  'Jedburgh',
  'Jedda',
  'Jeddah',
  'Jedlina',
  'Jedrzejow',
  'Jefferson',
  'Jefferson City',
  'Jefferson Valley',
  'Jeffersonville',
  'Jegenstorf',
  'Jeju',
  'Jekyll Island',
  'Jelah',
  'Jelambar',
  'Jelenia Góra',
  'Jelgava',
  'Jellico',
  'Jelling',
  'Jelutong',
  'Jemelle',
  'Jemeppe',
  'Jemez Pueblo',
  'Jemnice',
  'Jena',
  'Jenison',
  'Jenjarom',
  'Jenkintown',
  'Jenks',
  'Jenner',
  'Jennersdorf',
  'Jennings',
  'Jensen Beach',
  'Jeongok',
  'Jeonju',
  'Jerez De La Frontera',
  'Jerez De Los Caballeros',
  'Jericho',
  'Jerome',
  'Jersbek',
  'Jersey',
  'Jersey City',
  'Jersleben',
  'Jerusalem',
  'Jesenice',
  'Jesenik',
  'Jesenik Nad Odrou',
  'Jesi',
  'Jesmond',
  'Jesolo',
  'Jessen',
  'Jessheim',
  'Jessieville',
  'Jessore',
  'Jessup',
  'Jesteburg',
  'Jestetten',
  'Jesup',
  'Jesús María',
  'Jetersville',
  'Jetis',
  'Jette',
  'Jettenbach',
  'Jettingen-scheppach',
  'Jeumont',
  'Jeungpyeong',
  'Jeuss',
  'Jever',
  'Jevicko',
  'Jewett',
  'Jezreel',
  'Jeßnitz',
  'Jérica',
  'Jhagadia',
  'Jhajjar',
  'Jhansi',
  'Jharsuguda',
  'Ji-Paraná',
  'Ji-paraná',
  'Jiading',
  'Jiamusi',
  'Jian',
  'Jiande',
  'Jiang',
  'Jiangan',
  'Jiangbei',
  'Jiangjin',
  'Jiangmen',
  'Jiangnan',
  'Jiangning',
  'Jiangshan',
  'Jiangxi',
  'Jiangyan',
  'Jiangyin',
  'Jianxin',
  'Jianxing',
  'Jianyang',
  'Jiaojiang',
  'Jiaonan',
  'Jiaozhou',
  'Jiaozuo',
  'Jiashan',
  'Jiaxin',
  'Jiaxing',
  'Jiazhuang',
  'Jibowu',
  'Jibuti',
  'Jicin',
  'Jicina',
  'Jiexiu',
  'Jieyang',
  'Jihlava',
  'Jijel',
  'Jilava',
  'Jilin',
  'Jilove U Prahy',
  'Jim Thorpe',
  'Jimbaran',
  'Jimei',
  'Jimeirah',
  'Jiménez',
  'Jimma',
  'Jimo',
  'Jinan',
  'Jince',
  'Jincheng',
  'Jind',
  'Jindabyne',
  'Jindrichuv Hradec',
  'Jingan',
  'Jingdezhen',
  'Jinghai',
  'Jingjiang',
  'Jingmen',
  'Jingning',
  'Jingxing',
  'Jingyang',
  'Jingyu',
  'Jingzhou',
  'Jinhae',
  'Jinhua',
  'Jinhua Shi',
  'Jining',
  'Jinja',
  'Jinjang',
  'Jinjiang',
  'Jinju',
  'Jinling',
  'Jinniu',
  'Jinonice',
  'Jinotega',
  'Jinqiao',
  'Jinshan',
  'Jintan',
  'Jinyin',
  'Jinyuan',
  'Jinyun',
  'Jinzhou',
  'Jiquilpan De Juárez',
  'Jirkov',
  'Jishigang',
  'Jishou',
  'Jiujiang',
  'Jiulongpo',
  'Jiuquan',
  'Jiutai',
  'Jiutepec',
  'Jixi',
  'Jiyuan',
  'Jílové U Prahy',
  'Joaçaba',
  'Joaquin',
  'João Lisboa',
  'João Monlevade',
  'João Pessoa',
  'João Pinheiro',
  'Jockgrim',
  'Jocotenango',
  'Jodhpur',
  'Jodoigne',
  'Joelton',
  'Joensuu',
  'Joes',
  'Joetsu',
  'Jogeshwari',
  'Jogjakarta',
  'Johannesberg',
  'Johannesburg',
  'Johanneshov',
  'Johannisberg',
  'Johanniskirchen',
  'Johns Island',
  'Johnson',
  'Johnson City',
  'Johnson Creek',
  'Johnsonville',
  'Johnston',
  'Johnston City',
  'Johnstone',
  'Johnstown',
  'Johor',
  'Johor Baharu',
  'Johor Bahru',
  'Johore Bahru',
  'Joigny',
  'Joinville',
  'Joinville-le-pont',
  'Jokioinen',
  'Jokipii',
  'Jokkmokk',
  'Jolanda Di Savoia',
  'Joliet',
  'Joliette',
  'Jomala',
  'Jombang',
  'Jona',
  'Jonanjima',
  'Jonava',
  'Jonchery',
  'Jonen',
  'Jones Mills',
  'Jonesboro',
  'Jonesborough',
  'Jonesport',
  'Jonestown',
  'Jonesville',
  'Jonquière',
  'Jonschwil',
  'Jonsered',
  'Jonstorp',
  'Jonzac',
  'Joplin',
  'Joppa',
  'Jordan',
  'Jordbro',
  'Jordrup',
  'Jorhat',
  'Jork',
  'Jorvas',
  'Jos',
  'Joseph',
  'José Bonifácio',
  'Joshua',
  'Joshua Tree',
  'Joso',
  'Josse',
  'Jouarre',
  'Jouars-pontchartrain',
  "Joue-l'abbe",
  'Joué-lés-tours',
  'Joure',
  'Jouy-aux-arches',
  'Jouy-en-josas',
  'Jouy-le-moutier',
  'Jouy-sur-eure',
  'Joünié',
  'Jozefoslaw',
  'Jozefow',
  'Jõhvi',
  'Jönköping',
  'Juan-les-pins',
  'Juankoski',
  'Juarez',
  'Juatuba',
  'Juazeiro',
  'Juazeiro Do Norte',
  'Juárez',
  'Juba',
  'Jubail',
  'Jubbega',
  'Juchipila',
  'Juchitán De Zaragoza',
  'Judique',
  'Judsonia',
  'Juelsminde',
  'Jugenheim',
  'Juhala',
  'Juhu',
  'Juigné-sur-loire',
  'Juiz De Fora',
  'Juja',
  'Jujuy',
  'Julbach',
  'Julesburg',
  'Julian',
  'Julianadorp',
  'Jumairah',
  'Jumeira',
  'Jumet',
  'Jumilla',
  'Junagadh',
  'Juncosa',
  'Junction City',
  'Jundah',
  'Jundiaí',
  'Juneau',
  'Jungang',
  'Jungdae',
  'Junin',
  'Juniper Green',
  'Jupille',
  'Jupiter',
  'Jurançon',
  'Jurbise',
  'Jurmala',
  'Jurong',
  'Jurong West',
  'Jurowce',
  'Jursta',
  'Justin',
  'Juupajoki',
  'Juvignac',
  'Juvisy-sur-orge',
  'Jüchen',
  'Jülich',
  'Jünkerath',
  'Jüri',
  'Jyderup',
  'Jyllinge',
  'Jyskä',
  'Jyväskylä',
  'Jyväskylän Maalaiskunta',
  'Jørpeland',
  "K'ut'aisi",
  'Kaag',
  'Kaarina',
  'Kaarst',
  'Kaatsheuvel',
  'Kabale',
  'Kabatas',
  'Kabe',
  'Kabul',
  'Kabwe',
  'Kachidoki',
  'Kadan',
  'Kadapa',
  'Kadawata',
  'Kadi',
  'Kadikoy',
  'Kadima',
  'Kadlubia',
  'Kadoka',
  'Kadoma',
  'Kaduna',
  'Kaduwela',
  'Kafanchan',
  'Kafr El Sheikh',
  'Kafr Qara`',
  'Kafr Saba',
  'Kaga',
  'Kagawa',
  'Kagithane',
  'Kagoshima',
  'Kagoshima-shi',
  'Kahang',
  'Kahawa',
  'Kahl',
  'Kahl Am Main',
  'Kahla',
  'Kahoka',
  'Kahoku',
  'Kahuku',
  'Kahului',
  'Kaifeng',
  'Kaikoura',
  'Kail',
  'Kaili',
  'Kailua',
  'Kailua Kona',
  'Kainan',
  'Kaiping',
  'Kaiseraugst',
  'Kaisersbach',
  'Kaiserslautern',
  'Kaisheim',
  'Kaitaa',
  'Kaithal',
  'Kaiwaka',
  'Kaiwharawhara',
  'Kaizuka',
  'Kajaani',
  'Kajang',
  'Kajiki',
  'Kakamega',
  'Kakamigahara',
  'Kakasd',
  'Kakegawa',
  'Kakinada',
  'Kakkad',
  'Kakogawa',
  'Kala Amb',
  'Kalaheo',
  'Kalajoki',
  'Kalama',
  'Kalamariá',
  'Kalamazoo',
  'Kalamáki',
  'Kalamáta',
  'Kalanaur',
  'Kalangala',
  'Kalanti',
  'Kalasan',
  'Kalbach',
  'Kalchreuth',
  'Kalgan',
  'Kalgoorlie',
  'Kalibata',
  'Kalibo',
  'Kalideres',
  'Kalimoni',
  'Kalinin',
  'Kaliningrad',
  'Kalispell',
  'Kalisz',
  'Kalix',
  'Kalka',
  'Kalkaji',
  'Kalkaji Devi',
  'Kalkar',
  'Kalkaska',
  'Kall',
  'Kallhäll',
  'Kallithéa',
  'Kallnach',
  'Kalmar',
  'Kalmthout',
  'Kalokhórion',
  'Kalol',
  'Kalona',
  'Kalorama',
  'Kalpakkam',
  'Kalpetta',
  'Kalsdorf Bei Graz',
  'Kaltbrunn',
  'Kaltenkirchen',
  'Kaltensundheim',
  'Kaltern',
  'Kaluga',
  'Kalulushi',
  'Kalundborg',
  'Kalush',
  'Kalutara',
  'Kalyan',
  'Kalyani',
  'Kamagaya',
  'Kamaishi',
  'Kamakura',
  'Kamanje',
  'Kamas',
  'Kamata',
  'Kamateró',
  'Kameari',
  'Kamen',
  'Kamenice',
  'Kamenicky Senov',
  'Kamenne Zehrovice',
  'Kamensk-uralskiy',
  'Kamenz',
  'Kameoka',
  'Kamerik',
  'Kami-hasami',
  'Kami-meguro',
  'Kamiah',
  'Kamieniec Wroctawski',
  'Kamikawa',
  'Kamikita',
  'Kamimachi',
  'Kamimura',
  'Kaminoseki',
  'Kamisu',
  'Kamloops',
  'Kamnik',
  'Kamo',
  'Kamogawa',
  'Kamojima',
  'Kamp-bornhofen',
  'Kamp-lintfort',
  'Kampala',
  'Kampar',
  'Kampen',
  'Kampenhout',
  'Kamperland',
  'Kampong Datok Keramat',
  'Kampong Jawa Tengah',
  'Kampong Tantaya',
  'Kampung Subang',
  'Kamsdorf',
  'Kamuela',
  'Kamunting',
  'Kamyk',
  'Kanab',
  'Kanagawa',
  'Kanakapura',
  'Kanata',
  'Kanayama-cho',
  'Kanazawa',
  'Kanazawa-shi',
  'Kancheepuram',
  'Kanchipuram',
  'Kanchrapara',
  'Kanczuga',
  'Kanda',
  'Kandel',
  'Kandern',
  'Kandi',
  'Kandivli',
  'Kandla',
  'Kandy',
  'Kaneda',
  'Kaneohe',
  'Kangasala',
  'Kangasniemi',
  'Kangnam',
  'Kangos',
  'Kanhangad',
  'Kani',
  'Kanice',
  'Kanie',
  'Kankaanpää',
  'Kankakee',
  'Kankan',
  'Kannabe',
  'Kannapolis',
  'Kanniyakumari',
  'Kannot',
  'Kannur',
  'Kano',
  'Kanonji',
  'Kanoya',
  'Kanpur',
  'Kansas',
  'Kansas City',
  'Kantemirovka',
  'Kantens',
  'Kantomaanpää',
  'Kanturk',
  'Kanuma',
  'Kanyakumari',
  'Kanye',
  'Kanzaki',
  'Kaohsiung',
  'Kapaa',
  'Kapaau',
  'Kapan',
  'Kapar',
  'Kapas',
  'Kapel-avezaath',
  'Kapelle',
  'Kapellen',
  'Kapellen-drusweiler',
  'Kapfenberg',
  'Kapfenstein',
  'Kaplan',
  'Kapolei',
  'Kaposmero',
  'Kaposújlak',
  'Kaposvár',
  'Kappeln',
  'Kappelrodeck',
  'Kaprijke',
  'Kapunda',
  'Kapurthala',
  'Kapuvár',
  'Karachi',
  'Karad',
  'Karaganda',
  'Karagandy',
  'Karaikudi',
  'Karaj',
  'Karak',
  'Karaköy',
  'Karaman',
  'Karanchi',
  'Karancsalja',
  'Karanganyar',
  'Karaoglu',
  'Karasu',
  'Karatepe',
  'Karawang',
  'Karben',
  'Karcag',
  'Karczew',
  'Kardítsa',
  'Karen',
  'Karet Teng Sien',
  'Kargow',
  'Kargowa',
  'Karimnagar',
  'Karis',
  'Kariya',
  'Karjat',
  'Karkala',
  'Karkkila',
  'Karkur',
  'Karlin',
  'Karlivka',
  'Karlín',
  'Karlovac',
  'Karlovasi',
  'Karlovy Vary',
  'Karlsbach',
  'Karlsdorf-neuthard',
  'Karlsfeld',
  'Karlshagen',
  'Karlshamn',
  'Karlshorst',
  'Karlshuld',
  'Karlskoga',
  'Karlskron',
  'Karlskrona',
  'Karlslunde',
  'Karlslunde Strand',
  'Karlsruhe',
  'Karlsruhe-hagsfeld',
  'Karlsruhe-knielingen',
  'Karlsruhe-mühlburg',
  'Karlstad',
  'Karlstadt',
  'Karlstein',
  'Karmelava',
  'Karmé Yosef',
  "Karmi'el",
  'Karnal',
  'Karnes City',
  'Karns City',
  'Karol Bagh',
  'Karolinka',
  'Karrada',
  'Karratha',
  'Karrebæksminde',
  'Karsiyaka',
  'Kartal',
  'Karu',
  'Karuna',
  'Karunagapalli',
  'Karur',
  'Karvia',
  'Karvina',
  'Karviná 4',
  'Kasama',
  'Kasaoka',
  'Kasaragod',
  'Kase',
  'Kasejovice',
  'Kasendorf',
  'Kasganj',
  'Kashar',
  'Kashgar',
  'Kashihara',
  'Kashima',
  'Kashipur',
  'Kashiwa',
  'Kashiwara',
  'Kashiwazaki',
  'Kasilof',
  'Kasimpasa',
  'Kassel',
  'Kasserine',
  'Kasson',
  'Kastamonu',
  'Kastanienbaum',
  'Kastav',
  'Kastel Kambelovac',
  'Kastel Luksic',
  'Kastellaun',
  'Kasterlee',
  'Kastl',
  'Kastoriá',
  'Kastrup',
  'Kasuga',
  'Kasugai',
  'Kasukabe',
  'Kasumigaseki',
  'Kasumigaura',
  'Kasur',
  'Katajaranta',
  'Katano',
  'Katerinky',
  'Kateríni',
  'Kathmandu',
  'Kathu',
  'Kathua',
  'Katikati',
  'Katlenburg-lindau',
  'Kato Achaia',
  'Katonah',
  'Katoomba',
  'Katori',
  'Katovice',
  'Katowice',
  'Katra',
  'Katrineholm',
  'Katsina',
  'Katsuragi',
  'Kattarp',
  'Katuwawala',
  'Katwijk',
  'Katwijk Aan De Rijn',
  'Katy',
  'Katy Wroclawskie',
  'Katzenelnbogen',
  'Katzenfurt',
  'Kaufbeuren',
  'Kaufering',
  'Kaufman',
  'Kaufungen',
  'Kauhajoki',
  'Kauhava',
  'Kaukapakapa',
  'Kaukauna',
  'Kaunakakai',
  'Kaunas',
  'Kauneonga Lake',
  'Kauniainen',
  'Kauno',
  'Kaustinen',
  'Kavakli',
  'Kavaklidere',
  'Kavála',
  'Kawagoe',
  'Kawaguchi',
  'Kawai',
  'Kawana Waters',
  'Kawanishi',
  'Kawanoe',
  'Kawaramachi',
  'Kawasaki',
  'Kawasaki Si',
  'Kawasakicho',
  'Kawauchi',
  'Kawawa',
  'Kawerau',
  'Kayabacho',
  'Kayangdong',
  'Kaycee',
  'Kayes',
  'Kayhude',
  'Kayl',
  'Kayseri',
  'Kaysville',
  'Kazan',
  'Kazanlak',
  'Kazawa',
  'Kazincbarcika',
  'Kazlu Ruda',
  'Kál',
  'Kâmpôt',
  'Käärdi',
  'Käärmelahti',
  'Kälarne',
  'Källby',
  'Kärra',
  'Kärsämäki',
  'Käshofen',
  'Kävlinge',
  'Kåge',
  'Kågeröd',
  'Kållered',
  'Kårvik',
  'Kbely',
  'Kdyne',
  'Keaau',
  'Kealakekua',
  'Kealia',
  'Keansburg',
  'Kearney',
  'Kearneysville',
  'Kearny',
  'Kearsley',
  'Keasbey',
  'Keauhou',
  'Kebayoran',
  'Kebayoran Baru',
  'Kebayoran Lama',
  'Kebayoran Lama Utara',
  'Kebon',
  'Kebon Jeruk',
  'Kecel',
  'Kechi',
  'Kecskemét',
  'Keçiören',
  'Kediri',
  'Kedoya Selatan',
  'Kedzierzyn-kozle',
  'Keego Harbor',
  'Keele',
  'Keelung',
  'Keene',
  'Keene Valley',
  'Keenes',
  'Keerbergen',
  'Kefar Aviv',
  'Kefar Bin Nun',
  'Kefar Habad',
  'Kefar Hanagid',
  'Kefar Hess',
  'Kefar Menahem',
  'Kefar Monash',
  'Kefar Neter',
  'Kefar Netter',
  'Kefar Sava',
  'Kefar Shmaryahu',
  'Kefar Shmuel',
  'Kefar Sirkin',
  'Kefar Truman',
  'Kefar Uriya',
  'Kefar Veradim',
  'Kefar Vitkin',
  'Kefar Yona',
  'Kefár Saba',
  'Keffi',
  'Kefikon',
  'Keflavík',
  'Kegworth',
  'Kehl',
  'Kehl Am Rhein',
  'Kehlen',
  'Kehra',
  'Kehrig',
  'Kehrsatz',
  'Keighley',
  'Keila',
  'Keilor',
  'Keith',
  'Keizer',
  'Kelambakkam',
  'Kelaniya',
  'Kelapagading',
  'Kelayres',
  'Kelberg',
  'Kelbrook',
  'Kelburn',
  'Kelheim',
  'Kelkheim',
  'Keller',
  'Kellogg',
  'Kellokoski',
  'Kells',
  'Kellyville',
  'Kelmscott',
  'Kelowna',
  'Kelsall',
  'Kelseyville',
  'Kelso',
  'Kelsterbach',
  'Kelton',
  'Kelty',
  'Kelvedon',
  'Kelvedon Hatch',
  'Kelvin Grove',
  'Kemah',
  'Kemalpasa',
  'Kemang',
  'Kemanggisan',
  'Kematen',
  'Kematen An Der Krems',
  'Kemayoran',
  'Kembangan',
  'Kemberg',
  'Kemble',
  'Kemerovo',
  'Kemi',
  'Kemmenau',
  'Kemmerer',
  'Kemnath',
  'Kemnay',
  'Kemp',
  'Kempas',
  'Kempele',
  'Kempen',
  'Kempner',
  'Kempston',
  'Kempten',
  'Kempton',
  'Kemptthal',
  'Kemptville',
  'Kenai',
  'Kenansville',
  'Kenbridge',
  'Kendal',
  'Kendall',
  'Kendall Park',
  'Kendallville',
  'Kendari',
  'Kengeri',
  'Kenilworth',
  'Kenitra',
  'Kenley',
  'Kenmare',
  'Kenmore',
  'Kenn',
  'Kennebunk',
  'Kennebunkport',
  'Kennedale',
  'Kenner',
  'Kennesaw',
  'Kennett',
  'Kennett Square',
  'Kennewick',
  'Kennington',
  'Kennoway',
  'Kenora',
  'Kenosha',
  'Kenova',
  'Kensington',
  'Kent',
  'Kent City',
  'Kentfield',
  'Kentford',
  'Kenthurst',
  'Kentland',
  'Kenton',
  'Kents Store',
  'Kentucky',
  'Kentville',
  'Kentwood',
  'Kenvil',
  'Kenwick',
  'Kenwood',
  'Kenyase',
  'Kenzingen',
  'Keokuk',
  'Keonjhar',
  'Kepahiang',
  'Kepno',
  'Kepong',
  'Kerang',
  'Keratéa',
  'Keratsíni',
  'Kerava',
  'Kerälä',
  'Kerekegyháza',
  'Kerem Ben Zimra',
  'Kerem Maharal',
  'Kerepes',
  'Keresley',
  'Kerhonkson',
  'Kericho',
  'Kerikeri',
  'Kerikeri Inlet',
  'Kerkdriel',
  'Kerkenveld',
  'Kerkrade',
  'Kerkwijk',
  'Kerkwitz',
  'Kerman',
  'Kermit',
  'Kermt',
  'Kernersville',
  'Kerns',
  'Kerobokan',
  'Kerpen',
  'Kerrville',
  'Kerrykeel',
  'Kersey',
  'Kerteminde',
  'Kervignac',
  'Kerzers',
  'Kescheid',
  'Keshena',
  'Keskastel',
  'Kesklinn',
  'Kessel',
  'Kessel-lo',
  'Kesselsdorf',
  'Kessenich',
  'Kesteren',
  'Kestilä',
  'Keswick',
  'Keszthely',
  'Ketchikan',
  'Ketchum',
  'Ketsch',
  'Kettenhausen',
  'Kettenis',
  'Kettering',
  'Ketteringham',
  'Kety',
  'Ketzin',
  'Keuka Park',
  'Keuruu',
  'Kevelaer',
  'Kew',
  'Kew East',
  'Kew Gardens',
  'Kewanee',
  'Kewaskum',
  'Kewaunee',
  'Kewstoke',
  'Key Biscayne',
  'Key Colony Beach',
  'Key Largo',
  'Key West',
  'Keyingham',
  'Keymar',
  'Keynsham',
  'Keyport',
  'Keysborough',
  'Keyser',
  'Keystone',
  'Keystone Heights',
  'Kezdivasarhely',
  'Kezmarok',
  'Kédange-sur-canner',
  'Kétpó',
  'Kfar Ahim',
  'Kfar Maimon',
  'Kfar Malal',
  'Kfar Saba',
  'Kfar Tavor',
  'Kfar Vitkin',
  'Kfar Yona',
  'Khabarovsk',
  'Khadki',
  'Khaimah',
  'Khairpur',
  'Khalapur',
  'Khalándrion',
  'Khalástra',
  'Khalishpur',
  'Khamanon',
  'Khandallah',
  'Khanna',
  'Khanty-mansiysk',
  'Khar',
  'Kharagpur',
  'Kharar',
  'Kharian',
  'Kharkiv',
  'Kharkov',
  'Khartoum',
  'Khartoum North',
  'Khasab',
  'Kheda',
  'Kheri',
  'Kherson',
  'Khersónisos',
  'Khet Lat Krabang',
  'Khilgaon',
  'Khimki',
  'Khlong Luang',
  'Khlong San',
  'Khlong Toei',
  'Khmelnitskiy',
  'Khmelnytskyy',
  'Khobar',
  'Kholargós',
  'Khon Kaen',
  'Khopoli',
  'Khordha',
  'Khorgos',
  'Khoroshëvskiy',
  'Khorramabad',
  'Khotyn',
  'Khujand',
  'Khulna',
  'Kiama',
  'Kiambu',
  'Kiamesha Lake',
  'Kibbutz Afikim',
  'Kibworth',
  'Kibworth Harcourt',
  'Kibæk',
  'Kichijoji',
  'Kicin',
  'Kicukiro',
  'Kidderminster',
  'Kidlington',
  'Kidricevo',
  'Kidsgrove',
  'Kiebitzreihe',
  'Kiedrich',
  'Kiefersfelden',
  'Kiel',
  'Kielce',
  'Kieler',
  'Kierspe',
  'Kieselbronn',
  'Kiev',
  'Kifisiá',
  'Kifissia',
  'Kigali',
  'Kiganjo',
  'Kihei',
  'Kihikihi',
  'Kihniö',
  'Kikinda',
  'Kikuchi',
  'Kikugawa',
  'Kikuyu',
  'Kilafors',
  'Kilauea',
  'Kilb',
  'Kilbeggan',
  'Kilbirnie',
  'Kilbourne',
  'Kilbride',
  'Kilchberg',
  'Kilcoole',
  'Kilcullen',
  'Kildare',
  'Kilgore',
  'Kilifi',
  'Kilimanjaro',
  'Kilkeel',
  'Kilkenny',
  'Kilkís',
  'Kill Devil Hills',
  'Killaloe',
  'Killamarsh',
  'Killarney',
  'Killbuck',
  'Killdeer',
  'Killeen',
  'Killen',
  'Killeshandra',
  'Killiney',
  'Killington',
  'Killingworth',
  'Killorglin',
  'Killwangen',
  'Kilmacolm',
  'Kilmacrennan',
  'Kilmacthomas',
  'Kilmainham',
  'Kilmarnock',
  'Kilmaurs',
  'Kilpauk',
  'Kilpilahti',
  'Kilrush',
  'Kilsyth',
  'Kilwinning',
  'Kimball',
  'Kimberley',
  'Kimberling City',
  'Kimberly',
  'Kimberton',
  'Kimbolton',
  'Kimchon',
  'Kimhae',
  'Kimito',
  'Kimitsu',
  'Kimle',
  'Kimpo',
  'Kimpton',
  'Kimstad',
  'Kinabalu',
  'Kincardine',
  'Kincheloe',
  'Kindelbrück',
  'Kinder',
  'Kinderdijk',
  'Kinderhook',
  'Kindersley',
  'Kinding',
  'Kineshma',
  'Kineton',
  'King',
  'King City',
  'King George',
  'King Hill',
  'King Of Prussia',
  'King William',
  "King's Lynn",
  'Kingdom City',
  'Kingersheim',
  'Kingfield',
  'Kingfisher',
  'Kingman',
  'Kings Bromley',
  'Kings Cross',
  'Kings Langley',
  'Kings Mills',
  'Kings Mountain',
  'Kings Park',
  'Kingsbridge',
  'Kingsburg',
  'Kingsbury',
  'Kingsclear',
  'Kingsclere',
  'Kingscliff',
  'Kingscourt',
  'Kingseat',
  'Kingsey',
  'Kingsey Falls',
  'Kingsford',
  'Kingskerswell',
  'Kingsland',
  'Kingsley',
  'Kingsnorth',
  'Kingsport',
  'Kingsthorpe',
  'Kingston',
  'Kingston Bagpuze',
  'Kingston On Soar',
  'Kingston Springs',
  'Kingston Upon Hull',
  'Kingston Upon Thames',
  'Kingstown',
  'Kingstree',
  'Kingsville',
  'Kingswinford',
  'Kingswood',
  'Kington',
  'Kington Langley',
  'Kingwood',
  'Kinloss',
  'Kinna',
  'Kinnarp',
  'Kinnegad',
  'Kinneret',
  'Kinokawa',
  'Kinondoni',
  'Kinosaki',
  'Kinrooi',
  'Kinross',
  'Kinsale',
  'Kinshasa',
  'Kinshicho',
  'Kinsley',
  'Kinsman',
  'Kinston',
  'Kintnersville',
  'Kintore',
  'Kinzers',
  'Kippen',
  'Kippenheim',
  'Kirby',
  'Kirby Muxloe',
  'Kirchardt',
  'Kirchbarkau',
  'Kirchberg',
  'Kirchberg Am Wechsel',
  'Kirchberg An Der Murr',
  'Kirchberg An Der Raab',
  'Kirchborchen',
  'Kirchdorf',
  'Kirchen',
  'Kirchentellinsfurt',
  'Kirchheim',
  'Kirchheim Bei München',
  'Kirchheim Unter Teck',
  'Kirchheimbolanden',
  'Kirchhorst',
  'Kirchhundem',
  'Kirchlengern',
  'Kirchlindach',
  'Kirchlinteln',
  'Kirchohsen',
  'Kirchschlag',
  'Kirchseeon',
  'Kirchstetten',
  'Kirchzarten',
  'Kirchzell',
  'Kireka',
  'Kiriat Arieh',
  'Kiriat Hayim',
  'Kirikiri',
  'Kirikkale',
  'Kirk Ella',
  'Kirkby',
  'Kirkby In Ashfield',
  'Kirkby Lonsdale',
  'Kirkby Mallory',
  'Kirkby Stephen',
  'Kirkcaldy',
  'Kirkcudbright',
  'Kirke Såby',
  'Kirke-hyllinge',
  'Kirkel',
  'Kirkenes',
  'Kirkenær',
  'Kirkham',
  'Kirkintilloch',
  'Kirkkonummi',
  'Kirkland',
  'Kirkland Lake',
  'Kirklareli',
  'Kirklington',
  'Kirknewton',
  'Kirkstall',
  'Kirksville',
  'Kirkville',
  'Kirkwall',
  'Kirkwood',
  'Kirmington',
  'Kirn',
  'Kirov',
  'Kirovograd',
  'Kirovohrad',
  'Kirrawee',
  'Kirribilli',
  'Kirriemuir',
  'Kirschweiler',
  'Kirsehir',
  'Kirtlington',
  'Kirton',
  'Kiruna',
  'Kiryat Anavim',
  'Kiryat Gat',
  'Kiryat Malachi',
  'Kiryat Tivon',
  'Kiryat-ata',
  'Kiryat-yearim',
  'Kiryu',
  'Kisa',
  'Kisarazu',
  'Kisbér',
  'Kiseljak',
  'Kishinëv',
  'Kishiwada',
  'Kisigmánd',
  'Kisii',
  'Kiskunfélegyháza',
  'Kiskunhalas',
  'Kiskunmajsa',
  'Kissenbrück',
  'Kissimmee',
  'Kissing',
  'Kissufim',
  'Kista',
  'Kistarcsa',
  'Kistelek',
  'Kisumu',
  'Kisújszállás',
  'Kita',
  'Kitahiroshima',
  'Kitakami',
  'Kitakata',
  'Kitakyushu',
  'Kitale',
  'Kitami',
  'Kitanagoya',
  'Kitano',
  'Kitchener',
  'Kitee',
  'Kitsuki',
  'Kittanning',
  'Kittelfjäll',
  'Kittery',
  'Kittery Point',
  'Kitty Hawk',
  'Kitwe',
  'Kitzbühel',
  'Kitzingen',
  'Kivik',
  'Kiyiv',
  'Kiyosu',
  'Kißlegg',
  'Kjeller',
  'Kjellerup',
  'Kjoge',
  'Klaaswaal',
  'Kladno',
  'Klaeng',
  'Klagenfurt',
  'Klagshamn',
  'Klagstorp',
  'Klaipeda',
  'Klamath Falls',
  'Klampenborg',
  'Klang',
  'Klanovice',
  'Klarenbeek',
  'Klarup',
  'Klaten',
  'Klatovy',
  'Klaukkala',
  'Klausdorf',
  'Klazienaveen',
  'Klebu',
  'Kleczew',
  'Klein Amsterdam',
  'Klein Nordende',
  'Klein Wittensee',
  'Klein-bieberau',
  'Klein-oirlo',
  'Klein-winternheim',
  'Klein-zundert',
  'Kleinblittersdorf',
  'Kleinfurra',
  'Kleinheubach',
  'Kleinlützel',
  'Kleinmachnow',
  'Kleinostheim',
  'Kleinsendelbach',
  'Kleinwallstadt',
  'Kleinwenkheim',
  'Klepp',
  'Klepp Stasjon',
  'Kleppe',
  'Kleppestø',
  'Klerksdorp',
  'Klettbach',
  'Kleve',
  'Klicany',
  'Klimkovice',
  'Klimmen',
  'Klimovsk',
  'Klingenberg',
  'Klingenberg Am Main',
  'Klingenthal',
  'Klingnau',
  'Klintehamn',
  'Klippan',
  'Klipphausen',
  'Klippinge',
  'Klis',
  'Klixbüll',
  'Klockarstrand',
  'Kloetinge',
  'Klofta',
  'Klong Toey',
  'Kloosterburen',
  'Klosterlechfeld',
  'Klosterneuburg',
  'Klosters',
  'Klosters Dorf',
  'Kloten',
  'Kluang',
  'Kluczbork',
  'Klucze',
  'Klundert',
  'Klüsserath',
  'Klütz',
  'Klæbu',
  'Knapp',
  'Knaresborough',
  'Knarrevik',
  'Knäred',
  'Knebworth',
  'Knet',
  'Knetzgau',
  'Knezeves',
  'Knightdale',
  'Knighton',
  'Knightstown',
  'Knin',
  'Knislinge',
  'Knittelfeld',
  'Knittlingen',
  'Knivsta',
  'Knob Noster',
  'Knockholt',
  'Knockin',
  'Knokke-heist',
  'Knonau',
  'Knottingley',
  'Knowle',
  'Knowlton',
  'Knowsley',
  'Knox',
  'Knoxville',
  'Knurów',
  'Knutsford',
  'Knutwil',
  'Knysna',
  'Ko Samui',
  'Kobayashi',
  'Kobbegem',
  'Kobe',
  'Kobela',
  'Kobelyaky',
  'Kobern-gondorf',
  'Koberovy',
  'Kobierzyce',
  'Kobiór',
  'Koblach',
  'Koblenz',
  'Kobylnica',
  'Kocaeli',
  'Kocasinan',
  'Kocevje',
  'Kocevska Reka',
  'Kochi',
  'Kochino',
  'Koci',
  'Kockelscheuer',
  'Kodai',
  'Kodaira',
  'Kodak',
  'Kodama',
  'Kodambakkam',
  'Kode',
  'Kodiak',
  'Kodoli',
  'Kodrab',
  'Koekelare',
  'Koekelberg',
  'Koenigstein',
  'Koerich',
  'Kofu',
  'Kofu-shi',
  'Koga',
  'Koganei',
  'Kogarah',
  'Kohat',
  'Kohila',
  'Kohima',
  'Kohinata',
  'Kohlberg',
  'Kohler',
  'Kohtla-järve',
  'Kohuwala',
  'Koimbatore',
  'Koje',
  'Kojetin',
  'Kojonup',
  "Kokhav Ya'ir",
  'Kokkedal',
  'Kokkola',
  'Kokomlemle',
  'Kokomo',
  'Kokorzyn',
  'Kokotów',
  'Kokubunji',
  'Kokura',
  'Kol',
  'Kolárovo',
  'Kolbäck',
  'Kolbermoor',
  'Kolbingen',
  'Kolbjørnsvik',
  'Kolbotn',
  'Kolbudy',
  'Kolbuszowa',
  'Kolding',
  'Kolham',
  'Kolhapur',
  'Kolho',
  'Kolin',
  'Kolinany',
  'Kolitzheim',
  'Kolkata',
  'Kolkwitz',
  'Kollam',
  'Kolliken',
  'Kollmar',
  'Kolltveit',
  'Kollum',
  'Kollumerzwaag',
  'Kollupitiya',
  'Kolmården',
  'Koln',
  'Kolno',
  'Koloa',
  'Kolobrzeg',
  'Kolochau',
  'Kolodeje',
  'Kolomna',
  'Kolonija',
  'Kolpino',
  'Kolsås',
  'Kolsva',
  'Kolvereid',
  'Komagane',
  'Komagome',
  'Komaki',
  'Komatsu',
  'Komatsushima',
  'Komárno',
  'Komárom',
  'Komen',
  'Komló',
  'Komono',
  'Komorniki',
  'Komoro',
  'Komorow',
  'Komorów',
  'Komotiní',
  'Komsomol',
  'Komsomolsk',
  'Komstad',
  'Konak',
  'Konakovo',
  'Konan',
  'Konarzyny',
  'Kondapur',
  'Kondopoga',
  'Kondotti',
  'Konecchlumi',
  'Kongens Lyngby',
  'Kongsberg',
  'Kongsvik',
  'Kongsvinger',
  'Konice',
  'Konigsberg',
  'Konik Nowy',
  'Konin',
  'Koningsbosch',
  'Koniz',
  'Konjic',
  'Konken',
  'Konnevesi',
  'Konolfingen',
  'Konopnica',
  'Konosu',
  'Konotop',
  'Konradsreuth',
  'Konskie',
  'Konstancin-jeziorna',
  'Konstantynow Lodzki',
  'Konstantynów',
  'Konstanz',
  'Kontich',
  'Kontiolahti',
  'Konya',
  'Konz',
  'Koog Aan De Zaan',
  'Kootenai',
  'Kootwijkerbroek',
  'Kooyong',
  'Koper',
  'Kopervik',
  'Koppal',
  'Kopparberg',
  'Koppigen',
  'Koprivnica',
  'Koprivnice',
  'Korattur',
  'Korb',
  'Korba',
  'Korbach',
  'Korbeek-lo',
  'Korbussen',
  'Korcula',
  'Koriyama',
  'Korla',
  'Kormangala',
  'Kornatka',
  'Korneuburg',
  'Korntal-münchingen',
  'Kornwestheim',
  'Korolev',
  'Korolëv',
  'Koropí',
  'Korostyshiv',
  'Korschenbroich',
  'Korssund',
  'Korsør',
  'Kortemark',
  'Kortenberg',
  'Kortenhoef',
  'Kortgene',
  'Kortrijk',
  'Korumburra',
  'Korytna',
  'Kos',
  'Kosai',
  'Kosakowo',
  'Koscian',
  'Koscierzyna',
  'Kosciusko',
  'Kose',
  'Koseca',
  'Kosel',
  'Koserow',
  'Koshigaya',
  'Kosice',
  'Kosire',
  'Koskullskulle',
  'Kosmonosy',
  'Kosov',
  'Kosovë',
  'Kosovo',
  'Kosse',
  'Kostelec Na Hane',
  'Kostelec Nad Cernymi Lesy',
  'Kostheim',
  'Kostolany Nad Hornadom',
  'Kostolne Kracany',
  'Kostomloty',
  'Kostroma',
  'Kostrzyn',
  'Kostrzyn Nad Odra',
  'Koszalin',
  'Koszalinskie',
  'Kot Kapura',
  'Kota',
  'Kota Bharu',
  'Kota Kinabalu',
  'Kotagiri',
  'Kotara',
  'Kotdwara',
  'Koti',
  'Kotka',
  'Kotlje',
  'Koto',
  'Kotor',
  'Kotovskiy',
  'Kotowice',
  'Kottakkal',
  'Kottawa',
  'Kottayam',
  'Kotte',
  'Kottenheim',
  'Kottgeisering',
  'Kottingbrunn',
  'Kottmarsdorf',
  'Kotzschbar',
  'Kouba',
  'Koudekerk',
  'Koudekerk Aan Den Rijn',
  'Koudum',
  'Kountze',
  'Koutsélion',
  'Kouvola',
  'Kovagoszolos',
  'Kovilpatti',
  'Kovrov',
  'Kowloon City',
  'Koyama',
  'Koyang',
  'Koyasu',
  'Kozáni',
  'Kozhikode',
  'Kozienice',
  'Kozova',
  'Kópavogur',
  'Kórinthos',
  'Kölleda',
  'Kölliken',
  'Kölln-reisiek',
  'Köln',
  'Köngen',
  'Königs Wusterhausen',
  'Königsbach-stein',
  'Königsbrunn',
  'Königsbrück',
  'Königsdorf',
  'Königsee',
  'Königseggwald',
  'Königsfeld',
  'Königstein',
  'Königstein Im Taunus',
  'Königswinter',
  'Köniz',
  'Köpenhamn',
  'Köping',
  'Körfez',
  'Körle',
  'Körmend',
  'Kösching',
  'Kössen',
  'Köthen',
  'Krabbendijke',
  'Krabi',
  'Kradolf',
  'Kragujevac',
  'Kraiburg',
  'Krailling',
  'Krakow',
  'Kraków',
  'Kralice Na Hane',
  'Kraljevo',
  'Kralova Nad Vahom',
  'Kralupy Nad Vltavou',
  'Kraluv Dvur',
  'Kram',
  'Kramatorsk',
  'Kramerhof',
  'Kramfors',
  'Kranenburg',
  'Kranichfeld',
  'Kranj',
  'Kranzberg',
  'Krapina',
  'Krapinske Toplice',
  'Krasiejów',
  'Krasnany',
  'Krasnik',
  'Krasnodar',
  'Krasnodarskiy',
  'Krasnogorsk',
  'Krasnokutsk',
  'Krasnolesye',
  'Krasnoyarsk',
  'Krasnoznamensk',
  'Krathumban',
  'Kratovo',
  'Krauchenwies',
  'Krauchtal',
  'Kravare',
  'Krásna',
  'Kråkerøy',
  'Kredenbach',
  'Krefeld',
  'Kreischa',
  'Kremenchuk',
  'Kremlin-bicêtre',
  'Kremmen',
  'Kremmling',
  'Kremnica',
  'Krems',
  'Krems An Der Donau',
  'Kremsmunster',
  'Krenovice',
  'Kresgeville',
  'Kreslice',
  'Kresnice',
  'Kressbronn',
  'Kretinga',
  'Kretingos',
  'Kreuth',
  'Kreuzau',
  'Kreuzberg',
  'Kreuzlingen',
  'Kreuznach',
  'Kreuztal',
  'Kreuzwertheim',
  'Kriegstetten',
  'Kriens',
  'Kriftel',
  'Krimpen',
  'Krimpen Aan Den Ijssel',
  'Krionéri',
  'Krishnagiri',
  'Krishnapur',
  'Kristiansand',
  'Kristianstad',
  'Kristiansund',
  'Kristinehamn',
  'Kritzow',
  'Krivan',
  'Krivoy Rog',
  'Krize',
  'Krizevci',
  'Krk',
  'Krka',
  'Krmelin',
  'Krnica',
  'Krnov',
  'Krokialaukis',
  'Krokowa',
  'Krokstadelva',
  'Krombach',
  'Kromeriz',
  'Krommenie',
  'Krompachy',
  'Kronach',
  'Kronau',
  'Kronberg',
  'Kronbuhl Bei Sankt Gallen',
  'Kronburg',
  'Kronoby',
  'Kronshagen',
  'Kronstorf',
  'Krosno',
  'Krotoszyn',
  'Krotz Springs',
  'Kröpelin',
  'Krsko',
  'Krugersdorp',
  'Kruibeke',
  'Krum',
  'Krumbach',
  'Krumpendorf',
  'Kruså',
  'Krusevac',
  'Krusevo',
  'Kruszwica',
  'Krynica',
  'Krynica Zdroj',
  'Kryvyy Rih',
  'Krzemieniewo',
  'Krøderen',
  'Ktis',
  'Kuala',
  'Kuala Kedah',
  'Kuala Ketil',
  'Kuala Lumpor',
  'Kuala Lumpur',
  'Kuala Pilah',
  'Kuala Selangor',
  'Kuala Terengganu',
  'Kuantan',
  'Kubschütz',
  'Kuching',
  'Kudal',
  'Kudelstaart',
  'Kudus',
  'Kueishan',
  'Kufstein',
  'Kugayama',
  'Kugenuma',
  'Kuhardt',
  'Kuhi',
  'Kuhmo',
  'Kujo',
  'Kukatpally',
  'Kuki',
  'Kuklen',
  'Kukuljanovo',
  'Kula',
  'Kulai',
  'Kulebaki',
  'Kulim',
  'Kuliyapitiya',
  'Kullavik',
  'Kulm',
  'Kulmbach',
  'Kulpsville',
  'Kumagaya',
  'Kumakura',
  'Kumamoto',
  'Kumamoto-shi',
  'Kumano',
  'Kumar',
  'Kumasi',
  'Kumbakonam',
  'Kumeu',
  'Kumhausen',
  'Kumi',
  'Kumköy',
  'Kumla',
  'Kummerfeld',
  'Kumta',
  'Kuna',
  'Kuncice',
  'Kuncicky',
  'Kunfehértó',
  'Kungälv',
  'Kungsängen',
  'Kungsäter',
  'Kungsbacka',
  'Kungsör',
  'Kunice',
  'Kuningan',
  'Kuningan Timur',
  'Kunitachi',
  'Kunkletown',
  'Kunming',
  'Kunnamangalam',
  'Kunovice',
  'Kunshan',
  'Kunstat',
  'Kunszentmiklós',
  'Kunten',
  'Kuopio',
  'Kuortti',
  'Kupferberg',
  'Kupferzell',
  'Kuppenheim',
  'Kuramae',
  'Kuranda',
  'Kurashiki',
  'Kurchatov',
  'Kurdistan',
  'Kure',
  'Kure Beach',
  'Kuressaare',
  'Kurgan',
  'Kurganskaya',
  'Kuri',
  'Kurikka',
  'Kurim',
  'Kuringen',
  'Kurinskaya',
  'Kurla',
  'Kurnell',
  'Kurnool',
  'Kuro',
  'Kurobe',
  'Kursenai',
  'Kursk',
  'Kurtistown',
  'Kurtulus',
  'Kurucesme',
  'Kurume',
  'Kurunegala',
  'Kuruwita',
  'Kusadasi',
  'Kusatsu',
  'Kusel',
  'Kushiro',
  'Kusimoto',
  'Kusnacht',
  'Kussnacht Am Rigi',
  'Kusterdingen',
  'Kuta',
  'Kutna Hora',
  'Kutno',
  'Kutztown',
  'Kuurne',
  'Kuusalu',
  'Kuusamo',
  'Kuusankoski',
  'Kuwait',
  'Kuwana',
  'Kuybysheve',
  'Kuznetsk',
  'Kuznia Raciborska',
  'Kuznica Kiedrzynska',
  'Kútniky',
  'Küblis',
  'Küçükçekmece',
  'Kühlungsborn',
  'Kühsen',
  'Küllstedt',
  'Kümmersbruck',
  'Künten',
  'Künzell',
  'Künzelsau',
  'Küps',
  'Kürten',
  'Küsnacht',
  'Küssnacht',
  'Kütahya',
  'Küttigen',
  'Kvänum',
  'Kvål',
  'Kvelde',
  'Kverva',
  'Kvicksund',
  'Kvidinge',
  'Kvissleby',
  'Kvistgaard',
  'Kvistgård',
  'Kwaadmechelen',
  'Kwachon',
  'Kwai Tsing',
  'Kwakel',
  'Kwangju',
  'Kwangmyong',
  'Kwangyang',
  'Kwara',
  'Kwidzyn',
  'Kwinana',
  'Kwintsheul',
  'Kwun Tong',
  'Kyburg',
  'Kyiv',
  'Kyje',
  'Kyjov',
  'Kyle',
  'Kyle Of Lochalsh',
  'Kyles Ford',
  'Kyndeløse',
  'Kyneton',
  'Kyobashi',
  'Kyoto',
  'Kyoto-shi',
  'Kyrenia',
  'Kyrkby',
  'Kyrö',
  'Kyshtym',
  'Kysucké Nové Mesto',
  'Kyyiv',
  'Kyyjärvi',
  'Københoved',
  'Køge',
  "L'abbaye",
  "L'aigle",
  "L'ancienne Lorette",
  "L'ancienne-lorette",
  "L'aquila",
  "L'arbresle",
  "L'ardoise",
  "L'assomption",
  "L'eliana",
  "L'etang",
  "L'etoile",
  "L'etrat",
  "L'hay-les-roses",
  "L'herbergement",
  "L'hermitage",
  "L'horme",
  "L'hospitalet De Llobregat",
  "L'houmeau",
  "L'huisserie",
  "L'ile-d'elle",
  "L'ile-d'olonne",
  "L'ile-perrot",
  "L'ile-saint-denis",
  "L'isle",
  "L'isle-adam",
  "L'isle-d'espagnac",
  "L'isle-en-dodon",
  "L'isle-jourdain",
  "L'isle-sur-la-sorgue",
  "L'isle-sur-le-doubs",
  "L'islet",
  "L'isletville",
  "L'union",
  'La',
  'La Albuera',
  'La Almunia De Doña Godina',
  'La Altagracia',
  'La Ametlla',
  'La Baie',
  'La Balme-de-sillingy',
  'La Bañeza',
  'La Bauche',
  'La Baule-escoublac',
  'La Bernardière',
  'La Bernerie-en-retz',
  'La Bocca',
  'La Boissière',
  'La Bouilladisse',
  'La Boulangerie',
  'La Bouverie',
  'La Bresse',
  'La Brossette',
  'La Bruyère',
  'La Bussière',
  'La Canada Flintridge',
  'La Canonja',
  'La Capelle-bleys',
  'La Carlota',
  'La Carolina',
  'La Cavalerie',
  'La Celle-saint-cloud',
  'La Center',
  'La Chaize',
  'La Chambre',
  'La Chapelle',
  "La Chapelle D'armentieres",
  'La Chapelle-basse-mer',
  'La Chapelle-des-fougeretz',
  'La Chapelle-en-serval',
  'La Chapelle-faucher',
  'La Chapelle-launay',
  'La Chapelle-saint-luc',
  'La Chapelle-saint-mesmin',
  'La Chapelle-saint-sauveur',
  'La Chapelle-sur-crécy',
  'La Chapelle-sur-erdre',
  'La Chaussée',
  'La Chaussée-saint-victor',
  'La Chaux-de-fonds',
  'La Chevrolière',
  'La Chièsaz',
  'La Ciotat',
  'La Clayette',
  'La Colle-sur-loup',
  'La Concepción',
  'La Concordia',
  'La Condesa',
  'La Conner',
  'La Coruña',
  'La Courneuve',
  'La Crau',
  'La Crescent',
  'La Crescenta',
  'La Crosse',
  'La Cure',
  'La Défense',
  'La Eliana',
  'La Escala',
  'La Escondida',
  'La Estancia',
  'La Estanzuela',
  'La Fare-les-oliviers',
  'La Farge',
  'La Fargeville',
  'La Farlède',
  'La Fayette',
  'La Felguera',
  'La Ferté-alais',
  'La Ferté-bernard',
  'La Ferté-macé',
  'La Ferté-saint-aubin',
  'La Flèche',
  'La Floresta',
  'La Florida',
  'La Forclaz',
  'La Forêt',
  'La Forêt-fouesnant',
  'La Fouillouse',
  'La France',
  'La Francheville',
  'La Frette',
  'La Frette-sur-seine',
  'La Gacilly',
  'La Garde',
  'La Garde-adhémar',
  'La Garde-freinet',
  'La Garenne',
  'La Garenne-colombes',
  'La Garnache',
  'La Garonne',
  'La Garriga',
  'La Gerbaudière',
  'La Grand Combe',
  'La Grand Croix',
  'La Grande',
  'La Grande Motte',
  'La Grange',
  'La Grange Park',
  'La Granja',
  'La Gravelle',
  'La Guadeloupe',
  'La Guerche-de-bretagne',
  'La Habana',
  'La Habra',
  'La Haie-fouassière',
  'La Honda',
  'La Houssaye-en-brie',
  'La Hulpe',
  'La Jolla',
  'La Jumelière',
  'La Jumellière',
  'La Junta',
  'La Laguna',
  'La Lande',
  'La Lantejuela',
  'La Lapa',
  'La Limouzinière',
  'La Línea De La Concepción',
  'La Llagosta',
  'La Loggia',
  'La Loma De Los Gonzáles',
  'La Lonja',
  'La Loubière',
  'La Loupe',
  'La Louvière',
  'La Loyère',
  'La Lucila',
  'La Madelaine',
  'La Madeleine',
  'La Marque',
  'La Marsa',
  'La Martinière',
  'La Massana',
  'La Matanza',
  'La Menitré',
  'La Merlatière',
  'La Mesa',
  'La Métairie',
  'La Mézière',
  'La Milesse',
  'La Milliére',
  'La Mirada',
  'La Misere',
  'La Molina',
  'La Montagne',
  'La Mothe-achard',
  'La Motte',
  'La Motte-servolex',
  'La Muela',
  'La Murette',
  'La Napoule',
  'La Neuveville',
  'La Neuvillette',
  'La Nucía',
  'La Oliva',
  'La Orotava',
  'La Palma',
  'La Palma Del Condado',
  'La Pampa',
  'La Parra',
  'La Paz',
  'La Penne',
  'La Piedad Cavadas',
  'La Pine',
  'La Place',
  'La Plaine',
  'La Plaine-saint-denis',
  'La Plata',
  'La Plume',
  'La Pobla De Mafumet',
  'La Pobleta',
  'La Pocatière',
  'La Porte',
  'La Portellada',
  'La Poveda',
  'La Prairie',
  'La Praz',
  'La Puebla De Montalbán',
  'La Puebla De Roda',
  'La Puente',
  'La Queue-en-brie',
  'La Quinta',
  'La Rabotière',
  'La Ravoire',
  'La Regrippière',
  'La Reina',
  'La Réole',
  'La Réserve',
  'La Ricamarie',
  'La Richardais',
  'La Riche',
  'La Rinconada',
  'La Rioja',
  'La Rivière-de-corps',
  'La Roca',
  'La Roche-posay',
  'La Roche-sur-foron',
  'La Roche-sur-yon',
  'La Rochelle',
  'La Rochette',
  'La Roda',
  'La Rogère',
  'La Romana',
  'La Sagne',
  'La Salle',
  'La Salvetat-saint-gilles',
  'La Sarraz',
  'La Secuita',
  'La Selva Del Campo',
  'La Serena',
  'La Seyne-sur-mer',
  'La Séguinière',
  'La Solana',
  'La Soukra',
  'La Souterraine',
  'La Spezia',
  'La Talaudière',
  'La Teste-de-buch',
  'La Tigra',
  'La Tour-de-peilz',
  'La Tour-de-salvagny',
  'La Tranche-sur-mer',
  'La Trinité',
  'La Trinité-de-thouberville',
  'La Trinité-sur-mer',
  'La Tronche',
  'La Tuilière',
  'La Turballe',
  'La Union',
  'La Unión',
  'La Valette-du-var',
  'La Vall',
  'La Vergne',
  'La Verkin',
  'La Verne',
  'La Vernia',
  'La Verpillière',
  'La Verrie',
  'La Verrière',
  'La Vespière',
  'La Victoria',
  'La Victoria De Acentejo',
  'La Ville-aux-dames',
  'La Vista',
  'La Voulte-sur-rhône',
  'La Wantzenau',
  'La Ward',
  'Laaber',
  'Laag-keppel',
  'Laag-soeren',
  'Laagri',
  'Laane',
  'Laatzen',
  'Laax',
  'Labadie',
  'Labarthe-sur-lèze',
  'Labastide-saint-georges',
  'Labastide-saint-pierre',
  'Labbeck',
  'Labelle',
  'Labenne',
  'Labège',
  'Laboissière-en-thelle',
  'Labouheyre',
  'Labrador',
  'Labrède',
  'Labruguière',
  'Labuan',
  'Lac Du Flambeau',
  'Lac La Biche',
  'Lac-mégantic',
  'Lacadée',
  'Lacapelle-marival',
  'Lacarry-arhan-charritte-de-haut',
  'Lacaune',
  'Lacchiarella',
  'Lacey',
  'Laceyville',
  'Lachapelle-sous-rougemont',
  'Lachen',
  'Lachendorf',
  'Lachine',
  'Lachute',
  'Lacombe',
  'Laconia',
  'Lacq',
  'Lacroix-saint-ouen',
  'Ladang West Country',
  'Ladbergen',
  'Ladbroke',
  'Ladegaard',
  'Ladelund',
  'Ladenburg',
  'Ladera Ranch',
  'Ladice',
  'Ladmovce',
  'Ladon',
  'Ladson',
  'Ladwa',
  'Lady Lake',
  'Ladys Island',
  'Ladysmith',
  'Lae',
  'Laeken',
  'Laer',
  'Lafayette',
  'Lafayette Hill',
  'Lafox',
  'Lafrançaise',
  'Lagan',
  'Lagares',
  'Lage',
  'Lage Zwaluwe',
  'Lagedi',
  'Lages',
  'Lageweg',
  'Laging Handa',
  'Lagny',
  'Lago',
  'Lagoa',
  'Lagoa Nova',
  'Lagoa Santa',
  'Lagonegro',
  'Lagord',
  'Lagos',
  'Lagos De Moreno',
  'Lagrange',
  'Lagrangeville',
  'Laguardia',
  'Laguna',
  'Laguna Beach',
  'Laguna De Duero',
  'Laguna Hills',
  'Laguna Niguel',
  'Laguna Woods',
  'Lagundo',
  'Lagunitas',
  'Lahaina',
  'Lahaska',
  'Lahavot Haviva',
  'Lahijan',
  'Lahinch',
  'Lahn',
  'Lahnstein',
  'Laholm',
  'Lahor',
  'Lahore',
  'Lahore Cantonment',
  'Lahore Cantonment Co-operative Housing Society',
  'Lahr',
  'Lahti',
  'Lahug',
  'Laian',
  'Laichingen',
  'Laie',
  'Laigné-en-belin',
  'Laillé',
  'Lailly-en-val',
  'Lainate',
  'Laindon',
  'Laitila',
  'Laives',
  'Laiwu',
  'Laiyang',
  'Laizhou',
  'Lajeado',
  'Lajoux',
  'Lak Si',
  'Lakatamia',
  'Lake',
  'Lake Alfred',
  'Lake Ariel',
  'Lake Arrowhead',
  'Lake Arthur',
  'Lake Bluff',
  'Lake Buena Vista',
  'Lake Butler',
  'Lake Cargelligo',
  'Lake Charles',
  'Lake City',
  'Lake Como',
  'Lake Cowichan',
  'Lake Crystal',
  'Lake Dallas',
  'Lake Delton',
  'Lake Elmo',
  'Lake Elsinore',
  'Lake Forest',
  'Lake Forest Park',
  'Lake Geneva',
  'Lake George',
  'Lake Grove',
  'Lake Havasu City',
  'Lake Hiawatha',
  'Lake Hopatcong',
  'Lake In The Hills',
  'Lake Jackson',
  'Lake Katrine',
  'Lake Linden',
  'Lake Luzerne',
  'Lake Macquarie Shire',
  'Lake Mary',
  'Lake Mills',
  'Lake Milton',
  'Lake Monroe',
  'Lake Munmorah',
  'Lake Nebagamon',
  'Lake Odessa',
  'Lake Orion',
  'Lake Oswego',
  'Lake Ozark',
  'Lake Panasoffkee',
  'Lake Park',
  'Lake Peekskill',
  'Lake Placid',
  'Lake Powell',
  'Lake Providence',
  'Lake Saint Louis',
  'Lake Saint Peter',
  'Lake Spring',
  'Lake Stevens',
  'Lake Villa',
  'Lake Wales',
  'Lake Worth',
  'Lake Zurich',
  'Lakebay',
  'Lakefield',
  'Lakeland',
  'Laken',
  'Lakeport',
  'Lakes Creek',
  'Lakeside',
  'Lakeside Marblehead',
  'Lakeview',
  'Lakeville',
  'Lakewood',
  'Lakhish',
  'Laksevåg',
  'Lakshmipur',
  'Laleham',
  'Lalitpur',
  'Lalín',
  'Lalkua',
  'Lallio',
  'Lalor',
  'Lam Luk Ka',
  'Lamanon',
  'Lamar',
  'Lamballe',
  'Lamberhurst',
  'Lambersart',
  'Lamberton',
  'Lambertville',
  'Lambesc',
  'Lambeth',
  'Lambourn',
  'Lambrecht',
  'Lambres-lez-douai',
  'Lambton',
  'Lame Deer',
  'Lamego',
  'Lamerdingen',
  'Lamezia',
  'Lamía',
  'Lammhult',
  'Lamongan',
  'Lamoni',
  'Lamorlaye',
  'Lamoure',
  'Lampa',
  'Lampasas',
  'Lampertheim',
  'Lampeter',
  'Lamporecchio',
  'Lampung',
  'Lamure-sur-azergues',
  'Lamy',
  'Lanaken',
  'Lanark',
  'Lancaster',
  'Lancaster Park',
  'Lance Aux Épines',
  'Lancefield',
  'Lancenigo',
  'Lancey',
  'Lanchester',
  'Lanciano',
  'Lancing',
  'Lancut',
  'Lançon-provence',
  'Land O Lakes',
  'Landau',
  'Landau An Der Isar',
  'Landau In Der Pfalz',
  'Landebaëron',
  'Landeck',
  'Landegem',
  'Landegg',
  'Landelies',
  'Landen',
  'Landenberg',
  'Lander',
  'Landerneau',
  'Landford',
  'Landing',
  'Landingville',
  'Landisville',
  'Landivisiau',
  'Landön',
  'Landquart',
  'Landrethun',
  'Landriano',
  'Landrum',
  'Landsberg',
  'Landsberg Am Lech',
  'Landsberied',
  'Landsborough',
  'Landscheid',
  'Landsham',
  'Landshut',
  'Landskrona',
  'Landsmeer',
  'Landvetter',
  'Lane',
  'Lane City',
  'Lane Cove',
  'Laneffe',
  'Lanesborough',
  'Lanester',
  'Lanett',
  'Laneuvelotte',
  'Laneuveville-devant-nancy',
  'Lanexa',
  'Lang Suan',
  'Langå',
  'Langbroek',
  'Langdon',
  'Langeais',
  'Langedijk',
  'Langelsheim',
  'Langemark',
  'Langen',
  'Langenargen',
  'Langenau',
  'Langenbach',
  'Langenberg',
  'Langenbruck',
  'Langenburg',
  'Langeneichstädt',
  'Langenfeld',
  'Langenfelde',
  'Langengern',
  'Langenhagen',
  'Langenhahn',
  'Langenholzhausen',
  'Langenhorn',
  'Langenlebarn',
  'Langenlonsheim',
  'Langenpreising',
  'Langenselbold',
  'Langensteinbach',
  'Langenthal',
  'Langenwetzendorf',
  'Langenzenn',
  'Langeoog',
  'Langerwehe',
  'Langeskov',
  'Langesund',
  'Langevåg',
  'Langewiesen',
  'Langfang',
  'Langford',
  'Langham',
  'Langhirano',
  'Langholm',
  'Langhorne',
  'Langhorne Creek',
  'Langhus',
  'Langkawi',
  'Langlade',
  'Langley',
  'Langley Marish',
  'Langley Mill',
  'Langley-on-tyne',
  'Langmeil',
  'Langnau',
  'Langogne',
  'Langoiran',
  'Langon',
  'Langouet',
  'Langport',
  'Langreo',
  'Langston',
  'Langstone',
  'Langueux',
  'Langwarrin',
  'Langwedel',
  'Langweid',
  'Langxia',
  'Lanham',
  'Lanhélin',
  'Lanjarón',
  'Lankwitz',
  'Lannach',
  'Lannilis',
  'Lannion',
  'Lannon',
  'Lanoka Harbor',
  'Lanoraie',
  'Lansargues',
  'Lansdale',
  'Lansdowne',
  'Lanshan',
  'Lansing',
  'Lanskroun',
  'Lanta',
  'Lantana',
  'Lantau',
  'Lanthenay',
  'Lanton',
  'Lanús',
  'Lanxi',
  'Lanzhou',
  'Laoag City',
  'Laon',
  'Laotto',
  'Lapa',
  'Lapeer',
  'Lapel',
  'Lapeyrouse-fossat',
  'Laporte',
  'Lappeenranta',
  'Lappersdorf',
  'Lappi',
  'Laprairie',
  'Lapu-lapu',
  'Lapu-lapu City',
  'Lapua',
  'Lapworth',
  'Lara',
  'Laracha',
  'Laragh',
  'Laragne',
  'Laramie',
  'Larbert',
  'Larché',
  'Larchmont',
  'Larchwood',
  'Larçay',
  'Lardero',
  'Lardner',
  'Lardy',
  'Laredo',
  'Laren',
  'Largeasse',
  'Largentière',
  'Largo',
  'Largs',
  'Larkollen',
  'Larkspur',
  'Larmor-plage',
  'Larnaca',
  'Larnaka',
  'Larne',
  'Larned',
  'Larochette',
  'Laroque',
  'Larose',
  'Larrabezúa',
  'Larreule',
  'Laruscade',
  'Larvik',
  'Las Arenas',
  'Las Águilas',
  'Las Cabezas De San Juan',
  'Las Colinas',
  'Las Condes',
  'Las Cruces',
  'Las Herencias',
  'Las Lomas',
  'Las Matas',
  'Las Navas Del Marqués',
  'Las Palmas',
  'Las Palmas De Gran Canaria',
  'Las Penas',
  'Las Piñas',
  'Las Quintanillas',
  'Las Rosas',
  'Las Rozas',
  'Las Rozas De Madrid',
  'Las Termas',
  'Las Torres De Cotillas',
  'Las Vegas',
  'Las Vertientes',
  'Lasa',
  'Lasalle',
  'Lasarte',
  'Lasinja',
  'Lask',
  'Lasko',
  'Lasne-chapelle-saint-lambert',
  'Lassnitzhohe',
  'Lasswade',
  'Lastra A Signa',
  'Lat Krabang',
  'Lat Lum Kaeo',
  'Lat Phrao',
  'Latakia',
  'Latchingdon',
  'Latexo',
  'Latham',
  'Latheron',
  'Lathrop',
  'Lathuile',
  'Latimer',
  'Latina',
  'Latisana',
  'Latresne',
  'Latrobe',
  'Latronquière',
  'Latsia',
  'Lattes',
  'Laubach',
  'Lauben',
  'Lauchhammer',
  'Lauchheim',
  'Lauchringen',
  'Lauda-königshofen',
  'Laude',
  'Laudenbach',
  'Lauderdale',
  'Lauderdale By The Sea',
  'Lauderhill',
  'Lauenburg',
  'Lauerz',
  'Lauf',
  'Lauf An Der Pegnitz',
  'Laufach',
  'Laufelfingen',
  'Laufen',
  'Laufenburg',
  'Lauffen',
  'Lauffen Am Neckar',
  'Laughlin',
  'Laughton',
  'Lauingen',
  'Laulasmaa',
  'Launaguet',
  'Launceston',
  'Laundos',
  'Laupahoehoe',
  'Laupen',
  'Laupersdorf',
  'Lauperswil',
  'Laupheim',
  'Laure-minervois',
  'Laurel',
  'Laurel Hill',
  'Laurence Harbor',
  'Laurencekirk',
  'Laurens',
  'Laurent',
  'Laurie',
  'Laurinburg',
  'Lauro',
  'Lausanne',
  'Lausen',
  'Lauta',
  'Lauterach',
  'Lauterbach',
  'Lauterhofen',
  'Lautertal',
  'Lautoka',
  'Lauwe',
  'Laußnitz',
  'Lavaca',
  'Lavagna',
  'Laval',
  'Laval-ouest',
  'Lavalette',
  'Lavalle',
  'Lavallette',
  'Lavaltrie',
  'Lavant',
  'Lavardac',
  'Lavaud',
  'Lavaur',
  'Laveen',
  'Laveline-devant-bruyères',
  'Lavenham',
  'Laventille',
  'Lavernose',
  'Lavertezzo',
  'Laverton',
  'Lavezzola',
  'Lavi',
  'Lavina',
  'Lavington',
  'Lavis',
  'Lavon',
  'Lavonia',
  'Lavos',
  'Lavras',
  'Lawang',
  'Lawndale',
  'Lawnton',
  'Lawrence',
  'Lawrenceburg',
  'Lawrencetown',
  'Lawrenceville',
  'Lawson',
  'Lawton',
  'Laxå',
  'Laxenburg',
  'Laxfield',
  'Laxou',
  'Lay-saint-christophe',
  'Layrac',
  'Layton',
  'Laytonville',
  'Laytown',
  'Lazaro Cárdenas',
  'Lazcano',
  'Laziska Gorne',
  'Lazne Belohrad',
  'Lazne Bohdanec',
  'Lazne Kynzvart',
  'Lárisa',
  'Lárissa',
  'Lávrion',
  'Länna',
  'Långeruda',
  'Långshyttan',
  'Låsby',
  'Le Bardo',
  'Le Barp',
  'Le Bâtiment',
  'Le Beaucet',
  'Le Bignon',
  'Le Blanc-mesnil',
  'Le Bosc',
  'Le Boupère',
  "Le Bourg-d'ire",
  'Le Bourget',
  'Le Bourget-du-lac',
  'Le Bouscat',
  'Le Bouveret',
  'Le Brassus',
  'Le Broc',
  'Le Brusc',
  'Le Cannet',
  'Le Cannet-des-maures',
  "Le Cap D'agde",
  'Le Castellet',
  'Le Cateau',
  'Le Cellier',
  'Le Cendre',
  'Le Center',
  'Le Chambon-feugerolles',
  'Le Châtel',
  'Le Châtelard',
  'Le Chenit',
  'Le Chesnay',
  'Le Chesne',
  'Le Cheylard',
  'Le Cheylas',
  'Le Coteau',
  'Le Coudray',
  'Le Coudray-montceaux',
  'Le Creusot',
  'Le Crès',
  'Le Crocq',
  'Le Dorat',
  'Le Faou',
  'Le Fieu',
  'Le Genest',
  'Le Gond-pontouvre',
  'Le Grand',
  'Le Grand-quevilly',
  'Le Grand-saconnex',
  'Le Grau-du-roi',
  'Le Haillan',
  'Le Havre',
  'Le Hochet',
  'Le Kremlin-bicêtre',
  'Le Landeron',
  'Le Lavandou',
  'Le Lez',
  'Le Lignon',
  "Le Lion-d'angers",
  'Le Locle',
  'Le Loroux-bottereau',
  'Le Mans',
  'Le Mars',
  'Le Mesnil-le-roi',
  'Le Mesnil-simon',
  'Le Mée-sur-seine',
  'Le Molay-littry',
  'Le Mont-sur-lausanne',
  'Le Montat',
  'Le Montel',
  'Le Moyne',
  'Le Muy',
  'Le Noirmont',
  'Le Palais-sur-vienne',
  'Le Pallet',
  'Le Parcq',
  'Le Passage',
  'Le Pecq',
  'Le Perray',
  'Le Perray-en-yvelines',
  'Le Perreux',
  'Le Perreux-sur-marne',
  'Le Petit Paris',
  'Le Petit-quevilly',
  'Le Pêchereau',
  'Le Plessis-pâté',
  'Le Plessis-robinson',
  'Le Plessis-trévise',
  'Le Poiré-sur-vie',
  'Le Pont-de-beauvoisin',
  'Le Pont-de-claix',
  'Le Pontet',
  'Le Port',
  'Le Port-marly',
  'Le Pouliguen',
  'Le Pouzin',
  'Le Pradet',
  'Le Pré-saint-gervais',
  'Le Puech',
  'Le Puget-sur-argens',
  'Le Puy',
  'Le Puy-en Velay',
  'Le Quartier',
  'Le Raincy',
  'Le Relecq-kerhuon',
  'Le Rheu',
  'Le Rouret',
  'Le Rove',
  'Le Roy',
  'Le Sart',
  'Le Sel',
  'Le Sentier',
  'Le Soler',
  'Le Sueur',
  'Le Tallud',
  'Le Tampon',
  'Le Thillay',
  'Le Tholonet',
  'Le Thor',
  'Le Thour',
  'Le Tilleul',
  'Le Touquet',
  'Le Touquet-paris-plage',
  'Le Tréport',
  'Le Val',
  'Le Vaud',
  'Le Vaudreuil',
  'Le Verdier',
  'Le Vésinet',
  'Le Vigan',
  'Lead',
  'Leadville',
  'Leaf River',
  'Leagrave',
  'League City',
  'Leamington',
  'Leamington Spa',
  'Leander',
  'Leatherhead',
  'Leavenworth',
  'Leavesden Green',
  'Leawood',
  'Lebach',
  'Lebakbulus',
  'Lebanon',
  'Lebanon Junction',
  'Lebbeke',
  'Lebec',
  'Lebedyn',
  'Leblon',
  'Lebrija',
  'Lebring',
  'Lecanto',
  'Lecce',
  'Lecce Nei Marsi',
  'Lecco',
  'Lechbruck',
  'Lechlade',
  'Leconfield',
  'Leça Da Palmeira',
  'Leça Do Balio',
  'Ledbury',
  'Lede',
  'Ledeacker',
  'Ledeberg',
  'Ledegem',
  'Lederhose',
  'Ledgewood',
  'Lednické Rovne',
  'Leduc',
  'Ledyard',
  'Lee',
  'Lee On Solent',
  'Lee-on-the-solent',
  'Leechburg',
  'Leeden',
  'Leeder',
  'Leeds',
  'Leeds Point',
  'Leek',
  'Leeman',
  'Leende',
  'Leer',
  'Leerdam',
  'Leers',
  'Leersum',
  'Lees',
  'Lees Summit',
  'Leesburg',
  'Leesport',
  'Leesten',
  'Leesville',
  'Leeton',
  'Leetsdale',
  'Leeuwarden',
  'Lefka',
  'Lefkáda',
  'Lefkosa',
  'Lefkosia',
  'Legal',
  'Legana',
  'Leganés',
  'Legaspi',
  'Legau',
  'Legazpi',
  'Legazpi City',
  'Legazpia',
  'Legden',
  'Legé',
  'Legionowo',
  'Legnago',
  'Legnano',
  'Legnaro',
  'Legnica',
  'Legon',
  'Leh',
  'Lehavim',
  'Lehi',
  'Lehigh Acres',
  'Lehigh Valley',
  'Lehighton',
  'Lehman',
  'Lehmen',
  'Lehmo',
  'Lehnin',
  'Lehningen',
  'Lehota',
  'Lehrberg',
  'Lehre',
  'Lehrensteinsfeld',
  'Lehrte',
  'Leibnitz',
  'Leicester',
  'Leichhardt',
  'Leichlingen',
  'Leiden',
  'Leiderdorp',
  'Leidersbach',
  'Leidschendam',
  'Leie',
  'Leigh',
  'Leigh-on-sea',
  'Leighton Buzzard',
  'Leihgestern',
  'Leikanger',
  'Leimbach',
  'Leimen',
  'Leimuiden',
  'Leinburg',
  'Leinefelde',
  'Leinfelden-echterdingen',
  'Leingarten',
  'Leini',
  'Leinì',
  'Leinster',
  'Leintwardine',
  'Leipheim',
  'Leipsic',
  'Leipzig',
  'Leipzig-leutzsch',
  'Leiria',
  'Leirvik I Sogn',
  'Leisnig',
  'Leiston',
  'Leitchfield',
  'Leith',
  'Leitrim',
  'Leiva',
  'Leixlip',
  'Lejre',
  'Lekeryd',
  'Lekkerkerk',
  'Lekki',
  'Leknes',
  'Leksand',
  'Leland',
  'Lelekovice',
  'Leling',
  'Lelów',
  'Lelystad',
  'Lelystad-haven',
  'Lem',
  'Lembeke',
  'Lembras',
  'Leme',
  'Lemelerveld',
  'Lemery',
  'Lemesos',
  'Lemgo',
  'Lemi',
  'Lemiers',
  'Lemignano',
  'Lemitar',
  'Lemmer',
  'Lemmes',
  'Lemmon',
  'Lemon Grove',
  'Lemont',
  'Lemoore',
  'Lemoyne',
  'Lempäälä',
  'Lemvig',
  'Lemwerder',
  'Lena',
  'Lenart',
  'Lenart V Slovenskih Goricah',
  'Lençóis Paulista',
  'Lend',
  'Lendava',
  'Lendelede',
  'Lenesice',
  'Lenexa',
  'Lengau',
  'Lengede',
  'Lengel',
  'Lengenfeld',
  'Lengenwang',
  'Lengerich',
  'Lenggries',
  'Lengnau',
  'Lenham',
  'Leningrad',
  'Leningradskiy',
  'Lenk',
  'Lennestadt',
  'Lennik',
  'Lennox Head',
  'Lennoxtown',
  'Lenoir',
  'Lenoir City',
  'Lenox',
  'Lens',
  'Lensahn',
  'Lensk',
  'Lent',
  'Lentate Sul Seveso',
  'Lentföhrden',
  'Lenting',
  'Lentzweiler',
  'Lenzburg',
  'Lenzerheide',
  'Lenzing',
  'Lenzingerberg',
  'Leo',
  'Leoben',
  'Leobendorf',
  'Leobersdorf',
  'Leola',
  'Leoma',
  'Leominster',
  'Leonard',
  'Leonards Hill',
  'Leonberg',
  'Leoncin',
  'Leonding',
  'Leonia',
  'Leonore',
  'Leopardstown',
  'Leopoldina',
  'Leopoldsdorf',
  'Leopoldshafen',
  'Leopoldshöhe',
  'León',
  'Lepe',
  'Leping',
  'Leppävirta',
  'Leppersdorf',
  'Lerberget',
  'Lerchenberg',
  'Lerdala',
  'Leribe',
  'Lerici',
  'Lerkil',
  'Lerma',
  'Lerma De Villada',
  'Lerum',
  'Lerwick',
  'Les Abymes',
  'Les Acacias',
  'Les Aires',
  'Les Angles',
  'Les Auvergnes',
  'Les Baraques',
  'Les Barraques',
  'Les Borges Del Camp',
  'Les Cèdres',
  'Les Charbonnieres',
  'Les Chères',
  'Les Clayes-sous-bois',
  "Les Eglises-d'argenteuil",
  'Les Essarts-le-roi',
  'Les Éboulements',
  'Les Fossés',
  'Les Fougerêts',
  'Les Geneveys-sur-coffrane',
  'Les Genevez',
  'Les Halles',
  'Les Hautes-rivières',
  'Les Hauts-saint-jean',
  'Les Herbiers',
  'Les Houches',
  'Les Lilas',
  'Les Marches',
  'Les Mées',
  'Les Moulins',
  'Les Moutiers',
  'Les Mureaux',
  'Les Olmes',
  'Les Pavillons-sous-bois',
  'Les Petits Camps',
  'Les Pins',
  'Les Plans',
  'Les Playes',
  'Les Ponts-de-cé',
  "Les Sables-d'olonne",
  'Les Saintes Maries De La Mer',
  'Les-loges-en-josas',
  'Les-pennes-mirabeau',
  'Lesa',
  'Lescar',
  'Lesce',
  'Leshan',
  'Leskovac',
  'Leskovec Nad Moravicí',
  'Leskovec Pri Krskem',
  'Lesmahagow',
  'Lesna',
  'Lesquin',
  'Lessay',
  'Lessebo',
  'Lessines',
  'Lessolo',
  'Lesterville',
  'Lestijärvi',
  'Lestrem',
  'Leszczyny',
  'Leszno',
  'Letchworth',
  'Lethbridge',
  'Letnany',
  'Letohrad',
  'Letovice',
  'Lette',
  'Letterkenny',
  'Letzeburg',
  'Leubsdorf',
  'Leudelange',
  'Leukersdorf',
  'Leun',
  'Leuna',
  'Leunen',
  'Leura',
  'Leusden',
  'Leusden-centrum',
  'Leutenbach',
  'Leutenberg',
  'Leutershausen',
  'Leutkirch',
  'Leutkirch Im Allgäu',
  'Leuven',
  'Leuzigen',
  'Levallois',
  'Levallois-perret',
  'Levanger',
  'Levant',
  'Levata',
  'Levelland',
  'Levelock',
  'Leven',
  'Levenshulme',
  'Levent',
  'Leverett',
  'Leverkusen',
  'Levern',
  'Levernois',
  'Levice',
  'Levico',
  'Levin',
  'Levittown',
  'Lewes',
  'Lewis',
  'Lewis Center',
  'Lewis Run',
  'Lewisberry',
  'Lewisburg',
  'Lewisham',
  'Lewiston',
  'Lewistown',
  'Lewisville',
  'Lexington',
  'Lexington Park',
  'Lexington-Fayette',
  'Lexmond',
  'Leyburn',
  'Leyland',
  'Leysin',
  'Leyton',
  'Lezama',
  'Lezay',
  'Lezennes',
  'Lezo',
  'Lège',
  'Léchelles',
  'Léchère',
  'Lécousse',
  'Lée',
  'Léognan',
  'Lérida',
  'Lésigny',
  'Lévis',
  'Lëtki',
  'Lhasa',
  'Lhokseumawe',
  'Lhota Pod Libcany',
  'Li On',
  'Lianhuayuan',
  'Lianyungang',
  'Lianyungang Shi',
  'Liaocheng',
  'Liaoyang',
  'Libcany',
  'Libcice Nad Vltavou',
  'Liben',
  'Liberal',
  'Libercourt',
  'Liberec',
  'Liberia',
  'Liberty',
  'Liberty Center',
  'Liberty Corner',
  'Liberty Hill',
  'Liberty Lake',
  'Libertyville',
  'Libice Nad Cidlinou',
  'Libin',
  'Libis',
  'Liboc',
  'Libosovice',
  'Libourne',
  'Libramont',
  'Libres',
  'Libreville',
  'Librilla',
  'Libun',
  'Libus',
  'Lich',
  'Licheng',
  'Lichfield',
  'Lichoceves',
  'Lichtaart',
  'Lichtenau',
  'Lichtenberg',
  'Lichtenfels',
  'Lichtenrade',
  'Lichtensteig',
  'Lichtenstein',
  'Lichtentanne',
  'Lichtenvoorde',
  'Lichtenwörth',
  'Lichterfelde',
  'Lichtervelde',
  'Lichuan',
  'Lida',
  'Lidcombe',
  'Lidingö',
  'Lidköping',
  'Lido Di Camaiore',
  'Lidsdale',
  'Liebefeld',
  'Liebenburg',
  'Lieboch',
  'Liedekerke',
  'Liederbach',
  'Lieksa',
  'Liempde',
  'Lienden',
  'Lienen',
  'Lienz',
  'Liepaja',
  'Liepalotai',
  'Lier',
  'Lieren',
  'Lierre',
  'Liers',
  'Lieshout',
  'Liestal',
  'Lieto',
  'Lieusaint',
  'Lievelde',
  'Lievestuore',
  'Liège',
  'Lièpvre',
  'Liévin',
  'Lifford',
  'Ligny-en-brionnais',
  'Ligonier',
  'Ligornetto',
  'Lihue',
  'Liikva',
  'Lijiang',
  'Lijnden',
  'Likóvrisi',
  'Lilburn',
  'Lilienthal',
  'Liljeholmen',
  'Lilla Edet',
  'Lilla Nyckelviken',
  'Lille',
  'Lille Skensved',
  'Lillebonne',
  'Lillehammer',
  'Lillesand',
  'Lilleshall',
  'Lillestrøm',
  'Lillington',
  'Lillois-witterzée',
  'Lilongwe',
  'Lilydale',
  'Lilyfield',
  'Lima',
  'Limal',
  'Limas',
  'Limasol',
  'Limassol',
  'Limavady',
  'Limay',
  'Limbach',
  'Limbach-oberfrohna',
  'Limbe',
  'Limbiate',
  'Limbricht',
  'Limburg',
  'Limburg An Der Lahn',
  'Limburgerhof',
  'Limbus',
  'Limeil-brévannes',
  'Limeira',
  'Limelette',
  'Limena',
  'Limerick',
  'Limhamn',
  'Limington',
  'Limmen',
  'Limoeiro',
  'Limoges',
  'Limonest',
  'Limours',
  'Limón',
  'Limuru',
  'Linan',
  'Linares',
  'Linby',
  'Lince',
  'Lincent',
  'Lincheng',
  'Lincoln',
  'Lincoln City',
  'Lincoln Park',
  'Lincoln University',
  "Lincoln's New Salem",
  'Lincolnshire',
  'Lincolnton',
  'Lincolnwood',
  'Lincroft',
  'Linda',
  'Linda-a-velha',
  'Lindale',
  'Lindau',
  'Lindau Bodensee',
  'Lindavista',
  'Lindberg',
  'Linden',
  'Lindenberg',
  'Lindenfels',
  'Lindenhurst',
  'Lindesberg',
  'Lindfield',
  'Lindlar',
  'Lindley',
  'Lindome',
  'Lindon',
  'Lindsay',
  'Lindsborg',
  'Lindstrom',
  'Lindwedel',
  'Line Lexington',
  'Linesville',
  'Linfen',
  'Lingan',
  'Lingbao',
  'Lingen',
  'Lingfield',
  'Linghem',
  'Lingolsheim',
  'Lingui',
  'Lingyuan',
  'Linhai',
  'Linhares',
  'Linhó',
  'Liniers',
  'Linière',
  'Linkebeek',
  'Linkenheim-hochstetten',
  'Linkou',
  'Linköping',
  'Linlithgow',
  'Linn',
  'Linn Creek',
  'Linneryd',
  'Linnich',
  'Lins',
  'Linschoten',
  'Linsdorf',
  'Linselles',
  'Linstead',
  'Lint',
  'Lintgen',
  'Linthicum Heights',
  'Linthouse',
  'Linton',
  'Lintong',
  'Linville Falls',
  'Linwood',
  'Linxia',
  'Linxiang',
  'Linyi',
  'Linz',
  'Linz Am Rhein',
  'Linzhou',
  'Linzi',
  'Lions Head',
  'Lipa City',
  'Lipa Piotrowska',
  'Lipan',
  'Lipany',
  'Lipari',
  'Lipetsk',
  'Liphook',
  'Lipik',
  'Lipno',
  'Lipov',
  'Lipova',
  'Lipová',
  'Lippspringe',
  'Lippstadt',
  'Lipsheim',
  'Liptal',
  'Liptovsky Mikulas',
  'Liptovský Hrádok',
  'Lira',
  'Lirac',
  'Liria',
  'Lisbellaw',
  'Lisboa',
  'Lisbon',
  'Lisburn',
  'Liscate',
  'Lishui',
  'Lisieux',
  'Liskeard',
  'Liski',
  'Lisle',
  'Lismore',
  'Lisnaskea',
  'Lisov',
  'Lispole',
  'Liss',
  'Lissabon',
  'Lisse',
  'Lisses',
  'Lissieu',
  'Lissone',
  'Liston',
  'Listowel',
  'Liszki',
  'Lit-et-mixe',
  'Litchfield',
  'Litchfield Park',
  'Lith',
  'Lithgow',
  'Lithia',
  'Lithia Springs',
  'Lithonia',
  'Litija',
  'Lititz',
  'Litomerice',
  'Litomysl',
  'Litovel',
  'Little Baddow',
  'Little Bookham',
  'Little Chesterford',
  'Little Chute',
  'Little Clacton',
  'Little Compton',
  'Little Driffield',
  'Little Eaton',
  'Little Elm',
  'Little Falls',
  'Little Ferry',
  'Little Hadham',
  'Little Houghton',
  'Little Neck',
  'Little River',
  'Little Rock',
  'Little Silver',
  'Little Sutton',
  'Little Switzerland',
  'Littleborough',
  'Littlefield',
  'Littlehampton',
  'Littleport',
  'Littlerock',
  'Littleton',
  'Littoinen',
  'Litvinov',
  'Litvinovice',
  'Litvínov',
  'Litzendorf',
  'Liuan',
  'Liuhe',
  'Liushi',
  'Liuyang',
  'Liuzhou',
  'Livange',
  'Live Oak',
  'Lively',
  'Livermore',
  'Livermore Falls',
  'Livernon',
  'Liverpool',
  'Liversedge',
  'Livigno',
  'Livingston',
  'Livingston Manor',
  'Livingstone',
  'Livonia',
  'Livorno',
  'Livron-sur-drôme',
  'Livry-gargan',
  'Lixhausen',
  'Lixin',
  'Liyang',
  'Lizaso',
  'Lizella',
  'Lizhou',
  'Lizy-sur-ourcq',
  'Ljubecna',
  'Ljubljana',
  'Ljubljana-polje',
  'Ljubno Ob Savinji',
  'Ljugarn',
  'Ljungaverk',
  'Ljungby',
  'Ljungsbro',
  'Ljungskile',
  'Ljusdal',
  'Ljutomer',
  'Lkan',
  'Llagostera',
  'Llanberis',
  'Llandaff',
  'Llandovery',
  'Llandrindod Wells',
  'Llandudno',
  'Llandudno Junction',
  'Llandybie',
  'Llanelli',
  'Llanera',
  'Llanera De Ranes',
  'Llanes',
  'Llanfaelog',
  'Llanfairfechan',
  'Llanfairpwllgwyngyll',
  'Llanfallteg',
  'Llangattock',
  'Llangefni',
  'Llangollen',
  'Llanidloes',
  'Llanishen',
  'Llanon',
  'Llanrwst',
  'Llantrisant',
  'Llanyblodwel',
  'Lleida',
  'Llissá De Vall',
  'Llodio',
  'Lloret De Mar',
  'Lloydminster',
  'Lluchmayor',
  'Lo Barnechea',
  'Loanhead',
  'Lobbes',
  'Lobelville',
  'Lobenstein',
  'Locarno',
  'Locate Di Triulzi',
  'Loch Sheldrake',
  'Lochau',
  'Lochem',
  'Lochenhaus',
  'Lochinver',
  'Lochovice',
  'Lochristi',
  'Lochum',
  'Lochwinnoch',
  'Lock Haven',
  'Lockbourne',
  'Locke',
  'Lockeby',
  'Lockeport',
  'Lockerbie',
  'Lockhart',
  'Locking',
  'Lockport',
  'Lockwood',
  'Locminé',
  'Locust',
  'Locust Grove',
  'Locust Valley',
  'Lod',
  'Lodano',
  'Loddefjord',
  'Loddon',
  'Lodenice',
  'Lodi',
  'Lodz',
  'Loeches',
  'Loenen',
  'Loenen Op De Veluwe',
  'Loeuilly',
  'Lofsdalen',
  'Loftahammar',
  'Logan',
  'Loganholme',
  'Loganlea',
  'Logansport',
  'Loganton',
  'Loganville',
  'Logatec',
  'Logelheim',
  'Lognes',
  'Lograto',
  'Logroño',
  'Lohfelden',
  'Lohja',
  'Lohmar',
  'Lohn',
  'Lohne',
  'Lohr',
  'Lohra',
  'Lohrhaupten',
  'Lohsa',
  'Lohtaja',
  'Loimaa',
  'Loire',
  'Loiré',
  'Loison-sous-lens',
  'Loix',
  'Loja',
  'Lok',
  'Lokeren',
  'Lokoja',
  'Loleta',
  'Lolworth',
  'Lom',
  'Loma Linda',
  'Lomagna',
  'Lomas',
  'Lomas De Chapultepec',
  'Lomas De Santa Gertrudis',
  'Lomas De Zamora',
  'Lomazzo',
  'Lombard',
  'Lombardi',
  'Lombardía',
  'Lombardore',
  'Lombok',
  'Lomersheim',
  'Lometa',
  'Lomé',
  'Lomianki',
  'Lomira',
  'Lomita',
  'Lomma',
  'Lommatzsch',
  'Lomme',
  'Lommel',
  'Lommis',
  'Lompoc',
  'Lomza',
  'Lon',
  'Lonate Ceppino',
  'Lonato',
  'Lonavala',
  'Lonavla',
  'Loncin',
  'Londerzeel',
  'London',
  'London Colney',
  'Londonderry',
  'Londrina',
  'Lone Rock',
  'Lone Star',
  'Lone Tree',
  'Lonevåg',
  'Long Ashton',
  'Long Beach',
  'Long Bennington',
  'Long Branch',
  'Long Crendon',
  'Long Eaton',
  'Long Grove',
  'Long Island City',
  'Long Lake',
  'Long Marston',
  'Long Melford',
  'Long Prairie',
  'Long Stanton',
  'Long Stratton',
  'Long Valley',
  'Long Xuyên',
  'Longare',
  'Longarone',
  'Longboat Key',
  'Longbridge',
  'Longbridge Deverill',
  'Longchuan',
  'Longcross',
  'Longeville-en-barrois',
  'Longeville-lès-metz',
  'Longfeng',
  'Longfield',
  'Longford',
  'Longgang',
  'Longhua',
  'Longiano',
  'Longjumeau',
  'Longkamp',
  'Longkou',
  'Longmeadow',
  'Longmont',
  'Longniddry',
  'Longpont-sur-orge',
  'Longridge',
  'Longs',
  'Longton',
  'Longue Raie',
  'Longuenesse',
  'Longueuil',
  'Longueville',
  'Longuich',
  'Longvic',
  'Longview',
  'Longwood',
  'Longxing',
  'Longyan',
  'Longyou',
  'Lonigo',
  'Lonnerstadt',
  'Lonoke',
  'Lons',
  'Lons-le-saunier',
  'Lonsdale',
  'Lonsee',
  'Lonzée',
  'Lookout Mountain',
  'Loomberah',
  'Loomis',
  'Loop',
  'Loos',
  'Loos-en-gohelle',
  'Loosdrecht',
  'Loose',
  'Lopatinec',
  'Lopen',
  'Lopera',
  'Lopez',
  'Lopik',
  'Lopikerkapel',
  'Loppem',
  'Loppi',
  'Lora De Estepa',
  'Lora Del Río',
  'Lorain',
  'Lorca',
  'Lorch',
  'Lorcha',
  'Lorena',
  'Loreo',
  'Loreto',
  'Lorette',
  'Loretteville',
  'Loretto',
  'Lorgues',
  'Lorient',
  'Loriguilla',
  'Loriol',
  'Loriol-sur-drôme',
  'Loris',
  'Lorman',
  'Lormont',
  'Lorquí',
  'Lorris',
  'Lorry',
  'Lorsch',
  'Lorton',
  'Los Alamitos',
  'Los Alamos',
  'Los Altos',
  'Los Altos Hills',
  'Los Andes',
  'Los Angeles',
  'Los Ángeles',
  'Los Banos',
  'Los Baños',
  'Los Barrios',
  'Los Chiles',
  'Los Corrales De Buelna',
  'Los Cristianos',
  'Los Fresnos',
  'Los Garza',
  'Los Gatos',
  'Los Llanos De Aridane',
  'Los Lunas',
  'Los Majuelos',
  'Los Manzanos',
  'Los Mochis',
  'Los Molinos',
  'Los Olivos',
  'Los Osos',
  'Los Polvorines',
  'Los Teques',
  'Los Yoses',
  'Losone',
  'Losser',
  'Lost City',
  'Lostallo',
  'Lostice',
  'Lostock Gralam',
  'Lostorf',
  'Lostwithiel',
  'Lot',
  'Lothian',
  'Lotte',
  'Lotzwil',
  'Louannec',
  'Loucna Nad Desnou',
  'Loucun',
  'Loudéac',
  'Loudi',
  'Loudon',
  'Loudun',
  'Loudwater',
  'Louey',
  'Loué',
  'Loughborough',
  'Loughgall',
  'Loughlinstown',
  'Loughman',
  'Loughrea',
  'Loughton',
  'Louhans',
  'Louis',
  'Louis Trichardt',
  'Louisa',
  'Louisburg',
  'Louisburgh',
  'Louiseville',
  'Louisiana',
  'Louisville',
  'Loulé',
  'Lourdes',
  'Loureiro',
  'Loures',
  'Lourinhã',
  'Louro',
  'Lousa',
  'Lousada',
  'Louth',
  'Louvain',
  'Louvain-la-neuve',
  'Louveciennes',
  'Louveigné',
  'Louveira',
  'Louverné',
  'Louviers',
  'Louvigny',
  'Louvres',
  'Lovas',
  'Lovech',
  'Loveland',
  'Lovendegem',
  'Lovenjoel',
  'Loverval',
  'Loves Park',
  'Lovettsville',
  'Loviisa',
  'Lovingston',
  'Lovington',
  'Lovosice',
  'Lovran',
  'Lowden',
  'Lowell',
  'Lowellville',
  'Lower Hutt',
  'Lower Lake',
  'Lower Largo',
  'Lower Nicola',
  'Lower Sackville',
  'Lowestoft',
  'Lowton',
  'Loxahatchee',
  'Loxstedt',
  'Loxwood',
  'Loyers',
  'Loyettes',
  'Loyola Heights',
  'Loysburg',
  'Lozanne',
  'Lozen',
  'Lozenets',
  'Loznica',
  'Lozovo',
  'Lozzolo',
  'Loßburg',
  'Löbau',
  'Löberschütz',
  'Löchgau',
  'Löddeköpinge',
  'Löderup',
  'Löffingen',
  'Löhlbach',
  'Löhnberg',
  'Löhne',
  'Löningen',
  'Lönneberga',
  'Lörbyskog',
  'Lörrach',
  'Lösnich',
  'Löttorp',
  'Lövestad',
  'Luan',
  'Luancheng',
  'Luanda',
  'Luanshya',
  'Lubasz',
  'Lubbeek',
  'Lubbock',
  'Lubenec',
  'Lubenham',
  'Lubeník',
  'Lubersac',
  'Lublin',
  'Lubliniec',
  'Lublino',
  'Lubmin',
  'Lubny',
  'Lubon',
  'Lubumbashi',
  'Lubuskie',
  'Lubusz',
  'Lubyanka',
  'Luc-sur-mer',
  'Lucan',
  'Lucas',
  'Lucasville',
  'Lucca',
  'Lucedale',
  'Lucena',
  'Lucena Del Puerto',
  'Lucenec',
  'Luceni',
  'Lucens',
  'Lucera',
  'Lucerne',
  'Lucerne Valley',
  'Luché-pringé',
  'Lucka',
  'Luckenwalde',
  'Lucklum',
  'Lucknow',
  'Lucknow City',
  'Lucko',
  'Lucy',
  'Luçon',
  'Ludbreg',
  'Ludgerovice',
  'Ludgershall',
  'Ludhiana',
  'Ludikov',
  'Ludington',
  'Ludlow',
  'Ludon-médoc',
  'Ludowici',
  'Ludres',
  'Ludvigsborg',
  'Ludvika',
  'Ludwigsburg',
  'Ludwigsburg-eglosheim',
  'Ludwigsfelde',
  'Ludwigshafen',
  'Ludwigshafen Am Rhein',
  'Ludwigshafen Am Rhein-oggersheim',
  'Ludwigslust',
  'Lufkin',
  'Lugano',
  'Lugansk',
  'Lugarno',
  'Lugau',
  'Luggate',
  'Lugny',
  'Lugo',
  'Lugoff',
  'Lugoj',
  'Lugones',
  'Luhacovice',
  'Luhansk',
  'Luhden',
  'Luhe-wildenau',
  'Luino',
  'Lujan',
  'Lujan De Cuyo',
  'Lukang',
  'Lukavac',
  'Lukovica',
  'Lukow',
  'Lula',
  'Luleå',
  'Luling',
  'Lully',
  'Lumberton',
  'Lumberville',
  'Lumbin',
  'Lummen',
  'Lumpur',
  'Luna Pier',
  'Lund',
  'Lunde',
  'Lunderskov',
  'Lundsbrunn',
  'Lunel',
  'Lunel-viel',
  'Lunenburg',
  'Lunjiao',
  'Lunner',
  'Lunteren',
  'Lunzenau',
  'Luohe',
  'Luohu',
  'Luoyang',
  'Lupburg',
  'Lupiana',
  'Lupoglav',
  'Luqiao',
  'Luquan',
  'Lurago Marinone',
  'Lurais',
  'Lurate Caccivio',
  'Luray',
  'Lure',
  'Luré',
  'Lurgan',
  'Luriecq',
  'Lurtigen',
  'Lusaka',
  'Lusby',
  'Luserna',
  'Luserna San Giovanni',
  'Lussac-les-châteaux',
  'Lussan',
  'Lussas',
  'Lustenau',
  'Lutcher',
  'Luterbach',
  'Luthe',
  'Lutherville Timonium',
  'Lutin',
  'Luton',
  'Lutry',
  'Lutsk',
  'Luttenberg',
  'Lutterbach',
  'Lutterworth',
  'Lutz',
  'Lutzelhouse',
  'Luunja',
  'Luverne',
  'Luwan',
  'Lux',
  'Luxembourg',
  'Luxemburg',
  'Luxor',
  'Luyksgestel',
  'Luynes',
  'Luzarches',
  'Luzern',
  'Luzerna',
  'Luzerne',
  'Luzhou',
  'Luzianky',
  'Luziânia',
  'Luzinay',
  'Luzzana',
  'Luzzara',
  'Lübbecke',
  'Lübbenau',
  'Lübeck',
  'Lübesse',
  'Lübs',
  'Lüchow',
  'Lüdenscheid',
  'Lüdinghausen',
  'Lügde',
  'Lühe',
  'Lüneburg',
  'Lünen',
  'Lütetsburg',
  'Lütjensee',
  'Lützelbach',
  'Lützelflüh',
  'Lützen',
  'Lva Tolstogo',
  'Lviv',
  'Lvov',
  'Lycke',
  'Lycksele',
  'Lydbrook',
  'Lydney',
  'Lykovrisi',
  'Lyle',
  'Lyles',
  'Lyman',
  'Lyme',
  'Lymington',
  'Lymm',
  'Lympsham',
  'Lynbrook',
  'Lynchburg',
  'Lynden',
  'Lyndhurst',
  'Lyndonville',
  'Lyneham',
  'Lyngby',
  'Lyngdal',
  'Lynge',
  'Lynmouth',
  'Lynn',
  'Lynn Haven',
  'Lynnfield',
  'Lynnwood',
  'Lynwood',
  'Lyon',
  'Lyon Station',
  'Lyon-vaise',
  'Lyons',
  'Lyons Falls',
  'Lys-lès-lannoy',
  'Lysa Nad Labem',
  'Lysaker',
  'Lysekil',
  'Lysice',
  'Lyss',
  'Lyssach',
  'Lystrup',
  'Lysøysundet',
  'Lytham',
  'Lytle',
  'Lytton',
  'Lyubertsy',
  'Læsø',
  'Løgstrup',
  'Løgstør',
  'Løgumkloster',
  'Løkken Verk',
  'Løsning',
  'M C B H Kaneohe Bay',
  'Ma',
  "Ma'alot",
  "Ma'alot Tarshiha",
  'Ma`ale Hahamisha',
  'Maagan Michael',
  'Maanshan',
  'Maardu',
  'Maarheeze',
  'Maarn',
  'Maarsbergen',
  'Maarssen',
  'Maartensdijk',
  'Maasbracht',
  'Maasbree',
  'Maasdam',
  'Maasdijk',
  'Maaseik',
  'Maaskant',
  'Maasland',
  'Maasmechelen',
  'Maassluis',
  'Maastricht',
  'Mabank',
  'Mabelvale',
  'Mableton',
  'Mably',
  'Mabolo',
  'Macaé',
  'Macamic',
  'Macao',
  'Macapá',
  'Macau',
  'Macclenny',
  'Macclesfield',
  'Macduff',
  'Macedon',
  'Macedonia',
  'Maceió',
  'Maceira',
  'Macerata',
  'Machakos',
  'Machecoul',
  'Machelen',
  'Machen',
  'Macheon',
  'Machesney Park',
  'Maché',
  'Machi',
  'Machias',
  'Machida',
  'Machov',
  'Machynlleth',
  'Macieira De Rates',
  'Macinec',
  'Mackay',
  'Mackinac Island',
  'Macksville',
  'Mackworth',
  'Maclean',
  'Macleod',
  'Macmerry',
  'Macolin',
  'Macomb',
  'Macon',
  'Macroom',
  'Mactier',
  'Macul',
  'Macungie',
  'Macy',
  'Mação',
  'Madalena',
  'Madanapalle',
  'Madang',
  'Madawaska',
  'Madbury',
  'Maddaloni',
  'Maddington',
  'Made',
  'Madeirã',
  'Madelia',
  'Madeline',
  'Madera',
  'Madhapur',
  'Madhipura',
  'Madhuban',
  'Madhubani',
  'Madhyamgram',
  'Madignano',
  'Madill',
  'Madingley',
  'Madipakkam',
  'Madison',
  'Madison Heights',
  'Madisonville',
  'Madiswil',
  'Madiun',
  'Madley',
  'Madoc',
  "Madonna Dell'arco",
  'Madras',
  'Madrid',
  'Madridejos',
  'Madrigalejo Del Monte',
  'Madrigueras',
  'Madryn',
  'Madu',
  'Madura',
  'Madurai',
  'Mae Hong Son',
  'Mae Sai',
  'Maebaru',
  'Maebashi',
  'Maebashi-shi',
  'Maesteg',
  'Maezawa',
  'Mafeteng',
  'Mafra',
  'Magadan',
  'Magal',
  'Magalia',
  'Magallanes',
  'Magazine',
  'Magda',
  'Magdala',
  'Magdalena Del Mar',
  'Magdeburg',
  'Magdeburg-rothensee',
  'Magden',
  'Magee',
  'Magelang',
  'Magen',
  'Magenta',
  'Magetan',
  'Maggie Valley',
  'Maggio',
  'Magh',
  'Maghar',
  'Maghera',
  'Magherafelt',
  'Magill',
  'Maglie',
  'Maglód',
  'Magna',
  'Magnac-laval',
  'Magnago',
  'Magnano In Riviera',
  'Magnitogorsk',
  'Magnolia',
  'Magnor',
  'Magny-en-vexin',
  'Magny-le-hongre',
  'Magny-les-hameaux',
  'Magodo',
  'Magog',
  'Magor',
  'Magoúla',
  'Magrath',
  'Magshimim',
  'Magstadt',
  'Magurele',
  'Magway',
  'Mahabad',
  'Mahabaleshwar',
  'Mahajanga',
  'Mahalakshmi',
  'Mahalaxmi',
  'Mahanayim',
  'Mahanoy City',
  'Maharagama',
  'Mahbubnagar',
  'Mahdia',
  'Mahe',
  'Mahesana',
  'Mahé',
  'Mahim',
  'Mahlberg',
  'Mahlow',
  "Mahmudabad-e Pa'in",
  'Mahmutbey',
  'Mahnomen',
  'Mahomet',
  'Mahone Bay',
  'Mahopac',
  'Mahón',
  'Mahwah',
  'Maia',
  'Maibara',
  'Maiden',
  'Maidenhead',
  'Maidstone',
  'Maiduguri',
  'Maienfeld',
  'Maikammer',
  'Mainaschaff',
  'Mainbernheim',
  'Mainburg',
  'Maine',
  'Maineville',
  'Mainhardt',
  'Mainland',
  'Maintal',
  'Maintenon',
  'Mainvilliers',
  'Mainz',
  'Mainz-kastel',
  'Mairangi Bay',
  'Mairena Del Alcor',
  'Mairena Del Aljarafe',
  'Mairiporã',
  'Maisach',
  'Maisemore',
  'Maisons',
  'Maisons-alfort',
  'Maisons-laffitte',
  'Maisonsgoutte',
  'Maitama',
  'Maitland',
  'Maizuru',
  'Maía',
  'Majadahonda',
  'Majdan',
  'Majhitar',
  'Majunga',
  'Majura',
  'Majuro',
  'Makarov',
  'Makarska',
  'Makassar',
  'Makati',
  'Makawao',
  'Makeevka',
  'Makerere',
  'Makhachkala',
  'Makiyivka',
  'Makkah',
  'Makkah Al Mukarramah',
  'Makkah Al Mukarraman',
  'Maklár',
  'Makmur',
  'Makole',
  'Makó',
  'Makurdi',
  'Mala Strana',
  'Malabar',
  'Malabe',
  'Malabo',
  'Malabon',
  'Malabry',
  'Malacca',
  'Malacca Town',
  'Malacky',
  'Malad',
  'Malaga',
  'Malagón',
  'Malahide',
  'Malakhovka',
  'Malakoff',
  'Malang',
  'Malang Tengah',
  'Malans',
  'Malappuram',
  'Malargüe',
  'Malataverne',
  'Malate',
  'Malatya',
  'Malaut',
  'Malay',
  'Malaya Kushelëvka',
  'Malá Ida',
  'Malbork',
  'Malchow',
  'Malcom',
  'Malda',
  'Maldegem',
  'Malden',
  'Maldon',
  'Male',
  'Malegaon',
  'Malemort',
  'Malemort-sur-corrèze',
  'Malente',
  'Maler Kotla',
  'Malesco',
  'Malesherbes',
  'Malesice',
  'Malgrat De Mar',
  'Mali',
  'Mali Losinj',
  'Mali Zvornik',
  'Maliaño',
  'Malibu',
  'Malicorne',
  'Malijai',
  'Malindi',
  'Malinovo',
  'Malissard',
  'Malix',
  'Malkerns',
  'Malleray',
  'Mallersdorf',
  'Mallersdorf-pfaffenberg',
  'Malles Venosta',
  'Mallesvaram',
  'Mallén',
  'Malling',
  'Mallorca',
  'Mallorytown',
  'Mallow',
  'Malma',
  'Malmesbury',
  'Malmédy',
  'Malmköping',
  'Malmö',
  'Malmön',
  'Malmsbury',
  'Malnate',
  'Malo',
  'Maloja',
  'Malolos',
  'Malone',
  'Maloyaroslavets',
  'Malpas',
  'Malpils',
  'Malsch',
  'Malsfeld',
  'Malta',
  'Maltby',
  'Maltepe',
  'Malterdingen',
  'Malters',
  'Malton',
  'Malu Mare',
  'Malung',
  'Malur',
  'Malveira',
  'Malvern',
  'Malvern East',
  'Malvern Link',
  'Malvern Wells',
  'Malverne',
  'Malville',
  'Malvinas Argentinas',
  'Malz',
  'Malzéville',
  'Mamaia',
  'Mamaroneck',
  'Mamer',
  'Mamers',
  'Mamirolle',
  'Mammelzen',
  'Mammendorf',
  'Mammern',
  'Mammoth Lakes',
  'Mampangprapatan',
  'Manacor',
  'Manado',
  'Manage',
  'Managua',
  'Manahawkin',
  'Manakin Sabot',
  'Manalapan',
  'Manali',
  'Manama',
  'Manasquan',
  'Manassa',
  'Manassas',
  'Manassas Park',
  'Manaus',
  'Manavgat',
  'Manawa',
  'Mancelona',
  'Mancha Real',
  'Manchaca',
  'Manchaug',
  'Manchester',
  'Manchester Center',
  'Manchester Township',
  'Manching',
  'Mancos',
  'Mandaguaçu',
  'Mandal',
  'Mandalay',
  'Mandaluyong',
  'Mandan',
  'Mandaree',
  'Mandaue',
  'Mandaue City',
  'Mandeure',
  'Mandeville',
  'Mandi',
  'Mandi Bahauddin',
  'Mandirituba',
  'Mandrem',
  'Manduel',
  'Mandurah',
  'Mandurama',
  'Manduria',
  'Mandurriao',
  'Mandvi',
  'Mandya',
  'Mane',
  'Manerbio',
  'Manfredonia',
  'Mangalagiri',
  'Mangalore',
  'Mangaluru',
  'Mangaon',
  'Mangawhai',
  'Manger',
  'Mangere',
  'Mango',
  'Mangonui',
  'Mangualde',
  'Manhasset',
  'Manhattan',
  'Manhattan Beach',
  'Manheim',
  'Manhuaçu',
  'Maniago',
  'Manila',
  'Manila Heights Subdivision',
  'Manilva',
  'Maninghem',
  'Manipal',
  'Manisa',
  'Manises',
  'Manistee',
  'Manistique',
  'Manitou Springs',
  'Manitowish Waters',
  'Manitowoc',
  'Maniwaki',
  'Manizales',
  'Manjeri',
  'Mankato',
  'Manlius',
  'Manlleu',
  'Manly',
  'Manly Vale',
  'Mannering Park',
  'Manneville-la-pipard',
  'Mannford',
  'Mannhausen',
  'Mannheim',
  'Manning',
  'Manningtree',
  'Manno',
  'Manns Harbor',
  'Mannsworth',
  'Mannum',
  'Manor',
  'Manorbier',
  'Manorville',
  'Manosque',
  'Manotick',
  'Manouba',
  'Manresa',
  'Mansås',
  'Mansfeld',
  'Mansfield',
  'Mansfield Center',
  'Mansion',
  'Manson',
  'Mansoura',
  'Mansourah',
  'Mansura',
  'Manta',
  'Manteca',
  'Mantel',
  'Manteno',
  'Manteo',
  'Mantes-la-jolie',
  'Mantes-la-ville',
  'Mantgum',
  'Manthelan',
  'Manti',
  'Manton',
  'Mantorp',
  'Mantova',
  'Mantua',
  'Manu',
  'Manukau',
  'Manukau City',
  'Manvel',
  'Manville',
  'Many',
  'Manzanares',
  'Manzanares El Real',
  'Manzanillo',
  'Manzanita',
  'Manzano',
  'Manziana',
  'Manzini',
  'Mao',
  'Maoming',
  'Mapello',
  'Maple',
  'Maple Grove',
  'Maple Lake',
  'Maple Park',
  'Maple Plain',
  'Maple Ridge',
  'Maple Shade',
  'Maple Valley',
  'Mapleton',
  'Maplewood',
  'Mapo',
  'Mapodong',
  'Mapou',
  'Mapua',
  'Mapuca',
  'Maputo',
  'Mar De Espanha',
  'Mar Del Plata',
  'Mar Lin',
  'Marabá',
  'Maracaibo',
  'Maracanaú',
  'Maracay',
  'Maracás',
  'Maracena',
  'Marana',
  'Marandahalli',
  'Maranello',
  'Marano',
  'Marano Di Napoli',
  'Marans',
  'Marasu',
  'Marataízes',
  'Marathon',
  'Marathon Shores',
  'Marathón',
  'Marau',
  'Maraval',
  'Marbach',
  'Marbäck',
  'Marbehan',
  'Marbella',
  'Marble City',
  'Marble Falls',
  'Marble Hill',
  'Marblehead',
  'Marburg',
  'Marcali',
  'Marcallo Con Casone',
  'Marcaria',
  'Marcellus',
  'March',
  'Marchand',
  'Marche',
  'Marche-en-famenne',
  'Marche-lez-écaussinnes',
  'Marchena',
  'Marcheprime',
  'Marchiennes',
  'Marchissy',
  'Marchtrenk',
  'Marchwood',
  'Marciac',
  'Marcianise',
  'Marcilla',
  'Marcilloles',
  "Marcilly-d'azergues",
  'Marcinelle',
  'Marco',
  'Marco Island',
  'Marcols-les-eaux',
  'Marcon',
  'Marcoola',
  'Marcos Paz',
  'Marcoussis',
  'Marcón',
  'Marcq',
  'Marcq-en-baroeul',
  'Marcus Hook',
  'Marcy',
  "Marcy-l'etoile",
  'Marçon',
  'Mardan Cantonment',
  'Mardela Springs',
  'Marden',
  'Mardin',
  'Marechal Cândido Rondon',
  'Mareil-marly',
  'Marene',
  'Marengo',
  'Marennes',
  'Marest',
  'Mareuil-sur-cher',
  'Marfleet',
  'Margam',
  'Margaret River',
  'Margaretting',
  'Margaretville',
  'Margarita',
  'Margate',
  'Margão',
  'Margencel',
  'Marghera',
  'Marghita',
  'Margny-lès-compiègne',
  'Margraten',
  'Marguerittes',
  'Maria',
  'Maria Enzersdorf',
  'Maria Preta',
  'Mariager',
  'Mariahoop',
  'Mariakerke',
  'Mariana',
  'Marianka',
  'Marianna',
  'Mariano Comense',
  'Mariano Del Friuli',
  'Marianske Lazne',
  'Maribo',
  'Maribor',
  'Maribyrnong',
  'Maricopa',
  'Mariefred',
  'Mariehamn',
  'Marienberg',
  'Mariendorf',
  'Marienfeld',
  'Marienfelde',
  'Marienheide',
  'Marienrachdorf',
  'Marienthal',
  'Marienwerder',
  'Mariestad',
  'Marietta',
  'Marieville',
  'Mariglianella',
  'Marigliano',
  'Marignane',
  'Marignier',
  'Marigny',
  'Marigny-le-cahouet',
  'Marigny-saint-marcel',
  'Marigot',
  'Marija Gorica',
  'Marijampole',
  'Marikina',
  'Marilag',
  'Marilla',
  'Marin',
  'Marina',
  'Marina Del Rey',
  'Marina Di Montemarciano',
  'Marine City',
  'Marinette',
  'Maringá',
  'Marinha Grande',
  'Marinici',
  'Marino',
  'Marion',
  'Marion Bay',
  'Mariposa',
  'Mariupol',
  'Marília',
  'Marín',
  'Mark',
  'Markaryd',
  'Markdale',
  'Markdorf',
  'Marke',
  'Marked Tree',
  'Markelo',
  'Marken',
  'Market Bosworth',
  'Market Deeping',
  'Market Drayton',
  'Market Harborough',
  'Market Rasen',
  'Market Weighton',
  'Markfield',
  'Markgröningen',
  'Markham',
  'Marki',
  'Markinch',
  'Markkleeberg',
  'Markle',
  'Markleville',
  'Marklkofen',
  'Marklowice',
  'Marknesse',
  'Markovci',
  'Markovica',
  'Markópoulo',
  'Markópoulon',
  'Markranstädt',
  'Marks',
  'Marks Tey',
  'Marksville',
  'Markt Erlbach',
  'Markt Indersdorf',
  'Markt Piesting',
  'Markt Rettenbach',
  'Markt Schwaben',
  'Marktbreit',
  'Marktheidenfeld',
  'Marktleuthen',
  'Marktoberdorf',
  'Marktredwitz',
  'Marktrodach',
  'Marl',
  'Marlboro',
  'Marlborough',
  'Marlia',
  'Marlin',
  'Marlow',
  'Marlton',
  'Marly',
  'Marly-le-roi',
  'Marmande',
  'Marmaris',
  'Marmirolo',
  'Marmolejo',
  'Marmora',
  'Marnaz',
  'Marne',
  'Marne-la-vallée',
  'Marnes-la-coquette',
  'Maroggia',
  'Marolles-en-brie',
  'Marols',
  'Marondera',
  'Marong',
  'Maroochydore',
  'Marostica',
  'Maroubra',
  'Maroúli',
  'Marpingen',
  'Marple',
  'Marquain',
  'Marquartstein',
  'Marquesado',
  'Marquette',
  'Marquette Lez Lille',
  'Marquixanes',
  'Marrakech',
  'Marrakesh',
  'Marrangaroo',
  'Marratxi',
  'Marrero',
  'Marrickville',
  'Marriottsville',
  'Mars',
  'Mars Hill',
  'Marsa',
  "Marsac-sur-l'isle",
  'Marsala',
  'Marsannay-la-côte',
  'Marsaskala',
  'Marsberg',
  'Marschacht',
  'Marschall',
  'Marsden Park',
  'Marseillan',
  'Marseille',
  'Marseille-en-beauvaisis',
  'Marseilles',
  'Marseillette',
  'Marshall',
  'Marshall Mountain',
  'Marshalltown',
  'Marshallville',
  'Marshfield',
  'Marshfield Hills',
  'Marshville',
  'Marske-by-the-sea',
  'Marssac-sur-tarn',
  'Marston Green',
  'Marstons Mills',
  'Martano',
  'Martell',
  'Martellago',
  'Martfu',
  'Marthalen',
  'Marthasville',
  'Marthod',
  'Martignacco',
  'Martignas',
  'Martignas-sur-jalle',
  'Martignat',
  'Martigné-briand',
  'Martigny',
  'Martigny-combe',
  'Martigny-croix',
  'Martigues',
  'Martillac',
  'Martin',
  'Martinengo',
  'Martinez',
  'Martinez De Hoz',
  'Martinkylä',
  'Martino',
  'Martinópolis',
  'Martinroda',
  'Martins Ferry',
  'Martinsburg',
  'Martinsried',
  'Martinsville',
  'Martinville',
  'Martínez',
  'Martlesham',
  'Martley',
  'Martock',
  'Marton-in-cleveland',
  'Martorell',
  'Martorellas',
  'Martos',
  'Marugame',
  'Marum',
  'Marumbi',
  'Marunouchi',
  'Maruoka',
  'Marupe',
  'Marvejols',
  'Marwitz',
  'Mary Esther',
  'Mary-sur-marne',
  'Maryborough',
  'Maryland',
  'Maryland Heights',
  'Maryland Line',
  'Marylebone',
  'Marylhurst',
  'Maryport',
  'Marysville',
  'Maryville',
  'Mas Riudoms',
  'Mas-de-londres',
  'Masai',
  'Masaka',
  'Masaki',
  'Masala',
  'Masamagrell',
  'Masanasa',
  'Masaya',
  'Mascalucia',
  'Maschen',
  'Mascot',
  'Mascouche',
  'Maser',
  'Maseru',
  'Masham',
  'Mashantucket',
  'Mashhad',
  'Mashpee',
  'Masi San Giacomo',
  'Masjid Tanah',
  'Masku',
  'Maslak',
  'Masnou',
  'Masnuy-saint-jean',
  'Mason',
  'Mason City',
  'Masonville',
  'Maspalomas',
  'Maspeth',
  'Massa',
  'Massa E Cozzile',
  'Massa Lombarda',
  'Massa Marittima',
  'Massafra',
  'Massagno',
  'Massamá',
  'Massanas',
  'Massanet De La Selva',
  'Massapequa',
  'Massapequa Park',
  'Massarosa',
  'Massena',
  'Massenbachhausen',
  'Massenhoven',
  'Masserano',
  'Massieu',
  'Massieux',
  'Massillon',
  'Massing',
  'Massy',
  'Mastaiciai',
  'Masterton',
  'Mastic',
  'Mastic Beach',
  'Mastrils',
  'Masuda',
  'Masury',
  'Masvingo',
  'Matadepera',
  'Matamoros',
  'Matane',
  'Matang',
  'Mataram',
  'Mataró',
  'Matawan',
  'Matão',
  'Matehuala',
  'Matelândia',
  'Matelica',
  'Matera',
  'Materija',
  'Matfen',
  'Matfors',
  'Mather',
  'Mathern',
  'Mathews',
  'Mathura',
  'Matias Barbosa',
  'Matlock',
  'Mato Grosso',
  'Matos',
  'Matosinhos',
  'Matour',
  'Matraman',
  'Matraville',
  'Matsapha',
  'Matsubara',
  'Matsudo',
  'Matsue',
  'Matsue-shi',
  'Matsumoto',
  'Matsusaka',
  'Matsuura',
  'Matsuyama',
  'Matsuyama-shi',
  'Mattapan',
  'Mattapoisett',
  'Mattawa',
  'Mattawan',
  'Mattegoda',
  'Matten Bei Interlaken',
  'Mattersburg',
  'Matteson',
  'Matthews',
  'Mattighofen',
  'Mattishall',
  'Mattituck',
  'Mattoon',
  'Matulji',
  'Maturín',
  'Matuskovo',
  'Matzingen',
  'Mau',
  'Mauá',
  'Maubeuge',
  'Mauchamps',
  'Mauchenheim',
  'Mauchline',
  'Maucor',
  'Maud',
  'Maudsland',
  'Mauensee',
  'Mauer',
  'Mauer Bei Amstetten',
  'Mauerstetten',
  'Maugansville',
  'Mauguio',
  'Maulbronn',
  'Maulburg',
  'Maulden',
  'Mauldin',
  'Maule',
  'Maulévrier',
  'Maumee',
  'Maumelle',
  'Maungaturoto',
  'Maur',
  'Maureillas',
  'Mauren',
  'Maurens-scopont',
  'Maurepas',
  'Mauriac',
  'Mauriceville',
  'Mauron',
  'Maussane',
  'Maussans',
  'Mauston',
  'Mauves',
  'Mauves-sur-loire',
  'Mavcice',
  'Mawanella',
  'Maxcanú',
  'Maxdorf',
  'Maxéville',
  'Maxhütte-haidhof',
  'Maxton',
  'Maxville',
  'Maxwell',
  'Maxwelton',
  'Mayaguez',
  'Mayan Baruch',
  'Mayen',
  'Mayenne',
  'Mayer',
  'Mayet-de-montagne',
  'Mayetta',
  'Mayfair',
  'Mayfield',
  'Mayflower',
  'Maylands',
  'Maynard',
  'Maynooth',
  'Mayo',
  'Mayodan',
  'Mayor',
  'Maypearl',
  'Mays Landing',
  'Maysville',
  'Mayville',
  'Maywood',
  'Mazama',
  'Mazamitla',
  'Mazara Del Vallo',
  'Mazarrón',
  'Mazatlan',
  'Mazatlán',
  'Mazeikiai',
  'Mazeikiu',
  'Mazères',
  'Mazères-lezons',
  'Mazingarbe',
  'Mazkeret Batya',
  'Mazomanie',
  'Mazon',
  'Mazor',
  'Málaga',
  'Málta',
  'Mályi',
  'Mándra',
  'Mátészalka',
  'Mâcon',
  'Mälarhöjden',
  'Männedorf',
  'Mäntsälä',
  'Mäntyharju',
  'Märsta',
  'Märstetten',
  'Målilla',
  'Målsryd',
  'Måløv',
  'Måløy',
  'Månsarp',
  'Mårslet',
  'Mbabane',
  'Mbale',
  'Mbarara',
  'Mbeya',
  'Mc Adenville',
  'Mc Afee',
  'Mc Connellsburg',
  'Mc Connellsville',
  'Mc Cook',
  'Mc Cordsville',
  'Mc Donald',
  'Mc Graw',
  'Mc Kees Rocks',
  'Mc Lean',
  'Mc Minnville',
  'Mcalester',
  'Mcallen',
  'Mccall',
  'Mccalla',
  'Mccarr',
  'Mcchord Afb',
  'Mcclellan',
  'Mccomb',
  'Mccook',
  'Mccordsville',
  'Mccrory',
  'Mcdonald',
  'Mcdonough',
  'Mcfarland',
  'Mcgaheysville',
  'Mcgraths Hill',
  'Mchenry',
  'Mckees Rocks',
  'Mckeesport',
  'Mckenney',
  'Mckenzie',
  'Mckinleyville',
  'Mckinney',
  'Mckinnon',
  'Mclean',
  'Mcleansville',
  'Mclouth',
  'Mcmasterville',
  'Mcminnville',
  'Mcmurray',
  'Mcpherson',
  'Mcqueeney',
  'Mcsherrystown',
  'Mead',
  'Meadela',
  'Meadow Lake',
  'Meadow Vista',
  'Meadowbank',
  'Meadowbrook',
  'Meadows',
  'Meadows Of Dan',
  'Meadowvale',
  'Meadville',
  'Meaford',
  'Measham',
  'Meaux',
  'Mebane',
  'Mecca',
  'Mechanicsburg',
  'Mechanicsville',
  'Mechanicville',
  'Mechelen',
  'Mechelen-bovelingen',
  'Mechernich',
  'Mechtersen',
  'Mecidiyeköy',
  'Meckenbeuren',
  'Meckenheim',
  'Meckesheim',
  'Mecklenburg',
  'Meco',
  'Meda',
  'Medak',
  'Medan',
  'Medan Dua',
  'Medchal',
  'Meddersheim',
  'Medebach',
  'Medeiros',
  'Medell',
  'Medellín',
  'Medemblik',
  'Medenbach',
  'Medfield',
  'Medford',
  'Media',
  'Medianeira',
  'Mediapolis',
  'Medias',
  'Medicina',
  'Medicine Bow',
  'Medicine Hat',
  'Medicine Lodge',
  'Medina',
  'Medina De Ríoseco',
  'Medina Del Campo',
  'Medinah',
  'Mediona',
  'Medlanky',
  'Medolago',
  'Medolla',
  'Medstead',
  'Medulin',
  'Medvode',
  'Medway',
  'Meeandah',
  'Meeder',
  'Meeker',
  'Meer',
  'Meerane',
  'Meerbeek',
  'Meerbusch',
  'Meerhout',
  'Meerkerk',
  'Meern',
  'Meersburg',
  'Meerssen',
  'Meerut',
  'Meerut City',
  'Meggen',
  'Megiddo',
  'Meguro',
  'Mehr',
  'Mehrauli',
  'Mehring',
  'Mehrstetten',
  'Mehsana',
  'Mehun-sur-yèvre',
  'Meijel',
  'Meilen',
  'Meina',
  'Meine',
  'Meinersen',
  'Meinerzhagen',
  'Meinier',
  'Meiningen',
  'Meinisberg',
  'Meira',
  'Meiringen',
  'Meis',
  'Meisburg',
  'Meise',
  'Meisenheim',
  'Meishan',
  'Meishar',
  'Meisterschwanden',
  'Meitingen',
  'Meizhou',
  'Meißen',
  'Meißenheim',
  'Mejorada Del Campo',
  'Meknès',
  'Melaka',
  'Melano',
  'Melbourn',
  'Melbourne',
  'Melbourne Beach',
  'Melbourne Street Station',
  'Melbu',
  'Melby',
  'Meldola',
  'Meldorf',
  'Melegnano',
  'Melesse',
  'Melfa',
  'Melfi',
  'Melhus',
  'Meliana',
  'Melick',
  'Melikgazi',
  'Melilli',
  'Melissa',
  'Melissant',
  'Melitopol',
  'Melíssia',
  'Melksham',
  'Mellac',
  'Mellansel',
  'Melle',
  'Mellendorf',
  'Mellingen',
  'Mellrichstadt',
  'Mells',
  'Melmerby',
  'Melnik',
  'Melnikova',
  'Melrose',
  'Melrose Park',
  'Mels',
  'Melsbach',
  'Melsdorf',
  'Melsele',
  'Melsonby',
  'Melsungen',
  'Meltem',
  'Meltham',
  'Melton',
  'Melton Mowbray',
  'Melton South',
  'Melun',
  'Melville',
  'Melvindale',
  'Melzo',
  'Meløse',
  'Mem Martins',
  'Memari',
  'Memmingen',
  'Memmingerberg',
  'Memphis',
  'Mena',
  'Menai',
  'Menaldum',
  'Menan',
  'Menasha',
  'Mendaro',
  'Mendavia',
  'Mende',
  'Menden',
  'Mendenhall',
  'Menderes',
  'Mendes',
  'Mendham',
  'Mendig',
  'Mendlesham',
  'Mendocino',
  'Mendon',
  'Mendota',
  'Mendoza',
  'Mendrisio',
  'Menemen',
  'Meneméni',
  'Menen',
  'Mengen',
  'Mengeringhausen',
  'Mengerskirchen',
  'Menges',
  'Mengibar',
  'Mengo',
  'Menheniot',
  'Menidi',
  'Menifee',
  'Menlo Park',
  'Mennecy',
  'Meno',
  'Menominee',
  'Menomonee Falls',
  'Menomonie',
  'Menouf',
  'Mensdorf',
  'Menston',
  'Menteng',
  'Menteng Dua',
  'Menton',
  'Mentone',
  'Mentor',
  'Mentougou',
  'Menziken',
  'Menzingen',
  'Menznau',
  'Meñaca',
  'Meopham',
  'Meppel',
  'Meppen',
  'Mequon',
  'Mer',
  'Meram',
  'Merano',
  'Merate',
  'Merbelsrod',
  'Mercatale',
  'Mercato San Severino',
  'Merced',
  'Mercedes',
  'Mercenasco',
  'Mercer',
  'Mercer Island',
  'Merchantville',
  'Merching',
  'Merchtem',
  'Mercier',
  'Mercin-et-vaux',
  'Mercogliano',
  'Mercuès',
  'Mercy',
  'Merdeka',
  'Mere',
  'Meredith',
  'Meredosia',
  'Merelbeke',
  'Merenberg',
  'Merenschwand',
  'Merewether',
  'Merhavya',
  'Meriden',
  'Meridian',
  'Meridianville',
  'Merikaarto',
  'Merimbula',
  'Mering',
  'Merion Station',
  'Merishausen',
  'Merkendorf',
  'Merksem',
  'Merksplas',
  'Merlimont',
  'Merlin',
  'Merlo',
  'Merna',
  'Mernda',
  'Meron',
  'Merrick',
  'Merricks North',
  'Merrickville',
  'Merrifield',
  'Merrill',
  'Merrillville',
  'Merrimac',
  'Merrimack',
  'Merris',
  'Merritt',
  'Merritt Island',
  'Merrylands',
  'Merseburg',
  'Mersin',
  'Mersing',
  'Merstham',
  'Merston',
  'Mertert',
  'Merthyr Tydfil',
  'Mertingen',
  'Merton',
  'Mertzwiller',
  'Meru',
  'Mervilla',
  'Merzhausen',
  'Merzig',
  'Mesa',
  'Mesa Geitonia',
  'Meschede',
  'Mescherin',
  'Mesilla Park',
  'Meslan',
  'Meslay',
  "Meslin-l'eveque",
  'Mesnil-saint-blaise',
  'Mesolónghi',
  'Mesógeion',
  'Mesquite',
  'Messein',
  'Messel',
  'Messen',
  'Messina',
  'Mesto',
  'Mestre',
  'Mestrino',
  'Meta',
  'Metairie',
  'Metamora',
  'Metamórfosi',
  'Metamórfosis',
  'Meteghan',
  'Metepec',
  'Metheringham',
  'Methil',
  'Methuen',
  'Metow',
  'Metro',
  'Metropolis',
  'Metropolitan',
  'Metsä',
  'Metten',
  'Mettenbach',
  'Mettendorf',
  'Mettenheim',
  'Metter',
  'Mettingen',
  'Mettlach',
  'Mettlen',
  'Mettmann',
  'Mettmenstetten',
  'Metuchen',
  'Metylovice',
  'Metz',
  'Metz-tessy',
  'Metzingen',
  'Meudon',
  'Meulaboh',
  'Meulan',
  'Meulebeke',
  'Meung-sur-loire',
  'Meursault',
  'Meuse',
  'Mevasseret Ziyyon',
  'Mevo Beitar',
  'Mexborough',
  'Mexia',
  'Mexicali',
  'Mexicaltzingo',
  'Mexicanos',
  'Mexico',
  'Mexico Beach',
  'Mexico City',
  'Meximieux',
  'Mexy',
  'Meylan',
  'Meymac',
  'Meyrargues',
  'Meyreuil',
  'Meyrin',
  'Meythet',
  'Meyzieu',
  'Mezer',
  'Mezhdurechensk',
  'Mezica',
  'Mezilesi',
  'Mezirici',
  'Mezokovacshaza',
  'Mezzanego',
  'Mezzano',
  'Mezzolombardo',
  'Mezzovico',
  'Meßkirch',
  'Meßstetten',
  'Mèze',
  'Méaudre',
  'Médine',
  'Mégrine',
  'Méjannes-lès-alès',
  'Mélida',
  'Ménesplet',
  'Ménigoute',
  'Méreau',
  'Mérenvielle',
  'Méricourt',
  'Mérida',
  'Mérignac',
  'Mértola',
  'Méry',
  'Méry-la-bataille',
  'Méry-sur-oise',
  'México',
  'Mézériat',
  "Mi'ilya",
  'Miahuatlán De Porfirio Díaz',
  'Miami',
  'Miami Beach',
  'Miami Lakes',
  'Miami Springs',
  'Miamisburg',
  'Miamitown',
  'Miamiville',
  'Mianyang',
  'Miaoli',
  'Miass',
  'Miastko',
  'Micanopy',
  'Micasasa',
  'Michael',
  'Michalovce',
  'Michelau',
  'Michelbach',
  'Micheldorf',
  'Michelfeld',
  'Michelstadt',
  'Michendorf',
  'Michigan',
  'Michigan Center',
  'Michigan City',
  'Michle',
  'Michoacán De Ocampo',
  'Mickleover',
  'Mickleton',
  'Mid Florida',
  'Mid Missouri',
  'Middelburg',
  'Middelfart',
  'Middelharnis',
  'Middelkerke',
  'Middelstum',
  'Middenmeer',
  'Middle',
  'Middle Grove',
  'Middle Island',
  'Middle Musquodoboit',
  'Middle River',
  'Middle Village',
  'Middleboro',
  'Middlebranch',
  'Middlebrook',
  'Middleburg',
  'Middleburgh',
  'Middlebury',
  'Middlefield',
  'Middleport',
  'Middlesboro',
  'Middlesborough',
  'Middlesbrough',
  'Middlesex',
  'Middleton',
  'Middleton Stoney',
  'Middletown',
  'Middleville',
  'Middlewich',
  'Midhurst',
  'Midland',
  'Midland City',
  'Midland Junction',
  'Midland Park',
  'Midlands',
  'Midleton',
  'Midlothian',
  'Midnapore',
  'Midnapur',
  'Midori',
  'Midrand',
  'Midsayap',
  'Midsomer Norton',
  'Midvale',
  'Midview City',
  'Midway',
  'Midway City',
  'Midwest',
  'Midwest City',
  'Midwoud',
  'Mie',
  'Miedziana Góra',
  'Miedzyrzec Podlaski',
  'Mielec',
  'Miercurea',
  'Miercurea-ciuc',
  'Mieres',
  'Mierlo',
  'Mierlo-hout',
  'Miesbach',
  'Mifflin',
  'Mifflinburg',
  'Mifflintown',
  'Migdal',
  'Migdal Hameq',
  'Migdal Tefen',
  'Miggiano',
  'Migliarino',
  'Migliarino Pisano',
  'Migliaro',
  'Mignanego',
  'Mignano Monte Lungo',
  'Migné-auxances',
  'Mignières',
  'Miguel',
  'Miguel Couto',
  'Miguel Hidalgo',
  'Miguelturra',
  'Mihailesti',
  'Mihama',
  'Mihara',
  'Mihintale',
  'Mihla',
  'Mijas',
  'Mijdrecht',
  'Mijnsheerenland',
  'Mikhmoret',
  'Mikkeli',
  'Miklavz',
  'Mikolow',
  'Mikulov',
  'Mikuruba',
  'Mila',
  'Milaca',
  'Milagro',
  'Milan',
  'Milanese',
  'Milano',
  'Milano Marittima',
  'Milanówek',
  'Milas',
  'Milazzo',
  'Milbank',
  'Milbridge',
  'Mildenau',
  'Mildenhall',
  'Mildura',
  'Mile End',
  'Miles City',
  'Milesburg',
  'Milevsko',
  'Milford',
  'Milford Center',
  'Milford Square',
  'Milheeze',
  'Mililani',
  'Mill',
  'Mill Creek',
  'Mill Hall',
  'Mill Hill',
  'Mill Spring',
  'Mill Valley',
  'Millas',
  'Millau',
  'Millbrae',
  'Millbrook',
  'Millburn',
  'Millbury',
  'Milledgeville',
  'Miller Place',
  'Millersburg',
  'Millersport',
  'Millerstown',
  'Millersville',
  'Millerton',
  'Milligan College',
  'Millington',
  'Millinocket',
  'Millis',
  'Millry',
  'Mills',
  'Mills River',
  'Millsap',
  'Millsboro',
  'Milltown',
  'Millville',
  'Millwood',
  'Milly-la-forêt',
  'Milmort',
  'Milnathort',
  'Milngavie',
  'Milnrow',
  'Milnthorpe',
  'Milonice',
  'Milpa Alta',
  'Milpitas',
  'Miltach',
  'Miltenberg',
  'Milton',
  'Milton Bryant',
  'Milton Ernest',
  'Milton Keynes',
  'Miltonvale',
  'Milverton',
  'Milwaukee',
  'Milwaukie',
  'Mimarsinan',
  'Mimet',
  'Mimeure',
  'Mimizan',
  'Mims',
  'Minamata',
  'Minami',
  'Minami-aoyama',
  'Minami-senju',
  'Minami-shinagawa',
  'Minami-soma',
  'Minamiashigara',
  'Minamiku',
  'Minas De Ríotinto',
  'Minas Gerais',
  'Minato',
  'Mindelheim',
  'Minden',
  'Minderhout',
  'Mindoro',
  'Mine Hill',
  'Mineo',
  'Mineola',
  'Mineral',
  'Mineral De La Reforma',
  'Mineral Springs',
  'Mineral Wells',
  'Minerbe',
  'Minerbio',
  'Minersville',
  'Minerva',
  'Mingguang',
  'Minghang',
  'Mingo Junction',
  'Mingoville',
  'Mingyang',
  'Minhang',
  'Minhotães',
  'Minhou',
  'Minhsiung',
  'Minikowo',
  'Minna',
  'Minneapolis',
  'Minneola',
  'Minneota',
  'Minnesota City',
  'Minnesota Lake',
  'Minnetonka',
  'Minnetonka Beach',
  'Mino',
  'Minobu',
  'Minocqua',
  'Minoh',
  'Minokamo',
  'Minong',
  'Minonk',
  'Minooka',
  'Minori',
  'Minot',
  'Minot Afb',
  'Minowa',
  'Minquan',
  'Minsk',
  'Minsk Mazowiecki',
  'Minster',
  'Mintlaw',
  'Minto',
  'Minturn',
  'Minturno',
  'Minusio',
  'Minworth',
  'Minya',
  'Minyip',
  'Miñano Mayor',
  'Miñano Menor',
  'Mio',
  'Mions',
  'Mioveni',
  'Mira',
  'Mira Loma',
  'Mirabel',
  'Miracema',
  'Miraflores',
  'Miraflores De La Sierra',
  'Miramar',
  'Miramar Beach',
  'Miramichi',
  'Miranda',
  'Miranda De Ebro',
  'Mirandola',
  'Mirano',
  'Miravalles',
  'Mirebeau',
  'Mirefleurs',
  'Miremont',
  'Mireval',
  'Mirfield',
  'Miri',
  'Miribel',
  'Miribel-lanchâtre',
  'Mirków',
  'Mirmande',
  'Mirna',
  'Mirny',
  'Miroslav',
  'Mirosov',
  'Mirow',
  'Mirpur',
  'Mirville',
  'Miryang',
  'Mirzapur',
  'Misaki',
  'Misakubo',
  'Misano Adriatico',
  'Misato',
  'Misenheimer',
  'Miserey',
  'Miserey-salines',
  'Misgav',
  'Misgav Dov',
  'Mishawaka',
  'Mishima',
  'Mishmar David',
  'Mishmar Hanegev',
  'Mishmar Hasharon',
  'Mishmarot',
  'Misinto',
  'Miskolc',
  'Miskovice',
  'Mislata',
  'Misratah',
  'Missão Velha',
  'Mission',
  'Mission Bay',
  'Mission Hill',
  'Mission Hills',
  'Mission Viejo',
  'Mississauga',
  'Mississippi State',
  'Missoula',
  'Missouri City',
  'Missouri Valley',
  'Mistassini',
  'Mistelbach',
  'Mistelgau',
  'Misterton',
  'Mita',
  'Mitaka',
  'Mitcham',
  'Mitcheldean',
  'Mitchell',
  'Mitchelstown',
  'Mitchelton',
  'Mitford',
  'Mitino',
  'Mito',
  'Mitry-mory',
  'Mitsukaido',
  'Mitsuke',
  'Mittelbiberach',
  'Mittelherwigsdorf',
  'Mittelhof',
  'Mittelnkirchen',
  'Mittelstetten',
  'Mittenaar',
  'Mittenwald',
  'Mitterdorf Im Mürztal',
  'Mitterfels',
  'Mitterndorf',
  'Mitterskirchen',
  'Mitterteich',
  'Mittweida',
  'Mitwitz',
  'Miura',
  'Mixcos',
  'Miyada',
  'Miyagi',
  'Miyaji',
  'Miyakojima',
  'Miyakonojo',
  'Miyamae',
  'Miyazaki',
  'Miyazu',
  'Miyoshi',
  'Miyun',
  'Mizpe Ramon',
  'Mizuho',
  'Míkonos',
  'Míres',
  'Mjölby',
  'Mjømna',
  'Mjøndalen',
  'Mlada Boleslav',
  'Mladá Boleslav',
  'Mlade Buky',
  'Mladenovac',
  'Mlawa',
  'Mlynárce',
  'Mnichovice',
  'Mnichovo Hradiste',
  'Mnisek Pod Brdy',
  'Mo I Rana',
  'Moa',
  'Moab',
  'Moaña',
  'Moara Vlasiei',
  'Moate',
  'Mobara',
  'Mobberley',
  'Moberly',
  'Mobile',
  'Mobridge',
  'Mocejón',
  'Mochdre',
  'Mockfjärd',
  'Mocksville',
  'Moctezuma',
  'Moda',
  'Modalen',
  'Modasa',
  'Modbury',
  'Model City',
  'Model Town',
  'Modelu',
  'Modena',
  'Modesto',
  "Modi'in",
  'Modica',
  'Modra',
  'Modrany',
  'Modrice',
  'Modugno',
  'Moelv',
  'Moema',
  'Moen',
  'Moerbeke',
  'Moerdijk',
  'Moergestel',
  'Moerkapelle',
  'Moers',
  'Moers-vinn',
  'Moeskroen',
  'Moffett Field',
  'Moga',
  'Mogadishu',
  'Mogadore',
  'Mogán',
  'Mogi Das Cruzes',
  'Mogi-guaçu',
  'Mogi-mirim',
  'Mogilany',
  'Mogilëv',
  'Mogliano Veneto',
  'Mogoditshane',
  'Mogosoaia',
  'Moguer',
  'Mogyoród',
  'Mohali',
  'Mohammadpur',
  'Mohammedia',
  'Mohawk',
  'Mohács',
  'Mohelnice',
  'Mohlin',
  'Mohlsdorf',
  'Mohnton',
  'Moholm',
  'Moià',
  'Moignelée',
  'Moimacco',
  'Moira',
  'Moirans',
  'Moirans-en-montagne',
  'Moissac',
  'Moissy-cramayel',
  'Moita',
  'Mojave',
  'Mojácar',
  'Moji-mirim',
  'Mojokerto',
  'Mojzesovo',
  'Moka',
  'Mokelumne Hill',
  'Mokena',
  'Mokotow',
  'Mokpo',
  'Mol',
  'Mola Di Bari',
  'Molalla',
  'Mold',
  'Moldava Nad Bodvou',
  'Molde',
  'Moldova',
  'Moledet',
  'Molena',
  'Molenbeek',
  'Molenbeek-saint-jean',
  'Molenhoek',
  'Molenstede',
  'Molenwijk',
  'Molfetta',
  'Molina',
  'Molina De Segura',
  'Molinaro',
  'Moline',
  'Molinella',
  'Molino Del Piano',
  'Molíns De Rey',
  'Molledo',
  'Mollem',
  'Mollens',
  'Mollerusa',
  'Mollet Del Vallès',
  'Mollet del Vallès',
  'Mollis',
  'Molln',
  'Mollösund',
  'Molong',
  'Molsheim',
  'Molveno',
  'Mombasa',
  'Mombuey',
  'Momchilgrad',
  'Momence',
  'Mommenheim',
  'Momo',
  'Mona Vale',
  'Monaca',
  'Monachil',
  'Monaco',
  'Monaghan',
  'Monahans',
  'Monarto',
  'Monash',
  'Monaster',
  'Monasterevin',
  'Monastir',
  'Monbulk',
  'Moncada',
  'Moncalieri',
  'Moncalvo',
  'Moncarapacho',
  'Monchaltorf',
  'Moncks Corner',
  'Monclova',
  'Moncton',
  'Monção',
  'Monções',
  'Monda',
  'Mondercange',
  'Mondeville',
  'Mondésir',
  'Mondion',
  'Mondolfo',
  'Mondorf-les-bains',
  'Mondouzil',
  'Mondovi',
  'Mondragón',
  'Mondsee',
  'Monee',
  'Monein',
  'Monessen',
  'Monett',
  'Monéteau',
  'Monfalcone',
  'Monferrato',
  'Monforte De Lemos',
  'Mong Kok',
  'Mongaup Valley',
  'Mongstad',
  'Monheim',
  'Monica',
  'Monifieth',
  'Monistrol-sur-loire',
  'Moniuszki',
  'Monju',
  'Monk Fryston',
  'Monkhopton',
  'Monkstown',
  'Monkton',
  'Monkwearmouth',
  'Monmouth',
  'Monmouth Beach',
  'Monmouth Junction',
  'Monnaie',
  'Monnickendam',
  'Monnières',
  'Monon',
  'Monona',
  'Monongahela',
  'Monopoli',
  'Monor',
  'Monosson',
  'Monóvar',
  'Monplaisir',
  'Monroe',
  'Monroe Township',
  'Monroeville',
  'Monrovia',
  'Mons',
  'Mons-en-baroeul',
  'Monsano',
  'Monschau',
  'Monselice',
  'Monsey',
  'Monsheim',
  'Monsols',
  'Monson',
  'Monstad',
  'Monster',
  'Monsummano Terme',
  'Monsures',
  'Mont Belvieu',
  'Mont Clare',
  'Mont Kigali',
  'Mont-de-marsan',
  'Mont-dore',
  'Mont-louis',
  'Mont-royal',
  'Mont-saint-aignan',
  'Mont-saint-andré',
  'Mont-saint-guibert',
  'Mont-saint-hilaire',
  'Mont-sur-marchienne',
  'Mont-tremblant',
  'Montabaur',
  'Montady',
  'Montagnana',
  'Montagnola',
  'Montagnole',
  'Montagny',
  'Montagu',
  'Montague',
  'Montaigu',
  'Montaigu-de-quercy',
  'Montana',
  'Montanay',
  'Montaña',
  'Montardon',
  'Montargis',
  'Montarnaud',
  'Montastruc-la-conseillère',
  'Montataire',
  'Montauban',
  'Montauban-de-picardie',
  'Montauk',
  'Montauroux',
  'Montazels',
  'Montbazens',
  'Montbazon',
  'Montberon',
  'Montbéliard',
  'Montblanc',
  'Montbonnot',
  'Montbonnot-saint-martin',
  'Montboucher-sur-jabron',
  'Montbrió De La Marca',
  'Montbrison',
  'Montbronn',
  'Montceau-les-mines',
  'Montchanin',
  'Montclair',
  'Montcornet',
  'Monte Azul Paulista',
  'Monte Compatri',
  'Monte Grande',
  'Monte Marenzo',
  'Monte Negro',
  'Monte Porzio Catone',
  'Monte Rio',
  'Monte San Giovanni Campano',
  'Monte San Giusto',
  'Monte San Pietro',
  'Monte San Vito',
  'Monte Urano',
  'Monte Vista',
  'Montebello',
  'Montebello Vicentino',
  'Montebelluna',
  'Montecalvo In Foglia',
  'Montecalvoli',
  'Montecarlo',
  'Montecassiano',
  'Montecatini Terme',
  'Montecchio',
  'Montecchio Emilia',
  'Montecchio Maggiore',
  'Montecelio',
  'Montechiarugolo',
  'Montecorvino Rovella',
  'Montecristi',
  'Montego Bay',
  'Montegranaro',
  'Montegrotto Terme',
  'Montelabbate',
  'Montelavar',
  'Montello',
  'Montelupo Fiorentino',
  'Montemayor',
  'Montemor-o-novo',
  'Montemorelos',
  'Montemurlo',
  'Montemuro',
  'Montenegro',
  'Montenero',
  'Montenescourt',
  'Monteprandone',
  'Montepulciano',
  'Montereale Valcellina',
  'Montereau',
  'Monterey',
  'Monterey Park',
  'Monteriggioni',
  'Montería',
  'Monterone',
  "Monteroni D'arbia",
  'Monteroni Di Lecce',
  'Monterotondo',
  'Monterrey',
  'Montes Claros',
  'Montesilvano',
  'Montesquieu',
  'Montesquieu-lauragais',
  'Montesquieu-volvestre',
  'Montesson',
  'Monteux',
  'Montevallo',
  'Montevarchi',
  'Monteveglio',
  'Monteviale',
  'Montevideo',
  'Montevidéo',
  'Montezuma',
  'Montélier',
  'Montélimar',
  'Montévrain',
  'Montfaucon-en-velay',
  'Montfavet',
  'Montfermeil',
  'Montferrand-le-château',
  'Montferrier',
  'Montferrier-sur-lez',
  'Montfoort',
  'Montfort',
  "Montfort-l'amaury",
  'Montfort-sur-argens',
  'Montgat',
  'Montgermont',
  'Montgeron',
  'Montgiscard',
  'Montgomery',
  'Montgomery Village',
  'Montgomeryville',
  'Monthey',
  "Monticelli D'ongina",
  'Monticello',
  'Monticello Conte Otto',
  'Montichiari',
  'Montierchaume',
  'Montignies-sur-sambre',
  'Montigny',
  'Montigny-le-bretonneux',
  'Montigny-lès-cormeilles',
  'Montigny-lès-metz',
  'Montijo',
  'Montilla',
  'Montilliers',
  'Montivilliers',
  'Montlebon',
  'Montlhéry',
  'Montlignon',
  'Montlouis-sur-loire',
  'Montluçon',
  'Montmagny',
  'Montmarault',
  'Montmeló',
  'Montmélian',
  'Montmirail',
  'Montmorency',
  'Montney',
  'Montodine',
  'Montoggio',
  'Montoir-de-bretagne',
  'Montoire-sur-le-loir',
  'Montorio Veronese',
  'Montornés Del Vallés',
  'Montoro',
  'Montorso',
  'Montour Falls',
  'Montoursville',
  'Montpelier',
  'Montpellier',
  'Montrabé',
  'Montreal',
  'Montreat',
  'Montreuil',
  "Montreuil-l'argille",
  'Montreuil-sous-bois',
  'Montreuil-sur-barse',
  'Montreux',
  'Montrevel',
  'Montréal',
  'Montréal-est',
  'Montréal-ouest',
  'Montrond-les-bains',
  'Montrose',
  'Montrouge',
  'Monts',
  'Montsoult',
  'Montsûrs',
  'Montussan',
  'Montvale',
  'Montverde',
  'Montville',
  'Monument',
  'Monza',
  'Monzón',
  'Moody',
  'Mookhoek',
  'Mooloolaba',
  'Moon',
  'Moonachie',
  'Moonan Flat',
  'Moonee Ponds',
  'Moonwalk Subdivision',
  'Moora',
  'Moorabbin',
  'Moordrecht',
  'Moore',
  'Moore Park',
  'Moorefield',
  'Mooreland',
  'Moorestown',
  'Mooresville',
  'Moorhead',
  'Moorook',
  'Mooroolbark',
  'Moorpark',
  'Moorrege',
  'Moorsel',
  'Moorsele',
  'Moorslede',
  'Moorthorpe',
  'Moos',
  'Moosach',
  'Moosbierbaum',
  'Moosburg',
  'Moosburg An Der Isar',
  'Moose Creek',
  'Moose Jaw',
  'Moose Lake',
  'Moosic',
  'Moosinning',
  'Moosleerau',
  'Moosomin',
  'Moosup',
  'Mora',
  'Moradabad',
  'Moraga',
  'Moral De Calatrava',
  'Moraleja De Enmedio',
  'Moralzarzal',
  'Moran',
  'Morang',
  'Morangis',
  'Moratalaz',
  'Moratuwa',
  'Moravany',
  'Moravce',
  'Moravian Falls',
  'Moravicany',
  'Moravska Ostrava',
  'Moravska Trebova',
  'Moravské Lieskové',
  'Moravsky Pisek',
  'Morbach',
  'Morbegno',
  'Morbi',
  'Morbio',
  'Morbio Superiore',
  'Morciano Di Romagna',
  'Morcin',
  'Morcote',
  'Mordelles',
  'Morden',
  'Mordialloc',
  'Morebattle',
  'Morecambe',
  'Morehead',
  'Morehead City',
  'Moreira',
  'Moreland',
  'Morelia',
  'Morelos',
  'Morena',
  'Morenci',
  'Moreno',
  'Moreno Valley',
  'Morens',
  'Morestel',
  'Moret-sur-loing',
  'Moreton',
  'Moreton In Marsh',
  'Moreton Shire',
  'Moretonhampstead',
  'Moreuil',
  'Morez',
  'Morgan',
  'Morgan City',
  'Morgan Hill',
  'Morganton',
  'Morgantown',
  'Morganville',
  'Morges',
  'Morgongåva',
  'Morhange',
  'Mori',
  'Moriarty',
  'Moriches',
  'Moriguchi',
  'Moringen',
  'Morinville',
  'Morioka',
  'Morioka-shi',
  'Moritzburg',
  'Moriya',
  'Moriyama',
  'Morkov',
  'Morkovice',
  'Morlaix',
  'Morley',
  'Morlhon-le-haut',
  'Mormant',
  'Mormugao',
  'Mornago',
  'Mornant',
  'Morning Sun',
  'Morning View',
  'Morningside',
  'Mornington',
  'Morocco',
  'Morocco City',
  'Moroges',
  'Morolo',
  'Moroni',
  'Moroyama',
  'Morón De La Frontera',
  'Morpeth',
  'Morphett Vale',
  'Morra De Sanctis',
  'Morrilton',
  'Morris',
  'Morris Plains',
  'Morrison',
  'Morrisonville',
  'Morriston',
  'Morristown',
  'Morrisville',
  'Morro Bay',
  'Morrow',
  'Morsang-sur-orge',
  'Morsbach',
  'Morschach',
  'Morschwil',
  'Mortagne-au-perche',
  'Mortagne-sur-sèvre',
  'Mortara',
  'Mortdale',
  'Morteau',
  'Mortegliano',
  'Mortera',
  'Morteros',
  'Mortimer Common',
  'Mortlake',
  'Morton',
  'Morton Grove',
  'Mortsel',
  'Moruya',
  'Morvillars',
  'Morwell',
  'Morzine',
  'Mos',
  'Mosbach',
  'Moscenicka Draga',
  'Moscháto',
  "Mosciano Sant'angelo",
  'Mosciska',
  'Moscou',
  'Moscow',
  'Moscow Mills',
  'Moseley',
  'Moses Lake',
  'Mosfell',
  'Mosgiel',
  'Moshava',
  'Moshi',
  'Mosier',
  'Mosina',
  'Mosinee',
  'Mosjøen',
  'Moskau',
  'Moskháton',
  'Moskva',
  'Mosman',
  'Mosnang',
  'Mosonmagyaróvár',
  'Mosquera',
  'Moss',
  'Moss Beach',
  'Moss Landing',
  'Moss Vale',
  'Mossa',
  'Mossley',
  'Mossman',
  'Mossoró',
  'Most',
  'Mosta',
  'Mostar',
  'Moste',
  'Mostová',
  'Moszczenica',
  'Motai',
  'Motala',
  'Motherwell',
  'Motiers',
  'Motilla Del Palancar',
  'Motley',
  'Motomachi',
  'Motosu',
  'Motovun',
  'Motril',
  'Motru',
  'Motta Di Livenza',
  'Mouans-sartoux',
  'Mouchin',
  'Moudon',
  'Mouen',
  'Mougins',
  'Mougon',
  'Mouilleron-le-captif',
  'Moulineaux',
  'Moulins',
  'Moulsford',
  'Moult',
  'Moulton',
  'Moultonborough',
  'Moultrie',
  'Mound',
  'Mound City',
  'Moundridge',
  'Mounds',
  'Moundville',
  'Mount',
  'Mount Airy',
  'Mount Albert',
  'Mount Angel',
  'Mount Arlington',
  'Mount Aukum',
  'Mount Barker',
  'Mount Berry',
  'Mount Braddock',
  'Mount Carmel',
  'Mount Carroll',
  'Mount Clemens',
  'Mount Crawford',
  'Mount Dora',
  'Mount Eliza',
  'Mount Evelyn',
  'Mount Gambier',
  'Mount Gay',
  'Mount Gilead',
  'Mount Gravatt',
  'Mount Helen',
  'Mount Holly',
  'Mount Hood Parkdale',
  'Mount Hope',
  'Mount Horeb',
  'Mount Jackson',
  'Mount Joy',
  'Mount Juliet',
  'Mount Kisco',
  'Mount Laurel',
  'Mount Liberty',
  'Mount Lofty',
  'Mount Marion',
  'Mount Martha',
  'Mount Maunganui',
  'Mount Morris',
  'Mount Olive',
  'Mount Olivet',
  'Mount Orab',
  'Mount Orford',
  'Mount Pearl',
  'Mount Perry',
  'Mount Pleasant',
  'Mount Pleasant Mills',
  'Mount Pocono',
  'Mount Prospect',
  'Mount Pulaski',
  'Mount Rainier',
  'Mount Saint Joseph',
  'Mount Shasta',
  'Mount Sinai',
  'Mount Solon',
  'Mount Sterling',
  'Mount Stewart',
  'Mount Tamborine',
  'Mount Tyson',
  'Mount Uniacke',
  'Mount Union',
  'Mount Vernon',
  'Mount Washington',
  'Mount Waverley',
  'Mount Wolf',
  'Mount-royal',
  'Mountain',
  'Mountain Ash',
  'Mountain City',
  'Mountain Grove',
  'Mountain Home',
  'Mountain Lakes',
  'Mountain Pass',
  'Mountain Top',
  'Mountain View',
  'Mountain Village',
  'Mountainburg',
  'Mountainhome',
  'Mountainside',
  'Mountainville',
  'Mountlake Terrace',
  'Mountnessing',
  'Mountshannon',
  'Mountsorrel',
  'Mountville',
  'Mourilyan',
  'Mours-saint-eusèbe',
  'Mouscron',
  'Moussy-le-neuf',
  'Moustey',
  'Moustier',
  'Moutier',
  'Mouvaux',
  'Mouy',
  'Mouzon',
  'Movelier',
  'Moville',
  'Mowbray',
  'Moxee',
  'Moyers',
  'Moyock',
  'Moza',
  'Mozelos',
  'Mozé-sur-louet',
  'Mozzate',
  'Mór',
  'Mórahalom',
  'Móstoles',
  'Möckmühl',
  'Mödling',
  'Mögendorf',
  'Möglingen',
  'Möhlin',
  'Möhnesee',
  'Möhrendorf',
  'Mölln',
  'Mölltorp',
  'Mölndal',
  'Mölnlycke',
  'Mömbris',
  'Mömlingen',
  'Mönchaltorf',
  'Mönchengladbach',
  'Mönsterås',
  'Mörbylånga',
  'Mörfelden-walldorf',
  'Mörigen',
  'Möriken',
  'Mörlenbach',
  'Mörrum',
  'Mörschbach',
  'Mörschwil',
  'Möser',
  'Mössingen',
  'Mpigi',
  'Mragowo',
  'Mratin',
  'Msasani',
  'Msciwojow',
  'Msida',
  'Mszana',
  'Mt Pleasant',
  'Mt. Clemens',
  'Mtsensk',
  'Muak Lek',
  'Muang Krabi',
  'Muang Nontha Buri',
  'Muang Pang',
  'Muang Phrae',
  'Muang Rayong',
  'Muang Samut Prakan',
  'Muar',
  'Muara',
  'Much',
  'Mucugê',
  'Mucuri',
  'Mudanjiang',
  'Mudanya',
  'Mudersbach',
  'Mudgee',
  'Mudgeeraba',
  'Mueang Chiang Mai',
  'Mueang Nakhon Ratchasima',
  'Mueang Nonthaburi',
  'Mueang Phuket',
  'Mueang Samut Sakhon',
  'Muehlhausen',
  'Muenchen',
  'Muensingen',
  'Muenster',
  'Mugardos',
  'Muggensturm',
  'Muggia',
  'Muggio',
  'Muggiò',
  'Mugla',
  'Muglinov',
  'Mugnano Di Napoli',
  'Muhen',
  'Muhos',
  'Muiden',
  'Muir Of Ord',
  'Muizen',
  'Muizon',
  'Mujur',
  'Mukacheve',
  'Mukachevo',
  'Mukarov',
  'Mukdong',
  'Mukilteo',
  'Muko',
  'Mukono',
  'Mukwonago',
  'Mulago',
  'Mulbagal',
  'Mulberry',
  'Mulfingen',
  'Mulgrave',
  'Mulhouse',
  'Mulino',
  'Mullan',
  'Mullens',
  'Mullhyttan',
  'Mullica Hill',
  'Mullingar',
  'Mullins',
  'Mullsjö',
  'Mullumbimby',
  'Multan',
  'Multyfarnham',
  'Mulvane',
  'Mumbai',
  'Munchenstein',
  'Munchwilen',
  'Muncie',
  'Muncy',
  'Mundaring',
  'Mundelein',
  'Mundelsheim',
  'Munderkingen',
  'Mundolsheim',
  'Munford',
  'Mungindi',
  'Munguía',
  'Munich',
  'Munising',
  'Munith',
  'Munjeongildong',
  'Munka-ljungby',
  'Munkebo',
  'Munkedal',
  'Munkfors',
  'Munro',
  'Munroe Falls',
  'Munsingen',
  'Munster',
  'Munsterbilzen',
  'Muntinlupa',
  'Muntinlupa City',
  'Muotathal',
  'Muping',
  'Mura',
  'Murakami',
  "Murang'a",
  'Murano',
  'Muratpasa',
  'Murchin',
  'Murcia',
  'Muret',
  'Murfreesboro',
  'Murg',
  'Murgenthal',
  'Muri',
  'Muri Bei Bern',
  'Muriaé',
  'Muribeca',
  'Murlin',
  'Murmansk',
  'Murnau',
  'Murnau Am Staffelsee',
  'Muro',
  'Muromachi',
  'Muros',
  'Murphy',
  'Murphys',
  'Murr',
  'Murray',
  'Murray Bridge',
  'Murrayville',
  'Murrells Inlet',
  'Murrhardt',
  'Murrieta',
  'Murrumbeena',
  'Murrysville',
  'Murska Sobota',
  'Murtaugh',
  'Murtede',
  'Murten',
  'Murter',
  'Murviel-lès-montpellier',
  'Murwillumbah',
  'Murzhofen',
  'Musaffah',
  'Musashino',
  'Muscat',
  'Muscatine',
  'Muscle Shoals',
  'Museros',
  'Mushin',
  'Musi',
  'Music',
  'Musiri',
  'Muskego',
  'Muskegon',
  'Muskogee',
  'Muskoka',
  'Mussel',
  'Musselburgh',
  'Musselkanaal',
  'Musselshell',
  'Mussolino',
  'Mussoorie',
  'Mussy-sur-seine',
  'Mustafa',
  'Mustafabad',
  'Mustang',
  'Mustasaari',
  'Muswellbrook',
  'Mutare',
  'Muthaiga',
  'Mutilva Alta',
  'Mutilva Baja',
  'Mutis',
  'Mutlangen',
  'Mutsamudu',
  'Muttenz',
  'Mutterstadt',
  'Muttrah',
  'Mutzig',
  'Muuga',
  'Muurame',
  'Muvatupuzha',
  'Muzaffarnagar',
  'Muzaffarpur',
  'Mûrs-Érigné',
  'Mücheln',
  'Müden',
  'Mühbrook',
  'Mühlacker',
  'Mühlau',
  'Mühldorf',
  'Mühleberg',
  'Mühlen',
  'Mühlenbeck',
  'Mühlhausen',
  'Mühlhausen-ehingen',
  'Mühlheim',
  'Mühltal',
  'Mühltroff',
  'Mülheim',
  'Mülheim An Der Ruhr',
  'Mülheim-kärlich',
  'Müllheim',
  'Müllrose',
  'Mülsen',
  'Münchberg',
  'München',
  'Münchenbuchsee',
  'Münchenstein',
  'Münchsried',
  'Münchwilen',
  'Münden',
  'Münichham',
  'Münnerstadt',
  'Münsbach',
  'Münsing',
  'Münsingen',
  'Münster',
  'Münster-sarmsheim',
  'Münsterlingen',
  'Münstermaifeld',
  'Münzenberg',
  'Mürlenbach',
  'Mwanza',
  'Mwenge',
  'Myakka City',
  'Myers Flat',
  'Myerstown',
  'Myjava',
  'Mykolayiv',
  'Mylapore',
  'Mylau',
  'Mylor',
  'Mymensingh',
  'Myoko',
  'Myrhorod',
  'Myrtle Beach',
  'Myrtle Creek',
  'Myrtleford',
  'Mysen',
  'Myshall',
  'Mysiadlo',
  'Myslenice',
  'Myslowice',
  'Mysore',
  'Mystic',
  'Mysuru',
  'Mytchett',
  'Mytholmroyd',
  'Mytilene',
  'Mytishchi',
  'Mytishi',
  'Mýkonos',
  'Mørkøv',
  'N Charleston',
  "N'djamena",
  "Na'an",
  'Naaldwijk',
  'Naantali',
  'Naarden',
  'Naas',
  'Nabari',
  'Nabburg',
  'Naberezhnaya',
  'Naberezhnyye Chelny',
  'Nabeul',
  'Naches',
  'Nachod',
  'Nacional',
  'Nacka',
  'Nackenheim',
  'Nacogdoches',
  'Nadap',
  'Nadarzyn',
  'Nadia',
  'Nadiad',
  'Nadlac',
  'Naga City',
  'Nagahama',
  'Nagai',
  'Nagamachi',
  'Nagano',
  'Nagano-shi',
  'Naganohara',
  'Nagaoka',
  'Nagapattinam',
  'Nagar',
  'Nagareyama',
  'Nagasaki',
  'Nagasaki-shi',
  'Nagata',
  'Nagatacho',
  'Nagda',
  'Nagel',
  'Nagercoil',
  'Nago',
  'Nagold',
  'Nagothana',
  'Nagoya',
  'Nagpur',
  'Nags Head',
  'Nagu',
  'Nagyatád',
  'Nagykanizsa',
  'Nagykálló',
  'Nagykoros',
  'Nagymaros',
  'Nagymányok',
  'Nagypeterd',
  'Nagytarcsa',
  'Nagyvenyim',
  'Naha',
  'Naha-shi',
  'Nahal Oz',
  'Nahalal',
  'Naham',
  'Nahan',
  'Nahariya',
  'Nahariyya',
  'Nahe',
  'Nahsholim',
  'Nahshon',
  'Nahunta',
  'Naihati',
  'Naila',
  'Nailsworth',
  'Naini Tal',
  'Naintré',
  'Nairn',
  'Nairobi',
  'Nairobi South',
  'Nairobi West',
  'Naivasha',
  'Najafgarh',
  'Najran',
  'Naju',
  'Naka-gumi',
  'Nakagawa',
  'Nakagusuku',
  'Nakahara',
  'Nakaku',
  'Nakamura',
  'Nakano',
  'Nakanoku',
  'Nakanoshima',
  'Nakatsu',
  'Nakatsugawa',
  'Nakayama',
  'Nakhon Pathom',
  'Nakhon Ratchasima',
  'Naklo',
  'Nakskov',
  'Nakuru',
  'Nalagarh',
  'Nalbari',
  'Nam Dinh',
  'Namakkal',
  'Nambour',
  'Namchang',
  'Namdong',
  'Namerikawa',
  'Namest Nad Oslavou',
  'Namhyeondong',
  'Namiquipa',
  'Nampa',
  'Nampula',
  'Namsandong',
  'Namsos',
  'Namur',
  'Namyang',
  'Namyangju',
  'Namyslow',
  'Nana Glen',
  'Nanaimo',
  'Nanan',
  'Nanchang',
  'Nanchangshi',
  'Nanchong',
  'Nancras',
  'Nancy',
  'Nancy-sur-cluses',
  'Nançay',
  'Nanded',
  'Nandlstadt',
  'Nandrin',
  'Nandurbar',
  'Nangang',
  'Nangis',
  'Nangloi',
  'Nanhai',
  'Nanhui',
  'Naninne',
  'Nanital',
  'Nanjangud',
  'Nanjemoy',
  'Nanjin',
  'Nanjing',
  'Nankai',
  'Nankang',
  'Nanning',
  'Nanning Shi',
  'Nanping',
  'Nanqiao',
  'Nansha',
  'Nanshan',
  'Nanshanqu',
  'Nant-le-petit',
  'Nanteau-sur Essonne',
  'Nanterre',
  'Nantes',
  'Nanteuil-lès-meaux',
  'Nantey',
  'Nantgarw',
  'Nanticoke',
  'Nanto',
  'Nanton',
  'Nantong',
  'Nantou',
  'Nantucket',
  'Nantwich',
  'Nantycaws',
  'Nanuet',
  'Nanxiong',
  'Nanyang',
  'Nanyuki',
  'Naogaon',
  'Napa',
  'Napajedla',
  'Napanee',
  'Naperville',
  'Napier',
  'Napierville',
  'Naples',
  'Napoleon',
  'Napoleonville',
  'Napoli',
  'Nappanee',
  'Napradea',
  'Napton On The Hill',
  'Nara',
  'Nara-shi',
  'Narahenpita',
  'Narangba',
  'Narashino',
  'Narayanganj',
  'Narberth',
  'Narbonne',
  'Narborough',
  'Narela',
  'Narellan',
  'Narembeen',
  'Narita',
  'Narkaus',
  'Narlidere',
  'Narni',
  'Naro',
  'Naro Moru',
  'Naroda',
  'Narok',
  'Narooma',
  'Narón',
  'Narrabeen',
  'Narragansett',
  'Narre Warren',
  'Narrosse',
  'Narrow Neck',
  'Narrows',
  'Narsapur',
  'Narsinghpur',
  'Narumi',
  'Naruto',
  'Narva',
  'Narvik',
  'Narvon',
  'Narwana',
  'Naryan-mar',
  'Narzole',
  'Nasarawa',
  'Nasenberg',
  'Nash',
  'Nashotah',
  'Nashua',
  'Nashville',
  'Nasik',
  'Nasr',
  'Nasr City',
  'Nasrapur',
  'Nassau',
  'Nassen',
  'Nassogne',
  'Nastätten',
  'Nastola',
  'Nasushiobara',
  'Natal',
  'Natales',
  'Natanya',
  'Natchez',
  'Natchitoches',
  'Naters',
  'Nathalia',
  'Natick',
  'National',
  'National City',
  'Natori',
  'Natrona Heights',
  'Natsrat Ilit',
  'Nattenheim',
  'Nattheim',
  'Naucalpan',
  'Naucalpan De Juárez',
  'Nauen',
  'Naugatuck',
  'Nauheim',
  'Naujan-et-postiac',
  'Naul',
  'Naumburg',
  'Naumburg/saale',
  'Nauroth',
  'Nausitz',
  'Nava',
  'Navacchio',
  'Navaconcejo',
  'Navalcarnero',
  'Navalmoral De La Mata',
  'Navan',
  'Navarcles',
  'Navarre',
  'Navarrete',
  'Navarrés',
  'Navasota',
  'Nave',
  'Navegantes',
  'Naveil',
  'Navenby',
  'Navis',
  'Navsari',
  'Navsi',
  'Nawa',
  'Nawabganj',
  'Nawabshah',
  'Nawala',
  'Naxi',
  'Naxxar',
  'Nay',
  'Nayarit',
  'Nazareth',
  'Nazareth Illit',
  'Nazaré',
  'Nazaré Paulista',
  'Nazarje',
  'Nazeing',
  'Nazelles',
  'Nazimabad',
  'Nádudvar',
  'Nájera',
  'Náklo',
  'Námestovo',
  'Nápoles',
  'Náquera',
  'Náxos',
  'Näfels',
  'Nämdö',
  'Nänikon',
  'Närpes',
  'Näsåker',
  'Näsbypark',
  'Nässjö',
  'Näsviken',
  'Nättraby',
  'Ndola',
  'Neath',
  'Neauphle-le-vieux',
  'Neauphlette',
  'Nebahovy',
  'Nebikon',
  'Nebo',
  'Nebraska City',
  'Necedah',
  'Neckarbischofsheim',
  'Neckargemünd',
  'Neckarsulm',
  'Neckartailfingen',
  'Neckartenzlingen',
  'Neckarweihingen',
  'Neckarwestheim',
  'Neckenmarkt',
  'Nedenes',
  'Neder-over-heembeek',
  'Nederasselt',
  'Nederhemert-zuid',
  'Nederhorst Den Berg',
  'Nederland',
  'Nederweert',
  'Nederzwalm-hermelgem',
  'Nedlands',
  'Nedrow',
  'Needham',
  'Needham Heights',
  'Needham Market',
  'Needles',
  'Neenah',
  'Neenstetten',
  'Neepawa',
  'Neer',
  'Neerijnen',
  'Neerpelt',
  'Neeses',
  'Neffs',
  'Neftekamsk',
  'Neftenbach',
  'Negishi',
  'Negombo',
  'Negotino',
  'Negoya',
  'Nehalim',
  'Nehren',
  'Nehvizdy',
  'Neidling',
  'Neihu',
  'Neijiang',
  'Neillsville',
  'Neimeng',
  'Neitersen',
  'Neiva',
  'Neive',
  'Nekla',
  'Nekoosa',
  'Nelahozeves',
  'Nelas',
  'Nellmersbach',
  'Nellore',
  'Nellysford',
  'Nelson',
  'Nelson Bay',
  'Nelsonville',
  'Nelspruit',
  'Nembro',
  'Nemencine',
  'Nemesvámos',
  'Nemochovice',
  'Nemoto',
  'Nemours',
  'Nempont',
  'Nemsova',
  'Nenagh',
  'Nendaz',
  'Nenndorf',
  'Neodesha',
  'Neoga',
  'Neosho',
  'Nepalganj',
  'Nepean',
  'Nephi',
  'Nepi',
  'Neponset',
  'Neptune',
  'Neptune Beach',
  'Nerang',
  'Neresheim',
  'Nereto',
  'Nerima',
  'Nerja',
  'Nersingen',
  'Nerviano',
  'Nes Ziyona',
  'Nes Ziyyona',
  'Nesapakkam',
  'Nesbit',
  'Nesbru',
  'Neschen',
  'Nesconset',
  'Nesher',
  'Neshkoro',
  'Neskaupstaður',
  'Nesle',
  'Nesles-la-vallée',
  'Nesna',
  'Nesoddtangen',
  'Nesquehoning',
  'Ness Ziona',
  'Nesslau',
  'Neston',
  'Nesttun',
  'Nesvady',
  'Netanya',
  'Nether Alderley',
  'Netherlands',
  'Netherton',
  'Nethertown',
  'Netiv Haasara',
  'Netivot',
  'Netolice',
  'Netphen',
  'Netstal',
  'Netterden',
  'Nettersheim',
  'Nettetal',
  'Nettleham',
  'Nettleton',
  'Neu Isenburg',
  'Neu Kaliß',
  'Neu Sankt Johann',
  'Neu Wulmstorf',
  'Neu-anspach',
  'Neu-ulm',
  'Neubeuern',
  'Neubiberg',
  'Neubrandenburg',
  'Neuburg',
  'Neuburg An Der Donau',
  'Neuchatel',
  'Neuchâtel',
  'Neudenau',
  'Neudietendorf',
  'Neudorf',
  'Neudorfl',
  'Neudrossenfeld',
  'Neuenburg',
  'Neuenburg Am Rhein',
  'Neuendettelsau',
  'Neuengönna',
  'Neuenhagen',
  'Neuenhaus',
  'Neuenhof',
  'Neuenkirch',
  'Neuenkirchen',
  'Neuenmarkt',
  'Neuenrade',
  'Neuensalz',
  'Neuenstadt',
  'Neuenstein',
  'Neuental',
  'Neufahrn',
  'Neufahrn Bei Freising',
  'Neufchâteau',
  'Neufchâtel-en-bray',
  'Neufvilles',
  'Neuhaus',
  'Neuhaus Am Inn',
  'Neuhaus Am Rennweg',
  'Neuhaus-schierschnitz',
  'Neuhausen',
  'Neuhausen Am Rheinfall',
  'Neuhausen Ob Eck',
  'Neuheim',
  'Neuherberg',
  'Neuhof',
  'Neuhofen',
  'Neuhofen An Der Krems',
  'Neuilly',
  'Neuilly-en-thelle',
  'Neuilly-le-brignon',
  'Neuilly-plaisance',
  'Neuilly-sous-clermont',
  'Neuilly-sur-marne',
  'Neuilly-sur-seine',
  'Neukamperfehn',
  'Neukirchen',
  'Neukirchen Vorm Wald',
  'Neukirchen-vluyn',
  'Neukölln',
  'Neulingen',
  'Neulußheim',
  'Neumagen-dhron',
  'Neumark',
  'Neumarkt',
  'Neumarkt In Der Oberpfalz',
  'Neumünster',
  'Neunburg Vorm Wald',
  'Neung-sur-beuvron',
  'Neungdong',
  'Neunkirch',
  'Neunkirchen',
  'Neuötting',
  'Neuquen',
  'Neuquén',
  'Neuried',
  'Neuruppin',
  'Neusäß',
  'Neuss',
  'Neustadt',
  'Neustadt Am Main',
  'Neustadt Am Rübenberge',
  'Neustadt An Der Aisch',
  'Neustadt An Der Donau',
  'Neustadt An Der Orla',
  'Neustadt An Der Weinstraße',
  'Neustadt Bei Coburg',
  'Neustadt In Holstein',
  'Neustadt-glewe',
  'Neustetten',
  'Neustrelitz',
  'Neutal',
  'Neutral Bay',
  'Neutraubling',
  'Neuville',
  'Neuville-aux-bois',
  'Neuville-du-poitou',
  'Neuville-en-avesnois',
  'Neuville-en-ferrain',
  'Neuville-saint-rémy',
  'Neuville-sur-ain',
  'Neuville-sur-oise',
  'Neuville-sur-saône',
  'Neuville-sur-seine',
  'Neuvy-deux-clochers',
  'Neuvy-le-roi',
  'Neuweiler',
  'Neuwied',
  'Neuwürschnitz',
  'Nevada',
  'Nevada City',
  'Neve Ilan',
  'Neve Yaakov',
  'Nevele',
  'Neveronys',
  'Nevers',
  'Neves',
  'Nevé Shalom',
  'Nevinnomyssk',
  'Nevsehir',
  'New Albany',
  'New Alresford',
  'New Auburn',
  'New Augusta',
  'New Baltimore',
  'New Barnet',
  'New Bedfont',
  'New Bedford',
  'New Berlin',
  'New Bern',
  'New Bethlehem',
  'New Bloomfield',
  'New Boston',
  'New Braunfels',
  'New Bremen',
  'New Brighton',
  'New Britain',
  'New Brunswick',
  'New Buffalo',
  'New Canaan',
  'New Caney',
  'New Carlisle',
  'New Castle',
  'New Century',
  'New City',
  'New Concord',
  'New Cross',
  'New Cumberland',
  'New Cuyama',
  'New Delhi',
  'New Durham',
  'New Egypt',
  'New England',
  'New Era',
  'New Fairfield',
  'New Farm',
  'New Ferry',
  'New Franken',
  'New Freedom',
  'New Glarus',
  'New Glasgow',
  'New Gloucester',
  'New Gretna',
  'New Hamburg',
  'New Hampshire',
  'New Hampton',
  'New Hartford',
  'New Haven',
  'New Holland',
  'New Holstein',
  'New Hope',
  'New Hudson',
  'New Hyde Park',
  'New Iberia',
  'New Jersey',
  'New Johnsonville',
  'New Kensington',
  'New Kent',
  'New Kingston',
  'New Knoxville',
  'New Lagos',
  'New Laguna',
  'New Lambton',
  'New Lebanon',
  'New Lenox',
  'New Lexington',
  'New Lisbon',
  'New Liskeard',
  'New London',
  'New Lothrop',
  'New Madrid',
  'New Malden',
  'New Manila',
  'New Market',
  'New Marshfield',
  'New Meadows',
  'New Milford',
  'New Mills',
  'New Milton',
  'New Norfolk',
  'New Orleans',
  'New Oxford',
  'New Palestine',
  'New Paltz',
  'New Paris',
  'New Park',
  'New Philadelphia',
  'New Plymouth',
  'New Port Richey',
  'New Portland',
  'New Prague',
  'New Preston Marble Dale',
  'New Providence',
  'New Quay',
  'New Richmond',
  'New Riegel',
  'New Ringgold',
  'New River',
  'New Roads',
  'New Rochelle',
  'New Romney',
  'New Ross',
  'New Salem',
  'New Smyrna Beach',
  'New Southgate',
  'New Suffolk',
  'New Tazewell',
  'New Territories',
  'New Town',
  'New Tripoli',
  'New Ulm',
  'New Vernon',
  'New Vineyard',
  'New Waterford',
  'New Waverly',
  'New Well',
  'New Westminster',
  'New Wilmington',
  'New Windsor',
  'New York',
  'New York Mills',
  'Newark',
  'Newark Upon Trent',
  'Newark Valley',
  'Newaygo',
  'Newberg',
  'Newberry',
  'Newbridge',
  'Newburgh',
  'Newburn',
  'Newbury',
  'Newbury Park',
  'Newburyport',
  'Newby',
  'Newcastle',
  'Newcastle Emlyn',
  'Newcastle Under Lyme',
  'Newcastle Upon Tyne',
  'Newcastle Waters',
  'Newcastle West',
  'Newcastle-on-tyne',
  'Newdigate',
  'Newe Yamin',
  'Newe Yaraq',
  'Newell',
  'Newent',
  'Newfield',
  'Newfields',
  'Newfoundland',
  'Newhall',
  'Newhaven',
  'Newhebron',
  'Newhouse',
  'Newington',
  'Newkirk',
  'Newland',
  'Newlands',
  'Newman',
  'Newmanstown',
  'Newmarket',
  'Newmarket On Fergus',
  'Newnan',
  'Newport',
  'Newport Beach',
  'Newport Coast',
  'Newport News',
  'Newport Pagnell',
  'Newport-on-tay',
  'Newquay',
  'Newry',
  'Newstead',
  'Newton',
  'Newton Abbot',
  'Newton Aycliffe',
  'Newton Center',
  'Newton Falls',
  'Newton Grove',
  'Newton Highlands',
  'Newton In Makerfield',
  'Newton Lower Falls',
  'Newton Stewart',
  'Newton Upper Falls',
  'Newton-le-willows',
  'Newtonville',
  'Newtown',
  'Newtown Saint Boswells',
  'Newtown Square',
  'Newtownabbey',
  'Newtownards',
  'Newtownstewart',
  'Newyork',
  'Nexø',
  'Neyagawa',
  'Neydharting',
  'Neyland',
  'Neyruz',
  'Neyveli',
  'Nezvestice',
  'Neßlbach',
  'Néa Alikarnassós',
  'Néa Erithraía',
  'Néa Filadélfeia',
  'Néa Ionía',
  'Néa Mákri',
  'Néa Smírni',
  'Néon Iráklion',
  'Néos Kósmos',
  'Néos Marmarás',
  'Néron',
  'Néronville',
  'Névache',
  'Ngahere',
  'Ngaliyan',
  'Ngauranga',
  'Ngong',
  'Nguru',
  'Nha Trang',
  'Niagara',
  'Niagara Falls',
  'Niagara University',
  'Niagara-on-the-lake',
  'Niamey',
  'Niantic',
  'Nibancho',
  'Nibbixwoud',
  'Nibe',
  'Nibong Tebal',
  'Nicasio',
  'Nice',
  'Niceville',
  'Nichelino',
  'Nicholasville',
  'Nicholson',
  'Nicholville',
  'Nickenich',
  'Nicole',
  'Nicosia',
  'Nidau',
  'Nidda',
  'Nidderau',
  'Nideggen',
  'Nidfurn',
  'Niebelsbach',
  'Niebert',
  'Niebüll',
  'Niechorz',
  'Niedenstein',
  'Nieder-olm',
  'Nieder-roden',
  'Nieder-saulheim',
  'Niederalteich',
  'Niederanven',
  'Niederaula',
  'Niederbergkirchen',
  'Niederbipp',
  'Niederbrechen',
  'Niederbuchsiten',
  'Niedercorn',
  'Niederdorfelden',
  'Niederelbert',
  'Niedereschach',
  'Niederfüllbach',
  'Niedergosgen',
  'Niedergörsdorf',
  'Niedergösgen',
  'Niederhausbergen',
  'Niederhelfenschwil',
  'Niederkassel',
  'Niederkrüchten',
  'Niederland',
  'Niederlangen',
  'Niederlauer',
  'Niederlehme',
  'Niederlenz',
  'Niedernberg',
  'Niederneuching',
  'Niedernhall',
  'Niedernhausen',
  'Niederrohrdorf',
  'Niederroßla',
  'Niederscherli',
  'Niederschönhausen',
  'Niederstotzingen',
  'Niederurnen',
  'Niederuzwil',
  'Niederwalgern',
  'Niederwangen',
  'Niederweningen',
  'Niederwiesa',
  'Niederwinkling',
  'Niederzissen',
  'Niefern-Öschelbronn',
  'Niefern-öschelbronn',
  'Niehove',
  'Niel',
  'Niemegk',
  'Nienborg',
  'Nienburg',
  'Niendorf',
  'Nienhagen',
  'Nienwohld',
  'Niepolomice',
  'Nierstein',
  'Niesgrau',
  'Niesky',
  'Nieszawa',
  'Nietwerder',
  "Nieuil-l'espoir",
  'Nieuw-amsterdam',
  'Nieuw-beijerland',
  'Nieuw-buinen',
  'Nieuw-haamstede',
  'Nieuw-heeten',
  'Nieuw-lekkerland',
  'Nieuw-vennep',
  'Nieuwe Krim',
  'Nieuwe Pekela',
  'Nieuwegein',
  'Nieuwegein-zuid',
  'Nieuwendijk',
  'Nieuwer Amstel',
  'Nieuwerbrug',
  'Nieuwerkerk',
  'Nieuwerkerk Aan Den Ijssel',
  'Nieuwerkerken',
  'Nieuwkerke',
  'Nieuwkerken-waas',
  'Nieuwkoop',
  'Nieuwkuijk',
  'Nieuwland',
  'Nieuwleusen',
  'Nieuwpoort',
  'Nieuwveen',
  'Nieuwvliet',
  'Nièvroz',
  'Nigel',
  'Nightcliff',
  'Nigrán',
  'Nihombashi-hamacho',
  'Nihommatsu',
  'Niigata',
  'Niihama',
  'Niimi',
  'Niiza',
  'Nij Beets',
  'Nijehaske',
  'Nijemirdum',
  'Nijeveen',
  'Nijkerk',
  'Nijkerkerveen',
  'Nijlen',
  'Nijmegen',
  'Nijverdal',
  'Nikko',
  'Niklasdorf',
  'Nikolaev',
  'Nikopol',
  'Nil-saint-vincent',
  'Nilai',
  'Nilambur',
  'Niles',
  'Nilokheri',
  'Nilópolis',
  'Nilüfer',
  'Ningbo',
  'Ningbo Shi',
  'Ningde',
  'Ningguo',
  'Ninghai',
  'Ningxiang',
  'Nino',
  'Ninomiya',
  'Ninove',
  'Niort',
  'Niota',
  'Nipani',
  'Nipawin',
  'Niphad',
  'Nipomo',
  'Nir David',
  'Nir Eliyahu',
  'Nir Galim',
  'Nir Zevi',
  'Nir`am',
  'Nirasaki',
  'Nis',
  'Nisantasi',
  'Niscemi',
  'Nishi',
  'Nishi-nippori',
  'Nishi-shinagawa',
  'Nishigahara',
  'Nishihara',
  'Nishiki',
  'Nishikicho',
  'Nishiku',
  'Nishikubo',
  'Nishimachi',
  'Nishina',
  'Nishinomiya',
  'Nishio',
  'Nishiwaki',
  'Nisku',
  'Nismes',
  'Nispen',
  'Nisshin',
  'Nisswa',
  'Nistelrode',
  'Niteroi',
  'Niterói',
  'Nitra',
  'Nitry',
  'Nitsané Oz',
  'Nitta',
  'Nittenau',
  'Nittendorf',
  'Niva',
  'Nivaa',
  'Nivala',
  'Nivå',
  'Nivelles',
  'Nivolas-vermelle',
  'Niwot',
  'Nixa',
  'Nixon',
  'Nizamabad',
  'Nizanim',
  'Nizerolles',
  'Nizhegorodskaya',
  'Nizhnaya Tura',
  'Nizhnekamsk',
  'Nizhnevartovsk',
  'Nizhni Novgorod',
  'Nizhniy Novgorod',
  'Nizhniy Tagil',
  'Nizhyn',
  'Nizna',
  'Nizza Di Sicilia',
  'Níkaia',
  'Nîmes',
  'Njivice',
  'Njurunda',
  'No',
  'Noailles',
  'Noale',
  'Noáin',
  'Nobby',
  'Nobeoka',
  'Nobitz',
  'Noble',
  'Noble Park',
  'Nobleboro',
  'Nobleford',
  'Noblesville',
  'Nocera Inferiore',
  'Nocera Superiore',
  'Noci',
  'Noda',
  'Noer',
  'Noeux-les-mines',
  'Noé',
  'Nofekh',
  'Nogales',
  'Nogara',
  'Nogata',
  'Nogent',
  'Nogent-le-phaye',
  'Nogent-le-roi',
  'Nogent-le-rotrou',
  'Nogent-sur-marne',
  'Nogent-sur-seine',
  'Nogueira Da Regedoura',
  'Nogueira Do Cravo',
  'Nohen',
  'Nohfelden',
  'Nohra',
  'Noicattaro',
  'Noida',
  'Noidans-lès-vesoul',
  'Noisy-le-grand',
  'Noisy-le-roi',
  'Noja',
  'Noji',
  'Nokesville',
  'Nokha',
  'Nokia',
  'Nokomis',
  'Nol',
  'Nola',
  'Nolensville',
  'Nomain',
  'Nome',
  'Nomi',
  'Nonant',
  'Nonantola',
  'None',
  'Nong Khaem',
  'Nongan',
  'Nonnweiler',
  'Nono',
  'Nonoichi',
  'Nonsan',
  'Nonthaburi',
  'Nontron',
  'Noonan',
  'Noord-deurningen',
  'Noord-scharwoude',
  'Noordbeemster',
  'Noordbergum',
  'Noordbroek',
  'Noordeloos',
  'Noorderwijk',
  'Noordhorn',
  'Noordlaren',
  'Noordwijk',
  'Noordwijkerhout',
  'Noordwolde',
  'Noosa',
  'Noosa Heads',
  'Noosaville',
  'Nootdorp',
  'Nora',
  'Noranda',
  'Noraville',
  'Norberg',
  'Norbury',
  'Norcia',
  'Norco',
  'Norcross',
  'Nordborchen',
  'Nordborg',
  'Nordby',
  'Norden',
  'Nordendorf',
  'Nordenham',
  'Norderheistedt',
  'Norderney',
  'Norderstedt',
  'Norderwöhrden',
  'Nordfjordeid',
  'Nordfjordnes',
  'Nordhausen',
  'Nordheim',
  'Nordhorn',
  'Nordiya',
  'Nordkirchen',
  'Nordland',
  'Nords Wharf',
  'Nordstemmen',
  'Nordwalde',
  'Noresund',
  'Noréaz',
  'Norfolk',
  'Norge',
  'Norje',
  'Normal',
  'Norman',
  'Norman Park',
  'Normandel',
  'Normandy',
  'Normanhurst',
  'Normanton',
  'Normanton On Trent',
  'Normanville',
  'Norra Lagnö',
  'Norrahammar',
  'Norrby',
  'Norristown',
  'Norrköping',
  'Norrmalm',
  'Norroy-le-veneur',
  'Norrsundet',
  'Norrtälje',
  'Nors',
  'Norsborg',
  'Norsjö',
  'Nort-sur-erdre',
  'Norte',
  'North',
  'North Adams',
  'North Adelaide',
  'North Andover',
  'North Arlington',
  'North Arm',
  'North Attleboro',
  'North Augusta',
  'North Aurora',
  'North Babylon',
  'North Baltimore',
  'North Battleford',
  'North Bay',
  'North Beach',
  'North Bend',
  'North Benton',
  'North Bergen',
  'North Berwick',
  'North Billerica',
  'North Blenheim',
  'North Bovey',
  'North Branch',
  'North Branford',
  'North Bridge',
  'North Brighton',
  'North Brookfield',
  'North Brunswick',
  'North Canton',
  'North Cave',
  'North Charleston',
  'North Chatham',
  'North Chelmsford',
  'North Chicago',
  'North Chili',
  'North Conway',
  'North Dartmouth',
  'North Dighton',
  'North East',
  'North Easton',
  'North Falmouth',
  'North Ferriby',
  'North Ferrisburg',
  'North Fitzroy',
  'North Fork',
  'North Fort Myers',
  'North Franklin',
  'North Fremantle',
  'North Grafton',
  'North Granville',
  'North Greece',
  'North Grosvenordale',
  'North Hampton',
  'North Hatley',
  'North Haven',
  'North Highlands',
  'North Hill',
  'North Hills',
  'North Hollywood',
  'North Houston',
  'North Hyde Park',
  'North Hykeham',
  'North Jackson',
  'North Kansas City',
  'North Kingstown',
  'North Lake',
  'North Lake Grace',
  'North Las Vegas',
  'North Leigh',
  'North Liberty',
  'North Lima',
  'North Little Rock',
  'North Loburn',
  'North Manchester',
  'North Melbourne',
  'North Miami',
  'North Miami Beach',
  'North Myrtle Beach',
  'North Newbald',
  'North Newton',
  'North Olmsted',
  'North Oxford',
  'North Palm Beach',
  'North Palm Springs',
  'North Plains',
  'North Platte',
  'North Point',
  'North Pole',
  'North Port',
  'North Powder',
  'North Prairie',
  'North Providence',
  'North Reading',
  'North Richland Hills',
  'North Ridgeville',
  'North River',
  'North Rose',
  'North Royalton',
  'North Saanich',
  'North Saint Paul',
  'North Salem',
  'North Salt Lake',
  'North Scituate',
  'North Shields',
  'North Shore',
  'North Sioux City',
  'North Smithfield',
  'North Somercotes',
  'North Springfield',
  'North Stonington',
  'North Sutton',
  'North Sydney',
  'North Syracuse',
  'North Tazewell',
  'North Tonawanda',
  'North Truro',
  'North Turramurra',
  'North Vancouver',
  'North Vernon',
  'North Versailles',
  'North Wales',
  'North Walpole',
  'North Walsham',
  'North Waltham',
  'North Weald',
  'North Webster',
  'North Weymouth',
  'North Wheatley',
  'North White Plains',
  'North Wilkesboro',
  'North Wootton',
  'North Yarmouth',
  'North York',
  'Northallerton',
  'Northam',
  'Northampton',
  'Northaw',
  'Northborough',
  'Northbridge',
  'Northbrook',
  'Northchurch',
  'Northcote',
  'Northeast Harbor',
  'Northern Territory',
  'Northern Washington Co',
  'Northfield',
  'Northfield Falls',
  'Northfleet',
  'Northford',
  'Northgate',
  'Northiam',
  'Northleach',
  'Northmead',
  'Northolt',
  'Northport',
  'Northridge',
  'Northumberland',
  'Northvale',
  'Northville',
  'Northwest',
  'Northwich',
  'Northwood',
  'Nortmoor',
  'Norton',
  'Norton Bridge',
  'Norton Canes',
  'Norton Disney',
  'Norton Summit',
  'Nortorf',
  'Norwalk',
  'Norway',
  'Norwegen',
  'Norwell',
  'Norwich',
  'Norwood',
  'Noryangjin',
  'Nossa Senhora Das Graças',
  'Nossebro',
  'Nossen',
  'Nothéret',
  'Notodden',
  'Notre Dame',
  "Notre-dame-d'oe",
  'Notre-dame-de-commiers',
  'Notre-dame-de-gravenchon',
  'Notre-dame-de-lourdes',
  'Notre-dame-de-sanilhac',
  'Notter',
  'Notting Hill',
  'Nottingham',
  'Nottuln',
  'Nottwil',
  'Notzingen',
  'Nouakchott',
  'Nouan',
  'Nouméa',
  'Nouvion',
  'Nouzilly',
  'Nouzonville',
  'Nov',
  'Nova',
  'Nova América',
  'Nova Bassano',
  'Nova Bystrice',
  'Nova Friburgo',
  'Nova Gorica',
  'Nova Gradiska',
  'Nova Iguaçu',
  'Nova Kakhovka',
  'Nova Lima',
  'Nova Lubovna',
  'Nova Milanese',
  'Nova Módica',
  'Nova Odessa',
  'Nova Paka',
  'Nova Pazova',
  'Nova Prata',
  'Nova Role',
  'Nova Serrana',
  'Nova Veneza',
  'Nova Ves',
  'Novara',
  'Novate Milanese',
  'Novato',
  'Novaya',
  'Novazzano',
  'Nová Dubnica',
  'Nová Ves',
  'Nováky',
  'Nove',
  'Nove Mesto',
  'Nove Mesto Na Morave',
  'Nove Mesto Nad Metuji',
  'Novellara',
  'Novelty',
  'Noventa Di Piave',
  'Noves',
  'Nové Jirny',
  'Nové Mesto Nad Váhom',
  'Nové Sady',
  'Nové Zámky',
  'Novgorod',
  'Novi',
  'Novi Beograd',
  'Novi Di Modena',
  'Novi Ligure',
  'Novi Pazar',
  'Novi Sad',
  'Novi Travnik',
  'Novi Zagreb',
  'Novigrad',
  'Noville-les-bois',
  'Novo Hamburg',
  'Novo Hamburgo',
  'Novo Mesto',
  'Novokuybyshevsk',
  'Novokuznetsk',
  'Novomirgorod',
  'Novomoskovsk',
  'Novorossiysk',
  'Novosibirsk',
  'Novovolynsk',
  'Novy Bor',
  'Novy Jicin',
  'Nový Bor',
  'Nowa Sarzyna',
  'Nowe Skalmierzyce',
  'Nowra',
  'Nowshera',
  'Nowy Dwor Gdanski',
  'Nowy Dwor Mazowiecki',
  'Nowy Sacz',
  'Nowy Targ',
  'Noyal-sur-seiche',
  'Noyal-sur-vilaine',
  'Noyarey',
  'Noyelles-lès-seclin',
  'Noyelles-sur-selle',
  'Noyon',
  'Nozay',
  'Nõva',
  'Nödinge',
  'Nödinge-nol',
  'Nördlingen',
  'Nörten-hardenberg',
  'Nörvenich',
  'Nöttesta',
  'Nsawam',
  'Nsukka',
  'Nucice',
  'Nuenen',
  'Nueva',
  'Nuevo',
  'Nuevo Baztán',
  'Nuevo Casas Grandes',
  'Nuevo Laredo',
  'Nuevo León',
  'Nufringen',
  'Nugegoda',
  'Nuillé-sur-vicoin',
  'Nuits-saint-georges',
  'Nuland',
  'Nules',
  'Numakunai',
  'Numancia De La Sagra',
  'Numansdorp',
  'Numata',
  'Numazu',
  'Numbai',
  'Nummela',
  'Nummi',
  'Nunawading',
  'Nunda',
  'Nuneaton',
  'Nungambakkam',
  'Nunica',
  'Nunn',
  'Nunningen',
  'Nunoa',
  'Nunspeet',
  'Nuoro',
  'Nuremberg',
  'Nurenberg',
  'Nuriootpa',
  'Nurmijärvi',
  'Nursling',
  'Nusaybin',
  'Nusco',
  'Nusle',
  'Nussbaumen',
  'Nutfield',
  'Nuth',
  'Nutley',
  'Nutts Corner',
  'Nuuk',
  'Nuwaidrat',
  'Nußbaum',
  'Nußdorf',
  'Nußdorf Am Inn',
  'Nußloch',
  'Núcleo Bandeirante',
  'Núñez',
  'Nümbrecht',
  'Nürburg',
  'Nürensdorf',
  'Nürnberg',
  'Nürtingen',
  'Nütschau',
  'Ny Tolstrup',
  'Nyack',
  'Nyagan',
  'Nyahururu',
  'Nyarugenge',
  'Nyborg',
  'Nybro',
  'Nydalen',
  'Nyergesújfalu',
  'Nyeri',
  'Nyhammar',
  'Nyhamnsläge',
  'Nyingchi',
  'Nyírbéltek',
  'Nyíregyháza',
  'Nyírtass',
  'Nyírtelek',
  'Nykirke',
  'Nyköping',
  'Nykroppa',
  'Nykvarn',
  'Nykøbing',
  'Nykøbing Mors',
  'Nykøbing Sjælland',
  'Nyland',
  'Nymburk',
  'Nynäshamn',
  'Nyon',
  'Nyons',
  'Nyråd',
  'Nyrsko',
  'Nysa',
  'Nysted',
  'Nyuzen',
  'Nyúl',
  'Nærbø',
  'Nærum',
  'Næstved',
  'Nørager',
  'Nørre Aaby',
  'Nørre Alslev',
  'Nørre Nebel',
  'Nørreballe',
  'Nørresundby',
  'Nøtterøy',
  'Ñuñoa',
  'O Fallon',
  "O'brien",
  "O'connor",
  "O'fallon",
  "O'halloran Hill",
  "O'leary",
  'Oadby',
  'Oak',
  'Oak Bluffs',
  'Oak Brook',
  'Oak City',
  'Oak Creek',
  'Oak Forest',
  'Oak Grove',
  'Oak Harbor',
  'Oak Hill',
  'Oak Island',
  'Oak Lake',
  'Oak Lawn',
  'Oak Park',
  'Oak Park Heights',
  'Oak Ridge',
  'Oak Ridge North',
  'Oak View',
  'Oakboro',
  'Oakdale',
  'Oakey',
  'Oakham',
  'Oakhurst',
  'Oakington',
  'Oakland',
  'Oakland City',
  'Oakland Gardens',
  'Oakland Park',
  'Oaklands',
  'Oakleigh',
  'Oakleigh East',
  'Oakleigh South',
  'Oakley',
  'Oaklyn',
  'Oakmont',
  'Oakridge',
  'Oaks',
  'Oakton',
  'Oakvale',
  'Oakville',
  'Oakwood',
  'Oaxaca',
  'Oaxaca De Juárez',
  'Ob',
  'Oban',
  'Obdam',
  'Ober Gurzelen',
  'Ober-flörsheim',
  'Ober-mörlen',
  'Ober-olm',
  'Ober-ramstadt',
  'Ober-saulheim',
  'Oberammergau',
  'Oberasbach',
  'Oberau',
  'Oberaula',
  'Oberbessenbach',
  'Oberbibrach',
  'Oberbillig',
  'Oberbipp',
  'Oberboihingen',
  'Oberbuchsiten',
  'Oberburen',
  'Oberburg',
  'Oberbüren',
  'Oberderdingen',
  'Oberdiessbach',
  'Oberding',
  'Oberdorf',
  'Oberdorla',
  'Oberegg',
  'Obereisenheim',
  'Obereisesheim',
  'Oberelsbach',
  'Oberembrach',
  'Oberengstringen',
  'Oberentfelden',
  'Oberfeld',
  'Oberflacht',
  'Obergerlafingen',
  'Oberglatt',
  'Obergösgen',
  'Obergriesbach',
  'Oberhaching',
  'Oberhaid',
  'Oberhain',
  'Oberhausbergen',
  'Oberhausen',
  'Oberhausen-rheinhausen',
  'Oberhof',
  'Oberhofen',
  'Oberhoffen-sur-moder',
  'Oberiberg',
  'Oberkirch',
  'Oberkochen',
  'Oberkotzau',
  'Oberlahr',
  'Oberleichtersbach',
  'Oberlin',
  'Oberlungwitz',
  'Oberlunkhofen',
  'Obermarchtal',
  'Obermehler',
  'Obernai',
  'Obernburg',
  'Oberndorf',
  'Oberneukirchen',
  'Obernfeld',
  'Obernkirchen',
  'Obernzell',
  'Obernzenn',
  'Oberpallen',
  'Oberpframmern',
  'Oberreichenbach',
  'Oberried',
  'Oberrieden',
  'Oberriet',
  'Oberrot',
  'Oberruti',
  'Oberrüti',
  'Oberschaeffolsheim',
  'Oberschleißheim',
  'Oberschneiding',
  'Obersiggenthal',
  'Obersontheim',
  'Oberstaufen',
  'Oberstdorf',
  'Oberstenfeld',
  'Oberteuringen',
  'Obertheres',
  'Obertraubling',
  'Obertshausen',
  'Oberursel',
  'Oberuzwil',
  'Oberwaltersdorf',
  'Oberwangen',
  'Oberweis',
  'Oberweißbach',
  'Oberweningen',
  'Oberwiesen',
  'Oberwil',
  'Oberwöllstadt',
  'Oberzeitlbach',
  'Obfelden',
  'Obihiro',
  'Obninsk',
  'Oborniki',
  'Obourg',
  'Obregón',
  'Obrigheim',
  'Observatoriya',
  'Obsteig',
  'Obu',
  'Obukhiv',
  'Ocala',
  'Occhiobello',
  'Occidental',
  'Occoquan',
  'Ocean City',
  'Ocean Grove',
  'Ocean Isle Beach',
  'Ocean Shores',
  'Ocean Springs',
  'Ocean View',
  'Oceano',
  'Oceanport',
  'Oceanside',
  'Oceanville',
  'Ochiai',
  'Ochla',
  'Ochlenberg',
  'Ochlocknee',
  'Ocholt',
  'Ochota',
  'Ochsenfurt',
  'Ochsenhausen',
  'Ochten',
  'Ochtendung',
  'Ochtrup',
  'Ockelbo',
  'Ockenheim',
  'Ocklawaha',
  'Ockley',
  'Ocoee',
  'Oconomowoc',
  'Oconto Falls',
  'Ocotlán',
  'Ocova',
  'Ocoyoacac',
  'Ocquier',
  'Ocracoke',
  'Octeville',
  'Octeville-sur-mer',
  'October 6',
  'Odate',
  'Odawara',
  'Odda',
  'Oddense',
  'Odder',
  'Odelzhausen',
  'Odena',
  'Odense',
  'Odenthal',
  'Odenton',
  'Oderding',
  'Oderzo',
  'Odesa',
  'Odessa',
  'Odiham',
  'Odijk',
  'Odiliapeel',
  'Odintsovo',
  'Odintsovo-vakhromeyevo',
  'Odivelas',
  'Odolena Voda',
  'Odon',
  'Odorheiu Secuiesc',
  'Odry',
  'Oed',
  'Oederan',
  'Oedheim',
  'Oegstgeest',
  'Oeiras',
  'Oelde',
  'Oelsnitz',
  'Oelwein',
  'Oensingen',
  'Oer-erkenschwick',
  'Oerel',
  'Oerlikon',
  'Oerlinghausen',
  'Oersberg',
  'Oestrich-winkel',
  'Oettersdorf',
  'Oettingen',
  'Oetwil Am See',
  'Oetwil An Der Limmat',
  'Oetz',
  'Oetzen',
  'Oevel',
  'Oeverich',
  'Oeversee',
  'Ofakim',
  'Ofaqim',
  'Offenau',
  'Offenbach',
  'Offenbach An Der Queich',
  'Offenbach-hundheim',
  'Offenburg',
  'Officer',
  'Offingen',
  'Offranville',
  'Offwiller',
  'Ofterdingen',
  'Oftersheim',
  'Oftringen',
  'Ogaki',
  'Ogallala',
  'Ogano',
  'Ogata',
  'Ogawa',
  'Ogawara',
  'Ogbomoso',
  'Ogden',
  'Ogdensburg',
  'Ogema',
  'Ogeum',
  'Ogikubo',
  'Ogíjares',
  'Oglesby',
  'Ogre',
  'Ogresgala',
  'Ogrodzieniec',
  'Oguchi',
  'Ogudu',
  'Ogulin',
  'Ogun',
  'Ogunquit',
  'Ohain',
  'Ohashi',
  'Ohey',
  'Ohio',
  'Ohio City',
  'Ohiopyle',
  'Ohiowa',
  'Ohlstadt',
  'Ohmstal',
  'Ohrdruf',
  'Ohrid',
  'Ohsweken',
  'Oiã',
  'Oignies',
  'Oijen',
  'Oil City',
  'Oil Springs',
  'Oilville',
  'Oinófita',
  'Oinóï',
  'Oirlo',
  'Oirsbeek',
  'Oirschot',
  'Oisème',
  'Oisterwijk',
  'Oistins',
  'Oita',
  'Oita-shi',
  'Oituz',
  'Ojai',
  'Ojiya',
  'Ojota',
  'Oka',
  'Okahumpka',
  'Okanagan',
  'Okara',
  'Okatie',
  'Okato',
  'Okaya',
  'Okayama',
  'Okayama-shi',
  'Okazaki',
  'Okeechobee',
  'Okehampton',
  'Okemah',
  'Okemos',
  'Okinawa',
  'Oklahoma',
  'Oklahoma City',
  'Okmulgee',
  'Okotoks',
  'Oktyabrsk',
  'Okubo',
  'Okuda',
  'Olaberría',
  'Olaine',
  'Olathe',
  'Olawa',
  'Olbernhau',
  'Olbia',
  'Olching',
  'Old Bethpage',
  'Old Bonalbo',
  'Old Bridge',
  'Old Cairo',
  'Old Chatham',
  'Old Delhi',
  'Old Forge',
  'Old Greenwich',
  'Old Harbour',
  'Old Hickory',
  'Old Lyme',
  'Old Mission',
  'Old Orchard Beach',
  'Old Sarum',
  'Old Saybrook',
  'Old Town',
  'Old Westbury',
  'Old Windsor',
  'Oldbury',
  'Oldcastle',
  'Oldeboorn',
  'Oldebroek',
  'Oldenburg',
  'Oldenburg In Holstein',
  'Oldendorf',
  'Oldenzaal',
  'Oldham',
  'Oldmeldrum',
  'Olds',
  'Oldsmar',
  'Oldwick',
  'Olean',
  'Oleggio',
  'Oleiros',
  'Olen',
  'Olesa De Montserrat',
  'Olesh',
  'Olesnica',
  'Olesno',
  'Olevano Sul Tusciano',
  'Olfen',
  'Olgiate',
  'Olgiate Olona',
  'Olginate',
  'Olhão',
  'Oliana',
  'Oliete',
  'Olinda',
  'Olite',
  'Oliva',
  'Oliva De La Frontera',
  'Olival',
  'Olivares',
  'Olive Branch',
  'Oliveira',
  'Oliveira De Azemeis',
  'Oliveira De Frades',
  'Oliveira Do Bairro',
  'Oliveira Do Hospital',
  'Oliver',
  'Olivet',
  'Olivette',
  'Olivos',
  'Olímpia',
  'Olímpio De Freitas',
  'Olkusz',
  'Ollainville',
  'Ollería',
  'Ollerton',
  'Ollioules',
  'Ollon',
  'Olmedo',
  'Olmo',
  'Olmsted Falls',
  'Olmstedville',
  'Olney',
  'Olofstorp',
  'Olofström',
  'Olomouc',
  'Olongapo',
  'Olonne-sur-mer',
  'Oloron-sainte-marie',
  'Olot',
  'Olpe',
  'Olsberg',
  'Olsene',
  'Olsztyn',
  'Olten',
  'Olton',
  'Olula Del Río',
  'Olvera',
  'Olveston',
  'Olympia',
  'Olympia Fields',
  'Olympic Valley',
  'Olyphant',
  'Olza',
  'Omachi',
  'Omagh',
  'Omaha',
  'Omanawa',
  'Omar',
  'Omdurman',
  'Ome',
  'Omeath',
  'Omega',
  'Omegna',
  'Omemee',
  'Omez',
  'Omihachiman',
  'Omis',
  'Omisalj',
  'Omiya',
  'Ommen',
  'Omori',
  'Omro',
  'Omsk',
  'Omura',
  'Omuta',
  'Ona',
  'Onalaska',
  'Onamia',
  'Onancock',
  'Oncesti',
  'Onda',
  'Ondara',
  'Ondo',
  'Oneco',
  'Oneida',
  'Oneill',
  'Onekama',
  'Onekawa',
  'Oneonta',
  'Onesti',
  'Onet-le-château',
  'Onex',
  'Ongole',
  'Ongwediva',
  'Onhaye',
  'Onida',
  'Onil',
  'Onitsha',
  'Onkerzele',
  'Onna',
  'Onne',
  'Onnens',
  'Ono',
  'Onoda',
  'Onojo',
  'Onomichi',
  'Onsted',
  'Onstwedde',
  'Ontario',
  'Onteniente',
  'Ontex',
  'Ontiñena',
  'Onze-lieve-vrouw-waver',
  'Oñate',
  'Ooltewah',
  'Ooltgensplaat',
  'Oost',
  'Oost-souburg',
  'Oostburg',
  'Oosteeklo',
  'Oosteind',
  'Oostende',
  'Oosterbeek',
  'Oosterblokker',
  'Oosterhout',
  'Oostermeer',
  'Oosterwolde',
  'Oosterzele',
  'Oostkamp',
  'Oostmalle',
  'Oostrozebeke',
  'Oostrum',
  'Oostvoorne',
  'Oostzaan',
  'Ootmarsum',
  'Ooty',
  'Oô',
  'Opa Locka',
  'Opalenica',
  'Opatija',
  'Opatov',
  'Opava',
  'Opbrakel',
  'Opelika',
  'Opelousas',
  'Openshaw',
  'Opera',
  'Opfikon',
  'Opglabbeek',
  'Opheusden',
  'Opladen',
  'Oploo',
  'Opmeer',
  'Opoj',
  'Opole',
  'Oporto',
  'Opp',
  'Oppdal',
  'Oppeano',
  'Oppegård',
  'Oppenau',
  'Oppenheim',
  'Oppenhuizen',
  'Oppenweiler',
  'Opperdoes',
  'Oppède',
  'Opphaug',
  'Oppland',
  'Opwijk',
  'Oquawka',
  'Or Akiva',
  'Or Haner',
  'Or Yehuda',
  'Ora',
  'Oracabessa',
  'Oracle',
  'Oradea',
  'Oradell',
  'Oradour-sur-glane',
  'Oradour-sur-vayres',
  'Orai',
  'Oraiókastro',
  'Oraison',
  'Orakei',
  'Oran',
  'Orange',
  'Orange Beach',
  'Orange City',
  'Orange Park',
  'Orangeburg',
  'Orangevale',
  'Orangeville',
  'Oranienburg',
  'Oranim',
  'Oranjestad',
  'Oranmore',
  'Oravské Veselé',
  "Orbais-l'abbaye",
  'Orbassano',
  'Orbe',
  'Orbec',
  'Orbetello',
  'Orbottyan',
  'Orchard Park',
  'Orchies',
  'Orcines',
  'Orcoyen',
  'Ordino',
  'Ordizan',
  'Ordos',
  'Ordu',
  'Orduña',
  'Ordynskiy',
  'Orech',
  'Orechova Poton',
  'Orefield',
  'Oregon',
  'Oregon City',
  'Oregon House',
  'Oregun',
  'Orel',
  'Oreland',
  'Orem',
  'Orenburg',
  'Orendain',
  'Orense',
  'Orewa',
  'Oreye',
  'Orford',
  'Orgelet',
  'Orgeval',
  'Orhalom',
  'Orhangazi',
  'Oricáin',
  'Orielton',
  'Orient',
  'Oriental',
  'Origgio',
  'Origny-sainte-benoîte',
  'Orihuela',
  'Orillia',
  'Orimattila',
  'Orinda',
  'Orio Al Serio',
  'Orion',
  'Oriskany',
  'Oriskany Falls',
  'Oristano',
  'Orivesi',
  'Orizaba',
  'Orkanger',
  'Orland',
  'Orland Park',
  'Orlando',
  'Orleans',
  'Orléans',
  'Orlingbury',
  'Orlova',
  'Orlovskaya',
  'Orly',
  'Ormalingen',
  'Ormeau',
  'Ormelle',
  'Ormes',
  'Ormesby',
  'Ormesson-sur-marne',
  'Ormond',
  'Ormond Beach',
  'Ormskirk',
  'Ornago',
  'Ornans',
  'Oro',
  'Oro Verde',
  'Orofino',
  'Oron-la-ville',
  'Orono',
  'Orosháza',
  'Oroslavje',
  'Oroszlány',
  'Orot',
  'Oroville',
  'Orpington',
  'Orpund',
  'Orr',
  'Orrantia',
  'Orrick',
  'Orrville',
  'Orry-la-ville',
  'Orsa',
  'Orsay',
  'Orsett',
  'Orsieres',
  'Orsingen-nenzingen',
  'Orsinval',
  'Orsk',
  'Orsomarso',
  'Ortaköy',
  'Ortenberg',
  'Ortenburg',
  'Orthez',
  'Orting',
  'Ortona',
  'Ortonville',
  'Ortrand',
  'Ortuella',
  'Oruña',
  'Orvault',
  'Orvieto',
  'Orvin',
  'Orwigsburg',
  'Osage',
  'Osage Beach',
  'Osaka',
  'Osaka-shi',
  'Osakai',
  'Osaki',
  'Osan',
  'Osandong',
  'Osasco',
  'Osawatomie',
  'Osborn',
  'Osborne',
  'Osby',
  'Osceola',
  'Oschatz',
  'Oschersleben',
  'Oscoda',
  'Osdorf',
  'Osgood',
  'Osgoode',
  'Osh',
  'Oshakati',
  'Oshawa',
  'Oshkosh',
  'Oshodi',
  'Oshtemo',
  'Oshu',
  'Osijek',
  'Osimo',
  'Osiniec',
  'Oskaloosa',
  'Oskarshamn',
  'Oskarström',
  'Oskava',
  'Oslo',
  'Osloß',
  'Osmanabad',
  'Osmangazi',
  'Osnabrück',
  'Osnago',
  'Osnice',
  'Osny',
  'Osogbo',
  'Osoppo',
  'Osorno',
  'Ospedaletto',
  'Ospel',
  'Ospitaletto',
  'Osprey',
  'Oss',
  'Ossela',
  'Ossendrecht',
  'Osseo',
  'Ossett',
  'Ossi',
  'Ossian',
  'Ossining',
  'Ostbevern',
  'Ostelsheim',
  'Ostenau',
  'Ostend',
  'Osterburg',
  'Osterburken',
  'Osterhofen',
  'Osterholz-scharmbeck',
  'Ostermiething',
  'Ostermundigen',
  'Ostermünchen',
  'Osterode',
  'Osterode Am Harz',
  'Osterreicher',
  'Osterreinen',
  'Osterrönfeld',
  'Osterville',
  'Osterwick',
  'Osterwieck',
  'Ostfildern',
  'Osthofen',
  'Osthoffen',
  'Ostiglia',
  'Ostim',
  'Ostra',
  'Ostrau',
  'Ostrava',
  'Ostrava-zabreh',
  'Ostrhauderfehn',
  'Ostroh',
  'Ostroleka',
  'Ostrov',
  'Ostrow Wielkopolski',
  'Ostrowsko',
  'Ostrozska Nova Ves',
  'Ostrów Wielkopolski',
  'Ostrzeszów',
  'Ostseebad Binz',
  'Ostseebad Prerow',
  'Ostseebad Rerik',
  'Ostseebad Sellin',
  'Oststeinbek',
  'Ostuni',
  'Ostwald',
  'Osun',
  'Oswego',
  'Oswestry',
  'Oswiecim',
  'Osøyri',
  'Ota',
  'Otacílio Costa',
  'Otago',
  'Otaki Beach',
  'Otalampi',
  'Otaniemi',
  'Otaru',
  'Otawara',
  'Otegem',
  'Otelfingen',
  'Otemachi',
  'Otepää',
  'Otero De Herreros',
  'Otero De Rey',
  'Othmarsingen',
  'Otisco',
  'Otjiwarongo',
  'Otley',
  'Otloczyn',
  'Otnice',
  'Otopeni',
  'Otorohanga',
  'Otrokovice',
  'Otsego',
  'Otsu',
  'Otsu-shi',
  'Otsuka',
  'Otsuki',
  'Otsuma',
  'Otta',
  'Ottappalam',
  'Ottawa',
  'Ottawa Lake',
  'Ottenbach',
  'Ottendorf',
  'Ottendorf-okrilla',
  'Ottenheim',
  'Ottensheim',
  'Otterbach',
  'Otterbein',
  'Otterbourne',
  'Otterburn Park',
  'Otterfing',
  'Otterlo',
  'Ottersberg',
  'Ottershaw',
  'Ottersum',
  'Ottersweier',
  'Otterup',
  'Otterville',
  'Ottignies',
  'Ottmarsheim',
  'Ottobrunn',
  'Ottoville',
  'Ottumwa',
  'Ottweiler',
  'Otumoetai',
  'Otwock',
  'Otztal-bahnhof',
  'Ouagadougou',
  'Ouakam',
  'Oud-beijerland',
  'Oud-gastel',
  'Oud-heverlee',
  'Oud-turnhout',
  'Oud-zevenaar',
  'Ouddorp',
  'Oude Meer',
  'Oude Pekela',
  'Oude-tonge',
  'Oudehorne',
  'Oudelande',
  'Oudenaarde',
  'Oudenaken',
  'Oudenbosch',
  'Oudenburg',
  'Oudergem',
  'Ouderkerk',
  'Ouderkerk Aan De Amstel',
  'Ouderkerk Aan Den Ijssel',
  'Oudeschild',
  'Oudewater',
  'Oudkarspel',
  'Oudorp',
  'Oudtshoorn',
  'Ouges',
  'Ougrée',
  'Oujda',
  'Oulainen',
  'Oullins',
  'Oulton',
  'Oulu',
  'Oulunsalo',
  'Ounans',
  'Oundle',
  'Oupeye',
  'Ouray',
  'Ourém',
  'Ourinhos',
  'Ourique',
  'Ouro Prêto',
  'Outokumpu',
  'Outorela',
  'Outreau',
  'Outremont',
  'Ovar',
  'Ovcary',
  'Ovchinnikovskiy',
  'Over',
  'Overasselt',
  'Overath',
  'Overijse',
  'Overland',
  'Overland Park',
  'Overloon',
  'Overpelt',
  'Overton',
  'Overtown',
  'Overveen',
  'Ovid',
  'Oviedo',
  'Oviken',
  'Ovingham',
  'Ovington',
  'Owanyilla',
  'Owariasahi',
  'Owasso',
  'Owatonna',
  'Owego',
  'Owen',
  'Owen Sound',
  'Owens Cross Roads',
  'Owensboro',
  'Owensville',
  'Owerri',
  'Owings',
  'Owings Mills',
  'Owingsville',
  'Owinska',
  'Owls Head',
  'Owosso',
  'Oxbow',
  'Oxelösund',
  'Oxenford',
  'Oxford',
  'Oxford Mills',
  'Oxford Park',
  'Oxie',
  'Oxley',
  'Oxnard',
  'Oxon Hill',
  'Oxted',
  'Oxton',
  'Oy-mittelberg',
  'Oyabe',
  'Oyama',
  'Oyne',
  'Oyo',
  'Oyonnax',
  'Oyón',
  'Oyster Bay',
  'Oyten',
  'Ozaki',
  'Ozalj',
  'Ozark',
  'Ozarow Mazowiecki',
  'Ozegna',
  'Ozoir-la-ferrière',
  'Ozolnieki',
  'Ozona',
  'Ozone Park',
  'Ozumba',
  'Ozzano',
  'Óbidos',
  'Óhid',
  'Öckerö',
  'Ödåkra',
  'Ödenwald',
  'Ödsmål',
  'Öhringen',
  'Öjebyn',
  'Öjersjö',
  'Ölsremma',
  'Ömer',
  'Örebro',
  'Örkelljunga',
  'Örnsköldsvik',
  'Örsjö',
  'Örsundsbro',
  'Ösby',
  'Österåker',
  'Österbybruk',
  'Österhaninge',
  'Östermalm',
  'Österskär',
  'Östersund',
  'Östhammar',
  'Östra Karup',
  'Östra Tommarp',
  'Östringen',
  'Ötigheim',
  'Ötisheim',
  'Öveçler',
  'Övertorneå',
  'Öyestranda',
  "Pa'in Semes Kandeh",
  'Paal',
  'Paarl',
  'Pabellón De Arteaga',
  'Pabianice',
  'Pablo',
  'Pabna',
  'Pabneukirchen',
  'Paca',
  'Pacé',
  'Pacheco',
  'Pachino',
  'Pachmarhi',
  'Pachuca',
  'Pachuca De Soto',
  'Pacific',
  'Pacific Grove',
  'Pacific Palisades',
  'Pacifica',
  'Pacitan',
  'Packington',
  'Pacoima',
  'Pacov',
  'Pacy-sur-eure',
  'Paço De Arcos',
  'Paços De Ferreira',
  'Padang',
  'Padborg',
  'Padbury',
  'Paddington',
  'Paddock Wood',
  'Pademangan Barat',
  'Padenghe Sul Garda',
  'Paderborn',
  'Padernello',
  'Paderno',
  "Paderno D'adda",
  'Paderno Dugnano',
  'Padiham',
  'Padova',
  'Padra',
  'Padrauna',
  'Padre Nóbrega',
  'Padre Paraíso',
  'Padrón',
  'Padstow',
  'Padua',
  'Paducah',
  'Padula',
  'Paekakariki',
  'Paengaroa',
  'Paeonian Springs',
  'Paeroa',
  'Paganico',
  'Page',
  'Pagliare',
  'Pago Pago',
  'Pagoh',
  'Pagosa Springs',
  'Pahang',
  'Paharganj',
  'Pahoa',
  'Pahrump',
  'Paia',
  'Paianía',
  'Paicines',
  'Paiçandu',
  'Paide',
  'Paignton',
  'Pailherols',
  'Pailles',
  'Pailly',
  'Paimio',
  'Painesville',
  'Painted Post',
  'Paintsville',
  'Paio Pires',
  'Paipa',
  'Paiporta',
  'Paisley',
  'Paitilla',
  'Pajala',
  'Paján',
  'Paju',
  'Pak Kred',
  'Pak Kret',
  'Pakefield',
  'Pakenham',
  'Pakenham East',
  'Pakistan Kili',
  'Pakoslawice',
  'Pakowhai',
  'Paks',
  'Pakuranga',
  'Pala',
  'Palacios',
  'Palafolls',
  'Palafrugell',
  'Palaión Fáliron',
  'Palaiseau',
  'Palakkad',
  'Palam',
  'Palamós',
  'Palampur',
  'Palanpur',
  'Palanquinos',
  'Palatine',
  'Palatine Bridge',
  'Palatka',
  'Palausolitar',
  'Palavas-les-flots',
  'Palawan',
  'Palazzo',
  'Palazzolo',
  'Paldau',
  'Palembang',
  'Palencia',
  'Palermo',
  'Palestine',
  'Palestrina',
  'Palézieux',
  'Palghar',
  'Palgrave',
  'Palhaça',
  'Palhais',
  'Palhoça',
  'Pali',
  'Palidano',
  'Palingen',
  'Palisade',
  'Palisades',
  'Palisades Park',
  'Palivere',
  'Palladam',
  'Palladuc',
  'Pallejá',
  'Palling',
  'Pallíni',
  'Palm',
  'Palm Bay',
  'Palm Beach',
  'Palm Beach Gardens',
  'Palm Beach Shores',
  'Palm City',
  'Palm Coast',
  'Palm Desert',
  'Palm Harbor',
  'Palm Springs',
  'Palma',
  'Palma De Gandía',
  'Palma De Mallorca',
  'Palma Del Río',
  'Palmanova',
  'Palmas',
  'Palmdale',
  'Palmeira',
  'Palmeira Dos Índios',
  'Palmeiras',
  'Palmela',
  'Palmer',
  'Palmer Lake',
  'Palmerah',
  'Palmerston',
  'Palmerston North',
  'Palmerston North City',
  'Palmerton',
  'Palmetto',
  'Palmi',
  'Palmvale',
  'Palmwoods',
  'Palmyra',
  'Palo Alto',
  'Palo Cedro',
  'Palokka',
  'Palomar Mountain',
  'Palos De La Frontera',
  'Palos Heights',
  'Palos Hills',
  'Palos Park',
  'Palos Verdes Estates',
  'Palos Verdes Peninsula',
  'Palotina',
  'Paltamo',
  'Palu',
  'Palwal',
  'Pamiers',
  'Pampa',
  'Pampanga',
  'Pampisford',
  'Pamplemousses',
  'Pamplona',
  'Pampow',
  'Pamukkale',
  'Pan De Azúcar',
  'Pana',
  'Panadura',
  'Panagyurishte',
  'Panaji',
  'Panama',
  'Panama City',
  'Panama City Beach',
  'Panamá',
  'Panazol',
  'Pancevo',
  'Pancheraccia',
  'Panchgani',
  'Panchiao',
  'Panchla',
  'Pancoran',
  'Pandino',
  'Pandora',
  'Pandrup',
  'Panevezio',
  'Paneveziukas',
  'Panevezys',
  'Pangandaran',
  'Pangasinan',
  'Pangbourne',
  'Pangkalanbun',
  'Pangkalpinang',
  'Panglao',
  'Pangnirtung',
  'Pangyo',
  'Panicale',
  'Panipat',
  'Panissières',
  'Panjim',
  'Panjin',
  'Pankakoski',
  'Pankrac',
  'Panmure',
  'Pannerden',
  'Panningen',
  'Pannipitiya',
  'Panon',
  'Panora',
  'Panorama',
  'Panorama City',
  'Pansdorf',
  'Pantego',
  'Pantelimon',
  'Pantigliate',
  'Pantin',
  'Pantnagar',
  'Pantoja',
  'Panton Hill',
  'Panvel',
  'Panyu',
  'Panzhihua',
  'Paola',
  'Paoli',
  'Paonia',
  'Papakura',
  'Papalotla',
  'Papamoa',
  'Papágou',
  'Papeete',
  'Papenburg',
  'Papendrecht',
  'Paphos',
  'Papillion',
  'Papiol',
  'Papworth',
  'Par',
  'Parabiago',
  'Parabita',
  'Paracatu',
  'Parachute',
  'Paracin',
  'Paracuellos De Jarama',
  'Parade',
  'Paradise',
  'Paradise Valley',
  'Paradiso',
  'Paragominas',
  'Paragould',
  'Parainen',
  'Paraiso',
  'Paraíba',
  'Paraíso',
  'Paramaribo',
  'Paramount',
  'Paramus',
  'Parana',
  'Paranaguá',
  'Paranavaí',
  'Paraná',
  'Paranã',
  'Paranhos',
  'Paranoá',
  'Parañaque',
  'Paraparaumu',
  'Paraparaumu Beach',
  'Parasia',
  'Paraskeví',
  'Paray-le-monial',
  'Paray-vieille-poste',
  'Pará',
  'Pará De Minas',
  'Parád',
  'Parádsasvár',
  'Parbhani',
  'Parchim',
  'Parchman',
  'Parçay',
  'Parçay-meslay',
  'Pardes Hanah',
  'Pardes Hanna',
  'Pardes Hanna Karkur',
  'Pardesiyya',
  'Pardubice',
  'Parede',
  'Paredes',
  'Parel',
  'Parets',
  'Pargas',
  'Parham',
  'Parigny',
  'Paris',
  'Parish',
  'Parit Buntar',
  'Parit Raja',
  'Park',
  'Park City',
  'Park Forest',
  'Park Gate',
  'Park Hill',
  'Park Hills',
  'Park Rapids',
  'Park Ridge',
  'Park River',
  'Parkano',
  'Parkdale',
  'Parker',
  'Parkers Prairie',
  'Parkersburg',
  'Parkes',
  'Parkesburg',
  'Parkland',
  'Parklands',
  'Parkmore',
  'Parks',
  'Parkside',
  'Parkstetten',
  'Parkstone',
  'Parksville',
  'Parkton',
  'Parkville',
  'Parkwood',
  'Parla',
  'Parlabá',
  'Parlier',
  'Parlin',
  'Parma',
  'Parman',
  'Parnamirim',
  'Parndorf',
  'Parnell',
  'Parobé',
  'Parola',
  'Parolise',
  'Parpan',
  'Parque Lefevre',
  'Parramatta',
  'Parre',
  'Parrish',
  'Parrsboro',
  'Parry Sound',
  'Parsdorf',
  'Parsippany',
  'Parsit',
  'Parsons',
  'Partanna',
  'Parthenay',
  'Partille',
  'Partizánske',
  'Partridge Green',
  'Paruzzaro',
  'Parville',
  'Parys',
  'Pasadena',
  'Pasajes De San Pedro',
  'Pasay',
  'Pasay City',
  'Pascagoula',
  'Pasching',
  'Pasco',
  'Pascoag',
  'Pasdaran',
  'Pasewalk',
  'Pasian Di Prato',
  'Pasig',
  'Pasir Gudang',
  'Paskov',
  'Paso',
  'Paso Robles',
  'Paspels',
  'Pass Christian',
  'Passaggio',
  'Passaic',
  'Passau',
  'Passendale',
  'Passignano Sul Trasimeno',
  'Passo Fundo',
  'Passos',
  'Passy',
  'Pasto',
  'Pastoriza',
  'Pastrengo',
  'Pasuruan',
  'Pasvalys',
  'Patagones',
  'Patan',
  'Pataskala',
  'Patchogue',
  'Paterna',
  'Paterna De Rivera',
  'Paternáin',
  'Paternion',
  'Paterno',
  'Paterson',
  'Pathanamthitta',
  'Pathankot',
  'Pathhead',
  'Pathum Thani',
  'Pathum Wan',
  'Patiala',
  'Patiya',
  'Patísia',
  'Patna',
  'Patna City',
  'Pato Branco',
  'Paton',
  'Patos De Minas',
  'Patras',
  'Patrocínio',
  'Patrocínio Paulista',
  'Pattaya',
  'Pattensen',
  'Patterson',
  'Pattersonville',
  'Patti',
  'Pattonville',
  'Pattukkottai',
  'Pau',
  'Paudex',
  'Paul',
  'Paul Smiths',
  'Paulding',
  'Paulista',
  'Paulínia',
  'Paullina',
  'Paulo Ramos',
  'Pauls Valley',
  'Paulsboro',
  'Paulton',
  'Pauma Valley',
  'Pavas',
  'Pavelsbach',
  'Pavia',
  'Pavia Di Udine',
  'Pavlovo',
  'Pavlovskaya',
  'Paw Paw',
  'Pawcatuck',
  'Pawhuska',
  'Pawleys Island',
  'Pawling',
  'Pawnee',
  'Pawtucket',
  'Paxinos',
  'Paxton',
  'Payatas',
  'Payerne',
  'Paynesville',
  'Payson',
  'Pazardzhik',
  'Pazin',
  'Páfos',
  'Páganos',
  'Pájara',
  'Pákozd',
  'Pánuco',
  'Pápa',
  'Pátra',
  'Páty',
  'Pátzcuaro',
  'Pähl',
  'Pärnu',
  'Pea Ridge',
  'Peabody',
  'Peace River',
  'Peach Glen',
  'Peachland',
  'Peachtree City',
  'Peakhurst',
  'Peapack',
  'Pearblossom',
  'Pearce',
  'Pearl',
  'Pearl City',
  'Pearl River',
  'Pearland',
  'Pearsall',
  'Pebble Beach',
  'Peccioli',
  'Peckville',
  'Pecky',
  'Pecos',
  'Pecq',
  'Pedaso',
  'Pedrajas De San Esteban',
  'Pedreguer',
  'Pedreira',
  'Pedro Escobedo',
  'Pedro Leopoldo',
  'Pedrola',
  'Pedrouços',
  'Pedrógão Grande',
  'Pedrulha',
  'Peebles',
  'Peekskill',
  'Peelamedu',
  'Peer',
  'Peers',
  'Peetri',
  'Pegnitz',
  'Pego',
  'Pegognaga',
  'Peine',
  'Peipohja',
  'Peiting',
  'Peitz',
  'Peize',
  'Peißenberg',
  'Pekanbaru',
  'Pekin',
  'Pelabuhan Klang',
  'Pelahatchie',
  'Pelayos De La Presa',
  'Pelham',
  'Pelhrimov',
  'Peligros',
  'Pell City',
  'Pella',
  'Pellenberg',
  'Pellérd',
  'Pellouailles-les-vignes',
  'Pelotas',
  'Pemberton',
  'Pembrey',
  'Pembroke',
  'Pembroke Dock',
  'Pembroke Pines',
  'Pen Argyl',
  'Penafiel',
  'Penaga',
  'Penampang',
  'Penang',
  'Penarth',
  'Penápolis',
  'Pencader',
  'Pence Springs',
  'Pencoed',
  'Pendergrass',
  'Pendik',
  'Pendlebury',
  'Pendleton',
  'Penedo',
  'Penela',
  'Penetanguishene',
  'Penfield',
  'Pengam',
  'Pengkalan Chepa',
  'Penha',
  'Peniche',
  'Penicuik',
  'Peninsula',
  'Penistone',
  'Penjaringan',
  'Penkridge',
  'Penley',
  'Penmon',
  'Penn',
  'Penn Laird',
  'Penn Valley',
  'Penn Yan',
  'Pennant Hills',
  "Penne-d'agenais",
  'Pennellville',
  'Penngrove',
  'Pennington',
  'Penns Grove',
  'Pennsauken',
  'Pennsburg',
  'Pennsville',
  'Pennsylvania Furnace',
  'Penobscot',
  'Penrhos',
  'Penrith',
  'Penrose',
  'Penryn',
  'Pensacola',
  'Penshurst',
  'Pensilvania',
  'Penta-di-casinca',
  'Penthalaz',
  'Penthièvre',
  'Penticton',
  'Pentling',
  'Penttila',
  'Pentyrch',
  'Penwortham',
  'Penza',
  'Penzance',
  'Penzberg',
  'Penzing',
  'Peñaranda De Bracamonte',
  'Peñíscola',
  'Peoria',
  'Peoria Heights',
  'Peosta',
  'Peotone',
  'Peper Harow',
  'Pepperell',
  'Pequannock',
  'Peradeniya',
  'Perafita',
  'Perafort',
  'Perai',
  'Peraía',
  'Peralta',
  'Perambalur',
  'Peräseinäjoki',
  'Perbál',
  'Perchtoldsdorf',
  'Perdido',
  'Peregian Beach',
  'Pereira',
  'Pereiro De Aguiar',
  'Perg',
  'Pergine Valsugana',
  'Pergola',
  'Perham',
  'Perillo',
  'Peris',
  'Peristéri',
  'Peristérion',
  'Perivale',
  'Perkam',
  'Perkasie',
  'Perkins',
  'Perkiomenville',
  'Perleberg',
  'Perlen',
  'Perm',
  'Permata',
  'Pernambuco',
  'Pernem',
  'Pernes-les-fontaines',
  'Pernik',
  'Perniö',
  'Pero',
  'Pero Pinheiro',
  'Perpignan',
  'Perranporth',
  'Perret',
  'Perreux-sur-marne',
  'Perrineville',
  'Perris',
  'Perroy',
  'Perry',
  'Perry Hall',
  'Perrysburg',
  'Perrysville',
  'Perryton',
  'Perryville',
  'Pershore',
  'Persia',
  'Perstorp',
  'Perth',
  'Perth Amboy',
  'Perth City',
  'Perttula',
  'Pertuis',
  'Pertunmaa',
  'Peru',
  'Perugia',
  'Perumanseri',
  'Perundurai',
  'Perungudi',
  'Perú',
  'Pervomaisk',
  'Pesaro',
  'Pescadero',
  'Pescantina',
  'Pescara',
  'Peschadoires',
  'Peschiera',
  'Peschiera Del Garda',
  'Pescia',
  'Peseux',
  'Peshawar',
  'Peshawar Lines',
  'Peshtigo',
  'Pesnica',
  'Pesnica Pri Mariboru',
  'Peso Da Régua',
  'Pesquero',
  'Pessac',
  'Pesse',
  'Pest',
  'Pesterwitz',
  'Petacciato',
  'Petah Tikwah',
  'Petah Tiqva',
  'Petah Tiqwa',
  'Petal',
  'Petaling',
  'Petaling Jaya',
  'Petaling Lama',
  'Petaluma',
  'Petatlán',
  'Petawawa',
  'Petäjävesi',
  'Peterboro',
  'Peterborough',
  'Peterbrough',
  'Peterhead',
  'Peterhof',
  'Peterlee',
  'Petersaurach',
  'Petersborough',
  'Petersburg',
  'Petersfehn',
  'Petersfield',
  'Petershagen',
  'Petershausen',
  'Peterslahr',
  'Petit Couronne',
  'Petit-rechain',
  'Petit-roeulx-lez-nivelles',
  'Petitcodiac',
  'Petite Flandre',
  'Petite Forêt',
  'Petone',
  'Petoskey',
  'Petrel',
  'Petrich',
  'Petrila',
  'Petrinja',
  'Petris',
  'Petrolia',
  'Petrolina',
  'Petropavlovsk-kamchatskiy',
  'Petrosani',
  'Petroúpolis',
  'Petrovce',
  'Petrovsko',
  'Petrozavodsk',
  'Petrópolis',
  'Petrvald',
  'Petten',
  'Petukangan Selatan',
  'Petukangan Utara',
  'Petworth',
  'Pevensey',
  'Pewaukee',
  'Pewee Valley',
  'Pewsey',
  'Peynier',
  "Peypin-d'aigues",
  'Peyriac-minervois',
  'Peyrieu',
  'Peyrolles-en-provence',
  'Peyruis',
  'Peyton',
  'Pezinok',
  'Pécel',
  'Péchabou',
  'Pécs',
  'Péfka',
  'Péfki',
  'Pégomas',
  'Pélissanne',
  'Pénjamo',
  'Pér',
  'Pérama',
  'Pérenchies',
  'Pérignat-lès-sarliève',
  'Périgny',
  'Périgueux',
  'Pérols',
  'Péronnas',
  'Péronne',
  'Péruwelz',
  'Pétange',
  'Pétionville',
  'Pfaffenhofen',
  'Pfaffenhofen An Der Glonn',
  'Pfaffenhofen An Der Ilm',
  'Pfaffenhofen An Der Roth',
  'Pfaffikon',
  'Pfaffing',
  'Pfalzgrafenweiler',
  'Pfarrkirchen',
  'Pfastatt',
  'Pfäffikon',
  'Pfäffingen',
  'Pfedelbach',
  'Pfeffikon',
  'Pfeffingen',
  'Pfettisheim',
  'Pflugerville',
  'Pforzen',
  'Pforzheim',
  'Pförring',
  'Pfronten',
  'Pfulgriesheim',
  'Pfullendorf',
  'Pfullingen',
  'Pfungen',
  'Pfungstadt',
  'Pfyn',
  'Phagwara',
  'Phalempin',
  'Phalsbourg',
  'Phaltan',
  'Phangnga',
  'Pharr',
  'Phasi Charoen',
  'Phaya Thai',
  'Phelps',
  'Phenix City',
  'Phetchaburi',
  'Phibsborough',
  'Philadelphia',
  'Philip',
  'Philippeville',
  'Philippi',
  'Philippine',
  'Philippsburg',
  'Philips',
  'Philipsburg',
  'Phillip',
  'Phillips',
  'Phillipsburg',
  'Philo',
  'Philomath',
  'Phitsanulok',
  'Phla',
  'Phnom Pen',
  'Phnom Penh',
  'Phnum Pénh',
  'Phoenicia',
  'Phoenix',
  'Phoenixville',
  'Phra Khanong',
  'Phra Pradaeng',
  'Phuket',
  'Pia',
  'Piabetá',
  'Piacenza',
  'Pian Camuno',
  'Pianello Val Tidone',
  'Pianezza',
  'Piano Di Follo',
  'Piano Di Sorrento',
  'Pianopoli',
  'Pianoro',
  'Pianzano',
  'Piarco',
  'Piaseczno',
  'Piasek',
  'Piaski',
  'Piatra Neamt',
  'Piazzola Sul Brenta',
  'Picaña',
  'Picasent',
  'Picayune',
  'Piccadilly',
  'Picerno',
  'Pichincha',
  'Pickens',
  'Pickering',
  'Pickerington',
  'Pico',
  'Pico Rivera',
  'Picos',
  'Picton',
  'Pictou',
  'Piding',
  'Piedade',
  'Piedimonte San Germano',
  'Piedimulera',
  'Piedmont',
  'Piedrabuena',
  'Piedras',
  'Piedras Negras',
  'Piekary Slaskie',
  'Piekoszow',
  'Pieksämäki',
  'Pielavesi',
  'Piemonte',
  'Piera',
  'Pierce',
  'Pierceton',
  'Piermont',
  'Pierre',
  'Pierre-bénite',
  'Pierre-buffière',
  'Pierrefeu-du-var',
  'Pierrefonds',
  'Pierrefontaine',
  'Pierrelatte',
  'Pierrelaye',
  'Piershil',
  'Piesendorf',
  'Piestany',
  'Piet Retief',
  'Pietarsaari',
  'Pieterlen',
  'Pietermaritzburg',
  'Pietersburg',
  'Pietra Ligure',
  'Pietrasanta',
  'Pietro',
  'Pieve',
  'Pieve Di Cento',
  'Pieve Di Soligo',
  'Pieve Porto Morone',
  'Pigádia',
  'Pigeon Forge',
  'Piggs Peak',
  'Pignan',
  'Pignataro Interamna',
  'Pigny',
  'Piikkiö',
  'Piispanristi',
  'Pijnacker',
  'Pikesville',
  'Piketon',
  'Pikeville',
  'Pikérmi',
  'Pikkala',
  'Pila',
  'Pilaía',
  'Pilani',
  'Pilar',
  'Pilar De La Horadada',
  'Pilar Do Sul',
  'Pilas',
  'Pilbara',
  'Pilerne',
  'Piles',
  'Pilimatalawa',
  'Pilisborosjeno',
  'Piliscsév',
  'Pilisszentiván',
  'Pilisvörösvár',
  'Piliyandala',
  'Pilkanmaa',
  'Pilkhuwa',
  'Pilot',
  'Pilot Butte',
  'Pilot Grove',
  'Pilot Hill',
  'Pilot Mountain',
  'Pilot Point',
  'Pilsen',
  'Pilton',
  'Pilzno',
  'Pima',
  'Pimpama',
  'Pimperne',
  'Pimpri',
  'Pina De Ebro',
  'Pinagbuhatan',
  'Pinamalayan',
  'Pinang',
  'Pinchbeck',
  'Pincher Creek',
  'Pinckney',
  'Pinckneyville',
  'Pinconning',
  'Pincourt',
  'Pindamonhangaba',
  'Pindelo',
  'Pine',
  'Pine Bluff',
  'Pine Brook',
  'Pine Bush',
  'Pine City',
  'Pine Grove',
  'Pine Hill',
  'Pine Mountain',
  'Pine Mountain Club',
  'Pine Mountain Valley',
  'Pine Plains',
  'Pinecrest',
  'Pineda',
  'Pinedale',
  'Pinehurst',
  'Pinellas Park',
  'Pinerolo',
  'Pineto',
  'Pinetop',
  'Pinetown',
  'Pineuilh',
  'Pineville',
  'Piney Flats',
  'Pingdingshan',
  'Pingdu',
  'Pinggirrawa Barat',
  'Pinggu',
  'Pinghu',
  'Pingluo',
  'Pingshan',
  'Pingtan',
  'Pingtung',
  'Pingxiang',
  'Pingyang',
  'Pinhais',
  'Pinhalzinho',
  'Pinheiral',
  'Pinheiros',
  'Pinhoe',
  'Pinjarra',
  'Pink Hill',
  'Pinkafeld',
  'Pinkenba',
  'Pinki',
  'Pinneberg',
  'Pinner',
  'Pinnow',
  'Pinole',
  'Pinos Puente',
  'Pinoso',
  'Pinsaguel',
  'Pinto',
  'Pinxton',
  'Pinzolo',
  'Piños',
  'Piolenc',
  'Pioltello',
  'Piombino',
  'Pioneer',
  'Pioneiros',
  'Piotrków Trybunalski',
  'Piotrowice',
  'Piove Di Sacco',
  'Piovene Rocchette',
  'Piper City',
  'Pipersville',
  'Pipestone',
  'Pippa Passes',
  'Piqua',
  'Piracicaba',
  'Piraeus',
  'Piran',
  'Pirangut',
  'Pirapòzinho',
  'Pirassununga',
  'Piravom',
  'Pirey',
  'Pirituba',
  'Pirkkala',
  'Pirmasens',
  'Pirna',
  'Pirot',
  'Pisa',
  'Pisany',
  'Pisarovina',
  'Piscataway',
  'Pischelsdorf',
  'Pisco',
  'Pisek',
  'Pisgah Forest',
  'Pismo Beach',
  'Pissos',
  'Pistoia',
  'Pita Kotte',
  'Pitcairn',
  'Piteå',
  'Pitesti',
  'Pithapuram',
  'Pithoragarh',
  'Pitlochry',
  'Pitman',
  'Pitney',
  'Pitsford',
  'Pitstone',
  'Pitt Meadows',
  'Pittem',
  'Pittenweem',
  'Pittsboro',
  'Pittsburg',
  'Pittsburgh',
  'Pittsfield',
  'Pittsford',
  'Pittston',
  'Pittstown',
  'Pittsview',
  'Pittsville',
  'Piubega',
  'Piura',
  'Piúma',
  'Pivka',
  'Pixbo',
  'Pixian',
  'Pizhou',
  'Placencia De Las Armas',
  'Placentia',
  'Placerville',
  'Placé',
  'Placida',
  'Placitas',
  'Plaidt',
  'Plailly',
  'Plain',
  'Plain City',
  'Plainfield',
  'Plains',
  'Plainsboro',
  'Plainview',
  'Plainville',
  'Plainwell',
  'Plaisance-du-touch',
  'Plaisir',
  'Plaistow',
  'Plamondon',
  "Plan-d'orgon",
  'Plan-de-grasse',
  'Plan-les-ouates',
  'Plana',
  'Plana Nad Luznici',
  'Planaltina',
  'Planá',
  'Planegg',
  'Plankenberg',
  'Plankstadt',
  'Plano',
  'Plant City',
  'Plantagenet',
  'Plantation',
  'Plantersville',
  'Plantsville',
  'Plaquemine',
  'Plasencia',
  'Plataniá',
  'Plate',
  'Plateau',
  'Plato',
  'Platte City',
  'Platteville',
  'Plattling',
  'Plattsburg',
  'Plattsburgh',
  'Plattsmouth',
  'Plauen',
  'Playa',
  'Playa Coronado',
  'Playa Del Carmen',
  'Playa Del Rey',
  'Playa Vista',
  'Plaza',
  'Pláka',
  'Plätz',
  'Pleasant Garden',
  'Pleasant Grove',
  'Pleasant Hill',
  'Pleasant Mount',
  'Pleasant Prairie',
  'Pleasant Ridge',
  'Pleasant Valley',
  'Pleasant View',
  'Pleasanton',
  'Pleasantville',
  'Plegamans',
  'Pleidelsheim',
  'Plein',
  'Pleine-selve',
  'Pleiskirchen',
  'Plesching',
  'Plescop',
  'Pleslin',
  'Plessa',
  'Plessis-robinson',
  'Plessisville',
  'Plestin-les-grèves',
  'Plettenberg',
  'Pleubian',
  'Pleucadeuc',
  'Pleugriffet',
  'Pleumeur-bodou',
  'Pleurtuit',
  'Pleven',
  'Plewiska',
  'Pleystein',
  'Pléchâtel',
  'Plélo',
  'Plérin',
  'Pliening',
  'Pliezhausen',
  'Plobsheim',
  'Plochingen',
  'Plochocin',
  'Plock',
  'Ploemeur',
  'Ploërmel',
  'Ploiesti',
  'Plomeur',
  'Plonia',
  'Plonsk',
  'Plouay',
  'Ploudaniel',
  'Plouédern',
  'Plouër-sur-rance',
  'Ploufragan',
  'Plougastel-daoulas',
  'Plougoumelen',
  'Plouhinec',
  'Plouigneau',
  'Ploumagoar',
  'Plouvorn',
  'Plouzané',
  'Plovdiv',
  'Plover',
  'Plön',
  'Pluckemin',
  'Pluckley',
  'Pluguffan',
  'Pluit',
  'Plum Branch',
  'Plumelin',
  'Plumsteadville',
  'Plüderhausen',
  'Plymouth',
  'Plymouth Meeting',
  'Plympton',
  'Plzen',
  'Poá',
  'Pobla De Mafumet',
  'Pobla De Segur',
  'Poca',
  'Pocahontas',
  'Pocasset',
  'Pocatello',
  'Pochon',
  'Pochutla',
  'Pocking',
  'Pocklington',
  'Pocomoke City',
  'Pocono Pines',
  'Pocono Summit',
  'Pocopson',
  'Poczesna',
  'Poços De Caldas',
  'Poços de Caldas',
  'Podari',
  'Podbrezi',
  'Podbrezová',
  'Podebrady',
  'Podensac',
  'Podenzano',
  'Podere Liguria',
  'Podgorci',
  'Podgorica',
  'Podgorje',
  'Podgorze',
  'Podhorie',
  'Podlaski',
  'Podlesna',
  'Podolsk',
  'Podomoro',
  'Podskalie',
  'Podstrana',
  'Podvysoká',
  'Poederoijen',
  'Poeldijk',
  'Poestenkill',
  'Poggibonsi',
  'Pogliano Milanese',
  'Pohang',
  'Pohjankuru',
  'Pohjois-haaga',
  'Pohjois-savo',
  'Pohorelice',
  'Pohorsko',
  'Poiana Maggiore',
  'Poiana Sibiului',
  'Poil',
  'Poilley',
  'Poing',
  'Point Arena',
  'Point Cook',
  'Point Edward',
  'Point Lonsdale',
  'Point Lookout',
  'Point Pleasant',
  'Point Pleasant Beach',
  'Point Reyes Station',
  'Point Roberts',
  'Pointe Aux Canonniers',
  'Pointe Noire',
  'Pointe-à-pierre',
  'Pointe-claire',
  'Poirino',
  'Poissy',
  'Poisy',
  'Poitiers',
  'Pojuca',
  'Pokhara',
  'Pokolbin',
  'Pola De Lena',
  'Polanco',
  'Poland',
  'Polaniec',
  'Polanka Wielka',
  'Polch',
  'Polebrook',
  'Polegate',
  'Polesine',
  'Polevsko',
  'Polgár',
  'Polhov Gradec',
  'Police',
  'Police Nad Metuji',
  'Policka',
  'Policoro',
  'Poliénas',
  'Polignano A Mare',
  'Poligny',
  'Polinyá',
  'Polistena',
  'Poljane',
  'Poljane Nad Skofjo Loko',
  'Polk',
  'Polkton',
  'Pollachi',
  'Pollenfeld',
  'Pollenza',
  'Polleur',
  'Polmont',
  'Polna',
  'Polop',
  'Polska',
  'Polska Nowa',
  'Polson',
  'Poltava',
  'Poltár',
  'Polverara',
  'Polverigi',
  'Pomacle',
  'Pomarkku',
  'Pomáz',
  'Pombal',
  'Pomerol',
  'Pomeroy',
  'Pomezia',
  "Pomigliano D'arco",
  'Pomona',
  'Pomorie',
  'Pomorskie',
  'Pompadour',
  'Pompano Beach',
  'Pompey',
  'Pompidou',
  'Pompton Lakes',
  'Pompton Plains',
  'Ponca City',
  'Ponce',
  'Ponce De Leon',
  'Poncha Springs',
  'Ponchatoula',
  'Ponda',
  'Ponderay',
  'Pondicherry',
  'Pondichéry',
  'Pondokindah',
  'Ponferrada',
  'Ponoka',
  'Ponorogo',
  'Ponsacco',
  'Ponsonby',
  'Pont',
  'Pont-Évêque',
  'Pont-aven',
  'Pont-à-celles',
  'Pont-à-vendin',
  "Pont-de-l'isere",
  'Pont-de-roide',
  'Pont-du-casse',
  'Pont-du-château',
  "Pont-l'abbe",
  "Pont-l'eveque",
  'Pont-rouge',
  'Pont-saint-esprit',
  'Pont-saint-martin',
  'Pont-sainte-marie',
  'Pont-sainte-maxence',
  'Pont-salomon',
  'Ponta Delgada',
  'Ponta Grossa',
  'Pontailler-sur-saône',
  'Pontarlier',
  'Pontassieve',
  'Pontault',
  'Pontcarré',
  'Pontcharra',
  'Pontchâteau',
  'Ponte De Lima',
  'Ponte Di Barbarano',
  'Ponte Di Piave',
  'Ponte Do Lima',
  'Ponte Nelle Alpi',
  'Ponte San Giovanni',
  'Ponte San Nicolo',
  'Ponte San Nicolò',
  'Ponte San Pietro',
  'Ponte Vedra',
  'Ponte Vedra Beach',
  'Pontecagnano',
  'Pontecorvo',
  'Pontedera',
  'Pontefract',
  'Pontelungo',
  'Pontevedra',
  'Pontevico',
  'Ponthaux',
  'Pontiac',
  'Pontian',
  'Pontianak',
  'Pontida',
  'Pontinha',
  'Pontivy',
  'Pontoise',
  'Pontotoc',
  'Pontpierre',
  'Pontremoli',
  'Pontrieux',
  'Pontrilas',
  'Pontypool',
  'Pontypridd',
  'Ponzano',
  'Poole',
  'Pooler',
  'Poolesville',
  'Poonamallee',
  'Poortugaal',
  'Popayán',
  'Poperinge',
  'Popesti',
  'Popesti-leordeni',
  'Popice',
  'Poplar',
  'Poplar Bluff',
  'Poplar Grove',
  'Poplarville',
  'Popovice',
  'Poppenweiler',
  'Poppi',
  'Poppleton',
  'Poprad',
  'Popuvky',
  'Poquoson',
  'Porbandar',
  'Porcari',
  'Porcia',
  'Porcupine',
  'Pordenone',
  'Porec',
  'Pori',
  'Poricany',
  'Poringland',
  'Porirua',
  'Porlock',
  'Pornainen',
  'Pornic',
  'Pornichet',
  'Porqueras',
  'Porrentruy',
  'Porreras',
  'Porriño',
  'Porsea',
  'Porsgrunn',
  'Port Adelaide',
  'Port Alberni',
  'Port Allegany',
  'Port Allen',
  'Port Angeles',
  'Port Aransas',
  'Port Arthur',
  'Port Aux Basques',
  'Port Blair',
  'Port Botany',
  'Port Charlotte',
  'Port Chester',
  'Port Clinton',
  'Port Colborne',
  'Port Coquitlam',
  'Port Credit',
  'Port Deposit',
  'Port Dickson',
  'Port Douglas',
  'Port Elizabeth',
  'Port Ellen',
  'Port Ewen',
  'Port Fairy',
  'Port Gentil',
  'Port Glasgow',
  'Port Hadlock',
  'Port Harcourt',
  'Port Haywood',
  'Port Hope',
  'Port Hueneme',
  'Port Huron',
  'Port Jefferson',
  'Port Jefferson Station',
  'Port Jervis',
  'Port Kembla',
  'Port Klang',
  'Port Laoise',
  'Port Lavaca',
  'Port Louis',
  'Port Louis Town',
  'Port Macquarie',
  'Port Mathurin',
  'Port Matilda',
  'Port Melbourne',
  'Port Monmouth',
  'Port Moody',
  'Port Moresby',
  'Port Motueka',
  'Port Neches',
  'Port Nelson',
  'Port Orange',
  'Port Orchard',
  'Port Perry',
  'Port Republic',
  'Port Richey',
  'Port Royal',
  'Port Saint Joe',
  'Port Saint Lucie',
  'Port Shepstone',
  'Port St. Lucie',
  'Port Talbot',
  'Port Tobacco',
  'Port Townsend',
  'Port Trevorton',
  'Port Vincent',
  'Port Waikato',
  'Port Washington',
  'Port-au-prince',
  'Port-au-spain',
  'Port-en-bessin',
  'Port-joinville',
  'Port-of-spain',
  'Port-saint-père',
  'Port-sainte-foy-et-ponchapt',
  'Port-valais',
  'Port-vendres',
  'Porta Westfalica',
  'Portadown',
  'Portage',
  'Portage Des Sioux',
  'Portage La Prairie',
  'Portalegre',
  'Portales',
  'Portarlington',
  'Portchester',
  'Portela',
  'Portella Di Mare',
  'Porter',
  'Porters',
  'Portersville',
  'Porterville',
  'Portes-lès-valence',
  'Porth',
  'Porthcawl',
  'Porthleven',
  'Porthmadog',
  'Portico Di Caserta',
  'Portillo De Toledo',
  'Portimão',
  'Portishead',
  'Portknockie',
  'Portland',
  'Portlethen',
  'Portmarnock',
  'Portmore',
  'Porto',
  'Porto Alegre',
  'Porto Belo',
  'Porto Bom',
  'Porto Cristo',
  "Porto D'ascoli",
  'Porto Nacional',
  'Porto Potenza Picena',
  'Porto Real',
  'Porto Recanati',
  'Porto Salvo',
  'Porto San Giorgio',
  "Porto Sant'elpidio",
  'Porto-novo',
  'Porto-vecchio',
  'Portobello',
  'Portoferraio',
  'Portogruaro',
  'Portola',
  'Portola Valley',
  'Portomaggiore',
  'Portoroz',
  'Portoviejo',
  'Portree',
  'Portrush',
  'Portskewett',
  'Portslade',
  'Portsmouth',
  'Portstewart',
  'Portugal',
  'Portugalete',
  'Poruba',
  'Porur',
  'Porvoo',
  'Posadas',
  'Poschiavo',
  'Posen',
  'Poseyville',
  'Post Falls',
  'Post Mills',
  'Postbauer-heng',
  'Postojna',
  'Postville',
  'Potchefstroom',
  'Poteau',
  'Potenza',
  'Poth',
  'Potomac',
  'Potosi',
  'Potrero De Mapula',
  'Potsdam',
  'Pottelsdorf',
  'Pottenbrunn',
  'Pottenstein',
  'Potter Valley',
  'Potters Bar',
  'Pottersville',
  'Potton',
  'Potts Point',
  'Pottsboro',
  'Pottstown',
  'Pottsville',
  'Pottsville Beach',
  'Pottum',
  'Potwin',
  'Pouch',
  'Poucharramet',
  'Poucnik',
  'Poughkeepsie',
  'Pougues-les-eaux',
  'Pouilley-les-vignes',
  'Poulsbo',
  'Poultney',
  'Poulton',
  'Poulton Le Fylde',
  'Pound',
  'Pound Ridge',
  'Pounding Mill',
  'Pourcy',
  'Pourrières',
  'Pouso Alegre',
  'Pouzauges',
  'Povazska Bystrica',
  'Povazsky Chlmec',
  'Povedilla',
  'Povegliano',
  'Povegliano Veronese',
  'Povolaro',
  'Powai',
  'Poway',
  'Powder Springs',
  'Powell',
  'Powell River',
  'Powells Point',
  'Powers Lake',
  'Powhatan',
  'Powhattan',
  'Pownal',
  'Poxdorf',
  'Poynton',
  'Pozarevac',
  'Pozega',
  'Poznan',
  'Pozo Alcón',
  'Pozoblanco',
  'Pozuelo',
  'Pozuelo De Alarcón',
  'Pozuelo De Calatrava',
  'Pozuelo De Vidriales',
  'Pozzuoli',
  'Pórtugos',
  'Póvoa De Lanhoso',
  'Póvoa De Santa Iria',
  'Póvoa De Varzim',
  'Pôrto Alegre',
  'Pôrto Feliz',
  'Pôrto Seguro',
  'Pôrto União',
  'Pôrto Velho',
  'Pôtor',
  'Põlva',
  'Pölchow',
  'Pölten',
  'Pöttelsdorf',
  'Pößneck',
  'Prachatice',
  'Prachuap Khiri Khan',
  'Pradelles-cabardès',
  'Prades-le-lez',
  'Pradoluengo',
  'Pradópolis',
  'Prag',
  'Praga',
  'Prague',
  'Prague-vinohrady',
  'Praha',
  'Prahran',
  'Prahran East',
  'Prai',
  'Praia',
  'Praia Da Vitória',
  'Praia Grande',
  'Prairie',
  'Prairie Du Sac',
  'Prairie View',
  'Prairie Village',
  'Prairieville',
  'Prakanong',
  'Pralong',
  'Pramaggiore',
  'Prangins',
  'Prashad Nagar',
  'Praslavice',
  'Prat De Llobregat',
  'Prata Camportaccio',
  'Pratapgarh',
  'Prato',
  'Pratola',
  'Pratt',
  'Pratteln',
  'Prattville',
  'Pratz',
  'Pravia',
  'Prayssac',
  'Praz',
  'Präg',
  'Preajba',
  'Prebbleton',
  'Predazzo',
  'Predklasteri',
  'Predmerice Nad Labem',
  'Preetz',
  'Preganziol',
  'Pregassona',
  'Preitenegg',
  'Preith',
  'Preixan',
  'Prelouc',
  'Premiá De Mar',
  'Premnitz',
  'Premstatten',
  'Prenzlau',
  'Prenzlauer Berg',
  'Prerov',
  'Prescot',
  'Prescott',
  'Prescott Valley',
  'Preserje',
  'Presidente Prudente',
  'Presinge',
  'Presnenskiy',
  'Presov',
  'Presque Isle',
  'Pressath',
  'Pressig',
  'Prestanov',
  'Prestatyn',
  'Prestbury',
  'Presteigne',
  'Prestfoss',
  'Prestice',
  'Presto',
  'Preston',
  'Preston Brook',
  'Preston West',
  'Prestonsburg',
  'Prestwich',
  'Prestwick',
  'Pretoria',
  'Preures',
  'Preußisch Oldendorf',
  'Prevalje',
  'Prevalle',
  'Pré Saint Gervais',
  'Pré-en-pail',
  'Présilly',
  'Préveranges',
  'Préveza',
  'Prêles',
  'Pribor',
  'Pribram',
  'Prides Crossing',
  'Priego De Córdoba',
  'Priekopa',
  'Prien Am Chiemsee',
  'Priest River',
  'Prievidza',
  'Prigonrieux',
  'Prilep',
  'Prilly',
  'Primavera',
  'Prince Albert',
  'Prince Frederick',
  'Prince George',
  'Prince Rupert',
  'Princes Risborough',
  'Princesa Isabel',
  'Princess Anne',
  'Princeton',
  'Princeton Junction',
  'Princeville',
  'Prineville',
  'Pringy',
  'Prinsenbeek',
  'Priolo Gargallo',
  'Prior Lake',
  'Priozersk',
  'Prisdorf',
  'Prishtinë',
  'Prisian',
  'Pristina',
  'Pritzwalk',
  'Priuk',
  'Privas',
  'Prkovci',
  'Probolinggo',
  'Profondeville',
  'Proissans',
  'Project 6',
  'Prole',
  'Prophetstown',
  'Prosec',
  'Prosek',
  'Prosimerice',
  'Prospect',
  'Prospect Heights',
  'Prospect Park',
  'Prosper',
  'Prosperity',
  'Prosser',
  'Prostejov',
  'Protvino',
  'Prouvy',
  'Provence',
  'Providence',
  'Providencia',
  'Proville',
  'Provincetown',
  'Provo',
  'Prozor',
  'Prstice',
  'Prudhoe',
  'Pruhonice',
  'Pruillé-le-chétif',
  'Prunières',
  'Prusanky',
  'Pruszcz Gdanski',
  'Pruszkow',
  'Pruszków',
  'Prüm',
  'Prvacina',
  'Pryor',
  'Przasnysz',
  'Przedbórz',
  'Przemysl',
  'Przezmierowo',
  'Przykona',
  'Przytarnia',
  'Præstø',
  'Psichiko',
  'Pskov',
  'Psychico',
  'Psychiko',
  'Pszczyna',
  'Pszów',
  'Ptuj',
  'Puch Bei Hallein',
  'Puchenau',
  'Pucheng',
  'Puchheim',
  'Puchong',
  'Puchong New Village',
  'Pucón',
  'Pudong',
  'Pudsey',
  'Puducherry',
  'Pudukkottai',
  'Puebla',
  'Puebla De Alfindén',
  'Puebla De Cazalla',
  'Puebla De Vallbona',
  'Puebla De Zaragoza',
  'Pueblo',
  'Pueblo Libre',
  'Puente De Domingo Flórez',
  'Puente De Vallecas',
  'Puente-genil',
  'Puenteareas',
  'Puentes De García Rodríguez',
  'Puer',
  'Puerto',
  'Puerto Colombia',
  'Puerto De Andraitx',
  'Puerto De La Cruz',
  'Puerto De Sagunto',
  'Puerto De Santiago',
  'Puerto Del Rosario',
  'Puerto Escondido',
  'Puerto Madero',
  'Puerto Madryn',
  'Puerto Maldonado',
  'Puerto Montt',
  'Puerto Morelos',
  'Puerto Natales',
  'Puerto Nuevo',
  'Puerto Peñasco',
  'Puerto Princesa City',
  'Puerto Real',
  'Puerto Rico',
  'Puerto Suárez',
  'Puerto Tejada',
  'Puerto Vallarta',
  'Puerto Varas',
  'Puerto Viejo',
  'Puertollano',
  'Puget-ville',
  'Pugnac',
  'Puhos',
  'Puidoux',
  'Puig De Santa María',
  'Puigcerdá',
  'Puilboreau',
  'Puiseux',
  'Puiseux-pontoise',
  'Pujaudran',
  'Pukalani',
  'Pukekohe East',
  'Pula',
  'Pulaski',
  'Pulau Pinang',
  'Pulawy',
  'Pulborough',
  'Pulford',
  'Pulheim',
  'Pulianas',
  'Pulkovo',
  'Pullach',
  'Pullach Im Isartal',
  'Pulle',
  'Pullen Vale',
  'Pullenreuth',
  'Pullman',
  'Pully',
  'Pulogadung',
  'Pulomas',
  'Pulpí',
  'Pulsa',
  'Pulsnitz',
  'Pultusk',
  'Pulwama',
  'Puna',
  'Punat',
  'Pune',
  'Puning',
  'Punta Arenas',
  'Punta Cana',
  'Punta Del Hidalgo',
  'Punta Gorda',
  'Puntarenas',
  'Punxsutawney',
  'Pupyonggu',
  'Pura',
  'Purasawalkam',
  'Purbach',
  'Purcellville',
  'Purchase',
  'Purdon',
  'Purdy',
  'Purdys',
  'Pure',
  'Purfleet',
  'Purgatorio',
  'Puri',
  'Purkersdorf',
  'Purley',
  'Purling',
  'Purmerend',
  'Purnia',
  'Purton Stoke',
  'Puruliya',
  'Purvis',
  'Purwokerto',
  'Purworejo',
  'Pusa',
  'Pusan',
  'Pushkin',
  'Pushkina',
  'Pushkino',
  'Pusignan',
  'Pusing',
  'Puslinch',
  'Pustimer',
  'Pustkovec',
  'Pusztazámor',
  'Putatan',
  'Puteaux',
  'Puth',
  'Putian',
  'Putignano',
  'Putivl',
  'Putnam',
  'Putnam Valley',
  'Putney',
  'Putnok',
  'Putrajaya',
  'Puttalam',
  'Putte',
  'Putten',
  'Puttenham',
  'Puttershoek',
  'Puttur',
  'Putuo',
  'Putzbrunn',
  'Puunene',
  'Puurs',
  'Puxi',
  'Puyallup',
  'Puyang',
  'Puygouzon',
  'Puymiclan',
  'Puyricard',
  'Puzol',
  'Púchov',
  'Pürgen',
  'Püspökladány',
  'Püttlingen',
  'Pwllheli',
  'Pyatigorsk',
  'Pyeong',
  'Pyeongtaeg',
  'Pyhäranta',
  'Pylaía',
  'Pyle',
  'Pymble',
  'Pyongtaek',
  'Pyongyang',
  'Pyrbaum',
  'Pyrford',
  'Pyrmont',
  'Qadima',
  'Qalansuwa',
  'Qatar',
  'Qazvin',
  'Qena',
  'Qesarya',
  'Qian',
  'Qianan',
  'Qiandao',
  'Qianhai',
  'Qianjiang',
  'Qianshan',
  'Qidong',
  'Qihe',
  'Qijiang',
  'Qingdao',
  'Qingdao Shi',
  'Qinghai',
  'Qinghua',
  'Qingpu',
  'Qingpu Qu',
  'Qingshan',
  'Qingyuan',
  'Qingzhou',
  'Qinhuangdao',
  'Qinyang',
  'Qinzhou',
  'Qionghai',
  'Qionglai',
  'Qiqihar',
  'Qiryat Bialik',
  'Qiryat Gat',
  'Qiryat Motzkin',
  'Qiryat Ono',
  'Qiryat Shemona',
  'Qiryat Shmona',
  'Qiryat Yam',
  'Qishi',
  'Qom',
  'Qormi',
  'Quadra',
  'Quainton',
  'Quakenbrück',
  'Quaker Hill',
  'Quaker Street',
  'Quakers Hill',
  'Quakertown',
  'Qualiano',
  'Qualicum Beach',
  'Quantico',
  'Quanzhou',
  'Quapaw',
  'Quaregnon',
  'Quarnbek',
  'Quarrata',
  'Quarry Bay',
  'Quarryville',
  'Quart',
  'Quarteira',
  'Quarter',
  'Quartino',
  'Quarto',
  "Quarto D'altino",
  'Quarto Inferiore',
  "Quartu Sant'elena",
  'Quartucciu',
  'Quassolo',
  'Quatre Bornes',
  'Quatro Barras',
  'Quattro Castella',
  'Queanbeyan',
  'Quebec',
  'Quebeck',
  'Quebradilla',
  'Quechee',
  'Quedgeley',
  'Quedlinburg',
  'Queen Charlotte',
  'Queen City',
  'Queen Creek',
  'Queens',
  'Queens Beach',
  'Queens Village',
  'Queensbury',
  'Queenscliff',
  'Queensferry',
  'Queenstown',
  'Queensville',
  'Queijas',
  'Queimados',
  'Quel',
  'Queluz',
  'Quentin',
  'Quepos',
  'Quercamps',
  'Querétaro',
  'Querfurt',
  'Quero',
  'Quesnel',
  'Quetta',
  'Quetzaltenango',
  'Quezon',
  'Quezon City',
  'Québec',
  'Québriac',
  'Quétigny',
  'Quéven',
  'Quévert',
  'Qufu',
  'Qui Nhon',
  'Quiapo',
  'Quickborn',
  'Quierschied',
  'Quiévrechain',
  'Quijorna',
  'Quilcene',
  'Quilicura',
  'Quilleco',
  'Quilmes',
  'Quilpué',
  'Quimper',
  'Quin',
  'Quincy',
  'Quincy-voisins',
  'Quinlan',
  'Quint',
  'Quinta De Tilcoco',
  'Quinta Do Anjo',
  'Quintal',
  'Quintana',
  'Quintana Roo',
  'Quintanar De La Orden',
  'Quintero',
  'Quinto Di Treviso',
  'Quinto Vicentino',
  'Quinton',
  'Quispamsis',
  'Quitman',
  'Quito',
  'Quixadá',
  'Qujing',
  'Quogue',
  'Qurm',
  'Quzhou',
  "Ra'anana",
  "Ra'ananna",
  'Ra`ananah',
  'Raa-besenbek',
  'Raaba',
  'Raahe',
  'Raalte',
  'Raamsdonkveer',
  'Raasdorf',
  'Raasepori',
  'Rab',
  'Rabat',
  'Raben Steinfeld',
  'Rabenau',
  'Rabenstein',
  'Rabigh',
  'Racale',
  'Racconigi',
  'Race',
  'Raciborz',
  'Racibórz',
  'Racine',
  'Rackheath',
  'Rackwitz',
  'Radaur',
  'Radauti',
  'Radbruch',
  'Radcliff',
  'Radcliffe',
  'Radcliffe On Trent',
  'Radeberg',
  'Radebeul',
  'Radeburg',
  'Radece',
  'Radefeld',
  'Radegund Bei Graz',
  'Radelfingen',
  'Radevormwald',
  'Radford',
  'Radibor',
  'Radicondoli',
  'Radlett',
  'Radley',
  'Radlice',
  'Radlje Ob Dravi',
  'Radnor',
  'Radolfzell',
  'Radolfzell Am Bodensee',
  'Radom',
  'Radomlje',
  'Radomsko',
  'Radosina',
  'Radovljica',
  'Radslavice',
  'Radstadt',
  'Radstock',
  'Radzymin',
  'Raesfeld',
  'Rafaela',
  'Rafelbuñol',
  'Raffa',
  'Rafz',
  'Raggal',
  'Raggio',
  'Raghogarh',
  'Raglan',
  'Raguhn',
  'Ragusa',
  'Rahat',
  'Rahata',
  'Rahden',
  'Raheny',
  'Rahimyar Khan',
  'Rahway',
  'Raichur',
  'Raiganj',
  'Raigarh',
  'Rail Road Flat',
  'Rain',
  'Rainbach',
  'Rainbow City',
  'Rainbow Flat',
  'Rainham',
  'Rainhill',
  'Rainier',
  'Rainsville',
  'Raipur',
  'Raisio',
  'Raismes',
  'Raisting',
  'Raitenbuch',
  'Raiva',
  'Rajagiriya',
  'Rajahmundry',
  'Rajamäki',
  'Rajapalayam',
  'Rajec-jestrebi',
  'Rajecké Teplice',
  'Rajgangpur',
  'Rajgarh',
  'Rajhrad',
  'Rajim',
  'Rajkot',
  'Rajpura',
  'Rajshahi',
  'Rakaia',
  'Rake',
  'Rakek',
  'Rakov Potok',
  'Raková',
  'Rakovnik',
  'Rakovo',
  'Rakovski',
  'Rakvere',
  'Raleigh',
  'Ralston',
  'Ram On',
  'Ramada',
  'Ramadan City',
  'Ramah',
  'Ramalhal',
  'Ramallah',
  'Ramanathapuram',
  'Ramapuram',
  'Ramat Aviv',
  "Ramat Ef'al",
  'Ramat Eshkol',
  'Ramat Gan',
  'Ramat Hadar',
  'Ramat Hakovesh',
  'Ramat Hakovésh',
  'Ramat Hasharon',
  'Ramat Hayal',
  'Ramat Poleg',
  'Ramat Yishai',
  'Ramat Yishay',
  'Rambouillet',
  'Rambukkana',
  'Rameldange',
  'Ramelsloh',
  'Ramenskiy',
  'Ramenskoye',
  'Ramerberg',
  'Ramgarh',
  'Ramillies-offus',
  'Ramismuhle',
  'Ramla',
  'Ramlösa',
  'Ramnagar',
  'Ramnicu Valcea',
  'Ramnicul Valcea',
  'Ramona',
  'Ramonville',
  'Ramonville-saint-agne',
  'Ramos Arizpe',
  'Ramos Mejía',
  'Ramot Hashavim',
  'Ramot Menashe',
  'Ramotswa',
  'Rampur',
  'Ramsbottom',
  'Ramsbury',
  'Ramsdalen',
  'Ramsel',
  'Ramsen',
  'Ramseur',
  'Ramsey',
  'Ramsgate',
  'Ramsjö',
  'Ramstein',
  'Ramstein-miesenbach',
  'Ramvik',
  'Ranaghat',
  'Rancagua',
  'Rances',
  'Ranchi',
  'Rancho',
  'Rancho Cordova',
  'Rancho Cucamonga',
  'Rancho Dominguez',
  'Rancho Mirage',
  'Rancho Palos Verdes',
  'Rancho Santa Fe',
  'Rancho Santa Margarita',
  'Ranchot',
  'Ranco',
  'Rancocas',
  'Randaberg',
  'Randallstown',
  'Randburg',
  'Randbøldal',
  'Randers',
  'Randersacker',
  'Randfontein',
  'Randolph',
  'Randolph Center',
  'Random Lake',
  'Randvere',
  'Randwick',
  'Ranelagh',
  'Rang-du-fliers',
  'Rangamati',
  'Rangampet',
  'Rangely',
  'Ranger',
  'Rangoon',
  'Rangpo',
  'Rangpur',
  'Ranheim',
  'Rania',
  'Raniganj',
  'Rankweil',
  'Ransart',
  'Ransbach-baumbach',
  'Ransomville',
  'Ranson',
  'Ranst',
  'Rantigny',
  'Rantoul',
  'Rantrum',
  'Rapagnano',
  'Rapallo',
  'Rapid City',
  'Rapids City',
  'Rapla',
  'Rapone',
  'Rapotin',
  'Rapperswil',
  'Raritan',
  'Ras Al Khaima',
  'Ras Al Khaimah',
  'Rascafría',
  'Rasdorf',
  'Raseiniai',
  'Rashdia',
  'Rasht',
  'Rask Mølle',
  'Raslavice',
  'Rasnov',
  'Rastatt',
  'Rastede',
  'Rastenberg',
  'Rastorf',
  'Raszyn',
  'Rat Burana',
  'Ratchathewi',
  'Ratcliffe',
  'Ratekau',
  'Rathcoole',
  'Rathdrum',
  'Rathenow',
  'Rathfarnham',
  'Rathfriland',
  'Rathgar',
  'Rathmines',
  'Rathnew',
  'Ratingen',
  'Ratisbon',
  'Ratlam',
  'Ratmalana',
  'Ratnagiri',
  'Ratnapura',
  'Ratoath',
  'Ratoma',
  'Rattelsdorf',
  'Raubach',
  'Raubling',
  'Raudeberg',
  'Rauenberg',
  'Raufoss',
  'Rauha',
  'Rauma',
  'Raumbach',
  'Raunds',
  'Raunheim',
  'Ravels',
  'Ravena',
  'Ravenel',
  'Ravenna',
  'Ravensburg',
  'Ravenstein',
  'Ravenswood',
  'Ravne',
  'Rawai',
  'Rawalpindi',
  'Rawalpindi Cantonment',
  'Rawamangun',
  'Rawang',
  'Rawat',
  'Rawcliffe',
  'Rawdon',
  'Rawlins',
  'Rawtenstall',
  'Ray',
  'Ray Brook',
  'Ray City',
  'Rayland',
  'Rayleigh',
  'Raymond',
  'Raymond Terrace',
  'Raymore',
  'Rayne',
  'Raynham',
  'Raynham Center',
  'Rayong',
  'Rayville',
  'Razavi',
  'Razlog',
  'Rábapatona',
  'Rábapaty',
  'Ráckeve',
  'Rákospalota',
  'Râmnicu Vâlcea',
  'Räpina',
  'Rättvik',
  'Rävlanda',
  'Råå',
  'Råde',
  'Rånåsfoss',
  'Rångedala',
  'Råsunda',
  'Rea',
  'Readfield',
  'Reading',
  'Readyville',
  'Real De Catorce',
  'Reana Del Roiale',
  'Rearsby',
  'Rebecq-rognon',
  'Rebesgrün',
  'Rebesovice',
  'Rebeuville',
  'Rebordosa',
  'Rebouças',
  'Rebstein',
  'Recanati',
  'Rechberghausen',
  'Recife',
  'Recklinghausen',
  'Reckovice',
  'Recoleta',
  'Red Bank',
  'Red Banks',
  'Red Bay',
  'Red Beach',
  'Red Bluff',
  'Red Bud',
  'Red Deer',
  'Red Hill',
  'Red Hook',
  'Red Lion',
  'Red Lodge',
  'Red Oak',
  'Red Rock',
  'Red Wing',
  'Redan',
  'Redange',
  'Redbank',
  'Redbourn',
  'Redcar',
  'Redcliff',
  'Redcliffe',
  'Redcliffs',
  'Reddick',
  'Redding',
  'Redding Center',
  'Reddish',
  'Redditch',
  'Redenção',
  'Redfern',
  'Redfield',
  'Redford',
  'Redhead',
  'Redhill',
  'Redland Bay',
  'Redlands',
  'Redmond',
  'Rednitzhembach',
  'Redon',
  'Redonda',
  'Redondela',
  'Redondo',
  'Redondo Beach',
  'Redruth',
  'Redtsino',
  'Reduit',
  'Redwitz An Der Rodach',
  'Redwood',
  'Redwood City',
  'Redwood Estates',
  'Redwood Falls',
  'Redwood Shores',
  'Redwood Valley',
  'Reed City',
  'Reedley',
  'Reeds Spring',
  'Reedsburg',
  'Reedsport',
  'Reedsville',
  'Reefton',
  'Reepham',
  'Rees',
  'Reet',
  'Reeth',
  'Reeuwijk',
  'Reform',
  'Regau',
  'Regen',
  'Regensberg',
  'Regensburg',
  'Regensdorf',
  'Regenstauf',
  'Regents Park',
  'Reggio Calabria',
  'Reggio Nell Emilia',
  "Reggio Nell'emilia",
  'Reggiolo',
  'Reghaïa',
  'Reghin',
  'Regina',
  'Regnitzlosau',
  'Rego',
  'Rego Park',
  'Regueira De Pontes',
  'Reguengos',
  'Reguengos De Monsaraz',
  'Rehau',
  'Rehburg-loccum',
  'Rehfelde',
  'Rehling',
  'Rehlingen',
  'Rehlingen-siersburg',
  'Rehna',
  'Rehoboth',
  'Rehoboth Beach',
  'Rehov',
  'Rehovot',
  'Reichartshausen',
  'Reichelsheim',
  'Reichenau',
  'Reichenbach',
  'Reichenberg',
  'Reichenburg',
  'Reichenschwand',
  'Reichenwalde',
  'Reichertshofen',
  'Reichsdorf',
  'Reichstett',
  'Reiden',
  'Reidsville',
  'Reigate',
  'Reignat',
  'Reignier',
  'Reigoldswil',
  'Reilingen',
  'Reilly',
  'Reimlingen',
  'Reims',
  'Reims-la-brulée',
  'Reinach',
  'Reinbeck',
  'Reinbek',
  'Reinfeld',
  'Reinheim',
  'Reinholds',
  'Reinholterode',
  'Reinickendorf',
  'Reiningue',
  'Reino',
  'Reinosa',
  'Reinsberg',
  'Reinsdorf',
  'Reinstetten',
  'Reinsvoll',
  'Reisach',
  'Reisbach',
  'Reischach',
  'Reisgang',
  'Reiskirchen',
  'Reistad',
  'Reisterstown',
  'Reit Im Winkl',
  'Reixach',
  'Rejmyre',
  'Rekingen',
  'Rellingen',
  'Remagen',
  'Remanzacco',
  'Remington',
  'Remiremont',
  'Remote',
  'Remouillé',
  'Remscheid',
  'Remsen',
  'Remsenburg',
  'Remshart',
  'Remus',
  'Renage',
  'Renca',
  'Rende',
  'Rendsburg',
  'Renens',
  'Renesse',
  'Renfrew',
  'Renfrizhausen',
  'Rengo',
  'Rengsdorf',
  'Renkum',
  'Renmark',
  'Rennemoulin',
  'Renner',
  'Rennerod',
  'Rennes',
  'Renningen',
  'Rennweg',
  'Reno',
  'Renska Wies',
  'Rensselaer',
  'Rensselaer Falls',
  'Renswoude',
  'Rentería',
  'Renton',
  'Renville',
  'Renwick',
  'Reocín',
  'Repentigny',
  'Repino',
  'Replonges',
  'Reporyje',
  'Reppenstedt',
  'Repton',
  'Republic',
  'Requena',
  'Resana',
  'Rescaldina',
  'Rescue',
  'Research',
  'Reseda',
  'Resen',
  'Resende',
  'Reserve',
  'Reservoir',
  'Reshafim',
  'Resistencia',
  'Resplendor',
  'Ressegem',
  'Ressons-sur-matz',
  'Restinga Sêca',
  'Reston',
  'Retford',
  'Rethel',
  'Rethondes',
  'Rethwisch',
  'Retie',
  'Retonfey',
  'Retortillo De Soria',
  'Rettenbach',
  'Rettendon',
  'Retzwiller',
  'Reuil',
  'Reurieth',
  'Reus',
  'Reusel',
  'Reuthe',
  'Reutlingen',
  'Reutlingendorf',
  'Reutov',
  'Reutte',
  'Reuver',
  'Revadim',
  'Revel',
  'Revelstoke',
  'Revere',
  'Revesby',
  'Revilla De Collazos',
  'Revin',
  'Revivim',
  'Revnice',
  'Rewa',
  'Rewari',
  'Rex',
  'Rexburg',
  'Rexdale',
  'Rexford',
  'Reyes',
  'Reykjavík',
  'Reynella',
  'Reynolds',
  'Reynoldsburg',
  'Reynoldsville',
  'Reynosa',
  'Reyssouze',
  'Rez',
  'Rezekne',
  'Rezé',
  'Rezzato',
  'Réans',
  'Rébénacq',
  'Rédené',
  'Réthymno',
  'Rétság',
  'Réunion',
  'Rheda',
  'Rheda-wiedenbrück',
  'Rhede',
  'Rheden',
  'Rheems',
  'Rheeze',
  'Rheidt',
  'Rheims',
  'Rhein',
  'Rheinbach',
  'Rheinberg',
  'Rheinbreitbach',
  'Rheinbrohl',
  'Rheine',
  'Rheineck',
  'Rheinfelden',
  'Rheinsberg',
  'Rhenen',
  'Rhine',
  'Rhinebeck',
  'Rhinelander',
  'Rhion',
  'Rho',
  'Rhode',
  'Rhode-saint-genèse',
  'Rhodes',
  'Rhodesdale',
  'Rhome',
  'Rhondda',
  'Rhoon',
  'Rhôs-on-sea',
  'Rhuddlan',
  'Rhyl',
  'Rhymney',
  'Rialma',
  'Rialto',
  'Riaz',
  'Ribadavia',
  'Ribadumia',
  'Ribaforada',
  'Ribarroja',
  'Ribchester',
  'Ribe',
  'Ribeira',
  'Ribeirão',
  'Ribeirão Claro',
  'Ribeirão Pires',
  'Ribeirão Prêto',
  'Ribera',
  'Ribécourt',
  'Ribnica',
  'Ribnitz-damgarten',
  'Ricadi',
  'Ricany',
  'Ricasoli',
  'Riccione',
  'Rice',
  'Rice Lake',
  'Riceville',
  'Richardménil',
  'Richards Bay',
  'Richardson',
  'Richboro',
  'Richelieu',
  'Richemont',
  'Richfield',
  'Richfield Springs',
  'Richibucto',
  'Richland',
  'Richland Center',
  'Richlands',
  'Richlandtown',
  'Richmond',
  'Richmond Hill',
  'Richmond North',
  'Richmond South',
  'Richmond Upon Thames',
  'Richtenberg',
  'Richterswil',
  'Richvale',
  'Richwiller',
  'Richwood',
  'Rickenbach',
  'Rickmansworth',
  'Ricmanice',
  'Ridderkerk',
  'Riddes',
  'Riderwood',
  'Ridge',
  'Ridge Farm',
  'Ridge Spring',
  'Ridgecrest',
  'Ridgedale',
  'Ridgefield',
  'Ridgefield Park',
  'Ridgeland',
  'Ridgely',
  'Ridgetown',
  'Ridgeville',
  'Ridgeville Corners',
  'Ridgeway',
  'Ridgewood',
  'Ridgway',
  'Riding',
  'Ridley Park',
  'Ried',
  'Ried Im Innkreis',
  'Ried Im Traunkreis',
  'Rieden',
  'Riedenburg',
  'Riederich',
  'Riedering',
  'Riedhausen',
  'Riedholz',
  'Riedisheim',
  'Riedlingen',
  'Riegel',
  'Riegelsberg',
  'Riegelsville',
  'Riehen',
  'Rieka',
  'Rielasingen-worblingen',
  'Riemerling',
  'Riemst',
  'Riesa',
  'Riese',
  'Riesenbeck',
  'Rietberg',
  'Rietheim-weilheim',
  'Rieti',
  'Rieumes',
  'Rifle',
  'Rifu',
  'Riga',
  'Rigaud',
  'Rigby',
  'Riihimäki',
  'Riisipere',
  'Rijeka',
  'Rijen',
  'Rijkevorsel',
  'Rijnsburg',
  'Rijpwetering',
  'Rijsbergen',
  'Rijsenhout',
  'Rijssen',
  'Rijswijk',
  'Rikon',
  'Riksgränsen',
  'Rilhac-rancon',
  'Rilland',
  'Rillieux',
  'Rilly-la-montagne',
  'Rimavská Sobota',
  'Rimbach',
  'Rimbey',
  'Rimbo',
  'Rimersburg',
  'Rimforsa',
  'Rimini',
  'Rimouski',
  'Rimpar',
  'Rimsberg',
  'Rimsting',
  'Rinatya',
  'Rincon',
  'Rinconada',
  'Rincón De La Victoria',
  'Rincón De Romos',
  'Rindal',
  'Rindge',
  'Riner',
  'Ringaskiddy',
  'Ringe',
  'Ringgenberg',
  'Ringgold',
  'Ringkøbing',
  'Ringle',
  'Ringling',
  'Ringmer',
  'Ringoes',
  'Ringsend',
  'Ringsheim',
  'Ringstead',
  'Ringsted',
  'Ringwood',
  'Ringwood East',
  'Ringwood North',
  'Rinsumageest',
  'Rinteln',
  'Rio',
  'Rio Branco',
  'Rio Branco Do Sul',
  'Rio Claro',
  'Rio Cuarto',
  'Rio De Janeiro',
  'Rio De Mouro',
  'Rio Do Sul',
  'Rio Gallegos',
  'Rio Grande',
  'Rio Grande Da Serra',
  'Rio Grande Do Sul',
  'Rio Maior',
  'Rio Meão',
  'Rio Negrinho',
  'Rio Paranaíba',
  'Rio Rancho',
  'Rio Rico',
  'Rio Saliceto',
  'Rio Sul',
  'Rio Tinto',
  'Rio Verde',
  'Rio Vermelho',
  'Riol',
  'Riom',
  'Rion-des-landes',
  'Rionegro',
  'Rionero',
  'Riorges',
  'Rioz',
  'Ripalimosani',
  'Ripatransone',
  'Ripe',
  'Ripley',
  'Ripoll',
  'Ripollet',
  'Ripon',
  'Ripponden',
  'Ripton',
  'Ririe',
  'Ris-orangis',
  'Risalpur',
  'Risaralda',
  'Riseley',
  'Rishikesh',
  'Rishon Le Zion',
  'Rishon Letsiyon',
  'Rishon Leziyyon',
  'Rishpon',
  'Rishworth',
  'Rising Sun',
  'Risoul',
  'Rissen',
  'Risskov',
  'Risør',
  'Ritka',
  'Ritterhude',
  'Rittman',
  'Ritto',
  'Ritzville',
  'Riudellots De La Selva',
  'Riva',
  'Riva Del Garda',
  'Riva San Vitale',
  'Rivadavia',
  'Rivalta Di Torino',
  'Rivalta Scrivia',
  'Rivarolo Canavese',
  'Rive-de-gier',
  'Rivenhall',
  'River Edge',
  'River Falls',
  'River Forest',
  'River Grove',
  'River Ranch',
  'Rivera',
  'Riverbank',
  'Riverdale',
  'Riverhead',
  'Riverside',
  'Riverstick',
  'Riverstone',
  'Riverton',
  'Riverview',
  'Riverwood',
  'Rives',
  'Rives Junction',
  'Rivesaltes',
  'Riviera',
  'Rivière Du Rempart',
  'Rivière-des-prairies',
  'Rivière-du-loup',
  'Rivignano',
  'Rivington',
  'Rivne',
  'Rivoli',
  "Rivolta D'adda",
  'Rixensart',
  'Rixeyville',
  'Rixheim',
  'Riyad',
  'Riyadh',
  'Rizal',
  'Rize',
  'Rizhao',
  'Rímac',
  'Río Grande',
  'Río Piedras',
  'Río Segundo',
  'Ríofrío',
  'Ríohacha',
  'Ríudoms',
  'Ro',
  'Roa',
  'Roanne',
  'Roannes',
  'Roanoke',
  'Roanoke Rapids',
  'Roaring Spring',
  'Roatán',
  'Roath',
  'Robakowo',
  'Robbiate',
  'Robbinsville',
  'Robersonville',
  'Robert',
  'Robert-espagne',
  'Roberta',
  'Roberts',
  'Roberts Creek',
  'Robertsbridge',
  'Robertsdale',
  'Robesonia',
  'Robins',
  'Robinson',
  'Robledo De Chavela',
  'Roblin',
  'Robstown',
  'Roca',
  'Rocafort',
  'Rocca Di Caprileone',
  'Rocca Di Papa',
  'Roccamonfina',
  'Roccella Ionica',
  'Rocester',
  'Rochdale',
  'Roche',
  'Roche-la-molière',
  'Roche-lès-beaupré',
  'Rochecorbon',
  'Rochedale',
  'Rochefort',
  'Rochefort-sur-loire',
  'Rochelle',
  'Rochelle Park',
  'Rocher',
  'Roches',
  'Rocheservière',
  'Rochester',
  'Rochester Hills',
  'Rochester Mills',
  'Rochford',
  'Rochfortbridge',
  'Rociana',
  'Rock Cave',
  'Rock Falls',
  'Rock Hill',
  'Rock Island',
  'Rock Rapids',
  'Rock Spring',
  'Rock Springs',
  'Rock Tavern',
  'Rock Valley',
  'Rock View',
  'Rockanje',
  'Rockaway',
  'Rockaway Park',
  'Rockchapel',
  'Rockdale',
  'Rockenberg',
  'Rockfall',
  'Rockfield',
  'Rockford',
  'Rockhampton',
  'Rockingham',
  'Rockland',
  'Rocklea',
  'Rockledge',
  'Rockleigh',
  'Rocklin',
  'Rockmart',
  'Rockneby',
  'Rockport',
  'Rockton',
  'Rockvale',
  'Rockville',
  'Rockville Centre',
  'Rockwall',
  'Rockwell City',
  'Rockwood',
  'Rocky Face',
  'Rocky Gap',
  'Rocky Hill',
  'Rocky Mount',
  'Rocky Mountain House',
  'Rocky Point',
  'Rocky River',
  'Rocquencourt',
  'Roda De Berá',
  'Rodalben',
  'Rodano',
  'Roden',
  'Rodenbach',
  'Rodenberg',
  'Rodenhausen',
  'Rodenkirchen',
  'Rodenrijs',
  'Rodeo',
  'Rodersdorf',
  'Rodez',
  'Rodgau',
  'Rodigo',
  'Rodina',
  'Roding',
  'Rodney',
  'Rodome',
  'Rodriguez',
  'Roebuck',
  'Roehampton',
  'Roelands',
  'Roelofarendsveen',
  'Roermond',
  'Roeselare',
  'Roeser',
  'Roetgen',
  'Roeulx',
  'Rogasovci',
  'Rogers',
  'Rogersville',
  'Roggel',
  'Roggentin',
  'Roggwil',
  'Rognac',
  'Rognan',
  'Rognonas',
  'Rogue River',
  'Rohatec',
  'Rohini',
  'Rohnert Park',
  'Rohr',
  'Rohrau',
  'Rohrbach',
  'Rohrbach In Oberosterreich',
  'Rohrdorf',
  'Rohrersville',
  'Rohri',
  'Rohrmoos',
  'Rohtak',
  'Roissy',
  'Roissy-en-brie',
  'Roissy-en-france',
  'Roitham',
  'Roja',
  'Rojales',
  'Rokietnica',
  'Rokiskis',
  'Rokycany',
  'Rokytnice Nad Jizerou',
  'Roland',
  'Rolândia',
  'Rolde',
  'Rolesville',
  'Roleystone',
  'Rolfstorp',
  'Rolim De Moura',
  'Rolla',
  'Rolle',
  'Rolling Hills',
  'Rolling Hills Estates',
  'Rolling Meadows',
  'Rollingbay',
  'Rollingen',
  'Rollington Town',
  'Rolvenden',
  'Rolvsøy',
  'Rom',
  'Roma',
  'Romagnano',
  'Romagnano Sesia',
  'Romagnat',
  'Romain',
  'Romainmotier',
  'Romainville',
  'Romakloster',
  'Roman',
  'Romanel-sur-lausanne',
  'Romano Canavese',
  'Romano Di Lombardia',
  'Romans',
  'Romans-sur-isère',
  'Romanshorn',
  'Rome',
  'Romedal',
  'Romelanda',
  'Romentino',
  'Romeny',
  'Romeo',
  'Romeoville',
  'Romford',
  'Romiley',
  'Romilly-sur-andelle',
  'Rommelshausen',
  'Rommerskirchen',
  'Romney',
  'Romont',
  'Romorantin',
  'Romsey',
  'Romulus',
  'Ronan',
  'Roncade',
  'Roncadelle',
  'Roncaglia',
  'Ronceverte',
  'Ronchi Dei Legionari',
  'Ronchin',
  'Ronciglione',
  'Ronco Scrivia',
  'Roncq',
  'Ronda',
  'Rondonópolis',
  'Rondônia',
  'Ronfe',
  'Rongcheng',
  'Ronggui',
  'Ronkonkoma',
  'Ronks',
  'Ronneby',
  'Ronnenberg',
  'Ronse',
  'Rontalon',
  'Roodhouse',
  'Roodt-sur-syre',
  'Rookley',
  'Roopville',
  'Roorkee',
  'Roosendaal',
  'Roosevelt',
  'Root',
  'Rootstown',
  'Rooty Hill',
  'Ropar',
  'Ropczyce',
  'Ropley',
  'Roppongi',
  'Ropraz',
  'Roque Saenz Pena',
  'Roquebillière',
  'Roquebrune',
  'Roquefort',
  'Roquetas De Mar',
  'Rorbas',
  'Rosa',
  'Rosamond',
  'Rosanna',
  'Rosario',
  'Rosario Del Tala',
  'Rosarito',
  'Rosbach',
  'Rosbach Vor Der Höhe',
  'Roschach',
  'Rosciano',
  'Roscoe',
  'Roscoff',
  'Roscommon',
  'Roscrea',
  'Rosdorf',
  'Rose Bay',
  'Rose Belle',
  'Rose City',
  'Rose Hill',
  'Roseau',
  'Rosebank',
  'Rosebery',
  'Rosebud',
  'Roseburg',
  'Rosebush',
  'Rosedale',
  'Rosehill',
  'Roseland',
  'Roselle',
  'Roselle Park',
  'Rosemary Beach',
  'Rosemead',
  'Rosemère',
  'Rosemont',
  'Rosemount',
  'Rosenberg',
  'Rosendahl',
  'Rosendal',
  'Rosendale',
  'Rosenfeld',
  'Rosengarten',
  'Rosenhayn',
  'Rosenheim',
  'Rosersberg',
  'Roseto Degli Abruzzi',
  'Roseville',
  "Rosh Ha'ayin",
  'Rosh Haniqra',
  'Rosh Pinna',
  'Rosharon',
  'Rosheim',
  'Rosholt',
  'Rosice',
  'Rosières',
  'Rosières-près-troyes',
  'Rosignano Marittimo',
  'Roskilde',
  'Roslev',
  'Roslindale',
  'Roslyn',
  'Roslyn Heights',
  'Rosmalen',
  'Rosny-sous-bois',
  'Rosny-sur-seine',
  'Rosporden',
  'Ross',
  'Ross On Wye',
  'Rossano',
  'Rossano Veneto',
  'Rossau',
  'Rossens',
  'Rossett',
  'Rossford',
  'Rossland',
  'Rossville',
  'Rostock',
  'Rostov',
  'Rostov-na-donu',
  'Rostov-on-don',
  'Rostovskaya',
  'Rostrenen',
  'Rosult',
  'Roswell',
  'Rosyth',
  'Rot Am See',
  'Rota',
  'Rotenburg',
  'Rotenburg An Der Fulda',
  'Roth',
  'Rothbury',
  'Rothenburg',
  'Rothenburg Ob Der Tauber',
  'Rothenbürg',
  'Rothenkirchen',
  'Rothenstein',
  'Rothenuffeln',
  'Rotherbaum',
  'Rotherfield',
  'Rotherham',
  'Rothernburg',
  'Rothesay',
  'Rothley',
  'Rothrist',
  'Rothschild',
  'Rothwell',
  'Rotkreuz',
  'Rotnes',
  'Rotonda West',
  'Rotorua',
  'Rots',
  'Rotselaar',
  'Rott',
  'Rott Am Inn',
  'Rottach-egern',
  'Rottenbach',
  'Rottenburg',
  'Rottendorf',
  'Rottenmann',
  'Rotterdam',
  'Rotterdam Junction',
  'Rottingdean',
  'Rottofreno',
  'Rottum',
  'Rottweil',
  'Roubaix',
  'Roudnice Nad Labem',
  'Rouen',
  'Rouffach',
  'Rouffange',
  'Rouffiac',
  'Rougemont',
  'Rough And Ready',
  'Rouiba',
  'Rouillon',
  'Roulans',
  'Roulers',
  'Round Lake',
  'Round Lake Park',
  'Round Rock',
  'Roundup',
  'Rourkela',
  'Rous Mill',
  'Rousdon',
  'Rouses Point',
  'Rousinov',
  'Roussay',
  'Rousse',
  'Rousset',
  'Roussillon',
  'Roussillon-en-morvan',
  'Rouveen',
  'Rouvignies',
  'Roux',
  'Rouxmesnil-bouteilles',
  'Rouyn-noranda',
  'Rovaniemi',
  'Rovato',
  'Rovecne',
  'Rovellasca',
  'Rovensko Pod Troskami',
  'Roverbella',
  'Roveredo In Piano',
  'Rovereto',
  'Rovigo',
  'Rovinj',
  'Rovinka',
  'Rovno',
  'Rowland Heights',
  'Rowlands Castle',
  'Rowlands Gill',
  'Rowlett',
  'Rowley',
  'Rowley Regis',
  'Rowville',
  'Roxana',
  'Roxboro',
  'Roxbury',
  'Roxwell',
  'Roy',
  'Royal',
  'Royal City',
  'Royal Leamington Spa',
  'Royal Oak',
  'Royal Palm Beach',
  'Royal Tunbridge Wells',
  'Royal Wootton Bassett',
  'Royalton',
  'Royan',
  'Royapettah',
  'Royat',
  'Royersford',
  'Royse City',
  'Royston',
  'Royton',
  'Rozas',
  'Rozelle',
  'Rozenburg',
  'Roznava',
  'Roznov Pod Radhostem',
  'Rozoy-le-vieil',
  'Rozprza',
  'Roztoky',
  'Rozzano',
  'Roßdorf',
  'Roßhaupten',
  'Roßla',
  'Roßlau',
  'Roßwein',
  'Ródos',
  'Röbäck',
  'Rödelheim',
  'Rödelsee',
  'Rödental',
  'Röderau',
  'Rödersheim-gronau',
  'Rödlin',
  'Röfors',
  'Röhrmoos',
  'Röke',
  'Römhild',
  'Rönninge',
  'Röölä',
  'Röschenz',
  'Röslau',
  'Rösrath',
  'Röstånga',
  'Röszke',
  'Röthenbach',
  'Röthenbach An Der Pegnitz',
  'Röthlein',
  'Ruakaka',
  'Rubano',
  'Rubbestadneset',
  'Rubbiano',
  'Rubery',
  'Rubiera',
  'Rubigen',
  'Rubizhne',
  'Rubí',
  'Ruchheim',
  'Ruckersville',
  'Ruckinge',
  'Rud',
  'Ruda Pabianicka',
  'Ruda Slaska',
  'Ruddervoorde',
  'Ruddington',
  'Rude',
  'Rudelstetten',
  'Rudersberg',
  'Ruderting',
  'Rudkøbing',
  'Rudna',
  'Rudná',
  'Rudolfov',
  'Rudolfstetten',
  'Rudolstadt',
  'Rudong',
  'Rudow',
  'Rue',
  "Rue D'andouville",
  'Rue De Chorette',
  'Rueil-malmaison',
  'Ruelisheim',
  'Ruelle-sur-touvre',
  'Ruffec',
  'Rufi',
  'Rufisque',
  'Rugao',
  'Rugby',
  'Rugeley',
  'Ruggell',
  'Ruhengeri',
  'Ruhla',
  'Ruhpolding',
  'Ruhrort',
  'Ruhstorf',
  'Ruian',
  'Ruichang',
  'Ruidoso',
  'Ruidoso Downs',
  'Ruili',
  'Ruinen',
  'Ruinerwold',
  'Ruiru',
  'Ruisbroek',
  'Ruislip',
  'Ruiz',
  'Ruma',
  'Rumanová',
  'Rumbeke',
  'Rumbula',
  'Rumburk',
  'Rumford',
  'Rumia',
  'Rumilly',
  'Rumlang',
  'Rummen',
  'Rummu',
  'Rumney',
  'Rumson',
  'Rumst',
  'Runcorn',
  'Rungis',
  'Rungsted',
  'Runnemede',
  'Running Springs',
  'Runów',
  'Ruovesi',
  'Rupert',
  'Rupnagar',
  'Rupperswil',
  'Ruppichteroth',
  'Ruprechtshofen',
  'Rupt-sur-moselle',
  'Rus',
  'Rusanova',
  'Rusayl',
  'Ruschlikon',
  'Ruse',
  'Rush',
  'Rush Center',
  'Rush City',
  'Rushden',
  'Rushville',
  'Rusiec',
  'Rusk',
  'Ruskin',
  'Rusper',
  'Russa',
  'Russell',
  'Russell Springs',
  'Russellville',
  'Russi',
  'Russia',
  'Russian Mission',
  'Russikon',
  'Rust',
  'Rustburg',
  'Rustenburg',
  'Rustington',
  'Ruston',
  'Ruswil',
  'Rute',
  'Rutesheim',
  'Rutherford',
  'Rutherfordton',
  'Rutherglen',
  'Ruthin',
  'Ruti',
  'Rutigliano',
  'Rutland',
  'Rutledge',
  'Ruurlo',
  'Ruvo Di Puglia',
  'Ruwais',
  'Ruwi',
  'Ruzindol',
  'Ruzomberok',
  'Ruzyne',
  'Rückersdorf',
  'Rüdlingen',
  'Rüdnitz',
  'Rügland',
  'Rülzheim',
  'Rümikon',
  'Rümlang',
  'Rümmelsheim',
  'Rüschlikon',
  'Rüsselsheim',
  'Rüthen',
  'Rüti',
  'Ry',
  'Ryazan',
  'Rybalskaya',
  'Rybarzowice',
  'Rybinsk',
  'Rybnik',
  'Rychnov Nad Kneznou',
  'Rycroft',
  'Rydaholm',
  'Rydalmere',
  'Ryde',
  'Rydebäck',
  'Ryder',
  'Rye',
  'Rye Beach',
  'Rygge',
  'Rynkeby',
  'Ryogoku',
  'Ryton',
  'Ryzoviste',
  'Rzaska',
  'Rzekun',
  'Rzepin',
  'Rzeszow',
  'Rzeszów',
  'Rødby',
  'Rødding',
  'Rødekro',
  'Rødkærsbro',
  'Rødovre',
  'Rønde',
  'Rønne',
  'Rønnede',
  'Røros',
  'Rørvik',
  'Røyken',
  'Røyneberg',
  'Røyrvik',
  'S-chanf',
  'Saalbach',
  'Saalburg',
  'Saaldorf',
  'Saalfeld',
  'Saalfelden',
  'Saanen',
  'Saanichton',
  'Saarbrücken',
  'Saarlouis',
  'Saarwellingen',
  'Saas',
  'Saas-fee',
  'Saas-grund',
  'Saavedra',
  'Sabac',
  'Sabadell',
  'Sabae',
  'Sabah',
  'Sabanci',
  'Sabaneta',
  'Sabanilla',
  'Sabaudia',
  'Sabetha',
  'Sabin',
  'Sabinal',
  'Sabiñánigo',
  'Sablé',
  'Sablé-sur-sarthe',
  'Sablons',
  'Sabo',
  'Sabro',
  'Sabuk',
  'Sacavém',
  'Saccolongo',
  'Sacele',
  'Sacheon',
  'Sachse',
  'Sachseln',
  'Sachsen',
  'Sachsenhausen',
  'Sachsenkam',
  'Sacile',
  'Sackets Harbor',
  'Sackville',
  'Saclas',
  'Saclay',
  'Saco',
  'Sacramento',
  'Sacré-coeur-saguenay',
  'Sacriston',
  'Sada',
  'Sadat City',
  'Saddle Brook',
  'Saddle River',
  'Saddleworth',
  'Sado',
  'Sadov',
  'Sadska',
  'Sady',
  'Saegertown',
  'Saerbeck',
  'Safat',
  'Safed',
  'Safenwil',
  'Safety Harbor',
  'Safford',
  'Saffron Walden',
  'Safi',
  'Sag Harbor',
  'Saga',
  'Sagae',
  'Sagamihara',
  'Sagamore',
  'Sagamore Beach',
  'Sagamu',
  'Sagar',
  'Sagay City',
  'Saggart',
  'Saginaw',
  'Sagle',
  'Sagres',
  'Sagunto',
  'Saharanpur',
  'Sahibabad',
  'Sahuarita',
  'Sahuayo De Morelos',
  'Sahurs',
  'Sahy',
  'Sai Kung',
  'Sai Noi',
  'Sai Wan',
  'Saidapet',
  'Saidu Sharif',
  'Saignelegier',
  'Saignelégier',
  'Saijo',
  'Sailly-sur-la-lys',
  'Sainghin-en-mélantois',
  'Sains-en-gohelle',
  'Saint Agnes',
  'Saint Aignan',
  'Saint Albans',
  'Saint Albans Bay',
  'Saint Albert',
  'Saint Amand',
  'Saint Amant',
  'Saint Andrew',
  'Saint Andrews',
  'Saint André',
  'Saint Ann',
  'Saint Anne',
  "Saint Anne's",
  "Saint Anne's On Sea",
  'Saint Antoine',
  'Saint Asaph',
  'Saint Athan',
  'Saint Augustine',
  'Saint Austell',
  'Saint Bees',
  'Saint Benedict',
  'Saint Bonaventure',
  'Saint Bonifacius',
  'Saint Boswells',
  'Saint Bruno',
  'Saint Catharine',
  'Saint Catharines',
  'Saint Catherines',
  'Saint Charles',
  'Saint Clair',
  'Saint Clair Beach',
  'Saint Clair Shores',
  'Saint Clairsville',
  'Saint Claude',
  'Saint Cloud',
  'Saint Croix',
  'Saint Croix Falls',
  'Saint Cyr',
  "Saint David's",
  'Saint Davids',
  'Saint Didier',
  'Saint Edward',
  'Saint Elizabeth',
  'Saint Etienne',
  'Saint Florence',
  'Saint Francis',
  'Saint Gabriel',
  'Saint Gallen',
  'Saint George',
  "Saint George's",
  'Saint Georges',
  'Saint Gervais',
  'Saint Helena',
  'Saint Helens',
  'Saint Heliers',
  'Saint Henry',
  'Saint Hubert',
  'Saint Ignace',
  'Saint Ignatius',
  'Saint Ives',
  'Saint Jacobs',
  'Saint James',
  'Saint Jean Chrysostome',
  'Saint Jean Port Joli',
  'Saint Jeoire',
  'Saint John',
  "Saint John's",
  'Saint Johns',
  'Saint Johns Wood',
  'Saint Johnsbury',
  'Saint Johnsbury Center',
  'Saint Joseph',
  'Saint Julien',
  'Saint Kilda',
  'Saint Kilda South',
  'Saint Laurent',
  'Saint Leo',
  'Saint Leonard',
  'Saint Leonards',
  'Saint Louis',
  'Saint Louis Park',
  'Saint Lucia',
  "Saint Margaret's",
  'Saint Margarets',
  'Saint Martin',
  'Saint Martins',
  'Saint Martinville',
  'Saint Marys',
  'Saint Maurice',
  'Saint Meinrad',
  'Saint Mellons',
  'Saint Michael',
  'Saint Michaels',
  'Saint Moritz',
  'Saint Neots',
  'Saint Osyth',
  'Saint Paris',
  'Saint Paul',
  'Saint Paul Park',
  "Saint Paul's Bay",
  'Saint Peter',
  'Saint Peters',
  'Saint Peters Bay',
  'Saint Petersburg',
  'Saint Pierre Chandieu',
  'Saint Priest',
  'Saint Rose',
  'Saint Sampson',
  'Saint Simons Island',
  'Saint Stephen',
  'Saint Sulpice',
  'Saint Thomas',
  'Saint-Élix-le-château',
  'Saint-Éloi',
  'Saint-Étienne',
  'Saint-Étienne-au-mont',
  'Saint-Étienne-de-fontbellon',
  'Saint-Étienne-de-montluc',
  'Saint-Étienne-du-bois',
  'Saint-Étienne-du-rouvray',
  'Saint-Étienne-vallée-française',
  'Saint-affrique',
  'Saint-aignan-grand-lieu',
  'Saint-aigulin',
  'Saint-alban',
  'Saint-alban-leysse',
  'Saint-alexandre',
  'Saint-amand-de-vergt',
  'Saint-amand-les-eaux',
  'Saint-amant-tallende',
  'Saint-andéol-le-château',
  'Saint-andiol',
  "Saint-andre-d'huiriat",
  'Saint-andré-de-cubzac',
  'Saint-andré-des-eaux',
  'Saint-andré-le-gaz',
  'Saint-andré-le-puy',
  'Saint-andré-les-vergers',
  'Saint-andré-lez-lille',
  'Saint-andré-sur-orne',
  'Saint-antoine',
  'Saint-apollinaire',
  'Saint-arnoult-en-yvelines',
  'Saint-arsène',
  'Saint-aubert',
  'Saint-aubin',
  'Saint-aubin-de-médoc',
  'Saint-aubin-le-monial',
  'Saint-aubin-lès-elbeuf',
  'Saint-aubin-sur-gaillon',
  'Saint-aubin-sur-mer',
  'Saint-augustin',
  'Saint-aunès',
  'Saint-avaugourd-des-landes',
  'Saint-avertin',
  'Saint-avé',
  'Saint-avold',
  'Saint-ay',
  'Saint-baldoph',
  "Saint-barthelemy-d'anjou",
  'Saint-barthélemy',
  'Saint-basile-le-grand',
  'Saint-beauzire',
  'Saint-benoit',
  'Saint-bernard',
  'Saint-berthevin',
  'Saint-blaise',
  'Saint-blimont',
  'Saint-bonnet-de-mure',
  'Saint-bonnet-de-valclérieux',
  'Saint-bonnet-de-vieille-vigne',
  'Saint-bonnet-le-château',
  'Saint-bonnet-le-froid',
  'Saint-bonnet-près-riom',
  'Saint-bresson',
  'Saint-brévin-les-pins',
  'Saint-brice-sous-forêt',
  'Saint-brieuc',
  'Saint-brisson',
  'Saint-bruno-de-montarville',
  'Saint-cannat',
  'Saint-caradec',
  'Saint-carné',
  'Saint-cassien',
  'Saint-césaire',
  'Saint-cézert',
  'Saint-chaffrey',
  'Saint-chamas',
  'Saint-chamond',
  'Saint-chef',
  'Saint-christol-lès-alès',
  'Saint-christophe-sur-avre',
  'Saint-clair',
  'Saint-claud',
  'Saint-clément-à-arnes',
  'Saint-cloud',
  'Saint-contest',
  'Saint-cybardeaux',
  'Saint-cyprien',
  'Saint-cyr',
  "Saint-cyr-au-mont-d'or",
  'Saint-cyr-en-val',
  "Saint-cyr-l'ecole",
  'Saint-cyr-sur-loire',
  'Saint-cyr-sur-mer',
  'Saint-damien',
  'Saint-denis',
  'Saint-denis-de-la-bouteillerie',
  'Saint-denis-de-pile',
  'Saint-denis-en-val',
  'Saint-denis-la-chevasse',
  'Saint-denis-lès-bourg',
  'Saint-denis-lès-rebais',
  'Saint-denis-sur-loire',
  'Saint-denis-sur-sarthon',
  'Saint-désirat',
  "Saint-didier-au-mont-d'or",
  'Saint-didier-en-chablais',
  'Saint-dié',
  'Saint-dizier',
  'Saint-dominique',
  'Saint-donat',
  "Saint-donat-sur-l'herbasse",
  'Saint-doulchard',
  'Saint-elzéar',
  'Saint-estève',
  'Saint-eustache',
  'Saint-élix-le-château',
  'Saint-éloi',
  'Saint-émilion',
  'Saint-étienne-de-lauzon',
  'Saint-étienne-de-montluc',
  'Saint-étienne-de-saint-geoirs',
  'Saint-étienne-du-rouvray',
  'Saint-fargeau',
  'Saint-ferréol-les-neiges',
  'Saint-félicien',
  'Saint-félix-de-bourdeilles',
  'Saint-firmin-des-prés',
  'Saint-florent-sur-cher',
  'Saint-florentin',
  'Saint-fons',
  'Saint-fort',
  'Saint-franc',
  'Saint-fulgent',
  'Saint-gabriel-de-brandon',
  'Saint-galmier',
  'Saint-gaudens',
  'Saint-gâtien-des-bois',
  "Saint-genis-l'argentiere",
  'Saint-genis-laval',
  'Saint-genis-les-ollières',
  'Saint-genis-pouilly',
  'Saint-genix-sur-guiers',
  'Saint-georges',
  "Saint-georges-d'orques",
  'Saint-georges-de-reneins',
  'Saint-georges-du-bois',
  'Saint-georges-sur-erve',
  'Saint-geours-de Maremme',
  'Saint-geours-de-maremne',
  "Saint-germain-au-mont-d'or",
  'Saint-germain-de-grantham',
  'Saint-germain-en-coglès',
  'Saint-germain-en-laye',
  'Saint-germain-laval',
  'Saint-germain-laxis',
  "Saint-germain-sur-l'arbresle",
  'Saint-gervais-les-bains',
  'Saint-gély-du-fesc',
  'Saint-gérand',
  'Saint-ghislain',
  'Saint-gilles',
  'Saint-gilles-les-bois',
  'Saint-girons',
  'Saint-gratien',
  'Saint-grégoire',
  'Saint-hellier',
  'Saint-henri',
  'Saint-herblain',
  'Saint-héand',
  'Saint-hilaire-de-brethmas',
  'Saint-hilaire-de-chaléons',
  'Saint-hilaire-de-riez',
  'Saint-hilaire-du-harcouët',
  'Saint-hippolyte-du-fort',
  'Saint-honoré',
  'Saint-hubert',
  'Saint-hyacinthe',
  'Saint-imier',
  'Saint-ismier',
  'Saint-jacques',
  'Saint-jacques-de-la-lande',
  'Saint-jean',
  'Saint-jean-bonnefonds',
  'Saint-jean-brévelay',
  "Saint-jean-d'angely",
  "Saint-jean-d'illac",
  'Saint-jean-de-beauregard',
  'Saint-jean-de-braye',
  'Saint-jean-de-la-neuville',
  'Saint-jean-de-la-porte',
  'Saint-jean-de-la-ruelle',
  'Saint-jean-de-luz',
  'Saint-jean-de-maurienne',
  'Saint-jean-de-moirans',
  'Saint-jean-de-monts',
  'Saint-jean-de-muzols',
  'Saint-jean-de-verges',
  'Saint-jean-de-védas',
  'Saint-jean-en-val',
  'Saint-jean-kourtzerode',
  'Saint-jean-lachalm',
  'Saint-jean-le-blanc',
  'Saint-jean-roure',
  'Saint-jean-soleymieux',
  'Saint-jean-sur-couesnon',
  'Saint-jean-sur-mayenne',
  'Saint-jean-sur-richelieu',
  'Saint-jeannet',
  'Saint-jérôme',
  'Saint-joachim-de-montmorency',
  'Saint-jorioz',
  'Saint-jory',
  'Saint-josse-ten-noode',
  'Saint-juéry',
  'Saint-julien',
  'Saint-julien-de-concelles',
  'Saint-julien-du-puy',
  'Saint-julien-du-sault',
  'Saint-julien-en-genevois',
  'Saint-julien-en-saint-alban',
  'Saint-julien-les-villas',
  'Saint-julien-lès-metz',
  'Saint-junien',
  'Saint-just-en-chaussée',
  'Saint-just-et-vacquières',
  'Saint-just-la-pendue',
  'Saint-just-malmont',
  'Saint-just-près-brioude',
  'Saint-juste-du-lac',
  'Saint-lambert',
  'Saint-laurent',
  'Saint-laurent-blangy',
  'Saint-laurent-de-cognac',
  'Saint-laurent-de-la-salanque',
  'Saint-laurent-de-mure',
  'Saint-laurent-de-neste',
  'Saint-laurent-du-var',
  'Saint-laurent-sur-sèvre',
  "Saint-leonard-d'aston",
  'Saint-leu',
  "Saint-leu-d'esserent",
  'Saint-leu-la-forêt',
  'Saint-léger-des-bois',
  'Saint-léger-sous-cholet',
  'Saint-léonard',
  'Saint-léonard-de-noblat',
  'Saint-lieux-lès-lavaur',
  'Saint-lin-laurentides',
  'Saint-loubès',
  'Saint-louis',
  'Saint-louis-de-montferrand',
  'Saint-lô',
  'Saint-lubin-de-la-haye',
  'Saint-luc',
  'Saint-ludger',
  'Saint-lupicin',
  'Saint-lyphard',
  'Saint-lys',
  'Saint-macaire-en-mauges',
  'Saint-magne-de-castillon',
  'Saint-maime',
  "Saint-maixent-l'ecole",
  'Saint-malo',
  'Saint-malo Des 3 Fontaines',
  'Saint-mamet',
  'Saint-mandé',
  'Saint-marc',
  'Saint-marceau',
  'Saint-marcel',
  'Saint-marcel-lès-valence',
  'Saint-marcellin',
  "Saint-martial-d'artenset",
  'Saint-martin',
  'Saint-martin-bellevue',
  'Saint-martin-boulogne',
  "Saint-martin-d'heres",
  "Saint-martin-d'uriage",
  'Saint-martin-de-brômes',
  'Saint-martin-de-crau',
  'Saint-martin-de-seignanx',
  'Saint-martin-des-champs',
  'Saint-martin-du-vivier',
  'Saint-martin-en-haut',
  'Saint-martin-sur-le-pré',
  'Saint-mathieu',
  'Saint-mathieu-de-tréviers',
  'Saint-maur-des-fossés',
  'Saint-maurice',
  'Saint-maurice-de-cazevieille',
  'Saint-maurice-la-souterraine',
  'Saint-maurice-navacelles',
  'Saint-max',
  'Saint-maximin-la-sainte-baume',
  'Saint-memmie',
  'Saint-mesmin',
  'Saint-médard-de-guizières',
  'Saint-médard-de-mussidan',
  'Saint-médard-en-jalles',
  'Saint-méen-le-grand',
  'Saint-méloir-des-ondes',
  'Saint-michel-',
  'Saint-michel-en-grève',
  'Saint-michel-sur-orge',
  'Saint-michel-sur-rhône',
  'Saint-mitre-les-remparts',
  'Saint-nabord',
  'Saint-nazaire',
  "Saint-nazaire-d'aude",
  'Saint-nexans',
  'Saint-nicolas',
  'Saint-nicolas-de-la-taille',
  'Saint-nicolas-de-port',
  'Saint-nicolas-lèz-arras',
  'Saint-nolff',
  'Saint-nom-la-bretêche',
  'Saint-omer',
  'Saint-orens',
  'Saint-orens-de-gameville',
  'Saint-ouen',
  'Saint-ouen-en-belin',
  "Saint-ouen-l'aumone",
  'Saint-ouen-le-houx',
  'Saint-ouen-sur-iton',
  'Saint-ours',
  'Saint-pacôme',
  'Saint-pair-sur-mer',
  'Saint-pal-de-mons',
  'Saint-palais-sur-mer',
  'Saint-pantaléon-de-larche',
  'Saint-pardon-de-conques',
  'Saint-pargoire',
  'Saint-paul',
  'Saint-paul-cap-de-joux',
  "Saint-paul-d'espis",
  'Saint-paul-de-varces',
  'Saint-paul-de-vence',
  'Saint-paul-du-bois',
  'Saint-paul-en-jarez',
  'Saint-paul-lez-durance',
  'Saint-paul-mont-penit',
  'Saint-perreux',
  'Saint-père-en-retz',
  'Saint-péray',
  'Saint-philbert-de-bouaine',
  'Saint-philbert-de-grand-lieu',
  'Saint-philbert-en-mauges',
  "Saint-pierre-d'entremont",
  "Saint-pierre-d'excideuil",
  "Saint-pierre-d'irube",
  'Saint-pierre-de-boeuf',
  'Saint-pierre-de-chartreuse',
  'Saint-pierre-de-salerne',
  'Saint-pierre-des-corps',
  'Saint-pierre-du-mont',
  'Saint-pierre-du-perray',
  'Saint-pierre-en-vaux',
  'Saint-pierre-lès-elbeuf',
  'Saint-pierre-lès-nemours',
  'Saint-pierre-montlimart',
  'Saint-pierre-quiberon',
  'Saint-pol-de-léon',
  'Saint-pol-sur-mer',
  'Saint-pol-sur-ternoise',
  'Saint-pouange',
  'Saint-pourçain-sur-sioule',
  'Saint-prex',
  'Saint-priest',
  'Saint-priest-en-jarez',
  'Saint-priest-ligoure',
  'Saint-prix',
  'Saint-quentin',
  'Saint-quentin-en-yvelines',
  'Saint-quentin-fallavier',
  'Saint-quentin-sur-le-homme',
  'Saint-quentin-sur-sauxillanges',
  "Saint-rambert-d'albon",
  'Saint-raphaël',
  'Saint-raymond',
  'Saint-remi',
  'Saint-rémy',
  'Saint-rémy-de-provence',
  'Saint-rémy-la-vanne',
  'Saint-rémy-lès-chevreuse',
  'Saint-rimay',
  'Saint-roch',
  'Saint-roch-de-mékinac',
  "Saint-romain-au-mont-d'or",
  'Saint-romain-de-colbosc',
  'Saint-romain-de-jalionas',
  'Saint-romain-en-viennois',
  'Saint-romain-la-motte',
  'Saint-romain-le-puy',
  'Saint-romuald',
  'Saint-saulve',
  'Saint-sauveur',
  'Saint-sauveur-des-landes',
  'Saint-sauveur-des-monts',
  'Saint-savin',
  'Saint-selve',
  'Saint-sernin-du-plain',
  'Saint-sernin-sur-rance',
  'Saint-servais',
  'Saint-sever-calvados',
  'Saint-sébastien',
  'Saint-sébastien-sur-loire',
  'Saint-siméon',
  'Saint-sulpice',
  'Saint-sulpice-de-ruffec',
  'Saint-sulpice-et-cameyrac',
  "Saint-sylvain-d'anjou",
  "Saint-symphorien-d'ozon",
  'Saint-symphorien-sur-coise',
  'Saint-thibault',
  'Saint-thibault-des-vignes',
  'Saint-thonan',
  'Saint-thuriau',
  'Saint-tropez',
  'Saint-ubalde',
  'Saint-valérien-de-milton',
  'Saint-vallier',
  'Saint-varent',
  'Saint-viance',
  'Saint-viaud',
  'Saint-victor-sur-loire',
  'Saint-victor-sur-rhins',
  'Saint-vincent-de-tyrosse',
  'Saint-vincent-des-prés',
  'Saint-vith',
  'Saint-vivien',
  'Saint-vulbas',
  'Saint-yon',
  'Saint-yrieix-sur-charente',
  'Sainte Marie',
  'Sainte-Égrève',
  'Sainte-adèle',
  'Sainte-agathe-des-monts',
  'Sainte-agnès',
  'Sainte-anne-de-bellevue',
  'Sainte-anne-de-la-pérade',
  'Sainte-anne-des-monts',
  'Sainte-catherine',
  'Sainte-claire',
  'Sainte-colombe',
  'Sainte-consorce',
  'Sainte-croix',
  "Sainte-eulalie-d'ans",
  'Sainte-eulalie-en-born',
  'Sainte-florence',
  'Sainte-foy',
  "Sainte-foy-d'aigrefeuille",
  'Sainte-foy-lès-lyon',
  'Sainte-gemme-moronval',
  'Sainte-gemmes-sur Loire',
  'Sainte-geneviève-de-pierrefonds',
  'Sainte-geneviève-des-bois',
  'Sainte-hélène-du-lac',
  'Sainte-hénédine',
  'Sainte-honorine-du-fay',
  'Sainte-julie',
  'Sainte-julienne',
  'Sainte-justine',
  'Sainte-livrade-sur-lot',
  'Sainte-lizaigne',
  'Sainte-luce-sur-loire',
  'Sainte-marguerite-sur-fauville',
  'Sainte-marie',
  'Sainte-marie-de-gosse',
  'Sainte-marie-des-champs',
  'Sainte-marie-la-mer',
  'Sainte-marie-sur-mer',
  'Sainte-martine',
  'Sainte-menehould',
  'Sainte-savine',
  'Sainte-sigolène',
  'Sainte-sophie',
  'Sainte-thérence',
  'Sainte-thérèse',
  'Sainte-tulle',
  'Saintes',
  'Saintfield',
  'Saints-geosmes',
  'Saipan',
  'Saitama',
  'Saitama-shi',
  'Saix',
  'Saizerais',
  'Saïda',
  'Sakado',
  'Sakaemachi',
  'Sakai',
  'Sakaide',
  'Sakaiminato',
  'Sakaki',
  'Sakarya',
  'Sakata',
  'Saksvik',
  'Saku',
  'Sakura',
  'Sakuragawa',
  'Sakuragawacho',
  'Sakurai',
  'Sakuramachi',
  'Sakvice',
  'Sala',
  'Sala Baganza',
  'Sala Bolognese',
  'Sala Consilina',
  'Salaberry-de-valleyfield',
  'Salach',
  'Saladillo',
  'Salado',
  'Salak Selatan',
  'Salamanca',
  'Salamís',
  'Saland',
  'Salaspils',
  'Salatiga',
  'Salaya',
  'Salcedo',
  'Salcha',
  'Salching',
  'Salcininkai',
  'Salcombe',
  'Saldenburg',
  'Saldus',
  'Sale',
  'Saleilles',
  'Salem',
  'Salen',
  'Salerno',
  'Salers',
  'Salesópolis',
  'Salez',
  'Salford',
  'Salfords',
  'Salgado',
  'Salgen',
  'Salgesch',
  'Salgótarján',
  'Salida',
  'Salies-de-béarn',
  'Salina',
  'Salina Cruz',
  'Salinas',
  'Salinas De Pamplona',
  'Salindres',
  'Saline',
  'Salisbury',
  'Salisbury Mills',
  'Sallanches',
  'Sallenelles',
  'Sallent',
  'Salles',
  "Salles-d'aude",
  'Sallisaw',
  'Sallynoggin',
  'Salmabad',
  'Salmiya',
  'Salmiyah',
  'Salmo',
  'Salmon',
  'Salmon Arm',
  'Salmtal',
  'Salo',
  'Salobreña',
  'Salon',
  'Salon-de-provence',
  'Salonta',
  'Salou',
  'Salò',
  'Salsjö',
  'Salsomaggiore',
  'Salt',
  'Salt Ash',
  'Salt Lake',
  'Salt Lake City',
  'Salt Point',
  'Salta',
  'Saltaire',
  'Saltash',
  'Saltburn-by-the-sea',
  'Saltcoats',
  'Salteras',
  'Salterforth',
  'Saltillo',
  'Salto',
  'Salton City',
  'Saltsburg',
  'Saltsjö-duvnäs',
  'Saltsjöbaden',
  'Saltum',
  'Saltville',
  'Saluda',
  'Saluggia',
  'Saluzzo',
  'Salvador',
  'Salvagnac',
  'Salvaterra De Magos',
  'Salvatierra De Miño',
  'Salz',
  'Salzano',
  'Salzbergen',
  'Salzburg',
  'Salzgitter',
  'Salzhausen',
  'Salzhemmendorf',
  'Salzkotten',
  'Salzstetten',
  'Salzwedel',
  'Sam Khok',
  'Sam Phran',
  'Samambaia',
  'Samandira',
  'Samara',
  'Samarate',
  'Samarinda',
  'Samastipur',
  'Samatan',
  'Samazan',
  'Samba',
  'Sambalpur',
  'Sambeek',
  'Sambuca',
  'Samedan',
  'Samerberg',
  'Samford',
  'Samlesbury',
  'Sammamish',
  'Sammichele Di Bari',
  'Samoa',
  'Samobor',
  'Samone',
  'Samora Correia',
  'Samoreau',
  'Samorin',
  'Samotisky',
  'Sampang',
  'Samrong',
  'Samseong',
  'Samson',
  'Samstagern',
  'Samsun',
  'Samsung',
  'Samswegen',
  'Samundri',
  'Samut Prakan',
  'San Adria Del Besòs',
  'San Adrián',
  'San Adrián De Besós',
  'San Agustín',
  'San Agustín Del Guadalix',
  'San Andreas',
  'San Andrés Cholula',
  'San Andrés De La Barca',
  'San Andrés De Llavaneras',
  'San Andrés Del Pedregal',
  'San Andrés Del Rabanedo',
  'San Angelo',
  'San Anselmo',
  'San Antonio',
  'San Antonio De La Cal',
  'San Antonio De Litín',
  'San Ángel',
  'San Ángel Inn',
  'San Bartolomé De Tirajana',
  'San Baudilio De Llobregat',
  'San Benedetto Del Tronto',
  'San Benito',
  'San Bernardino',
  'San Bernardo',
  'San Biagio Di Callalta',
  'San Bonifacio',
  'San Borja',
  'San Bruno',
  'San Carlo',
  'San Carlos',
  'San Carlos De Bariloche',
  'San Casciano In Val Di Pesa',
  'San Casciano Val Di Pesa',
  'San Celoni',
  'San Cesario Sul Panaro',
  'San Ciprián De Viñas',
  'San Clemente',
  'San Cristóbal De Entreviñas',
  'San Cristóbal De La Laguna',
  'San Cristóbal De Las Casas',
  'San Cristóbal Ecatepec',
  'San Cugat Del Vallés',
  'San Diego',
  'San Dimas',
  'San Dona Di Piave',
  'San Donato Milanese',
  'San Donà Di Piave',
  'San Dorligo Della Valle',
  'San Esteban De Litera',
  'San Felice Del Benaco',
  'San Felice Sul Panaro',
  'San Felipe',
  'San Felíu De Llobregat',
  'San Fernando',
  'San Fernando De Henares',
  'San Filippo Della Mela',
  'San Fior',
  'San Francisco',
  'San Francisco Culhuacán',
  'San Francisco De Mostazal',
  'San Francisco Solano',
  'San Gabriel',
  'San Gavino Monreale',
  'San Genesio Ed Uniti',
  'San Geronimo',
  'San Gillio',
  'San Ginés',
  'San Giorgio',
  'San Giorgio Di Nogaro',
  'San Giorgio Di Piano',
  'San Giorgio In Bosco',
  'San Giorgio Ionico',
  'San Giorgio Morgeto',
  'San Giovanni',
  'San Giovanni Bianco',
  'San Giovanni In Fiore',
  'San Giovanni In Marignano',
  'San Giovanni In Persiceto',
  'San Giovanni Lupatoto',
  'San Giovanni Suèrgiu',
  'San Giovanni Valdarno',
  'San Giuliano Milanese',
  'San Giuliano Terme',
  'San Giuseppe Vesuviano',
  'San Giustino',
  'San Gregorio',
  'San Gwann',
  'San Hipólito De Voltregá',
  'San Ignacio Cerro Gordo',
  'San Ildefonso',
  'San Isidro',
  'San Jacinto',
  'San Javier',
  'San Joan De Vilatorrada',
  'San Joaquin',
  'San Jose',
  'San Jose City',
  'San Jose Del Monte',
  'San José',
  'San José Casas Caídas',
  'San José De Cùcuta',
  'San José De La Esquina',
  'San José De Los Arroyos',
  'San José Del Cabo',
  'San Juan',
  'San Juan Bautista',
  'San Juan Bautista Tuxtepec',
  'San Juan Capistrano',
  'San Juan De Alicante',
  'San Juan De Aznalfarache',
  'San Juan De Moró',
  'San Juan De Mozarrifar',
  'San Juan De Sabinas',
  'San Juan Del Puerto',
  'San Juan Del Río',
  'San Juan Despí',
  'San Justo',
  'San Lazzaro',
  'San Lazzaro Di Savena',
  'San Leandro',
  'San Lorenzo',
  'San Lorenzo De El Escorial',
  'San Lorenzo De Hortóns',
  'San Lorenzo Isontino',
  'San Lorenzo Savall',
  'San Luis',
  'San Luis Obispo',
  'San Luis Potosí',
  'San Luis Río Colorado',
  'San Marco Dei Cavoti',
  'San Marco Evangelista',
  'San Marcos',
  'San Marino',
  'San Martin',
  'San Martino Al Tagliamento',
  'San Martino Buon Albergo',
  'San Martino Di Lupari',
  'San Martino In Colle',
  'San Martino In Pensilis',
  'San Martino In Strada',
  'San Martino Siccomario',
  'San Martín De La Vega',
  'San Martín De Porres',
  'San Marzano Sul Sarno',
  'San Mateo',
  'San Mateo De Bages',
  'San Mateo De Gállego',
  'San Mauro Pascoli',
  'San Mauro Torinese',
  'San Michele Di Piave',
  'San Miguel',
  'San Miguel Chapultepec',
  'San Miguel Cuyutlán',
  'San Miguel De Allende',
  'San Miguel De Tucuman',
  'San Miniato',
  'San Nicola Di Gallipoli',
  'San Nicolas De Los Arroyos',
  'San Nicolás',
  'San Nicolás De Los Garsas',
  'San Nicolás de los Garza',
  'San Pablo',
  'San Pablo City',
  'San Pablo De Seguríes',
  'San Paolo',
  'San Pascual',
  'San Paulo',
  'San Pedro',
  'San Pedro Alcántara',
  'San Pedro Cholula',
  'San Pedro De Alcántara',
  'San Pedro De Montes De Oca',
  'San Pedro Del Pinatar',
  'San Pedro Sula',
  'San Pellegrino Terme',
  'San Piero A Sieve',
  'San Pietro Di Stra',
  'San Pietro In Casale',
  'San Pietro In Gu',
  'San Pietro In Gù',
  'San Pietro In Trento',
  'San Polo',
  "San Polo D'enza",
  'San Potito Sannitico',
  'San Quentin',
  'San Quirino',
  'San Quírico Safaja',
  'San Rafael',
  'San Ramon',
  'San Ramón',
  'San Remo',
  'San Roque',
  'San Roque De Ríomiera',
  'San Saba',
  'San Salvador',
  'San Salvo',
  'San Sebastian',
  'San Sebastián De Los Ballesteros',
  'San Sebastián De Los Reyes',
  'San Sebastián El Grande',
  'San Secondo Parmense',
  'San Severino Marche',
  'San Severo',
  'San Simeon',
  'San Stino Di Livenza',
  'San Valentino Torio',
  'San Vendemiano',
  'San Vicente',
  'San Vicente De Horts',
  'San Vicente Del Raspeig',
  'San Vincenzo',
  'San Vito',
  'San Vito Al Tagliamento',
  'San Vito Dei Normanni',
  'San Vittore',
  'San Vittore Del Lazio',
  'San Ysidro',
  'San Zenone Degli Ezzelini',
  'Sana',
  'Sanabis',
  'Sanand',
  'Sanandrei',
  'Sanary-sur-mer',
  'Sanayi',
  'Sanborn',
  'Sanbornton',
  'Sancaktepe',
  'Sancey-le-grand',
  'Sancheville',
  'Sanchung',
  'Sancoale',
  'Sancoins',
  'Sanctuary Point',
  'Sand',
  'Sand Creek',
  'Sand Springs',
  'Sandakan',
  'Sandane',
  'Sandanski',
  'Sandared',
  'Sandbach',
  'Sande',
  'Sande I Vestfold',
  'Sandefjord',
  'Sandeid',
  'Sanders',
  'Sandersville',
  'Sandfeld',
  'Sandford',
  'Sandgate',
  'Sandhagen',
  'Sandhaken',
  'Sandhamn',
  'Sandhausen',
  'Sandhill',
  'Sandhurst',
  'Sandia',
  'Sandiacre',
  'Sandnes',
  'Sandnessjøen',
  'Sandomierz',
  'Sandoval',
  'Sandown',
  'Sandpoint',
  'Sandrigo',
  'Sandringham',
  'Sandston',
  'Sandusky',
  'Sandvika',
  'Sandviken',
  'Sandweiler',
  'Sandwich',
  'Sandy',
  'Sandy Bay',
  'Sandy Hook',
  'Sandy Spring',
  'Sandy Springs',
  'Sandycroft',
  'Sandyford',
  'Sandymount',
  'Sanem',
  'Sanford',
  'Sangalhos',
  'Sangareddi',
  'Sangbong',
  'Sangdo',
  'Sangdodong',
  'Sangenjaya',
  'Sanger',
  'Sangerhausen',
  'Sanggyeyuktong',
  'Sangju',
  'Sangli',
  'Sango Otta',
  'Sangolquí',
  'Sangróniz',
  'Sangrur',
  'Sanhe',
  'Sanibel',
  'Sanilhac',
  'Sanitz',
  'Sanjo',
  'Sankt Augustin',
  'Sankt Florian',
  'Sankt Florian Am Inn',
  'Sankt Gallen',
  'Sankt Georgen',
  'Sankt Georgen Im Attergau',
  'Sankt Gilgen',
  'Sankt Ingbert',
  'Sankt Jakob Im Rosental',
  'Sankt Johann',
  'Sankt Johann In Tirol',
  'Sankt Leon-rot',
  'Sankt Margrethen',
  'Sankt Marien',
  'Sankt Marienkirchen',
  'Sankt Martin',
  'Sankt Martin Im Sulmtal',
  'Sankt Märgen',
  'Sankt Michaelisdonn',
  'Sankt Moritz',
  'Sankt Peter',
  'Sankt Peter Am Wimberg',
  'Sankt Petersburg',
  'Sankt Polten',
  'Sankt Pölten',
  'Sankt Stefan',
  'Sankt Ursen',
  'Sankt Valentin',
  'Sankt Veit An Der Glan',
  'Sankt Wendel',
  'Sankt-peterburg',
  'Sankt-vith',
  'Sanlitun',
  'Sanliurfa',
  'Sanlúcar De Barrameda',
  'Sanlúcar La Mayor',
  'Sanmen',
  'Sanmenxia',
  'Sanming',
  'Sannicandro Di Bari',
  'Sannois',
  'Sano',
  'Sanpailou',
  'Sanquelim',
  'Sans Souci',
  'Sansepolcro',
  'Sanson',
  'Sant Andreu',
  'Sant Carlos De La Ràpita',
  'Sant Climent Del Llobregat',
  'Sant Cugat Del Vallès',
  'Sant Feliu De Guíxols',
  'Sant Joan Les Fonts',
  'Sant Jordi',
  'Sant Juliá',
  'Sant Juliá De Vilatorta',
  'Sant Just Desvern',
  'Sant Pere De Ribes',
  "Sant Sadurni D'anoia",
  "Sant Sadurní D'anoia",
  'Sant Salvador',
  "Sant'agata Bolognese",
  "Sant'agata Di Militello",
  "Sant'agata Sul Santerno",
  "Sant'agnello",
  "Sant'ambrogio Di Valpolicella",
  "Sant'angelo In Vado",
  "Sant'angelo Le Fratte",
  "Sant'angelo Lodigiano",
  "Sant'anna",
  "Sant'antioco",
  "Sant'antonino Di Susa",
  "Sant'antonio Abate",
  "Sant'arcangelo Di Romagna",
  "Sant'egidio Alla Vibrata",
  "Sant'elpidio A Mare",
  "Sant'ilario D'enza",
  'Santa Albertina',
  'Santa Ana',
  'Santa Barbara',
  "Santa Barbara D'oeste",
  'Santa Bárbara De Casas',
  'Santa Catarina',
  'Santa Catarina Da Serra',
  'Santa Clara',
  'Santa Clarita',
  'Santa Coloma',
  'Santa Coloma De Cervelló',
  'Santa Coloma De Gramanet',
  'Santa Croce',
  "Santa Croce Sull' Arno",
  'Santa Cruz',
  'Santa Cruz Cabrália',
  'Santa Cruz De Bezana',
  'Santa Cruz De La Palma',
  'Santa Cruz De La Sierra',
  'Santa Cruz De La Zarza',
  'Santa Cruz De Las Flores',
  'Santa Cruz De Tenerife',
  'Santa Cruz Do Bispo',
  'Santa Cruz Do Capibaribe',
  'Santa Cruz Do Sul',
  'Santa Efigênia',
  'Santa Eugenia De Berga',
  'Santa Eulalia De Ríuprimer',
  'Santa Eulalia De Ronsaná',
  'Santa Eulalia Del Río',
  'Santa Fe',
  'Santa Fe Springs',
  'Santa Iria De Azoia',
  'Santa Isabel',
  'Santa Lucia Di Piave',
  'Santa Lucía',
  'Santa Luzia',
  'Santa Margarita',
  'Santa Maria',
  'Santa Maria Capua Vetere',
  'Santa Maria Di Sala',
  'Santa Maria Maddalena',
  'Santa Marinella',
  'Santa Marinha',
  'Santa María De Camo',
  'Santa María De Corcó',
  'Santa María De Palautordera',
  'Santa María Del Camí',
  'Santa María Del Mar',
  'Santa María Del Monte',
  'Santa María Del Páramo',
  'Santa Marta',
  'Santa Marta De Tormes',
  'Santa Monica',
  'Santa Oliva',
  'Santa Paula',
  'Santa Perpetua',
  'Santa Perpetua De Moguda',
  'Santa Pola',
  'Santa Rita Do Sapucaí',
  'Santa Rosa',
  'Santa Rosa Beach',
  'Santa Rosa De Cabal',
  'Santa Rosa De Lima',
  'Santa Rosa De Osos',
  'Santa Rosa Jáuregui',
  'Santa Teresa',
  'Santa Ynez',
  'Santamala',
  'Santana',
  'Santana De Parnaíba',
  'Santana Do Livramento',
  'Santana Do Paraíso',
  'Santander',
  'Santaquin',
  'Santarém',
  'Santee',
  'Santeramo In Colle',
  'Santiago',
  'Santiago Coltzingo',
  'Santiago De Calatrava',
  'Santiago De Cali',
  'Santiago De Chuco',
  'Santiago De Compostela',
  'Santiago De Los Caballeros',
  'Santiago De María',
  'Santiago De Surco',
  'Santiago Del Estero',
  'Santiago Do Cacém',
  'Santiago Ixcuintla',
  'Santiponce',
  'Santnagar',
  'Santo',
  'Santo Agostinho',
  'Santo Amaro',
  'Santo Amaro Da Imperatriz',
  'Santo André',
  'Santo Antão Do Tojal',
  'Santo António Da Charneca',
  'Santo Antônio Da Platina',
  'Santo Antônio De Pádua',
  'Santo Antônio Do Jardim',
  'Santo Antônio Do Pinhal',
  'Santo Antônio Do Sudoeste',
  'Santo Ângelo',
  'Santo Cristo',
  'Santo Da Serra',
  'Santo Domingo',
  'Santo Domingo City',
  'Santo Domingo De Guzmán',
  'Santo Domingo De La Calzada',
  'Santo Domingo Zanatepec',
  'Santo Estêvão',
  'Santo Inácio',
  'Santo Tirso',
  'Santo Tomé',
  'Santolan',
  'Santomera',
  'Santoña',
  'Santorso',
  'Santos',
  'Santovka',
  'Santpedor',
  'Santpoort-noord',
  'Santpoort-zuid',
  'Santry',
  'Santurce',
  'Sanur',
  'Sanya',
  'Saoula',
  'Saône',
  'Sapele',
  'Saphan Sung',
  'Sapiranga',
  'Sappemeer',
  'Sapporo',
  'Sapporo-shi',
  'Sapucaia',
  'Sapulpa',
  'Saquarema',
  'Sar',
  'Saracoglu',
  'Saragossa',
  'Sarah',
  'Sarajevo',
  'Saraland',
  'Saran',
  'Saranac',
  'Saranac Lake',
  'Saransk',
  'Sarasota',
  'Saratoga',
  'Saratoga Springs',
  'Saratov',
  'Sarbia',
  'Sarcedo',
  'Sarcelles',
  'Sardarshahr',
  'Sardis',
  'Sarepta',
  'Sarezzo',
  'Sargans',
  'Sargé-lès-le Mans',
  'Sargodha',
  'Saricam',
  'Sariñena',
  'Sariyer',
  'Sarlat-la-canéda',
  'Sarleinsbach',
  'Sarmenstorf',
  'Sarmeola',
  'Sarmiento',
  'Sarnen',
  'Sarnia',
  'Sarnico',
  'Sarno',
  'Sarona',
  'Saronno',
  'Sarpsborg',
  'Sarralbe',
  'Sarratt',
  'Sarrebourg',
  'Sarreguemines',
  'Sarrey',
  'Sarria',
  'Sarriá De Ter',
  'Sarroch',
  'Sarrola-carcopino',
  'Sars-et-rosières',
  'Sarstedt',
  'Sart-bernard',
  'Sart-dames-avelines',
  'Sartell',
  'Sartrouville',
  'Sarugaku',
  'Sarugakucho',
  'Sarver',
  'Sarzana',
  'Sarzeau',
  'Sarzedo',
  'Sas Van Gent',
  'Sasa',
  'Sasang',
  'Sasaram',
  'Sasbach',
  'Sasebo',
  'Saskatoon',
  'Sassari',
  'Sassen',
  'Sassenage',
  'Sassenberg',
  'Sassendorf',
  'Sassenheim',
  'Sasso Marconi',
  'Sassoferrato',
  'Sassuolo',
  'Satalice',
  'Satanta',
  'Satara',
  'Satellite Beach',
  'Sathon',
  'Sathonay-village',
  'Satigny',
  'Satkhira',
  'Satna',
  'Satsuma',
  'Satte',
  'Satteins',
  'Sattel',
  'Satu Mare',
  'Saturn',
  'Saucats',
  'Sauda',
  'Saue',
  'Sauensiek',
  'Sauerlach',
  'Saugatuck',
  'Saugerties',
  'Saughall',
  'Saugus',
  'Saujon',
  'Sauk Centre',
  'Sauk City',
  'Sauk Rapids',
  'Saukville',
  'Saulce-sur-rhône',
  'Sauldorf',
  'Saulgau',
  'Saulgond',
  'Saulgrub',
  'Saulieu',
  'Sault Sainte Marie',
  'Saultain',
  'Saulx-les-chartreux',
  'Saulxures-lès-nancy',
  'Saumur',
  'Saunderstown',
  'Sauquoit',
  'Sausalito',
  'Sausheim',
  'Sausset-les-pins',
  'Saussey',
  'Sautee Nacoochee',
  'Sauternes',
  'Sautron',
  'Sauvain',
  'Sauðárkrókur',
  'Saúde',
  'Sava',
  'Savage',
  'Savannah',
  'Savar',
  'Savas-mépin',
  'Savenay',
  'Saveni',
  'Saverne',
  'Saviano',
  'Savigliano',
  'Savignac',
  'Savignano Sul Rubicone',
  'Savigneux',
  'Savigny',
  'Savigny-le-temple',
  'Savigny-sur-orge',
  'Savina',
  'Savolles',
  'Savona',
  'Savonlinna',
  'Savonnières',
  'Savonranta',
  'Savosa Paese',
  'Savoy',
  'Savyon',
  'Sawahlunto',
  'Sawai Madhopur',
  'Sawara',
  'Sawbridgeworth',
  'Sawit',
  'Sawston',
  'Sawtell',
  'Sawtry',
  'Sawyer',
  'Sax',
  'Saxapahaw',
  'Saxilby',
  'Saxmundham',
  'Saxon',
  'Saxonburg',
  'Saxonia',
  'Sayabec',
  'Sayama',
  'Saybrook',
  'Sayre',
  'Sayreville',
  'Sayville',
  'Sayyan',
  'Saßnitz',
  'Sáenz Peña',
  'Sárkeresztes',
  'Sárospatak',
  'Sárvár',
  'Sângeru',
  'Sânpetrul-mare',
  'São Bento do Sul',
  'São Bernardo Do Campo',
  'São Borja',
  'São Caetano Do Sul',
  'São Carlos',
  'São Carlos Do Pinhal',
  'São Conrado',
  'São Cristóvão',
  'São Domingos De Rana',
  'São Félix Da Marinha',
  'São Francisco',
  'São Gonçalo',
  'São Gonçalo Do Pará',
  'São Joaquim De Bicas',
  'São João',
  'São João Da Madeira',
  'São João Da Talha',
  'São João De Ver',
  'São João Del Rei',
  'São João Do Estoril',
  'São João Dos Montes',
  'São João da Boa Vista',
  'São João del Rei',
  'São José',
  'São José Do Norte',
  'São José Dos Campos',
  'São José Dos Pinhais',
  'São José do Rio Prêto',
  'São Julião Do Tojal',
  'São Leopoldo',
  'São Lourenço',
  'São Lourenço Da Serra',
  'São Lourenço Do Oeste',
  'São Luiz',
  'São Luiz Do Maranhão',
  'São Luís',
  'São Mamede De Infesta',
  'São Marcos',
  'São Mateus Do Maranhão',
  'São Miguel Arcanjo',
  'São Paulo',
  'São Pedro',
  'São Pedro Do Estoril',
  'São Roque',
  'São Sebastião Do Paraíso',
  'São Sebastião Do Rio Prêto',
  'São Vicente',
  'São Vicente De Minas',
  'Sächsenheim',
  'Säffle',
  'Sälen',
  'Säriswil',
  'Särna',
  'Särö',
  'Säter',
  'Säve',
  'Sävedalen',
  'Sävsjö',
  'Sågmyra',
  'Scafati',
  'Scalea',
  'Scalenghe',
  'Scalloway',
  'Scandia',
  'Scandiano',
  'Scandicci',
  'Scandinavia',
  'Scarborough',
  'Scarborough Beach',
  'Scarborough Junction',
  'Scarnafigi',
  'Scarriff',
  'Scarsdale',
  'Sceaux',
  'Schaan',
  'Schaarbeek',
  'Schachen',
  'Schaefferstown',
  'Schaerbeek',
  'Schaffhausen',
  'Schafflund',
  'Schafisheim',
  'Schagen',
  'Schaidt',
  'Schaijk',
  'Schalchen',
  'Schalkau',
  'Schalkendorf',
  'Schalkhaar',
  'Schalksmühle',
  'Schalkwijk',
  'Schallstadt',
  'Schan',
  'Schapen',
  'Scharans',
  'Scharbeutz',
  'Scharmbeck',
  'Scharnebeck',
  'Scharrachbergheim',
  'Schauenstein',
  'Schaumburg',
  'Schäftlarn',
  'Schänis',
  'Schechen',
  'Scheemda',
  'Scheer',
  'Scheeßel',
  'Scheffau Am Tennengebirge',
  'Scheibenberg',
  'Schelle',
  'Schellebelle',
  'Schellerten',
  'Schelluinen',
  'Schenectady',
  'Schenefeld',
  'Schenkenberg',
  'Schenkendöbern',
  'Schenkon',
  'Schepdaal',
  'Schererville',
  'Schermbeck',
  'Schermerhorn',
  'Scherpenheuvel',
  'Scherpenisse',
  'Scherpenzeel',
  'Scherstetten',
  'Schertz',
  'Scheuerfeld',
  'Scheuring',
  'Scheveningen',
  'Schiavi Di Abruzzo',
  'Schiedam',
  'Schieder-schwalenberg',
  'Schiedlberg',
  'Schierling',
  'Schiers',
  'Schiffdorf',
  'Schifferstadt',
  'Schiffweiler',
  'Schijf',
  'Schijndel',
  'Schilde',
  'Schiller Park',
  'Schillingsfürst',
  'Schiltach',
  'Schiltigheim',
  'Schimmert',
  'Schin Op Geul',
  'Schindellegi',
  'Schinnen',
  'Schinznach Bad',
  'Schinznach Dorf',
  'Schio',
  'Schiphol',
  'Schkeuditz',
  'Schkopau',
  'Schladming',
  'Schlagsdorf',
  'Schlaitdorf',
  'Schlaitz',
  'Schlangen',
  'Schlangenbad',
  'Schlanstedt',
  'Schlattingen',
  'Schlehdorf',
  'Schleiden',
  'Schleiz',
  'Schlema',
  'Schleswig',
  'Schleusingen',
  'Schlieben',
  'Schliengen',
  'Schlierbach',
  'Schlieren',
  'Schliersee',
  'Schlitz',
  'Schlossrued',
  'Schloß Holte-stukenbrock',
  'Schloßberg',
  'Schloßborn',
  'Schluchsee',
  'Schlüchtern',
  'Schmalfeld',
  'Schmalkalden',
  'Schmallenberg',
  'Schmalzerode',
  'Schmelz',
  'Schmerikon',
  'Schmitten',
  'Schmölen',
  'Schmölln',
  'Schnait',
  'Schnaittach',
  'Schnaittenbach',
  'Schnecksville',
  'Schneeberg',
  'Schneider',
  'Schnelsen',
  'Schneverdingen',
  'Schnottwil',
  'Schofield',
  'Schofields',
  'Schoftland',
  'Schomberg',
  'Schondorf',
  'Schondorf Am Ammersee',
  'Schonenbuch',
  'Schonengrund',
  'Schonstett',
  'Schoolcraft',
  'Schooleys Mountain',
  'Schoonebeek',
  'Schoonhoven',
  'Schoonrewoerd',
  'Schopfheim',
  'Schopfloch',
  'Schorndorf',
  'Schortens',
  'Schoten',
  'Schoten-heide-bad',
  'Schotten',
  'Schöffengrund',
  'Schöftland',
  'Schömberg',
  'Schönaich',
  'Schönau',
  'Schönau Am Königssee',
  'Schönberg',
  'Schönbühl',
  'Schöndorf',
  'Schönebeck',
  'Schöneberg',
  'Schöneck',
  'Schönefeld',
  'Schöneiche',
  'Schönenberg',
  'Schönenbuch',
  'Schönengrund',
  'Schönenwerd',
  'Schöningen',
  'Schönkirchen',
  'Schönsee',
  'Schönstedt',
  'Schöntal',
  'Schönwald',
  'Schönwalde',
  'Schöppenstedt',
  'Schöppingen',
  'Schötmar',
  'Schötz',
  'Schramberg',
  'Schrassig',
  'Schriesheim',
  'Schriever',
  'Schrobenhausen',
  'Schroon Lake',
  'Schruns',
  'Schubelbach',
  'Schuby',
  'Schumacher',
  'Schupfen',
  'Schutterwald',
  'Schuttrange',
  'Schuyler',
  'Schuyler Lake',
  'Schuylerville',
  'Schuylkill Haven',
  'Schübelbach',
  'Schüpfen',
  'Schwabach',
  'Schwabenheim',
  'Schwabmünchen',
  'Schwadorf',
  'Schwaig',
  'Schwaigern',
  'Schwaikheim',
  'Schwalbach',
  'Schwalmstadt',
  'Schwalmtal',
  'Schwanden',
  'Schwandorf',
  'Schwanenstadt',
  'Schwanewede',
  'Schwanheim',
  'Schwarmstedt',
  'Schwarzach',
  'Schwarzenbach',
  'Schwarzenbach An Der Saale',
  'Schwarzenbek',
  'Schwarzenbruck',
  'Schwarzenburg',
  'Schwarzenfeld',
  'Schwarzenholz',
  'Schwarzheide',
  'Schwarzhofen',
  'Schwarzwald',
  'Schwaz',
  'Schwäbisch Gmünd',
  'Schwäbisch Hall',
  'Schwechat',
  'Schwedt',
  'Schwedt/oder',
  'Schweich',
  'Schweinfurt',
  'Schweitenkirchen',
  'Schweiz',
  'Schweizerhalle',
  'Schwelm',
  'Schwend',
  'Schwendi',
  'Schwenksville',
  'Schwenningen',
  'Schwepnitz',
  'Schwerin',
  'Schwertberg',
  'Schwerte',
  'Schwesing',
  'Schwetzingen',
  'Schweyen',
  'Schwieberdingen',
  'Schwyz',
  'Scicli',
  'Scinawka Srednia',
  'Scio',
  'Scionzier',
  'Sciota',
  'Scituate',
  'Sclessin',
  'Scooba',
  'Scoresby',
  'Scorzè',
  'Scotch Plains',
  'Scotia',
  'Scotland',
  'Scott',
  'Scott Air Force Base',
  'Scott City',
  'Scott Depot',
  'Scottdale',
  'Scotts',
  'Scotts Valley',
  'Scottsbluff',
  'Scottsboro',
  'Scottsburg',
  'Scottsdale',
  'Scottsville',
  'Scottville',
  'Scranton',
  'Scruton',
  'Scunthorpe',
  'Scuol',
  'Scy-chazelles',
  'Sde Boker',
  'Sde Yaakov',
  'Sderot',
  'Sea Cliff',
  'Sea Girt',
  'Sea Island',
  'Sea Isle City',
  'Seabrook',
  'Seacliff',
  'Seafield',
  'Seaford',
  'Seaforth',
  'Seagoville',
  'Seagrove',
  'Seaham',
  'Seal Beach',
  'Sealy',
  'Searcy',
  'Seaside',
  'Seaside Heights',
  'Seaside Park',
  'Seaton',
  'Seaton Delaval',
  'Seattle',
  'Seaview',
  'Sebastian',
  'Sebastopol',
  'Sebeka',
  'Seberang Jaya',
  'Seberi',
  'Seberov',
  'Sebring',
  'Sebringville',
  'Secaucus',
  'Secchia',
  'Sechelt',
  'Seckenheim',
  'Seclin',
  'Secor',
  'Secunderabad',
  'Secundurabad',
  'Sedalia',
  'Sedan',
  'Sedaví',
  'Sede Hemed',
  'Sede Warburg',
  'Sederot',
  'Sedgefield',
  'Sedgley',
  'Sedico',
  'Sedlcany',
  'Sedona',
  'Sedrata',
  'Sedriano',
  'Sedro Woolley',
  'Sedrun',
  'Sedziszow Malopolski',
  'Seebach',
  'Seedorf',
  'Seefeld',
  'Seefeld In Tirol',
  'Seegräben',
  'Seeham',
  'Seehausen Am Staffelsee',
  'Seeheim-jugenheim',
  'Seekirchen',
  'Seekonk',
  'Seelbach',
  'Seeley Lake',
  'Seelscheid',
  'Seelze',
  'Seend',
  'Seengen',
  'Seeon',
  'Seesen',
  'Seeshaupt',
  'Seevetal',
  'Seewalchen',
  'Seewiesen',
  'Seffner',
  'Segamat',
  'Segambut',
  'Segeltorp',
  'Segersta',
  'Segev',
  'Seglora',
  'Segorbe',
  'Segovia',
  'Segrate',
  'Segré',
  'Seguin',
  'Segundo',
  'Sehitkamil',
  'Sehnde',
  'Seia',
  'Seibersdorf',
  'Seiches-sur-le-loir',
  'Seifen',
  'Seignosse',
  'Seigy',
  'Seilh',
  'Seinäjoki',
  'Seine-port',
  'Seingbouse',
  'Seisdon',
  'Seitenroda',
  'Seitenstetten',
  'Seitingen-oberflacht',
  'Seixal',
  'Sejung',
  'Sekiguchi',
  'Selah',
  'Selangor',
  'Selargius',
  'Selatan',
  'Selb',
  'Selbitz',
  'Selby',
  'Selbyville',
  'Selçuklu',
  'Selden',
  'Sele',
  'Selfoss',
  'Selho',
  'Seligenporten',
  'Seligenstadt',
  'Seligenthal',
  'Selinsgrove',
  'Selje',
  'Seljord',
  'Selkirk',
  'Sellebakk',
  'Sellersburg',
  'Sellersville',
  'Selles',
  'Selles-sur-cher',
  'Sellindge',
  'Sells',
  'Selm',
  'Selma',
  'Selman City',
  'Selmer',
  'Selmsdorf',
  'Selna',
  'Selnica',
  'Selsey',
  'Selston',
  'Seltisberg',
  'Selva',
  'Selvazzano Dentro',
  'Selzach',
  'Selzen',
  'Sem',
  'Semaphore',
  'Semaran',
  'Semarang',
  'Sembach',
  'Sembrancher',
  'Semenivka',
  'Semenyih',
  'Semic',
  'Semiluki',
  'Semily',
  'Semington',
  'Seminole',
  'Seminyak',
  'Semmes',
  'Semnan',
  'Sempach',
  'Sempas',
  'Sempeter',
  'Semsales',
  'Senador Canedo',
  'Senago',
  'Senai',
  'Senatobia',
  'Senayan',
  'Senboku',
  'Sencur',
  'Send',
  'Sendai',
  'Sendai-shi',
  'Senden',
  'Sendenhorst',
  'Senec',
  'Seneca',
  'Seneca Falls',
  'Seneca Rocks',
  'Seneffe',
  'Senen',
  'Senftenberg',
  'Sengenthal',
  'Senhora Da Hora',
  'Senica',
  'Senigallia',
  'Senju',
  'Senkoy',
  'Senlac',
  'Senlis',
  'Senmanat',
  'Sennan',
  'Sennecey-le-grand',
  'Senneville',
  'Sennfeld',
  'Senningen',
  'Sennwald',
  'Sennybridge',
  'Senoia',
  'Senonches',
  'Senov',
  'Senovo',
  'Sens',
  'Sensenruth',
  'Senta',
  'Sentaraille',
  'Sentilj',
  'Sentinel',
  'Sentjernej',
  'Sentul',
  'Sentvid',
  'Senzu',
  'Seocho',
  'Seochon',
  'Seodun',
  'Seon',
  'Seongdong',
  'Seongnae',
  'Seongnam',
  'Seongsan',
  'Seongsu',
  'Seoni',
  'Seosan',
  'Seoul',
  'Sepang',
  'Sept-Îles',
  'Sept-îles',
  'Septèmes-les-vallons',
  'Seputeh',
  'Sequeira',
  'Sequim',
  'Seraing',
  'Seraing-le-château',
  'Serampore',
  'Serang',
  'Seravezza',
  'Serazereux',
  'Serba',
  'Serban Voda',
  'Serdang',
  'Serdang Baharu',
  "Sered'",
  'Seregno',
  'Serekunda',
  'Seremban',
  'Seren Del Grappa',
  'Sereno',
  'Serfaus',
  'Sergeant Bluff',
  'Sergipe',
  'Sergiyev Posad',
  'Seri Kembangan',
  'Seri Manjung',
  'Seriate',
  'Serio',
  'Serle',
  'Sermiers',
  'Sernaglia Della Battaglia',
  'Serooskerke',
  'Seropédica',
  'Serowe',
  'Serpa',
  'Serpong',
  'Serpukhov',
  'Serra',
  'Serra San Bruno',
  'Serramanna',
  'Serranillos Del Valle',
  'Serravalle',
  'Serravalle Pistoiese',
  'Serravalle Scrivia',
  'Serravalle Sesia',
  'Serre-lès-sapins',
  'Serres-castet',
  'Serrières-de-briord',
  'Serrig',
  'Serris',
  'Sersale',
  'Sersheim',
  'Sertãozinho',
  'Servian',
  'Servon',
  'Serzedo',
  'Seseña',
  'Seseña Nuevo',
  'Sessa',
  'Sestajovice',
  'Sestao',
  'Sesto Calende',
  'Sesto Fiorentino',
  'Sesto San Giovanni',
  'Sestri Levante',
  'Sestu',
  'Sesvete',
  'Sesvetski Kraljevec',
  'Setagaya',
  'Setapak',
  'Sete Lagoas',
  'Setiabudi',
  'Seto',
  'Settimo Torinese',
  'Settle',
  'Settsu',
  'Setúbal',
  'Seugy',
  'Seul',
  'Seux',
  'Seuzach',
  'Seva',
  'Sevastopol',
  'Sevelen',
  'Seven Hills',
  'Sevenoaks',
  'Seventeen Seventy',
  'Sevenum',
  'Sever Do Vouga',
  'Severance',
  'Severn',
  'Severna Park',
  'Seveso',
  'Sevierville',
  'Sevilla',
  'Sevilla La Nueva',
  'Seville',
  'Sevnica',
  'Sevojno',
  'Sevran',
  'Sewanee',
  'Seward',
  'Sewell',
  'Sewickley',
  'Sewri',
  'Sexau',
  'Sexbierum',
  'Sexsmith',
  'Seydisehir',
  'Seyhan',
  'Seymour',
  'Seynes',
  'Seynod',
  'Seysses',
  'Seyssinet-pariset',
  'Seyssins',
  'Sezana',
  'Sezemice',
  'Sezimovo Usti',
  'Sezze',
  'Sète',
  'Sèvres',
  'Sélestat',
  'Séméac',
  'Sémoy',
  'Séné',
  'Sérignan-du-comtat',
  'Sérres',
  'Sétif',
  'Sévérac-le-château',
  'Sévrier',
  'Sézanne',
  'Sfantu Gheorghe',
  'Sfax',
  'Sfântu-gheorghe',
  'Sgonico',
  'Sha Tin',
  'Shady Cove',
  'Shady Grove',
  'Shafa `amr',
  'Shafter',
  'Shaftesbury',
  'Shah Alam',
  'Shahapur',
  'Shahdara',
  'Shahe',
  'Shahjahanpur',
  'Shajing',
  'Shakopee',
  'Shakurpur',
  'Shalford',
  'Shalimar',
  'Shallotte',
  'Shallowater',
  'Shalu',
  'Sham Shui Po',
  'Shamir',
  'Shamokin',
  'Shandong',
  'Shangai',
  'Shangcheng',
  'Shanghai',
  'Shanghan',
  'Shangjie',
  'Shangjiezhen',
  'Shangqiu',
  'Shangrao',
  'Shangrao Shi',
  'Shangyu',
  'Shangzhou',
  'Shangzhu',
  'Shanhua',
  'Shankill',
  'Shanlin',
  'Shannan',
  'Shannon',
  'Shannon City',
  'Shantou',
  'Shanwei',
  'Shanxi',
  'Shanzhen',
  'Shaoguan',
  'Shaoxing',
  'Shaoyang',
  'Shapingba',
  'Shapleigh',
  'Sharbot Lake',
  'Sharja',
  'Sharjah',
  'Sharm El-sheikh',
  'Sharnbrook',
  'Sharnford',
  'Sharon',
  'Sharon Center',
  'Sharon Hill',
  'Sharon Springs',
  'Sharona',
  'Sharpsburg',
  'Sharpsville',
  'Sharq Hawalli',
  'Shasta Lake',
  'Shatoujiao',
  'Shatura',
  'Shavei Zion',
  'Shaver Lake',
  'Shavertown',
  'Shawano',
  'Shawbury',
  'Shawford',
  'Shawforth',
  'Shawinigan',
  'Shawnee',
  'Shawnee Mission',
  'Shawnee On Delaware',
  'Shchelkovo',
  'Shcherbinka',
  'Shchëlkovo',
  'Sheboygan',
  'Sheboygan Falls',
  'Shedema',
  'Shedfield',
  'Shediac',
  'Sheen',
  'Shefayim',
  'Sheffield',
  'Sheffield Lake',
  'Shefford',
  'Shehong',
  'Sheikh Habibullah',
  'Shek O',
  'Shekef',
  'Shekou',
  'Shelburne',
  'Shelburne Falls',
  'Shelby',
  'Shelbyville',
  'Sheldon',
  'Shelfanger',
  'Shell',
  'Shell Knob',
  'Shelley',
  'Shellharbour',
  'Shelter Island',
  'Shelton',
  'Shenandoah',
  'Shenchen',
  'Shenfield',
  'Shengdong',
  'Shengfang',
  'Shengze',
  'Shengzhou',
  'Shenhe',
  'Shenkeng',
  'Shenstone',
  'Shenton Park',
  'Shenyang',
  'Shenyang Shi',
  'Shenze',
  'Shenzhen',
  'Shenzhong',
  'Shenzhou',
  'Shepherdstown',
  'Shepherdsville',
  'Shepley',
  'Shepparton',
  'Shepparton Shire',
  'Shepperton',
  'Shepreth',
  'Shepshed',
  'Shepton Mallet',
  'Sherborn',
  'Sherborne',
  'Sherbrooke',
  'Sherburn Hill',
  'Sherburn In Elmet',
  'Sherburne',
  'Shercock',
  'Shere',
  'Sheridan',
  'Sheridan Lake',
  'Sheringham',
  'Sherman',
  'Sherman Oaks',
  'Sherrills Ford',
  'Sherwood',
  'Sherwood Park',
  'Shetulim',
  'Sheung Wan',
  'Shevchenko',
  'Sheyang',
  'Shezhen',
  'Shiba',
  'Shibadaimon',
  'Shibata',
  'Shibaura',
  'Shibei',
  'Shibîn El-kôm',
  'Shibuya',
  'Shichika',
  'Shifang',
  'Shifnal',
  'Shihezi',
  'Shihlin',
  'Shijiazhuang',
  'Shijingshan',
  'Shijonawate',
  'Shiki',
  'Shildon',
  'Shillington',
  'Shillong',
  'Shima',
  'Shimada',
  'Shimbashi',
  'Shimizu',
  'Shimla',
  'Shimoda',
  'Shimoga',
  'Shimogo',
  'Shimonoseki',
  'Shinan',
  'Shinbashi',
  'Shiner',
  'Shinfield',
  'Shingle Springs',
  'Shingu',
  'Shinjuku',
  'Shinkawa',
  'Shinmachi',
  'Shioda',
  'Shiodome',
  'Shiojiri',
  'Shioya',
  'Shipley',
  'Shippensburg',
  'Shippenville',
  'Shipshewana',
  'Shipston On Stour',
  'Shiqi',
  'Shiqiao',
  'Shiqiaopu',
  'Shirakawa',
  'Shiraoka',
  'Shirayama',
  'Shiraz',
  'Shirdi',
  'Shirebrook',
  'Shirehampton',
  'Shireoaks',
  'Shirley',
  'Shiroishi',
  'Shirokane',
  'Shirokanedai',
  'Shirpur',
  'Shishi',
  'Shishou',
  'Shiv',
  'Shivamogga',
  'Shivpuri',
  'Shiyan',
  'Shizuishan',
  'Shizuoka',
  'Shizuoka-shi',
  'Shkodër',
  'Shkodra',
  'Shlomi',
  'Shluhot',
  "Sho'eva",
  'Shoals',
  'Shoeburyness',
  'Shoemakersville',
  'Shohola',
  'Shomera',
  'Shomolu',
  'Shoreditch',
  'Shoreham',
  'Shoreham-by-sea',
  'Shoreline',
  'Shoresh',
  'Shorewood',
  'Shorkot',
  'Shorne',
  'Short Hills',
  'Shouguang',
  'Shouning',
  'Shoushan',
  'Show Low',
  'Shreve',
  'Shreveport',
  'Shrewsbury',
  'Shrub Oak',
  'Shuaiba',
  'Shuanggang',
  'Shuangjia',
  'Shuangliu',
  'Shuangqiao',
  'Shulin',
  'Shumen',
  'Shunde',
  'Shunyi',
  'Shupiyan',
  'Shuwaikh',
  'Shuwaikh Port',
  'Shuyang',
  'Shymkent',
  'Si Racha',
  'Sialkot',
  'Siauliai',
  'Siauliu',
  'Sibbhult',
  'Sibenik',
  'Sibiu',
  'Sibley',
  'Sibsagar',
  'Sibu',
  'Siccieu-saint Julien-carisieu',
  'Sichuan',
  'Sicienko',
  'Sicklerville',
  'Sidcup',
  'Siddapur',
  'Siddharthanagar',
  'Siddipet',
  'Sidhpur',
  'Sidi Bouzid',
  'Sidi Maarouf',
  'Sidlesham',
  'Sidmouth',
  'Sidney',
  'Sidoarjo',
  'Siebeldingen',
  'Siebnen',
  'Siechnice',
  'Siedlce',
  'Siedlisko',
  'Siegburg',
  'Siegen',
  'Siegenburg',
  'Siegendorf',
  'Siegertsbrunn',
  'Siem Reap',
  'Siemianowice Slaskie',
  'Siena',
  'Sieradz',
  'Sierentz',
  'Sierksdorf',
  'Siero',
  'Sierpc',
  'Sierra Blanca',
  'Sierra De Yeguas',
  'Sierra Madre',
  'Sierra Vista',
  'Sierra-engarcerán',
  'Sierrapando',
  'Sierre',
  'Siete Aguas',
  'Sievi',
  'Sigel',
  'Sigglesthorne',
  'Sighisoara',
  'Sigirino',
  'Siglufjörður',
  'Sigmaringen',
  'Sigmaringendorf',
  'Signal Hill',
  'Signal Mountain',
  'Signau',
  'Signes',
  'Sigolsheim',
  'Sigtuna',
  'Sigulda',
  'Sigüeiro',
  'Sihanoukville',
  'Siheungodong',
  'Sihor',
  'Sihung',
  'Sijsele',
  'Sikandarabad',
  'Sikar',
  'Sikeston',
  'Silales',
  'Silang',
  'Silava',
  'Silbitz',
  'Silchar',
  'Silchester',
  'Sile',
  'Silea',
  'Sileby',
  'Siler City',
  'Siliguri',
  'Silivri',
  'Siljan',
  'Siljansnäs',
  'Silkeborg',
  'Silkstone',
  'Silla',
  'Sillamäe',
  'Sillenstede',
  'Sillim',
  'Sillingy',
  'Sillod',
  'Silly',
  'Siloam Springs',
  'Sils',
  'Sils Im Domleschg',
  'Silsbee',
  'Silsden',
  'Silsoe',
  'Silt',
  'Silute',
  'Silvan',
  'Silvassa',
  'Silveira',
  'Silver City',
  'Silver Lake',
  'Silver Point',
  'Silver Spring',
  'Silver Springs',
  'Silverado',
  'Silverdale',
  'Silverlake',
  'Silverstone',
  'Silverthorne',
  'Silverton',
  'Silverwater',
  'Silves',
  'Silvi',
  'Silvis',
  'Simandres',
  'Simanovci',
  'Simao',
  'Simard',
  'Simbach Am Inn',
  'Simbario',
  'Simcheon',
  'Simcoe',
  'Simferopol',
  'Simi Valley',
  'Simmerath',
  'Simmern',
  'Simmersfeld',
  'Simnicu De Sus',
  'Simpang Ampat',
  'Simpele',
  'Simpelveld',
  'Simpson',
  'Simpsonville',
  'Simrishamn',
  'Simsbury',
  'Sinaai',
  'Sinaia',
  'Sinaloa',
  'Sinalunga',
  'Sincan',
  'Sincelejo',
  'Sinchon',
  'Sinchondong',
  'Sincraiu De Mures',
  'Sindelfingen',
  'Sinderen',
  'Sindorf',
  'Sineu',
  'Singaparna',
  'Singapore',
  'Singapur',
  'Singaraja',
  'Singen',
  'Singil',
  'Singleton',
  'Sinhan',
  'Sinhyeon',
  'Sinking Spring',
  'Sinop',
  'Sins',
  'Sinsadong',
  'Sinsheim',
  'Sint Annaparochie',
  'Sint Anthonis',
  'Sint Maarten',
  'Sint Michielsgestel',
  'Sint Pancras',
  'Sint Willebrord',
  'Sint-agatha-berchem',
  'Sint-amandsberg',
  'Sint-andries',
  'Sint-baafs-vijve',
  'Sint-denijs-westrem',
  'Sint-eloois-vijve',
  'Sint-eloois-winkel',
  'Sint-genesius-rode',
  'Sint-gillis',
  'Sint-gillis-waas',
  'Sint-jans-molenbeek',
  'Sint-joost-ten-node',
  'Sint-joris-winge',
  'Sint-katelijne-waver',
  'Sint-katherina-lombeek',
  'Sint-kruis',
  'Sint-lambrechts-woluwe',
  'Sint-laureins',
  'Sint-lievens-houtem',
  'Sint-martens-latem',
  'Sint-michiels',
  'Sint-niklaas',
  'Sint-oedenrode',
  'Sint-pauwels',
  'Sint-pieters-leeuw',
  'Sint-pieters-woluwe',
  'Sint-stevens-woluwe',
  'Sint-truiden',
  'Sinton',
  'Sintra',
  'Sinzheim',
  'Sinzig',
  'Sinzing',
  'Sion',
  'Sioux Center',
  'Sioux City',
  'Sioux Falls',
  'Siófok',
  'Siperia',
  'Siping',
  'Sipoo',
  'Sippy Downs',
  'Siputeh',
  'Siqian',
  'Siracusa',
  'Sirault',
  'Sirhind',
  'Sirkka',
  'Sirnach',
  'Sirsa',
  'Siruseri',
  'Sisak',
  'Siselen',
  'Sisli',
  'Sissach',
  'Sisseton',
  'Sister Bay',
  'Sisters',
  'Sistersville',
  'Sitamarhi',
  'Sitarganj',
  'Siteía',
  'Sitges',
  'Sitiawan',
  'Sitka',
  'Sitrah',
  'Sittard',
  'Sittensen',
  'Sittingbourne',
  'Siuntio',
  'Sivaganga',
  'Sivakasi',
  'Sivas',
  'Siviriez',
  'Siwan',
  'Six-fours-la-plage',
  'Sixmilebridge',
  'Sixthaselbach',
  'Siziano',
  'Sizzano',
  'Síndos',
  'Sísion',
  'Själevad',
  'Sjöbo',
  'Sjömarken',
  'Sjølund',
  'Sjøvegan',
  'Skagen',
  'Skagway',
  'Skaland',
  'Skalica',
  'Skanderborg',
  'Skane',
  'Skaneateles',
  'Skaneateles Falls',
  'Skanör',
  'Skara',
  'Skarnes',
  'Skarpnäck',
  'Skarszewy',
  'Skarzysko-kamienna',
  'Skattkärr',
  'Skaun',
  'Skawina',
  'Skälby',
  'Skänninge',
  'Skärblacka',
  'Skärhamn',
  'Skärholmen',
  'Skärplinge',
  'Skårer',
  'Skedsmokorset',
  'Skedvi',
  'Skee',
  'Skegness',
  'Skellefteå',
  'Skelmersdale',
  'Skelmorlie',
  'Skelton',
  'Skelton-in-cleveland',
  'Skene',
  'Skepplanda',
  'Skerike',
  'Skerries',
  'Skhimatárion',
  'Skhirat',
  'Ski',
  'Skibbereen',
  'Skibby',
  'Skien',
  'Skierniewice',
  'Skikda',
  'Skillingaryd',
  'Skillman',
  'Skinnskatteberg',
  'Skippack',
  'Skipton',
  'Skive',
  'Skjeberg',
  'Skjern',
  'Skjold',
  'Skoczów',
  'Skodje',
  'Skodsborg',
  'Skofja Loka',
  'Skofljica',
  'Skogås',
  'Skoghall',
  'Skogstorp',
  'Skokie',
  'Skolkovo',
  'Skomielna Biala',
  'Skopje',
  'Skoppum',
  'Skotterud',
  'Skovlunde',
  'Skowhegan',
  'Skórzewo',
  'Sköldinge',
  'Sköllersta',
  'Sköndal',
  'Skövde',
  'Skreia',
  'Skretting',
  'Skudai',
  'Skudeneshavn',
  'Skultuna',
  'Skurup',
  'Skvyra',
  'Skwentna',
  'Skykomish',
  'Skyland',
  'Skyllberg',
  'Skytop',
  'Skytta',
  'Skýdra',
  'Skælskør',
  'Skærbæk',
  'Skævinge',
  'Skødstrup',
  'Skøelv',
  'Skørping',
  'Slack',
  'Slacks Creek',
  'Slagelse',
  'Slagharen',
  'Slaithwaite',
  'Slangerup',
  'Slany',
  'Slapanice',
  'Slate Hill',
  'Slaterville Springs',
  'Slatina',
  'Slatinany',
  'Slaton',
  'Slave Lake',
  'Slavicin',
  'Slavkov U Brna',
  'Slavnica',
  'Slavonski Brod',
  'Slavyansk',
  'Slayton',
  'Sleaford',
  'Sleen',
  'Sleepy Eye',
  'Sleman',
  'Slemani',
  'Slemmestad',
  'Slesin',
  'Slezska Ostrava',
  'Sliac',
  'Slidell',
  'Sliedrecht',
  'Sliema',
  'Sligo',
  'Slijkenburg',
  'Slinfold',
  'Slinger',
  'Slingerlands',
  'Slingsby',
  'Slipi',
  'Slippery Rock',
  'Slite',
  'Sliven',
  'Slivenec',
  'Sloatsburg',
  'Sloboda',
  'Slobozia',
  'Slochteren',
  'Slootdorp',
  'Sloterdijk',
  'Slough',
  'Slovany',
  'Slovákov',
  'Slovenj Gradec',
  'Slovenska Bistrica',
  'Slovenska Lupca',
  'Slovyansk',
  'Slubice',
  'Sluis',
  'Slupsk',
  'Slusovice',
  'Slyudyanka',
  'Small Heath',
  'Smarje',
  'Smarr',
  'Smartno',
  'Smartno Pri Litiji',
  'Smarves',
  'Smålandsstenar',
  'Smederevo',
  'Smederevska Palanka',
  'Smedjebacken',
  'Smethport',
  'Smethwick',
  'Smichov',
  'Smicksburg',
  'Smilde',
  'Smith Center',
  'Smith River',
  'Smithers',
  'Smithfield',
  'Smiths Falls',
  'Smithsburg',
  'Smithshire',
  'Smithton',
  'Smithtown',
  'Smithville',
  'Smizany',
  'Smock',
  'Smolensk',
  'Smolenskiy',
  'Smögen',
  'Smutprakan',
  'Smyrna',
  'Smørumnedre',
  'Snailwell',
  'Snaith',
  'Snåsa',
  'Sneek',
  'Snellville',
  'Snetterton',
  'Snina',
  'Snodland',
  'Snohomish',
  'Snoqualmie',
  'Snow Hill',
  'Snowmass',
  'Snowmass Village',
  'Snowshoe',
  'Snyder',
  'Soacha',
  'Soave',
  'Sobernheim',
  'Sobeslav',
  'Sobieski',
  'Sobotiste',
  'Sobral',
  'Sobrance',
  'Sobreda',
  'Sobrosa',
  'Sochaczew',
  'Sochaux',
  'Sochi',
  'Sochodong',
  'Society Hill',
  'Socorro',
  'Socuéllamos',
  'Socx',
  'Soda Springs',
  'Sodankylä',
  'Soddy Daisy',
  'Sodrazica',
  'Sodus',
  'Soerendonk',
  'Soest',
  'Soestduinen',
  'Soesterberg',
  'Soeul',
  'Sofia',
  'Sofieberg',
  'Sofiya',
  'Sogn',
  'Sogndal',
  'Sogongdong',
  'Sogwipo',
  'Soham',
  'Sohar',
  'Sohl',
  'Sohland',
  'Sohna',
  'Sohren',
  'Soignies',
  'Soings-en-sologne',
  'Sointula',
  'Soissons',
  'Soisy',
  'Soisy-sous-montmorency',
  'Soja',
  'Soka',
  'Sokolnice',
  'Sokolov',
  'Sokolow Podlaski',
  'Sokoto',
  'Sola',
  'Solaize',
  'Solan',
  'Solana Beach',
  'Solapur',
  'Solaro',
  'Solbergelva',
  'Solbiate Arno',
  'Solbiate Olona',
  'Solbjerg',
  'Solcany',
  'Soldiers Grove',
  'Soldotna',
  'Soledad',
  'Soledad De Doblado',
  'Solesmes',
  'Soleuvre',
  'Soliera',
  'Soliers',
  'Solignano',
  'Solihull',
  'Solin',
  'Solingen',
  'Solkan',
  'Sollana',
  'Sollefteå',
  'Sollentuna',
  'Solliès-toucas',
  'Solliès-ville',
  'Solms',
  'Solna',
  'Solo',
  'Solofra',
  'Solomon',
  'Solon',
  'Solothurn',
  'Solrød Strand',
  'Solsona',
  'Solt',
  'Soltau',
  'Soltvadkert',
  'Solvang',
  'Solymár',
  'Somain',
  'Sombor',
  'Somborn',
  'Sombreffe',
  'Sombrerete',
  'Somerdale',
  'Someren',
  'Somero',
  'Somers',
  'Somers Point',
  'Somersby',
  'Somerset',
  'Somerset West',
  'Somersham',
  'Somersworth',
  'Somerton',
  'Somerville',
  'Somis',
  'Somma Lombardo',
  'Sommacampagna',
  'Sommariva Del Bosco',
  'Sommariva Perno',
  'Sommevoire',
  'Somogyvámos',
  'Somolu',
  'Sompting',
  'Son',
  'Son En Breugel',
  'Son Servera',
  'Sona',
  'Sonceboz',
  'Sondershausen',
  'Sondrio',
  'Sonepat',
  'Songam',
  'Songdo',
  'Songjiang',
  'Songkhla',
  'Songnam',
  'Songo',
  'Songpadong',
  'Sonipat',
  'Sonneberg',
  'Sonnefeld',
  'Sonnen',
  'Sonning',
  'Sonoma',
  'Sonora',
  'Sonpur',
  'Sonsbeck',
  'Sonseca',
  'Sontheim',
  'Sonthofen',
  'Sontra',
  'Sooke',
  'Soorts-hossegor',
  'Sophia',
  'Sophia Antipolis',
  'Sopot',
  'Sopó',
  'Sopron',
  'Soquel',
  'Sora',
  'Soragna',
  'Sorbas',
  'Sorbiers',
  'Sorbolo',
  'Sorel',
  'Sorell',
  'Sorens',
  'Soresina',
  'Sorgues',
  'Sori',
  'Soria',
  'Soriano Nel Cimino',
  'Sorinnes',
  'Sorocaba',
  'Soroti',
  'Sorrento',
  'Sorrizo',
  'Sort',
  'Sortland',
  'Sorzano',
  'Sorø',
  'Sosa',
  'Sosnowiec',
  'Sosúa',
  'Sotkuma',
  'Sotos',
  'Sotrondio',
  'Sotta',
  'Sottevast',
  'Sotteville-lès-rouen',
  'Sotto',
  'Sottrum',
  'Soualem',
  'Souderton',
  'Soufflenheim',
  'Souillac',
  'Soulac-sur-mer',
  'Soulbury',
  'Soulgé',
  'Soultz',
  'Soultz-haut-rhin',
  'Soultzmatt',
  'Soumagne',
  'Sound Beach',
  'Souppes-sur-loing',
  'Sousse',
  'South',
  'South Amboy',
  'South Bay',
  'South Beach',
  'South Beloit',
  'South Bend',
  'South Berwick',
  'South Boston',
  'South Bound Brook',
  'South Bowenfels',
  'South Brent',
  'South Brisbane',
  'South Burlington',
  'South Canaan',
  'South Cave',
  'South Cerney',
  'South Charleston',
  'South China',
  'South Dartmouth',
  'South Deerfield',
  'South Dennis',
  'South Easton',
  'South Egremont',
  'South El Monte',
  'South Elgin',
  'South Elmsall',
  'South Gate',
  'South Glastonbury',
  'South Glens Falls',
  'South Grafton',
  'South Hackensack',
  'South Hadley',
  'South Hamilton',
  'South Harwich',
  'South Haven',
  'South Hill',
  'South Holland',
  'South Houston',
  'South Jordan',
  'South Kensington',
  'South Kent',
  'South Kinangop',
  'South Lake Tahoe',
  'South Lancaster',
  'South Lebanon',
  'South Lopham',
  'South Lyon',
  'South Marston',
  'South Melbourne',
  'South Milwaukee',
  'South Mimms',
  'South Molton',
  'South Morang',
  'South Normanton',
  'South Norwood',
  'South Ockendon',
  'South Orange',
  'South Otterington',
  'South Ozone Park',
  'South Padre Island',
  'South Paris',
  'South Park',
  'South Pasadena',
  'South Perth',
  'South Pittsburg',
  'South Plainfield',
  'South Point',
  'South Portland',
  'South Queensferry',
  'South Range',
  'South Richmond Hill',
  'South River',
  'South Royalton',
  'South Saint Paul',
  'South Salem',
  'South Salt Lake',
  'South San Francisco',
  'South Shields',
  'South Sioux City',
  'South Surra',
  'South Sydney Municipality',
  'South Triangle',
  'South Valley Stream',
  'South Vienna',
  'South Wales',
  'South Weymouth',
  'South Windsor',
  'South Witham',
  'South Yarra',
  'Southall',
  'Southam',
  'Southampton',
  'Southaven',
  'Southbank',
  'Southborough',
  'Southbourne',
  'Southbridge',
  'Southbury',
  'Southeastern',
  'Southend',
  'Southend-on-sea',
  'Southern',
  'Southern Cross',
  'Southern Md Facility',
  'Southern Pines',
  'Southfield',
  'Southgate',
  'Southill',
  'Southington',
  'Southlake',
  'Southminster',
  'Southold',
  'Southport',
  'Southsea',
  'Southwark',
  'Southwater',
  'Southwell',
  'Southwest Brevard Cnty',
  'Southwest Harbor',
  'Southwick',
  'Southwold',
  'Souzy',
  'Soúda',
  'Soûr',
  'Sovata',
  'Soverato',
  'Sovetskaya',
  'Sovicille',
  'Sovodenj',
  'Sowerby Bridge',
  'Soweto',
  'Sowon',
  'Soyons',
  'Soyuz',
  'Sóller',
  'Sóskút',
  'Söderbärke',
  'Söderhamn',
  'Söderköping',
  'Söderkulla',
  'Södertälje',
  'Södertörns Villastad',
  'Södra Björke',
  'Södra Sandby',
  'Sögel',
  'Söhlde',
  'Sölden',
  'Söllingen',
  'Sölvesborg',
  'Sömmerda',
  'Sönnebüll',
  'Sörberge',
  'Sörgenloch',
  'Sösdala',
  'Spa',
  'Spacince',
  'Spaichingen',
  'Spain',
  'Spalding',
  'Spalene Porici',
  'Spalt',
  'Spanaway',
  'Spanbroek',
  'Spandau',
  'Spangenberg',
  'Spangle',
  'Spanish',
  'Spanish Fork',
  'Spanish Fort',
  'Spankeren',
  'Spardorf',
  'Sparkill',
  'Sparkle',
  'Sparks',
  'Sparks Glencoe',
  'Sparreholm',
  'Sparrows Point',
  'Sparsör',
  'Sparta',
  'Spartanburg',
  'Spáta',
  'Spånga',
  'Spearfish',
  'Spearwood',
  'Speculator',
  'Speeton',
  'Speicher',
  'Speichersdorf',
  'Speightstown',
  'Speke',
  'Spencer',
  'Spencerport',
  'Spencertown',
  'Spencerville',
  'Spenge',
  'Spennymoor',
  'Speonk',
  'Spessart',
  'Spetchley',
  'Speyer',
  'Spiceland',
  'Spicewood',
  'Spiddal',
  'Spielberg',
  'Spiennes',
  'Spiesen-elversberg',
  'Spiesheim',
  'Spiez',
  'Spijkenisse',
  'Spilamberto',
  'Spilimbergo',
  'Spilsby',
  'Spin',
  'Spindale',
  'Spinea',
  "Spino D'adda",
  'Spirit Lake',
  'Spisska Nova Ves',
  'Spisske Tomasovce',
  'Spitalfields',
  'Spjald',
  'Spjelkavik',
  'Splendora',
  'Split',
  'Spokane',
  'Spoleto',
  'Spoltore',
  'Spondon',
  'Spooner',
  'Spotswood',
  'Spotsylvania',
  'Sprang-capelle',
  'Spratton',
  'Spreenhagen',
  'Spreitenbach',
  'Spremberg',
  'Sprendlingen',
  'Sprimont',
  'Spring',
  'Spring Arbor',
  'Spring Branch',
  'Spring City',
  'Spring Creek',
  'Spring Gap',
  'Spring Green',
  'Spring Grove',
  'Spring Hill',
  'Spring Hope',
  'Spring House',
  'Spring Lake',
  'Spring Lake Park',
  'Spring Mount',
  'Spring Park',
  'Spring Valley',
  'Springboro',
  'Springbrook',
  'Springdale',
  'Springe',
  'Springer',
  'Springfield',
  'Springfield Gardens',
  'Springhill',
  'Springs',
  'Springtown',
  'Springvale',
  'Springvale South',
  'Springville',
  'Springwood',
  'Sprockhövel',
  'Spruce Grove',
  'Spruce Pine',
  'Sprundel',
  'Spur',
  'Spy',
  'Squamish',
  'Square',
  'Squires',
  'Sredisce',
  'Srednja Bistrica',
  'Srem',
  'Sremska Kamenica',
  'Sremska Mitrovica',
  'Sri Ganganagar',
  'Sri Jayewardenepura Kotte',
  'Srikakulam',
  'Srinagar',
  'Srinagar Garhwal',
  'Srinigar',
  'Sriperumbudur',
  'Sriracha',
  'Srivilliputtur',
  'Srodmiescie',
  'St Louis',
  'St. Charles',
  'St. Helena',
  'St. Helens',
  'St. Helier',
  "St. John's",
  "St. Mary's",
  'St. Pete Beach',
  'St. Peter Port',
  'St. Petersburg',
  'Sta Cruz',
  'Staaken',
  'Staasdorf',
  'Staatsburg',
  'Stabekk',
  'Stabio',
  'Stabroek',
  'Stacy',
  'Stade',
  'Stadel',
  'Staden',
  'Stadskanaal',
  'Stadt Frankfurt',
  'Stadtallendorf',
  'Stadtbergen',
  'Stadthagen',
  'Stadtilm',
  'Stadtlohn',
  'Stadtroda',
  'Stafa',
  'Staffa',
  'Staffanstorp',
  'Staffelbach',
  'Staffelfelden',
  'Stafford',
  'Stafford Springs',
  'Staffordshire Reef',
  'Staffordsville',
  'Staffort',
  'Stagno',
  'Stagno Lombardo',
  'Stahlavy',
  'Stahlstown',
  'Stahnsdorf',
  'Staindrop',
  'Staines',
  'Staines-upon-thames',
  'Stains',
  'Stalbridge',
  'Stalingrad',
  'Stallarholmen',
  'Stallen',
  'Stallikon',
  'Stallwang',
  'Stalowa Wola',
  'Stalybridge',
  'Stamboliyski',
  'Stambourne',
  'Stamford',
  'Stamford Bridge',
  'Stammham',
  'Stammheim',
  'Stamping Ground',
  'Stamsried',
  'Stanaford',
  'Stanbridge Station',
  'Standard',
  'Standerton',
  'Standish',
  'Standon',
  'Stanfield',
  'Stanford',
  'Stanford Le Hope',
  'Stangeland',
  'Stanger',
  'Stanhope',
  'Stankovce',
  'Stanley',
  'Stanleytown',
  'Stanmore',
  'Stanmore Bay',
  'Stanningley',
  'Stans',
  'Stansstad',
  'Stanstead Abbots',
  'Stansted',
  'Stansted Mountfitchet',
  'Stanton',
  'Stanton Lacy',
  'Stanway',
  'Stanwell',
  'Stanwix',
  'Stanwood',
  'Stapelfeld',
  'Staphorst',
  'Staplefield',
  'Stapleford',
  'Stapleford Tawney',
  'Staplehurst',
  'Staples',
  'Stapleton',
  'Staporkow',
  'Stapylton',
  'Star',
  'Stara Hut',
  'Stara Iwiczna',
  'Stara Lubovna',
  'Stara Pazova',
  'Stara Zagora',
  'Starachowice',
  'Staraya Kupavna',
  'Stará Turá',
  'Starbuck',
  'Stare Babice',
  'Stare Hradiste',
  'Stare Mesto',
  'Stare Miasto',
  'Stargard Szczecinski',
  'Starke',
  'Starkov',
  'Starksboro',
  'Starkville',
  'Starnberg',
  'Starogard Gdanski',
  'Starovelichkovskaya',
  'Stary Kolin',
  'Stary Oskol',
  'Stary Plzenec',
  'State College',
  'State Farm',
  'State Of Victoria',
  'State University',
  'Stateline',
  'Staten Island',
  'Statenice',
  'Statesboro',
  'Statesville',
  'Statham',
  'Stathelle',
  'Staubø',
  'Staudt',
  'Staufen',
  'Staufen Im Breisgau',
  'Staufenberg',
  'Staunton',
  'Staunton In The Vale',
  'Stavanger',
  'Staveley',
  'Stavelot',
  'Stavenger',
  'Stavern',
  'Stavropol',
  'Stavroúpoli',
  'Stawell',
  'Stayner',
  'Staythorpe',
  'Stayton',
  'Staßfurt',
  'Stäbelow',
  'Stäfa',
  'Steamboat Springs',
  'Steckborn',
  'Steckenroth',
  'Stedman',
  'Steele',
  'Steeleville',
  'Steelville',
  'Steenbecque',
  'Steenbergen',
  'Steendam',
  'Steenderen',
  'Steenokkerzeel',
  'Steenweg',
  'Steenwijk',
  'Steep Falls',
  'Steeton',
  'Stefanestii De Jos',
  'Steffisburg',
  'Stegaurach',
  'Stege',
  'Stegen',
  'Steibis',
  'Stein',
  'Stein Am Rhein',
  'Steinach',
  'Steinau',
  'Steinau An Der Straße',
  'Steinbach',
  'Steinberg',
  'Steinbergkirche',
  'Steindorf',
  'Steinebach',
  'Steinefrenz',
  'Steinen',
  'Steinenbronn',
  'Steinerberg',
  'Steineroth',
  'Steinfeld',
  'Steinfort',
  'Steinfurt',
  'Steingaden',
  'Steinhagen',
  'Steinhausen',
  'Steinhausen An Der Rottum',
  'Steinheim',
  'Steinkjer',
  'Steinsel',
  'Steinsland',
  'Steinweiler',
  'Steißlingen',
  'Stekene',
  'Stellarton',
  'Stelle',
  'Stellenbosch',
  'Stellendam',
  'Stembert',
  'Stemwarde',
  'Stendal',
  'Stenderup',
  'Stenhamra',
  'Stenkullen',
  'Stenlille',
  'Stenløse',
  'Stennis Space Center',
  'Stenovice',
  'Stenstorp',
  'Stenstrup',
  'Stenungsund',
  'Stepana Razina',
  'Stepaside',
  'Stephanskirchen',
  'Stephens City',
  'Stephentown',
  'Stephenville',
  'Sterboholy',
  'Sterling',
  'Sterling Heights',
  'Sterlitamak',
  'Sternberk',
  'Sternenfels',
  'Sterrebeek',
  'Sterrett',
  'Sterup',
  'Sterzing',
  'Stetten',
  'Stetten Am Kalten Markt',
  'Stettfurt',
  'Stettlen',
  'Stettler',
  'Steubenville',
  'Stevenage',
  'Stevens',
  'Stevens Point',
  'Stevenson',
  'Stevenson Ranch',
  'Stevenston',
  'Stevensville',
  'Steventon',
  'Stevoort',
  'Stewardson',
  'Stewart',
  'Stewarton',
  'Stewartstown',
  'Stewartsville',
  'Stewartville',
  'Steyerberg',
  'Steyning',
  'Steyr',
  'Steyregg',
  'Stezzano',
  'Stiefenhofen',
  'Stiens',
  'Stilesville',
  'Stillington',
  'Stillmore',
  'Stillorgan',
  'Stillwater',
  'Stilwell',
  'Stinson Beach',
  'Stio Pedro S. Lima',
  'Stiring-wendel',
  'Stirling',
  'Stittsville',
  'Stjørdalshalsen',
  'Stobrec',
  'Stochov',
  'Stockach',
  'Stockbridge',
  'Stockdale',
  'Stockdorf',
  'Stockelsdorf',
  'Stockerau',
  'Stockertown',
  'Stockheim',
  'Stockholm',
  'Stockport',
  'Stocksbridge',
  'Stocksfield',
  'Stockstadt',
  'Stockstadt Am Main',
  'Stockstadt Am Rhein',
  'Stocksund',
  'Stockton',
  'Stockton-on-tees',
  'Stoczek Lukowski',
  'Stodulky',
  'Stoetze',
  'Stogursey',
  'Stoke',
  'Stoke Gifford',
  'Stoke Hammond',
  'Stoke Mandeville',
  'Stoke Prior',
  'Stoke Saint Gregory',
  'Stoke-on-trent',
  'Stokenchurch',
  'Stokesdale',
  'Stokesley',
  'Stokke',
  'Stokkem',
  'Stokmarknes',
  'Stolberg',
  'Stollberg',
  'Stolniceni-prajescu',
  'Stolwijk',
  'Stolzenau',
  'Stonarov',
  'Stone',
  'Stone Harbor',
  'Stone Lake',
  'Stone Mountain',
  'Stone Park',
  'Stone Ridge',
  'Stone Town',
  'Stonefort',
  'Stoneham',
  'Stonehaven',
  'Stonehouse',
  'Stoneleigh',
  'Stoneville',
  'Stonewall',
  'Stoney Creek',
  'Stonington',
  'Stony Brook',
  'Stony Mountain',
  'Stony Plain',
  'Stony Point',
  'Stony Stratford',
  'Stopsley',
  'Stora Höga',
  'Stora Mellösa',
  'Storaas',
  'Storå',
  'Stord Island',
  'Stordal',
  'Store',
  'Storebö',
  'Storeng',
  'Storfors',
  'Storkow',
  'Storlien',
  'Storm Lake',
  'Stormville',
  'Stornoway',
  'Storo',
  'Storrington',
  'Storrs Mansfield',
  'Storuman',
  'Storvreta',
  'Story',
  'Story City',
  'Stotfold',
  'Stouffville',
  'Stoughton',
  'Stoumont',
  'Stourbridge',
  'Stourport',
  'Stoutenburg',
  'Stow',
  'Stow Cum Quy',
  'Stowe',
  'Stowmarket',
  'Stowting',
  'Stöckelsberg',
  'Stra',
  'Strabane',
  'Strada In Chianti',
  'Stradella',
  'Straelen',
  'Straffan',
  'Strafford',
  'Strahan',
  'Strahwalde',
  'Strakonice',
  'Stralsund',
  'Strambino',
  'Stramproy',
  'Strancice',
  'Strand',
  'Stranda',
  'Strandby',
  'Strandhill',
  'Stranraer',
  'Strasbourg',
  'Strasburg',
  'Strasnice',
  'Strass Im Zillertal',
  'Strassen',
  'Strasswalchen',
  'Straszyn',
  'Stratford',
  'Stratford On Avon',
  'Stratford-upon-avon',
  'Stratham',
  'Strathaven',
  'Strathcona',
  'Strathfield',
  'Strathmore',
  'Strathpine',
  'Strathroy',
  'Straubing',
  'Straume',
  'Strausberg',
  'Strawberry Hill',
  'Straz Nad Nisou',
  'Straz Pod Ralskem',
  'Straßberg',
  'Straße',
  'Straßen',
  'Straßkirchen',
  'Straßlach-dingharting',
  'Strängnäs',
  'Stråssa',
  'Streamwood',
  'Streatham',
  'Streator',
  'Streefkerk',
  'Street',
  'Streetman',
  'Streetsboro',
  'Streichen',
  'Strelice',
  'Strengberg',
  'Strengelbach',
  'Stresa',
  'Stretford',
  'Stretton',
  'Streufdorf',
  'Strée',
  'Strépy-bracquegnies',
  'Stribro',
  'Strigno',
  'Strigova',
  'Strijbeek',
  'Strijen',
  'Stritez',
  'Strixton',
  'Strmec',
  'Stroe',
  'Strogino',
  'Strombeek-bever',
  'Stromberg',
  'Stromness',
  'Stromsburg',
  'Strongsville',
  'Strood',
  'Stropkov',
  'Stroud',
  'Stroudsburg',
  'Strovolos',
  'Strömsholm',
  'Strömsnäsbruk',
  'Strömstad',
  'Strömsund',
  'Struckum',
  'Struer',
  'Struga',
  'Strumica',
  'Struthers',
  'Stryków',
  'Stryn',
  'Strzegom',
  'Strzelce Opolskie',
  'Strømmen',
  'Stuart',
  'Stuart Mill',
  'Stuarts Draft',
  'Studena',
  'Studenka',
  'Studham',
  'Studio City',
  'Studley',
  'Studzienice',
  'Stuhr',
  'Stulln',
  'Stupsk',
  'Sturbridge',
  'Sturgeon Bay',
  'Sturgeon Falls',
  'Sturgis',
  'Sturminster Newton',
  'Sturovo',
  'Sturtevant',
  'Stuttgart',
  'Stuyvesant',
  'Stuyvesant Falls',
  'Stühlingen',
  'Stützengrün',
  'Stützerbach',
  'Styrsö',
  'Støvring',
  'Suadiye',
  'Suan Luang',
  'Subang',
  'Subbiano',
  'Subiaco',
  'Subic',
  'Subingen',
  'Subirats',
  'Sublette',
  'Sublimity',
  'Subotica',
  'Sucany',
  'Succasunna',
  'Success',
  'Suceava',
  'Sucé',
  'Suchy Bór',
  'Suchy Las',
  'Suckley',
  'Sucy-en-brie',
  'Sudanell',
  'Sudbourne',
  'Sudbury',
  'Suderve',
  'Suez',
  'Suffern',
  'Suffield',
  'Suffolk',
  'Sugar City',
  'Sugar Grove',
  'Sugar Hill',
  'Sugar Land',
  'Sugar Valley',
  'Sugarcreek',
  'Sugarloaf',
  'Sugiez',
  'Suginomecho',
  'Sugito',
  'Suhl',
  'Suhr',
  'Suichang',
  'Suicheng',
  'Suika',
  'Suining',
  'Suippes',
  'Suisse',
  'Suisun City',
  'Suita',
  'Suite',
  'Suitland',
  'Suizhou',
  'Sujangarh',
  'Sukagawa',
  'Sukawati',
  'Sukkur',
  'Sukoharjo',
  'Sulaimani',
  'Sulaimania',
  'Sulaymaniyah',
  'Suldalsosen',
  'Sulechów',
  'Sulejówek',
  'Sulgen',
  'Sulingen',
  'Sulligent',
  'Sullivan',
  'Sullivans Island',
  'Sully',
  'Sulmingen',
  'Sulmona',
  'Sulphur',
  'Sulphur Springs',
  'Sultan',
  'Sultan Kudarat',
  'Sultana',
  'Sultanpur',
  'Sulthan Bathery',
  'Sulz',
  'Sulz Im Wienerwald',
  'Sulzano',
  'Sulzbach',
  'Sulzbach Am Main',
  'Sulzbach An Der Murr',
  'Sulzbach-rosenberg',
  'Sulzberg',
  'Sulzburg',
  'Sulzdorf',
  'Sulzemoos',
  'Sulzfeld',
  'Sumaré',
  'Sumas',
  'Sumavske Hostice',
  'Sumedang',
  'Sumerduck',
  'Sumida',
  'Suminoe',
  'Sumirago',
  'Sumiswald',
  'Sumiyoshi',
  'Summerdale',
  'Summerfield',
  'Summerhill',
  'Summerland',
  'Summerland Key',
  'Summerseat',
  'Summerside',
  'Summertown',
  'Summerville',
  'Summit',
  'Summit Argo',
  'Summit Hill',
  'Summit Point',
  'Sumner',
  'Sumperk',
  'Sumrall',
  'Sumter',
  'Sumterville',
  'Sumy',
  'Sun City',
  'Sun City Center',
  'Sun City West',
  'Sun Prairie',
  'Sun River',
  'Sun Valley',
  'Sunam',
  'Sunapee',
  'Sunburst',
  'Sunbury',
  'Sunbury-on-thames',
  'Sunchales',
  'Suncheon',
  'Sundance',
  'Sundby',
  'Sundbyberg',
  'Sunderland',
  'Sunderland Bridge',
  'Sundern',
  'Sundon',
  'Sundridge',
  'Sunds',
  'Sundsbruk',
  'Sundsvall',
  'Sundvolden',
  'Sundyberg',
  'Sungai Besi',
  'Sungai Buloh',
  'Sungai Buloh New Village',
  'Sungai Petani',
  'Sungei Dua',
  'Suning',
  'Sunland',
  'Sunland Park',
  'Sunndalsøra',
  'Sunne',
  'Sunnerö',
  'Sunningdale',
  'Sunninghill',
  'Sunnybank',
  'Sunnyside',
  'Sunnyslope',
  'Sunnyvale',
  'Suno',
  'Sunrise',
  'Sunrise Beach',
  'Sunset',
  'Sunset Beach',
  'Sunshine',
  'Sunyani',
  'Suolahti',
  'Suomijärvi',
  'Suomussalmi',
  'Superior',
  'Suqian',
  'Suquamish',
  'Sura Mica',
  'Surabaya',
  'Surajgarh',
  'Surakarta',
  'Surat',
  'Surat Thani',
  'Suratgarh',
  'Surberg',
  'Surbiton',
  'Surco',
  'Suresnes',
  'Surfers Paradise',
  'Surfside',
  'Surgoinsville',
  'Surgut',
  'Surhuisterveen',
  'Surprise',
  'Surquillo',
  'Surrency',
  'Surrey',
  'Surrey Hills',
  'Surry Hills',
  'Sursee',
  'Suru-lere',
  'Surville',
  'Suryapet',
  'Susaek',
  'Susaki',
  'Susano',
  'Susanville',
  'Susegana',
  'Susice',
  'Susono',
  'Sussex',
  'Susteren',
  'Susurluk',
  'Susville',
  'Sutherland',
  'Sutherlin',
  'Sutter',
  'Sutter Creek',
  'Sutton',
  'Sutton Bonington',
  'Sutton Coldfield',
  'Sutton Forest',
  'Sutton In Ashfield',
  'Sutton On The Forest',
  'Sutton Saint James',
  'Suva',
  'Suvereto',
  'Suwa',
  'Suwalki',
  'Suwanee',
  'Suwon',
  'Suyong',
  'Suzaka',
  'Suzano',
  'Suzhou',
  'Suzhou Shi',
  'Suzuka',
  'Suzuki',
  'Suzzara',
  'Südlohn',
  'Sükhbaatar',
  'Sülfeld',
  'Sülze',
  'Süsel',
  'Süßen',
  'Svalöv',
  'Svanesund',
  'Svatava',
  'Svärtinge',
  'Svätý Jur',
  'Svedala',
  'Svelgen',
  'Svendborg',
  'Svenljunga',
  'Svenshögen',
  'Svenstavik',
  'Svenstrup',
  'Sverdlov',
  'Sveta Nedelja',
  'Sveti Ivan Zelina',
  'Svetla Nad Sazavou',
  'Sviadnov',
  'Svidník',
  'Svinninge',
  'Svitavy',
  'Svojanov',
  'Svolvær',
  'Swadlincote',
  'Swaffham',
  'Swaffham Bulbeck',
  'Swainsboro',
  'Swakopmund',
  'Swallowfield',
  'Swalmen',
  'Swalwell',
  'Swampscott',
  'Swan Bay',
  'Swan Hill',
  'Swanage',
  'Swanley Junction',
  'Swanmore',
  'Swannanoa',
  'Swansboro',
  'Swansea',
  'Swanson',
  'Swanton',
  'Swanville',
  'Swanwick',
  'Swanzey',
  'Swarthmore',
  'Swartz Creek',
  'Swarzedz',
  'Swat',
  'Swatara',
  'Swavesey',
  'Sway',
  'Sweden',
  'Swedesboro',
  'Sweeden',
  'Sweet Briar',
  'Sweet Springs',
  'Sweet Water',
  'Sweileh',
  'Swiatniki Gorne',
  'Swidnica',
  'Swidnik',
  'Swiebodzice',
  'Swiebodzin',
  'Swiecie',
  'Swieradow-zdroj',
  'Swieta Katarzyna',
  'Swietochlowice',
  'Swift Current',
  'Swifterbant',
  'Swiftwater',
  'Swindon',
  'Swinton',
  'Swisher',
  'Swiss',
  'Swolgen',
  'Swoope',
  'Swords',
  'Sybrandaburen',
  'Sycamore',
  'Sydenham',
  'Sydney',
  'Sydney Mines',
  'Syeverodonetsk',
  'Syke',
  'Sykesville',
  'Syktyvkar',
  'Sylacauga',
  'Sylhet',
  'Sylling',
  'Sylmar',
  'Sylt-ost',
  'Sylva',
  'Sylvan Lake',
  'Sylvania',
  'Sylvester',
  'Syosset',
  'Syracuse',
  'Syria',
  'Syros',
  'Sysmä',
  'Syston',
  'Sywell',
  'Szada',
  'Szamotuly',
  'Szarvas',
  'Szarvasgede',
  'Szatymaz',
  'Százhalombatta',
  'Szczawno-zdrój',
  'Szczecin',
  'Szczecinek',
  'Szederkény',
  'Szeged',
  'Szekszárd',
  'Szendro',
  'Szentendre',
  'Szentes',
  'Szentgotthárd',
  'Szentistván',
  'Szentlorinc',
  'Szepietowo',
  'Székesfehérvár',
  'Szigethalom',
  'Szigetszentmárton',
  'Szigetszentmiklós',
  'Szigetvár',
  'Szirmabesenyo',
  'Szolnok',
  'Szombathely',
  'Szubin',
  'Szyszkow',
  'Sæby',
  'Sætre',
  'Søberg',
  'Søborg',
  'Søgne',
  'Sømna',
  'Sønder Omme',
  'Sønderborg',
  'Søndersø',
  'Søreidgrend',
  'Sørumsand',
  'Sørvad',
  'Søvik',
  "T'bilisi",
  "T'elavi",
  'Taarlo',
  'Taastrup',
  'Tabanan',
  'Tabarz',
  'Tabasco',
  'Tabata',
  'Tabdi',
  'Taber',
  'Taberg',
  'Tabernacle',
  'Tabernas',
  'Table Top',
  'Tabley',
  'Taboão Da Serra',
  'Tabokoto',
  'Tabor',
  'Tabor City',
  'Tabriz',
  'Tabuk',
  'Tabulbah',
  'Tacherting',
  'Tachikawa',
  'Tachlovice',
  'Tachov',
  'Tachung',
  'Tacloban City',
  'Tacoma',
  'Tacoronte',
  'Tadaoka',
  'Tadcaster',
  'Taden',
  'Tadepalli',
  'Tadley',
  'Tadworth',
  'Taean',
  'Taebla',
  'Taejon',
  'Tafers',
  'Taft',
  'Tagajo',
  'Taganrog',
  'Tagawa',
  'Tagaytay City',
  'Tagbilaran City',
  'Tagene',
  'Tagerwilen',
  'Taghazout',
  'Taglio Di Po',
  'Taguatinga',
  'Taguig',
  'Tagum',
  'Tahlequah',
  'Tahoe City',
  'Tahoma',
  'Tahtaci',
  'Tai',
  'Tai O',
  'Tai Po',
  'Taian',
  'Taibach',
  'Taicang',
  'Taichung',
  'Taichunghsien',
  'Taif',
  'Taihe',
  'Taiki',
  'Taillades',
  'Taimei',
  "Tain-l'hermitage",
  'Tainan',
  'Taipa',
  'Taipeh',
  'Taipei',
  'Taipeihsien',
  'Taiping',
  'Taishan',
  'Taissy',
  'Taivassalo',
  'Taiwan',
  'Taixing',
  'Taiyuan',
  'Taiyuan Shi',
  'Taizhou',
  'Tajimi',
  'Tajonar',
  'Tak',
  'Takahama',
  'Takajo',
  'Takaka',
  'Takamatsu',
  'Takamatsu-shi',
  'Takamiya',
  'Takanawa',
  'Takane',
  'Takanini',
  'Takaoka',
  'Takapuna',
  'Takarazuka',
  'Takasago',
  'Takasaki',
  'Takashima',
  'Takatsu-ku',
  'Takatsuki',
  'Takayama',
  'Takeda',
  'Takehara',
  'Takeley',
  'Takeo',
  'Taketoyo',
  'Taki',
  'Takigawa',
  'Takoma Park',
  'Takoradi',
  'Taksim',
  'Tal Shahar',
  'Talagante',
  'Talamanca',
  'Talamban',
  'Talamello',
  'Talangama North',
  'Talant',
  'Talatona',
  'Talavera De La Reina',
  'Talawatugoda',
  'Talby',
  'Talca',
  'Taldykorgan',
  'Talegaon Dabhade',
  'Taleigao',
  'Talence',
  'Talent',
  'Talheim',
  'Taling Chan',
  'Talisay',
  'Talkau',
  'Talkeetna',
  'Talladega',
  'Talladell',
  'Tallaght',
  'Tallahassee',
  'Tallassee',
  'Tallåsen',
  'Tallebudgera',
  'Tallevast',
  'Tallin',
  'Tallinn',
  'Tallinna',
  'Tallmadge',
  'Tallman',
  'Talloires',
  'Talmei Menashe',
  'Talmont',
  'Talovaya',
  'Talpiot',
  'Talstraße',
  'Taluyers',
  'Tama',
  'Tamagawa',
  'Tamahere',
  'Tamale',
  'Taman Melaka Baru',
  'Taman Tanjung Minyak',
  'Tamaqua',
  'Tamarac',
  'Tamarack',
  'Tamarana',
  'Tamarin',
  'Tamarindo',
  'Tamarite De Litera',
  'Tamaulipas',
  'Tamazulapan Del Progreso',
  'Tamási',
  'Tamba',
  'Tambaram',
  'Tambora',
  'Tamborine',
  'Tambov',
  'Tamengos',
  'Tamins',
  'Tamm',
  'Tampa',
  'Tampere',
  'Tampico',
  'Tampoi',
  'Tamra',
  'Tamura',
  'Tamworth',
  'Tamworth Municipality',
  'Tanabe',
  'Tanakpur',
  'Tananger',
  'Tanágra',
  'Tandang Sora',
  'Tandil',
  'Tandragee',
  'Tanem',
  'Taneytown',
  'Tanga',
  'Tangail',
  'Tangen',
  'Tangent',
  'Tanger',
  'Tangerang',
  'Tangermünde',
  'Tanggu',
  'Tangier',
  'Tangjiabang',
  'Tangmere',
  'Tangshan',
  'Tangstedt',
  'Tanjung Malim',
  'Tanjung Morawa',
  'Tanjung Priok',
  'Tanjung Tokong',
  'Tanjung Tualang',
  'Tanjungbarat',
  'Tanjungpinang',
  'Tankersley',
  'Tann',
  'Tannay',
  'Tanner',
  'Tannersville',
  'Tannhausen',
  'Tannois',
  'Tanta',
  'Tanumshede',
  'Tanunda',
  'Tanur',
  'Tanvald',
  'Tanza',
  'Taormina',
  'Taos',
  'Taounate',
  'Taoyuan',
  'Taoyüan',
  'Taoyüanhsien',
  'Tapachula',
  'Tapei',
  'Tapejara',
  'Taplow',
  'Taponas',
  'Tappahannock',
  'Tappan',
  'Taquara',
  'Taquaral',
  'Taquari',
  'Taquaritinga',
  'Tar Heel',
  'Taramani',
  'Tarancón',
  'Taranta Peligna',
  'Taranto',
  'Tarare',
  'Tarascon',
  'Tarazona',
  'Tarazona De La Mancha',
  'Tarbes',
  'Tarboro',
  'Tardeo',
  'Taree',
  'Taren Point',
  'Tarentum',
  'Targovishte',
  'Targoviste',
  'Targu Jiu',
  'Targu-frumos',
  'Targu-mures',
  'Tarifa',
  'Tariffville',
  'Tarímbaro',
  'Tarkwa',
  'Tarlac',
  'Tarm',
  'Tarmstedt',
  'Tarn Taran',
  'Tarnaveni',
  'Tarneit',
  'Tarnobrzeg',
  'Tarnovo',
  'Tarnow',
  'Tarnowo Podgórne',
  'Tarnowskie Góry',
  'Tarnów',
  'Tarp',
  'Tarpley',
  'Tarpon Springs',
  'Tarporley',
  'Tarragona',
  'Tarrant Hinton',
  'Tarrasa',
  'Tarrytown',
  'Tarsus',
  'Tartu',
  'Tarumanagara',
  'Tarumi',
  'Tarzana',
  'Tasek Gelugor',
  'Tashkent',
  'Tasikmalaya',
  'Tasman',
  'Tassin',
  'Tassin-la-demi-lune',
  'Tassullo',
  'Taszár',
  'Tata',
  'Tatabánya',
  'Tatamagouche',
  'Tatarstan',
  'Tate',
  'Tateyama',
  'Tatsuno',
  'Tattenhall',
  'Tatung',
  'Tau',
  'Taubaté',
  'Tauberbischofsheim',
  'Taucha',
  'Taufkirchen',
  'Taulignan',
  'Taunggyi',
  'Taunton',
  'Taunusstein',
  'Taupo',
  'Tauranga',
  'Tautii Margheraus',
  'Tauxigny',
  'Tavagnacco',
  'Tavannes',
  'Tavares',
  'Tavazzano',
  'Taveiro',
  'Tavel',
  'Taverne',
  'Tavernelle',
  'Tavernes',
  'Tavernier',
  'Taverny',
  'Taviano',
  'Tavistock',
  'Tavsanli',
  'Tawau',
  'Tawern',
  'Taxco De Alarcón',
  'Taxis',
  'Tayabas',
  'Taylor',
  'Taylors',
  'Taylorsville',
  'Taylorville',
  'Tayport',
  'Tayug',
  'Tazewell',
  'Tábor',
  'Táborfalva',
  'Tápszentmiklós',
  'Tárrega',
  'Távros',
  'Tân An',
  'Târgu Jiu',
  'Täbingen',
  'Täby',
  'Tägerwilen',
  'Tällberg',
  'Tänassilma',
  'Tärnaby',
  'Täuffelen',
  'Tävelsås',
  'Tårs',
  'Tåstrup',
  'Tbilisi',
  'Tblisi',
  'Tczew',
  'Te Araroa',
  'Te Rapa',
  'Tea',
  'Tea Tree',
  'Teaneck',
  'Tebas',
  'Tebet',
  'Tecamac',
  'Tecamachalco',
  'Teckomatorp',
  'Tecomatlán',
  'Tecovice',
  'Tecuci',
  'Tecumseh',
  'Teddington',
  'Teeffelen',
  'Teesside County Borough',
  'Tegelen',
  'Tegerfelden',
  'Tegernheim',
  'Tegernsee',
  'Tegucigalpa',
  'Tegueste',
  'Tehachapi',
  'Tehran',
  'Tehran Now',
  'Tehran Pars',
  'Tehri-garhwal',
  'Tehuacán',
  'Teià',
  'Teignmouth',
  'Teillé',
  'Teisendorf',
  'Teisnach',
  'Teissières-lès-bouliès',
  'Tejupilco',
  'Tekirdag',
  'Tel Aviv',
  'Tel Aviv-jaffa',
  'Tel Aviv-yafo',
  'Tel Hanan',
  'Tel Mond',
  'Telaga Timbul',
  'Telc',
  'Telde',
  'Telek',
  'Teleki',
  'Telford',
  'Telgate',
  'Telgte',
  'Telki',
  'Tell City',
  'Tellin',
  'Tellingstedt',
  'Telluride',
  'Telnice',
  'Telok Panglima Garang',
  'Telsiai',
  'Telsiu',
  'Teltow',
  'Teluk Bahang',
  'Teluk Intan',
  'Tema',
  'Temanggung',
  'Temara',
  'Tembisa',
  'Tembleque',
  'Temecula',
  'Temelin',
  'Temerloh',
  'Temora',
  'Tempaku-ku',
  'Tempe',
  'Tempelhof',
  'Temperance',
  'Temperley',
  'Tempio Pausania',
  'Temple',
  'Temple City',
  'Temple Grafton',
  'Temple Hills',
  'Templemars',
  'Templepatrick',
  'Templestowe',
  'Templestowe Lower',
  'Templeton',
  'Templin',
  'Temse',
  'Temuco',
  'Ten Boer',
  'Ten Sleep',
  'Tenafly',
  'Tenaha',
  'Tenbury Wells',
  'Tenby',
  'Tendo',
  'Tenero',
  'Tenggarong',
  'Tengzhou',
  'Tenhult',
  'Teni',
  'Teningen',
  'Tenino',
  'Tenjin',
  'Tennant Creek',
  'Tenneck',
  'Tennessee',
  'Tennessee Ridge',
  'Tennfjord',
  'Tennoji',
  'Tenosique',
  'Tenri',
  'Tenterden',
  'Tenuvot',
  'Tenyo',
  'Teo',
  'Teor',
  'Teotitlán Del Valle',
  'Tepeyanco',
  'Tepic',
  'Teplice',
  'Teplicka Nad Vahom',
  'Ter Aar',
  'Ter Apel',
  'Ter Heijde',
  'Terada',
  'Teralba',
  'Teramo',
  'Terborg',
  'Terenure',
  'Teresin',
  'Teresina',
  'Teresópolis',
  'Terezin',
  'Terheijden',
  'Terkaple',
  'Terlicko',
  'Termen',
  'Termoli',
  'Ternat',
  'Ternate',
  'Terndrup',
  'Terneuzen',
  'Terni',
  'Ternitz',
  'Ternopil',
  'Terra Alta',
  'Terra Ceia',
  'Terrace',
  'Terrace Park',
  'Terralba',
  'Terranuova Bracciolini',
  'Terrassa',
  'Terrasson',
  'Terråk',
  'Terre Haute',
  'Terrebonne',
  'Terrell',
  'Terrey Hills',
  'Terrigal',
  'Terrugem',
  'Terryville',
  'Terschuur',
  'Tertre',
  'Teruel',
  'Tervalampi',
  'Tervuren',
  'Terwispel',
  'Terwolde',
  'Terzorio',
  'Teschow',
  'Teslic',
  'Tesovice',
  'Tessenderlo',
  'Tessera',
  'Tesvikiye',
  'Tetbury',
  'Tete',
  'Teterboro',
  'Teteringen',
  'Teterow',
  'Teton Village',
  'Tetsworth',
  'Tettau',
  'Tettenhall',
  'Tettenweis',
  'Tettnang',
  'Teufen',
  'Teufenthal',
  'Teuge',
  'Teugn',
  'Teulada',
  'Teutopolis',
  'Tewantin',
  'Tewkesbury',
  'Tewksbury',
  'Texarkana',
  'Texas',
  'Texas City',
  'Texcoco',
  'Teynampet',
  'Tezpur',
  'Tezze Sul Brenta',
  'Téglás',
  'Tésa',
  'Tétouan',
  'Têsto Salto',
  'Thagoona',
  'Thal',
  'Thalang',
  'Thalassery',
  'Thale',
  'Thalfang',
  'Thalheim',
  'Thalheim Bei Wels',
  'Thalwil',
  'Thame',
  'Thames',
  'Thames Ditton',
  'Thames Haven',
  'Thana',
  'Thane',
  'Thanesar',
  'Thanh Hóa',
  'Thanjavur',
  'Thannhausen',
  'Thaon-les-vosges',
  'Thatcham',
  'Thatcher',
  'Thaxted',
  'Thayer',
  'Thayne',
  'Thayngen',
  'Thái Bình',
  'Thái Nguyên',
  'The Channon',
  'The Colony',
  'The Dalles',
  'The Hague',
  'The Junction',
  'The Plains',
  'The Rock',
  'The Rocks',
  'The Sea Ranch',
  'The Valley',
  'The Villages',
  'The Woodlands',
  'Theale',
  'Thebarton',
  'Thebes',
  'Thedinghausen',
  'Thelwall',
  'Them',
  'Theodore',
  'Theodosia',
  'Thermalbad Wiesenbad',
  'Therwil',
  'Thessalonike',
  'Thessaloníki',
  'Thetford',
  'Thetford Center',
  'Thetford Mines',
  'Theux',
  'Thélus',
  'Thénac',
  'Thérmi',
  'Thiais',
  'Thiberville',
  'Thibodaux',
  'Thief River Falls',
  'Thielle',
  'Thiells',
  'Thiene',
  'Thiensville',
  'Thierachern',
  'Thierhaupten',
  'Thiers',
  'Thierstein',
  'Thierville-sur-meuse',
  'Thiès',
  'Thiétreville',
  'Thika',
  'Thimister',
  'Thimphu',
  'Thines',
  'Thionville',
  'Thirroul',
  'Thirsk',
  'Thiruvananthapuram',
  'Thise',
  'Thisted',
  'Thiverval',
  'Thíva',
  'Thodure',
  'Thoiry',
  'Tholen',
  'Tholey',
  'Thomas',
  'Thomaston',
  'Thomastown',
  'Thomasville',
  'Thomer-la-sôgne',
  'Thomery',
  'Thompson',
  'Thompson Falls',
  'Thompsons Station',
  'Thomson',
  'Thong Lang',
  'Thonon-les-bains',
  'Thonotosassa',
  'Thoothukudi',
  'Thorhild',
  'Thorigné',
  'Thorigné-sur-dué',
  'Thorigny-sur-marne',
  'Thorigny-sur-oreuse',
  'Thorn',
  'Thornaby',
  'Thornborough',
  'Thornbury',
  'Thorncombe',
  'Thorndale',
  'Thorne',
  'Thornham',
  'Thornhill',
  'Thornleigh',
  'Thornton',
  'Thornton Heath',
  'Thornville',
  'Thornwood',
  'Thorofare',
  'Thorold',
  'Thorp Arch',
  'Thorpe Le Soken',
  'Thorsø',
  'Thouaré-sur-loire',
  'Thouars',
  'Thousand Oaks',
  'Thousand Palms',
  'Thônex',
  'Thörishaus',
  'Thrapston',
  'Three Forks',
  'Three Hills',
  'Three Lakes',
  'Three Rivers',
  'Thrissur',
  'Thuellin',
  'Thuin',
  'Thum',
  'Thun',
  'Thunder Bay',
  'Thundersley',
  'Thundorf',
  'Thundridge',
  'Thung Khru',
  'Thunstetten',
  'Thurleigh',
  'Thurles',
  'Thurmont',
  'Thurnau',
  'Thurso',
  'Thusis',
  'Thuwal',
  'Thyez',
  'Thynes',
  'Thyrnau',
  'Tiana',
  'Tianchang',
  'Tianfu',
  'Tianguá',
  'Tianhe',
  'Tianjin',
  'Tianjing',
  'Tianmen',
  'Tianmu',
  'Tianqiao',
  'Tianshui',
  'Tiantai',
  'Tianxin',
  'Tianxing',
  'Tianyi',
  'Tibaú Do Sul',
  'Tibás',
  'Tibbi',
  'Tiberias',
  'Tibro',
  'Ticehurst',
  'Ticino',
  'Tickfaw',
  'Tickhill',
  'Ticonderoga',
  'Tidaholm',
  'Tideswell',
  'Tiefenbach',
  'Tiefenbronn',
  'Tiefenort',
  'Tiegem',
  'Tiel',
  'Tieling',
  'Tielt',
  'Tienen',
  'Tienhoven',
  'Tiercelet',
  'Tierp',
  'Tietê',
  'Tietjerk',
  'Tiffin',
  'Tifrah',
  'Tifton',
  'Tigaraksa',
  'Tigard',
  'Tigerville',
  'Tigery',
  'Tigre',
  'Tihany',
  'Tijara',
  'Tijeras',
  'Tijnje',
  'Tijuana',
  'Tijuca',
  'Tijucas',
  'Tikamgarh',
  'Tikøb',
  'Tilburg',
  'Tilbury',
  'Tildonk',
  'Tilehurst',
  'Tillamook',
  'Tiller',
  'Tilleul',
  'Tillé',
  'Tillsonburg',
  'Tilst',
  'Tilton',
  'Tim',
  'Timaru',
  'Timbaúba',
  'Timberlake',
  'Timberville',
  'Timbó',
  'Timis',
  'Timisoara',
  'Timmendorfer Strand',
  'Timmins',
  'Timon',
  'Timóteo',
  'Timperley',
  'Timrat',
  'Timrå',
  'Timukan',
  'Timur',
  'Tinchebray',
  'Tincques',
  'Tincry',
  'Tindivanam',
  'Tineo',
  'Tinglev',
  'Tingsryd',
  'Tinley Park',
  'Tinlot',
  'Tinqueux',
  'Tinsley',
  'Tinsukia',
  'Tintinhull',
  'Tinuzi',
  'Tioga',
  'Tione Di Trento',
  'Tipp City',
  'Tipperary',
  'Tipton',
  'Tiptree',
  'Tiptur',
  'Tira',
  'Tirana',
  'Tiranë',
  'Tirano',
  'Tiraspol',
  'Tirat Carmel',
  'Tirat Hakarmel',
  'Tirat Karmel',
  'Tirat Yehuda',
  'Tirat Zvi',
  'Tirol',
  'Tirpersdorf',
  'Tirrenia',
  'Tirschenreuth',
  'Tiruchchirappalli',
  'Tiruchengodu',
  'Tiruchirappalli',
  'Tirumala',
  'Tirunelveli',
  'Tirupati',
  'Tiruppur',
  'Tirupur',
  'Tirurangadi',
  'Tiruvalla',
  'Tiruvallur',
  'Tiruvananantapuram',
  'Tiruvannamalai',
  'Tirva',
  'Tisa',
  'Tishomingo',
  'Tisice',
  'Tisnov',
  'Tiste',
  'Tistedal',
  'Tisvildeleje',
  'Tiszafüred',
  'Tiszakeszi',
  'Tiszakécske',
  'Tiszatardos',
  'Tiszaújváros',
  'Tiszavasvári',
  'Titchfield',
  'Titirangi',
  'Titisee-neustadt',
  'Tito',
  'Tittling',
  'Tittmoning',
  'Titusville',
  'Tiukka',
  'Tiverton',
  'Tivoli',
  'Tizayuca',
  'Tizi Ouzou',
  'Tizimín',
  'Tizzano Val Parma',
  'Tías',
  'Tjøme',
  'Tlahuac',
  'Tlajomulco De Zúñiga',
  'Tlalnepantla',
  'Tlalpan',
  'Tlaquepaque',
  'Tlatilco',
  'Tlaxcala',
  'Tlaxcala De Xicohténcatl',
  'Tlaxcalancingo',
  'Tlaxiaco',
  'Tlmace',
  'Toamasina',
  'Toano',
  'Tobata',
  'Tobersbach',
  'Toccoa',
  'Toccoa Falls',
  'Tochigi',
  'Tocna',
  'Toda',
  'Todd',
  'Toddington',
  'Todesfelde',
  'Todi',
  'Todmorden',
  'Todtnau',
  'Toffen',
  'Tofield',
  'Toft',
  'Tofte',
  'Togawa',
  'Togher',
  'Togliatti',
  'Toho',
  'Toivala',
  'Tojal',
  'Tokai',
  'Toki',
  'Tokio',
  'Tokoname',
  'Tokoro',
  'Tokoroa',
  'Tokorozawa',
  'Tokuda',
  'Tokushima',
  'Tokushima-shi',
  'Tokuyama',
  'Tokyo',
  'Tolbert',
  'Tolcayuca',
  'Toldijk',
  'Toledo',
  'Tolentino',
  'Toliara',
  'Tolima',
  'Tolland',
  'Tollarp',
  'Tollebeek',
  'Tollered',
  'Tolleson',
  'Tolmezzo',
  'Tolmin',
  'Tolna',
  'Tolono',
  'Tolosa',
  'Toluca',
  'Toluca De Lerdo',
  'Toluca Lake',
  'Tolyatti',
  'Tomah',
  'Tomahawk',
  'Tomakomai',
  'Tomar',
  'Tomares',
  'Tomasjord',
  'Tomaszów Mazowiecki',
  'Tomatin',
  'Tomball',
  'Tomblaine',
  'Tome',
  'Tomelilla',
  'Tomelloso',
  'Tomi',
  'Tomigusuku',
  'Tomils',
  'Tomiño',
  'Tomioka',
  'Tomis Nord',
  'Tomisato',
  'Tomiya',
  'Tommerup',
  'Tommerup Stationsby',
  'Tompkinsville',
  'Toms River',
  'Tomsk',
  'Tona',
  'Tonami',
  'Tonawanda',
  'Tonbridge',
  'Tondabayashi',
  'Tondela',
  'Tongala',
  'Tonganoxie',
  'Tongcheng',
  'Tongduchon',
  'Tongeren',
  'Tongfa',
  'Tonghua',
  'Tongli',
  'Tongliang',
  'Tongliao',
  'Tongling',
  'Tonglu',
  'Tongnae',
  'Tongren',
  'Tongsheng',
  'Tongxiang',
  'Tongzhou',
  'Tonk',
  'Tonkawa',
  'Tonneins',
  'Tonopah',
  'Tonosho',
  'Tonstad',
  'Tontitown',
  'Tonypandy',
  'Tooele',
  'Toone',
  'Toongabbie',
  'Toorak',
  'Toowong',
  'Toowoomba',
  'Topanga',
  'Topcliffe',
  'Topeka',
  'Topi',
  'Topkapi',
  'Toplita',
  'Topolcany',
  'Toppenish',
  'Toppesfield',
  'Topsfield',
  'Topsham',
  'Topton',
  'Toranomon',
  'Torbay',
  'Torbole',
  'Torchefelon',
  'Torcy',
  'Tordas',
  'Tordera',
  'Torekov',
  'Torelló',
  'Torgau',
  'Torhout',
  'Toride',
  'Torino',
  'Torksey',
  'Tornado',
  'Tornes I Romsdal',
  'Tornesch',
  'Tornio',
  'Toro',
  'Torola',
  'Toronto',
  'Tororo',
  'Torp',
  'Torpo',
  'Torpoint',
  'Torpshammar',
  'Torquay',
  'Torrance',
  'Torre',
  "Torre D'isola",
  'Torre De Vilela',
  'Torre Del Campo',
  'Torre Del Español',
  'Torredembarra',
  'Torredonjimeno',
  'Torrefarrera',
  'Torreglia',
  'Torrejon De Ardos',
  'Torrejón De Ardoz',
  'Torrejón De La Calzada',
  'Torrejón de Ardoz',
  'Torrelavega',
  'Torrelodones',
  'Torremanzanas',
  'Torremolinos',
  'Torremolinos-málaga',
  'Torrenova',
  'Torrent',
  'Torrente',
  'Torreón',
  'Torreperogil',
  'Torres De La Alameda',
  'Torres Novas',
  'Torres Vedras',
  'Torrevecchia Teatina',
  'Torrevieja',
  'Torri Di Quartesolo',
  'Torrijos',
  'Torrington',
  'Torrinha',
  'Torrita Di Siena',
  'Torsås',
  'Torsby',
  'Torshälla',
  'Torslanda',
  'Tortellá',
  'Tortoli',
  'Torton',
  'Tortona',
  'Tortosa',
  'Tortozendo',
  'Tortuguitas',
  'Torun',
  'Torun Polnocny',
  'Torup',
  'Torvela-joensuu',
  'Torzhok',
  'Torzym',
  'Tosabori',
  'Toscano',
  'Toscolano',
  'Toscolano Maderno',
  'Toshima',
  'Tossa',
  'Tostado',
  'Tostedt',
  'Tosu',
  'Totana',
  'Totanés',
  'Totnes',
  'Totowa',
  'Totsuka',
  'Tottenham',
  'Totteridge',
  'Tottington',
  'Totton',
  'Tottori',
  'Toucy',
  'Toufen',
  'Tougaloo',
  'Toul',
  'Toulaud',
  'Toulenne',
  'Toulon',
  'Toulouse',
  'Tourcoing',
  'Tourlaville',
  'Tournai',
  'Tournan-en-brie',
  'Tournedos-bois-hubert',
  'Tournefeuille',
  'Tournes',
  'Tournon',
  'Tournus',
  'Touro',
  'Tourrette',
  'Tours',
  'Tours-en-savoie',
  'Tours-en-vimeu',
  'Tours-sur-marne',
  'Toussieu',
  'Toussus-le-noble',
  'Toutle',
  'Touzim',
  'Tovarníky',
  'Towa',
  'Towaco',
  'Towada',
  'Towanda',
  'Towcester',
  'Tower',
  'Tower City',
  'Town And Country',
  'Townhill',
  'Townsend',
  'Townshend',
  'Township Of Washington',
  'Townsville',
  'Towson',
  'Towyn',
  'Toyama',
  'Toyama-shi',
  'Toyoake',
  'Toyohashi',
  'Toyohira',
  'Toyokawa',
  'Toyonaka',
  'Toyono',
  'Toyooka',
  'Toyoshina',
  'Toyosu',
  'Toyota',
  'Toyotomi',
  'Tórshavn',
  'Tórtola',
  'Tôrre',
  'Tôrres',
  'Tôtes',
  'Töcksfors',
  'Tönisvorst',
  'Tönning',
  'Töreboda',
  'Törökbálint',
  'Tötensen',
  'Traben-trarbach',
  'Traboch',
  'Trabuco Canyon',
  'Trabzon',
  'Tracy',
  'Tradate',
  'Trafalgar',
  'Trafford',
  'Trafford Park',
  'Trahegnies',
  'Trail',
  'Trainel',
  'Traisa',
  'Traiskirchen',
  'Traismauer',
  'Traitsching',
  'Trajouce',
  'Tralee',
  'Tramandaí',
  'Tramelan',
  'Tranås',
  'Tranbjerg',
  'Tranby',
  'Trancoso',
  'Tranemo',
  'Tranent',
  'Trang',
  'Trani',
  'Tranquility',
  'Trans-en-provence',
  'Transinne',
  'Trapani',
  'Trappenkamp',
  'Trappes',
  'Traralgon',
  'Trat',
  'Traun',
  'Traunfeld',
  'Traunreut',
  'Traunstein',
  'Trausdorf',
  'Trautenstein',
  'Travagliato',
  'Travasso',
  'Travelers Rest',
  'Traver',
  'Traverse',
  'Traverse City',
  'Trazegnies',
  'Träslövsläge',
  'Trångsund',
  'Trbovlje',
  'Treasure Beach',
  'Trebarov',
  'Trebaseleghe',
  'Trebatice',
  'Trebbin',
  'Trebelovice',
  'Trebgast',
  'Trebic',
  'Trebinje',
  'Trebisacce',
  'Trebisov',
  'Trebnje',
  'Trebon',
  'Trebonice',
  'Treboradice',
  'Trebovice',
  'Trebur',
  'Trecastagni',
  'Trecate',
  'Trechtingshausen',
  'Tredegar',
  'Treeton',
  'Treffort',
  'Treforest',
  'Tregaron',
  'Treignac',
  'Treillières',
  'Trelins',
  'Trelleborg',
  'Tremañes',
  'Tremblay',
  'Tremelo',
  'Tremont',
  'Tremonton',
  'Tremosna',
  'Tremosnice',
  'Trempealeau',
  'Tremsbüttel',
  'Trench',
  'Trencianska Turna',
  'Trencin',
  'Trendelburg',
  'Trenggalek',
  'Trent',
  'Trentham',
  'Trento',
  'Trenton',
  'Treorchy',
  'Tres Alquerías',
  'Tres Piedras',
  'Tres Pinos',
  'Trescore Balneario',
  'Tresses',
  'Trest',
  'Trets',
  'Treuchtlingen',
  'Treuen',
  'Treuenbrietzen',
  'Trevélez',
  'Treviglio',
  'Trevignano',
  'Treviso',
  'Trevor',
  'Trevões',
  'Trexlertown',
  'Trezzano Rosa',
  'Trezzano Sul Naviglio',
  'Trèves',
  'Trébeurden',
  'Trédarzec',
  'Tréflévenez',
  'Tréguex',
  'Tréguidel',
  'Trélazé',
  'Trélex',
  'Trélissac',
  'Trémuson',
  'Trévoux',
  'Três Corações',
  'Três De Maio',
  'Três Figos De Baixo',
  'Três Marias',
  'Três Pontas',
  'Três Rios',
  'Trhovy Stepanov',
  'Triadelphia',
  'Triangle',
  'Triberg',
  'Tricase',
  'Tricht',
  'Trichur',
  'Trichy',
  'Trie-château',
  'Triengen',
  'Trier',
  'Trierweiler',
  'Triesen',
  'Trieste',
  'Trige',
  'Triggiano',
  'Trigueros',
  'Trilport',
  'Trimbach',
  'Trimbak',
  'Trimont',
  'Trimstein',
  'Trimulgherry',
  'Trincomalee',
  'Trindade',
  'Trinec',
  'Tring',
  'Trinidad',
  'Trinita',
  'Trinity',
  'Trino',
  'Triplicane',
  'Tripoli',
  'Triptis',
  'Tripunittura',
  'Trissino',
  'Trittau',
  'Trivandrum',
  'Trivero',
  'Tríkala',
  'Trípolis',
  'Trmice',
  'Trn',
  'Trnava',
  'Trnova',
  'Trnovo',
  'Trnovo Pri Gorici',
  'Trnovska Vas',
  'Trobajo Del Camino',
  'Trobajo Del Cerecedo',
  'Trofa',
  'Trofarello',
  'Trogen',
  'Trogir',
  'Trois-rivières',
  'Troisdorf',
  'Troissereux',
  'Troistorrents',
  'Troisvierges',
  'Troitsk',
  'Troja',
  'Trolla',
  'Trollhättan',
  'Tromsdalen',
  'Tromsø',
  'Trondheim',
  'Trondhjem',
  'Tronoh',
  'Troon',
  'Trophy Club',
  'Trosa',
  'Trossingen',
  'Trostberg',
  'Trostyanets',
  'Trou Aux Biches',
  'Troubsko',
  'Trout Lake',
  'Troutdale',
  'Troutman',
  'Troutville',
  'Trouy',
  'Trowbridge',
  'Troy',
  'Troy Mills',
  'Troyes',
  'Trstená',
  'Trubbach',
  'Trubia',
  'Truccazzano',
  'Truckee',
  'Truganina',
  'Trujillo',
  'Trujillo Alto',
  'Trull',
  'Truman',
  'Trumann',
  'Trumansburg',
  'Trumbauersville',
  'Trumbull',
  'Truro',
  'Trusham',
  'Truskavets',
  'Trussville',
  'Truth Or Consequences',
  'Trutnov',
  'Trübbach',
  'Trysil',
  'Trzebinia',
  'Trzic',
  'Trzin',
  'Tsaile',
  'Tschiertschen',
  'Tsipori',
  'Tsu',
  'Tsubame',
  'Tsuchiura',
  'Tsuen Wan',
  'Tsukiji',
  'Tsukishima',
  'Tsukuba',
  'Tsukumi',
  'Tsumeb',
  'Tsur Moshe',
  'Tsuruga',
  'Tsurugashima',
  'Tsurumi',
  'Tsuruoka',
  'Tsushima',
  'Tsuyama',
  'Tsvetnoy',
  'Tualatin',
  'Tuam',
  'Tuamgraney',
  'Tuba City',
  'Tuban',
  'Tubarão',
  'Tubber',
  'Tubbergen',
  'Tubize',
  'Tuchenbach',
  'Tucheng',
  'Tuchlovice',
  'Tuchola',
  'Tuckahoe',
  'Tucker',
  'Tuckerton',
  'Tucson',
  'Tucumán',
  'Tucumcari',
  'Tudela',
  'Tuen Mun',
  'Tuggen',
  'Tuggerah',
  'Tuguegarao',
  'Tugun',
  'Tuil',
  'Tujunga',
  'Tuk',
  'Tukwila',
  'Tula',
  'Tulancingo',
  'Tulangan',
  'Tulare',
  'Tularosa',
  'Tulcea',
  'Tullahoma',
  'Tullamarine',
  'Tullamore',
  'Tulle',
  'Tullinge',
  'Tullins',
  'Tulln',
  'Tullnerbach-lawies',
  'Tullow',
  'Tully',
  'Tulsa',
  'Tultitlán',
  'Tuluá',
  'Tulum',
  'Tulungagung',
  'Tumba',
  'Tumbes',
  'Tumbi Vmbi',
  'Tumkur',
  'Tumwater',
  'Tuna',
  'Tunapuna',
  'Tunbridge Wells',
  'Tunceli',
  'Tuncurry',
  'Tundla',
  'Tune',
  'Tunechody',
  'Tungi',
  'Tunica',
  'Tuningen',
  'Tunis',
  'Tunisi',
  'Tunja',
  'Tunkhannock',
  'Tunnel Hill',
  'Tuntenhausen',
  'Tuolumne',
  'Tupã',
  'Tupelo',
  'Tupesy',
  'Tupper Lake',
  'Tura',
  'Turbenthal',
  'Turcianske Teplice',
  'Turckheim',
  'Turda',
  'Turdera',
  'Turek',
  'Turenki',
  'Turgi',
  'Turgutlu',
  'Turia',
  'Turin',
  'Turiz',
  'Turka',
  'Turkey',
  'Turkije',
  'Turku',
  'Turlock',
  'Turnbridge Wells',
  'Turner',
  'Turners Falls',
  'Turners Flat',
  'Turnhout',
  'Turnov',
  'Turpan',
  'Turramurra',
  'Turriff',
  'Turtle Lake',
  'Turtmann',
  'Tuscaloosa',
  'Tuscola',
  'Tuscumbia',
  'Tushiya',
  'Tuskegee',
  'Tuskegee Institute',
  'Tussenhausen',
  'Tustin',
  'Tuticorin',
  'Tuttlingen',
  'Tutzing',
  'Tuuri',
  'Tuusula',
  'Tuxedo Park',
  'Tuxford',
  'Tuxtepec',
  'Tuxtla',
  'Tuxtla Gutierres',
  'Tuxtla Gutiérrez',
  'Tuy Hòa',
  'Tuzla',
  'Tübingen',
  'Türi',
  'Türisalu',
  'Türkelli',
  'Türkenfeld',
  'Türkheim',
  'Tüßling',
  'Tvarozna',
  'Tvååker',
  'Tvedestrand',
  'Tveit',
  'Tver',
  'Tverskaya',
  'Tvis',
  'Tvrdosin',
  'Twain Harte',
  'Tweed',
  'Tweed Heads',
  'Twello',
  'Twerton',
  'Twickenham',
  'Twijzelerheide',
  'Twin Bridges',
  'Twin Falls',
  'Twineham',
  'Twinsburg',
  'Twisp',
  'Twist',
  'Twistringen',
  'Two Harbors',
  'Two Rivers',
  'Twycross',
  'Twyford',
  'Tyabb',
  'Tybee Island',
  'Tychy',
  'Tygelsjö',
  'Tyldesley',
  'Tyler',
  'Tylertown',
  'Tylicz',
  'Tynaarlo',
  'Tyndall',
  'Tyne Valley',
  'Tynec Nad Sazavou',
  'Tynemouth',
  'Tyngsboro',
  'Tynset',
  'Tyresö',
  'Tyringe',
  'Tyrnävä',
  'Tyrone',
  'Tyseley',
  'Tysmenytsya',
  'Tysnes',
  'Tystberga',
  'Tyumen',
  'Tywardreath',
  'Tølløse',
  'Tønder',
  'Tønsberg',
  'Tørring',
  'U S A F Academy',
  'Uauá',
  'Ubagai',
  'Ubatuba',
  'Ubá',
  'Ube',
  'Uberaba',
  'Uberach',
  'Uberlândia',
  'Ubstadt-weiher',
  'Ubud',
  'Uccle',
  'Uchisaiwaicho',
  'Uckange',
  'Uckfield',
  'Udaipur',
  'Udaipur City',
  'Udall',
  'Udamalpet',
  'Uddevalla',
  'Uddingston',
  'Uden',
  'Udenhout',
  'Uder',
  'Udhampur',
  'Udim',
  'Udine',
  'Udipi',
  'Udligenswil',
  'Udon Thani',
  'Udupi',
  'Ueberstorf',
  'Ueckermünde',
  'Ueda',
  'Uedem',
  'Uehlfeld',
  'Uelzen',
  'Ueno',
  'Uenohara',
  'Uerikon',
  'Uesslingen',
  'Uetendorf',
  'Uetersen',
  'Uetikon',
  'Uetikon Am See',
  'Uetze',
  'Ufa',
  'Uffelte',
  'Uffenheim',
  'Uffikon',
  'Uffing',
  'Uffington',
  'Ugchelen',
  'Ugento',
  'Uggiate',
  'Uherske Hradiste',
  'Uhersky Brod',
  'Uhersky Ostroh',
  'Uhingen',
  'Uhldingen-mühlhofen',
  'Uhlwiller',
  'Uhrichsville',
  'Uhrineves',
  'Uhwiesen',
  'Uig',
  'Uijongbu',
  'Uitenhage',
  'Uitgeest',
  'Uithoorn',
  'Uithuizermeeden',
  'Uitikon',
  'Uiwang',
  'Ujezd Nad Lesy',
  'Ujezdziec Maly',
  'Uji',
  'Ujjain',
  'Ujsoly',
  'Ukhta',
  'Uki',
  'Ukiah',
  'Ukkel',
  'Ukmerge',
  'Ukrainka',
  'Ulaanbaatar',
  'Ulan Bator',
  'Ulan-ude',
  'Ulbroka',
  'Ulceby',
  'Uldum',
  'Ulefoss',
  'Ulft',
  'Ulhasnagar',
  'Ulice',
  'Ulladulla',
  'Ullared',
  'Ulldecona',
  'Ullenhall',
  'Ullerslev',
  'Ullin',
  'Ullo',
  'Ulm',
  'Ulmen',
  'Ulricehamn',
  'Ulrichstein',
  'Ulsan',
  'Ulsteinvik',
  'Ulster',
  'Ulster Park',
  'Ulstrup',
  'Ultimo',
  'Ulu Tiram',
  'Ulus',
  'Ulvenhout',
  'Ulverston',
  'Ulvik',
  'Ulvila',
  'Ulyanovsk',
  'Umag',
  'Uman',
  'Umargam',
  'Umarkhed',
  'Umatilla',
  'Umán',
  'Umbertide',
  'Umeå',
  'Umeda',
  'Umemachi',
  'Umi',
  'Umina',
  'Umkirch',
  'Umm Al Qaiwain',
  'Umm Al Qawain',
  'Ummendorf',
  'Umpqua',
  'Umraniye',
  'Umuahia',
  'Umuarama',
  'Umudike',
  'Una',
  'Unadilla',
  'Unanderra',
  'Unawatuna',
  'Undenäs',
  'Underhill',
  'Underhill Center',
  'Undersåker',
  'Underwood',
  'Unetice',
  'Ungaran',
  'Unhos',
  'Unhost',
  'Unicov',
  'Unieux',
  'Unin',
  'Union',
  'Union Bridge',
  'Union Center',
  'Union City',
  'Union Grove',
  'Union Hall',
  'Union Lake',
  'Union Mills',
  'Union Pier',
  'Union Star',
  'Uniondale',
  'Uniontown',
  'Unionville',
  'Unionville Center',
  'United',
  'Unity',
  'Universal',
  'Universal City',
  'University',
  'University Center',
  'University City',
  'University Park',
  'University Place',
  'Unjha',
  'Unju',
  'Unkovice',
  'Unley',
  'Unna',
  'Unnao',
  'Unstone',
  'Unter Illnau',
  'Unterach',
  'Unterageri',
  'Unteräegeri',
  'Unterägeri',
  'Unterbreizbach',
  'Untereisesheim',
  'Unterengstringen',
  'Unterensingen',
  'Unterföhring',
  'Untergriesbach',
  'Untergruppenbach',
  'Unterhaching',
  'Unterkirnach',
  'Unterkulm',
  'Unterlunkhofen',
  'Untermaßfeld',
  'Untermeitingen',
  'Unterneukirchen',
  'Unterrammingen',
  'Unterschleißheim',
  'Unterseen',
  'Untersiemau',
  'Untersiggenthal',
  'Unterthingau',
  'Untertürkheim',
  'Unterweissenbach',
  'Unterwellenborn',
  'Uonuma',
  'Uozu',
  'Upahl',
  'Uphall',
  'Upice',
  'Upland',
  'Uplands',
  'Upminster',
  'Upper Beeding',
  'Upper Black Eddy',
  'Upper Coomera',
  'Upper Darby',
  'Upper Heyford',
  'Upper Holloway',
  'Upper Lake',
  'Upper Marlboro',
  'Upper Poppleton',
  'Upper Saddle River',
  'Upper Sandusky',
  'Upperco',
  'Upperville',
  'Upphärad',
  'Uppingham',
  'Uppland',
  'Upplands-väsby',
  'Uppsala',
  'Upsala',
  'Upton',
  'Upton Upon Severn',
  'Uralskaya',
  'Urasoe',
  'Urawa',
  'Urayasu',
  'Urbach',
  'Urbana',
  'Urbandale',
  'Urbania',
  'Urbanización Rodazul',
  'Urbanna',
  'Urbar',
  'Urbino',
  'Urda',
  'Urdorf',
  'Urdúliz',
  'Ureshino',
  'Ureterp',
  'Urexweiler',
  'Urfahr',
  'Urgnano',
  'Urk',
  'Urla',
  'Urlati',
  'Urloffen',
  'Urmia',
  'Urmitz',
  'Urmond',
  'Urmston',
  'Urnieta',
  'Urrugne',
  'Ursa',
  'Ursem',
  'Ursenbach',
  'Ursulo Galván',
  'Ursviken',
  'Urtenen',
  'Uruapan',
  'Urueña',
  'Uruguaiana',
  'Uruma',
  'Urumchi',
  'Urup',
  'Ury',
  'Urzut',
  'Urzy',
  'Us',
  'Usa River',
  'Usak',
  'Usaquén',
  'User',
  'Ushiku',
  'Ushuaia',
  'Usingen',
  'Usk',
  'Uslar',
  'Usmate Velate',
  'Usme',
  'Usquert',
  'Ussel',
  'Ussuriysk',
  'Uster',
  'Usti',
  'Usti Nad Labem',
  'Usti Nad Orlici',
  'Usuki',
  'Usúrbil',
  'Utah',
  'Utara',
  'Utebo',
  'Utena',
  'Uthlede',
  'Uthwerdum',
  'Utica',
  'Utiel',
  'Utö',
  'Utrecht',
  'Utrera',
  'Utsunomiya',
  'Utsunomiya-shi',
  'Uttarkashi',
  'Uttarpara',
  'Uttendorf',
  'Uttenreuth',
  'Utting',
  'Utting Am Ammersee',
  'Uttoxeter',
  'Uttran',
  'Uttwil',
  'Utzenstorf',
  'Uusikaarlepyy',
  'Uusikaupunki',
  'Uusimaa',
  'Uvalde',
  'Uvaly',
  'Uwajima',
  'Uxbridge',
  'Uyo',
  'Uzerche',
  'Uzeste',
  'Uzès',
  'Uzhgorod',
  'Uzhhorod',
  'Uzice',
  'Uzliedziai',
  'Uznach',
  'Uzwil',
  'Uzzano',
  'Úbeda',
  'Újhartyán',
  'Újlengyel',
  'Újudvar',
  'Ústí Nad Orlicí',
  'Übach-palenberg',
  'Überherrn',
  'Überlingen',
  'Übersee',
  'Übrigshausen',
  'Üröm',
  'Ürümqi',
  'Ürümqi Shi',
  'Ürzig',
  'Üsküdar',
  'Vaajakoski',
  'Vaals',
  'Vaasa',
  'Vaassen',
  'Vabres',
  'Vacallo',
  'Vacaville',
  'Vace',
  'Vacha',
  'Vachendorf',
  'Vaciamadrid',
  'Vaclavovice',
  'Vacoas',
  'Vadheim',
  'Vado Ligure',
  'Vadodara',
  'Vadodra',
  'Vadonville',
  'Vadstena',
  'Vadum',
  'Vaduz',
  'Vaggeryd',
  'Vagli Sotto',
  'Vagos',
  'Vahi',
  'Vahr',
  'Vaiano',
  'Vaiano Cremasco',
  'Vaida',
  'Vaihingen',
  'Vail',
  'Vailhauquès',
  'Vaivre-et-montoille',
  "Val-d'isere",
  "Val-d'or",
  'Val-david',
  'Val-de-la-haye',
  'Val-de-roulans',
  'Valasske Klobouky',
  'Valasske Mezirici',
  'Valatie',
  'Valauris',
  'Valbom',
  'Valbonne',
  'Valby',
  'Valcea',
  'Valcourt',
  'Valdagno',
  'Valdefresno',
  'Valdelafuente',
  'Valdemarsvik',
  'Valdemorillo',
  'Valdemoro',
  'Valdeolmos',
  'Valdeorras',
  'Valdepeñas',
  'Valdepiélagos',
  'Valderrobres',
  'Valdese',
  'Valdez',
  'Valdés',
  'Valdilecha',
  'Valdivia',
  'Valdobbiadene',
  'Valdosta',
  'Valduggia',
  'Vale De Cambra',
  'Vale De Prados',
  'Vale De Santarém',
  'Vale Do Paraíso',
  'Valea Lupului',
  'Valeggio Sul Mincio',
  'Valence',
  'Valencia',
  'Valencia De Alcántara',
  'Valencia De Don Juan',
  'Valencia Del Ventoso',
  'Valenciennes',
  'Valencina De La Concepción',
  'Valença',
  'Valentine',
  'Valenton',
  'Valenza',
  'Valenzano',
  'Valenzuela',
  'Valette',
  'Valframbert',
  'Valgunde',
  'Valhalla',
  'Valhermoso Springs',
  'Valinhos',
  'Valjevo',
  'Valkeakoski',
  'Valkenburg',
  'Valkenswaard',
  "Vall D'alba",
  'Vallabh Vidyanagar',
  'Valladolid',
  'Vallans',
  'Vallarsa',
  'Vallauris',
  'Vallåkra',
  'Vallbona',
  'Vallda',
  'Valldemosa',
  'Valldoreix',
  'Valle Crucis',
  'Valle De Guadalupe',
  'Valle De Mena',
  'Valle De Santiago',
  'Vallecas',
  'Valledupar',
  'Vallejo',
  'Vallendar',
  'Vallensbæk',
  'Vallentuna',
  'Valleroy',
  'Valles',
  'Vallet',
  'Valletta',
  'Valley',
  'Valley Center',
  'Valley City',
  'Valley Cottage',
  'Valley Ford',
  'Valley Forge',
  'Valley Heights',
  'Valley Mills',
  'Valley Park',
  'Valley Springs',
  'Valley Stream',
  'Valley View',
  'Valley Village',
  'Valleyfield',
  'Valleyford',
  'Vallée-jonction',
  'Vallières-les-grandes',
  'Vallo Della Lucania',
  'Vallorbe',
  'Vallromanas',
  'Valls',
  'Vallvik',
  'Valmadrera',
  'Valmestroff',
  'Valmiera',
  'Valmojado',
  'Valmondois',
  'Valmy',
  'Valognes',
  'Valongo',
  'Valparai',
  'Valparaiso',
  'Valparaíso',
  'Valréas',
  'Valrico',
  'Valsad',
  'Valthermond',
  'Valtice',
  'Valtierra',
  'Valvasone',
  'Valverde',
  'Valverde De La Virgen',
  'Valverde De Leganés',
  'Vamberk',
  'Vamdrup',
  'Vammala',
  'Van',
  'Van Buren',
  'Van Etten',
  'Van Horn',
  'Van Nuys',
  'Van Wert',
  'Vananda',
  'Vanceburg',
  'Vancouver',
  'Vandalia',
  'Vandalur',
  'Vandellós',
  'Vanderbijlpark',
  'Vandergrift',
  'Vanderhoof',
  'Vandoeuvre-lès-nancy',
  'Vange',
  'Vanhalinna',
  'Vanier',
  'Vankiva',
  'Vankleek Hill',
  'Vanløse',
  'Vannes',
  'Vansant',
  'Vansbro',
  'Vantaa',
  'Vanves',
  'Vanvik',
  'Vanxains',
  'Vanzago',
  'Vapi',
  "Vaprio D'adda",
  'Vara',
  'Varades',
  'Varallo Pombia',
  'Varanasi',
  'Varano',
  "Varano De' Melegari",
  'Varazdin',
  'Varazze',
  'Varberg',
  'Varde',
  'Varea',
  'Varedo',
  'Varekil',
  'Varel',
  'Varena',
  'Varennes',
  'Varennes-le-grand',
  'Varese',
  'Vargem Grande Do Sul',
  'Vargem Grande Paulista',
  'Varginha',
  'Varik',
  'Varkaus',
  'Varmo',
  'Varna',
  'Varnsdorf',
  'Varshavskiy',
  'Varsseveld',
  'Vasai',
  'Vasco Da Gama',
  'Vascon',
  'Vashi',
  'Vashon',
  'Vasiliko',
  'Vasilkov',
  'Vaslui',
  'Vass',
  'Vassar',
  'Vasselay',
  'Vasto',
  'Vasvár',
  'Vasylkiv',
  'Vatan',
  'Vaterstetten',
  'Vatne',
  'Vatteville-la-rue',
  'Vatutine',
  'Vaucluse',
  'Vaucresson',
  'Vaud',
  'Vauderens',
  'Vaudreuil-dorion',
  'Vaughan',
  'Vaughn',
  'Vaugneray',
  'Vauhallan',
  'Vaulion',
  'Vaulx-en-velin',
  'Vaulx-lez-tournai',
  'Vaulx-milieu',
  'Vauvert',
  'Vaux-le-pénil',
  'Vaux-sous-aubigny',
  'Vaux-sous-chèvremont',
  'Vaux-sur-mer',
  'Vaux-sur-seine',
  'Vauxhall',
  'Vavilova',
  'Vavuniya',
  'Vaxholm',
  'Vayrac',
  'Vazhakkad',
  'Vazzola',
  'Vác',
  'Vári',
  'Várzea',
  'Várzea Grande',
  'Várzea Paulista',
  'Vä',
  'Väckelsång',
  'Väderstad',
  'Vähäkyrö',
  'Väja',
  'Vällingby',
  'Vänersborg',
  'Vännäs',
  'Vännäsby',
  'Värmdö',
  'Värmland',
  'Värnamo',
  'Värsås',
  'Väsby',
  'Västerås',
  'Västerhaninge',
  'Västervik',
  'Västra',
  'Västra Frölunda',
  'Västra Karup',
  'Västra Tunhem',
  'Växbo',
  'Växjö',
  'Våga',
  'Vårby',
  'Vårgårda',
  'Våxtorp',
  'Vcelakov',
  'Vcelna',
  'Veauche',
  'Veauchette',
  'Veberöd',
  'Vecchiano',
  'Vechelde',
  'Vechta',
  'Vecsés',
  'Vedavågen',
  'Vedbæk',
  'Veddige',
  'Vedelago',
  'Vedum',
  'Veen',
  'Veendam',
  'Veenendaal',
  'Veenwouden',
  'Veessen',
  'Vega',
  'Vegadeo',
  'Vegas',
  'Veggiano',
  'Veghel',
  'Vegreville',
  'Veiga',
  'Veikars',
  'Veitsbronn',
  'Veitshöchheim',
  'Vejby',
  'Vejbystrand',
  'Vejen',
  'Vejer De La Frontera',
  'Vejle',
  'Veksø',
  'Vela Luka',
  'Velaine-en-haye',
  'Veland',
  'Velarde',
  'Velaux',
  'Velbert',
  'Velddriel',
  'Velden',
  'Velden Am Worthersee',
  'Veldhausen',
  'Veldhoven',
  'Velen',
  'Velenje',
  'Veles',
  'Velesin',
  'Veleslavin',
  'Velestínon',
  'Velichovky',
  'Velika Gorica',
  'Velika Mlaka',
  'Velika Nedelja',
  'Velike Brusnice',
  'Veliki Gaber',
  'Veliki Grdevac',
  'Velikiye Luki',
  'Veliko',
  'Veliko Trgovisce',
  'Velilla De San Antonio',
  'Velingrad',
  'Velka Bites',
  'Velka Bystrice',
  'Velka Hledsebe',
  'Velka Jesenice',
  'Velka Lehota',
  'Velka Lomnica',
  'Velka Nad Velickou',
  'Velke Bilovice',
  'Velke Heraltice',
  'Velke Mezirici',
  'Velke Porici',
  'Velke Prilepy',
  'Velky Kamenec',
  'Velky Krtis',
  'Velky Lapas',
  'Velky Trebesov',
  'Vellberg',
  'Velleron',
  'Velletri',
  'Vellinge',
  'Vellmar',
  'Vellore',
  'Velm',
  'Velma',
  'Velp',
  'Velsen',
  'Velsen-noord',
  'Velten',
  'Veltheim',
  'Velturno',
  'Velvary',
  'Vemb',
  'Vemmelev',
  'Venango',
  'Venansault',
  'Venaria Reale',
  'Venasque',
  'Venda Do Pinheiro',
  'Venda Nova',
  'Vendargues',
  'Vendegies',
  'Vendelsö',
  'Vendenheim',
  'Vendeville',
  'Vendin-le-vieil',
  'Vendôme',
  'Vendres',
  'Venegono',
  'Venegono Superiore',
  'Venelles',
  'Venetia',
  'Veneto',
  'Venette',
  'Venezia',
  'Venhorst',
  'Venice',
  'Venlo',
  'Vennesla',
  'Venningen',
  'Venon',
  'Venray',
  'Venta De Baños',
  'Ventnor',
  'Ventnor City',
  'Ventspils',
  'Ventura',
  'Venturina',
  'Venus',
  'Venustiano Carranza',
  'Vera',
  'Veracruz',
  'Veracruz Llave',
  'Veradale',
  'Verano',
  'Veranópolis',
  'Veraval',
  'Verbank',
  'Verberie',
  'Verbier',
  'Vercelli',
  'Verchères',
  'Vercorin',
  'Verdalsøra',
  'Verdello',
  'Verdelot',
  'Verden',
  'Verdets',
  'Verdi',
  'Verdun',
  'Vereeniging',
  'Veresegyház',
  'Vergara',
  'Vergennes',
  'Verges',
  'Vergt',
  'Veria',
  'Verkhnyaya Pyshma',
  'Verl',
  'Verlaine',
  'Vermilion',
  'Vermilion Bay',
  'Vermillion',
  'Vermont',
  'Vern-sur-seiche',
  'Verna',
  'Vernal',
  'Verneuil-en-halatte',
  'Verneuil-sur-avre',
  'Verneuil-sur-seine',
  'Vernham Dean',
  'Vernier',
  'Verniolle',
  'Vernioz',
  'Vernole',
  'Vernon',
  'Vernon Hills',
  'Vernon River',
  'Vernon Rockville',
  'Vernouillet',
  'Vero Beach',
  'Verolanuova',
  'Verona',
  'Verplanck',
  'Verrebroek',
  'Verres',
  'Verrières-le-buisson',
  'Verrone',
  'Versailles',
  'Versmold',
  'Versoix',
  'Verson',
  'Vert-le-petit',
  'Vert-saint-denis',
  'Vertesszolos',
  'Vertou',
  'Vertrieu',
  'Vertus',
  'Verviers',
  'Verwood',
  'Verzej',
  'Verzé',
  'Vescovana',
  'Vescovato',
  'Vesele',
  'Veselé',
  'Veseli Nad Luznici',
  'Veseli Nad Moravou',
  'Veselí Nad Moravou',
  'Vesenaz',
  'Vesoul',
  'Vespasiano',
  'Vespolate',
  'Vessigebro',
  'Vestal',
  'Vestavia Hills',
  'Vestbjerg',
  'Vestby',
  'Vestec',
  'Vestenbergsgreuth',
  'Vester-skerninge',
  'Vestmannaeyjar',
  'Vestnes',
  'Vestöl',
  'Veszprém',
  'Vetlanda',
  'Vettelschoß',
  'Veurey-voroize',
  'Veurne',
  'Vevay',
  'Vevey',
  'Veynes',
  'Veyras',
  'Veyre-monton',
  'Veyrier',
  'Veyrier-du-lac',
  'Vezin-le-coquet',
  'Vezins',
  'Vélez-málaga',
  'Vélizy',
  'Vélizy-villacoublay',
  'Vénissieux',
  'Vérac',
  'Véranne',
  'Véretz',
  'Véroia',
  'Véron',
  'Vésenaz',
  'Vétraz-monthoux',
  'Vétroz',
  'Vézeronce',
  'Vézénobres',
  'Viadana',
  'Viaducto Piedad',
  'Viagrande',
  'Vialonga',
  'Viamão',
  'Viana',
  'Viana Do Castelo',
  'Vianen',
  'Viano',
  'Viareggio',
  'Viarmes',
  'Vias',
  'Viator',
  'Vibo Valentia',
  'Viborg',
  'Viby',
  'Vic',
  'Vic-en-bigorre',
  'Vic-sur-aisne',
  'Vicálvaro',
  'Vicente',
  'Vicente Lopez',
  'Vicente López',
  'Vicenza',
  'Vichte',
  'Vichy',
  'Vicksburg',
  'Vico Nel Lazio',
  'Vicopisano',
  'Vicosoprano',
  'Vicques',
  'Victor',
  'Victor Harbor',
  'Victoria',
  'Victoria De Durango',
  'Victoria Estate',
  'Victoria Park',
  'Victoria Point',
  'Victoria Valley',
  'Victorias',
  'Victoriaville',
  'Victorville',
  'Vicuna',
  'Viçosa',
  'Vidalia',
  'Vidauban',
  'Videbæk',
  'Videira',
  'Videm',
  'Vidima',
  'Vidisha',
  'Vidnoye',
  'Vidor',
  'Viechtach',
  'Viehdorf',
  'Vieira De Leiria',
  'Viejo',
  'Vielbach',
  'Viella',
  'Vielsalm',
  'Vienenburg',
  'Vienna',
  'Viennay',
  'Vienne',
  'Vientiane',
  'Viereth-trunstadt',
  'Vierkirchen',
  'Vierlinden',
  'Viernheim',
  'Vierpolders',
  'Viersen',
  'Vierzon',
  'Vieux Fort',
  'Vig',
  'Viganello',
  'Vigarano Mainarda',
  'Vigasio',
  'Vigevano',
  'Viggiano',
  'Vigliano Biellese',
  'Vignacourt',
  'Vignate',
  'Vigne',
  'Vigneux-de-bretagne',
  'Vignola',
  'Vignoles',
  'Vigny',
  'Vigo',
  'Vigodarzere',
  'Vigone',
  'Vigonovo',
  'Vigonza',
  'Vigoulet-auzil',
  'Vigouroux',
  'Vihanti',
  'Vihtavuori',
  'Vihti',
  'Viile Satu Mare',
  'Viimsi',
  'Viitasaari',
  'Vijayapuri',
  'Vijayawada',
  'Vijfhuizen',
  'Vik',
  'Vikarbyn',
  'Vikasnagar',
  'Vikebukt',
  'Viken',
  'Vikhroli',
  'Vikingstad',
  'Vikmanshyttan',
  'Vila Do Bispo',
  'Vila Do Conde',
  'Vila Maior',
  'Vila Maria',
  'Vila Mariana',
  'Vila Nogueira De Azeitão',
  'Vila Nova',
  'Vila Nova De Cerveira',
  'Vila Nova De Famalicão',
  'Vila Nova De Gaia',
  'Vila Olímpia',
  'Vila Real',
  'Vila Real De Santo António',
  'Vila São Luís',
  'Vila Velha',
  'Vila Verde',
  'Vilabella',
  'Vilabertrán',
  'Vilablareix',
  'Viladecaballs',
  'Viladecáns',
  'Vilafant',
  'Vilafranca De Panades',
  'Vilamalla',
  'Vilani',
  'Vilanova',
  'Vilanova De Sau',
  'Vilanova Del Camí',
  'Vilanova I La Geltru',
  'Vilar De Andorinho',
  'Vilar Do Pinheiro',
  'Vilarinho Das Cambas',
  'Vilaseca',
  'Vilassar De Mar',
  'Vilatenim',
  'Vilá',
  'Vildbjerg',
  'Vile Parle',
  'Vilhelmina',
  'Vilhena',
  'Viljandi',
  'Vilkiskiai',
  'Villa Belgrano',
  'Villa Carlos Paz',
  'Villa Crespo',
  'Villa Cuauhtémoc',
  "Villa D'alme",
  'Villa De Alvarez',
  'Villa Di Serio',
  'Villa Espana',
  'Villa Gobernador Galvez',
  'Villa Guardia',
  'Villa Luzuriaga',
  'Villa Mercedes',
  'Villa Nicolás Romero',
  'Villa Park',
  'Villa Potenza',
  'Villa Rica',
  'Villa Unión',
  'Villa Urquiza',
  'Villabassa',
  'Villabé',
  'Villabona',
  'Villach',
  'Villacidro',
  'Villacoublay',
  'Villafalletto',
  'Villafamés',
  'Villafranca',
  'Villafranca De Bonany',
  'Villafranca De Los Barros',
  'Villafranca Del Panadés',
  'Villafranca Di Verona',
  'Villagarcía De Arosa',
  'Village',
  'Village Mills',
  'Villagonzalo',
  'Villagonzalo-pedernales',
  'Villahermosa',
  'Villajoyosa',
  'Villalba',
  'Villalba De La Sierra',
  'Villalbilla',
  'Villamanrique De La Condesa',
  'Villamayor',
  'Villamediana De Iregua',
  'Villamuriel De Cerrato',
  'Villands Vånga',
  'Villanière',
  'Villanova',
  "Villanova D'ardenghi",
  "Villanova D'asti",
  'Villanova Del Ghebbo',
  'Villanova Mondovi',
  "Villanova Sull'arda",
  'Villanubla',
  'Villanueva De Alcardete',
  'Villanueva De Algaidas',
  'Villanueva De Gállego',
  'Villanueva De La Barca',
  'Villanueva De La Cañada',
  'Villanueva De La Concepción',
  'Villanueva De La Serena',
  'Villanueva Del Pardillo',
  'Villanuova Sul Clisi',
  'Villaobispo',
  'Villaobispo De Las Regueras',
  'Villaquilambre',
  'Villar De Canes',
  'Villard-de-lans',
  'Villardonnel',
  'Villareal',
  'Villareggia',
  'Villares De La Reina',
  'Villaretto Di Roreto Chisone',
  'Villaricca',
  'Villarreal',
  'Villarreal De Los Infantes',
  'Villarrica',
  'Villarrobledo',
  'Villars',
  'Villars-les-dombes',
  'Villars-sur-glane',
  'Villars-sur-glâne',
  'Villars-sur-ollon',
  'Villasanta',
  'Villastellone',
  'Villatuerta',
  'Villava',
  'Villaverde',
  'Villavicencio',
  'Villaviciosa De Odón',
  'Villawood',
  'Villaz-saint-pierre',
  'Villány',
  'Villähde',
  'Ville',
  'Ville Platte',
  'Ville Saint Pierre',
  "Ville-d'avray",
  'Ville-la-grand',
  'Villebon',
  'Villebon-sur-yvette',
  'Villechenève',
  'Villecresnes',
  'Villedieu-les-poëles',
  'Villefontaine',
  'Villefranche-de-lauragais',
  'Villefranche-sur-mer',
  'Villefranche-sur-saône',
  'Villejuif',
  'Villejust',
  'Villemareuil',
  'Villemomble',
  'Villemotier',
  'Villemur-sur-tarn',
  'Villena',
  "Villenave-d'ornon",
  'Villeneuve',
  "Villeneuve-d'aveyron",
  'Villeneuve-de-berg',
  'Villeneuve-de-duras',
  'Villeneuve-la-garenne',
  'Villeneuve-le-roi',
  'Villeneuve-les-corbières',
  'Villeneuve-lez-avignon',
  'Villeneuve-lès-béziers',
  'Villeneuve-lès-bouloc',
  'Villeneuve-lès-maguelonne',
  'Villeneuve-loubet',
  'Villeneuve-saint-georges',
  'Villeneuve-saint-germain',
  'Villeneuve-sous-dammartin',
  'Villeneuve-sous-pymont',
  'Villeneuve-sur-auvers',
  'Villeneuve-sur-lot',
  'Villeneuve-tolosane',
  'Villennes-sur-seine',
  'Villeparisis',
  'Villepinte',
  'Villepreux',
  'Villers-bocage',
  'Villers-bretonneux',
  'Villers-la-faye',
  'Villers-la-ville',
  'Villers-le-bouillet',
  'Villers-lès-nancy',
  'Villers-lès-pots',
  'Villers-saint-amand',
  'Villers-saint-siméon',
  'Villers-semeuse',
  'Villers-sur-mer',
  'Villetaneuse',
  'Villetelle',
  'Villette-de-vienne',
  'Villeurbanne',
  'Villevaudé',
  'Villeveyrac',
  'Villevêque',
  'Villevieille',
  'Villey-sur-tille',
  'Villiers-adam',
  'Villiers-en-bière',
  'Villiers-le-bel',
  'Villiers-saint-frédéric',
  'Villiers-sur-marne',
  'Villiers-sur-morin',
  'Villigen',
  'Villingen',
  'Villingen-schwenningen',
  'Villingendorf',
  'Villmar',
  'Villmergen',
  'Villnachern',
  'Villorba',
  'Villotta',
  'Villupuram',
  'Vilnius',
  'Vilppula',
  'Vils',
  'Vilsbiburg',
  'Vilsheim',
  'Vilshofen',
  'Vilters',
  'Vilvoorde',
  'Vimenet',
  'Vimercate',
  'Vimmerby',
  'Vimodrone',
  'Vimoutiers',
  'Vimpeli',
  'Vimperk',
  'Vinaderos',
  'Vinalmont',
  'Vinaroz',
  'Vincennes',
  'Vincent',
  'Vincentown',
  'Vincey',
  'Vindeln',
  'Vinderup',
  'Vinding',
  'Vineland',
  'Vinemont',
  'Vineuil',
  'Vineuil-saint-firmin',
  'Vineyard',
  'Vineyard Haven',
  'Vingåker',
  'Vinh',
  'Vinh Long',
  'Vinhedo',
  'Vinica',
  'Vinicky',
  'Vinicne',
  'Vinings',
  'Vinisce',
  'Vinita',
  'Vinkel',
  'Vinkeveen',
  'Vinkovci',
  'Vinningen',
  'Vinnitsa',
  'Vinnytsya',
  'Vinohrady',
  'Vins-sur-caramy',
  'Vinslöv',
  'Vinstra',
  'Vinterstø',
  'Vinton',
  'Vintrie',
  'Vintrosa',
  'Viña Del Mar',
  'Viñas',
  'Viola',
  'Viols-le-fort',
  'Vionnaz',
  'Vipperød',
  'Virar',
  'Virden',
  'Vire',
  'Vireux-molhain',
  'Virey',
  'Viré',
  'Virgin',
  'Virginia',
  'Virginia Beach',
  'Virginia City',
  'Virginville',
  'Viriat',
  'Virklund',
  'Virneburg',
  'Viroflay',
  'Virolahti',
  'Viroqua',
  'Virrat',
  'Virsbo',
  'Virton',
  'Virudunagar',
  'Virugambakkam',
  'Virum',
  'Visakhapatnam',
  'Visalia',
  'Visano',
  'Visbek',
  'Visby',
  'Visconde Do Rio Branco',
  'Viserba',
  'Viseu',
  'Visé',
  'Vishakhapatnam',
  'Vislanda',
  'Visnjevac',
  'Viso Del Alcor',
  'Visp',
  'Visselhövede',
  'Vissenbjerg',
  'Vista',
  'Vista Alegre',
  'Visvliet',
  'Vitacura',
  'Vitebsk',
  'Viterbo',
  'Vitkov',
  'Vitoria',
  'Vitoria-gasteiz',
  'Vitória',
  'Vitória Da Conquista',
  'Vitória De Santo Antão',
  'Vitória Do Mearim',
  'Vitré',
  'Vitrolles',
  'Vitry-aux-loges',
  'Vitry-en-artois',
  'Vitry-le-françois',
  'Vitry-sur-seine',
  'Vittoria',
  'Vittorio',
  'Vittorio Veneto',
  'Vittsjö',
  'Vitznau',
  'Viuf',
  'Viuz-en-sallaz',
  'Vivaro',
  'Vivegnis',
  'Viviers-du-lac',
  'Vivy',
  'Vizag',
  'Vizela',
  'Vizovice',
  'Vizslás',
  'Vícar',
  'Vlaardingen',
  'Vlachovo Brezi',
  'Vladikavkaz',
  'Vladimir',
  'Vladislav Varnenchik',
  'Vladivostok',
  'Vlasim',
  'Vledder',
  'Vleuten',
  'Vlezenbeek',
  'Vlierden',
  'Vlijmen',
  'Vlimmeren',
  'Vlissingen',
  'Vlodrop',
  'Vlotho',
  'Vnukovo',
  'Vodice',
  'Vodnany',
  'Vodskov',
  'Voerde',
  'Voerendaal',
  'Vogelsdorf',
  'Voghera',
  'Voglans',
  'Vogtareuth',
  'Vogtsburg',
  'Vohburg An Der Donau',
  'Vohenstrauß',
  'Voiron',
  'Voisins-le-bretonneux',
  'Voitsberg',
  'Vojens',
  'Vojkovice',
  'Vojnic',
  'Vojnik',
  'Vokovice',
  'Volcano',
  'Volda',
  'Volendam',
  'Volga',
  'Volgodonsk',
  'Volgograd',
  'Volkach',
  'Volkel',
  'Volkers',
  'Volkertshausen',
  'Volketswil',
  'Volkmarsen',
  'Volla',
  'Vollebekk',
  'Vollenhove',
  'Vollore-montagne',
  'Vollsjö',
  'Volo',
  'Vologda',
  'Volokolamsk',
  'Volpago Del Montello',
  'Volpiano',
  'Volta Mantovana',
  'Volta Redonda',
  'Voluntari',
  'Volxheim',
  'Volyne',
  'Volzhsk',
  'Volzhskiy',
  'Volzhskoye',
  'Von Ormy',
  'Vonda',
  'Vonore',
  'Voorburg',
  'Voorhees',
  'Voorheesville',
  'Voorhout',
  'Voorschoten',
  'Voorthuizen',
  "Voray-sur-l'ognon",
  'Vorchdorf',
  'Vorden',
  'Vordingborg',
  'Voreppe',
  'Vorey',
  'Vormedal',
  'Voronezh',
  'Vorselaar',
  'Vorstenbosch',
  'Voskoniai',
  'Vosselaar',
  'Vossevangen',
  'Votice',
  'Votorantim',
  'Vottem',
  'Votuporanga',
  'Vouillé',
  'Vouliagméni',
  'Vouneuil-sous-biard',
  'Vouneuil-sur-vienne',
  'Vourles',
  'Vouvray',
  'Vouvry',
  'Vouzan',
  'Vouzela',
  'Vouziers',
  'Voúla',
  'Vólos',
  'Võru',
  'Vögelsen',
  'Vöhringen',
  'Völkermarkt',
  'Völklingen',
  'Vörå',
  'Vörbach',
  'Vörstetten',
  'Vösendorf',
  'Vöslau',
  'Vranov Nad Toplou',
  'Vratsa',
  'Vráble',
  'Vrå',
  'Vrbno Pod Pradedem',
  'Vrchlabi',
  'Vrcin',
  'Vreden',
  'Vredenburg',
  'Vreeland',
  'Vresina',
  'Vreta Kloster',
  'Vrhnika',
  'Vriezenveen',
  'Vrigstad',
  'Vroomshoop',
  'Vrsac',
  'Vrsovice',
  'Vrútky',
  'Vryheid',
  'Vsestary',
  'Vsetin',
  'Vuadens',
  'Vue',
  'Vught',
  'Vuhred',
  'Vuillecin',
  'Vukovar',
  'Vulcan',
  'Vung Tau',
  'Vuokatti',
  'Vuorilahti',
  'Vuren',
  'Vvedenskaya',
  'Vyazma',
  'Vyborg',
  'Vyshhorod',
  'Vyskov',
  'Vyskytna Nad Jihlavou',
  'Vysny Orlik',
  'Vysocany',
  'Vysoke Myto',
  'Vysoky Ujezd',
  'Værløse',
  'Wa',
  'Wa Keeney',
  'Waakirchen',
  'Waalre',
  'Waalwijk',
  'Waanrode',
  'Waardenburg',
  'Waarder',
  'Waarschoot',
  'Waasmunster',
  'Waban',
  'Wabash',
  'Wabern',
  'Wabush',
  'Waccabuc',
  'Wachenheim',
  'Wachtberg',
  'Wachtebeke',
  'Wachtendonk',
  'Wacken',
  'Waco',
  'Wacol',
  'Waconia',
  'Wada',
  'Wadala',
  'Wadayama',
  'Waddesdon',
  'Waddington',
  'Waddinxveen',
  'Wadebridge',
  'Wadena',
  'Wadenswil',
  'Wadern',
  'Wadersloh',
  'Wadestown',
  'Wadesville',
  'Wadeville',
  'Wadgassen',
  'Wadhurst',
  'Wading River',
  'Wadley',
  'Wadowice',
  'Wadsworth',
  'Wafangdian',
  'Waffenbrunn',
  'Wagenhausen',
  'Wageningen',
  'Wageningen-hoog',
  'Wagga Wagga',
  'Waghäusel',
  'Waging',
  'Wagoner',
  'Wah',
  'Wahgunyah',
  'Wahlbach',
  'Wahlen',
  'Wahoo',
  'Wahpeton',
  'Wahrenbrück',
  'Wahroonga',
  'Waialua',
  'Waianae',
  'Waiblingen',
  'Waibstadt',
  'Waidhofen',
  'Waidhofen An Der Ybbs',
  'Waidmannslust',
  'Waihi',
  'Waikanae',
  'Waikoloa',
  'Waikoukou',
  'Wailuku',
  'Waimanalo',
  'Waimauku',
  'Waimea',
  'Waimes',
  'Wain',
  'Wainwright',
  'Waipahu',
  'Waipu',
  'Wairau Valley',
  'Waitakere',
  'Waitangi',
  'Waitara',
  'Waite Park',
  'Waitoa',
  'Waitsfield',
  'Waiuku',
  'Wakamatsucho',
  'Wakarusa',
  'Wakasa',
  'Wakayama',
  'Wakayama-shi',
  'Wake Forest',
  'Wakeeney',
  'Wakefield',
  'Wakeman',
  'Waki',
  'Wakiso',
  'Wako',
  'Wakuya',
  'Walberton',
  'Walbridge',
  'Walbrzych',
  'Walcha',
  'Walchwil',
  'Walcott',
  'Walcourt',
  'Wald',
  'Wald Am Arlberg',
  'Wald-michelbach',
  'Waldalgesheim',
  'Waldaschaff',
  'Waldbreitbach',
  'Waldbröl',
  'Waldbrunn',
  'Waldburg',
  'Waldbüttelbrunn',
  'Walddorf',
  'Waldeck',
  'Waldems',
  'Walden',
  'Waldenbuch',
  'Waldenburg',
  'Walderbach',
  'Waldershof',
  'Waldesch',
  'Waldfischbach-burgalben',
  'Waldgirmes',
  'Waldhausen',
  'Waldheim',
  'Waldhölzbach',
  'Walding',
  'Waldkappel',
  'Waldkirch',
  'Waldkirchen',
  'Waldkraiburg',
  'Waldlaubersheim',
  'Waldmohr',
  'Waldmünchen',
  'Waldniel',
  'Waldo',
  'Waldoboro',
  'Waldorf',
  'Waldport',
  'Waldron',
  'Waldsassen',
  'Waldsee',
  'Waldshut',
  'Waldshut-tiengen',
  'Waldstatt',
  'Waldstetten',
  'Waldwick',
  'Walendów',
  'Wales',
  'Wales Center',
  'Walesby',
  'Waleska',
  'Walferdange',
  'Walford',
  'Walgrave',
  'Walhain',
  'Walhalla',
  'Walheim',
  'Walker',
  'Walkern',
  'Walkersville',
  'Walkerton',
  'Walkertown',
  'Walkerville',
  'Wall',
  'Walla Walla',
  'Wallace',
  'Wallaceburg',
  'Wallan',
  'Walland',
  'Wallasey',
  'Wallau',
  'Wallbach',
  'Walldorf',
  'Walldürn',
  'Walled Lake',
  'Wallendstadt',
  'Wallenhorst',
  'Waller',
  'Wallerfing',
  'Wallers',
  'Wallersdorf',
  'Wallerstein',
  'Wallhamn',
  'Wallingford',
  'Wallington',
  'Wallis',
  'Wallisellen',
  'Wallkill',
  'Wallmenroth',
  'Wallsend',
  'Wallsend-on-tyne',
  'Wallstadt',
  'Walluf',
  'Walmersley',
  'Walnut',
  'Walnut Cove',
  'Walnut Creek',
  'Walnut Grove',
  'Walnut Ridge',
  'Walpole',
  'Wals',
  'Walsall',
  'Walsall Wood',
  'Walsham Le Willows',
  'Walsingham',
  'Walsoken',
  'Walsrode',
  'Walterboro',
  'Waltershausen',
  'Waltham',
  'Waltham Abbey',
  'Waltham Cross',
  'Walthamstow',
  'Walton',
  'Walton On The Hill',
  'Walton Upon Thames',
  'Walton Upon Trent',
  'Waltonville',
  'Waltrop',
  'Walvis Bay',
  'Walworth',
  'Walzenhausen',
  'Wambrechies',
  'Wambrook',
  'Wamego',
  'Wamel',
  'Wampsville',
  'Wamuran',
  'Wan Chai',
  'Wanaka',
  'Wanamingo',
  'Wanaque',
  'Wanborough',
  'Wanchai',
  'Wancourt',
  'Wandlitz',
  'Wandsworth',
  'Wanfercée-baulet',
  'Wanfried',
  'Wang',
  'Wang Thonglang',
  'Wanganui',
  'Wangaratta',
  'Wangen',
  'Wangen An Der Aare',
  'Wangen Im Allgäu',
  'Wangjing',
  'Wangs',
  'Wanhua',
  'Wanna',
  'Wanneperveen',
  'Wanneroo',
  'Wannweil',
  'Wansdorf',
  'Wantage',
  'Wantagh',
  'Wantirna',
  'Wantirna South',
  'Wanze',
  'Wanzhou',
  'Wanzleben',
  'Wapakoneta',
  'Wapato',
  'Wapenveld',
  'Wappingers Falls',
  'Warabi',
  'Warangal',
  'Waratah',
  'Warboys',
  'Warburg',
  'Warburton',
  'Warcoing',
  'Warden',
  'Wardenburg',
  'Wardensville',
  'Wardha',
  'Wardin',
  'Wardrecques',
  'Ware',
  'Ware Shoals',
  'Waregem',
  'Wareham',
  'Waremme',
  'Waren',
  'Warendorf',
  'Warfield',
  'Wargrave',
  'Waringfield',
  'Warisan',
  'Warkworth',
  'Warlingham',
  'Warlow',
  'Warm Springs',
  'Warman',
  'Warmenhuizen',
  'Warmington',
  'Warminster',
  'Warmley',
  'Warmond',
  'Warnbro',
  'Warnemünde',
  'Warner',
  'Warner Robins',
  'Warner Springs',
  'Warnes',
  'Warneton',
  'Warngau',
  'Warnham',
  'Warnitz',
  'Warragul',
  'Warrawee',
  'Warren',
  'Warren Center',
  'Warrendale',
  'Warrenpoint',
  'Warrens',
  'Warrensburg',
  'Warrenton',
  'Warrenville',
  'Warri',
  'Warrimoo',
  'Warrington',
  'Warrior',
  'Warriors Mark',
  'Warrnambool',
  'Warroad',
  'Warsaw',
  'Warsingsfehn',
  'Warstein',
  'Warszawa',
  'Wartberg',
  'Wartenberg',
  'Wartenburg',
  'Warthausen',
  'Wartrace',
  'Waru',
  'Warwick',
  'Wasaga Beach',
  'Waseca',
  'Washburn',
  'Washdyke',
  'Washim',
  'Washington',
  'Washington Court House',
  'Washington Crossing',
  'Washington Depot',
  'Washington Grove',
  'Washington Island',
  'Washington Mills',
  'Washington Navy Yard',
  'Washingtonville',
  'Washoe Valley',
  'Washougal',
  'Wasilla',
  'Waskom',
  'Wasosz Dolny',
  'Wasquehal',
  'Wasseiges',
  'Wasselonne',
  'Wassenaar',
  'Wassenberg',
  'Wasserbillig',
  'Wasserburg',
  'Wasserburg Am Inn',
  'Wassertrüdingen',
  'Wasungen',
  'Watari',
  'Watauga',
  'Watchet',
  'Watchfield',
  'Watchung',
  'Water Mill',
  'Waterbeach',
  'Waterboro',
  'Waterbury',
  'Waterbury Center',
  'Waterdown',
  'Waterfall',
  'Waterfoot',
  'Waterford',
  'Watergang',
  'Watergrasshill',
  'Wateringen',
  'Waterloo',
  'Waterlooville',
  'Watermaal-bosvoorde',
  'Watermael-boitsfort',
  'Waterman',
  'Waterport',
  'Waterside',
  'Watertown',
  'Waterville',
  'Waterville Valley',
  'Watervliet',
  'Wates',
  'Watford',
  'Watford City',
  'Wath Upon Dearne',
  'Wathlingen',
  'Watkins',
  'Watkins Glen',
  'Watkinsville',
  'Watlington',
  'Watonga',
  'Watou',
  'Watseka',
  'Watson Lake',
  'Watsonville',
  'Wattens',
  'Wattenwil',
  'Watthana',
  'Watthana Nakhon',
  'Wattignies',
  'Wattignies-la-victoire',
  'Watton',
  'Wattrelos',
  'Wattsburg',
  'Wattwil',
  'Wattwiller',
  'Watzerath',
  'Waubach',
  'Wauchula',
  'Wauconda',
  'Waukee',
  'Waukegan',
  'Waukesha',
  'Waunakee',
  'Waupaca',
  'Waupun',
  'Wauregan',
  'Waurika',
  'Waurn Ponds',
  'Wausau',
  'Wausaukee',
  'Wauseon',
  'Wauthier-braine',
  'Wautoma',
  'Wauwatosa',
  'Wauwil',
  'Wauzeka',
  'Waveland',
  'Wavendon',
  'Waverley',
  'Waverly',
  'Waverton',
  'Wavre',
  'Wavrin',
  'Waxahachie',
  'Waxhaw',
  'Waycross',
  'Wayland',
  'Wayne',
  'Waynesboro',
  'Waynesburg',
  'Waynesville',
  'Wayville',
  'Wayzata',
  'Wächtersbach',
  'Wädenswil',
  'Wängi',
  'Wäschenbeuren',
  'Wear',
  'Weare',
  'Weatherford',
  'Weatherly',
  'Weatogue',
  'Weaverham',
  'Weaverville',
  'Webb City',
  'Webbekom',
  'Webberville',
  'Webster',
  'Webster City',
  'Websterville',
  'Weddel',
  'Wedding',
  'Wedel',
  'Wedendorf',
  'Wedgefield',
  'Wedgeport',
  'Wedmore',
  'Wednesbury',
  'Wednesfield',
  'Wee Waa',
  'Weed',
  'Weedsport',
  'Weehawken',
  'Weekley',
  'Weelde',
  'Weener',
  'Weerde',
  'Weert',
  'Wees',
  'Weesen',
  'Weesp',
  'Weeze',
  'Wegberg',
  'Wegenstetten',
  'Weggis',
  'Wegrzce',
  'Wehingen',
  'Wehnrath',
  'Wehrheim',
  'Weibern',
  'Weichs',
  'Weida',
  'Weiden',
  'Weidenberg',
  'Weidhausen',
  'Weidling',
  'Weifang',
  'Weihai',
  'Weihenstephan',
  'Weikersheim',
  'Weil Am Rhein',
  'Weil Der Stadt',
  'Weil Im Schönbuch',
  'Weilbach',
  'Weilburg',
  'Weiler',
  'Weiler-simmerberg',
  'Weilerbach',
  'Weilerswist',
  'Weilheim',
  'Weilheim In Oberbayern',
  'Weilmünster',
  'Weimar',
  'Weimarschmieden',
  'Weinbach',
  'Weinböhla',
  'Weiner',
  'Weinfelden',
  'Weingarten',
  'Weinheim',
  'Weiningen',
  'Weinolsheim',
  'Weinsberg',
  'Weinsheim',
  'Weinstadt-endersbach',
  'Weinviertl',
  'Weirton',
  'Weisenbach',
  'Weisendorf',
  'Weiser',
  'Weiskirchen',
  'Weismain',
  'Weissach',
  'Weissenburg',
  'Weisslingen',
  'Weistrach',
  'Weiswampach',
  'Weitefeld',
  'Weitersburg',
  'Weiterstadt',
  'Weitnau',
  'Weitramsdorf',
  'Weiyang',
  'Weiz',
  'Weißandt-gölzau',
  'Weißenbrunn',
  'Weißenburg',
  'Weißenburg In Bayern',
  'Weißenfels',
  'Weißenhaus',
  'Weißenhorn',
  'Weißenohe',
  'Weißensberg',
  'Weißensee',
  'Weißenthurm',
  'Weißwasser',
  'Wejherowo',
  'Wekerom',
  'Welch',
  'Welcome',
  'Weldon',
  'Welford',
  'Welkenraedt',
  'Welland',
  'Wellard',
  'Wellborn',
  'Wellcamp',
  'Wellen',
  'Wellesbourne',
  'Wellesley',
  'Wellesley Hills',
  'Wellfleet',
  'Wellford',
  'Welling',
  'Wellingborough',
  'Wellingore',
  'Wellington',
  'Wells',
  'Wells Next The Sea',
  'Wellsboro',
  'Wellsburg',
  'Wellsford',
  'Wellsville',
  'Wellton',
  'Welney',
  'Wels',
  'Welschenbach',
  'Welshpool',
  'Welsleben',
  'Welsum',
  'Welver',
  'Welwyn',
  'Welwyn Garden City',
  'Welzheim',
  'Wem',
  'Wembley',
  'Wemeldinge',
  'Wemindji',
  'Wemmel',
  'Wemperhardt',
  'Wenatchee',
  'Wenchi',
  'Wendeburg',
  'Wendell',
  'Wendelsheim',
  'Wendelstein',
  'Wenden',
  'Wendeng',
  'Wendens Ambo',
  'Wendlingen',
  'Wendorf',
  'Wendouree',
  'Wendover',
  'Wendtorf',
  'Wenduine',
  'Weng Im Innkreis',
  'Wengen',
  'Wengi',
  'Wenham',
  'Wenigenlupnitz',
  'Wenling',
  'Wennigsen',
  'Wenona',
  'Wenonah',
  'Wenshan',
  'Wentorf',
  'Wentorf Bei Hamburg',
  'Wentworth',
  'Wentworthville',
  'Wentzville',
  'Wenxi',
  'Wenxiang',
  'Wenzhou',
  'Werda',
  'Werdau',
  'Werdenberg',
  'Werder',
  'Werdohl',
  'Werfen',
  'Werken',
  'Werkendam',
  'Werkhoven',
  'Werl',
  'Werlte',
  'Wermelskirchen',
  'Wermsdorf',
  'Wernau',
  'Wernberg-köblitz',
  'Werne',
  'Werneck',
  'Wernersville',
  'Wernetshausen',
  'Wernigerode',
  'Werribee',
  'Werribee South',
  'Werrington',
  'Wertheim',
  'Werther',
  'Wertingen',
  'Wervershoof',
  'Wervicq-sud',
  'Wervik',
  'Wesel',
  'Wesendorf',
  'Weslaco',
  'Wesley Chapel',
  'Wesseling',
  'Wesseln',
  'Wessem',
  'Wessin',
  'Wesson',
  'West Alexander',
  'West Alexandria',
  'West Allis',
  'West Atlantic City',
  'West Auckland',
  'West Babylon',
  'West Barnstable',
  'West Bend',
  'West Berlin',
  'West Bloomfield',
  'West Boothbay Harbor',
  'West Boxford',
  'West Boylston',
  'West Branch',
  'West Bridgewater',
  'West Bridgford',
  'West Brome',
  'West Bromwich',
  'West Brooklyn',
  'West Burleigh',
  'West Burlington',
  'West Burton',
  'West Byfleet',
  'West Calder',
  'West Caldwell',
  'West Chester',
  'West Chicago',
  'West Chiltington',
  'West Columbia',
  'West Concord',
  'West Cornwall',
  'West Covina',
  'West Coxsackie',
  'West Creek',
  'West Dennis',
  'West Des Moines',
  'West Dover',
  'West Drayton',
  'West Dummerston',
  'West Elizabeth',
  'West End',
  'West Fargo',
  'West Finley',
  'West Godavari',
  'West Gorton',
  'West Greenwich',
  'West Grove',
  'West Haddon',
  'West Halifax',
  'West Ham',
  'West Hanningfield',
  'West Harrison',
  'West Hartford',
  'West Hatfield',
  'West Haven',
  'West Haverstraw',
  'West Helena',
  'West Hempstead',
  'West Henrietta',
  'West Hills',
  'West Hollywood',
  'West Horsley',
  'West Hurley',
  'West Hyannisport',
  'West Hythe',
  'West Islip',
  'West Jefferson',
  'West Jordan',
  'West Kensington',
  'West Kingston',
  'West Kirby',
  'West Lafayette',
  'West Lake Hills',
  'West Lebanon',
  'West Liberty',
  'West Linn',
  'West Linton',
  'West Long Branch',
  'West Los Angeles',
  'West Malling',
  'West Mambalam',
  'West Mansfield',
  'West Marton',
  'West Mclean',
  'West Memphis',
  'West Middlesex',
  'West Mifflin',
  'West Milford',
  'West Milton',
  'West Molesey',
  'West Monroe',
  'West New York',
  'West Newbury',
  'West Newfield',
  'West Newton',
  'West Nyack',
  'West Olive',
  'West Orange',
  'West Palm Beach',
  'West Park',
  'West Plains',
  'West Point',
  'West Portsmouth',
  'West Roxbury',
  'West Sacramento',
  'West Saint Paul',
  'West Salem',
  'West Simsbury',
  'West Springfield',
  'West Stockbridge',
  'West Terre Haute',
  'West Thurrock',
  'West Tisbury',
  'West Trenton',
  'West Union',
  'West Valley City',
  'West Wareham',
  'West Warwick',
  'West Wellow',
  'West Wickham',
  'West Winfield',
  'West Yarmouth',
  'West Yellowstone',
  'West-graftdijk',
  'West-knollendam',
  'Westbank',
  'Westbeemster',
  'Westboro',
  'Westborough',
  'Westbroek',
  'Westbrook',
  'Westbury',
  'Westby',
  'Westchester',
  'Westcliffe',
  'Westcott',
  'Westend',
  'Westerbeek',
  'Westerburg',
  'Westerham',
  'Westerheim',
  'Westerholz',
  'Westerkappeln',
  'Westerlo',
  'Westerly',
  'Western Grove',
  'Western Junction',
  'Western Springs',
  'Westernohe',
  'Westerstede',
  'Westerstetten',
  'Westerville',
  'Westervoort',
  'Westerwijtwerd',
  'Westfield',
  'Westfield Center',
  'Westford',
  'Westgarth',
  'Westgate',
  'Westgate On Sea',
  'Westhampton',
  'Westhampton Beach',
  'Westhausen',
  'Westheim',
  'Westhoughton',
  'Westlake',
  'Westlake Village',
  'Westland',
  'Westlands',
  'Westley',
  'Westmalle',
  'Westmead',
  'Westmeath',
  'Westmill',
  'Westminister',
  'Westminster',
  'Westmont',
  'Westmoreland',
  'Westmount',
  'Weston',
  'Weston Creek',
  'Weston On The Green',
  'Weston-super-mare',
  'Westonaria',
  'Westover',
  'Westphalia',
  'Westport',
  'Westview',
  'Westville',
  'Westwood',
  'Westwoud',
  'Westzaan',
  'Wetaskiwin',
  'Wetherby',
  'Wethersfield',
  'Wetschen',
  'Wettenberg',
  'Wetter',
  'Wetteren',
  'Wettin',
  'Wettingen',
  'Wettolsheim',
  'Wetumka',
  'Wetumpka',
  'Wetzikon',
  'Wetzlar',
  'Weurt',
  'Wevelgem',
  'Wever',
  'Wewoka',
  'Wexford',
  'Weyarn',
  'Weybridge',
  'Weyburn',
  'Weyerhaeuser',
  'Weyers Cave',
  'Weyersheim',
  'Weyhe',
  'Weyhill',
  'Weymouth',
  'Wezemaal',
  'Wezembeek-oppem',
  'Wezep',
  'Weßling',
  'Whaddon',
  'Whakatane',
  'Whaley Bridge',
  'Whampoa',
  'Whangaparaoa',
  'Whangarei',
  'Wharram',
  'Wharton',
  'Whatton',
  'Wheat Ridge',
  'Wheathampstead',
  'Wheatland',
  'Wheatley',
  'Wheaton',
  'Wheelers Hill',
  'Wheelersburg',
  'Wheeling',
  'Wheldrake',
  'Whenuapai',
  'Whetstone',
  'Whippany',
  'Whistler',
  'Whitakers',
  'Whitby',
  'Whitchurch',
  'Whitchurch-stouffville',
  'White',
  'White Bear Lake',
  'White Castle',
  'White City',
  'White Haven',
  'White Horse Beach',
  'White House',
  'White Lake',
  'White Marsh',
  'White Oak',
  'White Pigeon',
  'White Plains',
  'White River Junction',
  'White Rock',
  'White Salmon',
  'White Sulphur Springs',
  'White Waltham',
  'Whitechapel',
  'Whitecourt',
  'Whitefield',
  'Whitefish',
  'Whitegate',
  'Whitehall',
  'Whitehaven',
  'Whitehead',
  'Whitehorse',
  'Whitehouse',
  'Whitehouse Station',
  'Whiteland',
  'Whitesboro',
  'Whitestone',
  'Whitestown',
  'Whiteville',
  'Whitewater',
  'Whitewright',
  'Whitianga',
  'Whiting',
  'Whitinsville',
  'Whitland',
  'Whitley',
  'Whitley Bay',
  'Whitman',
  'Whitmore Lake',
  'Whitney',
  'Whitsett',
  'Whitstable',
  'Whittier',
  'Whittington',
  'Whittlebury',
  'Whittlesey',
  'Whittlesford',
  'Whitwick',
  'Whitworth',
  'Whixley',
  'Whyalla',
  'Whyteleafe',
  'Wiazowna',
  'Wichelen',
  'Wichita',
  'Wichita Falls',
  'Wichte',
  'Wichtrach',
  'Wick',
  'Wickede',
  'Wickenburg',
  'Wickford',
  'Wickham',
  'Wickliffe',
  'Wicklow',
  'Wickrath',
  'Widnes',
  'Wiedemar',
  'Wieden',
  'Wiedergeltingen',
  'Wiedlisbach',
  'Wiefelstede',
  'Wiehl',
  'Wieliczka',
  'Wieliszew',
  'Wielsbeke',
  'Wielun',
  'Wienau',
  'Wiener Neudorf',
  'Wiener Neustadt',
  'Wienersdorf',
  'Wierde',
  'Wierden',
  'Wieringerwerf',
  'Wiernsheim',
  'Wiers',
  'Wiesau',
  'Wiesbaden',
  'Wiesbaum',
  'Wieselburg',
  'Wiesendangen',
  'Wiesensteig',
  'Wiesentheid',
  'Wiesloch',
  'Wiesmoor',
  'Wiesthal',
  'Wietmarschen',
  'Wigan',
  'Wiggensbach',
  'Wigginton',
  'Wigram',
  'Wigston',
  'Wigton',
  'Wihogne',
  'Wijchen',
  'Wijdewormer',
  'Wijgmaal',
  'Wijhe',
  'Wijk Aan Zee',
  'Wijk Bij Duurstede',
  'Wijnegem',
  'Wijnjewoude',
  'Wijster',
  'Wijtschate',
  'Wikon',
  'Wil',
  'Wilberforce',
  'Wilberfoss',
  'Wilbraham',
  'Wilburgstetten',
  'Wilburton',
  'Wilchingen',
  'Wilcote',
  'Wild Rose',
  'Wildau',
  'Wildberg',
  'Wildbergerhütte',
  'Wildegg',
  'Wilder',
  'Wilderswil',
  'Wildervank',
  'Wildeshausen',
  'Wildhaus',
  'Wildomar',
  'Wildon',
  'Wildpoldsried',
  'Wildwood',
  'Wiler',
  'Wiley',
  'Wilford',
  'Wilhelm',
  'Wilhelminadorp',
  'Wilhelmsdorf',
  'Wilhelmshaven',
  'Wilhering',
  'Wilkau-haßlau',
  'Wilkes Barre',
  'Wilkesboro',
  'Wilkeson',
  'Wilkinson',
  'Wilkinstown',
  'Willacoochee',
  'Willamina',
  'Willand',
  'Willard',
  'Willcox',
  'Willebadessen',
  'Willebroek',
  'Willems',
  'Willemsoord',
  'Willemstad',
  'Willenhall',
  'Willerby',
  'Willernie',
  'Willesborough',
  'Willesden',
  'Williams',
  'Williams Bay',
  'Williams Lake',
  'Williamsburg',
  'Williamson',
  'Williamsport',
  'Williamston',
  'Williamstown',
  'Williamsville',
  'Williamtown',
  'Willich',
  'Willimantic',
  'Willingboro',
  'Willingen',
  'Willingham',
  'Willington',
  'Willis',
  'Willisau',
  'Williston',
  'Williston Park',
  'Williton',
  'Willits',
  'Willmar',
  'Willmering',
  'Willoughby',
  'Willow',
  'Willow Creek',
  'Willow Grove',
  'Willow Spring',
  'Willow Springs',
  'Willow Street',
  'Willowbrook',
  'Willowdale',
  'Willows',
  'Wills Point',
  'Willstätt',
  'Willunga',
  'Wilmerding',
  'Wilmersdorf',
  'Wilmette',
  'Wilmington',
  'Wilmore',
  'Wilmot',
  'Wilmslow',
  'Wilnecote',
  'Wilnsdorf',
  'Wilp',
  'Wilpshire',
  'Wilrijk',
  'Wilsden',
  'Wilsdruff',
  'Wilsele',
  'Wilson',
  'Wilson Hill',
  'Wilsonville',
  'Wilster',
  'Wilton',
  'Wilton Manors',
  'Wiltshire',
  'Wiltz',
  'Wimauma',
  'Wimberley',
  'Wimbish',
  'Wimbledon',
  'Wimborne',
  'Wimborne Minster',
  'Wimmis',
  'Wimsheim',
  'Winamac',
  'Wincanton',
  'Winchester',
  'Winchfield',
  'Winchmore Hill',
  'Wind Gap',
  'Windach',
  'Windber',
  'Winder',
  'Windermere',
  'Windesheim',
  'Windhagen',
  'Windham',
  'Windhoek',
  'Windisch',
  'Windischeschenbach',
  'Windischleuba',
  'Windlesham',
  'Windom',
  'Windorf',
  'Windsor',
  'Windsor Forest',
  'Windsor Heights',
  'Windsor Locks',
  'Windsor Mill',
  'Winfield',
  'Winford',
  'Winfred',
  'Wing',
  'Wingate',
  'Wingene',
  'Wingerode',
  'Wingrave',
  'Winhöring',
  'Winikon',
  'Winkel',
  'Winkelhaid',
  'Winkelman',
  'Winkfield',
  'Winkleigh',
  'Winkler',
  'Winlock',
  'Winn',
  'Winnabow',
  'Winnebago',
  'Winneconne',
  'Winnemucca',
  'Winnenden',
  'Winnersh',
  'Winnetka',
  'Winneweer',
  'Winnie',
  'Winningen',
  'Winnington',
  'Winnipeg',
  'Winnsboro',
  'Winnweiler',
  'Winona',
  'Winona Lake',
  'Winooski',
  'Winschoten',
  'Winsen',
  'Winsford',
  'Winslow',
  'Winsted',
  'Winston Salem',
  'Winston-salem',
  'Winsum',
  'Winter Garden',
  'Winter Haven',
  'Winter Park',
  'Winter Springs',
  'Winterbach',
  'Winterberg',
  'Winterborne Kingston',
  'Winterhude',
  'Winterport',
  'Winters',
  'Winterstettenstadt',
  'Winterswijk',
  'Winterthur',
  'Winterthur Töss',
  'Winterton',
  'Winterville',
  'Winthrop',
  'Winthrop Harbor',
  'Wintzenheim',
  'Wiórek',
  'Wipperfürth',
  'Wirges',
  'Wiri',
  'Wirksworth',
  'Wirtheim',
  'Wisbech',
  'Wiscasset',
  'Wisconsin Dells',
  'Wisconsin Rapids',
  'Wise',
  'Wishaw',
  'Wisla Wielka',
  'Wisley',
  'Wislina',
  'Wismar',
  'Wisner',
  'Wissembourg',
  'Wissen',
  'Wissenkerke',
  'Wissous',
  'Wiston',
  'Witbank',
  'Witchford',
  'Witham',
  'Withcott',
  'Withnell',
  'Witney',
  'Wittelsheim',
  'Witten',
  'Wittenau',
  'Wittenbach',
  'Wittenberg',
  'Wittenberge',
  'Wittenburg',
  'Wittgendorf',
  'Wittgert',
  'Wittichenau',
  'Wittingen',
  'Wittlich',
  'Wittnau',
  'Wittstock',
  'Witzenhausen',
  'Witzhave',
  'Wix',
  'Wixom',
  'Wizernes',
  'Wißmannsdorf',
  'Wladyslawowo',
  'Wloclawek',
  'Wloszczowa',
  'Woburn',
  'Wodonga',
  'Wodzislaw Slaski',
  'Woerden',
  'Woerdense Verlaat',
  'Wofford Heights',
  'Woggersin',
  'Wognum',
  'Wohlen',
  'Woippy',
  'Woking',
  'Wokingham',
  'Wola',
  'Wola Zaradzynska',
  'Wolbrom',
  'Wolcott',
  'Wolcottville',
  'Woldingham',
  'Wolf Creek',
  'Wolf Point',
  'Wolfach',
  'Wolfeboro',
  'Wolfen',
  'Wolfenbüttel',
  'Wolfenschiessen',
  'Wolfersdorf',
  'Wolfertschwenden',
  'Wolfforth',
  'Wolfgang',
  'Wolfgantzen',
  'Wolfhagen',
  'Wolfratshausen',
  'Wolfsbach',
  'Wolfsberg',
  'Wolfsburg',
  'Wolfschlugen',
  'Wolfurt',
  'Wolfville',
  'Wolfwil',
  'Wolgast',
  'Wolgyedong',
  'Wolhusen',
  'Wolica',
  'Wolka Nosowska',
  'Wolkenstein',
  'Wolkersdorf',
  'Wollaston',
  'Wollerau',
  'Wollersdorf',
  'Wollongong',
  'Wolmirstedt',
  'Wolnzach',
  'Wolomin',
  'Wolpertshausen',
  'Wolsingham',
  'Wolston',
  'Wolsztyn',
  'Woltersdorf',
  'Woluwé-saint-lambert',
  'Woluwé-saint-pierre',
  'Wolvega',
  'Wolverhampton',
  'Wolvertem',
  'Wolverton',
  'Wombourn',
  'Wommelgem',
  'Wondelgem',
  'Wonder Lake',
  'Wonersh',
  'Wonewoc',
  'Wong Tai Sin',
  'Wonga Park',
  'Wonju',
  'Wonmigu',
  'Wooburn',
  'Wood Dale',
  'Wood Ridge',
  'Wood River',
  'Woodacre',
  'Woodbine',
  'Woodbourne',
  'Woodbridge',
  'Woodburn',
  'Woodbury',
  'Woodbury Heights',
  'Woodcliff Lake',
  'Woodend',
  'Woodford',
  'Woodford Green',
  'Woodhall Spa',
  'Woodham Ferrers',
  'Woodhaven',
  'Woodhull',
  'Woodinville',
  'Woodland',
  'Woodland Hills',
  'Woodland Park',
  'Woodlands',
  'Woodlawn',
  'Woodley',
  'Woodlyn',
  'Woodmere',
  'Woodridge',
  'Woodruff',
  'Woods Cross',
  'Woods Hole',
  'Woodsboro',
  'Woodsfield',
  'Woodside',
  'Woodstock',
  'Woodstown',
  'Woodsville',
  'Woodville',
  'Woodward',
  'Woodway',
  'Wool',
  'Wooler',
  'Woolford',
  'Woolgoolga',
  'Woolloomooloo',
  'Woolpit',
  'Woolrich',
  'Woolston',
  'Woolwich',
  'Woombye',
  'Woonona',
  'Woonsocket',
  'Wooster',
  'Wooton Bassett',
  'Wootton',
  'Worb',
  'Worben',
  'Worbis',
  'Worblaufen',
  'Worcester',
  'Worcester Park',
  'Woringen',
  'Workington',
  'Worksop',
  'Workum',
  'Worland',
  'Worli',
  'Wormeldange',
  'Wormer',
  'Wormerveer',
  'Worminghall',
  'Worms',
  'Wormstedt',
  'Worongary',
  'Worplesdon',
  'Worsley',
  'Wortegem',
  'Worth',
  'Worthenbury',
  'Worthing',
  'Worthington',
  'Worton',
  'Wotton-under-edge',
  'Woubrugge',
  'Woudenberg',
  'Woustviller',
  'Wouw',
  'Wouwse-plantage',
  'Wöhrden',
  'Wölfershausen',
  'Wölfersheim',
  'Wölflinswil',
  'Wölsendorf',
  'Wörrstadt',
  'Wört',
  'Wörth',
  'Wörth Am Rhein',
  'Wörthsee',
  'Wragby',
  'Wraxall',
  'Wray',
  'Wrea Green',
  'Wrentham',
  'Wrestedt',
  'Wrestlingworth',
  'Wrexham',
  'Wriedel',
  'Wright City',
  'Wrightstown',
  'Wrightsville',
  'Wrightsville Beach',
  'Wrociszew',
  'Wroclaw',
  'Wronki',
  'Wroslaw',
  'Wrotham',
  'Wroughton',
  'Wuan',
  'Wuchang',
  'Wucheng',
  'Wudang',
  'Wudi',
  'Wuhai',
  'Wuhan',
  'Wuhan Shi',
  'Wuhu',
  'Wuhu Shi',
  'Wuhua',
  'Wujiang',
  'Wujin',
  'Wuku',
  'Wulumuqi',
  'Wundersleben',
  'Wundowie',
  'Wunsiedel',
  'Wunstorf',
  'Wuppertal',
  'Wuqing',
  'Wurenlos',
  'Wurmannsquick',
  'Wurmberg',
  'Wurmlingen',
  'Wurtland',
  'Wurtsboro',
  'Wurzbach',
  'Wurzen',
  'Wuse',
  'Wustermark',
  'Wutha',
  'Wutöschingen',
  'Wuustwezel',
  'Wuwei',
  'Wuxi',
  'Wuxi Shi',
  'Wuxue',
  'Wuyi',
  'Wuzhong',
  'Wuzhou',
  'Wülfrath',
  'Wünnenberg',
  'Wünnewil',
  'Wünschendorf',
  'Würenlingen',
  'Würenlos',
  'Würselen',
  'Würtingen',
  'Würzberg',
  'Würzburg',
  'Wüstenbrand',
  'Wüstenrot',
  'Wyandanch',
  'Wyandotte',
  'Wyberton',
  'Wyckoff',
  'Wycombe',
  'Wye Mills',
  'Wyebridge',
  'Wyee',
  'Wyke',
  'Wylie',
  'Wymondham',
  'Wynantskill',
  'Wynau',
  'Wyncote',
  'Wynigen',
  'Wynne',
  'Wynnewood',
  'Wynnum',
  'Wynyard',
  'Wyoming',
  'Wyong',
  'Wysokie Mazowieckie',
  'Wyszków',
  'Wythenshawe',
  'Wytheville',
  'Xaffévillers',
  'Xaintrailles',
  'Xalapa',
  'Xanten',
  'Xanxerê',
  'Xaxim',
  'Xánthi',
  'Xenia',
  'Xerém',
  'Xiacheng',
  'Xiamei',
  'Xiamen',
  'Xiamen Shi',
  'Xian',
  'Xian Shi',
  'Xiangang',
  'Xiangcheng',
  'Xiangfan',
  'Xiangshan',
  'Xiangtan',
  'Xiangyang',
  'Xiangzhou',
  'Xianju',
  'Xianning',
  'Xianxia',
  'Xianyang',
  'Xiaodongzhuang',
  'Xiaogan',
  'Xiaoshan',
  'Xiashanqu',
  'Xichang',
  'Xicheng',
  'Xicotepec De Juárez',
  'Xidong',
  'Xihongmen',
  'Xihu',
  'Xili',
  'Ximen',
  'Xinchang',
  'Xincheng',
  'Xinchu',
  'Xinggang',
  'Xinghua',
  'Xinglongtai',
  'Xingning',
  'Xingtai',
  'Xingyi',
  'Xinhua',
  'Xinhui',
  'Xining',
  'Xinji',
  'Xinjian',
  'Xinjiang',
  'Xinqiao',
  'Xinwei',
  'Xinxiang',
  'Xinyang',
  'Xinye',
  'Xinyi',
  'Xinying',
  'Xinyu',
  'Xinyuan',
  'Xinzhou',
  'Xinzhuang',
  'Xirdalan',
  'Xishan',
  'Xixia',
  'Xixian',
  'Xixiang',
  'Xizhang',
  'Xonrupt-longemer',
  'Xuancheng',
  'Xuanwu',
  'Xuanzhou',
  'Xuchang',
  'Xuhui',
  'Xujiahui',
  'Xuzhou',
  'Xylophagou',
  'Xylókastro',
  'Yaan',
  'Yaba',
  'Yabu',
  'Yabuki',
  'Yachiyo',
  'Yad Binyamin',
  'Yad Hanna',
  'Yad Rambam',
  'Yadkinville',
  'Yafah',
  'Yafo',
  'Yagur',
  'Yahiko',
  'Yair',
  'Yaita',
  'Yaiza',
  'Yaizu',
  'Yakima',
  'Yakima Indian Res',
  'Yakum',
  'Yakuplu',
  'Yakutsk',
  'Yalding',
  'Yalova',
  'Yalta',
  'Yamaga',
  'Yamagata',
  'Yamagata-shi',
  'Yamaguchi',
  'Yamanashi',
  'Yamashitacho',
  'Yamato-minami',
  'Yamin',
  'Yamoussoukro',
  'Yamskaya',
  'Yamunanagar',
  'Yan Nawa',
  'Yanagawa',
  'Yanagibashi',
  'Yanagimoto',
  'Yanai',
  'Yanan',
  'Yanbu Al Sinaiyah',
  'Yanbu Al-bahar',
  'Yanbu Industrial City',
  'Yancheng',
  'Yanchuan',
  'Yanco',
  'Yandina',
  'Yangan',
  'Yangchon',
  'Yangchongu',
  'Yangchun',
  'Yanghe',
  'Yangjin',
  'Yangju',
  'Yangling',
  'Yangon',
  'Yangpu',
  'Yangpyeong',
  'Yangquan',
  'Yangsan',
  'Yangshuo',
  'Yangxi',
  'Yangzhong',
  'Yangzhou',
  'Yanji',
  'Yankton',
  'Yanliang',
  'Yanqi',
  'Yanqing',
  'Yanshi',
  'Yanta',
  'Yantai',
  'Yantzaza',
  'Yanzhou',
  'Yao',
  'Yaoundé',
  'Yaphank',
  'Yardley',
  'Yarm',
  'Yarm On Tees',
  'Yarmouth',
  'Yarmouth Port',
  'Yarnton',
  'Yaroslavl',
  'Yarqona',
  'Yarra',
  'Yarra Junction',
  'Yarrabah',
  'Yarraville',
  'Yashio',
  'Yatala',
  'Yate',
  'Yates City',
  'Yatsushiro',
  'Yatton',
  'Yau Tsim Mong',
  'Yavatmal',
  'Yavne',
  'Yavneh',
  'Yavoriv',
  'Yawata',
  'Yaxley',
  'Yazoo City',
  'Ye',
  'Yeadon',
  'Yeaveley',
  'Yebes',
  'Yecla',
  'Yecla De Yeltes',
  'Yehud',
  'Yekaterinburg',
  'Yelahanka',
  'Yelken',
  'Yellow Jacket',
  'Yellow Springs',
  'Yellowknife',
  'Yelm',
  'Yelverton',
  'Yemassee',
  'Yenagoa',
  'Yenibosna',
  'Yenihisar',
  'Yenimahalle',
  'Yenisahra',
  'Yens',
  'Yeoju',
  'Yeoksamdong',
  'Yeongyang',
  'Yeosu',
  'Yeovil',
  'Yepes',
  'Yeppoon',
  'Yerevan',
  'Yeri',
  'Yerington',
  'Yermasoyia',
  'Yerres',
  'Yerseke',
  'Yeruham',
  'Yesan',
  'Yesilkoy',
  'Yèbles',
  'Yên Bái',
  'Yffiniac',
  'Yibin',
  'Yichang',
  'Yichun',
  'Yiewsley',
  'Yinchuan',
  'Yinchuan Shi',
  'Yingcheng',
  'Yingge',
  'Yingkou',
  'Yingpu Jiedao',
  'Yingtan',
  'Yining',
  'Yinzhou',
  "Yir'on",
  'Yiwu',
  'Yixing',
  'Yiyang',
  'Yiyuan',
  'Yizheng',
  'Yizhuang',
  'Ylämylly',
  'Yläne',
  'Ylikiiminki',
  'Ylistaro',
  'Ylitornio',
  'Ylivieska',
  'Ylöjärvi',
  'Yngsjö',
  'Yoakum',
  'Yodefat',
  'Yodogawa',
  'Yogyakarta',
  'Yohanan',
  'Yokkaichi',
  'Yokogawa',
  'Yokohama',
  'Yokohama-shi',
  'Yokosuka',
  'Yokote',
  'Yola',
  'Yolet',
  'Yonago',
  'Yonezawa',
  'Yong Peng',
  'Yongam',
  'Yongan',
  'Yongchon',
  'Yongdudong',
  'Yongdungpo',
  'Yonggu',
  'Yongin',
  'Yongjia',
  'Yongkang',
  'Yongnam',
  'Yongsan',
  'Yongshun',
  'Yongsin',
  'Yongxin',
  'Yongzhou',
  'Yonkers',
  'Yopal',
  'Yoqn`am',
  'Yoqne`am `illit',
  'Yorba Linda',
  'Yorii',
  'Yorishima',
  'York',
  'York Haven',
  'York New Salem',
  'York Springs',
  'Yorklyn',
  'Yorkshire',
  'Yorkton',
  'Yorktown',
  'Yorktown Heights',
  'Yorkville',
  'Yosemite National Park',
  'Yoshida',
  'Yoshikawa',
  'Yoshkar-ola',
  'Yotsukaido',
  'Yotsuya',
  'Youghal',
  'Youidodong',
  'Young',
  'Young Harris',
  'Youngstown',
  'Youngsville',
  'Youngtown',
  'Youngwood',
  'Yountville',
  'Yoyogi-sanyacho',
  'Yozgat',
  'Ypäjä',
  'Ypres',
  'Ypsilanti',
  'Yreka',
  'Ysselsteyn',
  'Ystad',
  'Ystrad Mynach',
  'Ystrad-meurig',
  'Ytrac',
  'Ytterby',
  'Yuba City',
  'Yubei',
  'Yucaipa',
  'Yucatán',
  'Yucca',
  'Yucca Valley',
  'Yucheng',
  'Yuci',
  'Yuecheng',
  'Yuehu',
  'Yuen Long',
  'Yueqing',
  'Yuexi',
  'Yueyang',
  'Yuhang',
  'Yuhu',
  'Yuhuan',
  'Yukon',
  'Yukuhashi',
  'Yulee',
  'Yulin',
  'Yuma',
  'Yuncheng',
  'Yuncos',
  'Yunfeng',
  'Yunfu',
  'Yungaburra',
  'Yungho',
  'Yunhe',
  'Yunnan',
  'Yuntian',
  'Yunusemre',
  'Yunyang',
  'Yuregir',
  'Yurihonjo',
  'Yushan',
  'Yushe',
  'Yusong',
  'Yuval',
  'Yuxi',
  'Yuyao',
  'Yuzawa',
  'Yuzhno-sakhalinsk',
  'Yuzhnyy',
  'Yuzhong',
  'Yuzhou',
  'Yuzhuang',
  'Yüanlin',
  'Yünlin',
  'Yünnan',
  'Yverdon-les-bains',
  'Yves',
  'Yvetot',
  'Yvette',
  'Yvoir',
  'Yvonand',
  'Yvorne',
  'Yvrac',
  "Za'tar",
  'Zaandam',
  'Zaandijk',
  'Zabbar',
  'Zabehlice',
  'Zaberfeld',
  'Zabierzow',
  'Zabierzów',
  'Zabki',
  'Zabkowice Slaskie',
  'Zabnica',
  'Zabrze',
  'Zacatecas',
  'Zachary',
  'Zacler',
  'Zacualtipán',
  'Zaczernie',
  'Zadar',
  'Zafra',
  'Zagazig',
  'Zaghouan',
  'Zagorje',
  'Zagorz',
  'Zagreb',
  'Zagrebacka Dubrava',
  'Zagyvaszántó',
  'Zahlé',
  'Zahorovice',
  'Zainingen',
  'Zajecar',
  'Zakopane',
  'Zakroczym',
  'Zalaegerszeg',
  'Zalasewo',
  'Zalau',
  'Zalec',
  'Zalla',
  'Zaltbommel',
  'Zama',
  'Zamalek',
  'Zamberk',
  'Zamboanga City',
  'Zamienie',
  'Zamora',
  'Zamosc',
  'Zamrsk',
  'Zamudio',
  'Zandhoven',
  'Zandt',
  'Zandvoort',
  'Zane',
  'Zanesville',
  'Zanica',
  'Zanzibar',
  'Zaozhuang',
  'Zapata',
  'Zapopan',
  'Zaporizhzhya',
  'Zaporozhye',
  'Zapresic',
  'Zaragoza',
  'Zarandona',
  'Zaratán',
  'Zarautz',
  'Zarechny',
  'Zarechnyy',
  'Zarephath',
  'Zargoza',
  'Zaria',
  'Zarnesti',
  'Zarnovica',
  'Zarpen',
  "Zarqa'",
  'Zarren',
  'Zary',
  'Zastavka U Brna',
  'Zatec',
  'Zaton',
  'Zaventem',
  'Zawiercie',
  'Záhony',
  'Zákynthos',
  'Závadka',
  'Zäziwil',
  'Zbehy',
  'Zbiroh',
  'Zblewo',
  'Zbraslav',
  'Zbuzany',
  'Zdanice',
  'Zdar Nad Sazavou',
  'Zdiby',
  'Zdice',
  'Zebbug',
  'Zebetin',
  'Zebrak',
  'Zebulon',
  'Zeddam',
  'Zedelgem',
  'Zedowice',
  'Zeebrugge',
  'Zeeland',
  'Zeewolde',
  'Zeez',
  'Zegocina',
  'Zehdenick',
  'Zehlendorf',
  'Zeilsheim',
  'Zeist',
  'Zeithain',
  'Zeitz',
  'Zelatovice',
  'Zele',
  'Zelechovice Nad Drevnici',
  'Zelem',
  'Zelenec',
  'Zelenogorsk',
  'Zelenograd',
  'Zelezniki',
  'Zelezny Brod',
  'Zelhem',
  'Zelienople',
  'Zeliezovce',
  'Zell',
  'Zell Am Harmersbach',
  'Zella-mehlis',
  'Zellik',
  'Zellingen',
  'Zellwood',
  'Zeltingen-rachtig',
  'Zeltweg',
  'Zelzate',
  'Zemgale',
  'Zempoala',
  'Zemst',
  'Zemun',
  'Zengcheng',
  'Zenia',
  'Zenica',
  'Zephyr Cove',
  'Zephyrhills',
  'Zeppelinheim',
  'Zeralda',
  'Zeravice',
  'Zerbst',
  'Zermatt',
  'Zernez',
  'Zernica',
  'Zerniki',
  'Zetel',
  'Zetten',
  'Zeulenroda',
  'Zeuthen',
  'Zeven',
  'Zevenaar',
  'Zevenbergen',
  'Zevenbergschen Hoek',
  'Zevenhuizen',
  'Zevio',
  'Zeytinburnu',
  'Zgorzelec',
  'Zhabei',
  'Zhangbaduo',
  'Zhangdian',
  'Zhangjiagang',
  'Zhangjiajie',
  'Zhangjiakou',
  'Zhangjiang',
  'Zhangmutou',
  'Zhangpu',
  'Zhangqiu',
  'Zhangshan',
  'Zhangshu',
  'Zhangzhou',
  'Zhanjiang',
  'Zhaoqing',
  'Zhaotong',
  'Zhaoyuan',
  'Zhaozhuang',
  'Zhedong',
  'Zhengjiang',
  'Zhengzhou',
  'Zhenhai',
  'Zhenjiang',
  'Zhenwu',
  'Zhenzhou',
  'Zhijiang',
  'Zhitomir',
  'Zhmerynka',
  'Zhongdian',
  'Zhonghe',
  'Zhongjiang',
  'Zhongrong',
  'Zhongshan',
  'Zhongshi',
  'Zhongtian',
  'Zhongwei',
  'Zhongxian',
  'Zhongxin',
  'Zhongxing',
  'Zhongyi',
  'Zhongzhi',
  'Zhongzhou',
  'Zhoucheng',
  'Zhoukou',
  'Zhoushan',
  'Zhovkva',
  'Zhucheng',
  'Zhuhai',
  'Zhuji',
  'Zhujiang',
  'Zhukovskiy',
  'Zhukovskogo',
  'Zhumadian',
  'Zhuozhou',
  'Zhuzhou',
  'Zhytomyr',
  'Ziar Nad Hronom',
  'Zibello',
  'Zibo',
  'Zicron Jacob',
  'Zidlochovice',
  'Ziegelbrücke',
  'Zielona Góra',
  'Zielonka',
  'Zielonki',
  'Zierikzee',
  'Zigong',
  'Zihlschlacht',
  'Zihuatanejo',
  'Zijderveld',
  'Zilina',
  'Zillis',
  'Zimapán',
  'Zimatlán',
  'Zimmern',
  'Zimmern Ob Rottweil',
  'Zinacantepec',
  'Zinchenko',
  'Zincirlikuyu',
  'Zingem',
  'Zingst',
  'Zinj',
  'Zinkgruvan',
  'Zinnowitz',
  'Zion',
  'Zionsville',
  'Zipf',
  'Zippori',
  'Ziqim',
  'Zirapur',
  'Zirc',
  'Ziri',
  'Zirndorf',
  'Zirovnica',
  'Zitenice',
  'Zittau',
  'Zivinice',
  'Zizers',
  'Zizkov',
  'Zlicin',
  'Zlin',
  'Zlín',
  'Zlonice',
  'Zlotniki Wielkie',
  'Zlotoryja',
  'Zlutice',
  'Znojmo',
  'Zoar',
  'Zoelen',
  'Zoerle-parwijs',
  'Zoersel',
  'Zoetermeer',
  'Zoeterwoude',
  'Zoeterwoude-rijndijk',
  'Zofingen',
  'Zogno',
  'Zográfou',
  'Zola Predosa',
  'Zollbrück',
  'Zollikofen',
  'Zollikon',
  'Zolling',
  'Zollstock',
  'Zoltan',
  'Zonguldak',
  'Zonhoven',
  'Zonnebeke',
  'Zoppola',
  'Zoran',
  'Zorneding',
  'Zornheim',
  'Zorroza',
  'Zory',
  'Zossen',
  'Zottegem',
  'Zoucheng',
  'Zouping',
  'Zoutkamp',
  'Zovko',
  'Zörbig',
  'Zrece',
  'Zrenjanin',
  'Zruc Nad Sazavou',
  'Zsámbék',
  'Zschepplin',
  'Zschopau',
  'Zschorna',
  'Zsombó',
  'Zubia',
  'Zubiaur',
  'Zubri',
  'Zuchwil',
  'Zug',
  'Zugersee',
  'Zuidbroek',
  'Zuidermeer',
  'Zuiderwoude',
  'Zuidhorn',
  'Zuidland',
  'Zuidlaren',
  'Zuidoostbeemster',
  'Zuienkerke',
  'Zuilen',
  'Zulte',
  'Zumaia',
  'Zumaya',
  'Zumárraga',
  'Zumbrota',
  'Zumikon',
  'Zumpango De Ocampo',
  'Zundert',
  'Zunhua',
  'Zunyi',
  'Zunzgen',
  "Zuq Mikha'il",
  'Zur Moshe',
  'Zurawia',
  'Zurawica',
  'Zurich',
  'Zurit',
  'Zurzach',
  'Zushi',
  'Zusmarshausen',
  'Zutendaal',
  'Zutphen',
  'Zuzenhausen',
  'Zuzgen',
  'Zuzwil',
  'Zülpich',
  'Zürich',
  'Zvishavane',
  'Zvole',
  'Zvolen',
  'Zvornik',
  'Zwaag',
  'Zwaagdijk',
  'Zwaagdijk-oost',
  'Zwaagwesteinde',
  'Zwaanshoek',
  'Zwanenburg',
  'Zwartewaal',
  'Zweibrücken',
  'Zweisimmen',
  'Zwenkau',
  'Zwevegem',
  'Zwevezele',
  'Zwickau',
  'Zwierzyniec',
  'Zwiesel',
  'Zwijnaarde',
  'Zwijndrecht',
  'Zwinderen',
  'Zwingen',
  'Zwingenberg',
  'Zwolle',
  'Zwönitz',
  'Zygi',
  'Zygmunty',
  'Zyrardow',
  'Zytnia',
  'Zywiec',
  '`ali',
  '`amman',
  '`ein Hahoresh',
  '`ein Hamifrats',
  '`ein Hashofet',
  '`ein Shemer',
  '`ein Tsurim',
  '`ein Vared',
  'Ølen',
  'Ølsted',
  'Ølstykke',
  'Ørby',
  'Ørbæk',
  'Ørje',
  'Ørsta',
  'Ørum',
  'Østbirk',
  'Øster Brønderslev',
  'Østerhede',
  'Østermarie',
  'Øvre',
  'Øvre Årdal',
  'Øvrebø',
  'Øyeren',
]
