import React, { useState } from 'react'
import styles from '../style.module.scss'
import { Icon } from '@blueprintjs/core'
import { DialogueType } from '..'

interface Props {
  chatHistory: DialogueType[]
  appendQueryToChat: React.Dispatch<React.SetStateAction<DialogueType[]>>
}

const QueryInput = (props: Props) => {
  const { chatHistory, appendQueryToChat } = props
  const [queryText, setQueryText] = useState<string>('')
  const [compositionStart, setCompositionStart] = useState(false)
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQueryText(e.target.value)
  }
  const handleInputSubmit = () => {
    appendQueryToChat([...chatHistory, { message: queryText, source: 'user' }])
    setQueryText('')
  }
  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !compositionStart) {
      handleInputSubmit()
    }
  }
  return (
    <div className={`${styles.maxWidth} ${styles.inputContainer}`}>
      <input
        className={styles.input}
        type="text"
        placeholder="Top 10 aerospace companies"
        value={queryText}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        onCompositionStart={() => setCompositionStart(true)}
        onCompositionEnd={() => setCompositionStart(false)}
      />
      <Icon icon="send-message" className={styles.sendIcon} onClick={handleInputSubmit} />
    </div>
  )
}

export default QueryInput
